import { createSignature, getEDSKeyInfo } from 'api/requests/document-sign.api';

export const getP12ClientKeyObject = async ({
                                              isInternalSignEmployeeInitiator,
                                              edsBase64,
                                              edsPassword,
                                              isOCSPVerified = true,
                                              handleError = error => {
                                              }
                                            }) => {
  if (isInternalSignEmployeeInitiator) {
    return {
      subject: ''
    };
  }

  try {
    const response = await getEDSKeyInfo({
      'revocationCheck': [
        'OCSP'
      ],
      'keys': [
        {
          key: edsBase64,
          password: edsPassword,
          keyAlias: null
        }
      ]
    });

    return response?.signers?.[0];
  } catch (e) {
    handleError(e);
  }
};

export const sendSignatureToParagraph = async ({
                                                 isExternal = false,
                                                 userModelId,
                                                 formData,
                                                 handleSuccess = () => {
                                                 },
                                                 handleError = errorMessage => {
                                                 }
                                               }) => {
  try {
    const params = isExternal ? { isExternal, userModelId } : { userModelId };
    await createSignature({
      params,
      formData
    });
    handleSuccess();
  } catch (e) {
    handleError(e.response?.data?.Message);
  }
};

