import { FormValues } from './types';

import { MODIFIER_DELIMITER, MODIFIERS, REPLACEABLE_PART_END, REPLACEABLE_PART_START } from './constants';

export const getReplaceableTemplateParts = (template: string): string[] => template
    .split(REPLACEABLE_PART_START)
    .filter((value: string): boolean => value.includes(REPLACEABLE_PART_END))
    .map((value: string): string => value.split(REPLACEABLE_PART_END)[0]);

export const replaceWithModifiers = (template: string, values: FormValues): string => {
  let result = template;
  const replaceableParts = getReplaceableTemplateParts(template);

  // Формат: {{ fieldName ! modifier }} или {{ fieldName }}
  // modifier - название модификатора для значения поля fieldName
  for (const part of replaceableParts) {
    const [formField, modifier] = part.split(MODIFIER_DELIMITER).map(v => v.trim());
    let formFieldValue = values[formField] as string || '(none)';

    if (typeof values[formField] === 'boolean') {
      formFieldValue = values[formField].toString();
    }

    const shouldApplyModifier =
      typeof values[formField] === 'string' &&
      modifier &&
      Object.keys(MODIFIERS).includes(modifier);

    if (shouldApplyModifier) {
      formFieldValue = MODIFIERS[modifier](formFieldValue);
    }

    const replaceString = REPLACEABLE_PART_START + part + REPLACEABLE_PART_END;
    result = result.replace(replaceString, formFieldValue);
  }

  return result;
};

