import makeStyles from '@mui/styles/makeStyles';

type styleProps = {
  isPassedCondition: boolean;
};

export default makeStyles(({ spacing, palette }) => ({
  historyItemWrapper: {
    position: 'relative',
    marginTop: 10,
    marginLeft: spacing(6),
    paddingRight: spacing(6),
    // '&:first-child': {
    //   marginTop: spacing(4),
    // },
  },
  timelinePart: {
    position: 'absolute',
    left: -26,
    top: 5,
    height: '100%',
  },
  timelinePoint: {
    width: 15,
    height: 19,
    display: 'flex',
    position: 'absolute',
    backgroundColor: '#FFFFFF',
    zIndex: 2,
    '& img': {
      width: 15,
      marginTop: 2,
      marginBottom: 2,
    },
  },
  timelineLine: {
    height: '100%',
    width: 1,
    background: ({ isPassedCondition }: styleProps) =>
      isPassedCondition
      ? '#007994'
      : 'repeating-linear-gradient(to bottom, transparent, transparent 5px, #A9A9B8 5px, #A9A9B8 10px)',
    position: 'absolute',
    top: 10,
    left: 7,
    marginTop: 4,
  },
  historyConditionItemData: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 8,
    marginLeft: -8,
    marginTop: -10,
    marginBottom: 16,
    width: 'calc(100% + 8px)',
    border: '1px solid rgba(38, 40, 66, 0.12)',
    borderRadius: 8,
  },
  historyConditionItemDataFutureStep: {
    '& p, & h6': {
      color: '#A9A9B8 !important',
    },
  },
  conditionName: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 12,
    color: '#262842',
  },
  conditionDescription: {
    marginTop: 4,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    color: '#262842',
    '& span': {
      fontWeight: 500
    }
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  conditionToggleLabel: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    color: '#A9A9B8',
  },
  conditionToggleButtons: {
    marginLeft: 12,
    height: 24,
    '& button': {
      height: 24,
      minWidth: 20,
      padding: spacing(2, 3),
    },
    '& button:first-child': {
      borderTopLeftRadius: 6,
      borderBottomLeftRadius: 6,
    },
    '& button:last-child': {
      borderTopRightRadius: 6,
      borderBottomRightRadius: 6,
    },
  },
  conditionToggleActiveButton: {
    background: 'rgba(38, 40, 66, 0.08)',
  },
  conditionBranchWrapper: {
    marginTop: -16,
    '&:first-child': {
      marginTop: '0 !important'
    }
  }
}));
