import React, { memo, useMemo } from 'react';
import { Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import isNil from 'lodash/isNil';

import useStyles from '../../useStyles';

import { CURRENCY_SYMBOLS } from './constants';

type Props = {
  name?: string;
  hint?: string;
  currencyFieldName?: string;
  isTaskDetailsVariant?: boolean;
  [key: string]: unknown;
};

export const ReadOnlyAmountAndCurrency = memo(({
  hint,
  name,
  currencyFieldName = 'currency',
  isTaskDetailsVariant = false,
}: Props) => {
  const classes = useStyles();
  const {t} = useTranslation();

  const {
    register,
    watch
  } = useFormContext();

  const amount = watch(name) || '';
  const currency = watch(currencyFieldName) || '';

  const text = useMemo(() => {
    if (amount || +amount === 0) {
      const currencyValue =
        Object.keys(CURRENCY_SYMBOLS).includes(currency) ?
          CURRENCY_SYMBOLS[currency] :
          currency;

      return (
        <>
          {currencyValue !== currency && <>{currencyValue}</>}
          <NumberFormat
            value={+amount}
            displayType="text"
            thousandSeparator=" "
            decimalSeparator="."
            fixedDecimalScale={false}
            decimalScale={2}
          />
          {currencyValue === currency && <>{currencyValue}</>}
        </>
      );
    }

    return '';
  }, [amount, currency]);

  if (isNil(amount)) {
    return <div>
      <Typography>{hint}</Typography>
      <Typography className={classes.emptyValueMessage}>
        {t('form_components.readOnly.emptyValue')}
      </Typography>
    </div>;
  }

  return (
    <div>
      <Typography>{hint}</Typography>
      <Typography className={cn({
        [classes.taskDetailsReadOnly]: isTaskDetailsVariant,
      })}>{text}</Typography>

      <input type="hidden" data-selenium={name} {...register(name)} />
    </div>
  );
});
