import React, { useCallback, useMemo, useState } from 'react';

import classNames from 'classnames';
import { LinearProgress, ThemeProvider, Theme, StyledEngineProvider, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useTranslation } from 'react-i18next';

import { theme } from '@dartech/dms-ui';
import { Styled } from './attachment-item.styles';
import { AttachmentItemType } from 'attachments-types';

import attachmentErrorIconImage from 'assets/images/emojis/attachmenterror.png';
import AttachmentPreview from 'components/Attachments/AttachmentPreview';
import closeIconImage from 'assets/images/emojis/close.png';
import { FILENAME_DISPLAY_LENGTH, iconsMap } from 'components/Attachments/constants';
import FileView from '../FileView';
import { VariantSelect } from '../attachments-list/attachments-list.component';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface AttachmentsItemProps {
  attachment: AttachmentItemType;
  onClick?: (attachment: AttachmentItemType) => void | undefined;
  onDelete?: (attachment: any) => void | undefined;
  onUndo?: (attachment: any) => void | undefined;
  readOnly?: boolean;
  variant?: VariantSelect;
}

const Loader = withStyles((theme) => ({
  root: {
    height: 4,
    borderRadius: 0,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[100],
  },
  bar: {
    borderRadius: 0,
    backgroundColor: '#6787e3',
  },
}))(LinearProgress);

const AttachmentItem: React.FC<AttachmentsItemProps> = ({
  attachment,
  variant = 'big',
  readOnly = false,
  onClick = undefined,
  onDelete = undefined,
  onUndo = undefined,
}) => {
  const { t } = useTranslation();
  const { id, assignee, creationDate, error, filename, isDeleted, isLoading, loadingProgress, size, step } = attachment;
  const [isPreviewFileModalOpen, setIsPreviewFileModalOpen] = useState<boolean>(false);

  const localizedTexts = {
    translatedDownloadButtonText: t('buttons.download'),
    translatedFileSizes: {
      byte: t('units.b'),
      kilobyte: t('units.kb'),
      megabyte: t('units.mb'),
      gigabyte: t('units.gb'),
      terabyte: t('TB'),
      petabyte: t('PB'),
      exabyte: t('EB'),
      zettabyte: t('ZB'),
      yottabyte: t('YB'),
    },
    translatedMenuCopyButtonText: t('buttons.copy'),
    translatedMenuDeleteButtonText: t('buttons.delete'),
    translatedMenuMoveToButtonText: t('buttons.moveTo'),
    translatedMenuRenameButtonText: t('buttons.rename'),
    translatedPreviewNotAvailableMessage: t('errors.previewNotAvailable'),
    translatedShareButtonText: t('buttons.share'),
    translatedUnexpectedErrorMessageWhileLoading: t('errors.unexpectedErrorWhileLoadingFile'),
    translatedNotSupportedFileMessage: t('errors.notSupportedFile'),
  };
  const getFileType = (filename: string) => filename.split('.').pop();
  const fileInfo = { fileId: String(id), size: size, fileName: filename, format: getFileType(filename) };

  const title = useMemo(() => {
    if (!filename) {
      return '';
    }
    if (filename.length <= FILENAME_DISPLAY_LENGTH) {
      return filename;
    }

    const dotIndex = filename.lastIndexOf('.');
    const name = filename.substring(0, dotIndex);
    const fileType = filename.substring(dotIndex + 1);
    const visibleNameLength = FILENAME_DISPLAY_LENGTH - 1 - fileType.length;
    const visibleNamePart = name.substring(0, visibleNameLength - 3) + '...';
    return `${visibleNamePart}.${fileType}`;
  }, [attachment]);

  const fileSize = useMemo(() => {
    if (!size) return null;
    const k = 1000;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }, [attachment]);

  const handleClick = useCallback(() => {
    if (isLoading || isDeleted || !onClick) return;
    onClick(attachment);
  }, [onClick, attachment]);

  const handleDelete = useCallback(() => {
    if (onDelete) {
      onDelete(attachment);
      if (isPreviewFileModalOpen) setIsPreviewFileModalOpen(false);
    }
  }, [isPreviewFileModalOpen, onDelete, attachment]);

  const handleUndo = useCallback(() => {
    onUndo && onUndo(attachment);
  }, [onUndo, attachment]);

  const openAttachmentPreview = useCallback(() => {
    setIsPreviewFileModalOpen(true);
  }, []);

  const attachmentIcon = useMemo(() => {
    if (error) return attachmentErrorIconImage;
    if (!filename) return iconsMap.default;
    const fileType = getFileType(filename);
    if (iconsMap[fileType]) return iconsMap[fileType];
    return iconsMap.default;
  }, [attachment]);

  const AttachmentItemBig = () => {
    return (
      <div className="AttachmentItem">
        {!readOnly && <img className="CloseIcon" src={closeIconImage} alt="close icon" onClick={handleDelete} />}

        <div className="ContentWrapper" onClick={handleClick}>
          <div className="AttachmentItemIconWrapper">
            <img className="AttachmentIcon" src={attachmentIcon} alt="attachment icon" />
          </div>

          {isLoading && <Loader variant="determinate" value={(loadingProgress || 0) * 100} />}

          <div className="AttachmentItemTitleWrapper">
            <span className="AttachmentItemTitle">
              {title}
              {fileSize && <span className="AttachmentItemSize"> ({fileSize})</span>}
            </span>
          </div>
        </div>

        {error && <Typography className="AttachmentError">{error}</Typography>}
      </div>
    );
  };

  const AttachmentItemSmall = () => {
    return (
      <div
        className={classNames('AttachmentItemCompact', {
          AttachmentItemCompactDashed: variant === 'compact-dashed',
          AttachmentItemCompactDashedReadonly: readOnly,
        })}
      >
        {isDeleted ? (
          <div className="DeletedFileContentWrapper">
            {t('form_components.attachment.deleted_file')}{' '}
            <span className="UndoButton" onClick={handleUndo}>
              {t('form_components.attachment.undo')}
            </span>
          </div>
        ) : (
          <FileView
            key={id}
            assignee={assignee}
            attachmentIcon={attachmentIcon}
            creationDate={creationDate}
            fileSize={fileSize}
            handleDelete={handleDelete}
            handleDownloadFile={handleClick}
            isLoading={isLoading}
            openAttachmentPreview={openAttachmentPreview}
            readOnly={readOnly}
            step={step}
            title={title}
            progress={(loadingProgress || 0) * 100}
          />
        )}
      </div>
    );
  };

  return (
    <>
      <AttachmentPreview
        deleteActionHandler={handleDelete}
        fileInfo={fileInfo}
        handleDownloadClick={handleClick}
        isPreviewFileModalOpen={isPreviewFileModalOpen}
        localizedTexts={localizedTexts}
        readOnly={readOnly}
        setIsPreviewFileModalOpen={setIsPreviewFileModalOpen}
      />
      <Styled.AttachmentItem>{variant === 'big' ? <AttachmentItemBig /> : <AttachmentItemSmall />}</Styled.AttachmentItem>
    </>
  );
};

export default AttachmentItem;
