import { HcmsUserModel } from './user.types';

export enum StatusType {
  PENDING = 'pending',
  RESOLVED = 'resolved',
  REJECTED = 'rejected',
}

export type CompanyInfo = {
  bin: string;
  createdAt: string;
  id: string;
  isHolding: string;
  name: string;
  shortName: string;
};

export type CompanyPermission = {
  id: string;
  companyId: string;
  role: string;
  permissionSysName: string;
  permissionTitle: string;
  resourceUrl: string;
  description: string;
  descriptionRus: string;
  descriptionKaz: string;
  view: boolean;
  lastEdit: string;
  access: boolean;
  settingsActive: boolean;
};

export type CompanyPermissionInfo = {
  count: number;
  lastEdit: string;
  permissions: CompanyPermission[];
} | null;

export type CompanyProcess = {
  access: boolean;
  companyId: string;
  id: string;
  lastEdit: string;
  role: string;
  settingsActive: boolean;
  startProcessId: string;
};

export type CompanyProcessesInfo = {
  count: number;
  lastEdit: string;
  processes: CompanyProcess[];
} | null;

export type CompanyRoleInfo = {
  companyId: string;
  roleName: string;
  lastEdit: string | null;
  permissionFullInfo: CompanyPermissionInfo;
  processesFullInfo: CompanyProcessesInfo;
  usersCount: number;
};

export type CompanyUserInfo = {
  companyId: string;
  lastEdit: string;
  employeeInfo: HcmsUserModel;
  personalPermissionInfo: CompanyPermissionInfo;
  personalProcessesInfo: CompanyProcessesInfo;
};

export type UserPermission = {
  active: boolean;
  description: string;
  descriptionKaz: string;
  descriptionRus: string;
  permissionSysName: string;
  permissionTitle: string;
  resourceUrl: string;
  view: boolean;
};

export enum PermissionType {
  Update = 'UPDATE',
  Write = 'WRITE',
  Delete = 'DELETE',
  Read = 'READ',
}

export enum PermissionModule {
  ALL_PROFILES = 'ALL_PROFILES',
  AUTOMATION = 'AUTOMATION',
  CNB_GROUP = 'CNB_GROUP',
  CNB_OWN_GROUP = 'CNB_OWN_GROUP',
  COMPANY_PROFILE = 'COMPANY_PROFILE',
  COUNTERPARTIES = 'COUNTERPARTIES',
  CUSTOM_ROLE = 'CUSTOM_ROLE',
  DASHBOARD = 'DASHBOARD',
  DIRECTORIES = 'DIRECTORIES',
  DOCUMENTS = 'DOCUMENTS',
  EMPLOYEE_CONTRACT = 'EMPLOYEE_CONTRACT',
  EMPLOYEE_PROFILE = 'EMPLOYEE_PROFILE',
  EMPLOYEE_WORKLOAD = 'EMPLOYEE_WORKLOAD',
  FLEXIBLE_SETUP = 'FLEXIBLE_SETUP',
  HOLDING_ADMINISTRATION = 'HOLDING_ADMINISTRATION',
  HOLDING_STRUCTURE = 'HOLDING_STRUCTURE',
  INTEGRATIONS = 'INTEGRATIONS',
  LABELS = 'LABELS',
  LEGAL_FORMS = 'LEGAL_FORMS',
  LMS_COURSE = 'LMS_COURSE',
  LMS_COURSE_ASSIGN = 'LMS_COURSE_ASSIGN',
  LMS_COURSE_HOMEWORK = 'LMS_COURSE_HOMEWORK',
  LMS_COURSE_PROGRAM = 'LMS_COURSE_PROGRAM',
  LMS_COURSE_STATS = 'LMS_COURSE_STATS',
  LMS_COURSE_STATUS = 'LMS_COURSE_STATUS',
  LMS_USER_PROGRESS = 'LMS_USER_PROGRESS',
  MARKETPLACE_PARTNERSHIP = 'MARKETPLACE_PARTNERSHIP',
  MARKETPLACE_PROFILE = 'MARKETPLACE_PROFILE',
  MARKETPLACE_TEAM = 'MARKETPLACE_TEAM',
  ORG_STRUCTURE = 'ORG_STRUCTURE',
  OVERVIEW_PULSE_DELETE = 'OVERVIEW_PULSE_DELETE',
  OVERVIEW_PULSE_PUBLISH = 'OVERVIEW_PULSE_PUBLISH',
  PERMISSIONS = 'PERMISSIONS',
  PROFILE_CONSTRUCTOR = 'PROFILE_CONSTRUCTOR',
  STAFF_LIST = 'STAFF_LIST',
  TEMPLATES = 'TEMPLATES',
  TIMESHEET_ADMINISTRATION = 'TIMESHEET_ADMINISTRATION',
  USER_AND_TEAMS = 'USER_AND_TEAMS',
  WORKSPACE_CALENDAR = 'WORKSPACE_CALENDAR',
}
