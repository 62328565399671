import { useEffect, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  useProcessesListState,
  useAvailableTemplateProcessesListState,
  getProcessesListAction,
  getProcessesGroupsAction,
  getAvailableTemplateProcessesListAction,
} from 'store/requests';
import { useUserProfile, useUsersRole } from 'hooks';

import { ProcessGroupType, ProcessType } from 'types';

const useBpmProcess = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: profileId, companyId } = useUserProfile();
  const {currentUserProcesses} = useUsersRole();

  const { data, loading, error, groups } = useProcessesListState();
  const { data: templateProcessesList } = useAvailableTemplateProcessesListState();

  const processesList = useMemo(() => {
    const activeAvailableTemplateProcesses = templateProcessesList
      ?.filter(({ active }) => !!active)
      .map(process => ({...process, isTemplateProcess: true})) || [];
    const baseProcessesList = data?.map(process => ({ ...process, isTemplateProcess: false })) || [];

    return [...baseProcessesList, ...activeAvailableTemplateProcesses];
  }, [data, templateProcessesList, currentUserProcesses]);

  const groupWithProcesses = useMemo(() =>
      Array.isArray(groups) ?
      groups.map((element: ProcessGroupType) => ({
          ...element,
          processes: Array.isArray(processesList)
                     ? processesList
                       .filter(({ groupSysName }: ProcessType) => groupSysName === element.sysName)
                       .sort((process1, process2) => {
                         const process1LocalizedName = t(`constructor-${process1.processSysName}.name`, { defaultValue: process1.name });
                         const process2LocalizedName = t(`constructor-${process2.processSysName}.name`, { defaultValue: process2.name });
                         return process1LocalizedName.localeCompare(process2LocalizedName);
                       }) : [],
        }),
      ).sort((group1, group2) => group1?.name > group2?.name ? 1 : -1) : [],
    [groups, processesList]);

  const getProcessByName = useCallback((processName: string): ProcessType =>
      processesList.find(({ name }: ProcessType) => processName === name), [processesList]);

  useEffect(() => {
    if (!groups) {
      dispatch(getProcessesGroupsAction());
    }
  }, [groups, dispatch]);

  useEffect(() => {
    dispatch(getProcessesListAction())
  }, [dispatch, companyId, profileId]);

  useEffect(() => {
    dispatch(getAvailableTemplateProcessesListAction());
  }, [dispatch, companyId, profileId]);

  return {
    processes: processesList,
    groups: groupWithProcesses,
    error,
    loading,
    getProcessByName
  };
};

export default useBpmProcess;
