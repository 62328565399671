import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useRequestParams } from 'hooks';
import { setCurrentPage as setApprovalsPage } from 'store/approvals';
import { getMyRequestsAction } from 'store/requests/requests.actions';
import { setRequestListPageAction as setRequestsPage } from 'store/requests';
import { setFilters, useFiltersState } from 'store/search';

import { PageType } from 'types';

type Props = {
  completed: boolean;
  currentPage: number;
  searchValue?: string;
  type?: PageType;
  setBadgeCountDelta?: (value: number) => void;
  closeFilter?: () => void;
};

const deleteEmptyValues = (params) => {
  Object.keys(params).forEach((key) => {
    if ((!params[key] || params[key]?.length === 0) && params[key] !== false) {
      delete params[key];
      return;
    }
  });

  return params;
};

const useContentFilter = ({
  completed,
  currentPage,
  searchValue = '',
  type = PageType.REQUESTS,
  setBadgeCountDelta = (v) => {
    //
  },
  closeFilter,
}: Props) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const filtersState = useFiltersState();
  const prevSearchValue = useRef(searchValue);

  const { updateHistory, urlParams, getRequestParameters } = useRequestParams({
    currentPage: filtersState?.pagination?.page,
    completed,
    pageSize: filtersState?.pagination?.size,
  });

  const [filterParams, setFilterParams] = useState<any>({});
  const [keepLegalEntityValue, setKeepLegalEntityValue] = useState(false);

  useEffect(() => {
    if (prevSearchValue.current !== searchValue) {
      submitFilter(filterParams);
    }
  }, [searchValue, filterParams]);

  useEffect(() => {
    if (searchValue !== prevSearchValue.current) {
      prevSearchValue.current = searchValue;
    }
  }, [searchValue]);

  useEffect(() => {
    if (keepLegalEntityValue) {
      setBadgeCountDelta(1);
    } else {
      setBadgeCountDelta(0);
    }
  }, [keepLegalEntityValue, setBadgeCountDelta]);

  const isReviewedApprovalsPage = useMemo((): boolean => ['approvals', 'reviewed'].every((page) => pathname.includes(page)), [pathname]);

  const { reset, getValues, watch, control, handleSubmit, setValue } = useForm({});

  const submitFilter = (filterParamsArg): any => {
    const {
      assignee,
      initiator,
      fromDate,
      author,
      tillDate,
      legalEntity,
      processDefinitionName,
      urgent,
      pagination = {
        size: 10,
        page: 0,
        sort: 'taskStartDate,DESC',
      },
      searchText,
      ...parameters
    } = filterParamsArg;

    setFilterParams(filterParamsArg);

    if (type === PageType.APPROVALS) {
      dispatch(
        setApprovalsPage({
          page: 1,
          needRefresh: false,
        })
      );
    }

    if (type === PageType.REQUESTS) {
      dispatch(
        setRequestsPage({
          page: 1,
          needRefresh: false,
        })
      );
    }

    const p = getRequestParameters(urlParams);

    const params = deleteEmptyValues({
      ...parameters,
      ...p,
      legalEntity: legalEntity?.shortName,
      processDefinitionName: processDefinitionName || [],
      assignee: assignee || [],
      initiator: initiator || [],
      author: author || [],
      fromDate: fromDate?.toISOString() || '',
      tillDate: tillDate?.toISOString() || '',
      urgent: urgent ? urgent : null,
      searchText: searchValue || searchText,
      pagination: filtersState?.pagination || pagination,
    });

    dispatch(setFilters(params));

    if (type === PageType.REQUESTS) {
      const myRequestsParams = {
        ...params,
        taskCompleted: completed,
        completed: completed,
        pending: !completed,
        archive: false,
      };
      dispatch(setFilters(myRequestsParams));
      dispatch(getMyRequestsAction(myRequestsParams));
    }

    updateHistory(deleteEmptyValues(params));
  };

  const resetForm = useCallback(
    (formValues, checkEmptiness = true, shouldUpdateHistory = true): void => {
      if (checkEmptiness) {
        let isFormEmpty = true;
        for (const field of Object.keys(formValues)) {
          if (!(field === 'legalEntity' && keepLegalEntityValue) && formValues[field]) {
            isFormEmpty = false;
          }
        }

        if (isFormEmpty) {
          return;
        }
      }

      if (type === PageType.APPROVALS) {
        dispatch(
          setApprovalsPage({
            page: 1,
            needRefresh: false,
          })
        );
      }

      if (type === PageType.REQUESTS) {
        dispatch(
          setRequestsPage({
            page: 1,
            needRefresh: false,
          })
        );
      }

      const emptyValues = {
        assignee: [],
        initiator: '',
        legalEntity: '',
        processDefinitionName: [],
        searchText: '',
        fromDate: null,
        tillDate: null,
        urgent: false,
      };

      const historyValues = {
        ...emptyValues,
      };

      if (keepLegalEntityValue && watch('legalEntity')?.shortName) {
        emptyValues['legalEntity'] = watch('legalEntity');
        historyValues['legalEntity'] = watch('legalEntity')?.shortName;
      }

      if (shouldUpdateHistory) {
        updateHistory(deleteEmptyValues(historyValues));
      }

      dispatch(setFilters({ ...filtersState, archive: false }));
      reset(emptyValues);
    },
    [reset, updateHistory, currentPage, keepLegalEntityValue, watch]
  );

  useEffect(() => {
    resetForm({}, false, true);
    closeFilter && closeFilter();
  }, [pathname]);

  return {
    control,
    watch,
    isReviewedApprovalsPage,
    handleSubmit,
    submitFilter,
    resetForm,
    getValues,
    setKeepLegalEntityValue,
    setValue,
  };
};

export default useContentFilter;
