import React, { memo, useMemo, ReactElement, useEffect } from 'react';

import { Card, Box, Checkbox, ButtonBase, Typography, Tooltip, Button, CircularProgress } from '@mui/material';
import { useTranslation } from "react-i18next";
import cn from 'classnames';
import shave from 'shave';
import isNil from 'lodash/isNil';

import { HtmlParser, TaskStatusIndicator, Spinner } from 'components';

import { useVisiblePanel } from "store/approvals";
import { useHoldingTranslationsLoadedState } from 'store/main';
import { useUsersRole } from 'hooks';

import { TaskRequestType, TaskStatusesEnum } from 'types';
import { MASS_ACTIONS_VALIDATION_RESULTS, TicketDataType } from './ticket.types';
import { useTicket } from './useTicket';

import {
  COMMENTS_COUNTER_CLASS,
  ATTACHMENTS_COUNTER_CLASS,
  TRUNCATED_FIELD_TYPES,
  MASS_ACTIONS_VALIDATION_ERRORS,
} from './constants';
import useStyles from './useStyles';

import commentsIcon from 'assets/images/icons/comments-ticket-icon.svg';
import AttachmentsIcon from 'assets/images/icons/attachments-icon-new.svg';
import SignatureIcon from 'assets/images/icons/signature_regular.svg';
import PriorityHigh from 'assets/images/icons/priority-high.svg';
import PriorityMedium from 'assets/images/icons/priority-medium.svg';
import { ReactComponent as CheckboxUncheckedIcon } from 'assets/images/icons/checkbox-unchecked.svg';
import { ReactComponent as CheckboxCheckedIcon } from 'assets/images/icons/checkbox-checked.svg';
import ClockIconOverdue from 'assets/images/icons/clock-icon-overdue.svg';
import ClockIconNormal from 'assets/images/icons/clock-icon-grey.svg';
import { limitStringLength } from 'utils/user';
import { numberWithSpaces } from 'utils/general';

type TicketProps = {
  request: TaskRequestType;
  type?: 'myRequest' | 'completedRequest' | 'reviewedApproval' | 'approval' | 'draft' | 'watchers';
  selected?: {
    [taskId: string]: {
      processDefinitionName: string
      processStep: string
      taskId: string
    }
  };
  active?: boolean;
  onClick?: () => void;
  setMassApprove?: (ticket: TicketDataType) => void;
  selectTicket?: (ticket: TicketDataType) => void;
  className?: string;
};

export const taskStatuses = {
  InProgress: {
    color: '#497CF6',
    translationKey: 'form_components.votingResults.inProgress',
  },
  Completed: {
    color: '#2DB77B',
    translationKey: 'task_statuses.completed',
  },
  Rework: {
    color: '#FFA530',
    translationKey: 'task_statuses.rework',
  },
  Reject: {
    color: '#D6331F',
    translationKey: 'task_statuses.reject',
  },
  Canceled: {
    color: '#D6331F',
    translationKey: 'task_statuses.canceled',
  },
  Draft: {
    color: '#8B8C9E',
    translationKey: 'task_statuses.draft',
  },
};

export const Ticket = memo(({
  request,
  type = 'myRequest',
  setMassApprove,
  selectTicket,
  selected,
  active = false,
  onClick,
  className,
}: TicketProps): ReactElement => {
  const { t } = useTranslation();
  const isPanelVisible = useVisiblePanel();
  const holdingTranslationsLoaded = useHoldingTranslationsLoadedState();
  const { hasAccessToMassActions } = useUsersRole();

  const {
    loading,
    error,
    ticketData,
    handleClick,
    handleCommentsCounterClick,
    handleAttachmentsCounterClick,
    handleTakeOnTask,
    takeOnTaskLoading
  } = useTicket({
    request,
    type,
    setMassApprove,
    onClick,
  });

  const processTitle = useMemo((): string => {
    if (!(ticketData?.processName || ticketData?.processDisplayName)) {
      return "";
    }

    return ticketData?.processDisplayName || ticketData?.processName;
  }, [ticketData?.processName, ticketData?.processDisplayName]);

  const isMassApproveAvailable = useMemo((): boolean =>
      ['approval', 'draft'].includes(type) && isPanelVisible && ticketData?.massApproveAvailable,
    [isPanelVisible, type, ticketData]);

  const classes = useStyles({isMassApproveAvailable});

  const isTicketCheckboxChecked = useMemo((): boolean =>
    selected && Boolean(selected[ticketData?.id]),
    [selected, ticketData?.id]);

  const status = useMemo(() => {
    const taskStatus = type === "draft" ? TaskStatusesEnum.Draft : request.businessTask.taskStatus
    if(!taskStatus) return null;

    return <Box display="flex" alignItems="center">
          <Box className={classes.statusIcon} bgcolor={taskStatuses[taskStatus]?.color}></Box>
          <Typography>{t(taskStatuses[taskStatus]?.translationKey)}</Typography>
      </Box>
  }, [classes.statusIcon, request.businessTask.taskStatus, t])

  const priority = useMemo(() => {
    if (!request.urgent) return;

    return (
      <Box display="flex" alignItems="center" gap={1}>
        <img className={classes.priorityIcon} src={request.urgent.urgent ? PriorityHigh : PriorityMedium} alt="" />
        <Typography>
          {request.urgent.urgent ? t("form_components.urgent.high") : t("form_components.urgent.medium")}
      </Typography>
      </Box>
    );
  }, [classes.priorityIcon, request.urgent, t])

  const massActionValidationError = useMemo(() => {
    if (!isPanelVisible || !hasAccessToMassActions || !ticketData?.massApproveCheckboxDisabled) {
      return null;
    }

    if (ticketData?.massActivationValidationErrorType === MASS_ACTIONS_VALIDATION_RESULTS.NEED_TAKE_ON_TASK) {
      return <Box className={classes.cardValidationMessageWrapper}>
        <span>{t(MASS_ACTIONS_VALIDATION_ERRORS[ticketData?.massActivationValidationErrorType])}</span>
        <Button
          className={classes.validationErrorButton}
          key={'take_on_task'}
          color="secondary"
          onClick={event => {
            event.stopPropagation();
            handleTakeOnTask();
          }}
        >
          {takeOnTaskLoading && (
          <Box display="flex" alignItems="center" mr={2}>
            <CircularProgress style={{ 'color': 'white' }} size={16}/>
          </Box>
        )}
          <Typography>{t('buttons.button_take_on_a_task')}</Typography>
        </Button>
      </Box>;
    }

    return <Box className={classes.cardValidationMessageWrapper}>
      {t(MASS_ACTIONS_VALIDATION_ERRORS[ticketData?.massActivationValidationErrorType])}
    </Box>
  }, [ticketData, t, hasAccessToMassActions, isPanelVisible])

  useEffect(() => {
    if (holdingTranslationsLoaded) {
      setTimeout(() => {
        shave(".summary-field-hint", 40, { spaces: false, character: "...:" });
      }, 100)
    }
  }, [error, loading, ticketData, holdingTranslationsLoaded]);

  const processInitiator = useMemo(() => limitStringLength(ticketData?.initiator ?? '', 25), [ticketData]);

  const renderContent: ReactElement = useMemo((): ReactElement => {
    if (loading) {
      return <Spinner/>;
    }

    if (error) {
      return <div>{error}</div>;
    }

    if (ticketData) {
      return (
        <Box className={classes.cardWrapper} onClick={handleClick}>
          {massActionValidationError}

          <Box className={classes.cardInnerWrapper}>

            {ticketData.isSignatureStep && <div className={classes.ticketChip}>
              <img src={SignatureIcon} alt={'Signature Icon'}/>
              <span>{t('task_data_view.signatureStep')}</span>
            </div>}

            <Box className={classes.cardContent}>
              <Box display="flex" alignItems="center" mb={2}>
                {ticketData.iconPath && (
                  <img
                    src={ticketData.iconPath}
                    className={classes.cardContentHeaderIcon}
                    alt=""
                  />
                )}

                <Tooltip title={processTitle} classes={{
                  popper: classes.cardTooltipPopper,
                  tooltip: cn(classes.cardTooltip, classes.cardTooltipLongText),
                }}>
                  <Typography className={classes.cardContentHeaderTitle}>{processTitle}</Typography>
                </Tooltip>
              </Box>

              <Box mt={1} minHeight={20}>
                {ticketData.summaryType === 'description' && <div className={classes.processDescription}>
                  <Tooltip title={ticketData.processDescription} placement="bottom" arrow enterDelay={500} classes={{
                    popper: classes.cardTooltipPopper,
                    tooltip: cn(classes.cardTooltip, classes.cardTooltipLongText),
                  }}>
                    <span>{ticketData.processDescription}</span>
                  </Tooltip>
                </div>}

                {ticketData.summaryType === 'html' &&
                  <div className={classes.summaryHtmlWrapper}><HtmlParser code={ticketData.summary}/></div>}

                {ticketData.summaryType === 'fields' && ticketData.summaryFields &&
                  <div className={classes.summaryFieldsWrapper}>
                    {ticketData.summaryFields.map(({ hint, value, fieldType, valuesCount }) => {
                      let v;
                      try {
                        if (Number(value) % 1 === 0) {
                          v = String(value).split('.')[0];
                        } else {
                          v = value;
                        }
                      } catch (e) {
                        console.error(e);
                      }
                      return (
                        <div className={classes.summaryField}>
                          <Tooltip title={hint} placement="bottom" arrow enterDelay={500}
                                  classes={{ popper: classes.cardTooltipPopper, tooltip: classes.cardTooltip }}>
                            <div className={classes.summaryFieldHintWrapper}>
                              <span className={cn('summary-field-hint', classes.summaryFieldHint)}>
                                {hint}:
                              </span>
                            </div>
                          </Tooltip>

                          {TRUNCATED_FIELD_TYPES.includes(fieldType) && !!value
                          ?
                          <Tooltip title={value} placement="bottom" arrow enterDelay={500} classes={{
                            popper: classes.cardTooltipPopper,
                            tooltip: cn(classes.cardTooltip, classes.cardTooltipLongText),
                          }}>
                            <span className={cn(classes.summaryFieldValue, { [classes.summaryFieldEmptyValue]: !value })}>
                              {(!isNil(v) && !!v) || v === -1 ? v : t('form_components.readOnly.emptyValue')}
                            </span>
                          </Tooltip>
                          :
                          <span
                            className={cn(classes.summaryFieldValue, { [classes.summaryFieldEmptyValue]: !value })}>
                              {(!isNil(v) && !!v) || v === -1 ? v : t('form_components.readOnly.emptyValue')}
                            {valuesCount > 1 ? <span
                              className={classes.summaryFieldValueCount}>{' '}(+{valuesCount - 1})</span> : null}
                          </span>
                          }
                        </div>
                      )
                  })}
                  </div>
                }
              </Box>

              <Box display="flex" flexDirection="column">
                {ticketData.urgent && ticketData.reasonOfUrgent && (
                  <Typography className={cn(classes.cardContentFooterTitle, classes.cardContentUrgentTitle)}>
                    {t('form_components.urgent.title')}: <span>{ticketData.reasonOfUrgent}</span>
                  </Typography>
                )}

                <Box display="flex" gap={8} pt={2}>
                  {priority}
                  {status}
                </Box>

                <Box display="flex" alignItems="center" pt={2}>
                  <Typography className={classes.cardContentFooterTitle}>{processInitiator}</Typography>

                  {/*
                COMMENT_COUNTER_CLASS and ATTACHMENTS_COUNTER_CLASS must be on every element of counters
                to correctly open corresponding tab when counter is clicked
                */}

                  <ButtonBase
                    className={cn(classes.cardContentFooterButton, COMMENTS_COUNTER_CLASS, { [classes.unreadIcon]: ticketData.unreadComments })}
                    onClick={handleCommentsCounterClick}
                  >
                    <img
                      className={cn(classes.cardContentFooterIcon, COMMENTS_COUNTER_CLASS)}
                      src={commentsIcon}
                      alt="comments"
                    />

                    <Typography className={cn(classes.cardContentFooterTitle, COMMENTS_COUNTER_CLASS)}>
                      {ticketData.commentsSize}
                    </Typography>
                  </ButtonBase>

                  <ButtonBase
                    className={cn(classes.cardContentFooterButton, ATTACHMENTS_COUNTER_CLASS, { [classes.unreadIcon]: ticketData.unreadAttachments })}
                    onClick={handleAttachmentsCounterClick}
                  >
                    <img
                      className={cn(classes.cardContentFooterIcon, ATTACHMENTS_COUNTER_CLASS)}
                      src={AttachmentsIcon}
                      alt="attachments"
                    />

                    <Typography className={cn(classes.cardContentFooterTitle, ATTACHMENTS_COUNTER_CLASS)}>
                      {ticketData.attachmentCount}
                    </Typography>
                  </ButtonBase>

                  <Box className={classes.cardContentDueDate}>
                    <img
                      className={cn(classes.cardContentFooterIcon)}
                      src={ticketData.isTimerOver ? ClockIconOverdue : ClockIconNormal}
                      alt=""
                    />
                    <Typography className={cn(classes.cardContentFooterTitle, COMMENTS_COUNTER_CLASS, {[classes.cardContentFooterTitleOverdue]: ticketData.isTimerOver})}>
                      {
                        ticketData.isTimerOver
                        ? t('task_data_view.timerOverdue')
                        : t('task_data_view.timerDueDate')
                      }: {ticketData.formattedDeadline || '-'}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box className={classes.cardStatus}>
              <TaskStatusIndicator
                step={ticketData.stepNumber}
                processSysName={ticketData.processSysName}
                parallelBranchesName={ticketData.parallelBranchesName}
                parallelBranchesStepperOrder={ticketData.parallelBranchesStepperOrder}
                totalSteps={ticketData.stepsTotal}
                isCompleted={ticketData.completed}
                isCancelled={ticketData.cancelled}
                isRejected={ticketData.reject}
                stepsList={ticketData.stepsList}
                hasCondition={ticketData.hasCondition}
              />
            </Box>
          </Box>
        </Box>
      );
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, loading, ticketData, massActionValidationError]);

  return (
    <Box className={cn(
    classes.wrapper,
    className,
    {
      [classes.wrapperDraft]: type === "draft"
    }
    )}
    >
      {isMassApproveAvailable && (
        <Checkbox
          icon={<CheckboxUncheckedIcon/>}
          checkedIcon={<CheckboxCheckedIcon/>}
          color="default"
          className={cn(classes.checkbox)}
          classes={{disabled: classes.checkboxDisabled, checked: classes.checkboxChecked}}
          disabled={ticketData?.massApproveCheckboxDisabled}
          checked={isTicketCheckboxChecked}
          onChange={() => selectTicket(ticketData)}
        />
      )}

      <Card
        className={cn(classes.card, {
          [classes.cardActive]: active,
          [classes.cardUrgent]: ticketData?.urgent,
          [classes.cardUnread]: ticketData?.unread,
          [classes.cardDraft]: type === 'draft',
        })}
        elevation={0}
      >
        {renderContent}
      </Card>
    </Box>
  );
});
