import React, {ReactElement, useMemo} from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, IconButton, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import cn from "classnames";
import { useTranslation } from 'react-i18next';

import {ErrorMessage, Spinner} from "components";

import {useTemplateIconSelectButton} from "./useTemplateIconSelectButton";
import {useProcessIconList} from "store/requests";
import {StatusType} from "types";

import useStyles from './TemplateIconSelectButton.useStyles';

interface TemplateIconSelectDialogProps {
  activeIcon: string | null;
  handleIconSelect: (processIcon: string) => void;
  error: boolean;
}

export const TemplateIconSelectButton = ({
  activeIcon,
  handleIconSelect,
  error = false
}: TemplateIconSelectDialogProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    data: processIcons,
    status,
    errorMessage
  } = useProcessIconList()

  const {
    isDialogOpen,
    selectedProcessIcon,
    handleDialogOpen,
    handleDialogClose,
    handleProcessIconSelect
  } = useTemplateIconSelectButton({
    activeIcon,
    handleIconSelect
  });

  const isButtonDisabled = useMemo((): boolean =>
    status === StatusType.PENDING,
    [status]);

  const dialogContent = useMemo((): ReactElement => {
    switch (status) {
      case StatusType.RESOLVED: {
        return (
          <Box className={classes.dialogContentGrid}>
            {processIcons.map((processIcon: string): ReactElement => (
              <Button
                className={cn(classes.dialogContentButton, {
                  [classes.dialogContentButtonActive]: selectedProcessIcon === processIcon
                })}
                onClick={() => handleProcessIconSelect(processIcon)}
              >
                <img src={processIcon} alt="" />
              </Button>
            ))}
          </Box>
        );
      }
      case StatusType.REJECTED: {
        return (
          <ErrorMessage text={errorMessage} />
        );
      }
      case StatusType.PENDING:
      default: {
        return (
          <Spinner absolute={false} />
        );
      }
    }
  }, [processIcons, selectedProcessIcon, errorMessage, status]);

  return (
    <>
      <Button
        // className={cn(classes.dialogContentButton, classes.dialogContentButtonPrimary, classes.currentIconButton)}
        className={cn(classes.currentIconButton, {[classes.iconButtonWithError]: error})}
        disabled={isButtonDisabled}
        onClick={handleDialogOpen}
      >
        {isButtonDisabled ?
          <Spinner absolute={false} size={24} /> :
          <img src={activeIcon} />
        }
      </Button>

      <Dialog open={isDialogOpen} onClose={handleDialogClose} classes={{ paper: classes.paper }}>
        <Box className={classes.dialogHeader}>
          <Typography className={classes.dialogHeaderTitle}>{t('customProcesses.creationPage.iconSelectModal.title')}</Typography>

          <IconButton
            type="button"
            size="small"
            onClick={handleDialogClose}
          >
            <CloseIcon
              fontSize="small"
              className={classes.dialogHeaderButtonIcon}
            />
          </IconButton>
        </Box>

        <DialogContent className={classes.dialogContent}>
          {dialogContent}
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleDialogClose}
          >
            {t('customProcesses.creationPage.buttons.cancel')}
          </Button>

          <Button
            variant="contained"
            color="primary"
            disabled={!selectedProcessIcon}
            onClick={() => {
              handleIconSelect(selectedProcessIcon);
              handleDialogClose();
            }}
          >
            {t('customProcesses.creationPage.buttons.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
