import React, { memo } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Box,
} from '@mui/material';
import cn from 'classnames';

import { FormField } from 'pages/Task/TaskForm/FormFields';
import { FormButtonList } from 'pages/Task/TaskForm/ActionsBar/FormButtonList';

import { TaskParametersType } from 'types';

import useStyles from './useStyles';
import { Spinner } from 'components';

type Props = {
  color?: 'info' | 'danger' | 'neutral';
  open: boolean;
  title: string;
  subtitle: string;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  loading?: boolean;
  closeIcon?: string;
  fullScreen?: boolean;
  buttonList?: TaskParametersType[];
  childrenAttributes?: any[];
  onClose: () => void;
  handleAction: (params: {
    action: string;
    tplName?: string;
    componentParams?: { [key: string]: any };
    instanceFormFieldName?: string;
    instance?: any;
    validationAttributeList?: TaskParametersType[];
  }) => void;
};

export const DialogModal = memo(
  ({
    color = 'info',
    title,
    subtitle,
    open = false,
    maxWidth = false,
    fullScreen = false,
    loading = false,
    closeIcon,
    buttonList = [],
    childrenAttributes = [],
    onClose,
    handleAction,
  }: Props) => {
    const classes = useStyles();

    const headerBackgroundClass = cn({
      [classes.wrapper]: true,
      [classes.wrapperColorInfo]: color === 'info',
      [classes.wrapperColorDanger]: color === 'danger',
      [classes.wrapperColorNeutral]: color === 'neutral',
    });

    return (
      <Dialog
        open={open}
        fullScreen={fullScreen}
        onClose={onClose}
        fullWidth={!!maxWidth}
        maxWidth={maxWidth}
        hideBackdrop={true}
      >
        <Box component="header" className={headerBackgroundClass}>
          <Box display="flex" flexDirection="column">
            <Typography variant="h3" className={classes.headerTitle}>
              {title}
            </Typography>

            {subtitle && (
              <Box mt={2}>
                <Typography>{subtitle}</Typography>
              </Box>
            )}
          </Box>

          <Box pt={1}>
            <IconButton onClick={onClose} size="large">
              <img src={closeIcon} alt="Close" />
            </IconButton>
          </Box>
        </Box>

        {!!childrenAttributes.length && (
          <DialogContent className={classes.content}>
            {loading && (
              <Box zIndex={1400}>
                <Spinner absolute/>
              </Box>
            )}
            {childrenAttributes.map((childrenAttribute) => (
              <FormField
                key={childrenAttribute.id}
                attribute={childrenAttribute}
              />
            ))}
          </DialogContent>
        )}

        {!!buttonList.length && (
          <DialogActions className={classes.actions}>
            <FormButtonList
              disabled={false}
              showButtonsOnBothSides
              existingButtonList={buttonList}
              validationAttributeList={childrenAttributes}
              onClick={handleAction}
            />
          </DialogActions>
        )}
      </Dialog>
    );
  },
  (prevProps: Props, nextProps: Props) =>
    prevProps.color === nextProps.color &&
    prevProps.open === nextProps.open &&
    prevProps.title === nextProps.title &&
    prevProps.subtitle === nextProps.subtitle &&
    prevProps.maxWidth === nextProps.maxWidth &&
    prevProps.closeIcon === nextProps.closeIcon &&
    prevProps.loading === nextProps.loading &&
    prevProps.buttonList?.length === nextProps.buttonList?.length &&
    prevProps.childrenAttributes?.length ===
      nextProps.childrenAttributes?.length
);
