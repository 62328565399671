import { handleActions } from 'redux-actions';
import { FULFILLED, PENDING, REJECTED } from 'store/constants';

import { APPROVALS_ACTIONS } from './approvals.actions';

export const initState = {
  approvalsList: {
    data: null,
    error: '',
    loading: false,
    currentPage: 1,
    pageSize: 10,
    completed: false,
    needRefresh: false,
    reviewed: false,
  },
  watchList: {
    data: {
      content: []
    }
  },
  approvalsCounters: {
    active: -1,
    reviewed: -1,
  },
  selectedApproval: null as string,
  selectedTask: null,
  selectedTaskWatchers: null,
  selectedReviewedCurrentTask: null,
  visiblePanel: false,
  massApproveActionStatus: {
    loading: false,
    percentage: 0,
    statusText: '',
    showErrorIcon: false,
    showSuccessIcon: false
  }
};

type InitState = typeof initState

const {
  CLEAR_SELECTED_APPROVAL,
  CLEAR_SELECTED_REVIEWED_CURRENT_TASK,
  GET_ACTIVE_APPROVALS_COUNT,
  GET_APPROVALS,
  GET_REVIEWED_APPROVALS_COUNT,
  GET_WATCH_LIST,
  REFRESH_APPROVALS,
  SET_ACTIVE_APPROVALS_COUNT,
  SET_APPROVALS_ERROR,
  SET_APROVALS_LIST_REVIEWED,
  SET_CURRENT_PAGE_SIZE,
  SET_CURRENT_PAGE,
  SET_MASS_APPROVE_ACTION_STATUS,
  SET_REVIEWED_APPROVALS_COUNT,
  SET_SELECTED_APPROVAL,
  SET_SELECTED_REVIEWED_CURRENT_TASK,
  TOGGLE_APPROVAL_PANEL,
  SET_SELECTED_TASK,
  SET_SELECTED_TASK_WATCHERS
} = APPROVALS_ACTIONS;

export default handleActions(
  {
    [GET_APPROVALS + PENDING]: (state: InitState) => ({
      ...state,
      approvalsList: {
        ...state.approvalsList,
        loading: true,
      },
    }),
    [GET_APPROVALS + FULFILLED]: (state: InitState, { payload }) => ({
      ...state,
      approvalsList: {
        ...state.approvalsList,
        loading: false,
        data: payload,
      },
    }),
    [GET_APPROVALS + REJECTED]: (state: InitState, { payload }) => ({
      ...state,
      approvalsList: {
        ...state.approvalsList,
        loading: false,
        error: payload?.message || 'errors.somethingIsWrong',
      },
    }),
    [SET_APPROVALS_ERROR]: (state: InitState, { payload }) => ({
      ...state,
      approvalsList: {
        ...state.approvalsList,
        loading: false,
        error: payload,
      },
    }),
    [SET_CURRENT_PAGE]: (state: InitState, { payload }) => ({
      ...state,
      approvalsList: {
        ...state.approvalsList,
        currentPage: payload?.page || payload,
        needRefresh: payload.needRefresh ? !state.approvalsList.needRefresh : state.approvalsList.needRefresh,
      },
    }),
    [SET_CURRENT_PAGE_SIZE]: (state: InitState, { payload }) => ({
      ...state,
      approvalsList: {
        ...state.approvalsList,
        pageSize: payload,
        currentPage: 1,
        needRefresh: !state.approvalsList.needRefresh,
      },
    }),

    [SET_APROVALS_LIST_REVIEWED]: (state: InitState, { payload }) => ({
      ...state,
      approvalsList: {
        ...state.approvalsList,
        reviewed: payload,
        currentPage: 1,
      },
    }),
    [REFRESH_APPROVALS]: (state: InitState, { payload }) => ({
      ...state,
      approvalsList: {
        ...state.approvalsList,
        needRefresh: true,
      },
    }),
    [GET_WATCH_LIST + FULFILLED]: (state: InitState, {payload}) => ({
      ...state,
      watchList: {
        ...state.watchList,
        data: payload
      }
    }),
    [SET_SELECTED_APPROVAL]: (state: InitState, { payload }) => ({
      ...state,
      selectedApproval: payload,
    }),
    [SET_SELECTED_TASK]: (state: InitState, { payload }) => ({
      ...state,
      selectedTask: payload,
    }),
    [SET_SELECTED_TASK_WATCHERS]: (state: InitState, { payload }) => ({
      ...state,
      selectedTaskWatchers: payload,
    }),
    [SET_SELECTED_REVIEWED_CURRENT_TASK]: (state: InitState, { payload }) => ({
      ...state,
      selectedReviewedCurrentTask: payload,
    }),
    [CLEAR_SELECTED_APPROVAL]: (state: InitState) => ({
      ...state,
      selectedApproval: null,
    }),
    [CLEAR_SELECTED_REVIEWED_CURRENT_TASK]: (state: InitState) => ({
      ...state,
      selectedReviewedCurrentTask: null,
    }),
    [TOGGLE_APPROVAL_PANEL]: (state: InitState) => ({
      ...state,
      visiblePanel: !state.visiblePanel,
    }),
    [GET_ACTIVE_APPROVALS_COUNT + FULFILLED]: (state: InitState, { payload }) => ({
      ...state,
      approvalsCounters: {
        ...state.approvalsCounters,
        active: payload
      },
    }),
    [GET_REVIEWED_APPROVALS_COUNT + FULFILLED]: (state: InitState, { payload }) => ({
      ...state,
      approvalsCounters: {
        ...state.approvalsCounters,
        reviewed: payload
      },
    }),
    [SET_ACTIVE_APPROVALS_COUNT]: (state: InitState, { payload }) => ({
      ...state,
      approvalsCounters: {
        ...state.approvalsCounters,
        active: payload
      },
    }),
    [SET_REVIEWED_APPROVALS_COUNT]: (state: InitState, { payload }) => ({
      ...state,
      approvalsCounters: {
        ...state.approvalsCounters,
        reviewed: payload
      },
    }),
    [SET_MASS_APPROVE_ACTION_STATUS]: (state: InitState, { payload }) => ({
      ...state,
      massApproveActionStatus: payload
    }),
  },
  initState
);
