import boldIcon from 'assets/images/icons/editor-button-bold.svg';
import italicIcon from 'assets/images/icons/editor-button-italic.svg';
import strikethroughIcon from 'assets/images/icons/editor-button-strikethrough.svg';
import numberedListIcon from 'assets/images/icons/editor-button-numbered-list.svg';
import markedListIcon from 'assets/images/icons/editor-button-marked-list.svg';
import boldIconActive from 'assets/images/icons/editor-button-bold-active.svg';
import italicIconActive from 'assets/images/icons/editor-button-italic-active.svg';
import strikethroughIconActive from 'assets/images/icons/editor-button-strikethrough-active.svg';
import numberedListIconActive from 'assets/images/icons/editor-button-numbered-list-active.svg';
import markedListIconActive from 'assets/images/icons/editor-button-marked-list-active.svg';
import boldIconDisabled from 'assets/images/icons/editor-button-bold-disabled.svg';
import italicIconDisabled from 'assets/images/icons/editor-button-italic-disabled.svg';
import strikethroughIconDisabled from 'assets/images/icons/editor-button-strikethrough-disabled.svg';
import numberedListIconDisabled from 'assets/images/icons/editor-button-numbered-list-disabled.svg';
import markedListIconDisabled from 'assets/images/icons/editor-button-marked-list-disabled.svg';

export const MENTION_SEARCH_REGEX = /(^|\s|>|(?:&nbsp;))(@[\w|\wЁёА-я|.]*)/g;
export const MENTION_REGEX = /(^|\s|>)(@[\w|\wЁёА-я|.]+)/g;

export const MENTION_REPLACE_TEMPLATE = '$1<b>$2</b>';

export const COMMENTS_TAB_NAME = 'comments';

export const ACTION_LABEL_COLORS = {
  red: 'red',
  blue: 'blue',
  green: 'green',
};

export const STYLE_TYPES = {
  BLOCK: 'BLOCK',
  INLINE: 'INLINE',
};

export const CONTROL_BUTTONS = [
  [
    {
      key: 'BOLD',
      icon: boldIcon,
      iconActive: boldIconActive,
      iconDisabled: boldIconDisabled,
      activeInFocusedEditor: true,
      styleType: STYLE_TYPES.INLINE,
    },
    {
      key: 'ITALIC',
      icon: italicIcon,
      iconActive: italicIconActive,
      iconDisabled: italicIconDisabled,
      activeInFocusedEditor: true,
      styleType: STYLE_TYPES.INLINE,
    },
    {
      key: 'STRIKETHROUGH',
      icon: strikethroughIcon,
      iconActive: strikethroughIconActive,
      iconDisabled: strikethroughIconDisabled,
      activeInFocusedEditor: true,
      styleType: STYLE_TYPES.INLINE,
    },
  ],
  [
    {
      key: 'ordered-list-item',
      icon: numberedListIcon,
      iconActive: numberedListIconActive,
      iconDisabled: numberedListIconDisabled,
      activeInFocusedEditor: true,
      styleType: STYLE_TYPES.BLOCK,
    },
    {
      key: 'unordered-list-item',
      icon: markedListIcon,
      iconActive: markedListIconActive,
      iconDisabled: markedListIconDisabled,
      activeInFocusedEditor: true,
      styleType: STYLE_TYPES.BLOCK,
    },
  ],
];

export const STYLE_MAP = {
  STRIKETHROUGH: {
    textDecoration: 'line-through',
  },
  BLACK: {
    color: '#000000',
  },
  BLUE: {
    color: '#0072B8',
  },
};
