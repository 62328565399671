import makeStyles from '@mui/styles/makeStyles';
import CheckIcon from 'assets/images/icons/check.svg';
import CircIcon from 'assets/images/icons/circ.svg';
import CircActiveIcon from 'assets/images/icons/circ-blue.svg';

export default makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  listItem: {
    borderLeft: '9px solid transparent',
    width: '100%',
    flexWrap: 'wrap',
    color: theme.palette.grey[600],
    padding: theme.spacing(3, 0, 3.5, 14.75),
    '&.Mui-selected': {
      backgroundColor: theme.palette.grey[200],
      borderLeftColor: theme.palette.primary[700],
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      },
      '& span': {
        fontWeight: 600,
      },
      '& $listText': {
        color: theme.palette.grey[800],
        '& i': {
          background: `url(${CircActiveIcon}) no-repeat center`,
        },
      },
    },
    '& a': {
      display: 'block',
    },
  },
  completed: {
    '& $listText': {
      '& i': {
        backgroundImage: `url(${CheckIcon})`,
      },
    },
  },
  skipped: {
    opacity: 0.5,
  },
  listText: {
    fontSize: 16,
    fontWeight: 600,
    width: '100%',
    marginTop: theme.spacing(1),
    position: 'relative',
    minHeight: 20,
    '& i': {
      display: 'block',
      width: 20,
      height: 20,
      position: 'absolute',
      top: 0,
      left: -30,
      borderRadius: '50%',
      backgroundSize: 'contain',
      background: `url(${CircIcon}) no-repeat center`,
    },
  },
}));
