import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { getLegalEntities } from 'api/requests';
import { MultipleSelect } from 'components';
import isArray from 'lodash/isArray';

type Props = {
  label?: string;
  disabled?: boolean;
  onSelect?: (value: string[]) => void;
  defaultValue?: string[];
  selectedValues: string[];
  analyticsFiltersDesignVariant?: boolean;
};

export const SelectMultipleLegalEntities = ({
  label,
  disabled,
  onSelect,
  defaultValue,
  selectedValues,
  analyticsFiltersDesignVariant = false,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [legalEntityList, setLegalEntity] = useState([]);

  const hasValueChanged = useMemo(
    () =>
      isArray(defaultValue) &&
      isArray(selectedValues) &&
      !(
        defaultValue.length === selectedValues.length &&
        selectedValues.every((value, index) => value === defaultValue[index])
      ),
    [defaultValue, selectedValues]
  );

  const getLegalEntityList = useCallback(async () => {
    try {
      setError('');
      setLoading(true);

      const LEParameters = { process: 'Payments' };
      const LEResponse = await getLegalEntities(LEParameters);

      setLegalEntity(LEResponse);
    } catch (error) {
      setLegalEntity([]);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getLegalEntityList();
  }, [getLegalEntityList]);

  const parsedData = useMemo(
    () => (legalEntityList ? legalEntityList.map((v) => v.name) : []),
    [legalEntityList]
  );

  const handleSelect = useCallback(
    (value) => {
      onSelect && onSelect(value);
    },
    [selectedValues]
  );

  return (
    <MultipleSelect
      label={label}
      options={parsedData}
      selectedOptions={selectedValues}
      onSelect={handleSelect}
      disabled={disabled}
      analyticsFiltersDesignVariant={analyticsFiltersDesignVariant}
      hasValueChanged={hasValueChanged}
    />
  );
};
