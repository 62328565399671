import styled from 'styled-components';
import {theme} from '@dartech/dms-ui';

const SelectGlossary = styled.div`
  .switch {
    margin-right: ${theme.spacing(1.5)};
  }
  .label {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    width: auto;
    max-width: 100%;
  }
  .icon {
    width: 24;
    margin-right: ${theme.spacing(1.5)};
  }
  .autoGlossary > div > div > div {
    margin-top: 0;
  }
`

export const Styled = {
  SelectGlossary
};
