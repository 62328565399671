import React from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel } from '@mui/material';

import useUserDropdownStyles from '../UserList.useStyles';
import { ALL_USERS_KEY } from '../constants';

interface InputTopSideProps {
  allowOnlyOneDepartment?: boolean;
  allowOnlyOneUser?: boolean;
  displaySelectedUsers: string;
  handleSelectAll: () => void;
  isUserSelectInCurrentDepartmentAllowed: boolean;
  selectedDepartment: string;
  showAllDepartmentsGroup?: boolean;
  selectAllState: {
    checked: boolean;
    indeterminate: boolean;
  },
}

const InputTopSide = (props: InputTopSideProps) => {
  const {
    allowOnlyOneDepartment = false,
    allowOnlyOneUser = false,
    displaySelectedUsers,
    handleSelectAll,
    isUserSelectInCurrentDepartmentAllowed,
    selectAllState,
    selectedDepartment,
    showAllDepartmentsGroup = false,
  } = props;
  const classes = useUserDropdownStyles({});
  const { t } = useTranslation();

  return (
    <>
      {allowOnlyOneDepartment &&
        <div className={classes.UserDropdownListWarningMessage}>
          {t('form_components.select_users.onlyOneDepartmentAllowed')}
        </div>}

      {!allowOnlyOneUser && !(showAllDepartmentsGroup && selectedDepartment === ALL_USERS_KEY) &&
        (<FormControlLabel
          control={<Checkbox
            color={'default'}
            className={classNames(classes.UserDropdownListCheckbox, classes.checkbox, {
              [classes.checkboxDisabled]: !isUserSelectInCurrentDepartmentAllowed,
              [classes.checkboxChecked]: selectAllState.indeterminate || selectAllState.checked,
            })}
            checked={selectAllState.checked}
            indeterminate={selectAllState.indeterminate && !selectAllState.checked}
          />}
          label={displaySelectedUsers}
          onChange={handleSelectAll}
          disabled={!isUserSelectInCurrentDepartmentAllowed}
          className={classes.UserDropdownListSelectAll}
        />)}
    </>
  )
};

export default InputTopSide;
