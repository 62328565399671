import React, { memo, ReactElement, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { UserInfo } from 'components';

import { useUsersState } from 'store/users';
import { getUserDataByIdOrLogName } from 'utils/user';

import { UserType } from 'types';
import { useUserProfile } from 'hooks';

type CreatorProps = {
  initiator?: string;
  companyId?: string;
}

export const Creator = memo(({ initiator, companyId }: CreatorProps): ReactElement => {
  const { t } = useTranslation();
  const { users } = useUsersState();
  const { companyName, companyLogo } = useUserProfile();

  const [user, setUser] = useState<UserType | null>(null);

  const getUserData = async (assigneeKey: string) => {
    const assigneeId = Object.keys(users).find((hcmsUserId: string) => {
      const { logName, id }: UserType = users[hcmsUserId];

      // Данные от creator-a могут приходить ввиде logName-a либо griffon id
      return [logName, id].includes(initiator);
    });

    if (assigneeId) {
      setUser(users[assigneeId]);
      return;
    }

    const userInfo = await getUserDataByIdOrLogName(assigneeKey, companyId);
    if (userInfo) {
      setUser(userInfo);
    }
  };

  useEffect(() => {
    if (!(initiator === 'company' || initiator === '${company}')) {
      getUserData(initiator);
    }
  }, [initiator, users]);

  if (user) {
    return (
      <UserInfo
        user={user}
        title={t("task_data_view.created_by")}
        showRole={false}
        avatarSize={24}
      />
    )
  }

  if (initiator === 'company' || initiator === '${company}') {
    return (
      <UserInfo
        user={{
          id: '',
          avatar: companyLogo,
          email: '',
          firstName: '',
          fullName: companyName,
          fullNameRu: '',
          lastName: '',
          phoneNumber: '',
          role: [''],
          logName: companyName,
          jobTitle: ''
        }}
        title={t("task_data_view.created_by")}
        showRole={false}
        avatarSize={24}
      />
    )
  }

  return (
    <div>{t("task_data_view.employee_not_found")}</div>
  )
});
