import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ spacing, palette }) => ({
  autostartForm: {
    marginTop: spacing(4),
  },
  repeatWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  repeatLabel: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#6D6E85',
    marginRight: spacing(4),
  },
  repeatCountInput: {
    height: 40,
    width: 80,
    marginRight: spacing(3),
  },
  repeatUnitSelect: {
    minWidth: 160,
    height: 40,
    border: '1px solid rgba(38, 40, 66, 0.12)',
    borderRadius: 10,
    '& img': {
      marginRight: spacing(1),
      marginTop: spacing(0.75)
    }
  },
  startWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: spacing(5),
  },
  blockLabel: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    color: '#6D6E85',
    marginBottom: spacing(2),
  },
  startInputsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 12,
  },
  startDateSelect: {
    minWidth: 160,
    width: 'min-content',
    '& > div': {
      border: '1px solid rgba(38, 40, 66, 0.12)',
      borderRadius: 10,
    }
  },
  startTimeSelect: {
    width: 120,
    border: '1px solid rgba(38, 40, 66, 0.12)',
    borderRadius: 10,
    '& svg': {
      right: 8
    }
  },
  repeatWeekdaysWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: spacing(5),
  },
  repeatWeekdaysButtonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 12,
  },
  weekdayButton: {
    width: 40,
    height: 40,
    minWidth: 40,
    maxWidth: 40,
    minHeight: 40,
    maxHeight: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#FFFFFF',
    border: '1px solid rgba(38, 40, 66, 0.12)',
    borderRadius: 10,
    color: '#262842',
    '&:hover': {
      background: 'rgba(38, 40, 66, 0.04)',
      border: '1px solid rgba(38, 40, 66, 0.12)',
    },
    '&:active': {
      background: 'rgba(38, 40, 66, 0.08)',
      border: '1px solid rgba(38, 40, 66, 0.12)',
    },
  },
  selectedWeekdayButton: {
    background: '#E0613A !important',
    border: '1px solid #E0613A !important',
    color: '#FFFFFF',
    '&:hover, &:active': {
      background: '#D15C3B',
      border: '1px solid #D15C3B',
    },
  },
  repeatInfoMessage: {
    marginTop: spacing(2),
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    color: '#262842',
    '& span': {
      color: '#8B8C9E'
    }
  },
  endingWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: spacing(5),
  },
  radioButton: {
    border: 'none !important',
    backgroundColor: 'transparent !important',
    marginRight: spacing(2.5),
    '& svg path': {
      fill: '#8B8C9E !important',
    },
  },
  radioButtonChecked: {
    '& svg path': {
      fill: '#007994 !important',
    },
  },
  radioOptionLabelWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  radioOptionLabel: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#101F2B',
    height: 40,
    marginRight: spacing(4),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  endRepetitionsCountInput: {
    height: 40,
    width: 80,
    marginRight: spacing(3),
  },
  repetitionsInputsWrapper: {
    marginBottom: -20,
  },
  endingAfterInputsWrapper: {
    marginBottom: -20,
  },
  endingAfterInputsInnerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
  },
  repetitionsLabel: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#8B8C9E',
  },
  processStepFormErrorMessage: {
    height: 16,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: spacing(1),
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    color: '#D6331F',
    '& img': {
      marginRight: spacing(1.25),
      height: 13,
      width: 13
    }
  }
}));
