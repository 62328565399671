import { ConditionStep, DocumentTemplate, ParallelBranchesGroup, ProcessStep } from '../../../TemplateProcesses.types';

type Props = {
  processStep: ProcessStep,
  processSteps: ProcessStep[],
  allProcessStepsFields: any,
  conditions: ConditionStep[],
  parallelBranchesGroups: ParallelBranchesGroup[],
  documents: DocumentTemplate[],
  defaultProcessLanguage: string
}

export const getProcessStepsPublicationParams = ({
  processStep,
  processSteps,
  conditions,
  parallelBranchesGroups,
  documents,
  allProcessStepsFields,
  defaultProcessLanguage,
}: Props) => {
  let conditionAndParallelPreviousSteps = [processStep.stepOrder];

  // conditionJoin = true -> processStep is last step in positive or negative condition branch

  let conditionJoin = false;
  if (processStep?.isConditionBranchStep) {
    const branchStepCondition = conditions?.find(c => [...c?.positiveBranch, ...c?.negativeBranch].includes(processStep?.stepOrder));
    conditionAndParallelPreviousSteps = [branchStepCondition?.positiveBranch[branchStepCondition?.positiveBranch?.length - 1], branchStepCondition?.negativeBranch[branchStepCondition?.negativeBranch.length - 1]];

    const isPositiveBranchStep = branchStepCondition?.positiveBranch.includes(processStep?.stepOrder);
    conditionJoin = isPositiveBranchStep
                    ? branchStepCondition?.positiveBranch.findIndex(v => v === processStep?.stepOrder) + 1 === branchStepCondition?.positiveBranch.length
                    : branchStepCondition?.negativeBranch.findIndex(v => v === processStep?.stepOrder) + 1 === branchStepCondition?.negativeBranch.length;
  }

  if (processStep?.isParallelBranchesGroupStep) {
    const stepParallelBranchesGroup = parallelBranchesGroups?.find(group => group?.steps?.flat().includes(processStep.stepOrder));
    if (stepParallelBranchesGroup) {
      conditionAndParallelPreviousSteps = stepParallelBranchesGroup.steps.map(b => b[b.length - 1]);
    }
  }

  const stepCondition = conditions?.find(condition => conditionAndParallelPreviousSteps?.includes(condition?.previousStepOrder));
  const isStepWithCondition = !!stepCondition && conditionAndParallelPreviousSteps.includes(processStep.stepOrder);
  const stepNextParallelGroup = parallelBranchesGroups?.find(group => conditionAndParallelPreviousSteps?.includes(group?.previousStepOrder));
  const isStepBeforeParallelStepsGroup = !!stepNextParallelGroup && conditionAndParallelPreviousSteps.includes(processStep.stepOrder);

  // possibleNextSteps - steps to which process may go from the processStep

  let possibleNextSteps = isStepWithCondition
                          ? []
                          : isStepBeforeParallelStepsGroup
                            ? stepNextParallelGroup?.steps.map(b => b[0])
                            : [processStep?.stepOrder + 1];

  if (isStepWithCondition) {
    const positiveStep = stepCondition?.hasPositiveBranch
                         ? stepCondition?.positiveBranch[0]
                         : stepCondition?.negativeBranch.some(v => v === processSteps.length)
                           ? -1
                           : Math.max(...stepCondition?.negativeBranch) + 1;
    const negativeStep = stepCondition?.hasNegativeBranch
                         ? stepCondition?.negativeBranch[0]
                         : stepCondition?.positiveBranch.some(v => v === processSteps.length)
                           ? -1
                           : Math.max(...stepCondition?.positiveBranch) + 1;
    const extraSteps = [];
    if (!stepCondition?.hasPositiveBranch && positiveStep >= 0) {
      if (processSteps[positiveStep - 1]?.isParallelBranchesGroupStep) {
        const positiveStepParallelStepsGroup = parallelBranchesGroups?.find(group => group?.steps?.flat().includes(positiveStep));
        extraSteps.push(...(positiveStepParallelStepsGroup?.steps.map(b => b[0]).flat() || []));
      }
    }
    if (!stepCondition?.hasNegativeBranch && negativeStep >= 0) {
      if (processSteps[negativeStep - 1]?.isParallelBranchesGroupStep) {
        const negativeStepParallelStepsGroup = parallelBranchesGroups?.find(group => group?.steps?.flat().includes(negativeStep));
        extraSteps.push(...(negativeStepParallelStepsGroup?.steps.map(b => b[0]).flat() || []));
      }
    }
    possibleNextSteps = [positiveStep, negativeStep, ...extraSteps].filter((value, index, array) => array.indexOf(value) === index);
  }

  // branchesEndStep - step(s) immediately after branching (condition/parallel branches)
  // immediately after processStep
  // branchesEndScriptModel - script model of condition immediately after processStep

  let branchesEndStep = [];
  let branchesEndScriptModel = {};
  let stepAfterBranchesOrder = -1;

  if (isStepWithCondition) {
    stepAfterBranchesOrder = Math.max(
      ...stepCondition?.positiveBranch, ...stepCondition?.negativeBranch,
    ) + 1;
  }

  if (isStepBeforeParallelStepsGroup) {
    stepAfterBranchesOrder = Math.max(...stepNextParallelGroup.steps.flat()) + 1;
  }

  if (stepAfterBranchesOrder >= 0) {
    if (stepAfterBranchesOrder <= processSteps.length) {
      const stepAfterBranches = processSteps[stepAfterBranchesOrder - 1];

      if (stepAfterBranches?.isParallelBranchesGroupStep) {
        const stepParallelStepsGroup = parallelBranchesGroups.find(group => group.steps.flat().includes(stepAfterBranchesOrder));
        if (stepParallelStepsGroup) {
          branchesEndStep = stepParallelStepsGroup?.steps.map(b => b[0]);
        }
      } else if (stepAfterBranches?.isConditionBranchStep) {
        const branchStepCondition = conditions?.find(c => [...c?.positiveBranch, ...c?.negativeBranch].includes(stepAfterBranchesOrder));
        if (branchStepCondition) {
          branchesEndStep = branchStepCondition?.hasPositiveBranch && branchStepCondition?.hasNegativeBranch
                            ? [branchStepCondition?.positiveBranch[0], branchStepCondition?.negativeBranch[0]]
                            : branchStepCondition?.hasPositiveBranch
                              ? [branchStepCondition?.positiveBranch[0]]
                              : [branchStepCondition?.negativeBranch[0]];

          if (isStepBeforeParallelStepsGroup) {
            let nextConditionPositiveStep = branchStepCondition?.hasPositiveBranch
                                            ? [branchStepCondition?.positiveBranch[0]]
                                            : branchStepCondition?.negativeBranch.some(v => v === processSteps?.length)
                                              ? [-1]
                                              : [Math.max(...branchStepCondition?.negativeBranch) + 1];
            let nextConditionNegativeStep = branchStepCondition?.hasNegativeBranch
                                            ? [branchStepCondition?.negativeBranch[0]]
                                            : branchStepCondition?.positiveBranch.some(v => v === processSteps?.length)
                                              ? [-1]
                                              : [Math.max(...branchStepCondition?.positiveBranch) + 1];

            if (branchStepCondition && !branchStepCondition?.hasPositiveBranch && nextConditionPositiveStep[0] >= 0) {
              if (processSteps[nextConditionPositiveStep[0] - 1]?.isParallelBranchesGroupStep) {
                const positiveStepParallelStepsGroup = parallelBranchesGroups.find(group => group.steps?.flat()?.includes(nextConditionPositiveStep[0]));
                nextConditionPositiveStep = positiveStepParallelStepsGroup?.steps?.map(b => b[0]) || [];
              }
            }
            if (branchStepCondition && !branchStepCondition?.hasNegativeBranch && nextConditionNegativeStep[0] >= 0) {
              if (processSteps[nextConditionNegativeStep[0] - 1]?.isParallelBranchesGroupStep) {
                const negativeStepParallelStepsGroup = parallelBranchesGroups.find(group => group.steps.flat().includes(nextConditionNegativeStep[0]));
                nextConditionNegativeStep = negativeStepParallelStepsGroup?.steps.map(b => b[0]) || [];
              }
            }

            branchesEndScriptModel = {
              condition: branchStepCondition?.condition.type,
              field: branchStepCondition?.condition.field,
              fieldType: allProcessStepsFields.find(item => item?.field.id === branchStepCondition?.condition.field)?.field?.component,
              negativeStep: nextConditionNegativeStep,
              positiveStep: nextConditionPositiveStep,
              hasPositiveStep: branchStepCondition?.hasPositiveBranch,
              hasNegativeStep: branchStepCondition?.hasNegativeBranch,
              scriptName: branchStepCondition?.stepName[defaultProcessLanguage] || '',
              value: branchStepCondition?.condition.value,
              isNewVersion: true,
            };
          }
        }
      } else {
        branchesEndStep = [stepAfterBranchesOrder];
      }
    } else {
      branchesEndStep = [-1]
    }
  }

  if (!isStepWithCondition && processStep?.stepOrder < processSteps?.length && processStep?.isConditionBranchStep) {
    const branchStepCondition = conditions?.find(c =>
      [...c?.positiveBranch, ...c?.negativeBranch].includes(processStep?.stepOrder));
    if (processSteps[processStep.stepOrder]?.isConditionBranchStep) {
      // для шагов в условиях так как при условии после шага N и N+1 и N+2 ведут на N+3
      // только для условий в которых шаги есть в обоих ветках
      if (branchStepCondition?.hasPositiveBranch && branchStepCondition?.hasNegativeBranch && branchStepCondition?.positiveBranch[branchStepCondition?.positiveBranch.length - 1] === processStep?.stepOrder) {
        possibleNextSteps = [processStep.stepOrder + (branchStepCondition.positiveBranch.length + branchStepCondition.negativeBranch.length) <= processSteps.length
                             ? processStep.stepOrder + (branchStepCondition.positiveBranch.length + branchStepCondition.negativeBranch.length)
                             : -1];
      }

      if (isStepBeforeParallelStepsGroup) {
        possibleNextSteps = stepNextParallelGroup.steps.map(b => b[0]);
      }
    }
  }

  // parallelJoin = true -> processStep is last step in one of parallel branches

  let parallelJoin = false;
  if (processStep?.isParallelBranchesGroupStep) {
    const stepParallelStepsGroup = parallelBranchesGroups.find(group => group.steps.flat().includes(processStep.stepOrder));
    if (stepParallelStepsGroup) {
      const stepBranch = stepParallelStepsGroup?.steps.find(b => b.includes(processStep.stepOrder));
      const isLastStepInBranch = stepBranch.indexOf(processStep.stepOrder) === stepBranch.length - 1;
      const isGroupLastProcessFlowElement = stepParallelStepsGroup?.steps.flat().some(stepOrder => stepOrder >= processSteps.length);
      if (isLastStepInBranch) {
        parallelJoin = true;
      }

      if (isGroupLastProcessFlowElement && isLastStepInBranch) {
        possibleNextSteps = [-1];
      } else {
        if (isLastStepInBranch) {
          if (isStepBeforeParallelStepsGroup) {
            possibleNextSteps = stepNextParallelGroup.steps.map(b => b[0]) || [];
          } else if (isStepWithCondition) {
            possibleNextSteps = [stepCondition.hasPositiveBranch
                                 ? stepCondition.positiveBranch[0]
                                 : -1,
                                 stepCondition.hasNegativeBranch
                                 ? stepCondition.negativeBranch[0]
                                 : -1];
          } else {
            possibleNextSteps = [Math.max(...(stepParallelStepsGroup?.steps.flat() || [])) + 1];
          }
        } else {
          possibleNextSteps = [stepBranch[stepBranch.indexOf(processStep.stepOrder) + 1]];
        }
      }
    }
  }

  // у последних шагов процесса следующие шаги отсутствуют
  if (processStep.stepOrder === processSteps.length) {
    possibleNextSteps = [-1];
  }

  let conditionPositiveStep = isStepWithCondition ?
                              stepCondition?.hasPositiveBranch
                              ? [stepCondition?.positiveBranch[0]]
                              : stepCondition?.negativeBranch.some(v => v === processSteps?.length)
                                ? [-1]
                                : [Math.max(...stepCondition?.negativeBranch) + 1]
                                                  : [0];
  let conditionNegativeStep = isStepWithCondition ?
                              stepCondition?.hasNegativeBranch
                              ? [stepCondition?.negativeBranch[0]]
                              : stepCondition?.positiveBranch.some(v => v === processSteps?.length)
                                ? [-1]
                                : [Math.max(...stepCondition?.positiveBranch) + 1]
                                                  : [0];

  if (stepCondition && !stepCondition?.hasPositiveBranch && conditionPositiveStep[0] >= 0) {
    if (processSteps[conditionPositiveStep[0] - 1]?.isParallelBranchesGroupStep) {
      const positiveStepParallelStepsGroup = parallelBranchesGroups.find(group => group.steps.flat().includes(conditionPositiveStep[0]));
      conditionPositiveStep = positiveStepParallelStepsGroup?.steps.map(b => b[0]) || [];
    }
  }
  if (stepCondition && !stepCondition?.hasNegativeBranch && conditionNegativeStep[0] >= 0) {
    if (processSteps[conditionNegativeStep[0] - 1]?.isParallelBranchesGroupStep) {
      const negativeStepParallelStepsGroup = parallelBranchesGroups.find(group => group.steps.flat().includes(conditionNegativeStep[0]));
      conditionNegativeStep = negativeStepParallelStepsGroup?.steps.map(b => b[0]) || [];
    }
  }

  // previousSteps - step(s) immediately before the processStep (steps from which process may go to the processStep)

  let previousSteps = [];
  if (processStep?.isConditionBranchStep) {
    const branchStepCondition = conditions.find(c =>
      [...c?.positiveBranch, ...c?.negativeBranch].includes(processStep?.stepOrder));
    const isPositiveBranchStep = branchStepCondition?.positiveBranch.includes(processStep?.stepOrder);
    const branchSteps = isPositiveBranchStep
                        ? branchStepCondition?.positiveBranch
                        : branchStepCondition?.negativeBranch;
    const stepIndex = branchSteps?.findIndex(v => v === processStep.stepOrder);
    previousSteps = stepIndex === 0 ? [branchStepCondition?.previousStepOrder] : [branchSteps[stepIndex - 1]];

    // set to last branch steps next step order instead if -1
    if (branchStepCondition?.positiveBranch && branchStepCondition?.negativeBranch){
      const lastNegative = branchStepCondition?.negativeBranch[branchStepCondition?.negativeBranch.length - 1]
      const lastPositive = branchStepCondition?.positiveBranch[branchStepCondition?.positiveBranch.length - 1]

      const max = Math.max(lastNegative, lastPositive)
      // if next step is step
      if ((processStep.stepOrder === lastNegative || processStep.stepOrder === lastPositive) && processSteps[max] && !processSteps[max].isConditionBranchStep && !processSteps[max].isParallelBranchesGroupStep) {
        possibleNextSteps = [processSteps[max].stepOrder]
      }
    }
  }

  if (processStep?.isParallelBranchesGroupStep) {
    const stepParallelStepsGroup = parallelBranchesGroups.find(group => group.steps.flat().includes(processStep.stepOrder));
    if (stepParallelStepsGroup) {
      const stepBranch = stepParallelStepsGroup.steps.find(b => b.includes(processStep.stepOrder));
      const stepIndexInBranch = stepBranch.indexOf(processStep.stepOrder);
      const previousStep = stepIndexInBranch <= 0
                           ? processSteps[stepParallelStepsGroup?.previousStepOrder - 1]
                           : processSteps[stepBranch[stepIndexInBranch - 1] - 1];
      if (previousStep?.isConditionBranchStep) {
        const branchStepCondition = conditions.find(c =>
          [...c?.positiveBranch, ...c?.negativeBranch].includes(previousStep?.stepOrder));
        previousSteps = [
          branchStepCondition?.hasPositiveBranch
          ? branchStepCondition?.positiveBranch[branchStepCondition?.positiveBranch.length - 1]
          : branchStepCondition?.previousStepOrder,
          branchStepCondition?.hasNegativeBranch
          ? branchStepCondition?.negativeBranch[branchStepCondition?.negativeBranch.length - 1]
          : branchStepCondition?.previousStepOrder,
        ];
      } else {
        previousSteps = [previousStep.stepOrder];
      }
    }
  }

  const parallelBranchName = isStepBeforeParallelStepsGroup
                             ? stepNextParallelGroup?.stepName[defaultProcessLanguage] || ''
                             : '';

  const scriptModel = isStepWithCondition ?
      {
        condition: stepCondition?.condition.type,
        field: stepCondition?.condition.field,
        fieldType: allProcessStepsFields.find(item => item?.field.id === stepCondition?.condition.field)?.field?.component,
        negativeStep: [...conditionNegativeStep],
        positiveStep: [...conditionPositiveStep],
        hasPositiveStep: stepCondition?.hasPositiveBranch,
        hasNegativeStep: stepCondition?.hasNegativeBranch,
        scriptName: stepCondition?.stepName[defaultProcessLanguage] || '',
        value: stepCondition?.condition.value as string,
        isNewVersion: true,
      } : {};


  const hasSignature = documents.some(doc => doc.signatureSteps.includes(processStep.stepOrder));

  const summaryFields = processStep.summaryFields.map((fieldId, index) => {
    const formField = allProcessStepsFields?.find(item => item.field.id === fieldId)?.field;
    if (!formField) {
      return null;
    }

    return {
      name: formField?.hint[defaultProcessLanguage],
      sysName: formField?.id,
      type: formField?.component,
      fieldOrder: index,
    };
  }).filter(Boolean);

  return {
    previousSteps,
    possibleNextSteps,
    branchesEndStep,
    branchesEndScriptModel,
    parallelJoin,
    conditionJoin,
    parallelBranchName,
    isStepWithCondition,
    scriptModel,
    stepCondition,
    stepNextParallelGroup,
    hasSignature,
    summaryFields
  }
}
