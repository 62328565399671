import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ spacing, palette }) => ({
  card: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '12px 12px 12px 2px',
    paddingLeft: 2,
    borderRadius: 12,
    border: '1px solid rgba(38, 40, 66, 0.12)',
    background: '#FFF'
  },
  selectedCard: {
    border: '2px solid #007994',
  },
  dragAndDropIndicatorWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    minWidth: 20,
    marginRight: 2,
    '& img': {
      minWidth: 20,
      width: 20,
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  name: {
    color: '#262842',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '18px',
    margin: 0,
    padding: 0,
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    color: '#6D6E85',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
  },
  description: {
    color: '#262842',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
    margin: 0,
    padding: 0,
    marginTop: 4,
  },
}));
