import React, { useCallback, useEffect, useState, Suspense, useMemo } from 'react';
import { Box, FormHelperText } from '@mui/material';
import { Moment } from 'moment';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getBpmDocuments, getDocflowCounterparties, getDocflowDocumentTypesDirectories } from 'api/requests';
import { Spinner } from 'components';
import { useUserProfile } from 'hooks';
import { useTaskState } from 'store/requests';
import { Docflow, PermissionModule, PermissionType } from 'types';
import { removeSpacesFromNumber } from 'utils/general';
import { hasPermission } from 'utils/workspace';

import SelectWithFilter from './SelectWithFilter/SelectWithFilter';
import { DocumentCard } from './DocumentCard';
import { useStyles } from './useStyles';

const DocumentDetails = React.lazy(async () => ({
  default: (await import('@dar-dms/utils')).DocumentDetails,
}));

interface DocumentFilterAndInformationProps {
  name: string;
  sum?: number;
  currency?: string;
  hint?: string;
  placeholder?: string;
  params?: any;
  defaultValue?: Docflow.ProcessDocument[];
}

interface DocumentType {
  id: string;
  name: string;
}

interface CounterpartyType {
  id: string;
  name: string;
}

export const DocumentFilterAndInformation: React.FC<DocumentFilterAndInformationProps> = ({
  name,
  sum,
  currency,
  hint,
  params,
  placeholder,
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { id } = useUserProfile();
  const { data: bpmTask } = useTaskState();
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([]);
  const [counterparties, setCounterparties] = useState<CounterpartyType[]>([]);
  const [filters, setFilters] = useState<{
    documentTypeIds: string[];
    counterpartyIds: string[];
    dateRange: { startDate?: Moment | null; endDate?: Moment | null };
  }>({
    documentTypeIds: [],
    counterpartyIds: [],
    dateRange: { startDate: null, endDate: null },
  });
  const [documentList, setDocumentList] = useState<Array<Docflow.DocflowDocument>>([]);
  const [documentToPreview, setDocumentToPreview] = useState<number | null>(null);

  const isDocflowSuperAdmin = useMemo(
    () =>
      hasPermission(PermissionModule.DOCUMENTS, PermissionType.Read) &&
      hasPermission(PermissionModule.DOCUMENTS, PermissionType.Write) &&
      hasPermission(PermissionModule.DOCUMENTS, PermissionType.Update) &&
      hasPermission(PermissionModule.DOCUMENTS, PermissionType.Delete),
    []
  );

  const docflowDocuments = useMemo(() => bpmTask?.entity?.taskInstance?.docFlowDocuments, [bpmTask]);

  const [selectedDocuments, setSelectedDocuments] = useState<Array<Docflow.DocflowDocument | Docflow.ProcessDocument>>(
    watch('docFlowDocuments') || docflowDocuments || []
  );

  const selectedFormDocuments = watch('docFlowDocuments') || [];

  const signatureEnabled = useMemo(() => {
    let signRequired = false;
    const signRequiredSteps = params?.signRequiredSteps || [];
    const currentStep = bpmTask?.currentAction?.stepperOrder;
    if (signRequiredSteps?.length && typeof currentStep === 'number' && signRequiredSteps.find((step) => step >= currentStep)) {
      signRequired = true;
    }
    return signRequired;
  }, [bpmTask, params]);

  useEffect(() => {
    setIsLoading(true);
    getBpmDocuments({
      signatureEnabled,
      documentTypes: filters?.documentTypeIds || [],
      counterpartyIds: filters?.counterpartyIds || [],
      createdAtStart: filters?.dateRange?.startDate ? filters?.dateRange?.startDate.format('YYYY-MM-DD') : undefined,
      createdAtEnd: filters?.dateRange?.endDate ? filters?.dateRange?.endDate.format('YYYY-MM-DD') : undefined,
      isAdmin: isDocflowSuperAdmin,
      userId: id,
    })
      .then((res) => setDocumentList(res?.content || []))
      .finally(() => setIsLoading(false));
  }, [signatureEnabled, filters]);

  useEffect(() => {
    getDocflowDocumentTypesDirectories().then((glossaries) => {
      setDocumentTypes(
        glossaries.map((item) => ({
          id: item.id,
          name: item.localization[`${i18n?.language}`] || item?.localization?.en || item.value,
        }))
      );
    });
    getDocflowCounterparties().then((result) =>
      setCounterparties(result?.content.map((item) => ({ id: item.id, name: item.signatoryName })))
    );
  }, []);

  useEffect(() => setValue(name, selectedDocuments.map((doc) => doc.id).join(',')), [selectedDocuments]);

  const handleSelectDocuments = (documents: Array<Docflow.DocflowDocument>) => {
    setSelectedDocuments(documents);
  };

  const handleFilterChange = (newFilters: typeof filters) => {
    setFilters(newFilters);
  };

  const handleDeleteDocumentSelection = (documentId: number) => {
    const newValue = selectedFormDocuments.filter((item) => item.id !== documentId);
    setSelectedDocuments(newValue);
    setValue('docFlowDocuments', newValue);
  };

  const handleOpenDocument = (documentId: number) => setDocumentToPreview(documentId);

  const closeDocumentPreview = useCallback(() => setDocumentToPreview(null), []);

  return (
    <Box>
      <SelectWithFilter
        popperWidth={'100%'}
        multiSelect={params?.multipleSelection}
        label={t('DocumentProcessFromDocflow.DocumentSelection')}
        showCount={true}
        placeholder={placeholder}
        loadingDocuments={isLoading}
        documentList={documentList}
        documentTypes={documentTypes}
        counterparties={counterparties}
        onFilterChange={handleFilterChange}
        selectedDocument={selectedDocuments}
        setSelectedDocument={handleSelectDocuments}
        deleteDocument={handleDeleteDocumentSelection}
        hasError={Boolean(errors?.[name]?.message)}
      />
      <Controller
        control={control}
        name={name}
        rules={{ required: t('form_components.required_errors.default') as string }}
        render={() => <input type="hidden" data-selenium={name} />}
      />
      {errors?.[name] && errors?.[name]?.message ? (
        <FormHelperText className={classes.errorMessage} error>
          {errors?.[name]?.message}
        </FormHelperText>
      ) : null}

      {selectedDocuments.map((doc) => (
        <DocumentCard
          document={doc}
          onDelete={handleDeleteDocumentSelection}
          openDocument={handleOpenDocument}
          sum={typeof sum === 'string' ? removeSpacesFromNumber(sum) : sum}
          currency={currency}
          selectedFormDocuments={selectedFormDocuments}
          setValue={setValue}
          key={doc.id}
        />
      ))}
      {documentToPreview && (
        <Suspense fallback={<Spinner absolute />}>
          <DocumentDetails id={documentToPreview} isPreview onClose={closeDocumentPreview} />
        </Suspense>
      )}
    </Box>
  );
};
