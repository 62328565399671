import React, { ReactElement } from 'react';
import { Box, Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import useSLAFormStyles from './SLAForm.useStyles';
import { ProcessStep } from '../../TemplateProcesses.types';
import { StepExecutionTimeField } from '../StepExecutionTimeField';

import InfoIcon from 'assets/images/icons/info_outline.svg';
import { ReactComponent as CheckboxUncheckedIcon } from 'assets/images/icons/checkbox-unchecked.svg';
import { ReactComponent as CheckboxCheckedIcon } from 'assets/images/icons/checkbox-checked.svg';

interface SLAForm {
  currentStep: ProcessStep;
  hasWorkCalendar: boolean;
  register: any,
  trigger: any,
  errors: any,
  clearErrors: any,
  isEditDisabled: boolean,
  handleSLACheckboxToggle: (value: boolean) => void;
  handleTimerValueChange: (value: string) => void;
}

export const SLAForm = ({
  currentStep,
  register,
  trigger,
  errors,
  clearErrors,
  isEditDisabled,
  hasWorkCalendar,
  handleSLACheckboxToggle,
  handleTimerValueChange
}: SLAForm): ReactElement => {
  const { t } = useTranslation();
  const classes = useSLAFormStyles();

  const helpTooltipContent = <div className={classes.helpTooltipContent}>
    <p>{t('customProcesses.creationPage.processForm.SLACheckbox.help')}</p>
  </div>;

  return (
    <Box
      className={cn(classes.SLAForm)}>
      {(currentStep?.stepOrder > 1) && (
        <>
          <Box mt={2} className={classes.checkboxWrapper}>
            <FormControlLabel
              className={cn(classes.checkbox, { [classes.checkboxChecked]: currentStep?.hasTimer })}
              control={<Checkbox
                checked={currentStep?.hasTimer}
                color="default"
                disabled={isEditDisabled}
                icon={<CheckboxUncheckedIcon/>}
                checkedIcon={<CheckboxCheckedIcon/>}
                classes={{
                  disabled: classes.checkboxDisabled,
                  checked: classes.checkboxChecked,
                }}
                onChange={e => handleSLACheckboxToggle(e?.target?.checked)}
              />}
              classes={{ label: classes.checkboxLabel }}
              label={t('customProcesses.creationPage.processForm.fields.SLACheckbox')}
            />

            <Tooltip
              classes={{
                popper: classes.tooltipPopper,
                tooltip: classes.helpTooltip,
              }}
              arrow
              placement="bottom"
              title={helpTooltipContent}
              leaveDelay={500}
            >
              <img src={InfoIcon} className={classes.infoIcon}/>
            </Tooltip>
          </Box>

          {currentStep?.hasTimer && <Box className={classes.executionTimeWrapper} mt={3}>
            <StepExecutionTimeField
              register={register}
              trigger={trigger}
              errors={errors}
              clearErrors={clearErrors}
              currentStep={currentStep}
              hasWorkCalendar={hasWorkCalendar}
              isEditDisabled={isEditDisabled}
              handleStepExecutionTimeChange={handleTimerValueChange}
            />
          </Box>}

          {(currentStep?.hasTimer && !hasWorkCalendar) && <p className={classes.noCalendarInfoMessage}>
            {t('customProcesses.creationPage.processForm.fields.SLANoCalendarInfoMessage')}
          </p>}
        </>
      )}
    </Box>
  );
};

