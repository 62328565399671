import React, { Dispatch, Suspense } from 'react';

import { LocalizedTexts, PreviewFile, TypeFileData } from '@dar-dms/utils';

import ModalContainer from './ModalContainer';
import { Spinner } from 'components/Spinner';
import { getFileByIdQuery, getAttachmentFromPS } from 'api/requests';

interface AttachmentPreviewProps {
  deleteActionHandler: () => void;
  fileInfo: TypeFileData;
  handleDownloadClick: () => void;
  isPreviewFileModalOpen: boolean;
  localizedTexts: LocalizedTexts;
  readOnly: boolean;
  setIsPreviewFileModalOpen: Dispatch<boolean>;
}

const AttachmentPreview = (props: AttachmentPreviewProps) => {
  const {
    deleteActionHandler,
    fileInfo,
    handleDownloadClick,
    isPreviewFileModalOpen,
    localizedTexts,
    readOnly,
    setIsPreviewFileModalOpen,
  } = props;
  return (
    <ModalContainer
      isOpen={isPreviewFileModalOpen}
      onClose={() => setIsPreviewFileModalOpen(false)}
      fullWidth
    >
      {isPreviewFileModalOpen &&
        <Suspense fallback={<Spinner />}>
          <PreviewFile
            data={fileInfo}
            getAttachmentFromPS={getAttachmentFromPS}
            getFileByIdQuery={getFileByIdQuery}
            handleDownloadClick={handleDownloadClick}
            handlers={{ deleteActionHandler }}
            isDrive={false}
            localizedTexts={localizedTexts}
            onClose={() => setIsPreviewFileModalOpen(false)}
            readOnly={readOnly}
            transformDocToPdf={{} as any}
          />
        </Suspense>
      }
    </ModalContainer>
  )
}

export default AttachmentPreview;
