import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ spacing, palette }) => ({
  groupsDropdown: {
    position: 'relative',
    maxWidth: '100%',
    height: 52,

    backgroundColor: '#FFFFFF',
    border: '1px solid rgba(38, 40, 66, 0.08)',
    borderRadius: 12,
    padding: spacing(2.5, 2.5, 2.5, 2),

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  groupDropdownDisabled: {
    cursor: 'auto',
    '&, &:hover': {
      backgroundColor: palette.grey[300],
      color: palette.grey[500],
    },
  },
  groupDropdownActive: {
    transform: 'rotate(180deg)',
  },
  label: {
    textAlign: 'left',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    color: '#6D6E85',
    marginBottom: spacing(1),
    '&::after': {
      display: 'inline-block',
      content: '"*"',
      color: palette.error[600],
      fontSize: 14,
      position: 'relative',
      right: -2,
      top: -2,
    },
  },
  groupDropdownTitle: {
    fontSize: 16,
    fontWeight: 400,
    color: '#101F2B',
  },
  groupDropdownTitleDisabled: {
    color: palette.grey[500],
  },
  groupDropdownValue: {
    fontSize: 16,
    fontWeight: 400,
    color: '#8A96A1',
  },
  groupDropdownIcon: {
    transition: '0.3s transform ease',

    '& > path': {
      fill: '#6D7C8B !important',
    },
  },
  groupDropdownMenu: {
    display: 'none',
    position: 'absolute',
    top: 80,
    left: 0,
    zIndex: 1350,

    width: '100%',
    backgroundColor: '#FFFFFF',
    border: '1px solid rgba(38, 40, 66, 0.08)',
    boxShadow: '0px 8px 16px rgba(38, 40, 66, 0.04)',
    borderRadius: spacing(2.5),
  },
  groupDropdownMenuActive: {
    display: 'block',
  },
  groupDropdownMenuHeader: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid #E0E3EA',
  },
  groupDropdownMenuSearchWrapper: {
    backgroundColor: '#F5F8FA',
    borderRadius: spacing(1),
    padding: spacing(2, 3),
    lineHeight: 'initial',
  },
  groupDropdownMenuSearchTextField: {
    backgroundColor: '#FFFFFF',
    borderRadius: spacing(1),
  },
  groupDropdownMenuContent: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 210,
    overflow: 'auto',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    padding: spacing(0),
    '& label': {
      width: '100%',
    },
  },
  option: {
    padding: spacing(2, 3),
    width: '100%',
    cursor: 'pointer',
  },
  optionSelected: {
    background: 'rgba(38, 40, 66, 0.08)',
  },
  userDropdownMenuFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

    padding: spacing(2),
    borderTop: '1px solid #E0E3EA',
  },
  groupCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  groupCardIcon: {
    width: 32,
    height: 32,
    borderRadius: 6,
  },
  groupInfoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: spacing(2),
  },
  groupCardName: {
    textAlign: 'left',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '18px',
    color: '#262842',
    maxWidth: 420,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  groupCardDescription: {
    textAlign: 'left',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '16px',
    color: '#8B8C9E',
    maxWidth: 420,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));
