import makeStyles from '@mui/styles/makeStyles';
export default makeStyles(() => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    height: "100%"
  },
  flexGrow: {
    flexGrow: 1
  }
}))
