import { makeStyles } from '@mui/styles';

interface Props {
  noBorder?: boolean;
}

export const useStyles = makeStyles({
  container: {
    width: '100%',
    borderBottom: (props: Props) => (props?.noBorder ? 'none' : '1px solid #2628421F'),
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    padding: '16px 0',
  },
  value_wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
  label: {
    color: '#6D6E85',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '18px',
    whiteSpace: 'nowrap',
  },
  value: {
    fontSize: 14,
    lineHeight: '18px',
    color: '#262842',
    textAlign: 'right',
  },
  info: {
    fontSize: 12,
    lineHeight: '16px',
    color: '#6D6E85',
    textAlign: 'right',
  },
  document_name: {
    width: 'fit-content',
    display: 'inline-block',
    fontSize: 12,
    lineHeight: '16px',
    color: '#262842',
    fontWeight: 600,
    padding: '4px 8px',
    backgroundColor: '#F0F0F7',
    borderRadius: 6,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  sumValidation: {
    width: '100%',
    padding: '8px 12px',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
  },
  sumValidationTitle: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '18px',
    marginBottom: 6,
  },
  sumValidationSubTitle: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
  },
  iconWrapper: {
    width: 24,
    height: 24,
  },
  payment_button: {
    backgroundColor: '#fff',
    border: '1px solid #2628421F',
    borderRadius: 8,
    marginLeft: 'auto',
    padding: '6px 12px',
    cursor: 'pointer',
  },
  payment_button_text: {
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
});
