import makeStyles from '@mui/styles/makeStyles';

type Props = {
  isSigned: boolean
}

export default makeStyles(() => ({
  card: {
    width: '100%',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 12,
    padding: '8px 12px 8px 12px',
    borderRadius: 12,
    border: '1px solid #2628421F',
    background: '#FFFFFF',
  },
  cardInfo: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 12,
  },
  cardIconWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardIcon: {
    width: 32,
    height: 32,
    minWidth: 32,
    minHeight: 32,
  },
  cardText: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  documentName: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '18px',
    color: '#007994',
  },
  documentInfo: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
    color: '#6D6E85',
  },
  stepInfo: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
    color: '#6D6E85',
    marginTop: 8,
    '& span': {
      fontWeight: 500,
    },
  },
  signatureStatus: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
    color: ({ isSigned }: Props) => isSigned ? '#262842' : '#6D6E85',
  },
  cardInfoTitle: {
    fontWeight: 500,
    color: '#6D6E85',
  },
  documentStatusIcon: {
    height: 16,
    width: 16,
    marginRight: 4,
    display: 'inline',
    marginBottom: 4,
  },
  cardActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 4,
  },
  button: {
    height: 32,
    width: 'max-content',
    borderRadius: '8px !important',
  },
  iconButton: {
    height: 32,
    width: 32,
  },
  error: {
    color: '#D6331F'
  },
  errorIcon: {
    height: 16,
    width: 16,
    marginRight: 4,
    display: 'inline',
    marginBottom: -4,
  }
}));
