import React, { memo } from 'react';
import { TextField } from '@mui/material';
import NumberFormat from 'react-number-format';
import { Controller, useFormContext } from 'react-hook-form';

import useNumber from './useNumber';

import {
  NUMBER__DECIMAL_SCALE,
  NUMBER__DECIMAL_SEPARATOR,
  NUMBER__THOUSAND_SEPARATOR,
} from './constants';

type Props = {
  name: string;
  hint: string;
  rules: { [key: string]: string };
  params: {
    mask?: string;
    allowNegative?: boolean;
    allowLeadingZeros?: boolean;
    placeholder?: string;
  };
  isActive: boolean;
  isDisabled: boolean;
};

export const Number = memo(({
  hint,
  name,
  rules,
  params,
  isActive,
  isDisabled,
}: Props) => {
  const { watch, control, formState: { errors } } = useFormContext();

  const {
    disabled,
    validationRules
  } = useNumber({
    name,
    rules,
    isActive,
    isDisabledParam: isDisabled
  });

  return (
    <Controller
      control={control}
      name={name}
      rules={validationRules}
      render={({ field: { value, onChange }}) => (
        <>
          <label>{hint}</label>
        <NumberFormat
          fullWidth
          // label={hint}
          value={watch(name)}
          defaultValue={value}
          disabled={disabled}
          inputProps={{'data-selenium': name}}
          customInput={TextField}
          placeholder={params?.placeholder || ''}
          decimalScale={NUMBER__DECIMAL_SCALE}
          decimalSeparator={NUMBER__DECIMAL_SEPARATOR}
          thousandSeparator={NUMBER__THOUSAND_SEPARATOR}
          format={params.mask || null}
          allowNegative={!!params.allowNegative}
          allowLeadingZeros={!!params.allowLeadingZeros}
          error={!!errors[name]}
          helperText={!!errors[name] && errors[name].message}
          onChange={onChange}
        />
          </>
      )}
    />
  );
});
