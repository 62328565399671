import React from "react"
import useStyles from "./useStyles"
import { useTranslation } from "react-i18next";
import AddIcon from "assets/images/icons/add_circle_filled.svg"
import { Typography } from "@mui/material";

type Props = {
  onClick: () => void
}

export const CreateFromScratchCard = ({onClick}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return <div className={classes.root} onClick={onClick}>

    <div className={classes.addIconWrapper}>
      <img src={AddIcon} alt="add icon" />
    </div>

    <Typography className={classes.title}>{t("customProcesses.createFromScratch")}</Typography>
    <Typography className={classes.description}>{t("customProcesses.createProcessOrUseTemplate")}</Typography>
  </div>
}
