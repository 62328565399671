import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';

import { DocumentDetails } from '@dar-dms/utils';
import { useUserProfile } from 'hooks';
import useTaskDocuments from './useTaskDocuments';
import DocumentCard from '../../../../DocumentsView/DocumentCard';

import useStyles from './useStyles';
import { useTaskState } from '../../../../../store/requests';

type Props = {
  className?: string;
  activeTab?: string;
  formMethods?: any;
  checkValidationErrors?: () => boolean;
  setErrorMessageLines?: (value: ('fields' | 'documents')[]) => void;
};

export const DocumentsTab = ({ className, activeTab, formMethods, checkValidationErrors, setErrorMessageLines }: Props) => {
  const { t } = useTranslation();
  const { data: bpmTask } = useTaskState();
  const { id } = useUserProfile();
  const docflowDocuments = useWatch({ name: 'docFlowDocuments' });
  const {
    groupedDocuments,
    docflowDocumentsForSignature,
    docflowDocumentsForView,
    currentLoadingDocumentId,
    currentDocumentId,
    documentViewMode,
    documentsForSignature,
    signedDocuments,
    isSignatureAvailable,
    openDocumentForSignWithValidation,
    openDocumentForPreviewWithValidation,
    openDocumentForSign,
    downloadDocument,
    changeCurrentDocument,
    onSignComplete,
    beforeDocumentSign,
    handleDocumentClose,
    selectedDocflowDocument,
    previewDocument,
  } = useTaskDocuments({
    fetchDocflowDocuments: true,
    showDeletedDocumentsError: true,
    formMethods,
    checkValidationErrors,
    setErrorMessageLines,
    docflowDocuments,
  });

  const classes = useStyles();

  return (
    <div className={cn(className, classes.tabContentWrapper)}>
      {groupedDocuments?.sign.length > 0 || docflowDocumentsForSignature.length ? (
        <div className={classes.documentsListSection}>
          <h3 className={classes.documentsListSectionTitle}>{t('new_request.summary.documents.signingRequired')}</h3>
          <div className={classes.documentsListWrapper}>
            {groupedDocuments?.sign?.map((document) => (
              <DocumentCard
                documentId={document.docflowDocumentId}
                documentName={document.name}
                fileType={'pdf'}
                size={document.size}
                creationDate={document.dataCreate}
                stepName={t(`constructor-${bpmTask.processSysName}.actions.${bpmTask.currentAction.sysName}.name`, {
                  defaultValue: bpmTask.currentAction.name,
                })}
                needSignature={bpmTask.isAssignee(id, false) && isSignatureAvailable}
                isSigned={signedDocuments.includes(document?.docflowDocumentId) || document?.isSigned}
                isFullySigned={document.signings.every(
                  (signing) => signing.status || (signing.stepOrder === bpmTask.currentAction.stepperOrder && document.isSigned)
                )}
                hasSignatures={document.isSigned || document.signings.some((v) => v.status)}
                isPublished={document?.isPublished}
                isLoading={document.docflowDocumentId === currentLoadingDocumentId}
                onSign={openDocumentForSignWithValidation}
                onPreview={openDocumentForPreviewWithValidation}
                onDownload={downloadDocument}
              />
            ))}
            {docflowDocumentsForSignature.map((doc) => (
              <DocumentCard
                documentId={doc.id}
                documentName={doc.title}
                fileType={'pdf'}
                size={0}
                creationDate={doc.createdAt}
                stepName={t(`constructor-${bpmTask.processSysName}.actions.${bpmTask.currentAction.sysName}.name`, {
                  defaultValue: bpmTask.currentAction.name,
                })}
                isSigned={doc.status === 'SIGNED' || doc.signatories.find((user) => user.userId === id)?.isSigned}
                isFullySigned={!!doc.signatories.length && doc.signatories.every((item) => item.isSigned)}
                isPublished={doc.status !== 'DRAFT'}
                isLoading={doc.id === currentLoadingDocumentId}
                hasSignatures={doc.status === 'SIGNED' || doc.signatories.some((user) => user.isSigned)}
                needSignature={
                  bpmTask.isAssignee(id, false) && isSignatureAvailable && !doc.signatories.find((user) => user.userId === id)?.isSigned
                }
                onSign={openDocumentForSign}
                onDownload={downloadDocument}
                onPreview={previewDocument}
                isDocflowDocument
              />
            ))}
          </div>
        </div>
      ) : null}

      {/* {currentDocumentId >= 0 && (
        <DocumentDetails
          id={currentDocumentId}
          isPreview={documentViewMode === 'view'}
          documentsList={documentsForSignature.includes(currentDocumentId || currentLoadingDocumentId) ? documentsForSignature : []}
          signedDocumentsList={signedDocuments}
          handleCurrentDocumentChange={changeCurrentDocument}
          onSignSuccess={onSignComplete}
          beforeSign={beforeDocumentSign}
          onClose={handleDocumentClose}
        />
      )} */}

      {groupedDocuments?.view.length > 0 || docflowDocumentsForView.length ? (
        <div className={classes.documentsListSection}>
          <h3 className={classes.documentsListSectionTitle}>{t('new_request.summary.documents.noSignature')}</h3>
          <div className={classes.documentsListWrapper}>
            {groupedDocuments?.view?.map((document) => (
              <DocumentCard
                documentId={document.docflowDocumentId}
                documentName={document.name}
                fileType={'pdf'}
                size={document.size}
                creationDate={document.dataCreate}
                stepName={t(`constructor-${bpmTask.processSysName}.actions.${bpmTask.currentAction.sysName}.name`, {
                  defaultValue: bpmTask.currentAction.name,
                })}
                isLoading={document.docflowDocumentId === currentLoadingDocumentId}
                needSignature={false}
                hasSignatures={document.isSigned || document.signings.some((v) => v.status)}
                isSigned={false}
                isFullySigned={document.signings.every(
                  (signing) => signing.status || (signing.stepOrder === bpmTask.currentAction.stepperOrder && document.isSigned)
                )}
                isPublished={document?.isPublished}
                onPreview={openDocumentForPreviewWithValidation}
                onDownload={downloadDocument}
              />
            ))}
            {docflowDocumentsForView.map((doc) => (
              <DocumentCard
                documentId={doc.id}
                documentName={doc.title}
                fileType={'pdf'}
                size={0}
                creationDate={doc.createdAt}
                stepName={t(`constructor-${bpmTask.processSysName}.actions.${bpmTask.currentAction.sysName}.name`, {
                  defaultValue: bpmTask.currentAction.name,
                })}
                isSigned={doc.status === 'SIGNED' || doc.signatories.find((user) => user.userId === id)?.isSigned}
                isFullySigned={doc.signatories.every((item) => item.isSigned)}
                isPublished={doc.status !== 'DRAFT'}
                hasSignatures
                needSignature={false}
                isLoading={doc.id === currentLoadingDocumentId}
                onPreview={previewDocument}
                onDownload={downloadDocument}
                isDocflowDocument
              />
            ))}
          </div>
        </div>
      ) : null}

      {(selectedDocflowDocument || currentDocumentId >= 0) && (
        <DocumentDetails
          id={currentDocumentId >= 0 ? currentDocumentId : selectedDocflowDocument}
          isPreview={documentViewMode === 'view'}
          documentsList={[...documentsForSignature, ...docflowDocumentsForSignature.map((doc) => doc.id)]}
          signedDocumentsList={[
            ...signedDocuments,
            ...docflowDocumentsForSignature
              .filter((doc) => doc.signatories.find((signatory) => signatory.userId === id && signatory.isSigned))
              .map((doc) => doc.id),
            ...docflowDocumentsForView
              .filter((doc) => doc.signatories.find((signatory) => signatory.userId === id && signatory.isSigned))
              .map((doc) => doc.id),
          ]}
          handleCurrentDocumentChange={changeCurrentDocument}
          beforeSign={beforeDocumentSign}
          onSignSuccess={documentViewMode === 'view' ? null : onSignComplete}
          onClose={handleDocumentClose}
        />
      )}
    </div>
  );
};
