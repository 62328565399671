import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ spacing }) => ({
  dialogTitle: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.3,
    color: '#262842'
  },
  dialogSubtitle: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.3,
    color: '#8B8C9E',
    margin: spacing(5, 0, 7)
  },
  dialogIcon: {
    '& > path': {
      fill: '#6D7C8B'
    }
  },
  dialogActions: {
    height: 64,
    backgroundColor: '#F7F8FC'
  }
}));
