import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  title: {
    color: '#262842',
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: 500,
  },
  disabledCloseButton: {
    opacity: 0.5,
  },
}));

export default useStyles;
