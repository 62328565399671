import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => ({
  closeButton: {
    width: 20,
    height: 20,
    padding: "8px !important",
    boxSizing: "content-box",
    borderRadius: "8px !important",
    border: "2px solid transparent !important",
  },
}))
