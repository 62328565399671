/**
 * @param {object} schema - Yup schema
 * @param {*} check
 * @returns {boolean}
 */
export const safeCheckValidity = (schema, check) => {
  try {
    return schema.isValidSync(check);
  } catch (e) {}
  return false;
};
