import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ spacing }) => ({
  contentWrapper: {
    width: 'fit-content',
    minWidth: '100%',
  },
  listWrapper: {
    width: 'fit-content',
    minWidth: '100%',
  },
  processStepsListTitle: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 18,
    color: '#101F2B',
    margin: '0 auto 16px',
    maxWidth: 280,
  },
  processStepListItem: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0,
  },
  processStepListButton: {
    position: 'relative',

    height: 48,
    width: '100%',
    maxWidth: 'none',


    border: '1px dashed #A9A9B8',
    borderRadius: 12,

    padding: spacing(4),

    color: '#262842',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,

    transition: '0.3s background-color ease',

    '&::after': {
      content: '""',
      position: 'absolute',
      top: -24,
      left: '50%',

      height: 24,
      borderTop: '1px dashed #A9A9B8',
    },

    '&:hover': {
      backgroundColor: '#F5F8FA',
    },

    '&:focus': {
      backgroundColor: '#E6ECF1',
    },

    '&.Mui-disabled': {
      border: '1px dashed rgba(38, 40, 66, 0.12)',
      background: 'transparent',
      color: '#6D6E85',
    },
  },
  pathEnd: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    padding: 10,
    marginTop: 12,
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: -60,
      left: '50%',

      height: 60,
      borderLeft: '1px dashed #A9A9B8',
    },
  },
  pathEndPreview: {
    '&:before': {
      top: '-20px !important',
      height: '20px !important',
    },
  },
  pathEndIcon: {
    width: 24,
    height: 24,
  },
  pathEndText: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    color: '#6D6E85',
  },
  addButtonWrapper: {
    position: 'relative',
    height: 32,
    width: '100%',
  },
  addButton: {
    zIndex: 1,
    position: 'absolute',
    top: 20,
    left: 'calc(50% - 11px)',
    marginLeft: -0.5,

    display: 'block',
    height: 24,
    width: 24,

    backgroundColor: '#FFFFFF !important',
    border: '1px solid rgba(38, 40, 66, 0.12) !important',
    borderRadius: '50% !important',
    transition: '0.3s background-color ease',

    '&:hover': {
      backgroundColor: '#F5F8FA !important',
    },

    '&:focus': {
      backgroundColor: '#E6ECF1 !important',
    },
  },
  addIcon: {
    height: 12,
    width: 12,
    minHeight: 12,
    minWidth: 12,
    marginLeft: -6,
    marginTop: -6
  },
  maximumStepsReachedMessageWrapper: {
    minWidth: 400,
    height: 52,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
    zIndex: 20,
    background: '#F7F8FC',
    border: '1px dashed #A9A9B8',
    borderRadius: 12,
  },
  maximumStepsReachedMessageText: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 18,
    color: '#6D6E85',
  },
  pathEndLimitReached: {
    marginTop: 20,
    '&:before': {
      top: '-92px !important',
      height: '92px !important',
    },
  },
}));
