import React, { ReactElement, useMemo, useState } from 'react';
import {
  Box,
  IconButton,
  Tooltip,
  ButtonGroup, Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import useDocumentFormStyles from './DocumentsForm.useStyles';
import {
  ConditionStep,
  DocumentTemplate,
  Language,
  ParallelBranchesGroup,
  ProcessStep,
} from '../../TemplateProcesses.types';
import { getDisplayedStepOrder } from '../../TemplateProcesses.utils';
import FieldHiddenIconDocument from 'assets/images/icons/eye_hide_regular.svg';
import FieldVisibleIconDocument from 'assets/images/icons/template-process-field-shown.svg';

import DragIndicatorVertical from 'assets/images/icons/drag-indicator-vertical.svg';
import FullyLinkedFieldsIcon from 'assets/images/icons/checkmark_circle_regular.svg';
import PreviewIcon from 'assets/images/icons/preview_link_regular.svg';
import EditIcon from 'assets/images/icons/template-process-field-edit.svg';
import DeleteIcon from 'assets/images/icons/delete_regular.svg';
import InfoIcon from '../../../../assets/images/icons/info_outline.svg';
import ErrorIcon from 'assets/images/icons/warning-outlined-circle.svg';
import { DeleteDocumentConfirmationModal } from '../DeleteDocumentConfirmationModal';
import classNames from 'classnames';
import FieldHiddenIcon from '../../../../assets/images/icons/eye_hide_regular.svg';
import FieldVisibleIcon from '../../../../assets/images/icons/template-process-field-shown.svg';

interface DocumentsFormCardProps {
  document: DocumentTemplate;
  focusedDocumentId: string;
  currentProcessStepOrder: number;
  processSteps: ProcessStep[];
  conditions: ConditionStep[];
  parallelBranchesGroups: ParallelBranchesGroup[];
  dragHandleProps: any;
  hasNoSignatureError?: boolean;
  isEditButtonHidden?: boolean;
  isEditDisabled: boolean;
  areActionsAvailable?: boolean;
  gridPathRef: any;
  currentLanguage: Language;
  onVisibilityChange: (isVisible: boolean) => void;
  onEdit: () => void;
  onPreview: () => void;
  onDelete: () => void;
  handleProcessSelect: (step: ProcessStep) => void;
  onVisibilityButtonClick?: (p: object) => void;
  shouldDisplaySection?: boolean;
  isParallelBranchesGroupStep?: boolean;
}

export const DocumentsFormCard = ({
  document,
  currentProcessStepOrder,
  processSteps,
  conditions,
  parallelBranchesGroups,
  currentLanguage,
  focusedDocumentId,
  dragHandleProps,
  isEditButtonHidden = false,
  hasNoSignatureError = false,
  areActionsAvailable = true,
  onEdit,
  gridPathRef,
  isEditDisabled,
  onVisibilityChange,
  onPreview,
  onDelete,
  handleProcessSelect,
                                    shouldDisplaySection,
                                    isParallelBranchesGroupStep,
}: DocumentsFormCardProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useDocumentFormStyles();

  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const handleCardClick = (event) => {
     if(shouldDisplaySection) {
       return;
     }
    if (
      !event.target.className?.includes?.('CARD_ACTION') &&
      !event.target.className?.includes?.('TOOLTIP') &&
      !event.target.className?.includes?.('MuiTooltip') &&
      !isEditDisabled &&
      areActionsAvailable
    ) {
      onEdit();
    }
  };

  const keyExists = localStorage.getItem('hiddenIds') !== null;
  const isFirstAppearanceStep = useMemo(() =>
      document?.firstAppearanceStep === currentProcessStepOrder
    , [document, currentProcessStepOrder]);
  const isHidden = useMemo(() =>
      document?.hiddenSteps.includes(currentProcessStepOrder)
    , [document, currentProcessStepOrder]);
  const step = processSteps?.find(s => s?.stepOrder === document?.firstAppearanceStep);

  const tooltipTitle = useMemo(() => {
    const step = processSteps?.find(s => s?.stepOrder === document?.firstAppearanceStep);

    const stepNumber = getDisplayedStepOrder(step, conditions, parallelBranchesGroups);

    if (step?.stepOrder === currentProcessStepOrder) {
      return <>
        {t('customProcesses.creationPage.processForm.cardTooltips.fieldAddedOnCurrentStep')} {stepNumber} - {step?.stepName[currentLanguage] || t('customProcesses.processStep.stepNamePlaceholder')}
      </>;
    }

    return <>
      {t('customProcesses.creationPage.processForm.cardTooltips.fieldInheritedFromStep')} {stepNumber} - {step?.stepName[currentLanguage] || t('customProcesses.processStep.stepNamePlaceholder')}

      <br/>

      <Button
        variant="text"
        onClick={() => {
          handleProcessSelect(step);

          setTimeout(() => {
            const activeStep = gridPathRef.current.querySelector('div[class*=processStepCardActive]');
            activeStep.scrollIntoView({ behavior: 'smooth' });
          }, 500);
        }}
        className={cn('TOOLTIP', classes.goToStepButton)}
      >
        <span className="TOOLTIP">
           {t('customProcesses.creationPage.processForm.cardTooltips.goToStep')} {stepNumber}
        </span>
      </Button>
    </>;

  }, [currentLanguage, currentProcessStepOrder, processSteps, document]);

  return <>
    <Box
      className={cn(classes.documentCard, {
        [classes.documentCardActive]: focusedDocumentId === document?.uniqueId,
        [classes.documentCardError]: hasNoSignatureError,
      })}
      onClick={handleCardClick}
    >
      <div className={classes.dragAndDropIndicator}>
        <img src={DragIndicatorVertical} alt="" {...dragHandleProps}/>
      </div>

      <div className={classes.cardContent}>
        <div className={classes.cardTopRowWrapper}>
      <span className={classes.documentName}>
        {document?.title}
        <Tooltip
          placement="bottom"
          title={<span className="TOOLTIP">{tooltipTitle}</span>}
          classes={{
            tooltipArrow: classes.tooltip,
            arrow: classes.tooltipArrow,
            tooltip: classes.fieldTooltip,
          }}
        >
            <img src={InfoIcon} alt="" className={cn(classes.infoIcon, 'TOOLTIP')}/>
          </Tooltip>
      </span>
          {document?.signatureSteps.includes(currentProcessStepOrder) &&
            <span className={classes.signatureChip}>
            {t('customProcesses.creationPage.processForm.documentCard.signatureRequired')}
          </span>
          }
        </div>
        <span className={classes.documentStatus}>
        {document?.fields?.filter(field => field.processFieldId)?.length === document?.fields?.length &&
          <img src={FullyLinkedFieldsIcon} alt={''} className={classes.documentStatusIcon}/>
        }
          <span className={classes.documentStatusText}>
            {t('customProcesses.creationPage.processForm.documentCard.fieldsSetUp', {
              setUpFields: document?.fields?.filter(field => field.processFieldId)?.length,
              totalFields: document?.fields?.length,
            })}
        </span>
      </span>
      </div>

      {(!isEditDisabled && areActionsAvailable) &&
        <div className={classes.cardActions}>
          <ButtonGroup size="small" aria-label="small button group">
            <Tooltip
              title={t('customProcesses.creationPage.processForm.cardTooltips.previewDocument')}
              placement="bottom"
              arrow
              classes={{
                tooltipArrow: classes.tooltip,
                arrow: classes.tooltipArrow,
                tooltip: classes.fieldTooltip,
              }}>
              <IconButton
                className={cn(classes.documentActionsButton, 'CARD_ACTION')}
                onClick={() => {
                  onPreview();
                }
                }
                size="large">
                <img src={PreviewIcon} alt={t('customProcesses.creationPage.processForm.cardTooltips.previewDocument')}
                     className="CARD_ACTION"/>
              </IconButton>
            </Tooltip>

            {(!isEditButtonHidden && !isParallelBranchesGroupStep) && <Tooltip
              title={t('customProcesses.creationPage.processForm.cardTooltips.editDocument')}
              placement="bottom"
              arrow
              classes={{
                tooltipArrow: classes.tooltip,
                arrow: classes.tooltipArrow,
                tooltip: classes.fieldTooltip,
              }}>
              <IconButton
                className={cn(classes.documentActionsButton, 'CARD_ACTION')}
                onClick={onEdit}
                size="large">
                <img src={EditIcon} alt={t('customProcesses.creationPage.processForm.cardTooltips.editDocument')}
                     className="CARD_ACTION"/>
              </IconButton>
            </Tooltip>
            }
            {!isFirstAppearanceStep && (
              isHidden
                ?
                <Tooltip
                  title={t('customProcesses.creationPage.processForm.cardTooltips.showDocument')}
                  placement="bottom"
                  arrow
                  classes={{
                    tooltipArrow: classes.tooltip,
                    arrow: classes.tooltipArrow,
                    tooltip: classes.fieldTooltip,
                  }}>
                  <IconButton
                    className={cn(classes.documentActionsButton, 'CARD_ACTION')}
                    onClick={() => onVisibilityChange(true)}
                  >
                    <img src={FieldHiddenIconDocument} alt={t('customProcesses.creationPage.processForm.cardTooltips.showDocument')}
                         className="CARD_ACTION"/>
                  </IconButton>
                </Tooltip>
                :
                <Tooltip
                  title={t('customProcesses.creationPage.processForm.cardTooltips.hideDocument')}
                  placement="bottom"
                  arrow
                  classes={{
                    tooltipArrow: classes.tooltip,
                    arrow: classes.tooltipArrow,
                    tooltip: classes.fieldTooltip,
                  }}>
                  <IconButton
                    className={cn(classes.documentActionsButton, 'CARD_ACTION')}
                    onClick={() => onVisibilityChange(false)}
                  >
                    <img src={FieldVisibleIconDocument} alt={t('customProcesses.creationPage.processForm.cardTooltips.hideDocument')}
                         className="CARD_ACTION"/>
                  </IconButton>
                </Tooltip>
            )}

            {isFirstAppearanceStep &&
              <Tooltip
                title={t('customProcesses.creationPage.processForm.cardTooltips.deleteDocument')}
                placement="bottom"
                arrow
                classes={{
                  tooltipArrow: classes.tooltip,
                  arrow: classes.tooltipArrow,
                  tooltip: classes.fieldTooltip,
                }}>
                <IconButton
                  className={cn(classes.documentActionsButton, 'CARD_ACTION')}
                  onClick={() => setConfirmationModalOpen(true)}
                  size="large">
                  <img src={DeleteIcon} alt={t('customProcesses.creationPage.processForm.cardTooltips.deleteDocument')}
                       className="CARD_ACTION"/>
                </IconButton>
              </Tooltip>
            }
          </ButtonGroup>
        </div>
      }
    </Box>

    {hasNoSignatureError &&
      <div className={classes.noSignaturesError}>
        <img src={ErrorIcon} alt={''}/>
        <span>{t('customProcesses.creationPage.processForm.documentWithoutSignaturesError')}</span>
      </div>
    }

    <DeleteDocumentConfirmationModal
      isOpen={isConfirmationModalOpen}
      onClose={() => setConfirmationModalOpen(false)}
      onConfirm={onDelete}
    />
  </>;
};
