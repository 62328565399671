import { useEffect, useState, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { getEmployees } from 'api/requests';
import { sortArrayOfObjects } from 'utils/general';

const useEmployeeSelect = name => {
  const {
    control,
    setValue: setFormValue,
    watch,
    trigger,
    formState: {
      errors
    }
  } = useFormContext();

  const isInitialMount = useRef(true);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(watch(name) ? { name: watch(name) } : null);
  const [inputValue, setInputValue] = useState(watch(name) || '');

  const [company, centralCompany] = watch(['company', 'centralCompany']);

  useEffect(() => {
    const clearSelectedValue = () => {
      if (!isInitialMount.current) {
        setValue(null);
        setInputValue('');
      } else {
        isInitialMount.current = false;
      }
    };

    const params = {
      company: company || centralCompany || ''
    };

    setLoading(true);
    getEmployees(params)
      .then(data => {
        clearSelectedValue();
        const list = data.sort((a, b) => sortArrayOfObjects(a, b, 'name'));
        setOptions(list);
      })
      .catch(() => {
        setValue(null);
        setInputValue('');
        setOptions([]);
      }).finally(() => {
        setLoading(false);
      })
  }, [name, company, centralCompany]);

  const handleChange = (_, val) => {
    setValue(val);
    setFormValue(name, (val && val.name) || '');

    errors[name] && trigger(name);
  };

  const handleInputChange = (_, val) => {
    setInputValue(val);
    setFormValue(name, (value && value.name) || '');

    errors[name] && trigger(name);
  };

  const getOptionLabel = option => (option && option.name) || '';
  const getOptionSelected = (option, value) => value && option.name === value.name;

  return {
    loading,
    options,
    value,
    control,
    inputValue,
    errors,
    handleChange,
    handleInputChange,
    getOptionLabel,
    getOptionSelected
  };
};

export default useEmployeeSelect;
