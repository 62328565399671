import { createTheme, adaptV4Theme, DeprecatedThemeOptions, PaletteColor } from '@mui/material/styles';
import { theme } from '@dartech/dms-ui';
import type {} from '@mui/x-tree-view';
import type {} from '@mui/x-date-pickers';

// TODO: Update whole primary color in theme
const newPrimary = '#039BE6';
const newPrimaryHover = '#0692D9';
const newPrimaryActive = '#088DD2';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const customTheme = createTheme(adaptV4Theme(theme));

customTheme.palette = {
  ...customTheme.palette,
  primary: {
    light: '#007994',
    dark: '#007994',
    main: '#007994',
    contrastText: '#007994',
  },
};

customTheme.components = {
  ...customTheme.components,
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        width: 'auto',
      },
      option: {
        '&.Mui-focused': {
          backgroundColor: '#F5F8FA !important',
        },
      },
      popupIndicator: {
        color: '#8B8C9E !important',
      },
      clearIndicator: {
        color: '#8B8C9E !important',
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      paper: {
        marginTop: '4px !important',
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        padding: '11px 7px',
        '&:hover': {
          backgroundColor: '#F5F8FA',
        },
        '&:focus': {
          backgroundColor: '#F5F8FA',
        }
      },
    },
  },
  MuiButton: {
    defaultProps: {
      variant: 'contained',
    },
    variants: [
      {
        props: { variant: 'contained', color: 'primary' },
        style: {
          color: `${theme.palette.common.white} !important`,
          backgroundColor: `${newPrimary} !important`,
          border: `1px solid ${newPrimary} !important`,
          '& path': {
            fill: `${theme.palette.common.white} !important`,
          },
          '&:hover': {
            backgroundColor: `${newPrimaryHover} !important`,
            borderColor: `${newPrimaryHover} !important`,
          },
          '&:active': {
            backgroundColor: `${newPrimaryActive} !important`,
            borderColor: `${newPrimaryActive} !important`,
          },
          '&.Mui-disabled': {
            backgroundColor: `${theme.palette.grey[300]} !important`,
            borderColor: `${theme.palette.grey[300]} !important`,
            color: `${theme.palette.common.white} !important`,
          },
        },
      },
      {
        props: { variant: 'contained', color: 'secondary' },
        style: {
          color: `${theme.palette.grey[700]}`,
          backgroundColor: `${theme.palette.grey[50]}`,
          border: `1px solid ${theme.palette.grey[300]} !important`,
          borderColor: `${theme.palette.grey[300]}`,
          '& path': {
            fill: `${theme.palette.common.black}`,
          },
          '&:hover': {
            backgroundColor: `${theme.palette.grey[100]}`,
            borderColor: `${theme.palette.grey[300]}`,
          },
          '&:active': {
            backgroundColor: `${theme.palette.grey[200]}`,
            borderColor: `${theme.palette.grey[300]}`,
          },
          '&.Mui-disabled': {
            color: `${theme.palette.grey[400]}`,
            backgroundColor: `${theme.palette.grey[50]}`,
            '& path': {
              fill: `${theme.palette.grey[400]}`,
            },
          },
        },
      },
      {
        props: { variant: 'outlined', color: 'primary' },
        style: {
          color: newPrimary,
          backgroundColor: 'transparent',
          border: `1px solid ${newPrimary}`,
          borderColor: newPrimary,
          '& path': {
            fill: newPrimary,
          },
          '&:hover': {
            color: theme.palette.common.white,
            backgroundColor: newPrimaryHover,
            borderColor: newPrimaryHover,
            '& path': {
              fill: theme.palette.common.white,
            },
          },
          '&:active': {
            color: theme.palette.common.white,
            backgroundColor: newPrimaryActive,
            borderColor: newPrimaryActive,
            '& path': {
              fill: theme.palette.common.white,
            },
          },
          '&.Mui-disabled': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.grey[300],
            borderColor: theme.palette.grey[300],
            '& path': {
              fill: theme.palette.common.white,
            },
          },
        },
      },
      {
        props: { variant: 'outlined', color: 'secondary' },
        style: {
          color: theme.palette.grey[700],
          backgroundColor: theme.palette.grey[50],
          border: `1px solid ${theme.palette.grey[300]}`,
          borderColor: theme.palette.grey[300],
          '& path': {
            fill: theme.palette.common.black,
          },
          '&:hover': {
            color: theme.palette.grey[700],
            backgroundColor: theme.palette.grey[100],
            borderColor: theme.palette.grey[300],
            '& path': {
              fill: theme.palette.common.black,
            },
          },
          '&:active': {
            color: theme.palette.grey[700],
            backgroundColor: theme.palette.grey[200],
            borderColor: theme.palette.grey[300],
            '& path': {
              fill: theme.palette.common.black,
            },
          },
          '&.Mui-disabled': {
            color: theme.palette.grey[400],
            backgroundColor: theme.palette.grey[50],
            borderColor: theme.palette.grey[300],
            '& path': {
              fill: theme.palette.grey[400],
            },
          },
        },
      },
      {
        props: { variant: 'text', color: 'primary' },
        style: {
          color: theme.palette.primary[200],
          backgroundColor: 'transparent',
          '& path': {
            fill: theme.palette.primary[200],
          },
          '&:hover': {
            color: theme.palette.primary[300],
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            '& path': {
              fill: theme.palette.primary[300],
            },
          },
          '&:active': {
            color: theme.palette.primary[200],
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            '& svg path': {
              fill: theme.palette.primary[200],
            },
          },
          '&.Mui-disabled': {
            color: theme.palette.grey[400],
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            '& path': {
              fill: theme.palette.grey[400],
            },
          },
        },
      },
      {
        props: { variant: 'text', color: 'secondary' },
        style: {
          color: theme.palette.grey[700],
          backgroundColor: 'transparent',
          border: '1px solid transparent',
          borderColor: 'transparent',
          '& path': {
            fill: theme.palette.common.black,
          },
          '&:hover': {
            color: theme.palette.grey[700],
            backgroundColor: theme.palette.grey[100],
            borderColor: theme.palette.grey[100],
            '& path': {
              fill: theme.palette.common.black,
            },
          },
          '&:active': {
            color: theme.palette.grey[700],
            backgroundColor: theme.palette.grey[200],
            borderColor: theme.palette.grey[200],
            '& svg path': {
              fill: theme.palette.common.black,
            },
          },
          '&.Mui-disabled': {
            color: theme.palette.grey[400],
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            '& path': {
              fill: theme.palette.grey[400],
            },
          },
        },
      },
    ],
    styleOverrides: {
      /* Styles applied to the root element. */
      root: {
        boxSizing: 'border-box',
        margin: 0,
        height: 32,
        borderRadius: '10px',
        outline: 'none',
        padding: theme.spacing(1, 3),
        textTransform: 'none',
        fontFamily: theme.typography.button.fontFamily,
        fontSize: theme.typography.button.fontSize,
        boxShadow: 'none !important',
        lineHeight: '24px',
        '&.MuiButton-containedSecondary path': {
          fill: 'inherit',
        },
      },
      /* Styles applied to the root element if `size="small"` and `variant="contained"`. */
      sizeSmall: {
        height: 24,
        padding: theme.spacing(0.5, 2),
        fontSize: '12px',
        lineHeight: '18px',
      },
      /* Styles applied to the root element if `size="large"` and `variant="contained"`. */
      sizeLarge: {
        height: 40,
        padding: theme.spacing(2, 4),
        fontSize: theme.typography.button.fontSize,
        lineHeight: '24px',
      },
      /* Styles applied to the startIcon element if supplied. */
      startIcon: {
        display: 'inherit',
        marginRight: 8,
        marginLeft: 0,
        '&.MuiButton-sizeSmall': {
          marginLeft: 0,
        },
      },
      /* Styles applied to the endIcon element if supplied. */
      endIcon: {
        display: 'inherit',
        marginRight: 0,
        marginLeft: 8,
        '&.MuiButton-sizeSmall': {
          marginRight: 0,
        },
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        color: '#8B8C9E',
        '&:hover': {
          backgroundColor: '#F5F8FA !important',
        },
        '& img': {
          maxWidth: 'none'
        }
      },
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        '&:not(.MuiRadio-root):not(.MuiCheckbox-root):not(.MuiSwitch-switchBase):not(.MuiAutocomplete-clearIndicator)': {
          '& path[fill="none"]': {
            fill: 'none !important',
          },
        },
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        '& .MuiSwitch-root': {
          marginRight: 6,
        },
      },
      labelPlacementStart: {
        marginLeft: 0,
        '& .MuiSwitch-root': {
          marginLeft: 6,
          marginRight: 0,
        },
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      body1: {
        fontFamily: 'inherit',
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        fontFamily: 'inherit',
      },
    },
  },
  // TODO: remove MuiCircularProgress styles, when @dartech/dms-ui is updated to 1.31.5 or higher version
  MuiCircularProgress: {
    styleOverrides: {
      root: {
        color: '#8B8C9E',
      },
      colorPrimary: {
        color: '#8B8C9E',
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        '&:hover': {
          backgroundColor: '#F5F8FA !important',
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      focused: {
        input: {
          borderColor: 'rgb(29, 43, 54)',
        },
      },
      input: {
        minHeight: 40,
        maxHeight: 40,
        boxSizing: 'border-box',
      },
      root: {
        '& legend': {
          display: 'none',
        },
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      select: {
        paddingTop: '10px !important',
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: 'outlined',
      InputLabelProps: { shrink: true },
    },
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-root': {
          borderRadius: '10px',

          '&.Mui-disabled': {
            backgroundColor: '#05057A0F',
          },
        },
        '& div:not(.MuiInputBase-multiline)': {
          '&.MuiOutlinedInput-root': {
            height: 40,
          },
        },
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      input: {
        paddingLeft: 8,
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        borderColor: 'inherit',
        backgroundColor: '#1E50FF0A',
        borderWidth: 0,
        top: 0,
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        padding: '0 4px',
        marginLeft: '-4px',
        position: 'relative',
        transform: 'none !important',
        color: '#6D6E85 !important',
      },
    },
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  MuiTreeItem: {
    styleOverrides: {
      content: {
        '&.Mui-selected': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        height: 24,
        width: 44 + 12,
        padding: 0,
        paddingLeft: 12,
      },
      track: {
        height: 24,
        width: 44,
        borderRadius: 12,
        backgroundColor: '#E8E8F1',
        opacity: '1 !important',
      },
      thumb: {
        marginTop: 2,
        marginLeft: 2,
        color: '#ffffff !important',
      },
      switchBase: {
        padding: 0,
        left: 12,
      },
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        color: '#8B8C9E !important',
      },
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      root: {
        '&:focus, &:hover': {
          backgroundColor: 'rgba(0, 121, 148, 0.12) !important',
        },
        '&.Mui-selected': {
          backgroundColor: 'rgba(0, 121, 148, 0.12) !important',
        },
      },
    },
  },
  MuiPickersMonth: {
    styleOverrides: {
      monthButton: {
        '&:focus, &:hover': {
          backgroundColor: 'rgba(0, 121, 148, 0.12) !important',
        },
        '&.Mui-selected': {
          backgroundColor: 'rgba(0, 121, 148, 0.12) !important',
        },
      },
    },
  },
  MuiPickersYear: {
    styleOverrides: {
      yearButton: {
        '&:focus, &:hover': {
          backgroundColor: 'rgba(0, 121, 148, 0.12) !important',
        },
        '&.Mui-selected': {
          backgroundColor: 'rgba(0, 121, 148, 0.12) !important',
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        '&:hover': {
          backgroundColor: '#F5F8FA !important',
        },
      },
    },
  },
};

customTheme.zIndex = {
  ...customTheme.zIndex,
  modal: 1200,
};

export default customTheme;
