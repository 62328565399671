import { parseJSON } from 'utils/general';

// TODO: this code was moved from FormButton component and useFieldBehaviour hook, needs to be refactored and types fixed;

interface IsButtonVisible {
  button: any;
  bpmTask: any;
  getFieldsByListOfRules: (listOfRules: any[]) => any[];
  isRuleGroupWorks: (fields: any[]) => boolean;
  isCompletedTask?: boolean;
  hasAssignee?: boolean;
  isAssignee?: boolean;
  isWatcher?: boolean;
  isAssigneeCandidate?: boolean;
  isInitiator?: boolean;
  showOnlyAllStepsButtons?: boolean;
}

export const isButtonVisible = ({
  button,
  bpmTask,
  getFieldsByListOfRules,
  isRuleGroupWorks,
  isCompletedTask,
  hasAssignee,
  isAssignee,
  isWatcher,
  isAssigneeCandidate,
  isInitiator,
  showOnlyAllStepsButtons,
}: IsButtonVisible) => {
  const {
    showOnlyToInitiator,
    showOnlyWithAssignee,
    showOnlyWithoutAssignee,
    hideOnAutostartProcesses,
    showOnAllRequestFormationFormSteps,
    showOnlyOnPredefinedSteps,
    hideOnPredefinedSteps,
    buttonVisibleSteps,
    buttonHiddenSteps,
    showOnCompletedTasks,
    showForWatchers,
    showToInitiator,
  } = button;

  const behaviour = parseJSON(button?.behaviour);

  const isVisible = (): boolean => {
    const visibilityFields = getFieldsByListOfRules(behaviour.visibleOn);

    if (visibilityFields.length) {
      return isRuleGroupWorks(visibilityFields);
    }

    return true;
  };

  const isInvisible = (): boolean => {
    const invisibilityFields = getFieldsByListOfRules(behaviour.invisibleOn);

    if (invisibilityFields.length) {
      return isRuleGroupWorks(invisibilityFields);
    }

    return false;
  };

  const assigneeConditionsResult =
    (!hasAssignee && isAssigneeCandidate && showOnlyWithoutAssignee) ||
    (isAssignee && hasAssignee && showOnlyWithAssignee) ||
    (isInitiator && (showOnlyToInitiator || showToInitiator)) ||
    (isWatcher && showForWatchers);

  const isShownOnThisStep =
    (showOnlyOnPredefinedSteps && buttonVisibleSteps?.includes(bpmTask?.currentAction.stepperOrder)) || !showOnlyOnPredefinedSteps;

  const isHiddenOnThisStep =
    (hideOnPredefinedSteps && buttonHiddenSteps?.includes(bpmTask?.currentAction.stepperOrder)) ||
    (hideOnAutostartProcesses && ['company', '${company}'].includes(bpmTask?.initiator));

  const stepsConditionsResult = isShownOnThisStep && !isHiddenOnThisStep;

  const completedTasksConditionResult =
    !(bpmTask?.isCompleted || bpmTask.businessTask?.taskStatus === 'Completed' || isCompletedTask) ||
    ((bpmTask?.isCompleted || bpmTask.businessTask?.taskStatus === 'Completed' || isCompletedTask) && !!showOnCompletedTasks);

  return (
    isVisible() &&
    !isInvisible() &&
    completedTasksConditionResult &&
    !(showOnlyAllStepsButtons && !showOnAllRequestFormationFormSteps) &&
    ((assigneeConditionsResult && stepsConditionsResult) ||
      (!showOnlyWithoutAssignee &&
        !showOnlyWithAssignee &&
        !showOnlyToInitiator &&
        !showOnlyOnPredefinedSteps &&
        !hideOnPredefinedSteps &&
        isAssignee) ||
      (bpmTask?.isCompleted && completedTasksConditionResult))
  );
};
