import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString, { ParsedQuery } from 'query-string';
import moment from 'moment';

import {
  INITIATOR_DEFAULT,
  CURRENT_PAGE_DEFAULT,
  PROCESS_DEFAULT,
  SEARCH_QUERY_DEFAULT,
  LEGAL_ENTITY_DEFAULT,
} from '../constants';

const useApprovalsHistory = () => {
  const { search } = useLocation();

  const {
    processName = PROCESS_DEFAULT,
    initiator = INITIATOR_DEFAULT,
    query = SEARCH_QUERY_DEFAULT,
    from,
    till,
    page = CURRENT_PAGE_DEFAULT,
    entity = LEGAL_ENTITY_DEFAULT,
  } = queryString.parse(search) as ParsedQuery<any>;

  const [dateFrom, setDateFrom] = useState(new Date(from));
  const [dateTo, setDateTo] = useState(new Date(till));
  const [currentPage, setCurrentPage] = useState(+page);

  const [searchQuery, setSearchQuery] = useState(query);

  const [processRequest, setProcessRequest] = useState(
    processName ? { name: processName } : PROCESS_DEFAULT
  );
  const [initiatorRequest, setInitiatorRequest] = useState(
    initiator ? { logName: initiator } : INITIATOR_DEFAULT
  );
  const [legalEntityRequest, setLegalEntity] = useState(
    entity ? { name: entity } : LEGAL_ENTITY_DEFAULT
  );

  const handleProcessChange = (newProcess) => setProcessRequest(newProcess);

  const handleInitiatorChange = (newInitiator) =>
    setInitiatorRequest(newInitiator);

  const handleLegalEntityChange = (legalEntity) => setLegalEntity(legalEntity);

  const handleSearchQueryChange = (newQuery) => setSearchQuery(newQuery);

  const handleDateFromChange = (date) =>
    setDateFrom(moment(date).startOf('day').toDate());

  const handleDateToChange = (date) =>
    setDateTo(moment(date).endOf('day').toDate());

  return {
    processRequest,
    initiatorRequest,
    legalEntityRequest,
    searchQuery,
    dateFrom,
    dateTo,
    currentPage,
    setCurrentPage,
    handleProcessChange,
    handleInitiatorChange,
    handleSearchQueryChange,
    handleDateFromChange,
    handleDateToChange,
    handleLegalEntityChange,
  };
};

export default useApprovalsHistory;
