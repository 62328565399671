export const URGENT_SWITCH_VALUE__DEFAULT = false;
export const URGENT_TEXTAREA_VALUE__DEFAULT = '';
export const URGENT_DECLINE_VALUE__DEFAULT = '';

export const URGENT_SWITCH_NAME = 'urgentSwitch';
export const URGENT_TEXTAREA_NAME = 'urgentReason';
export const URGENT_DECLINE_TEXTAREA_NAME = 'urgentDeclineReason';

const PAYMENTS_V1_SYS_NAME = 'P1-payment-new-ver-1';
const TIME_OFF_SYS_NAME = 'time_off';
const PROCUREMENT_SYS_NAME = 'procurement_v1';

export const URGENT_PROCESSES = [
  PAYMENTS_V1_SYS_NAME,
  TIME_OFF_SYS_NAME,
  PROCUREMENT_SYS_NAME
];
