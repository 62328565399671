import React  from "react"

import { Flow } from "./Flow"

export const TaskInfo = () => {
  return <div>

    <Flow />
  </div>
}
