import { baseV1ApiClient } from '../clients';
import { commonExceptionHandler } from '../handlers';
import { getProfileCompanyDataFromLocalStorage } from 'utils/user';

export const getGlossaryData = async (endpoint, params = {}) => {
  try {
    const { id: companyId, holdingId } = getProfileCompanyDataFromLocalStorage();

    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: `/camunda/${endpoint}`,
      params: {
        ...params,
        holdingId
      },
      headers: {
        companyId,
      },
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getGlossaryByCode = async (baseCode) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/camunda/integration-financial-system/dataStore',
      params: {
        baseCode
      }
    });

    return {
      baseCode,
      data: response?.data?.body
    };
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getExchangeRate = async () => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/camunda/integration-financial-system/exchangeRate',
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getEmployees = async params => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/camunda/integration-financial-system/employees',
      params
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getLegalEntities = async params => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/camunda/integration-financial-system/legalEntities',
      params
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const uploadNewEmployeeData = async data => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'PUT',
      url: '/camunda/integration-financial-system/company/newEmployee',
      data
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getCompanyDataById = async (companyId) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: `/camunda/integration-financial-system/hcms/companiesById`,
      headers: {
        companyId,
      },
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getProcessIconsUrlList = async (): Promise<string[]> => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/camunda/integration-financial-system/aws/urls',
      params: {
        fileGroup: 'custom'
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getUrlListByGroup = async (fileGroup: string): Promise<string[]> => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/camunda/integration-financial-system/aws/urls',
      params: {
        fileGroup
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};
