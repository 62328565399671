import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ palette, spacing }) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  icon: {
    height: 24,
    width: 24,
    marginRight: spacing(1)
  },
  label: {
    fontSize: 14,
    fontWeight: 400,
    color: palette.grey[700]
  },
  emptyValueLabel: {
    color: palette.grey[500]
  },
}));
