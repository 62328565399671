import makeStyles from '@mui/styles/makeStyles';
import theme from '@dartech/dms-ui';

export default makeStyles((theme) => ({
  urgentToggle: {
    width: 268,
    height: 40,
    borderRadius: 4,
    padding: 2,
    background: theme.palette.grey[200],
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
  },
  disabledToggle: {
    cursor: 'default',
  },
  option: {
    width: 132,
    height: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    transitionDuration: '500ms',
    transitionProperty: 'background-color',
  },
  selectedOption: {
    background: theme.palette.grey[50],
    transitionDuration: '500ms',
    transitionProperty: 'background-color',
  },
  optionIcon: {
    height: 17,
    marginRight: 12,
  },
}));
