import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  multipleSelectField: {
    display: 'flex'
  },
  iconSpan: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingLeft: '10px'
  },
  imageIcon: {
    width: '20px',
    height: '20px',
    marginBottom: '19px'
  },
  link: {
    color: '#3f51b5',
    cursor: 'pointer'
  }
}));

export default useStyles;
