import { ReactComponent as SingleLineFieldIcon } from 'assets/images/icons/singleline-field-icon.svg';
import SingleLineFieldIconPath from 'assets/images/icons/singleline-field-icon.svg';
import { ReactComponent as MultiLineFieldIcon } from 'assets/images/icons/multiline-field-icon.svg';
import MultiLineFieldIconPath from 'assets/images/icons/multiline-field-icon.svg';
import { ReactComponent as NumberFieldIcon } from 'assets/images/icons/number-field-icon.svg';
import NumberFieldIconPath from 'assets/images/icons/number-field-icon.svg';
import { ReactComponent as DateFieldIcon } from 'assets/images/icons/date-field-icon.svg';
import DateFieldIconPath from 'assets/images/icons/date-field-icon.svg';
import { ReactComponent as FileFieldIcon } from 'assets/images/icons/file-field-icon.svg';
import FileFieldIconPath from 'assets/images/icons/file-field-icon.svg';
import { ReactComponent as GlossaryFieldIcon } from 'assets/images/icons/glossary-field-icon.svg';
import GlossaryFieldIconPath from 'assets/images/icons/glossary-field-icon.svg';
import { ReactComponent as SystemGlossaryFieldIcon } from 'assets/images/icons/glossary-field-icon.svg';
import SystemGlossaryFieldIconPath from 'assets/images/icons/glossary-field-icon.svg';
import { ReactComponent as GlossaryMultiSelectFieldIcon } from 'assets/images/icons/glossary-multi-select-field-icon.svg';
import GlossaryMultiSelectFieldIconPath from 'assets/images/icons/glossary-multi-select-field-icon.svg';
import { ReactComponent as UserSelectFieldIcon } from 'assets/images/icons/person_regular.svg';
import UserSelectFieldIconPath from 'assets/images/icons/person_regular.svg';
import { ReactComponent as DocumentFromDocflowFieldIcon } from 'assets/images/icons/document-docflow-field-icon.svg';
import DocumentFromDocflowFieldIconPath from 'assets/images/icons/document-docflow-field-icon.svg';
import { ReactComponent as SumFieldIcon } from 'assets/images/icons/sum-field-icon.svg';
import SumFieldIconPath from 'assets/images/icons/sum-field-icon.svg';

import { FieldComponent } from '../../TemplateProcesses.types';
import { environment } from 'environments/environment';

export const FIELDS: FieldComponent[] = [
  {
    key: 'singleLineText',
    title: 'customProcesses.fields.singleLine.name',
    icon: SingleLineFieldIcon,
    iconPath: SingleLineFieldIconPath,
    description: 'customProcesses.fields.singleLine.description',
  },
  {
    key: 'multiLineText',
    title: 'customProcesses.fields.multiLine.name',
    icon: MultiLineFieldIcon,
    iconPath: MultiLineFieldIconPath,
    description: 'customProcesses.fields.multiLine.description',
  },
  {
    key: 'currency',
    title: 'customProcesses.fields.number.name',
    icon: NumberFieldIcon,
    iconPath: NumberFieldIconPath,
    description: 'customProcesses.fields.number.description',
  },
  {
    key: 'dateLineArea',
    title: 'customProcesses.fields.date.name',
    icon: DateFieldIcon,
    iconPath: DateFieldIconPath,
    description: 'customProcesses.fields.date.description',
  },
  {
    key: 'attachmentTemplate',
    title: 'customProcesses.fields.attachment.name',
    icon: FileFieldIcon,
    iconPath: FileFieldIconPath,
    description: 'customProcesses.fields.attachment.description',
  },
  {
    key: 'userList',
    title: 'customProcesses.fields.userList.name',
    icon: UserSelectFieldIcon,
    iconPath: UserSelectFieldIconPath,
    description: 'customProcesses.fields.userList.description',
  },
  {
    key: 'glossary',
    title: 'customProcesses.fields.glossary.name',
    icon: GlossaryFieldIcon,
    iconPath: GlossaryFieldIconPath,
    description: 'customProcesses.fields.glossary.description',
  },
  {
    key: 'glossaryMultiSelect',
    title: 'customProcesses.fields.glossaryMultiSelect.name',
    icon: GlossaryMultiSelectFieldIcon,
    iconPath: GlossaryMultiSelectFieldIconPath,
    description: 'customProcesses.fields.glossaryMultiSelect.description',
  },
  {
    key: 'systemGlossary',
    title: 'customProcesses.fields.systemGlossary.name',
    icon: SystemGlossaryFieldIcon,
    iconPath: SystemGlossaryFieldIconPath,
    description: 'customProcesses.fields.systemGlossary.description',
    hiddenFromFieldsList: true,
  },
  {
    key: 'documentFromDocflow',
    title: 'DocumentProcessFromDocflow.DocumentFromDocFlow',
    icon: DocumentFromDocflowFieldIcon,
    iconPath: DocumentFromDocflowFieldIconPath,
    description: 'DocumentProcessFromDocflow.FieldTypeDocFlow',
    isDisabledToAdd: true,
    hiddenFromFieldsList: !environment.isDocumentAdditionInTemplateProcessesAvailable
  },
  {
    key: 'sum',
    title: 'DocumentProcessFromDocflow.SumDocFlow',
    icon: SumFieldIcon,
    iconPath: SumFieldIconPath,
    description: 'DocumentProcessFromDocflow.ValidateAmount',
    isDisabledToAdd: true,
    hiddenFromFieldsList: !environment.isDocumentAdditionInTemplateProcessesAvailable
  },
];

export const FIELDS_DEFAULT_PARAMS = {
  dateLineArea: {
    placeholder: {
      ru: 'ДД.ММ.ГГГГ',
      en: 'DD.MM.YYYY',
      kz: 'Күні.Айы.Жылы',
      id: 'DD.MM.YYYY',
      de: 'TT.MM.JJJJ',
    },
  },
  documentFromDocflow: {
    hint: {
      ru: 'Документ',
      en: 'Document',
      kz: 'Құжат',
      de: 'Dokument',
      id: 'Dokumen',
    },
    isRequired: false,
  },
  sum: {
    placeholder: {
      ru: '0.00',
      en: '0.00',
      kz: '0.00',
      de: '0.00',
      id: '0.00',
    },
  },
};

export const COMPONENTS_PARAMS = {
  glossary: {
    directoryPath: '',
    directoryCode: '',
  },
  documentFromDocflow: {
    multipleSelection: false,
  },
};

export const MULTILINE_TEXT_FIELD_KEY = 'multiLineText';
export const DOCUMENT_DOCFLOW_FIELD_KEY = 'documentFromDocflow';
export const DOCUMENT_DOCFLOW_FIELD_MULTIPLE_SELECTION_PARAM = 'multipleSelection';
export const DOCUMENT_DOCFLOW_FIELD_IS_REQUIRED_PARAM = 'isRequired';
export const SUM_FIELD_KEY = 'sum';
export const ATTACHMENT_FIELD_KEY = 'attachmentTemplate';
export const SYSTEM_GLOSSARY_FIELD_KEY = 'systemGlossary';
export const GLOSSARY_FIELD_KEY = 'glossary';
export const GLOSSARY_MULTI_SELECT_FIELD_KEY = 'glossaryMultiSelect';
export const GLOSSARY_FIELD_DIRECTORY_PARAM = 'directoryPath';
export const GLOSSARY_FIELD_DIRECTORY_CODE_PARAM = 'directoryCode';
export const SYSTEM_GLOSSARY_INDEX_NAME_PARAM = 'glossaryIndexName';

export const READONLY_FIELD_SUFFIX = 'ReadOnly';
export const HIDDEN_FIELD_SUFFIX = 'Hidden';
export const NOT_REQUIRED_EDITABLE_FIELD_SUFFIX = 'EditableNotRequired';

export enum FIELD_TYPES {
  INTEGRATED = 'integrated',
  SYSTEM = 'system',
}

export const SYSTEM_GLOSSARIES = {
  'dms-bpm-glossary-timesheets': 'customProcesses.systemGlossaries.timeOffTypes',
  'dms-bpm-glossary-timesheets-v2': 'customProcesses.systemGlossaries.timeOffTypes',
};

export const SYSTEM_GLOSSARIES_DEFAULT_NAMES = {
  'dms-bpm-glossary-timesheets': 'Time off types',
  'dms-bpm-glossary-timesheets-v2': 'Time off types',
};

export const MAX_FIELDS_IN_SUMMARY = 5;
