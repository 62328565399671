import { handleActions } from 'redux-actions';

import { REQUESTS_ACTIONS } from './requests.actions';
import { FULFILLED, PENDING, REJECTED } from 'store/constants';
import { StatusType } from "types";

export const initState = {
  processDefinitions: [],
  processIcons: {
    data: [],
    status: StatusType.PENDING,
    errorMessage: null
  },
  glossary: {
    error: false,
    loading: false,
    ids: [],
    mapList: {},
  },
  templates: {
    error: false,
    loading: false,
    data: null,
    taskTemplate: null,
  },
  requestsList: {
    data: null,
    error: '',
    loading: false,
    currentPage: 1,
    pageSize: 10,
    completed: false,
    needRefresh: false,
  },
  requestsCounters: {
    active: -1,
    completed: -1,
    drafts: -1
  },
  processes: {
    loading: false,
    error: false,
    groups: null,
    data: null
  },
  templateProcesses: {
    loading: false,
    error: false,
    data: null
  },
  availableTemplateProcesses: {
    loading: false,
    error: false,
    data: null
  },
  task: {
    loading: false,
    error: null,
    data: null,
    createdFromTemplate: false,
    initialTab: 'comments',
    componentsDataLoadedTable: {},
    hiddenComponentsList: [],
    disabledComponentsList: [],
    taskOpenTime: 0,
    documents: [],
    hasDocumentsSignatureValidationError: false,
    hasNewComments: false,
    isSignRequired: false,
  },
  submitSnackbar: {
    action: '',
    open: false,
  },
  selectedRequest: null,
  createProcess: null,
  darkOverlayShown: false,
  createDialogOpen: false,
  sideModalOpen: false,
  createAfterSubmit: false,
  activeStep: 0,
  isSummaryStep: false,
  taskSteps: null,
  skippedSteps: [],
  draftSaved: false,
  refreshDraftsList: false
};

type InitState = typeof initState;

const {
  GET_GLOSSARY_BY_ID,
  SET_GLOSSARY_ID,
  GET_MY_REQUESTS,
  REFRESH_MY_REQUESTS,
  GET_PROCESS_ICONS_LIST,
  GET_PROCESSES_LIST,
  GET_TEMPLATE_PROCESSES_LIST,
  GET_AVAILABLE_TEMPLATE_PROCESSES_LIST,
  GET_PROCESSES_GROUPS,
  CREATE_REQUEST,
  OPEN_CREATE_DIALOG,
  SET_ACTIVE_STEP,
  SET_SKIPPED_STEPS,
  SET_TASK_STEPS,
  SAVE_DRAFT,
  SET_SAVE_DRAFT_STATUS,
  SET_DRAFTS_LIST_REFRESH_STATUS,
  GET_TEMPLATES,
  REMOVE_TEMPLATE,
  CLEAR_TEMPLATES,
  SET_TEMPLATE,
  CREATE_AFTER_SUBMIT,
  SET_REQUESTS_CURRENT_PAGE,
  SET_REQUESTS_PAGE_SIZE,
  SET_REQUEST_LIST_COMPLETED,

  GET_TASK_BY_ID,
  SET_TASK_LOADING,
  SET_TASK_COMPONENTS_DATA_LOADING_STARTED,
  SET_TASK_COMPONENTS_DATA_LOADING_FINISHED,
  SET_TASK_COMPONENT_HIDDEN,
  SET_TASK_COMPONENT_VISIBLE,
  SET_TASK_COMPONENTS_DISABLED,
  CLEAR_TASK_DATA,
  SET_TASK_OPEN_TIME,
  SET_TASK_DOCUMENTS_SIGNATURE_VALIDATION_ERROR_STATUS,
  SET_TASK_DOCUMENTS,
  SET_TASK_NEW_COMMENTS_INDICATOR,

  SET_SELECTED_REQUEST,
  CLEAR_SELECTED_REQUEST,
  OPEN_SIDE_MODAL,
  SHOW_DARK_OVERLAY,
  SET_SUBMIT_SNACKBAR_PARAMS,
  SET_INITIAL_TAB,

  GET_ACTIVE_REQUESTS_COUNT,
  GET_COMPLETED_REQUESTS_COUNT,
  GET_DRAFTS_REQUESTS_COUNT,
  SET_ACTIVE_REQUESTS_COUNT,
  SET_COMPLETED_REQUESTS_COUNT,
  SET_DRAFTS_REQUESTS_COUNT,
  SET_IS_SIGN_REQUIRED
} = REQUESTS_ACTIONS;

export default handleActions(
  {
    [GET_PROCESS_ICONS_LIST + PENDING]: (state: InitState) => ({
      ...state,
      processIcons: {
        ...state.processIcons,
        status: StatusType.PENDING,
      }
    }),
    [GET_PROCESS_ICONS_LIST + FULFILLED]: (state: InitState, { payload }) => ({
      ...state,
      processIcons: {
        ...state.processIcons,
        status: StatusType.RESOLVED,
        data: payload
      }
    }),
    [GET_PROCESS_ICONS_LIST + REJECTED]: (state: InitState, { payload }) => ({
      ...state,
      processIcons: {
        ...state.processIcons,
        status: StatusType.REJECTED,
        errorMessage: payload?.message ?? 'Error loading icons'
      }
    }),
    [GET_MY_REQUESTS + PENDING]: (state: InitState) => ({
      ...state,
      requestsList: {
        ...state.requestsList,
        loading: true,
        needRefresh: false,
      }
    }),
    [GET_MY_REQUESTS + FULFILLED]: (state: InitState, { payload }) => ({
      ...state,
      requestsList: {
        ...state.requestsList,
        loading: false,
        error: '',
        data: payload,
        needRefresh: false,
      },
    }),
    [GET_MY_REQUESTS + REJECTED]: (state: InitState, { payload }) => ({
      ...state,
      requestsList: {
        ...state.requestsList,
        loading: false,
        error: payload?.message || 'Error loading requests',
        needRefresh: false,
      },
    }),
    [GET_ACTIVE_REQUESTS_COUNT + FULFILLED]: (state: InitState, { payload }) => ({
      ...state,
      requestsCounters: {
        ...state.requestsCounters,
        active: payload
      },
    }),
    [GET_ACTIVE_REQUESTS_COUNT + REJECTED]: (state: InitState, { payload }) => ({
      ...state,
      requestsCounters: {
        ...state.requestsCounters,
        active: -1
      },
    }),
    [GET_COMPLETED_REQUESTS_COUNT + FULFILLED]: (state: InitState, { payload }) => ({
      ...state,
      requestsCounters: {
        ...state.requestsCounters,
        completed: payload
      },
    }),
    [GET_COMPLETED_REQUESTS_COUNT + REJECTED]: (state: InitState, { payload }) => ({
      ...state,
      requestsCounters: {
        ...state.requestsCounters,
        completed: -1
      },
    }),
    [GET_DRAFTS_REQUESTS_COUNT + FULFILLED]: (state: InitState, { payload }) => ({
      ...state,
      requestsCounters: {
        ...state.requestsCounters,
        drafts: payload
      },
    }),
    [GET_DRAFTS_REQUESTS_COUNT + REJECTED]: (state: InitState, { payload }) => ({
      ...state,
      requestsCounters: {
        ...state.requestsCounters,
        drafts: -1
      },
    }),
    [SET_ACTIVE_REQUESTS_COUNT]: (state: InitState, { payload }) => ({
      ...state,
      requestsCounters: {
        ...state.requestsCounters,
        active: payload
      },
    }),
    [SET_COMPLETED_REQUESTS_COUNT]: (state: InitState, { payload }) => ({
      ...state,
      requestsCounters: {
        ...state.requestsCounters,
        completed: payload
      },
    }),
    [SET_DRAFTS_REQUESTS_COUNT]: (state: InitState, { payload }) => ({
      ...state,
      requestsCounters: {
        ...state.requestsCounters,
        drafts: payload
      },
    }),
    [REFRESH_MY_REQUESTS]: (state: InitState) => ({
      ...state,
      requestsList: {
        ...state.requestsList,
        needRefresh: true,
      },
    }),
    [SET_REQUESTS_CURRENT_PAGE]: (state: InitState, { payload }) => ({
      ...state,
      requestsList: {
        ...state.requestsList,
        currentPage: payload?.page,
        needRefresh: payload?.needRefresh ?? true,
      },
    }),
    [SET_REQUESTS_PAGE_SIZE]: (state: InitState, { payload }) => ({
      ...state,
      requestsList: {
        ...state.requestsList,
        pageSize: payload,
        currentPage: 1,
        needRefresh: true,
      },
    }),
    [SET_REQUEST_LIST_COMPLETED]: (state: InitState, { payload }) => ({
      ...state,
      requestsList: {
        ...state.requestsList,
        completed: payload,
        currentPage: 1,
        needRefresh: true,
      },
    }),

    [GET_GLOSSARY_BY_ID + PENDING]: (state: InitState) => ({
      ...state,
      glossary: {
        ...state.glossary,
        loading: true,
      },
    }),
    [GET_GLOSSARY_BY_ID + FULFILLED]: (state: InitState, { payload }) => ({
      ...state,
      glossary: {
        ...state.glossary,
        loading: false,
        mapList: {
          ...state.glossary.mapList,
          [payload.baseCode]: payload.data,
        },
      },
    }),
    [GET_GLOSSARY_BY_ID + REJECTED]: (state: InitState) => ({
      ...state,
      glossary: {
        ...state.glossary,
        loading: true,
        error: true,
      },
    }),
    [SET_GLOSSARY_ID]: (state: InitState, { payload }) => ({
      ...state,
      glossary: {
        ...state.glossary,
        ids: [...new Set([...state.glossary.ids, payload])],
      },
    }),

    [GET_PROCESSES_LIST + PENDING]: (state: InitState) => ({
      ...state,
      processes: {
        ...state.processes,
        loading: true,
      },
    }),
    [GET_PROCESSES_LIST + FULFILLED]: (state: InitState, { payload }) => ({
      ...state,
      processes: {
        ...state.processes,
        loading: false,
        data: payload,
        groups: state.processes.groups,
      },
    }),
    [GET_PROCESSES_LIST + REJECTED]: (state: InitState, { payload }) => ({
      ...state,
      processes: {
        ...state.processes,
        data: [],
        loading: false,
        error: payload?.message,
      },
    }),
    [GET_TEMPLATE_PROCESSES_LIST + PENDING]: (state: InitState) => ({
      ...state,
      templateProcesses: {
        ...state.templateProcesses,
        loading: true,
      },
    }),
    [GET_TEMPLATE_PROCESSES_LIST + FULFILLED]: (state: InitState, { payload }) => ({
      ...state,
      templateProcesses: {
        ...state.templateProcesses,
        loading: false,
        data: payload,
      },
    }),
    [GET_TEMPLATE_PROCESSES_LIST + REJECTED]: (state: InitState, { payload }) => ({
      ...state,
      templateProcesses: {
        ...state.templateProcesses,
        data: [],
        loading: false,
        error: payload?.message,
      },
    }),
    [GET_AVAILABLE_TEMPLATE_PROCESSES_LIST + PENDING]: (state: InitState) => ({
      ...state,
      availableTemplateProcesses: {
        ...state.availableTemplateProcesses,
        loading: true,
      },
    }),
    [GET_AVAILABLE_TEMPLATE_PROCESSES_LIST + FULFILLED]: (state: InitState, { payload }) => ({
      ...state,
      availableTemplateProcesses: {
        ...state.availableTemplateProcesses,
        loading: false,
        data: payload,
      },
    }),
    [GET_AVAILABLE_TEMPLATE_PROCESSES_LIST + REJECTED]: (state: InitState, { payload }) => ({
      ...state,
      availableTemplateProcesses: {
        ...state.availableTemplateProcesses,
        data: [],
        loading: false,
        error: payload?.message,
      },
    }),
    [GET_PROCESSES_GROUPS + PENDING]: (state: InitState) => ({
      ...state,
      processes: {
        ...state.processes,
        loading: true,
      },
    }),
    [GET_PROCESSES_GROUPS + FULFILLED]: (state: InitState, { payload }) => ({
      ...state,
      processes: {
        ...state.processes,
        // loading: false,
        groups: payload,
      },
    }),

    [GET_TEMPLATES + PENDING]: (state: InitState) => ({
      ...state,
      templates: {
        ...state.templates,
        loading: true,
      },
    }),
    [GET_TEMPLATES + FULFILLED]: (state, { payload }) => ({
      ...state,
      templates: {
        ...state.templates,
        loading: false,
        data: payload,
      },
    }),
    [GET_TEMPLATES + REJECTED]: (state: InitState, { payload }) => ({
      ...state,
      templates: {
        ...state.templates,
        loading: false,
        error: payload || 'Error loading templates',
      },
    }),
    [REMOVE_TEMPLATE]: (state: InitState, { payload }) => ({
      ...state,
      templates: {
        ...state.templates,
        data: state.templates.data?.filter((t) => t.id !== payload),
      },
    }),
    [CLEAR_TEMPLATES]: (state: InitState) => ({
      ...state,
      templates: initState.templates,
    }),
    [SET_TEMPLATE]: (state: InitState, { payload }) => ({
      ...state,
      templates: {
        ...state.templates,
        taskTemplate: payload,
      },
      task: {
        ...state.task,
        createdFromTemplate: true,
      },
    }),
    [CREATE_REQUEST]: (state: InitState, { payload }) => ({
      ...state,
      createProcess: payload,
      createDialogOpen: !!payload,
      isSummaryStep: false,
      createAfterSubmit: false,
      activeStep: 0,
      taskSteps: payload && state.taskSteps ? null : state.taskSteps,
    }),
    [OPEN_CREATE_DIALOG]: (state: InitState, { payload }) => ({
      ...state,
      createDialogOpen: payload,
      isSummaryStep: false,
      task: {
        ...state.task,
        createdFromTemplate: false,
      },
    }),
    [SHOW_DARK_OVERLAY]: (state: InitState, { payload }) => ({
      ...state,
      darkOverlayShown: payload,
    }),
    [OPEN_SIDE_MODAL]: (state: InitState, { payload }) => ({
      ...state,
      sideModalOpen: payload,
    }),
    [SET_ACTIVE_STEP]: (state: InitState, { payload }) => ({
      ...state,
      activeStep: payload,
      isSummaryStep: state.taskSteps.length === payload,
    }),
    [SET_SKIPPED_STEPS]: (state: InitState, { payload }) => ({
      ...state,
      skippedSteps: payload,
    }),
    [SET_TASK_STEPS]: (state: InitState, { payload }) => ({
      ...state,
      taskSteps: payload,
      skippedSteps: [],
    }),
    [SAVE_DRAFT + FULFILLED]: (state: InitState) => ({
      ...state,
      draftSaved: true,
    }),
    [SET_SAVE_DRAFT_STATUS]: (state: InitState, { payload }) => ({
      ...state,
      draftSaved: payload,
    }),
    [SET_DRAFTS_LIST_REFRESH_STATUS]: (state: InitState, {payload}) => ({
      ...state,
      refreshDraftsList: payload,
    }),
    [CREATE_AFTER_SUBMIT]: (state: InitState, { payload }) => ({
      ...state,
      createAfterSubmit: payload,
    }),
    [GET_TASK_BY_ID + PENDING]: (state: InitState) => ({
      ...state,
      task: {
        ...state.task,
        loading: true,
      }
    }),
    [GET_TASK_BY_ID + FULFILLED]: (state: InitState, { payload }) => ({
      ...state,
      task: {
        ...state.task,
        loading: false,
        data: payload.task,
        hiddenComponentsList: [],
        disabledComponentsList: [],
        taskOpenTime: (new Date()).getTime(),
        documents: payload.documents,
        hasDocumentsSignatureValidationError: false,
        hasNewComments: false,
      },
      templates: {
        taskTemplate: null
      }
    }),
    [GET_TASK_BY_ID + REJECTED]: (state: InitState, { payload }) => ({
      ...state,
      task: {
        ...state.task,
        loading: false,
        error: payload,
        hiddenComponentsList: [],
        disabledComponentsList: [],
        documents: [],
        hasDocumentsSignatureValidationError: false,
      },
      templates: {
        taskTemplate: null
      }
    }),
    [SET_TASK_DOCUMENTS]: (state: InitState, { payload }) => ({
      ...state,
      task: {
        ...state.task,
        documents: payload,
      },
    }),
    [SET_TASK_NEW_COMMENTS_INDICATOR]: (state: InitState, { payload }) => ({
      ...state,
      task: {
        ...state.task,
        hasNewComments: payload,
      },
    }),
    [SET_TASK_LOADING]: (state: InitState) => ({
      ...state,
      task: {
        ...state.task,
        loading: true,
      },
    }),
    [SET_TASK_OPEN_TIME]: (state: InitState, { payload }) => ({
      ...state,
      task: {
        ...state.task,
        taskOpenTime: payload,
      },
    }),
    [SET_TASK_DOCUMENTS_SIGNATURE_VALIDATION_ERROR_STATUS]: (state: InitState, { payload }) => ({
      ...state,
      task: {
        ...state.task,
        hasDocumentsSignatureValidationError: payload,
      },
    }),
    [SET_IS_SIGN_REQUIRED]: (state: InitState, { payload }) => ({
      ...state,
      task: {
        ...state.task,
        isSignRequired: payload,
      },
    }),
    [SET_TASK_COMPONENTS_DATA_LOADING_STARTED]: (state: InitState, { payload }) => ({
      ...state,
      task: {
        ...state.task,
        componentsDataLoadedTable: {
          ...state.task.componentsDataLoadedTable,
          [payload]: true
        }
      }
    }),
    [SET_TASK_COMPONENTS_DATA_LOADING_FINISHED]: (state: InitState, { payload }) => ({
      ...state,
      task: {
        ...state.task,
        componentsDataLoadedTable: {
          ...state.task.componentsDataLoadedTable,
          [payload]: false
        }
      }
    }),
    [SET_TASK_COMPONENT_HIDDEN]: (state: InitState, { payload }) => ({
      ...state,
      task: {
        ...state.task,
        hiddenComponentsList: state.task.hiddenComponentsList.includes(payload)
                              ? state.task.hiddenComponentsList
                              : [...state.task.hiddenComponentsList, payload]
      }
    }),
    [SET_TASK_COMPONENT_VISIBLE]: (state: InitState, { payload }) => ({
      ...state,
      task: {
        ...state.task,
        hiddenComponentsList: state.task.hiddenComponentsList.filter(v => v !== payload)
      }
    }),
    [SET_TASK_COMPONENTS_DISABLED]: (state: InitState, { payload }) => ({
      ...state,
      task: {
        ...state.task,
        disabledComponentsList: [...state.task.disabledComponentsList, ...payload]
      }
    }),
    [CLEAR_TASK_DATA]: (state: InitState) => ({
      ...state,
      task: {
        ...state.task,
        loading: false,
        error: '',
        data: null,
        initialTab: 'information',
        componentsDataLoadedTable: {},
        hiddenComponentsList: [],
        disabledComponentsList: [],
        documents: [],
      },
    }),
    [SET_SELECTED_REQUEST]: (state: InitState, { payload }) => ({
      ...state,
      selectedRequest: payload,
    }),
    [CLEAR_SELECTED_REQUEST]: (state: InitState) => ({
      ...state,
      selectedRequest: null,
    }),
    [SET_SUBMIT_SNACKBAR_PARAMS]: (state: InitState, { payload }) => ({
      ...state,
      submitSnackbar: payload,
    }),
    [SET_INITIAL_TAB]: (state: InitState, { payload }) => ({
      ...state,
      task: {
        ...state.task,
        initialTab: payload,
      },
    }),
  },
  initState
);
