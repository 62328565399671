import React, {useState, useCallback, useMemo, memo, ReactElement} from 'react';
import { ButtonBase, Grow, IconButton, Tooltip } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import moment from 'moment/moment';

import { TaskStatusIndicator } from 'components';

import { clearSelectedRequest, clearTaskData, useTaskState } from 'store/requests';
import { clearSelectedApproval, clearSelectedReviewedCurrentTask } from 'store/approvals';
import { formatTimeFromMinutes } from 'utils/time';
import { getProfileCompanyDataFromLocalStorage } from 'utils/user';

import { Creator } from './Creator';
import useStyles from './useStyles';

import LinkIcon from 'assets/images/icons/links-tab-icon.svg';
import ClockIcon from 'assets/images/icons/clock-icon.svg';
import OverdueIcon from 'assets/images/icons/warning-sign.svg';

import {
  REQUEST_LINK_TEMPLATE,
  TOOLTIP_TIMEOUT,
  WORKSPACE_PARAM,
} from './constants';

export const DetailsHeader = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { data: bpmTask } = useTaskState();

  const tooltipCopyLinkText = t('task_data_view.copy_link');
  const tooltipCopiedLinkText = t('task_data_view.copied_link');

  const [isTooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const [tooltipText, setTooltipText] = useState<string>(tooltipCopyLinkText);

  const {
    applicationNumber,
    companyId,
    taskId,
    title,
    initiator,
    step,
    stepName,
    dueDateTemplate,
    taskEndDate,
    totalSteps,
    isCompleted,
    isCancelled,
    isRejected,
    stepsList,
    hasCondition,
    processSysName,
    parallelBranchesName,
    parallelBranchesStepperOrder
  } = useMemo(() => {
    const {
      currentParallelSteps = null,
      currentParallelStepsStepperOrder = 0,
      stepsDetails = [],
      hasCondition = false,
      completedStepOrder = 0,
      stepsCount = 0,
    } = bpmTask.businessTask.progressBar ?? {};

    return {
      applicationNumber: bpmTask.applicationNumber,
      companyId: bpmTask.businessTask?.companyId || '',
      taskId: bpmTask.taskId,
      title: t(`constructor-${bpmTask.processSysName}.name`, { defaultValue: bpmTask.processName }),
      initiator: bpmTask.initiator,
      step: +completedStepOrder,
      stepName: t(`constructor-${bpmTask.processSysName}.actions.${bpmTask.stepSysName}.name`, { defaultValue: bpmTask.stepName }),
      dueDateTemplate: bpmTask.businessTask.dueDateTemplate,
      taskEndDate: bpmTask.businessTask.taskEndDate,
      totalSteps: +stepsCount,
      isCompleted: bpmTask.isCompleted || bpmTask.businessTask?.taskStatus === 'Completed',
      isCancelled: Boolean(bpmTask.isCancelled) || bpmTask.businessTask?.taskStatus === 'Canceled',
      isRejected: bpmTask.businessTask?.taskStatus === 'Reject',
      stepsList: stepsDetails,
      hasCondition,
      processSysName: bpmTask.processSysName,
      parallelBranchesName: currentParallelSteps,
      parallelBranchesStepperOrder: currentParallelStepsStepperOrder
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bpmTask])

  const handleClose = () => {
    dispatch(clearSelectedRequest());
    dispatch(clearSelectedApproval());
    dispatch(clearSelectedReviewedCurrentTask());
    dispatch(clearTaskData());
  };

  const showTooltip = useCallback(() => {
    setTooltipOpen(true);
  }, [setTooltipOpen]);

  const closeTooltip = useCallback(() => {
    if (tooltipText !== tooltipCopiedLinkText) {
      setTooltipOpen(false);
    }
  }, [setTooltipOpen, tooltipText]);

  const copyTaskLink = useCallback(() => {
    const { id: companyId } = getProfileCompanyDataFromLocalStorage();
    const requestLink = REQUEST_LINK_TEMPLATE + bpmTask?.processInstanceId + WORKSPACE_PARAM + companyId;
    navigator.clipboard.writeText(requestLink);
    setTooltipText(tooltipCopiedLinkText);

    // 2 timeouts to prevent change of tooltip text before it is fully close
    setTimeout(() => setTooltipOpen(false), TOOLTIP_TIMEOUT);
    setTimeout(() => setTooltipText(tooltipCopyLinkText), TOOLTIP_TIMEOUT + 200);
  }, [taskId, setTooltipOpen, setTooltipText]);

  const timerStatus = useMemo(() => {
    if (!dueDateTemplate || (taskEndDate && moment(dueDateTemplate).isAfter(moment(taskEndDate)))) {
      return {
        remainingTime: null,
        isTimerOver: false,
      }
    }

    const deadline = moment(dueDateTemplate);
    const timeDifference = taskEndDate ? Math.abs(deadline.diff(moment(taskEndDate), 'minutes')) : Math.abs(deadline.diff(Date.now(), 'minutes'));
    const isTimerOver = taskEndDate ? deadline.isBefore(moment(taskEndDate)) : deadline.isBefore(moment(Date.now()));
    return {
      remainingTime: formatTimeFromMinutes(timeDifference, i18n.language),
      isTimerOver
    }
  }, [dueDateTemplate, taskEndDate]);

  return (
    <>
      <div className={classes.cardHeader}>
        <div className={classes.cardHeaderInfoWrapper}>
          <span className={classes.applicationNumberWrapper}>
            ID:{' '}
            <span className={classes.applicationNumber}>
              {applicationNumber}
            </span>
            <Tooltip
              TransitionComponent={Grow}
              title={tooltipText}
              open={isTooltipOpen}
              placement={'top'}
            >
              <ButtonBase
                onClick={copyTaskLink}
                onMouseEnter={showTooltip}
                onMouseLeave={closeTooltip}
              >
                <img
                  src={LinkIcon}
                  alt="copy link"
                  className={classes.copyLinkButton}
                />
              </ButtonBase>
            </Tooltip>
          </span>
          <Tooltip title={title}>
          <h2 className={classes.cardTitle}>
          {title}
          </h2>
          </Tooltip>
        </div>
      </div>

      <div className={classes.InitiatorAndStatusWrapper}>
        <Creator initiator={initiator} companyId={companyId} />
        <TaskStatusIndicator
          step={step}
          variant="header"
          processSysName={processSysName}
          parallelBranchesName={parallelBranchesName}
          parallelBranchesStepperOrder={parallelBranchesStepperOrder}
          totalSteps={totalSteps}
          isCompleted={isCompleted}
          isCancelled={isCancelled}
          isRejected={isRejected}
          stepsList={stepsList}
          hasCondition={hasCondition}
        />
      </div>

      {timerStatus?.remainingTime && (
        <div className={classes.remainingTimeWrapper}>
          <img src={ClockIcon} className={classes.clockIcon}/>
          <span className={classes.clockTitle}>
            {timerStatus.isTimerOver ? t('task_data_view.timerOverdue') : t('task_data_view.timerRemaining')}:
          </span>
          {timerStatus.isTimerOver && <img src={OverdueIcon} className={classes.clockOverdueIcon}/>}
          <span className={cn(classes.clockTime, { [classes.clockTimeOverdue]: timerStatus.isTimerOver })}>
            {timerStatus.remainingTime}
          </span>
          <span className={classes.clockHelp}>{t('customProcesses.processForm.timeFieldHint')}</span>
        </div>
      )}
    </>
  );
}

export default DetailsHeader;
