import { useEffect, useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

import {
  searchRequests,
  setSearchCurrentPage,
  setSearchCurrentPageSize,
  useSearchListState,
} from 'store/search';
import {
  getTaskById,
  setSelectedRequest,
  useSelectedRequest,
} from 'store/requests';
import { useRequestParams, useUserProfile } from 'hooks';

import { routesList } from 'router';

const useSearch = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { companyName: profileCompanyName } = useUserProfile();

  const selectedRequest = useSelectedRequest();

  const {
    data: requests,
    loading,
    error,
    pageSize,
    totalPages,
    totalElements,
    currentPage,
    needRefresh,
  } = useSearchListState();

  const { getRequestParameters, urlParams } = useRequestParams({
    type: 'search',
    currentPage,
    pageSize,
  });

  const prevUrlParams = useRef(urlParams);
  const prevSearchText = useRef('');
  const [searchText, setSearchText] = useState<string>('');

  const handleSearchStart = useCallback(
    (searchText?: string): void => {
      urlParams.searchText = searchText ?? '';

      const requestParameters = getRequestParameters(urlParams);
      if (!Object.keys(requestParameters).includes('legalEntity')) {
        requestParameters['legalEntity'] = profileCompanyName;
      }

      dispatch(searchRequests(requestParameters));
    },
    [dispatch, urlParams, getRequestParameters]
  );

  const debouncedHandleSearchStart = useCallback(debounce((value: string) => handleSearchStart(value), 400), [handleSearchStart]);

  useEffect(() => {
    if (!isEqual(prevUrlParams.current, urlParams) || prevSearchText.current !== searchText || needRefresh) {
      searchText && debouncedHandleSearchStart(searchText);
    }
  }, [urlParams, needRefresh, searchText, debouncedHandleSearchStart]);

  useEffect(() => {
    if (!isEqual(prevUrlParams.current, urlParams)) {
      prevUrlParams.current = urlParams;
    }
  }, [urlParams]);

  useEffect(() => {
    if (prevSearchText.current !== searchText) {
      prevSearchText.current = searchText;
    }
  }, [searchText]);

  const onSearchHelperCloseHandler = () => {
    history.push(routesList(t).requests.path);
  };

  const handleTaskSelection = (taskId) => {
    dispatch(setSelectedRequest(taskId));
    dispatch(getTaskById(taskId));
  };

  const handlePageSizeChange = (event) => {
    dispatch(setSearchCurrentPageSize(event.target.value));
  };

  const handlePageChange = (_, newPage) => {
    dispatch(setSearchCurrentPage(newPage));
  };

  return {
    requests,
    totalPages,
    totalElements,
    error,
    loading,
    pageSize,
    currentPage,
    searchText,
    setSearchText,
    selectedRequest,
    handleSearchStart,
    handlePageChange,
    handleTaskSelection,
    handlePageSizeChange,
    onSearchHelperCloseHandler,
  };
};

export default useSearch;
