import { useCallback, useEffect, useMemo, useState } from 'react';

import { getFinalDocuments } from 'api/requests/document-sign.api';
import { useTaskState } from "store/requests";

import { EdsFinalDocumentProps, EdsFinalDocumentsHookResponse, RequestParameters, DocumentItem } from "./types";
import { environment } from 'environments/environment';

const PARAGRAPH_DEFAULT_ACCOUNT = environment.NX_DEFAULT_PARAGRAPH_ACCOUNT;
const ORDER_DEFAULT_VALUE = 1;

const useEdsFinalDocuments = ({
  params: {
    processSysId,
    order = ORDER_DEFAULT_VALUE,
    userModelId = PARAGRAPH_DEFAULT_ACCOUNT,
  }
}: EdsFinalDocumentProps): EdsFinalDocumentsHookResponse => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [documentList, setDocumentList] = useState<DocumentItem[]>([]);

  const { data: bpmTask } = useTaskState();

  const taskId = useMemo((): string => {
    if (userModelId && bpmTask?.processInstanceId) {
      return bpmTask.processInstanceId;
    }

    return bpmTask?.taskId || '';
  }, [userModelId, bpmTask]);

  const getRequestParameters = useCallback((): RequestParameters => {
    const defaultParameters = {
      taskId,
      params: {
        userModelId,
      }
    };

    if (processSysId) {
      return {
        ...defaultParameters,
        params: {
          ...defaultParameters.params,
          processSysId
        }
      }
    }

    return {
      ...defaultParameters,
      params: {
        ...defaultParameters.params,
        order: order
      }
    }
  }, [order, taskId, userModelId, processSysId]);

  const getFinalDocumentsRequest = useCallback(async (): Promise<void> => {
    setIsLoading(true);

    try {
      const requestParameters = getRequestParameters();
      const documentsInformation = await getFinalDocuments(requestParameters);

      const documentList = Object.keys(documentsInformation).map((documentType: string): DocumentItem => ({
        label: `${documentType} document`,
        link: documentsInformation[documentType]
      }));

      setDocumentList(documentList);
    } catch (error) {
      setErrorMessage(error);
    } finally {
      setIsLoading(false);
    }
  }, [getRequestParameters]);

  useEffect(() => {
    if (taskId) {
      getFinalDocumentsRequest();
    }
  }, [taskId, getFinalDocumentsRequest]);

  return {
    isLoading,
    documentList,
    errorMessage
  };
};

export default useEdsFinalDocuments;
