import React, { ReactElement, useEffect, useMemo } from 'react';

import { NotificationContainer } from 'react-notifications';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import theme from 'theme';
import 'i18/index';
import { getHoldingTranslations } from 'i18/utils';
import { Header } from '@dar-dms/utils';

import { checkAccessToAdminSettings, checkTemplateProcessesAccesses, getAclUserAccesses } from 'store/settings';
import { CreateProcessDialog } from 'pages';
import { getHCMSUsersAction, getOldIdUsersAction, getUsersPositionsAction } from 'store/users';
import { MainRouter } from 'router';
import { ProcessProvider } from 'contexts/ProcessContext/ProcessProvider';
import { restoreSideMenuStateFromLocalStorage, setHoldingTranslationsLoaded, useSideMenuOpen } from 'store/main';
import { takeBreadcrumbs } from 'utils/breadcrumbs';
import { useBilling, useUserProfile } from 'hooks';

import useStyles from './useStyles';
import MassApproveLoader from 'components/MassApproveLoader';
import { setMassApproveActionStatus, useMassApproveActionStatus } from 'store/approvals';


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {
  }
}


export const MainLayout = (): ReactElement => {
  const dispatch = useDispatch();
  const { loading, showErrorIcon, showSuccessIcon, percentage, statusText } = useMassApproveActionStatus();

  useBilling();

  const isSideMenuOpen = useSideMenuOpen();
  const { id: profileId, companyId } = useUserProfile();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const breadcrumbs = takeBreadcrumbs(t)[pathname] || [];
  const styles = useStyles({ isSideMenuOpen });

  // TODO: Продумать вариант добавления userContext для getUserAccess
  useEffect(() => {
    dispatch(getAclUserAccesses());
    dispatch(checkAccessToAdminSettings());
    dispatch(checkTemplateProcessesAccesses());
    dispatch(getHCMSUsersAction(companyId));
    dispatch(getOldIdUsersAction());
    dispatch(setHoldingTranslationsLoaded(false));
    dispatch(getUsersPositionsAction());
    getHoldingTranslations(dispatch);
  }, [dispatch, profileId, companyId]);

  useEffect(() => {
    dispatch({ type: 'RESET_STATE' });
    dispatch(restoreSideMenuStateFromLocalStorage());
  }, [profileId]);

  const contentClass = useMemo(() => {
    switch (pathname) {
      case '/template-processes':
      case '/upgrade':
        return styles.noPaddingContent;
      case '/requests/active':
      case '/requests/completed':
      case '/requests/drafts':
        return styles.requestsContent;
      default:
        return styles.content;
    }
  }, [pathname, styles]);

  const onCloseMassApproveLoader = () => {
    dispatch(setMassApproveActionStatus({
      loading: false,
      percentage: 0,
      statusText: ``,
      showErrorIcon: false,
      showSuccessIcon: false
    }));
  };

  return (
    <ProcessProvider>
      <Box className={styles.root}>
        <div className={styles.headerWrapper}>
          <Header breadcrumbs={breadcrumbs} />
        </div>
        <Box component="article" className={contentClass}>
          {profileId && <MainRouter />}
        </Box>
        <CreateProcessDialog />

        <Box className={styles.notificationContainer}>
          <NotificationContainer />
        </Box>

        {
          loading &&
          <MassApproveLoader
            onClose={onCloseMassApproveLoader}
            showErrorIcon={showErrorIcon}
            showSuccessIcon={showSuccessIcon}
            percentage={percentage}
            statusText={statusText} />
        }

      </Box>
    </ProcessProvider>
  );
};
