import { CancelToken } from 'axios';

import { baseV1ApiClient, baseV1ApiFormDataClient } from '../clients';
import { commonExceptionHandler } from '../handlers';
import { saveFileFromAxiosResponse } from 'utils/files';

const timeout = 60 * 10 * 1000;

export const uploadAttachmentToPS = async ({
  params: {
    companyId,
    fileId
  },
  data,
  config = {}
}: {
  params: {
    companyId: string;
    fileId: string;
  },
  data: FormData,
  config?: {
    handleUploadProgress?: (progress: number) => void;
    cancelToken?: CancelToken
  }
}): Promise<string> => {
  const { handleUploadProgress, cancelToken } = config;

  try {
    const response = await baseV1ApiFormDataClient.request({
      method: 'POST',
      url: '/camunda/integration-financial-system/attach',
      params: {
        companyId,
        fileId
      },
      data,
      timeout,
      cancelToken,
      onUploadProgress: (progressEvent) => {
        const progressUpload = +progressEvent?.loaded/(+progressEvent?.total + 1);

        if (handleUploadProgress) {
          handleUploadProgress(progressUpload);
        }
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const downloadAttachmentFromPS = async (fileId, filename, type): Promise<void> => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/camunda/integration-financial-system/attach/file',
      params: {
        fileId
      },
      responseType: 'arraybuffer',
      timeout,
    });

    saveFileFromAxiosResponse(response, type, filename);
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getAttachmentFromPS = async ({fileId}): Promise<{ data: Blob | ArrayBuffer }> => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/camunda/integration-financial-system/attach/file',
      params: {
        fileId
      },
      responseType: 'blob',
      timeout,
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getFileByIdQuery = async ({fileId, signal}): Promise<{ data: Blob | ArrayBuffer }> => {
  try {
    const { data } = await baseV1ApiClient.request({
      method: 'GET',
      url: `/camunda/integration-financial-system/attach/v2/file`,
      params: { fileId },
      responseType: 'arraybuffer',
      timeout,
      signal
    });
    return data;
  } catch (err) {
    if (signal?.aborted) return;
    throw err;
  }
}

