import React, { memo, ReactElement, useCallback, useEffect, useMemo } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Spinner } from 'components';

import { useTemplateViewer } from './useTemplateViewer';
import { TemplateViewerProps, TemplateViewerHookResponse, Content } from './types';

import { HINT_MESSAGES } from './constants';

import useStyles from './useStyles';

export const TemplateViewer = memo(({
  name,
  label,
  params,
  displayStep,
  isActive,
  setButtonDisabled,
  setAlwaysActiveButtons = () => {
    //
  }
}: TemplateViewerProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    return () => {
      setButtonDisabled(false);
    }
  }, [])

  const {
    isUploadLoading,
    uploadErrorMessage,
    uploadHintMessage,
    isAttachmentExists,
    errorMessage,
    templateDocumentStatus,
    templateDocumentURL,
    uploadFileToPS
  }: TemplateViewerHookResponse = useTemplateViewer({
    params,
    setButtonDisabled,
    setAlwaysActiveButtons,
    displayStep
  });

  const contentPending = useCallback((): ReactElement => (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Spinner absolute={false} />
      <Typography>{t('form_components.templateViewer.templateLoading')}</Typography>
    </Box>
  ), []);

  const contentResolved = useCallback((): ReactElement => (
    <Box display="flex" flexDirection="column">
      <iframe src={templateDocumentURL} className={classes.iframe} />

      {isAttachmentExists && (
        <Box display="flex" flexDirection="column" alignItems="flex-start" pt={1}>
          <Typography color="primary" className={classes.hintMessage}>{t(uploadHintMessage)}</Typography>

          <Box mt={2}>
            <Button
              type="button"
              variant="contained"
              onClick={uploadFileToPS}
              disabled={isUploadLoading || uploadHintMessage === HINT_MESSAGES.SUCCESS_APPROVE_PDF_FILE}
              color="primary"
            >
              {t('form_components.templateViewer.buttonLabel')}
            </Button>
          </Box>

          {uploadErrorMessage && (
            <Box mt={2}>
              <Typography color="secondary">{t(uploadErrorMessage)}</Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
    //eslint-disable-next-line react-hooks/exhaustive-deps
  ), [
    templateDocumentURL,
    isAttachmentExists,
    isUploadLoading,
    uploadHintMessage,
    uploadErrorMessage,
    uploadFileToPS
  ]);

  const contentRejected = useCallback((): ReactElement => (
    <Typography color="error">{t(errorMessage)}</Typography>
    ), [errorMessage]);

  const content = useMemo((): Content => ({
    pending: () => contentPending(),
    resolved: () => contentResolved(),
    rejected: () => contentRejected()
  }), [contentPending, contentResolved, contentRejected]);

  if (!isActive) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column">
      {label && <Typography>{label}</Typography>}
      <input type='hidden' data-selenium={name} />

      {content[templateDocumentStatus]()}
    </Box>
  )
});
