import { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { getTaskHistoryStructured } from 'api/requests';
import { useTaskState } from 'store/requests';
import { getTranslationKeyForTaskHistoryInPast } from 'utils/translations';
import { useInterval } from 'hooks';
import { environment } from 'environments/environment';

import {
  HistoryNode,
  HistoryNodeData,
  HistoryNodeType,
  StepType,
  StructuredHistoryCondition,
  StructuredHistoryParallelAssigneesStep,
  StructuredHistoryParallelBranches,
  StructuredHistoryStep,
} from './history.types';

const HISTORY_REFRESH_INTERVAL = 60000;

type Props = {
  isActiveTab?: boolean
}

const useTaskHistory = ({ isActiveTab = false }: Props) => {
  const { data: bpmTask } = useTaskState();
  const { t } = useTranslation();

  const [structuredHistory, setStructuredHistory] = useState<StructuredHistoryStep>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  const APPROVE_STAGES_TYPES = {
    CURRENT_STEP: t('task_history.current_step'),
    FUTURE_STEP: t('task_history.future_step'),
    DEFAULT_STEP: t('task_history.approve'),
    CANCELLED_STEP: t('task_statuses.stopped'),
    INTERRUPTED_STEP: t('task_history.interrupted'),
  };

  const initializeTaskHistory = useCallback(async () => {
    try {
      const taskHistory = await getTaskHistoryStructured(
        bpmTask.processInstanceId,
      );
      setStructuredHistory(taskHistory);
    } catch (error) {
      setError(error?.message || t('task_data_view.history_tab.loading_error'));
    } finally {
      setLoading(false);
    }
  }, [bpmTask?.processInstanceId, bpmTask?.isTemplateProcessTask]);

  const getStepApproveStageInfo = useCallback(
    ({
      isCurrentStep,
      isFutureStep = false,
      cancelledStep = false,
      hasSignature = false,
      taskStartDate,
      approveStage,
    }: StepType) => {
      if (isFutureStep || !taskStartDate) {
        return {};
      }

      // const approveStageType =
      //   cancelledStep || approveStage?.toLowerCase().includes('reject') || approveStage?.toLowerCase().includes('cancel')
      //   ? 'reject'
      //   : approveStage?.toLowerCase().includes('rework') || approveStage?.toLowerCase().includes('reassign')
      //     ? 'rework'
      //     : 'approve';
      let approveStageType;

      switch (true) {
        case cancelledStep || approveStage?.toLowerCase().includes('reject') || approveStage?.toLowerCase().includes('cancel'):
          approveStageType = 'reject';
          break;
        case approveStage?.toLowerCase().includes('rework') || approveStage?.toLowerCase().includes('reassign'):
          approveStageType = 'rework';
          break;
        case approveStage?.toLowerCase().includes('interrupt'):
          approveStageType = 'interrupted';
          break;
        default:
          approveStageType = 'approve';
          break;
      }

      if (isCurrentStep && approveStageType !== 'reject') {
        return {
          displayApproveStage: t('task_history.waitingApproval'),
          displayApproveStageType: approveStageType,
          completionDateString: '',
        };
      }

      const currentApproveStagePrefix = hasSignature ? t('task_data_view.history_tab.signature_step_prefix', {defaultValue: 'Signed & '}) : ''

      const translatedCurrentApprovedStage = cancelledStep
                                             ? t('task_history_past.stop')
                                             : t(getTranslationKeyForTaskHistoryInPast(approveStage?.substring(1, approveStage.length - 1) || APPROVE_STAGES_TYPES.DEFAULT_STEP));

      const isUSRegion = environment.region === 'US';
      const currentRegion = isUSRegion ? 'MM/DD/YYYY, hh:mm A' : 'D MMMM Y, HH:mm';
      const currentStepEndDate = taskStartDate
                                 ? moment(taskStartDate).format(currentRegion)
                                 : t('task_data_view.history_tab.no_date');

      return {
        displayApproveStage: currentApproveStagePrefix + translatedCurrentApprovedStage,
        displayApproveStageType: approveStageType,
        completionDateString: currentStepEndDate,
      };
    },
    [],
  );

  const parseStructuredHistory = (initialNode: StructuredHistoryStep) => {
    const stepsList: HistoryNode[] = [];
    let tempNode: HistoryNodeData = initialNode;
    let nodeType: HistoryNodeType = 'step';

    while (tempNode) {
      if (nodeType === 'condition') {
        const tempConditionNode = tempNode as StructuredHistoryCondition;
        const positiveSteps = [];
        const negativeSteps = [];
        if (tempConditionNode?.positiveEvent) {
          let positiveNode = tempConditionNode.positiveEvent;
          while (positiveNode) {
            positiveNode.isFutureStep = !positiveNode.completed && !positiveNode.taskId;
            positiveNode.isCurrentStep = !positiveNode.completed && !!positiveNode.taskId;
            positiveNode = {
              ...positiveNode,
              ...getStepApproveStageInfo(positiveNode),
              assigneeList: positiveNode?.assignee?.split(','),
            };
            positiveSteps.push({ type: 'step', data: positiveNode });
            positiveNode = positiveNode.nextStep;
          }
        }
        if (tempConditionNode?.negativeEvent) {
          let negativeNode = tempConditionNode.negativeEvent;
          while (negativeNode) {
            negativeNode.isFutureStep = !negativeNode.completed && !negativeNode.taskId;
            negativeNode.isCurrentStep = !negativeNode.completed && !!negativeNode.taskId;
            negativeNode = {
              ...negativeNode,
              ...getStepApproveStageInfo(negativeNode),
              assigneeList: negativeNode?.assignee?.split(','),
            };
            negativeSteps.push({ type: 'step', data: negativeNode });
            negativeNode = negativeNode.nextStep;
          }
        }
        tempConditionNode.positiveBranch = positiveSteps.reverse();
        tempConditionNode.negativeBranch = negativeSteps.reverse();
        tempConditionNode.isFutureNode = !tempConditionNode?.actualResult?.length;
        stepsList.push({ type: 'condition', data: tempConditionNode });
      } else if (nodeType === 'parallelBranches') {
        const tempParallelBranchesNode = tempNode as StructuredHistoryParallelBranches;
        const branchesCount = tempParallelBranchesNode.parallelBranches.length;
        let branches = [];
        let isFutureNode = true;
        let isCurrentNode = false;
        for (let branchIndex = 0; branchIndex < branchesCount; branchIndex += 1) {
          branches.push([]);
          let tempBranchNode = tempParallelBranchesNode.parallelBranches[branchIndex];
          if (tempBranchNode?.completed) {
            isFutureNode = false;
          } else {
            if (tempBranchNode?.taskId) {
              isCurrentNode = true;
              isFutureNode = false;
            }
          }
          while (tempBranchNode) {
            tempBranchNode.isFutureStep = !tempBranchNode.completed && !tempBranchNode.taskId;
            tempBranchNode.isCurrentStep = !tempBranchNode.completed && !!tempBranchNode.taskId;
            tempBranchNode = {
              ...tempBranchNode,
              ...getStepApproveStageInfo(tempBranchNode),
              assigneeList: tempBranchNode?.assignee?.split(','),
            } as unknown as StructuredHistoryStep;
            branches[branchIndex].push({ type: 'step', data: tempBranchNode });
            tempBranchNode = tempBranchNode.nextStep;
          }
          branches[branchIndex] = branches[branchIndex].reverse();
        }
        branches = branches.reverse();
        tempParallelBranchesNode.isFutureNode = isFutureNode;
        tempParallelBranchesNode.isCurrentNode = isCurrentNode;
        tempParallelBranchesNode.branches = branches;
        stepsList.push({ type: 'parallelBranches', data: tempParallelBranchesNode });
      } else if (nodeType === 'parallelAssigneesStep') {
        const tempParallelAssigneesNode = tempNode as StructuredHistoryParallelAssigneesStep;
        tempParallelAssigneesNode.assigneeSteps = tempParallelAssigneesNode.parallelEvents.map(node => {
          return {
            type: 'step',
            data: {
              ...node,
              ...getStepApproveStageInfo(node),
              isFutureStep: !node.completed && !node.taskId,
              isCurrentStep: !node.completed && !!node.taskId,
              assigneeList: node?.assignee?.split(';') || [],
            },
          } as HistoryNode;
        });
        tempParallelAssigneesNode.isFutureNode = tempParallelAssigneesNode.assigneeSteps
          .every(s => (s.data as StructuredHistoryStep)?.isFutureStep);
        stepsList.push({ type: 'parallelAssigneesStep', data: tempParallelAssigneesNode });
      } else {
        const nodeWithActionAndDate = {
          ...tempNode,
          assigneeList: tempNode?.assignee?.split(','),
          isFutureStep: !tempNode.completed && !tempNode.taskId,
          isCurrentStep: !tempNode.completed,
          ...getStepApproveStageInfo({
            ...tempNode,
            isCurrentStep: !tempNode.completed,
          }),
        } as StructuredHistoryStep;
        stepsList.push({ type: nodeType, data: nodeWithActionAndDate });
      }

      if (tempNode.conditionHistory) {
        tempNode = tempNode.conditionHistory;
        nodeType = 'condition';
      } else if (tempNode.parallelBranchHistory) {
        tempNode = tempNode.parallelBranchHistory;
        nodeType = 'parallelBranches';
      } else if (tempNode.parallelHistory) {
        tempNode = tempNode.parallelHistory;
        nodeType = 'parallelAssigneesStep';
      } else {
        tempNode = tempNode.nextStep;
        nodeType = 'step';
      }
    }

    const stepsListWithPastTasks = stepsList.map(node => {
      if (node.type === 'step')
        return {
          ...node,
          data: {
            ...node.data,
            isFutureStep: !node.data?.completed && !node.data?.taskId,
            isCurrentStep: !node.data?.completed && node.data?.taskId,
          } as unknown as StructuredHistoryStep,
        } as HistoryNode;

      return node;
    }).reverse();

    return stepsListWithPastTasks;
  };

  const steps = useMemo((): HistoryNode[] => {
    return parseStructuredHistory(structuredHistory);
  }, [structuredHistory]);

  useEffect(() => {
    if (isActiveTab && !structuredHistory) {
      initializeTaskHistory();
    }
  }, [initializeTaskHistory, isActiveTab]);

  const updateTaskHistory = useCallback(() => {
    if (isActiveTab && structuredHistory) {
      initializeTaskHistory();
    }
  }, [isActiveTab, initializeTaskHistory, structuredHistory]);

  useEffect(() => {
    updateTaskHistory();
  }, [isActiveTab]);

  useInterval(updateTaskHistory, HISTORY_REFRESH_INTERVAL);

  return {
    steps,
    error,
    loading,
    parseStructuredHistory,
  };
};

export default useTaskHistory;
