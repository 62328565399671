import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: 'unset',
    border: '1px solid rgba(38, 40, 66, 0.12)',
    borderRadius: '12px',
    background: '#fff',
    marginBottom: 8,

    '&:hover': {
      background: 'rgba(38, 40, 66, 0.04)',
    },
  },
  icon: {
    marginRight: 12,
  },
  documentHeader: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    cursor: 'pointer',
  },
  documentTitleContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  documentTitle: {
    flex: 1,
    display: 'inline-block',
    color: '#262842',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  documentSigned: {
    width: 'fit-content',
    backgroundColor: '#1250F314',
    borderRadius: 6,
    padding: '4px 8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '4px',
    marginLeft: 4,
  },
  documentSignedText: {
    color: '#376BE5',
    fontSize: 12,
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  documentDetails: {
    color: '#6D6E85',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    marginTop: 2,
  },
  iconButtons: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    position: 'absolute',
    right: 0,
  },
  iconButton: {
    width: '32px !important',
    height: '32px !important',
    color: '#262842',
    background: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #E4E4ED',
    borderRadius: 8,
    cursor: 'pointer',
    transition: '0.3s',
    '&:hover': {
      background: 'rgba(38, 40, 66, 0.04)',
      transition: '0.3s',
    },
  },
  fieldsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  fieldWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  fieldLabel: {
    color: '#262842',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '14px',
  },
  fieldValue: {
    color: 'rgb(38, 40, 66)',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
  },
  fieldValueTitle: {
    color: 'rgb(109, 110, 133)',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '18px',
  },
  fieldInfo: {
    color: '#6D6E85',
    fontSize: 12,
    lineHeight: '16px',
    textAlign: 'right',
  },
  accordionRoot: {
    backgroundColor: 'transparent',
    padding: 0,
    boxShadow: 'none',
    margin: '0 !important',
    '&::before': {
      opacity: '1 !important',
      transition: 'none',
    },
  },
  accordionSummary: {
    minHeight: '0 !important',
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: 'transparent',
    margin: 0,
    padding: 0,

    '& svg path': {
      fill: '#262842 !important',
    },
  },
  accordionSummaryContent: {
    margin: '15px 0',
  },
  accordionDetails: {
    padding: '0 0 12px 0',
  },
  sumValidation: {
    width: '100%',
    padding: '8px 12px',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
  },
  sumValidationTitle: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '18px',
    marginBottom: 6,
  },
  sumValidationSubTitle: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
  },
  iconWrapper: {
    width: 24,
    height: 24,
  },
  errorMessage: {
    margin: '0 0 10px 14px',
  },
}));
