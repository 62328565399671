import { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
  setTaskComponentDataLoadingFinished,
  setTaskComponentDataLoadingStarted
} from 'store/requests';
import { getExchangeRate } from 'api/requests';

type Props = {
  name: string;
}

export const useExchangeRate = ({ name }: Props) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [currencyRates, setCurrencyRates] = useState([]);

  const getCurrencyRates = useCallback(async () => {
    dispatch(setTaskComponentDataLoadingStarted(name));

    try {
      const rates = await getExchangeRate();

      setCurrencyRates(rates);
    } catch(error) {
      const { message } = error;

      setCurrencyRates([]);
      setError(message);
    } finally {
      dispatch(setTaskComponentDataLoadingFinished(name));
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getCurrencyRates()
  }, [getCurrencyRates]);

  return {
    loading,
    error,
    currencyRates
  };
};
