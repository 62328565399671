import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ spacing, transitions }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: spacing(6),
    minHeight: 'calc(100vh - 100px)'
  },
  headerWrapper: {
    margin: "12px 0",
    minHeight: 40,
    boxSizing: 'content-box',
  },
  mainTitle: {
    fontSize: 24,
    fontWeight: 500,
    color: '#101F2B'
  },
  filterButton: {
    display: 'flex',
    gap: 10,
    padding: '10px 16px',
    borderRadius: 10,
    height: 40,
  },
  filterButtonActive: {
    background: '#F0F0F7',
    color: '#262842'
  },
  popup: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    top: 0,
    zIndex: 100,

    opacity: 0,
    visibility: "hidden"
  },
  popupOpened: {
    opacity: 1,
    visibility: "visible",
    animation: `$slideEnter 200ms ${transitions.easing.easeInOut}`
  },
  popupClosed: {
    pointerEvents: 'none',
    visibility: "hidden",
    transition: "200ms visibility",
    animation: `$slideExit 200ms ${transitions.easing.easeInOut}`,
  },
  searchInput: {
    width: 260,
    height: '40px !important',

    '& input': {
      padding: 0,
    },
    '& > div': {
      borderRadius: 10,
      height: 40,
    }
  },
  searchIconWrapper: {
    marginRight: 3,

    '& svg': {
      fill: '#8B8C9E',
      width: 17
    }
  },
  paginationText: {
    color: '#6D6E85'
  },
  "@keyframes slideEnter": {
    "0%": {
      opacity: 0,
      transform: "translateX(100%)"
    },
    "100%": {
      opacity: 1,
      transform: "translateX(0)"
    }
  },
  "@keyframes slideExit": {
    "0%": {
      opacity: 1,
      transform: "translateX(0)",
    },
    "100%": {
      opacity: 0,
      transform: "translateX(100%)",
    }
  }
}));
