import React, {ReactElement, useContext, useEffect, useMemo, useState} from "react";
import {Box, Typography} from "@mui/material";
import { useTranslation } from 'react-i18next';

import useTemplateProcessesEmptyContentStyles from './TemplateProcessesEmptyContent.useStyles';
import { CreateFromScratchCard } from "./CreateFromScratchCard";
import { NoContentMessage, Spinner } from "components";
import { getAllTemplates } from "api/requests";
import { TemplatesTableRow } from "pages/Templates/components/TemplatesTable/TemplatesTable.types";
import { ProcessContextType } from "types";
import ProcessContext from "contexts/ProcessContext";
import { TemplateInfoCard } from "../TemplateListDialog/TemplateInfoCard/TemplateInfoCard";
import { TemplatePreview } from "../TemplatePreview";

interface TemplateProcessesEmptyContentProps {
  onProcessCreate: () => void;
  isFilterOn: boolean
}

export const TemplateProcessesEmptyContent = ({ onProcessCreate, isFilterOn }: TemplateProcessesEmptyContentProps): ReactElement => {
  const { t, i18n } = useTranslation();
  const classes = useTemplateProcessesEmptyContentStyles({isFilterOn});
  const [isLoading, setIsLoading] = useState(false);
  const [templates, setTemplates] = useState<(TemplatesTableRow & {stepsCount: number})[]>([]);
  const { groups }: Partial<ProcessContextType> = useContext(ProcessContext);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>(null)

  const getTemplates = async () => {
    setIsLoading(true)

    try {
      const data = await getAllTemplates();

      setTemplates(data);
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getTemplates();
  }, [])

  const templateCategorieNames = useMemo(() => {
    if (templates.length < 1) return [];

    return [...new Set(templates.map(template => template.category))]
  }, [templates])

  return (
    <Box className={classes.root}>
      {isFilterOn ? <Box display="flex" alignItems="center">
        <Box display="flex" flexDirection="column">
          <NoContentMessage noResultsWithFilters />
        </Box>
      </Box>
      :
      <>
        <Typography className={classes.emptyProcessesTitle}>{t("customProcesses.createFirstProcess")}</Typography>
        <Typography className={classes.emptyProcessesDescription}>{t("customProcesses.createProcessFromScratchOrFromTemplate")}</Typography>

        <Box className={classes.createFromScratchCardWrapper}>
          <CreateFromScratchCard onClick={onProcessCreate} />
        </Box>


        {isLoading && <Spinner absolute={false} />}

        {
        templateCategorieNames.length > 0 && <div className={classes.templatesContent}>
            <Typography className={classes.createFromTemplate}>
              {t("customProcesses.createFromTemplate")}
              <span className={classes.showAllTemplates}>{t("templates.showAllTemplates")}</span>
            </Typography>


            {templateCategorieNames.map(category => {
              const translatedCategoryName = t(`groups.${category}.name`, { defaultValue: groups.find(g => g?.sysName === category)?.name })

              return    <Box>
              <Typography className={classes.categoryTitle}>
                  {translatedCategoryName}
              </Typography>

              <div className={classes.templateCards}>
                {templates.filter(template => template.category === category).map(template => {
                  let info = template.languages[0]
                  const foundLanguage = template.languages.find(attr => attr.language === i18n.language)

                  if (foundLanguage){
                    info = foundLanguage;
                  }

                  return <TemplateInfoCard
                    title={template.processName}
                    description={template.description}
                    stepsCount={template.stepsCount}
                    categoryName={translatedCategoryName}
                    onClick={() => {
                      setSelectedTemplateId(template.processSysName)
                    }}
                    iconPath={template.iconPath}
                  />
                })}
              </div>
            </Box>
            })}

        </div>
        }

      {
        selectedTemplateId && <TemplatePreview
        close={() => {
          setSelectedTemplateId(null)
        }}
        id={selectedTemplateId}
        />
      }

      </>
      }

    </Box>
  )
}
