import { jwtDecode } from 'jwt-decode';

import { getUserWorkspacesList, getWorkspaceToken } from 'api/requests';
import { PermissionModule, PermissionType, Workspace } from 'types';

const WORKSPACE_STORAGE_KEY = 'workspace';
const WORKSPACE_TOKEN_STORAGE_KEY = 'workspace_token';

export const changeCurrentWorkspace = async (workspaceId: string) => {
  const userWorkspaces = await getUserWorkspacesList();
  const workspaceData = userWorkspaces.find(workspace => workspace.id === workspaceId);
  const tokenResponse = await getWorkspaceToken(workspaceId);

  localStorage.setItem(WORKSPACE_TOKEN_STORAGE_KEY, tokenResponse?.token);
  localStorage.setItem(WORKSPACE_STORAGE_KEY, JSON.stringify(workspaceData));

  window.location.reload();
}

export const hasPermission = (
  module: PermissionModule,
  crudType: PermissionType
) => {
  const workspaceToken = localStorage.getItem(WORKSPACE_TOKEN_STORAGE_KEY);
  const decodedToken = jwtDecode<Workspace.WorkspaceDecodedToken>(
    workspaceToken
  );
  return decodedToken.permissions?.[module]?.includes(crudType);
};
