import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({spacing}) => {
  return {
    newRequestButton: {
      height: 40,
      padding: spacing(2, 3),
      marginLeft: spacing(3)
    },
    settingsButton: {
      width: 40,
      maxWidth: 40,
      minWidth: 40,
      height: 40,
      marginLeft: spacing(3),
      background: '#FFFFFF',
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: 10,
      padding: 9
    },
    menuItem: {
      color: '#262842',
      '& img': {
        marginRight: 9
      }
    }
  }
})
