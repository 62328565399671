import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => ({
  multilineSmall: {
    '& textarea:first-child': {
      minHeight: 36,
    }
  },
  multilineMedium: {
    '& textarea:first-child': {
      minHeight: 72,
    }
  },
  multilineLarge: {
    '& textarea:first-child': {
      minHeight: 108,
    }
  },
  textField: {
    '& > button': {
      display: 'none'
    }
  }
}));
