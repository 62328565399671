import React, { useState, useEffect, Suspense } from 'react';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';

import createGenerateClassName from '@mui/styles/createGenerateClassName';
import StylesProvider from '@mui/styles/StylesProvider';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { matomoScript, matomoTagManagerScript } from 'utils/analytics';

import { authService } from '@dar-dms/topbar';
import { ErrorBoundary } from '@dar-dms/utils';
import theme from 'theme';
import store from 'store';
// import { matomoScript, matomoTagManagerScript } from 'utils/analytics';

import { MainLayout as BpmApp } from 'layouts';
import { Spinner } from 'components';


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {
  }
}

const generateClassName = createGenerateClassName({
  productionPrefix: 'processes'
});

const Root = () => {
  const [isAuthLoaded, setAuthLoaded] = useState<boolean>(false);

  useEffect(() => {
    // Инициализация Matomo
    eval(matomoScript);
    // Инициализация Tag Manager
    eval(matomoTagManagerScript);
  }, []);

  useEffect(() => {
    if (authService) {
      const subscribe = authService.loggedIn.subscribe((auth) => {
        if (auth) {
          setAuthLoaded(true);
        }
      });

      return () => {
        subscribe.unsubscribe();
      };
    }
  }, []);

  useEffect(() => {
    // Инициализация Matomo
    eval(matomoScript);
    // Инициализация Tag Manager
    eval(matomoTagManagerScript);
  }, []);

  if (!isAuthLoaded) {
    return <Spinner absolute={false} />;
  }

  return (
    <ErrorBoundary moduleName="dar-dms-processes">
      <React.StrictMode>
        <StyledEngineProvider injectFirst>
          <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
              <Suspense fallback={<Spinner fullscreen absolute={false} />}>
                <Provider store={store}>
                  <BrowserRouter basename="processes">
                    <BpmApp />
                  </BrowserRouter>
                </Provider>
              </Suspense>
            </ThemeProvider>
          </StylesProvider>
        </StyledEngineProvider>
      </React.StrictMode>
    </ErrorBoundary>
  );
};

export default Root;
