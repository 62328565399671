import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ spacing }) => ({
  tabs: {
    minHeight: 'auto',
    borderBottom: '1px solid #CED7DF',
  },
  tabIndicator: {
    display: 'block',
  },
  tab: {
    fontSize: 14,
    color: '#101F2B',
    textTransform: 'none',
    padding: spacing(2.5, 4),
    minHeight: 'auto',
    borderBottom: 'none',
    flexDirection: 'row',
    minWidth: 100,
    '&.Mui-selected': {
      border: 'none',
      fontWeight: 'medium',
    },
    '&.MuiButtonBase-root': {
      padding: spacing(2.5, 4),
    }
  },
  templatesTab: {
    padding: spacing(2.25, 3),
    '& span': {
      flexDirection: 'row',
      alignItems: 'center',
    }
  },
  tabIcon: {
    height: 20,
    maxHeight: 20,
    width: 'auto',
    marginBottom: '0 !important',
    marginRight: spacing(1),
  }
}));
