import React, { ReactElement, useMemo } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { FormButton } from 'pages/Task/TaskForm/ActionsBar/FormButtonList/FormButton';
import { useFieldBehaviour } from 'pages/Task/TaskForm/FormFields/hooks';

import { getTranslationKeyForButtonLabel } from 'utils/translations';
import { useTaskState } from 'store/requests';
import { useUserProfile, useUsersRole } from 'hooks';
import { isButtonVisible } from 'pages/Task/TaskForm/ActionsBar/FormButtonList/utils';

type Props = {
  hint?: string;
  behaviour: any;
  attributeId: any;
  attributeSysName?: string;
  stateOrder: any;
  params: any;
  handleAction: any;
};

type Button = {
  action?: string;
  key?: number;
  color?: 'primary' | 'inherit' | 'default' | 'secondary';
  colorClass?: 'info' | 'danger' | 'orange' | 'success' | 'neutral';
  variant?: 'text' | 'contained';
  label?: string;
  type?: string;
  isIconConnected?: boolean;
  icon?: string;
  componentParams?: any;
  behaviour?: any;
  component?: string;
  attributeId?: number;
  attachmentSigning?: string;
  isExternalSign?: boolean;
  isInternalSign?: boolean;
  isInternalSignWithEmployee?: boolean;
  isInternalSignEmployeeInitiator?: boolean;
  showOnlyToInitiator?: boolean;
  showOnlyWithAssignee?: boolean;
  showOnlyWithoutAssignee?: boolean;
  useWithoutRequiredFieldsFilled?: boolean;
  leftSideButton?: boolean;
  createLinkedEntity?: boolean;
};

export const FormButtonField = ({
  hint,
  behaviour,
  attributeId,
  attributeSysName = '',
  stateOrder,
  params,
  handleAction,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const { isRuleGroupWorks, getFieldsByListOfRules } = useFieldBehaviour();
  const { data: bpmTask } = useTaskState();
  const { id: profileId, nickname: profileNickName } = useUserProfile();
  const { hasAccessToPromoteRequests } = useUsersRole();

  const hasAssignee = useMemo((): boolean => !!bpmTask?.assignee, [bpmTask]);

  const isAssignee: boolean = useMemo(
    (): boolean =>
      bpmTask?.isAssignee(profileId, hasAccessToPromoteRequests) || bpmTask?.isAssignee(profileNickName, hasAccessToPromoteRequests),
    [bpmTask, profileId, profileNickName, hasAccessToPromoteRequests]
  );

  const isAssigneeCandidate: boolean = useMemo(
    (): boolean =>
      bpmTask?.isAssigneeCandidate(profileId, hasAccessToPromoteRequests) ||
      bpmTask?.isAssigneeCandidate(profileNickName, hasAccessToPromoteRequests),
    [bpmTask, profileId, profileNickName, hasAccessToPromoteRequests]
  );

  const isInitiator: boolean = useMemo((): boolean => bpmTask?.initiator === profileId || bpmTask?.initiator === profileNickName, [
    bpmTask,
    profileId,
    profileNickName,
  ]);

  const button = useMemo(() => {
    const parsedButton: Button = {};

    parsedButton.component = 'action-button';
    parsedButton.behaviour = behaviour;
    parsedButton.key = attributeId + stateOrder;
    parsedButton.action = params?.value || '';
    parsedButton.label = t(getTranslationKeyForButtonLabel(params?.label, bpmTask.processSysName), { defaultValue: params?.label || '' });
    parsedButton.icon = params?.icon || '';
    parsedButton.componentParams = params;

    parsedButton.showOnlyToInitiator = !!params?.showOnlyToInitiator;
    parsedButton.showOnlyWithAssignee = !!params?.showOnlyWithAssignee;
    parsedButton.showOnlyWithoutAssignee = !!params?.showOnlyWithoutAssignee;

    parsedButton['variant'] = params?.textButton ? 'text' : 'contained';

    switch (params.class) {
      case 'success':
        parsedButton.color = 'primary';
        break;
      case 'danger':
        parsedButton.color = 'secondary';
        break;
      default:
        parsedButton.color = 'default';
        break;
    }

    parsedButton.colorClass = params.class || 'neutral';

    return parsedButton;
  }, [behaviour, attributeId, stateOrder]);

  const isVisible = useMemo(
    () =>
      isButtonVisible({
        button,
        bpmTask,
        isRuleGroupWorks,
        getFieldsByListOfRules,
        isInitiator,
        isAssignee,
        hasAssignee,
        isAssigneeCandidate,
      }),
    // eslint-disable-next-line
    [button, bpmTask, isInitiator, isAssignee, hasAssignee, isAssigneeCandidate]
  );

  return (
    <div>
      {hint && <Typography>{hint}</Typography>}
      <FormButton
        index={attributeId}
        isVisible={isVisible}
        disabled={false}
        button={button}
        inFormVariant={!hint}
        inFormVariantWithHint={!!hint}
        isEDSButton={() => false}
        onClick={(index, action, button) => {
          handleAction({ index, action, componentParams: button.componentParams });
        }}
      />
    </div>
  );
};
