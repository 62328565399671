import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { Box, Checkbox, FormControlLabel, Modal, Radio, Tooltip } from '@mui/material';
import cn from 'classnames';
import { Trans, useTranslation } from 'react-i18next';

import useAutostartCheckboxStyles from './AutostartCheckbox.useStyles';
import { AllProcessFieldsListItem, DocumentTemplate, ProcessStep } from '../../TemplateProcesses.types';

import InfoIcon from 'assets/images/icons/info_outline.svg';
import { ReactComponent as CheckboxUncheckedIcon } from 'assets/images/icons/checkbox-unchecked.svg';
import { ReactComponent as CheckboxCheckedIcon } from 'assets/images/icons/checkbox-checked.svg';
import { ConfirmationWithImageModal } from '../../../../components/Modal/ConfirmationWithImageModal';

interface AutostartCheckboxProps {
  currentStep: ProcessStep;
  value: boolean;
  disabled?: boolean;
  handleAutostartToggle: (value: boolean) => void;
  handleRadio?: (num: number, newState: string) => void;
  requestInitiatorSteps?: number[]
}

export const AutostartCheckbox = ({
  currentStep,
  value,
  disabled = false,
  handleAutostartToggle,
                                    handleRadio,
                                    requestInitiatorSteps
}: AutostartCheckboxProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useAutostartCheckboxStyles();
  const [isConfirmationModalOpen, setConfirmationModalClose] = useState(false);

  const handleAutoStartConfirm = () => {
      const newState = !value;
      handleAutostartToggle(newState);
      if (newState) {
        handleRadio(1, 'autoStart'); // Синхронизируем с радио-баттоном
      }
  }


  const helpTooltipContent =
    <div className={classes.helpTooltipContent}>
      <p>
        {t('customProcesses.creationPage.processForm.autostartCheckboxHelp1')}
      </p>
      <p>
        <Trans i18nKey="customProcesses.creationPage.processForm.autostartCheckboxHelp">
          <div dangerouslySetInnerHTML={{ __html: t('customProcesses.creationPage.processForm.autostartCheckboxHelpSubtitle') }}></div>
        </Trans>
      </p>
    </div>;


  return (
    <Box
      className={cn(classes.DirectManagerCheckbox)}>
      {currentStep?.stepOrder === 1 && (
        <>
          <Box mt={1} className={classes.checkboxWrapper}>
            <FormControlLabel
              //className={cn(classes.checkbox)}
              control={<Radio
                checked={value}
                //color="primary"
                disabled={disabled}
                classes={{
                  root: classes.root,
                  checked: classes.checked,
                  disabled: classes.disabled
                }}
                // onClick={() => {
                //   const newState = !value;
                //   handleAutostartToggle(newState);
                //   if (newState) {
                //     handleRadio(1, 'autoStart'); // Синхронизируем с радио-баттоном
                //   }
                // }}
                onChange={(e) => {
                  // const newState = e.target.checked; // Получаем новое значение из события
                  // handleAutostartToggle(newState);
                  // if (newState) {
                  //   handleRadio(1, 'autoStart');
                  // }
                  const newState = e.target.checked; // Получаем новое значение из события

                  if (requestInitiatorSteps.length > 0) {
                    // Если массив не пуст, вызываем модалку
                    setConfirmationModalClose(true);
                  } else {
                    // Если массив пуст, выполняем первую логику
                    handleAutostartToggle(newState);
                    if (newState) {
                      handleRadio(1, 'autoStart');
                    }
                  }
                }}
                //onChange={e => setConfirmationModalClose(true)}
               //onChange={e => handleAutostartToggle(value)}
              />}
              classes={{label: classes.checkboxLabel}}
              label={t('customProcesses.creationPage.processForm.autostartCheckboxLabel')}
            />

            <Tooltip
              classes={{
                popper: classes.tooltipPopper,
                tooltip: classes.helpTooltip,
              }}
              arrow
              placement="bottom"
              title={helpTooltipContent}
              leaveDelay={500}
            >
              <img src={InfoIcon} className={classes.infoIcon}/>
            </Tooltip>
          </Box>
        </>
      )}

      <Modal open={isConfirmationModalOpen}>
        <Box>
          <ConfirmationWithImageModal
            description={t('customProcesses.creationPage.autoStartDescription')}
            title={t('customProcesses.creationPage.processForm.autostartActivationDialogTitle')}
            okButtonText={t('customProcesses.creationPage.buttons.confirm')}
            close={() => {
              setConfirmationModalClose(false);
            }}
            action={async () => {
              setConfirmationModalClose(false);
              handleAutoStartConfirm()
            }}
          />
        </Box>
      </Modal>
    </Box>
  );
};
