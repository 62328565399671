import React, { useEffect, useMemo, useState } from 'react';
import { Button, Tooltip, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getGlossaryAsTree } from 'api/requests';
import { Spinner } from 'components';

import { useStyles } from './ReadOnlyMultiSelectGlossary.styles';
import { ReadOnlyMultiSelectGlossaryDialog } from './ReadOnlyMultiSelectGlossaryModal';
import { initializeSelectedStates } from '../Glossary/utils';

type DirectoryInfo = {
  id: string;
  [key: string]: any;
};

type TreeStructureNode = {
  data: DirectoryInfo;
  children: TreeStructureNode[];
  id?: string;
};

type Props = {
  name?: string;
  hint?: string;
  params?: { [key: string]: unknown };
};

enum FetchingState {
  SUCCESS,
  ERROR,
  LOADING,
  IDLE,
}

export const ReadOnlyMultiSelectGlossary = ({ name, hint, params }: Props) => {
  const { i18n, t } = useTranslation();

  const classes = useStyles();
  const [selectedIds, setSelectedIds] = useState([]);
  const [treeStructure, setTreeStructure] = useState([]);
  const [treeStructureNodes, setTreeStructureNodes] = useState({});
  const [fetchingState, setFetchingState] = useState(FetchingState.LOADING);
  const [open, setOpen] = useState(false);

  const { watch } = useFormContext();

  const formValue = watch(name);

  const displayedValues = useMemo(
    () =>
      selectedIds.map((id) => {
        const nodeData = (Object.values(treeStructureNodes).find((node) =>
          [(node as TreeStructureNode)?.id, (node as TreeStructureNode)?.data?.id].includes(id)
        ) as TreeStructureNode)?.data;
        return nodeData?.localization?.[i18n.language] || nodeData?.directoryName || nodeData?.value || id;
      }),
    [selectedIds, treeStructureNodes]
  );

  const init = async () => {
    try {
      const directoriesPathList = params?.directoryPath ? (params.directoryPath as string).split('/') : [null];
      const directoryId = directoriesPathList[directoriesPathList.length - 1];
      const directoryCode = params.directoryCode;
      const directoryGettingVariant = directoryCode ? 'code' : 'id';
      getGlossaryAsTree(null, true).then((directories) => {
        setFetchingState(FetchingState.SUCCESS);
        const nodesList = initializeSelectedStates(
          directories.filter((node) => node.data.status === 'PUBLISHED'),
          selectedIds
        );
        const directoryNode = Object.values(nodesList).find((node) =>
          directoryGettingVariant === 'id'
            ? (node as TreeStructureNode)?.data?.id === directoryId
            : (node as TreeStructureNode)?.data?.directoryName === directoryCode
        ) as TreeStructureNode;
        setTreeStructure(directoryNode?.children.filter((node) => node.data.status === 'PUBLISHED'));
      });
    } catch (error) {
      console.log('Error loading glossary values');
    }
  };

  useEffect(() => {
    if (!open && !!formValue && selectedIds.length === 0) {
      setSelectedIds(formValue.split(',') as string[]);
      init();
    }
    if (!formValue) {
      setFetchingState(FetchingState.SUCCESS);
    }
  }, [formValue, open, params?.directoryPath]);

  useEffect(() => {
    if (treeStructure) {
      setTreeStructureNodes(initializeSelectedStates(treeStructure, selectedIds, true));
    }
  }, [selectedIds, treeStructure]);

  const handleShowAll = () => {
    setOpen(true);
  };

  if (selectedIds?.length === 0 && fetchingState === FetchingState.SUCCESS) {
    return (
      <>
        {!!hint && <Typography>{hint}</Typography>}
        <Typography className={classes.emptyValueMessage}>{t('form_components.readOnly.emptyValue')}</Typography>
      </>
    );
  }

  return (
    <>
      {!!hint && <Typography>{hint}</Typography>}

      <div className={classes.valuesListWrapper}>
        {fetchingState === FetchingState.LOADING ? (
          <div style={{ width: 16, height: 16 }}>
            <Spinner buttonVersion absolute={false} size={16} />
          </div>
        ) : (
          displayedValues.map((displayedValue) => (
            <Tooltip
              title={displayedValue}
              placement="top"
              arrow
              enterDelay={500}
              classes={{ popper: classes.tooltipPopper, tooltip: classes.tooltip }}
            >
              <div className={classes.valueChip}>{displayedValue}</div>
            </Tooltip>
          ))
        )}
      </div>

      {fetchingState === FetchingState.SUCCESS && (
        <Button variant="text" className={classes.showAllButton} onClick={handleShowAll}>
          {t('buttons.showAll', { valuesCount: selectedIds.length, defaultValue: `Show all (${selectedIds.length})` })}
        </Button>
      )}

      <ReadOnlyMultiSelectGlossaryDialog
        isOpen={open}
        onClose={() => setOpen(false)}
        glossaryNodes={treeStructureNodes}
        treeStructure={treeStructure}
        values={selectedIds}
        title={hint}
      />
    </>
  );
};
