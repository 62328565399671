import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, IconButton, Link, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import cn from 'classnames';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { MoreHoriz } from '@mui/icons-material';

import { useUsersState } from 'store/users';
import { LOCALIZED_TIME_UNITS } from 'utils/time';

import { FirstErrorStepIndexContext, ConfirmDeleteProcessStepModalContext } from '../SetupTemplateProcessesDialog';
import { PROCESS_STEP_CARD_ERROR_WRAPPER_CLASSNAME, ProcessStepTag, UserDropdownCard } from '../index';
import { UserInfo } from '../../../../components';
import { ProcessStepAddMenu } from '../ProcessStepAddMenu';
import { AssigneeListModal } from './AssigneeListModal';
import EmptyAvatar from 'assets/images/icons/empty-avatar.svg'
import {
  DepartmentInfo,
  Language,
  ProcessStep,
  StepValidationResult,
  UserSelectDepartmentData,
} from '../../TemplateProcesses.types';

import useProcessStepCardStyles from './ProcessStepCard.useStyles';

import AutostartIcon from 'assets/images/icons/outlined-play-icon.svg';
import PersonIcon from 'assets/images/icons/new-user-icon.svg';
import ClockIcon from 'assets/images/icons/clock-icon.svg';
import SignatureIcon from 'assets/images/icons/signature-icon.svg';
import PlusIcon from 'assets/images/icons/black-plus-sign.svg';
import WarningIcon from 'assets/images/icons/warning_regular.svg';
import { DM_CHECK_ASSIGNEE, PERFORMER_STEP_ASSIGNEE, REQUEST_INITIATOR } from 'pages/TemplateProcesses/TemplateProcesses.constants';

interface ProcessStepCardProps {
  tagSize?: 'small' | 'medium';
  processIntegrations?: string[];
  language: Language;
  processStep: ProcessStep;
  stepErrors: StepValidationResult;
  departmentsList: DepartmentInfo[];
  documentSignaturesCount: number;
  isActive: boolean;
  isReadonlyView: boolean;
  isEditDisabled?: boolean;
  isDeletionDisabled?: boolean;
  hideLines?: boolean;
  isMenuButtonShown?: boolean;
  isAddCardButtonShown?: boolean;
  isAddCardButtonDisabled?: boolean;
  isAddConditionButtonAvailable?: boolean;
  isAddParallelBranchesButtonAvailable?: boolean;
  isDirectManagerStep?: boolean;
  isPerformerStep?: boolean;
  isRequestInitiatorStep?: boolean;
  isAutostartActive?: boolean;
  handleClick: () => void;
  handleAddCard?: () => void;
  handleAddCondition?: () => void;
  handleAddParallelStepsGroup?: () => void;
  index?: number;
  tagStepOrder?: number | string;
  isTemplatePreview?: boolean;
  isFlowPreview?: boolean;
}

export const ProcessStepCard = ({
  tagSize = 'small',
  tagStepOrder,
  language,
  processStep,
  documentSignaturesCount,
  stepErrors,
  departmentsList,
  processIntegrations = [],
  isActive,
  isReadonlyView = false,
  isEditDisabled = false,
  isDeletionDisabled = false,
  hideLines = false,
  isMenuButtonShown = true,
  isAddCardButtonShown = true,
  isAddCardButtonDisabled = false,
  isAddConditionButtonAvailable = true,
  isAddParallelBranchesButtonAvailable = true,
  isDirectManagerStep = false,
  isPerformerStep = false,
  isRequestInitiatorStep = false,
  isAutostartActive = false,
  handleClick,
  handleAddCard,
  handleAddCondition,
  handleAddParallelStepsGroup,
  isTemplatePreview,
  index,
  isFlowPreview,
}: ProcessStepCardProps): ReactElement => {
  const { t, i18n } = useTranslation();
  const classes = useProcessStepCardStyles();

  const { index: firstErrorStepIndex, setEl, ref: firstErrorStepEl } = useContext(FirstErrorStepIndexContext) || {};

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [addMenuAnchorEl, setAddMenuAnchorEl] = useState<null | HTMLElement>(null);

  const [displayedAssigneeList, setDisplayedAssigneeList] = useState([]);

  const {
    setIsOpenConfirmDeleteModal,
    setDataForDelete,
  } = useContext(ConfirmDeleteProcessStepModalContext);

  const rootRef = useRef(null);

  const { users, positions } = useUsersState();

  useEffect(() => {
    if (firstErrorStepIndex === index) {
      setEl(rootRef.current);
    }
  }, [firstErrorStepIndex]);

  const deactivatedUsersInfo = useMemo(() => {
    if (!isFlowPreview || processStep?.stepOrder === 1) return null;

    const count = processStep?.assigneeIdFromBackend.reduce((acc, userId) => {
      if (![DM_CHECK_ASSIGNEE, PERFORMER_STEP_ASSIGNEE, REQUEST_INITIATOR].includes(userId) && !users[userId]) {
        acc += 1;
      }

      return acc;
    }, 0);

    if (count === 0) return null;

    return <Typography className={classes.usersDeactivatedInfo}>
      <img src={WarningIcon} width={16} alt=""/>

      {count} {t('customProcesses.usersDeactivated')}
    </Typography>;

  }, [isFlowPreview, users, processStep]);


  const assigneeIcon = useMemo(() => {
    if (processStep?.stepOrder === 1 && isAutostartActive) {
      return AutostartIcon;
    }

    return PersonIcon;
  }, [processStep?.stepOrder, isAutostartActive]);

  const showAllAssignees = (allUsers: any[]) => {
    setDisplayedAssigneeList(allUsers.map(user => {
      let jobTitle = user.jobTitle;
      const position = positions[user.jobTitle];
      if (position) {
        jobTitle = position.value;

        const translatedValue = position.localization?.[i18n.language];
        if (translatedValue) {
          jobTitle = translatedValue;
        }
      }

      return {
        ...user,
        jobTitle,
      };
    }));
  };

  const assigneeInfo = useCallback((userId = null) => {
    const stepCardName = JSON.parse(localStorage.getItem('selectedRadioByStep')) || '';

    if (!processStep?.assigneeId?.length ||
      (!processStep?.parallel && processStep?.assigneeId?.filter(v => !!v)?.length === 0) ||
      (!userId && userId !== null)) {
      let assigneeString = t('customProcesses.creationPage.processForm.assigneeNotSelected');
      let isEmptyAssignee = true;

      if (isDirectManagerStep) {
        assigneeString = t('customProcesses.creationPage.processForm.assigneeDirectManager');
        isEmptyAssignee = false;
      }

      if (isRequestInitiatorStep && !isAutostartActive) {
        assigneeString = t('customProcesses.requestInitiatorTitle');
        isEmptyAssignee = false;
      }

      if (isPerformerStep) {
        assigneeString = t('customProcesses.creationPage.processForm.assigneePerformer');
        isEmptyAssignee = false;
      }

      if (processStep?.stepOrder === 1 && isAutostartActive) {
        assigneeString = t('customProcesses.table.autostart');
        isEmptyAssignee = false;
      }

      return (<Typography
        className={cn(classes.processStepCardText, { [classes.processStepCardTextEmpty]: isEmptyAssignee })}>{!isEmptyAssignee && <img src={EmptyAvatar} />} {assigneeString}
      </Typography>);
    }

    if (userId || processStep?.assigneeId?.length === 1) {
      return <UserInfo user={users[userId || processStep?.assigneeId[0]]} avatarSize={20} smallName truncateName/>;
    }

    if (isFlowPreview) {
      const allUsers = processStep?.assigneeId?.map(userId => {
        const user = users[userId];
        if (user) {
          let departmentName = user?.department;
          const departmentInfo = departmentsList.find(({ id }) => id === departmentName);
          if (departmentInfo) {
            departmentName = Object.keys(departmentInfo.localization).includes(i18n.language)
                             ? departmentInfo.localization[i18n.language]
                             : departmentInfo.value;
          }
          return {
            ...user,
            departmentName,
          };
        }

        return null;
      }).filter(Boolean);

      const displayedUsers = allUsers.slice(0, 3);

      return displayedUsers.length && <Box className={classes.processStepAssigneeWrapper}>
        {displayedUsers.map(user => {
          return <UserInfo user={user} avatarSize={20} smallName truncateName/>;
        })}

        {allUsers.length > displayedUsers.length && <Link
          component="button"
          variant="body2"
          onClick={() => showAllAssignees(allUsers)}
          className={classes.showAllAssigneeBtn}
        >
          {t('customProcesses.customProcesses.readonlyView.showAll')}
        </Link>}
      </Box>;
    }

    const displayedAssigneeId = processStep?.assigneeId[0];
    const tooltipAssignees = processStep?.assigneeId?.map(userId => users[userId]).filter(Boolean);

    const tooltipAssigneesDividedByDepartments =
      tooltipAssignees.reduce((acc, assignee): { [key: string]: UserSelectDepartmentData } => {
        const userDepartmentKey = assignee?.department || 'no_department';
        if (!Object.keys(acc).includes(userDepartmentKey)) {
          let departmentName = userDepartmentKey === 'no_department'
                               ? t('form_components.select_users.no_department')
                               : userDepartmentKey;

          const departmentInfo = departmentsList.find(({ id }) => id === userDepartmentKey);
          if (departmentInfo) {
            departmentName = Object.keys(departmentInfo.localization).includes(i18n.language)
                             ? departmentInfo.localization[i18n.language]
                             : departmentInfo.value;
          }

          acc[userDepartmentKey] = {
            key: userDepartmentKey,
            name: departmentName,
            users: [],
          };
        }
        acc[userDepartmentKey].users.push(assignee);
        return acc;
      }, {});

    const tooltipAssigneesSorted = (Object.values(tooltipAssigneesDividedByDepartments) as UserSelectDepartmentData[])
      // sort placing 'no department assign' group to the end
      .sort((a, b) => a.key === 'no_department' ? 1 : a.name.localeCompare(b.name))
      .map(department => ({
        ...department,
        users: department.users.sort((a, b) => a.fullName.localeCompare(b.fullName)),
      }));

    const tooltipContent = <Scrollbars style={{ width: 160, height: 160 }} className={classes.tooltipContent}>{
      tooltipAssigneesSorted.map(department => {
        return <>
          <span>{department.name}</span>
          <ul>
            {department.users.map(user => <li>{user?.fullName}</li>)}
          </ul>
        </>;
      })
    }
    </Scrollbars>;

    return (
      <Box className={classes.processStepAssigneeWrapper}>
        <UserInfo user={users[displayedAssigneeId]} avatarSize={20} smallName truncateName/>
        <Tooltip
          arrow
          placement="bottom"
          title={tooltipContent}
          leaveDelay={500}
          classes={{
            popper: classes.processStepAssigneesTooltipPopper,
            tooltip: classes.processStepAssigneesTooltip,
          }}>
          <span className={classes.processStepAssigneeCount}>(+{tooltipAssignees.length - 1})</span>
        </Tooltip>
      </Box>);
  }, [processStep, departmentsList, isDirectManagerStep, isPerformerStep, isRequestInitiatorStep, isAutostartActive]);

  const hasErrors = useMemo(() =>
      stepErrors?.assigneeId || stepErrors?.stepName?.length > 0 || stepErrors?.timer || stepErrors?.lengthLimit?.stepName?.length > 0
      || stepErrors?.fields || stepErrors?.autostartRepeatCount || stepErrors?.autostartEndRepeatCount
    , [stepErrors]);

  const isAddButtonAvailable = useMemo(() => {
      return !isReadonlyView && !isPerformerStep && processStep?.stepOrder > 1;
    }
    , [isReadonlyView, isPerformerStep, processStep]);

  return (
    <div
      ref={rootRef}
      className={cn(classes.wrapper, {
        [classes.wrapperError]: hasErrors,
        [classes.wrapperWithoutAddButton]: !isAddButtonAvailable && isAddCardButtonShown,
        [classes.wrapperAddButtonNotShown]: !isAddCardButtonShown,
        [classes.wrapperWithoutLines]: hideLines,
      })}>
      {(isAddButtonAvailable && isAddCardButtonShown) &&
        (<IconButton
        className={classes.processStepAddStep}
        onClick={({ currentTarget }) => {
          setAddMenuAnchorEl(currentTarget);
        }}
        disabled={isAddCardButtonDisabled}
        size="large">
          <img src={PlusIcon} className={classes.processStepAddStepIcon}/>
        </IconButton>)
      }

      <Box>

        <Box
          className={cn(classes.processStepCard, {
            [classes.processStepCardActive]: isActive,
            [classes.processStepCardError]: hasErrors && !isActive,
            [PROCESS_STEP_CARD_ERROR_WRAPPER_CLASSNAME]: hasErrors,
          })}
          onClick={handleClick}
        >
          <div className={classes.processStepCardTopRow}>
            <div className={classes.processStepCardTagsWrapper}>
              <ProcessStepTag
                size={tagSize}
                stepOrder={tagStepOrder || processStep?.stepOrder}
              />
              {(processIntegrations?.length > 0 && processStep.stepOrder === 1 ) &&
                <ProcessStepTag
                  size={tagSize}
                  stepOrder={tagStepOrder || processStep?.stepOrder}
                  isIntegratedStep
                />
              }
            </div>

            {(processStep?.stepOrder > 1 && !isDeletionDisabled && !isReadonlyView && !isEditDisabled && isMenuButtonShown) && (
              <IconButton
                size="small"
                onClick={({ currentTarget }) => {
                  setAnchorEl(currentTarget);
                }}
              >
                <MoreHoriz fontSize="small" color="secondary"/>
              </IconButton>
            )}

          </div>

          <Box pt={isTemplatePreview ? 0 : 3}>
            {processStep?.stepName[language]
             ? <Typography className={classes.processStepCardName}>{processStep?.stepName[language]}</Typography>
             : <Typography
               className={classes.processStepCardNamePlaceholder}>{t('customProcesses.processStep.stepNamePlaceholder')}</Typography>
            }
          </Box>

          {((!isTemplatePreview && !isFlowPreview) || (isFlowPreview && processStep?.timer)) && processStep?.stepOrder > 1 &&
            (<Box display="flex" alignItems="flex-start" pt={2.5}>
              <img src={ClockIcon} className={isFlowPreview
                                              ? classes.processStepCardIconInTemplatePreview
                                              : classes.processStepCardIcon} alt=""/>

              <Box pl={1} pt={0.5}>
                <Typography className={classes.processStepCardTitle}>
                  {t('customProcesses.processStep.time')}:
                </Typography>
              </Box>

              <Box pl={2} pt={0.5}>
                <Typography
                  className={cn(classes.processStepCardText, { [classes.processStepCardTextEmpty]: !processStep?.timer })}>
                  {processStep?.timer
                   ? processStep?.timer + ' ' + LOCALIZED_TIME_UNITS[i18n.language].hour
                   : t('customProcesses.creationPage.processForm.assigneeNotSelected')}
                </Typography>
              </Box>
            </Box>)
          }

          {documentSignaturesCount > 0 &&
            (<Box display="flex" alignItems="flex-start" pt={2.5}>
              <img src={SignatureIcon} className={isFlowPreview ? classes.processStepCardIconInTemplatePreview : classes.processStepCardIcon} alt=""/>

              <Box pl={1} pt={0.5}>
                <Typography className={classes.processStepCardTitle}>
                  {t('customProcesses.processStep.signatures')}:
                </Typography>
              </Box>

              <Box pl={2} pt={0.5}>
                <Typography
                  className={cn(classes.processStepCardText)}>
                  {documentSignaturesCount}
                </Typography>
              </Box>
            </Box>)
          }

          {deactivatedUsersInfo}

          {/* TODO: Refactor assignee render */}
          {processStep?.parallel && !isTemplatePreview
           ?
           (!isFlowPreview ? <Box className={classes.parallelAssigneesWrapper}>
            <span className={classes.parallelAssigneesTitle}>
              {t('customProcesses.creationPage.processForm.parallelAssigneeTitle')}{' '}({processStep?.assigneeId?.length})
            </span>
                             {processStep?.assigneeId.map(assignee => {
                               return <Box className={classes.parallelAssigneesItem}>
                                 <img src={assigneeIcon} className={isFlowPreview
                                                                    ? classes.processStepCardIconInTemplatePreview
                                                                    : classes.processStepCardIcon} alt=""/>

                                 {!(processStep?.stepOrder === 1 && isAutostartActive) &&
                                   (<Box pl={1}>
                                     <Typography className={classes.processStepCardTitle}>
                                       {t('customProcesses.creationPage.processForm.assignee')}:
                                     </Typography>
                                   </Box>)
                                 }

                                 <Box pl={2} className={classes.parallelAssigneesItemUser}>
                                   {assigneeInfo(assignee)}
                                 </Box>
                               </Box>;
                             })
                             }
                           </Box> :
            <Box display="flex" pt={2} className={cn(
              classes.processStepCardAssigneeBlockWrapper,
              {
                [classes.processStepCardAssigneeBlockWrapperForFlowPreview]: isFlowPreview,
              },
            )}>
              <img src={assigneeIcon} className={isFlowPreview
                                                 ? classes.processStepCardIconInTemplatePreview
                                                 : classes.processStepCardIcon} alt=""/>

              {!(processStep?.stepOrder === 1 && isAutostartActive) &&
                (<Box pl={1} pt={0.5}>
                  <Typography className={classes.processStepCardTitle}>
                    {t('customProcesses.creationPage.processForm.parallelAssigneeTitle')}
                  </Typography>
                </Box>)
              }

              <Box pl={2} pt={0.5} className={classes.processStepCardAssigneeInfoWrapper}>
                {assigneeInfo()}
              </Box>
            </Box>
           )
           :
           !isTemplatePreview && <Box display="flex" pt={2} className={cn(
               classes.processStepCardAssigneeBlockWrapper,
               {
                 [classes.processStepCardAssigneeBlockWrapperForFlowPreview]: isFlowPreview,
               },
             )}>
               <img src={assigneeIcon}
                    className={isFlowPreview ? classes.processStepCardIconInTemplatePreview : classes.processStepCardIcon}
                    alt=""/>

               {!(processStep?.stepOrder === 1 && isAutostartActive) &&
                 (<Box pl={1} pt={0.5}>
                   <Typography className={classes.processStepCardTitle}>
                     {processStep?.stepOrder === 1
                      ? t('customProcesses.creationPage.processForm.initiator')
                      : processStep?.assigneeId?.length > 1
                        ? t('task_history.possible_assignees')
                        : t('customProcesses.creationPage.processForm.assignee')}:
                   </Typography>
                 </Box>)
               }

               <Box pl={2} pt={0.5} className={classes.processStepCardAssigneeInfoWrapper}>
                 {assigneeInfo()}
               </Box>
             </Box>
          }
        </Box>

        {hasErrors && !isFlowPreview && <div className={classes.processStepCardErrorMessage}>
          <img src={WarningIcon} alt="warning"/>
          <span>{t('customProcesses.creationPage.processFormErrors.requiredFieldsNotFilled')}</span>
        </div>}
      </Box>

      <ProcessStepAddMenu
        open={Boolean(addMenuAnchorEl) && isMenuButtonShown}
        anchorElement={addMenuAnchorEl}
        setAnchorElement={setAddMenuAnchorEl}
        handleAddCondition={handleAddCondition}
        handleAddParallelStepsGroup={handleAddParallelStepsGroup}
        handleAddStep={handleAddCard}
        hide
        isAddConditionButtonAvailable={isAddConditionButtonAvailable}
        isAddParallelBranchesButtonAvailable={isAddParallelBranchesButtonAvailable}
      />

      <Menu
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        open={Boolean(anchorEl)}
        MenuListProps={{
          style: {
            display: 'flex',
            flexDirection: 'column',
          },
        }}
        PaperProps={{
          style: {
            border: '1px solid rgba(38, 40, 66, 0.08)',
            boxShadow: '0px 8px 16px rgba(38, 40, 66, 0.04)',
            borderRadius: 10,
            marginTop: 4,
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => {
          setIsOpenConfirmDeleteModal(true);
          setDataForDelete({
            processStep,
            isPerformerStep,
          });
          setAnchorEl(null);
        }}>
          <Box>
            {t('customProcesses.creationPage.buttons.deleteStep')}
          </Box>
        </MenuItem>
      </Menu>

      {displayedAssigneeList.length > 0 && <AssigneeListModal
        title={processStep?.parallel
               ? t('customProcesses.creationPage.processForm.parallelAssigneeTitle')
               : t('task_history.possible_assignees')}
        users={displayedAssigneeList}
        onClose={() => setDisplayedAssigneeList([])}
      />}
    </div>
  );
};
