import React, { useMemo } from 'react';

import useBpmUrgentRequest from './useBpmUrgentRequest';

import BpmUrgentRequestFormation from "./BpmUrgentRequestFormation";
import BpmUrgentRequestCheck from "./BpmUrgentRequestCheck";
import BpmUrgentRequestReadOnly from "./BpmUrgentRequestReadOnly";

type bpmUrgentRequestHookProps = {
  isUrgentProcess: boolean;
  isUrgentMark: boolean;
  isUrgentReadOnly: boolean;
  isUrgentEmpty: boolean;
  isUrgentRequest: boolean;
  isUrgentFirstStep: boolean;
  urgentReason: string;
  urgentDeclineReason: string;
  handleUrgentReason: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleUrgentDecline: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setUrgentRequestValue: (value: boolean) => void
};

export const UrgentRequest = ({readOnly = false}: {readOnly?: boolean}) => {
  const {
    isUrgentProcess,
    isUrgentMark,
    isUrgentReadOnly,
    isUrgentEmpty,
    isUrgentRequest,
    isUrgentFirstStep,
    urgentReason,
    urgentDeclineReason,
    handleUrgentReason,
    handleUrgentDecline,
    setUrgentRequestValue,
  }: bpmUrgentRequestHookProps = useBpmUrgentRequest();

  const isUrgentInvisible = useMemo(
    () =>
      !isUrgentProcess ||
      (isUrgentProcess && !isUrgentMark && !isUrgentRequest),
    [isUrgentProcess, isUrgentMark, isUrgentRequest]
  );

  if (isUrgentInvisible) {
    return null;
  }

  if (isUrgentMark && (isUrgentEmpty || isUrgentFirstStep)) {
    return (
      <BpmUrgentRequestFormation
        isUrgentReadOnly={readOnly || isUrgentReadOnly}
        isUrgentRequest={isUrgentRequest}
        urgentReason={urgentReason}
        handleUrgentReason={handleUrgentReason}
        setUrgentRequestValue={setUrgentRequestValue}
      />
    );
  }

  if (isUrgentMark) {
    return (
      <BpmUrgentRequestCheck
        isUrgentReadOnly={isUrgentReadOnly}
        isUrgentRequest={isUrgentRequest}
        urgentReason={urgentReason}
        urgentDeclineReason={urgentDeclineReason}
        handleUrgentDecline={handleUrgentDecline}
        setUrgentRequestValue={setUrgentRequestValue}
      />
    );
  }

  return <BpmUrgentRequestReadOnly urgentReason={urgentReason} />;
};
