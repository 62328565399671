import React, { ReactElement } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, IconButton, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Trans, useTranslation } from 'react-i18next';

import useDeleteConditionConfirmationDialogStyles from './DeleteConditionConfirmationDialog.useStyles';

interface ChangesRollbackConfirmationDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const DeleteConditionConfirmationDialog = ({
  isOpen,
  onClose,
  onConfirm,
}: ChangesRollbackConfirmationDialogProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useDeleteConditionConfirmationDialogStyles();

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Modal open={isOpen}>
      <Box className={classes.modalBody}>
        <div className={classes.modalHeader}>
          <Typography className={classes.title}>
            {t('customProcesses.creationPage.processForm.conditionDeleteDialogTitle')}
          </Typography>

          <IconButton onClick={onClose} size="large">
            <CloseIcon className={classes.closeIcon}/>
          </IconButton>
        </div>

        <div className={classes.modalContent}>
          <Typography className={classes.description}>
            {t('customProcesses.creationPage.processForm.conditionDeleteDialogMessage')}
          </Typography>
        </div>

        <div className={classes.modalFooter}>
          <Button variant="contained" color="secondary" className={classes.modalButton} onClick={onClose}>
            {t('template_delete_modal.button_cancel')}
          </Button>
          <Button variant="contained" color="primary" className={classes.modalButton} onClick={handleConfirm}>
            {t('customProcesses.table.actions.delete')}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
