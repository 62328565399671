import { Box, Slide } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';

import { ErrorMessage, Spinner } from 'components';
import { setIsSignRequired, useTaskState } from 'store/requests';
import useStyles from './useStyles';
import { useTaskDetails } from './hooks';

import { TaskDetailsContent } from './components/TaskDetailsContent';
import { getHistoryTask } from 'api/requests';
import { AlertModal } from 'components/Modal/AlertModal';
import Portal from 'utils/portal';
import { useTranslation } from 'react-i18next';
import { useUserProfile } from 'hooks';
import { useDispatch } from 'react-redux';

const CanceledStatusValue = "Canceled"

export const TaskDetails = () => {
  const dispatch = useDispatch();
  const { data: bpmTask, error, loading } = useTaskState();
  const { close, isFullScreen } = useTaskDetails();
  const classes = useStyles();
  const [isTaskLoading, setTaskLoading] = useState(false);
  const [isRequestCancelled, setIsRequestCancelled] = useState(false);
  const { id: userId } = useUserProfile();
  
  const isCurrentUserWatcher = useMemo(() => {
    if (bpmTask) {
      return bpmTask.businessTask.observer.find((id) => id === userId);
    }
    return undefined;
  }, [bpmTask, userId]);

  const {t} = useTranslation();

  const [isOpened, setIsOpened] = useState(true);

  useEffect(() => {
    if(!bpmTask) {
      dispatch(setIsSignRequired(false))
    }
  }, [bpmTask]);

  const handleClose = () => {
    setIsOpened(false);
  }

  const TIME_FOR_REFRESH_STATUS = 1000 * 15

  const checkForCancel = (taskInstance: any):boolean => {
    if (!taskInstance?.enumAttributes) return

    for (const attribute of taskInstance.enumAttributes) {
      if (attribute.name === 'approveStage' && attribute.value?.includes(CanceledStatusValue)) {
        return true
      }
    }
  }

  const getCancelledStatus = async () => {
    setTaskLoading(true)
    try {
      const data = await getHistoryTask(bpmTask.taskId)
      if (checkForCancel(data.taskInstance)) {
        setIsRequestCancelled(true)
      }
    } catch (error) {
      //
    } finally {
      // every TIME_FOR_REFRESH_STATUS this function will be called
      new Promise<void>(() => {
        setTimeout(() => {
          setTaskLoading(false)
        }, TIME_FOR_REFRESH_STATUS)
      })
    }
  }

  useEffect(() => {
    if (isRequestCancelled || !bpmTask?.taskId || loading || bpmTask?.initiator === userId) return

    if (checkForCancel(bpmTask.instance)) {
      setIsRequestCancelled(true)
      return
    }

    if (isTaskLoading) return

    getCancelledStatus()

  }, [bpmTask, isRequestCancelled, isTaskLoading, userId])

  useEffect(() => {
    if(loading){
      // clear
      setIsRequestCancelled(false)
    }
  }, [loading])

  return (
    <Slide
      in={isOpened}
      onExited={close} // set render condition to false
      direction='left'
      timeout={300}
    >
      <Box
        className={cn(
          classes.root,
          {
            [classes.fullScreen]: isFullScreen
          }
        )
        }
      >
        {isRequestCancelled && !isCurrentUserWatcher &&
        <Portal id="request_was_canceled_portal">
          <AlertModal
            title={t("errors.approval_not_relevant")}
            description={t("errors.request_was_cancelled")}
            close={handleClose}
          />
        </Portal>}

        {loading && <Spinner absolute />}
        {Boolean(error) && <ErrorMessage text={t('errors.somethingIsWrong')} />}
        {
          // pass animation end function
          bpmTask && !loading && <TaskDetailsContent handleClose={handleClose} />
        }
      </Box>
    </Slide>
  );
};
