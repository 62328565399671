import React from 'react';
import { ButtonBase, Tooltip, Typography } from '@mui/material';
import { Check } from '@mui/icons-material';

import { ReactComponent as WarningIcon } from 'assets/images/icons/warning_icon.svg';
import { Docflow } from 'types';
import { numberWithSpaces } from 'utils/general';

import { useStyles } from './DocflowDocumentInfo.style';
// import { OverflowTooltip } from 'components/OverflowTooltip/overflow-tooltip';
import {useTranslation} from "react-i18next";
import {limitStringLength} from "../../utils/user";

interface DocflowDocumentInfoProps {
  document: Docflow.ProcessDocument;
  sum?: number;
  currency?: string;
  noBorder?: boolean;
  onOpen?: () => void;
}

export const DocflowDocumentInfo: React.FC<DocflowDocumentInfoProps> = ({ document, sum, currency, noBorder, onOpen }) => {
  const styles = useStyles({ noBorder });
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      {document.counterParties?.length ? (
        <div className={styles.value_wrapper}>
          <div className={styles.header}>
            <Typography className={styles.label}>{t('fields.filterDocument.Counterparty')}</Typography>
            <Typography className={styles.document_name}>{document.title}</Typography>
          </div>
          {document.counterParties.map((counterparty) => (
            <div style={{ marginBottom: '8px' }}>
              <Tooltip title={counterparty.name}>
                <Typography className={styles.value}>{limitStringLength(counterparty.name, 20)}</Typography>
              </Tooltip>
              <Tooltip title={counterparty.companyCode}>
                <Typography className={styles.info}>BIN {limitStringLength(counterparty.companyCode, 20)}</Typography>
              </Tooltip>
            </div>
          ))}
        </div>
      ) : null}
      {document?.payments?.contractValue ? (
        <div className={styles.value_wrapper}>
          <div className={styles.header}>
            <Typography className={styles.label}>{t('fields.filterDocument.ContractValue')}</Typography>
            <Tooltip title={document.title} arrow>
              <Typography className={styles.document_name}>{document.title}</Typography>
            </Tooltip>
          </div>
          <div>
            <Typography className={styles.value}>
              {numberWithSpaces(document?.payments?.contractValue)} {document?.payments?.currency}
            </Typography>
            {document?.payments.isVat ? <Typography className={styles.info}>{t('fields.filterDocument.IncludingVat')}</Typography> : null}
          </div>
        </div>
      ) : null}
      {document?.payments?.paymentBalance ? (
        <div className={styles.value_wrapper}>
          <div className={styles.header}>
            <Typography className={styles.label}>{t('fields.filterDocument.PaymentBalance')}</Typography>
            <Tooltip title={document.title} arrow>
              <Typography className={styles.document_name}>{document.title}</Typography>
            </Tooltip>
            {onOpen ? (
              <ButtonBase className={styles.payment_button} onClick={onOpen}>
                <Typography className={styles.payment_button_text}>{t('fields.filterDocument.PaymentPlan')}</Typography>
              </ButtonBase>
            ) : null}
          </div>

          <Typography className={styles.value}>
            {numberWithSpaces(document?.payments?.paymentBalance)} {document?.payments?.currency}
          </Typography>
        </div>
      ) : null}
      {sum && (
        currency !== document?.payments?.currency ? (
          <div className={styles.sumValidation} style={{ backgroundColor: '#FFE8C2' }}>
            <div className={styles.iconWrapper}>
              <WarningIcon />
            </div>
            <div>
              <Typography className={styles.sumValidationTitle}>
                {t('DocumentProcessFromDocflow.CurrencyMismatch')}
              </Typography>
              <Typography className={styles.sumValidationSubTitle}>
                {t('DocumentProcessFromDocflow.CurrencyMismatchNoUpdate')}
              </Typography>
            </div>
          </div>
        ) : document?.payments?.paymentBalance && sum > document?.payments?.paymentBalance ? (
          <div className={styles.sumValidation} style={{ backgroundColor: '#FFE8C2' }}>
            <div className={styles.iconWrapper}>
              <WarningIcon />
            </div>
            <div>
              <Typography className={styles.sumValidationTitle}>{t('DocumentProcessFromDocflow.ExceedsPaymentBalance')}</Typography>
              <Typography className={styles.sumValidationSubTitle}>{t('DocumentProcessFromDocflow.RequestAmountExceedsLimit')}</Typography>
            </div>
          </div>
        ) : document?.payments?.paymentBalance && sum <= document?.payments?.paymentBalance ? (
          <div className={styles.sumValidation} style={{ backgroundColor: '#DBFCEE' }}>
            <Check style={{ fontSize: 24, color: '#2DB77B' }} />
            <div>
              <Typography className={styles.sumValidationTitle}>{t('DocumentProcessFromDocflow.WithinPaymentBalance')}</Typography>
              <Typography className={styles.sumValidationSubTitle}>
                {t('DocumentProcessFromDocflow.RequestAmountWithinLimit')}
              </Typography>
            </div>
          </div>
        ) : null
      )}
    </div>
  );
};
