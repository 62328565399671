import { useMemo } from 'react';
import { useFormContext } from "react-hook-form";

import { useTaskState } from 'store/requests';

const WAGES_PROBATIONARY_PERIOD_NAME = 'wagesProbationaryPeriod';
const POST_TRIAL_WAGES_NAME = 'post-trialWages';
const ADAPTATION_SYS_NAME = 'onboarding_adaptation';

export default function useBpmSwitchOnBoarding() {
    const { getValues } = useFormContext();
    const { data: bpmTask } = useTaskState();

    const isAdaptation = useMemo((): boolean =>
        bpmTask && bpmTask.businessEntity.sysName === ADAPTATION_SYS_NAME,
        [bpmTask]);

    const isAdaptationSalaryEquals = useMemo((): boolean => {
        if (!isAdaptation)
            return false;

        const wagesProbationaryPeriod = getValues(WAGES_PROBATIONARY_PERIOD_NAME);
        const postTrialWages = getValues(POST_TRIAL_WAGES_NAME);

        if (!wagesProbationaryPeriod || !postTrialWages) {
            return false;
        }

        return wagesProbationaryPeriod === postTrialWages;
    }, [isAdaptation, getValues]);

    return {
        isAdaptationSalaryEquals
    }
}
