import { TreeStructureNode } from './TreeGlossaryMultiSelect';

export const initializeSelectedStates = (directoriesStructure: TreeStructureNode[], selectedNodes: string[], useUniqueIds = false) => {
  const tempNodes = {};
  const parseNode = (node) => {
    let selectedCount = 0;
    let totalCount = 0;
    if (node.children.length > 0) {
      node.children.forEach(childNode => {
        const { s, t } = parseNode(childNode);
        selectedCount += s;
        totalCount += t;
      });
    } else {
      totalCount = 1;
      if (selectedNodes.includes(node.data.id)) {
        selectedCount = 1;
      }
    }
    tempNodes[useUniqueIds ? node.id : node.data.id] = {
      ...node,
      totalChildCount: totalCount,
      selectedChildCount: selectedCount,
    };

    return { s: selectedCount, t: totalCount };
  };
  directoriesStructure?.forEach(node => {
    parseNode(node);
  });
  return tempNodes;
};
