import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ spacing, palette }) => ({
  popover: {
    position: 'absolute',
    top: '50vh',
    left: '50vw',
  },
  commentInput: {
    marginTop: spacing(2),

    '& label': {
      fontWeight: 600,
      color: palette.grey[600],
      position: 'relative',
      display: 'block',
      width: 'fit-content',
    },

    '& div': {
      marginTop: 0,
    },
  },
  commentInputRequired: {
    '& label': {
      '&::after': {
        display: 'inline-block',
        content: '"*"',
        color: palette.error[600],
        fontSize: 14,
        position: 'relative',
        right: -2,
        top: -2,
      },
    },
  },
  content: {
    padding: spacing(6),
    paddingBottom: spacing(7)
  },
  message: {
    fontSize: 14,
    color: palette.grey[700]
  },
  usersWrapper: {
    marginTop: spacing(5)
  },
  userItem: {
    marginBottom: spacing(4),
    '&:last-child': {
      marginBottom: 0
    }
  },
  dialogButton: {
    height: 40,
  },
  redButton: {
    backgroundColor: palette.error[400],
    borderColor: palette.error[400],
  },
}));

export default useStyles;
