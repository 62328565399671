export enum AttributeType {
  BOOLEAN = 'BOOLEAN',
  STRING = 'STRING',
  INTEGER = 'INTEGER',
  DOUBLE = 'DOUBLE',
  DATETIME = 'DATETIME',
  FILE = 'FILE',
  LINK = 'LINK',
  ENUM = 'ENUM',
  LINKLIST = 'LINKLIST'
}

export interface ProgressBarStepType {
  assigneeId: string[];
  candidateUsers: boolean;
  parallelAssignee: boolean;
  simpleAssignee: boolean;
  stepName: string;
  stepperOrder: number;
  actionSysName?: string;
}

interface ProgressBarType {
  currentParallelSteps: string | null;
  currentParallelStepsStepperOrder: number | null;
  stepsDetails: ProgressBarStepType[];
  hasCandidateUsers: boolean;
  hasCondition: boolean;
  hasParallelAssignees: boolean;
  completedStepOrder: number;
  stepsCount: number;
}

export interface MassActionValidationType {
  hasRequiredFields: boolean,
  needSignature: boolean,
  hasDocumentSign: boolean,
  noAssignee: boolean,
  needAssignPerformer: boolean,
  stepWithoutApproveAction: boolean,
  oldRequest: boolean,
}

export interface TaskValuesType {
  summary: string;
  summary_new: string;
  fullName: string;
  project: string;
  position: string;
  foundersAndShares: string;
  budgetDetail: string;
  currency: string;
  typeOfExpense: string;
  typeOfOrders: string;
  amountInKzt: string;
  name: string;
  company: string;
  supplierName: string;
  supplier: string;
  amount: string;
  paymentType: string;
  country: string;
  generalDirector: string;
  description: string;
  employee: string;
  serviceContract: boolean;
  legalEntities: string;
  attachments: string;
  SupplierEmail: string;
  existingSupplier: string;
  supplierBin: string;
  bin: string;
  probation: string;
  email?: string;
  individualNumber?: string;
}

export interface TaskEntityType {
  urgent?: {
    urgent: boolean;
    reason: string;
  };
  businessActionDTOList: any[];
  businessStateDTOList: any[];
  currentState: any;
  approvedTaskId: string;
  businessTask: {
    actionSysName: string;
    applicationNumber: string;
    assignee: string;
    candidateUsers: string[] | null;
    completed: boolean;
    dueDateTemplate?: null | string;
    errorStatus: string;
    initiator: string;
    processDefinitionName: string;
    processInstanceId: string;
    processStartDate: string;
    progressBar: ProgressBarType
    rootProcessInstanceId: string;
    sysName: string;
    taskCompleted: boolean;
    taskEndDate: string;
    taskId: string;
    taskStartDate: string;
    taskName: string;
    taskStatus: string;
    observer: string[];
  };
  taskInstance: {
    [key: string]: {
      sysName: string;
      name: string;
      value: string | string[]
    }[]
  };
}

interface CommonTaskType {
  id: string | number;
  name: string;
  sysName: string;
}

export interface TaskActionType extends CommonTaskType {
  active: boolean;
  massApprove: boolean;
  assigneeVariableName: string | null;
  urgentMark: boolean;
  stepperOrder: number;
  stepperLabel: string;
}

export interface BusinessEntityType extends CommonTaskType {
  attributes: Array<{
    sysName: string;
    name: string;
    defaultValue: string;
    id: number;
    stepDescription: string;
    stepSysName?: string;
    type?: string;
    stepNumber: number;
    tabNumber?: number;
    tabDescription?: string;
    tabSysName?: string;
  }>;
  iconPath: string;
  processDefinitionId: string;
  template?: boolean;
}

export interface TaskParametersType extends CommonTaskType {
  attributeId?: number;
  behaviour: string;
  bsnRules: any[];
  component: string;
  componentParams: string;
  cssClass: string;
  defaultValue: string | number;
  groupName: string;
  hint: string;
  id: number;
  name: string;
  state_order: number;
  stepDescription: string;
  stepNumber: number;
  stepSysName: string;
  sysName: string;
  tabDescription: string;
  tabNumber: string;
  tabSysName: string;
  type: AttributeType;
  typeAttributeId: number;
  isTaskDetailsVariant?: boolean;
  value: string | number | string[] | number[];
}

export interface TaskStateType extends CommonTaskType {
  active: boolean;
  hint: string;
  bsnParameters: TaskParametersType[];
}

export enum TaskStatusesEnum {
  Completed = 'Completed',
  Rework = 'Rework',
  Reject = 'Reject',
  InProgress = 'InProgress',
  Canceled = 'Canceled',
  Draft = 'Draft'
}

export interface TaskRequestType {
  businessTask: {
    taskId: string;
    companyId: string;
    processInstanceId: string;
    applicationNumber: string;
    initiator: string;
    assignee: string;
    assigneesList: string[];
    candidateUsers: string[];
    processDefinitionName: string;
    processSysName?: string;
    taskCompleted: boolean;
    taskEndDate?: string;
    errorStatus: boolean;
    dueDate: null | string;
    dueDateTemplate: null | string;
    badges?: {
      initiator?: {
        taskRead: boolean;
        commentRead: boolean;
        attachmentRead: boolean;
      },
      assignee?: {
        taskRead: boolean;
        commentRead: boolean;
        attachmentRead: boolean;
      },
      observer?: {
        taskRead: string;
        commentRead: string;
        attachmentRead: string;
      }[],
      candidateUsers?: {
        taskRead: string;
        commentRead: string;
        attachmentRead: string;
      }[]
    }
    taskStatus: TaskStatusesEnum;
    progressBar: ProgressBarType;
  }
  defaultDescription: string;
  commentsSize: number,
  attachmentCount: number,
  currentAction: {
    name: string,
    sysName?: string,
    stepperOrder: number,
    massApprove: boolean,
    assigneeVariableName?: string | null;
  },
  processIconPath: string,
  summary: string,
  summaryFields: {
    name: string;
    sysName: string;
    fieldSysName: string;
    type: string;
    fieldOrder: number;
    value: string | number;
    localization?: { [key: string]: string };
    valuesCount?: number;
  }[],
  massActivationValidation?: MassActionValidationType,
  descriptionLocalization?: { [key: string]: string },
  totalCountOfSteps: number,
  approvedTaskId?: string;
  urgent: {
    urgent: boolean,
    reason: string
  },
  hasDocumentSign?: boolean;
}

export interface ProcessDocumentData {
  attachmentId: string;
  dataCreate: string;
  dataLastEdit?: string;
  documentHiddenSteps: number[];
  firstAppearanceStep: number;
  id: number;
  docflowDocumentId: number;
  name: string;
  processInstanceId: string;
  signRequiredSteps: number[];
  size?: number;
  status: string;
  stepSysName?: string;
  isPublished?: boolean;
  isSigned?: boolean;
  signings: {
    stepOrder: number,
    status: boolean,
    signDate: null | string
  }[];
}
