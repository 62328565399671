import { useEffect, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import {
  ConditionStep,
  Language,
  ParallelBranchesGroup,
  ProcessStep,
  StepName,
} from '../../../TemplateProcesses.types';

export const useProcessConditions = ({
  fields,
  conditions,
  parallelBranchesGroups,
  performerSelectionSteps,
  allProcessStepsFields,
  languages,
  replace,
  setConditions,
  setParallelBranchesGroups,
  setCurrentProcessIndex,
  setSelectedItemType,
  setFocusedStepFieldId,
  nameFieldRef,
  setSummaryFields,
  setSelectedStepField,
  setIsSubmitAvailable,
  setDirectManagerSteps,
                                       setRequestInitiatorSteps,
  setPerformerSelectionSteps,
  handleStepDelete,
  currentTypeSummaryFields
}) => {
  const handleConditionValuesChange = (condition: ConditionStep) => {
    setIsSubmitAvailable(true);
    const conditionIndex = conditions.findIndex(item => item.id === condition.id);
    const modifiedConditionsList = [...conditions];
    modifiedConditionsList[conditionIndex] = condition;
    setConditions(() => modifiedConditionsList);
  };

  const handleConditionDelete = (condition: ConditionStep) => {
    const processSteps = fields as unknown as ProcessStep[];
    setIsSubmitAvailable(true);
    // remove condition from conditions list
    // and fix step orders for conditions after deleted one
    const deletedStepsCount = (condition.hasPositiveBranch ? condition.positiveBranch.length : 0)
      + (condition.hasNegativeBranch ? condition.negativeBranch.length : 0);
    const conditionSteps = [
      ...(
        condition.hasPositiveBranch
          ? condition.positiveBranch
          : []),
      ...(
        condition.hasNegativeBranch
          ? condition.negativeBranch
          : []
      )];
    const modifiedConditionsList = conditions.filter(item => item.id !== condition.id)
      .map(c =>
        c.previousStepOrder >= Math.max(...conditionSteps)
          ? {
            ...c,
            previousStepOrder: c.previousStepOrder - deletedStepsCount,
            positiveBranch: c.positiveBranch.map(v => v - deletedStepsCount),
            negativeBranch: c.negativeBranch.map(v => v - deletedStepsCount),
          }
          : c);
    // focus on deleted condition previous step
    setCurrentProcessIndex(() => condition.previousStepOrder - 1);
    setSelectedItemType('processStep');

    const modifiedParallelStepsGroupsList = parallelBranchesGroups.map(g =>
      g.previousStepOrder >= Math.max(...conditionSteps)
        ? {
          ...g,
          previousStepOrder: g.previousStepOrder - deletedStepsCount,
          steps: g.steps.map(b => b.map(v => v - deletedStepsCount)),
        }
        : g);
    setTimeout(() => {
      setConditions(() => modifiedConditionsList);
      setParallelBranchesGroups(() => modifiedParallelStepsGroupsList);
    }, 0);
    // remove condition branch steps
    const fieldsAddedOnRemovedSteps = allProcessStepsFields
      .filter(item => conditionSteps.includes(item?.firstAppearanceStep))
      .map(item => item?.field?.id);
    const minStep = Math.min(...conditionSteps);

    if (fieldsAddedOnRemovedSteps.length > 0) {
      const modifiedProcessFields = processSteps
        .map((processField, index) => {
          if (index < minStep) {
            return processField;
          }

          return {
            ...processField,
            fields: processField.fields
              .filter(field => !fieldsAddedOnRemovedSteps.includes(field.id))
              .map((field, index) => ({ ...field, order: index + 1 })),
            summaryFields: processField.summaryFields.filter(id => !fieldsAddedOnRemovedSteps.includes(id))
          };
        })
        .filter(({ stepOrder }) => !conditionSteps.includes(stepOrder))
        .map((step, index) => ({
          ...step,
          stepOrder: index + 1,
        }));
      replace(modifiedProcessFields);

      const modifiedConditions = conditions.map(c => {
        if (fieldsAddedOnRemovedSteps?.includes(c?.condition?.field)) {
          c.condition.field = '';
          c.condition.type = '';
          c.condition.value = '';
        }
        return c;
      });
      setConditions(() => modifiedConditions);

      setSelectedStepField('');
    } else {
      replace(
        processSteps
          .filter(({ stepOrder }) => !conditionSteps.includes(stepOrder))
          .map((step, index) => ({
            ...step,
            stepOrder: index + 1,
          })),
      );
    }

    setDirectManagerSteps(steps => steps
      .filter(step => !conditionSteps.includes(step))
      .map(step => step < minStep ? step : step - deletedStepsCount),
    );
    setPerformerSelectionSteps(steps => steps
      .filter(step => !conditionSteps.includes(step))
      .map(step => step < minStep ? step : step - deletedStepsCount),
    );
    setRequestInitiatorSteps(steps => steps
      .filter(step => !conditionSteps.includes(step))
      .map(step => step < minStep ? step : step - deletedStepsCount),
    );
  };

  const handleConditionBranchStepAdd = (condition: ConditionStep, branchType: 'positive' | 'negative', stepPosition: number) => {
    let addedStepOrder =
      branchType === 'positive'
        ? stepPosition < condition.positiveBranch.length
          ? condition.positiveBranch[stepPosition]
          : condition.positiveBranch[stepPosition - 1] + 1
        : stepPosition < condition.negativeBranch.length
          ? condition.negativeBranch[stepPosition]
          : condition.negativeBranch[stepPosition - 1] + 1;

    if ((!condition.hasPositiveBranch && branchType === 'positive')) {
      addedStepOrder = condition.previousStepOrder + 1;
    }

    if ((!condition.hasNegativeBranch && branchType === 'negative')) {
      addedStepOrder = condition.positiveBranch[condition.positiveBranch.length - 1] + 1;
    }

    const conditionListComparisonStep =
      !condition.hasNegativeBranch || branchType === 'negative'
        ? addedStepOrder - 1
        : addedStepOrder;

    if (branchType === 'positive') {
      condition.positiveBranch = condition.positiveBranch.map(v => v >= addedStepOrder ? v + 1 : v);
      condition.negativeBranch = condition.negativeBranch.map(v => v + 1);
      condition.positiveBranch = [
        ...condition.positiveBranch.slice(0, stepPosition),
        addedStepOrder,
        ...condition.positiveBranch.slice(stepPosition),
      ];
      condition.hasPositiveBranch = true;
    } else {
      condition.negativeBranch = condition.negativeBranch.map(v => v >= addedStepOrder ? v + 1 : v);
      condition.negativeBranch = [
        ...condition.negativeBranch.slice(0, stepPosition),
        addedStepOrder,
        ...condition.negativeBranch.slice(stepPosition),
      ];
      condition.hasNegativeBranch = true;
    }

    const modifiedParallelBranchesGroups = [...parallelBranchesGroups]
      .map(g =>
        g.previousStepOrder >= addedStepOrder - 1
          ? {
            ...g,
            previousStepOrder: g.previousStepOrder + 1,
            steps: g.steps.map(b => b.map(v => v + 1)),
          }
          : g);
    setParallelBranchesGroups(() => modifiedParallelBranchesGroups);

    const fieldsToAdd = allProcessStepsFields.filter(field => {
      if (field.firstAppearanceStep > addedStepOrder) {
        return false;
      }

      if ([...(condition?.hasPositiveBranch ? condition.positiveBranch : []), ...(condition?.hasNegativeBranch
        ? condition.negativeBranch
        : [])].includes(field?.firstAppearanceStep)) {
        if (
          (branchType === 'negative' && [...(condition?.hasPositiveBranch
            ? condition.positiveBranch
            : [])].includes(field?.firstAppearanceStep))
          || (branchType === 'positive' && [...(condition?.hasNegativeBranch
            ? condition.negativeBranch
            : [])].includes(field?.firstAppearanceStep))
        ) {
          return false;
        }
      }
      return true;
    });

    const stepsBefore = fields.slice(0, addedStepOrder - 1);
    const stepsAfter = fields.slice(addedStepOrder - 1).map((step, index) => ({
      ...step,
      stepOrder: addedStepOrder + 1 + index,
    }));
    let stepToCopySummaryFields: ProcessStep | undefined;
    for(let i = stepsBefore.length - 1;i >= 0;i--) {
      if(!currentTypeSummaryFields.includes(stepsBefore[i].id)) {
        stepToCopySummaryFields = fields[i];
        break;
      }
    }
    if(!stepToCopySummaryFields) {
      for(let i = addedStepOrder - 1;i < fields.length;i++) {
        if(!currentTypeSummaryFields.includes(fields[i].id)) {
          stepToCopySummaryFields = fields[i];
          break;
        }
      }
    }

    const previousStepsFields = fields
      .slice(0, addedStepOrder - 1)
      .reduce((acc, step) => {
        step.fields.forEach(field => {
          if (!acc.some(accField => accField.id === field.id)) {
            acc.push(field);
          }
        });
        return acc;
      }, [])
      .map((item, index) => ({
        ...item,
        order: index + 1,
        isRequired: false,
        isEditable: false,
      }));

    replace(
      [
        ...stepsBefore,
        {
          id: uuid(),
          stepOrder: addedStepOrder,
          stepName: languages.reduce((acc, lang) => ({ ...acc, [lang]: '' }), {}),
          stepDescription: languages.reduce((acc, lang) => ({ ...acc, [lang]: '' }), {}),
          assigneeId: [],
          fields: previousStepsFields,
          timer: '',
          hasTimer: false,
          parallel: false,
          isConditionBranchStep: true,
          isParallelBranchesGroupStep: false,
          summaryFields: stepToCopySummaryFields ? stepToCopySummaryFields.summaryFields : []
        },
        ...stepsAfter
      ],
    );

    const modifiedConditionsList = conditions.map(c =>
      c.previousStepOrder >= conditionListComparisonStep
        ? {
          ...c,
          previousStepOrder: c.previousStepOrder + 1,
          positiveBranch: c.positiveBranch.map(v => v + 1),
          negativeBranch: c.negativeBranch.map(v => v + 1),
        }
        : c);
    setConditions(() => modifiedConditionsList);

    setDirectManagerSteps(steps => steps.map(step => step < addedStepOrder ? step : step + 1));
    setPerformerSelectionSteps(steps => steps.map(step => step < addedStepOrder ? step : step + 1));
    setRequestInitiatorSteps(steps => steps.map(step => step < addedStepOrder ? step : step + 1));

    setTimeout(() => {
      setCurrentProcessIndex(addedStepOrder - 1);
      setSelectedItemType('processStep');
      setFocusedStepFieldId('');
      nameFieldRef?.current?.focus();
    });
  };


  return {
    handleConditionValuesChange,
    handleConditionDelete,
    handleConditionBranchStepAdd,
  };
};
