import React from 'react';
import { LocalizationProvider, DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { FormHelperText } from '@mui/material';
import { environment } from '../../../environments/environment';
import 'dayjs/locale/en'; // US locale (week starts on Sunday)
import 'dayjs/locale/de'; // Example non-US locale (week starts on Monday)

const LOCALES = {
  en: environment.region === 'US'? 'en' : 'en-GB',
  kz: 'kk',
  ru: 'ru',
  id: 'id',
};

type Props = {
  name?: string;
  [key: string]: any;
  value?: Date | string;
  onChange?: (arg: Date) => void;
  checkDateDisabled?: (arg: Date) => boolean;
  disabled?: boolean;
  disableKeyboardInput?: boolean;
  loading?: boolean;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
};

export const DatePicker = ({
  name,
  value,
  onChange,
  disabled,
  disableKeyboardInput = false,
  loading,
  checkDateDisabled = (arg: Date) => false,
  placeholder = null,
  error,
  helperText,
  ...props
}: Props) => {
  const { i18n } = useTranslation();

  moment.locale(LOCALES[i18n.language] || i18n.language);
  const isUSRegion = environment.region === 'US';

  const formatPlaceholder =
    isUSRegion && (placeholder === 'dd.mm.yyyy' || placeholder === 'DD.MM.YYYY')
      ? 'MM/DD/YYYY'
      : placeholder;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={LOCALES[i18n.language] || i18n.language}>
      <MuiDatePicker
        {...props}
        format={isUSRegion ? 'MM/DD/YYYY' : 'DD/MM/YYYY'}
        value={value ? moment(value) : null}
        onChange={(value) => (value ? onChange(value.toDate()) : onChange(null))}
        shouldDisableDate={(value) => checkDateDisabled(value.toDate())}
        disabled={loading || disabled}
        slots={{
          textField: TextField,
        }}
        slotProps={{
          textField: {
            variant: 'outlined',
            color: 'primary',
            placeholder: formatPlaceholder,
          },
        }}
      />
      {error && helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </LocalizationProvider>
  );
};
