
export const primaryColor = '#EA7042';
export const bigStone = '#172737';
export const white = '#FFF';
export const darkGray = '#5F5F5F';
export const red = '#EF2E27';

const palette = {
  primary: {
    main: primaryColor,
    light: "#F1855C",
    white
  },
  secondary: {
    main: red
  },
  error: {
    main: red
  },
  text: {
    primary: darkGray,
    secondary: '#465564',
    gray: '#A7A7A7',
    lightGray: '#6F7984',
    dark: '#2C2C2C'
  },
  background: {
    default: '#FFF',
    bigStone,
    darkGray: '#2A3948',
    lightBlue: '#C7DAE5',
    darkBlue: '#0376da'
  },
  success: {
    main: '#1ac41a'
  }
};

export default palette;
