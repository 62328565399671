import { useSelector } from 'react-redux';
import type { RootState } from 'store';

export const useCustomModalState = () =>
  useSelector(
    ({ main: { customModalParams } }: RootState) => customModalParams
  );

export const useSideMenuOpen = () =>
  useSelector(
    ({ main: { sideMenuOpen } }: RootState) => sideMenuOpen
  );

export const useReadStatus = () =>
  useSelector(
    ({ main: { readStatus } }: RootState) => readStatus
  );

export const useHoldingTranslationsLoadedState = () =>
  useSelector(
    ({ main: { holdingTranslationsLoaded } }: RootState) => holdingTranslationsLoaded
  );
