import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  content: {
    marginTop: theme.spacing(2),
    '& > *': {
      margin: theme.spacing(2, 1)
    }
  },
  uploadButton: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  progressContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1)
  }
}));

export default useStyles;
