import React from 'react';
import { useTranslation } from 'react-i18next';
import {Box, Button, CircularProgress, Typography } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import { AppTextField } from "components";

import { EDS_FORM } from 'pages/Task/TaskForm/Eds/EdsConstants';

import useStyles from './useStyles';

type Props = {
  loading: boolean;
  buttonLabel: string;
  clientEdsFileName: string;
  isButtonDisabled: boolean;
  handleClientEdsFile: (event: any) => Promise<void>;
  handleClientEdsPassword: (val: string) => void;
  handleClientSignature: () => void;
};

export const EdsFormBody = ({
  loading,
  buttonLabel,
  clientEdsFileName,
  isButtonDisabled,
  handleClientEdsFile,
  handleClientEdsPassword,
  handleClientSignature,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.content}>
      <Button
        fullWidth
        className={classes.uploadButton}
        variant="outlined"
        component="label"
      >
        <Box display="flex" alignItems="center">
          <AttachFileIcon color="primary" />
          <Typography>{t(EDS_FORM.UPLOAD_BUTTON_LABEL)}</Typography>
        </Box>

        <input
          type="file"
          accept=".p12"
          onChange={handleClientEdsFile}
          hidden
        />
      </Button>

      {!!clientEdsFileName && <Typography>{clientEdsFileName}</Typography>}

      <AppTextField
        fullWidth
        type="password"
        label={t(EDS_FORM.PASSWORD_STORAGE_LABEL)}
        variant="outlined"
        onChange={(e) => handleClientEdsPassword(e.target.value)}
        disabled={loading}
      />

      <Button
        type="button"
        variant="contained"
        color="primary"
        disabled={isButtonDisabled}
        onClick={handleClientSignature}
      >
        <span>{t(buttonLabel)}</span>

        {loading && (
          <Box className={classes.progressContainer}>
            <CircularProgress size={24} />
          </Box>
        )}
      </Button>
    </Box>
  );
};
