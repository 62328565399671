export const takeBreadcrumbs = (t) => ({
  '/requests/active': [
    {
      text: t('breadcrumbs.my_work', {defaultValue: 'My work'}),
      to: '/processes/approvals/active',
    },
    {
      text: t('Processes.module', {defaultValue: 'Processes'}),
      to: '/processes/approvals/active',
    },
    {
      text: t('Processes.module_requests', {defaultValue: 'Requests'}),
      to: '/processes/requests/active',
    },
    {
      text: t('Processes.Requests_tab1', {defaultValue: 'Active'}),
      to: '/processes/requests/active',
    },
  ],
  '/requests/completed': [
    {
      text: t('breadcrumbs.my_work', {defaultValue: 'My work'}),
      to: '/processes/approvals/active',
    },
    {
      text: t('Processes.module', {defaultValue: 'Processes'}),
      to: '/processes/approvals/active',
    },
    {
      text: t('Processes.module_requests', {defaultValue: 'Requests'}),
      to: '/processes/requests/active',
    },
    {
      text: t('Processes.Requests_tab2', {defaultValue: 'Completed'}),
      to: '/processes/requests/completed',
    },
  ],
  '/requests/drafts': [
    {
      text: t('breadcrumbs.my_work', {defaultValue: 'My work'}),
      to: '/processes/approvals/active',
    },
    {
      text: t('Processes.module', {defaultValue: 'Processes'}),
      to: '/processes/approvals/active',
    },
    {
      text: t('Processes.module_requests', {defaultValue: 'Requests'}),
      to: '/processes/requests/active',
    },
    {
      text: t('Processes.Requests_tab3', {defaultValue: 'Drafts'}),
      to: '/processes/requests/drafts',
    },
  ],
  [`/approvals/active`]: [
    {
      text: t('breadcrumbs.my_work', {defaultValue: 'My work'}),
      to: '/processes/approvals/active',
    },
    {
      text: t('Processes.module', {defaultValue: 'Processes'}),
      to: '/processes/approvals/active',
    },
    {
      text: t('Processes.module_approvals', {defaultValue: 'Approvals'}),
      to: '/processes/approvals/active',
    },
    {
      text: t('Processes.Approvals_tab1', {defaultValue: 'Active'}),
      to: '/processes/approvals/active',
    },
  ],
  [`/approvals/reviewed`]: [
    {
      text: t('breadcrumbs.my_work', {defaultValue: 'My work'}),
      to: '/processes/approvals/active',
    },
    {
      text: t('Processes.module', {defaultValue: 'Processes'}),
      to: '/processes/approvals/active',
    },
    {
      text: t('Processes.module_approvals', {defaultValue: 'Approvals'}),
      to: '/processes/approvals/active',
    },
    {
      text: t('Processes.Approvals_tab2', {defaultValue: 'Reviewed'}),
      to: '/processes/approvals/active',
    },
  ],
  [`/template-processes`]: [
    {
      text: t('breadcrumbs.my_work', {defaultValue: 'My work'}),
      to: '/processes/approvals/active',
    },
    {
      text: t('Processes.module', {defaultValue: 'Processes'}),
      to: '/processes/approvals/active',
    },
    {
      text: t('customProcesses.title', {defaultValue: 'Custom processes'}),
      to: '/processes/template-processes',
    },
  ],
  [`/admin-panel`]: [
    {
      text: t('breadcrumbs.my_work', {defaultValue: 'My work'}),
      to: '/processes/approvals/active',
    },
    {
      text: t('Processes.module', {defaultValue: 'Processes'}),
      to: '/processes/approvals/active',
    },
    {
      text: t('AdminPanel.title', {defaultValue: 'Admin panel'}),
      to: '/processes/admin-panel',
    },
  ],
  [`/templates`]: [
    {
      text: t('breadcrumbs.my_work', {defaultValue: 'My work'}),
      to: '/processes/approvals/active',
    },
    {
      text: t('Processes.module', {defaultValue: 'Processes'}),
      to: '/processes/approvals/active',
    },
    {
      text: t('templates.title', {defaultValue: 'Templates'}),
      to: '/processes/templates',
    },
  ],
  [`/analytics`]: [
    {
      text: t('breadcrumbs.my_work', {defaultValue: 'My work'}),
      to: '/processes/approvals/active',
    },
    {
      text: t('Processes.module', {defaultValue: 'Processes'}),
      to: '/processes/approvals/active',
    },
    {
      text: t('Processes.module_analytics', {defaultValue: 'Analytics'}),
      to: '/processes/analytics',
    },
  ],
  [`/settings/roles`]: [
    {
      text: t('breadcrumbs.home', {defaultValue: 'Home'}),
      to: '/admin',
    },
    {
      text: t('breadcrumbs.permissions', {defaultValue: 'Permissions'}),
      to: '/processes/settings/roles',
    },
    {
      text: t('breadcrumbs.processes', {defaultValue: 'Processes'}),
      to: '/processes/settings/roles',
    },
    {
      text: t('settings.sidebar.menu.rolesAndPermissions', {defaultValue: 'Roles & permissions'}),
      to: '/processes/settings/roles',
    },
  ],
  [`/settings/groups`]: [
    {
      text: t('breadcrumbs.my_work', {defaultValue: 'My work'}),
      to: '/processes/approvals/active',
    },
    {
      text: t('Processes.module', {defaultValue: 'Processes'}),
      to: '/processes/approvals/active',
    },
    {
      text: t('breadcrumbs.process_settings', {defaultValue: 'Settings'}),
      to: '/processes/settings/roles',
    },
    {
      text: t('groupsSettings.title', {defaultValue: 'Groups'}),
      to: '/processes/settings/groups',
    },
  ],
  [`/upgrade`]: [
    {
      text: t('breadcrumbs.my_work', {defaultValue: 'My work'}),
      to: '/processes/approvals/active',
    },
    {
      text: t('Processes.module', {defaultValue: 'Processes'}),
      to: '/processes/approvals/active',
    },
  ],
});
