import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { useUsersState } from 'store/users';
import { useTaskState } from 'store/requests';

import { compareUsers } from 'utils/user';
import { UserType } from 'types';

export const useUserSelect = ({ name, params }) => {
  const { setValue: setFormValue, watch, trigger } = useFormContext();

  const { data: bpmTask } = useTaskState();
  const { users } = useUsersState();

  const [value, setValue] = useState(watch(name) || null);

  const options = useMemo(() =>
    Object.values(users)
      .sort(compareUsers)
      .map((user: UserType) => ({
        letter: user?.fullName[0]?.toUpperCase() || '',
        ...user
      })),
    [users]);

  const childrenComponent = useMemo(
    () => {
      return bpmTask
             && Object.prototype.hasOwnProperty.call(params, 'childrenComponentSysName')
             && params?.childrenComponentSysName
        ? bpmTask.getAttributeBySysName(params.childrenComponentSysName)
        : null
    },
    [params, bpmTask]
  );

  const childrenComponentParameter = useMemo(
    () =>
      Object.prototype.hasOwnProperty.call(params, 'childrenComponentParameter')
        ? params.childrenComponentParameter : '',
    [params]
  );

  const handleChange = async (_, value) => {
    setValue(value);

    if (Array.isArray(value) && value.length) {
      setFormValue(name, value);
      trigger(name);
      return;
    }

    if (value) {
      const { fullName, id } = value;
      setFormValue(name, id ? id : fullName);

      if (childrenComponent && childrenComponentParameter) {
        const childrenParameterValue = value[childrenComponentParameter];
        setFormValue(childrenComponent.name, childrenParameterValue);
      }

      trigger(name);
      return;
    }

    setFormValue(name, '');
    childrenComponent && setFormValue(childrenComponent.name, '');
  };

  return {
    options,
    value,
    setValue,
    setFormValue,
    watch,
    handleChange,
  };
}
