import React, { ReactElement } from 'react';
import { Box, Button, IconButton, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

import useBranchStepsLimitReachedDialogStyles from './BranchStepsLimitReachedDialog.useStyles';
import { BRANCH_STEPS_NUMBER_LIMIT } from '../../TemplateProcesses.constants';

interface StepsLimitReachedDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const BranchStepsLimitReachedDialog = ({
  isOpen,
  onClose,
}: StepsLimitReachedDialogProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useBranchStepsLimitReachedDialogStyles();

  return (
    <Modal open={isOpen}>
      <Box className={classes.modalBody}>
        <div className={classes.modalHeader}>
          <Typography className={classes.title}>
            {t('customProcesses.creationPage.processForm.branchStepsLimitReachedDialogTitle')}
          </Typography>

          <IconButton onClick={onClose} size="large">
            <CloseIcon className={classes.closeIcon}/>
          </IconButton>
        </div>

        <div className={classes.modalContent}>
          <Typography className={classes.description}>
            {t('customProcesses.creationPage.processForm.branchStepsLimitReachedDialogMessage', {value: BRANCH_STEPS_NUMBER_LIMIT})}
          </Typography>
        </div>

        <div className={classes.modalFooter}>
          <Button variant="contained" color="secondary" className={classes.modalButton} onClick={onClose}>
            {t('template_outdated_modal.button_close')}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
