import React, { memo, useMemo } from 'react';
import { Typography, Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = {
  hint: string;
  name: string;
  value: any;
}

export const ReadOnlyAccessType = memo(({
  hint,
  name,
  value,
}: Props) => {
  const { register } = useFormContext();
  const { t, i18n } = useTranslation();

  const localizedValue = useMemo(() => {
    const translations = i18n.getResourceBundle('en', '');
    if (!translations || !translations.glossary) {
      return value;
    }

    const glossariesKeys = Object.keys(translations.glossary)
      .reduce((acc, key) => {
        return {
          ...acc,
          [translations.glossary[key]]: key,
        };
      }, {});
    const glossaryValueKey = glossariesKeys[value];
    return t(`glossary.${glossaryValueKey}`, { defaultValue: value });
  }, [value]);

  if (!value) {
    return null;
  }

  return (
    <Grid container>
      <input type="hidden" data-selenium={name} {...register(name)} />

      <Grid item sm={6}>
        <Typography>{hint}</Typography>
      </Grid>

      <Grid item sm={6}>
        <Typography color="primary">{localizedValue}</Typography>
      </Grid>
    </Grid>
  );
}, (prevProps, nextProps) =>
  prevProps.name === nextProps.name &&
  prevProps.hint === nextProps.hint &&
  prevProps.value === nextProps.value);
