import { MASS_ACTIONS_VALIDATION_RESULTS } from './ticket.types';

export const COMMENTS_COUNTER_CLASS = 'commentsCounter';
export const ATTACHMENTS_COUNTER_CLASS = 'attachmentsCounter';
export const MALFORMED_TASK_ERROR = 'Malformed task';

export const TRUNCATED_FIELD_TYPES = ['multiLineText', 'singleLineText'];

export const MASS_ACTIONS_VALIDATION_ERRORS = {
  [MASS_ACTIONS_VALIDATION_RESULTS.HAS_REQUIRED_FIELDS]: 'taskTicket.massActionValidation.errors.hasRequiredFields',
  [MASS_ACTIONS_VALIDATION_RESULTS.NEED_SIGNATURE]: 'taskTicket.massActionValidation.errors.needSignature',
  [MASS_ACTIONS_VALIDATION_RESULTS.NEED_TAKE_ON_TASK]: 'taskTicket.massActionValidation.errors.needTakeOnTask',
  [MASS_ACTIONS_VALIDATION_RESULTS.NEED_PERFORMER_SELECTION]: 'taskTicket.massActionValidation.errors.needPerformerSelection',
  [MASS_ACTIONS_VALIDATION_RESULTS.IS_REWORK_STEP]: 'taskTicket.massActionValidation.errors.isReworkStep',
  [MASS_ACTIONS_VALIDATION_RESULTS.IS_CANCELED_STEP]: 'taskTicket.massActionValidation.errors.isCanceledStep',
  [MASS_ACTIONS_VALIDATION_RESULTS.IS_REJECT_STEP]: 'taskTicket.massActionValidation.errors.isRejectStep',
  [MASS_ACTIONS_VALIDATION_RESULTS.IS_OLD_REQUEST]: 'taskTicket.massActionValidation.errors.isOldRequest',
}
