import React, {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  memo,
  useState, ReactElement
} from 'react';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import isArray from 'lodash/isArray';

import { DialogModal, SideModal } from 'components';

import { setCustomModalParams, useCustomModalState } from 'store/main';
import { showDarkOverlay, useTaskState } from 'store/requests';
import { TaskParametersType } from 'types';

import CloseIcon from 'assets/images/icons/close-icon.svg';
import CloseWhiteIcon from 'assets/images/icons/close-white.svg';

type Attribute = {
  attributeSysName: string;
  dependentAttributeSysName?: string;
};

type Props = {
  sysName: string;
  params: {
    color?: 'info' | 'danger' | 'neutral';
    title?: string;
    subtitle?: string;
    maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    isFullScreen?: boolean;
    location?: 'center' | 'side';
    attributeList?: Attribute[];
    attributeButtonList?: Attribute[];
  };
  handleAction: (params: {
    action: string;
    tplName?: string;
    componentParams?: { [key: string]: any };
    instanceFormFieldName?: string;
    instance?: any;
    validationAttributeList?: TaskParametersType[];
  }) => void;
};

export const CustomModal = memo(
  ({
    sysName,
    handleAction,
    params: {
      title,
      color = 'info',
      subtitle,
      maxWidth = false,
      isFullScreen = false,
      location = 'center',
      attributeList = [],
      attributeButtonList = [],
    },
  }: Props): ReactElement => {
    const dispatch = useDispatch();

    const ref = useRef({});

    const { data: bpmTask } = useTaskState();
    const {
      open,
      loading,
      sysName: openModalSysName,
      focusedLinkedInstance,
      instanceFieldsPrefix,
      fillWithInstanceValues,
      focusedLinkedInstanceFieldName,
      clearFields,
      saveDataToMainForm,
    } = useCustomModalState();

    const { setValue, watch, getValues } = useFormContext();

    const [refreshComponents, setRefreshComponents] = useState(false);

    const isOpen = useMemo(
      (): boolean => open && sysName === openModalSysName,
      [open, sysName, openModalSysName]
    );

    const closeIcon = useMemo(
      (): string => (color === 'neutral' ? CloseIcon : CloseWhiteIcon),
      [color]
    );

    const onClose = useCallback((): void => {
      dispatch(setCustomModalParams({ open: false }));
    }, [dispatch]);

    const onButtonClick = useCallback(
      (params) => {
        handleAction({
          ...params,
          focusedLinkedInstanceId: focusedLinkedInstance?.id,
          instanceFormFieldName: focusedLinkedInstanceFieldName,
          instanceFieldsPrefix,
        });
      },
      [
        handleAction,
        focusedLinkedInstance,
        focusedLinkedInstanceFieldName,
        instanceFieldsPrefix,
      ]
    );

    const getModalAttributesByList = useCallback(
      (attributes: Attribute[]): any[] => {
        if (!(bpmTask && attributes.length)) {
          return [];
        }

        return attributes.map(
          ({ attributeSysName, dependentAttributeSysName }) => {
            const attribute = bpmTask.getAttributeBySysName(attributeSysName);

            if (!dependentAttributeSysName) {
              return attribute;
            }

            const {
              name: dependentAttributeName,
            } = bpmTask.getAttributeBySysName(dependentAttributeSysName);

            return {
              ...attribute,
              dependentAttributeName,
            };
          }
        );
      },
      [bpmTask]
    );

    useEffect(() => {
      dispatch(showDarkOverlay(isOpen));
    }, [dispatch, isOpen]);

    // TODO: написать комментарии и отрефакторить, ибо сейчас код непонятный
    useEffect(() => {
      if (saveDataToMainForm) {
        if (isOpen) {
          ref.current = {};
          const watchedFields = getModalAttributesByList(attributeList);
          watchedFields.forEach(({ name }) => {
            ref.current[name] = watch(name);
          });
        } else {
          const refCurrentKeys = Object.keys(ref.current);
          if (!refCurrentKeys.length) {
            return;
          }

          const obj = getValues();
          refCurrentKeys.forEach((name) => {
            if (name in obj && obj[name] !== ref.current[name]) {
              setValue(name, ref.current[name]);
            }
          });

          ref.current = {};
        }
      }
    }, [
      watch,
      attributeList,
      getModalAttributesByList,
      isOpen,
      getValues,
      setValue,
      saveDataToMainForm,
    ]);

    // TODO: написать комментарии к этому коду и убрать setTimeout
    useEffect(() => {
      // fill modal with value of instance from redux state
      // for opening modal for linked entity instance (edit, security check, ...)
      if (
        isOpen &&
        ((!loading &&
          fillWithInstanceValues &&
          focusedLinkedInstance &&
          instanceFieldsPrefix) ||
          (clearFields && instanceFieldsPrefix))
      ) {
        if (clearFields) {
          setRefreshComponents((v) => !v);
        }

        // it does not work without timeout
        setTimeout(() => {
          // find modal fieldswith specified prefix
          const values = getValues();
          const modalFormFields = Object.keys(values).filter((field) =>
            field.startsWith(instanceFieldsPrefix)
          );
          const modalFormFieldsWithoutPrefix = modalFormFields.map((v) =>
            v.replace(instanceFieldsPrefix, '')
          );

          // clear modal fields
          if (clearFields) {
            modalFormFields.forEach((field) => {
              setValue(field, '');
            });
            dispatch(
              setCustomModalParams({
                clearFields: false,
              })
            );
            if (!fillWithInstanceValues) {
              setRefreshComponents((v) => !v);
              return;
            }
          }

          // the go throught instance attributes and fill
          // corresponding modal field with values
          if (!clearFields) {
            setRefreshComponents((v) => !v);
          }

          const instanceKeys = Object.keys(focusedLinkedInstance) || [];
          instanceKeys.map((key) => {
            const currentInstance = focusedLinkedInstance[key];
            if (!key.includes('Attributes')) {
              return;
            }
            currentInstance.map(({ name, value }) => {
              if (modalFormFieldsWithoutPrefix.includes(name)) {
                const newValue = isArray(value)
                  ? value.filter((v) => !!v).join(',')
                  : value;
                setValue(instanceFieldsPrefix + name, newValue);
              }
            });
          });
          dispatch(
            setCustomModalParams({
              fillWithInstanceValues: false,
            })
          );
          // if (clearFields) {
          setRefreshComponents((v) => !v);
          // }
        }, 0);
      }
    }, [
      isOpen,
      loading,
      fillWithInstanceValues,
      focusedLinkedInstance,
      instanceFieldsPrefix,
      setRefreshComponents,
      dispatch,
    ]);

    const modalsMap = useMemo(() => {
      return {
        center: (
          <DialogModal
            open={isOpen}
            color={color}
            title={title}
            maxWidth={maxWidth}
            subtitle={subtitle}
            closeIcon={closeIcon}
            fullScreen={isFullScreen}
            loading={loading}
            buttonList={getModalAttributesByList(attributeButtonList)}
            childrenAttributes={getModalAttributesByList(attributeList)}
            onClose={onClose}
            handleAction={onButtonClick}
          />
        ),
        side: (
          <SideModal
            open={isOpen}
            color={color}
            title={title}
            subtitle={subtitle}
            loading={fillWithInstanceValues || clearFields || loading}
            refreshComponents={refreshComponents}
            closeIcon={closeIcon}
            buttonList={getModalAttributesByList(attributeButtonList)}
            childrenAttributes={getModalAttributesByList(attributeList)}
            onClose={onClose}
            handleAction={onButtonClick}
          />
        ),
        default: (
          <DialogModal
            open={isOpen}
            color={color}
            title={title}
            maxWidth={maxWidth}
            subtitle={subtitle}
            closeIcon={closeIcon}
            loading={loading}
            fullScreen={isFullScreen}
            buttonList={getModalAttributesByList(attributeButtonList)}
            childrenAttributes={getModalAttributesByList(attributeList)}
            onClose={onClose}
            handleAction={onButtonClick}
          />
        ),
      };
    }, [
      color,
      title,
      isOpen,
      onClose,
      maxWidth,
      subtitle,
      closeIcon,
      isFullScreen,
      attributeList,
      attributeButtonList,
      handleAction,
      getModalAttributesByList,
      fillWithInstanceValues,
      clearFields,
      loading,
      refreshComponents,
    ]);

    return <>{modalsMap[location] || modalsMap.default}</>;
  }
);
