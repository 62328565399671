import { FunctionComponent } from 'react';
import { UserType } from '../../types';

export type StepName = {
  [key in Language]: string;
};

export interface ProcessStep {
  id?: string;
  stepDescription?: StepName;
  stepOrder: number;
  stepName: StepName;
  assigneeId: string[];
  fields?: FormField[];
  hasTimer?: boolean;
  timer?: number | null | string;
  parallel?: boolean;
  isConditionBranchStep?: boolean;
  isParallelBranchesGroupStep?: boolean;
  assigneeIdFromBackend?: string[];
  summaryFields?: string[];
}

export interface ConditionStep {
  id: string;
  previousStepOrder: number;
  stepName: Partial<StepName>;
  condition: {
    field: string;
    type: string;
    value: string | number | Date;
  };
  positiveBranch: number[];
  negativeBranch: number[];
  hasPositiveBranch: boolean;
  hasNegativeBranch: boolean;
}

export interface ParallelBranchesGroup {
  id: string;
  previousStepOrder: number;
  stepName: Partial<StepName>;
  steps: number[][];
}

export interface FormField {
  id: string;
  component: string;
  isRequired: boolean;
  isEditable: boolean;
  isHidden: boolean;
  order: number;
  documentId: string;
  type: string;
  integration: string | null;
  hint: {
    [key: string]: string;
  };
  placeholder: {
    [key: string]: string;
  };
  params?: {
    [key: string]: string | number | boolean | number[];
  };
}

export interface FormProcess {
  language: Language;
  processName: string;
  description: string;
}

export interface TemplateProcessSettings {
  formProcess: FormProcess[];
  processIcon: string;
  processGroup: string;
}

export enum Language {
  RUSSIAN = 'ru',
  KAZAKH = 'kz',
  ENGLISH = 'en',
  BAHASA = 'id',
  GERMAN = 'de',
}

export interface TemplateProcessTableColumn {
  id: keyof Omit<TemplateProcess, 'languages'> | 'processMenu' | 'allLanguages' | 'creator';
  label: string;
  width?: number | string;
}

export interface TemplateProcessTableMenuButton {
  title: string;
  isProcessPublished?: boolean;
  onClick: () => void;
  visible: boolean;
  icon?: string;
}

export enum TemplateProcessType {
  DRAFT = 'DRAFT',
  PROCESS = 'PROCESS',
}

export interface TemplateProcess {
  category: string;
  companyId: string;
  createdDate: string;
  creator: string;
  description: string;
  handleRadioChange?: any;
  iconPath: string;
  integration?: string | null;
  summary_new?: string;
  summaryFields?: {
    name: string;
    sysName: string;
    type: string;
    fieldOrder: number;
  }[];
  generateAttributes?: {
    name: string;
    sysName: string;
    attributeLanguageDTO: {
      hint: string;
      language: string;
      placeholder: string;
    }[];
    documentTemplateField: string;
    type?: string;
    integration?: string | null;
  }[];
  languages: {
    description: string;
    language: string;
    processName: string;
  }[];
  lastUpdated: string;
  processName: string;
  processAutoSaveId?: string | null;
  processPublishedId?: string | null;
  draftIsChange?: boolean;
  hasAssigneeError?: boolean;
  processSysName: string;
  published: boolean;
  performerList?: number[];
  steps: {
    assigneeId: string[];
    stepName: string;
    stepOrder: number;
    timer?: number;
    attributes?: {
      attributeLanguageDTO: {
        hint: string;
        language: string;
        placeholder: string;
      }[];
      name: string;
      state_order: number;
      sysName: string;
    }[];
    possibleSteps?: number[];
    branchesEndStep?: number[];
    branchesEndScriptModel?: {
      condition?: string;
      field?: string;
      fieldType?: string;
      negativeStep?: number[];
      positiveStep?: number[];
      hasNegativeStep?: boolean;
      hasPositiveStep?: boolean;
      scriptName?: string;
      value?: string;
      isNewVersion?: boolean;
    };
    prevStep?: number[];
    parallelJoin?: boolean;
    conditionJoin?: boolean;
    parallelBranchName?: string;
    parallelBranch?: boolean;
    script?: boolean;
    scriptModel?: {
      condition?: string;
      field?: string;
      fieldType?: string;
      negativeStep?: number[];
      positiveStep?: number[];
      hasNegativeStep?: boolean;
      hasPositiveStep?: boolean;
      scriptName?: string;
      value?: string;
      isNewVersion?: boolean;
    };
    hasSignature: boolean;
    summaryFields?: {
      name: string;
      sysName: string;
      type: string;
      fieldOrder: number;
    }[];
  }[];
  stepsCount: number;
  stepsLanguage: {
    language: Language;
    name: string;
    stepOrder: number;
    stepDescription: string;
    scriptName: string;
    parallelBranchName: string;
  }[];
  type?: TemplateProcessType;
  autoStart?: string;
  recurrenceAutoStart?: {
    count?: number;
    startDate: string;
    endDate: string | null;
    endingNever: boolean;
    endingCount: number | null;
    recurrenceRule: string;
  };
  docflowTemplates: {
    attachmentId: string;
    docFlowTemplateId: string;
    fields: {
      docFlowTemplateFieldId: string;
      docFlowTemplateId: string;
      processFieldId: string;
      templateFieldId: string;
      templateFieldName: string;
      templateId: string;
      type: string;
      hint: string;
    }[];
    firstAppearanceStep: number;
    templateOrder?: number;
    processSysId: string;
    signRequiredSteps: number[];
    documentHiddenSteps: number[];
    title: string;
    description: string;
    department: string;
    type: string;
  }[];
  docflowTemplatesCount?: number;
}

export interface DepartmentInfo {
  additionalFields: any;
  createdAt: string;
  directoryId: string;
  holdingId: string;
  id: string;
  lastEdit: string;
  localization: { [lang: string]: string };
  status: string;
  value: string;
  valueParentId: string | null;
  valuesCount: number;
}

export interface UserSelectDepartmentData {
  key: string;
  name: string;
  selected?: number;
  total?: number;
  users?: UserType[];
}

export interface FieldComponent {
  key: string;
  title: string;
  icon: FunctionComponent;
  iconPath?: string;
  description: string;
  hiddenForTemplate?: boolean;
  hiddenFromFieldsList?: boolean;
  isDisabledToAdd?: boolean;
}

export interface StepValidationResult {
  [x: string]: any;

  stepName: string[];
  assigneeId: boolean;
  parallelAssignees: { required: boolean; repeated: boolean }[];
  fields: boolean;
  errorFieldsIds: string[];
  paramsWithErrors: { [key: string]: string[] };
  timer: boolean;
  autostartRepeatCount: boolean;
  autostartEndRepeatCount: boolean;
  scriptName: string[];
  scriptCondition: boolean;
  parallelBranchesName: string[];
  documents: string[];
  documentsWithoutSignature: string[];
  lengthLimit: {
    stepName: string[];
    scriptName: string[];
    parallelBranchesName: string[];
  };
}

export type Weekday = 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';

export interface AutostartSettings {
  repeatUnit: 'day' | 'week' | 'month' | 'year';
  repeatCount: number;
  startDate: Date;
  repeatDays: Weekday[];
  endingType: 'never' | 'date' | 'after';
  endingDate: Date | null;
  endingRepetitionsCount: number;
}

export type WorkspaceInfo = {
  id: string;
  bin: string;
  holdingId: string;
  isHolding: boolean;
  isDeputy: boolean;
  createdBy: string;
  employeeId: string;
  type: string;
  organization: {
    name: string;
    shortName: string;
    logo: string;
    email: string;
    type: string;
  };
  numberOfEmployees: number;
  role: string;
  isSubsidiary: boolean;
};

export type AllProcessFieldsListItem = {
  field: FormField;
  firstAppearanceStep: number;
  isFieldFromConditionStep: boolean;
  isFieldFromParallelBranchesGroupStep: boolean;
  fieldParallelBranchesGroupStep: string;
};

export type DocumentTemplateField = {
  id: string;
  name: string;
  type: string;
  hint: string;
  processFieldId?: string;
  backUniqueId?: string;
};

export type DocumentTemplate = {
  id: number;
  uniqueId?: string;
  backUniqueId?: string;
  title: string;
  destination: string;
  status: string;
  author: {
    userId: string;
    workspaceId: string;
  };
  department: string;
  type: string;
  createdAt: string;
  updatedAt: string;
  description: string;
  attachmentId: string;
  fields?: DocumentTemplateField[];
  firstAppearanceStep?: number;
  signatureSteps?: number[];
  hiddenSteps?: number[];
};
