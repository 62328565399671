import makeStyles from '@mui/styles/makeStyles';

export default makeStyles({
  avatarWrapper: {
    width: 28,
    height: 28,
    borderRadius: '50%',
    borderWidth: 2,
    borderColor: '#fff',
  },
  avatar: {
    width: 24,
    height: 24,
    fontSize: 12,
  },
  tooltip: {
    whiteSpace: 'pre-line',
  },
});
