import React, { ReactElement } from 'react';
import { Box, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import useFieldsListCardStyles from './FieldsListCard.useStyles';

import DragIndicator from 'assets/images/icons/drag-indicator.svg';
import { FieldComponent } from '../../TemplateProcesses.types';

interface ProcessStepCardProps {
  field: FieldComponent;
  isDragged?: boolean;
  rowType?: boolean;
  name?: string;
  type?: string;
  disabled?: boolean;
  selectedItemType?: 'processStep' | 'condition' | 'parallelBranchesGroups';
  isConditionStep?: boolean;
  isParallelStep?: boolean;
}

export const FieldsListCard = ({
  field,
  isDragged = false,
  rowType,
  name,
  type = '',
  disabled,
  selectedItemType,
  isConditionStep,
  isParallelStep,
}: ProcessStepCardProps): ReactElement => {
  const classes = useFieldsListCardStyles();
  const { t } = useTranslation();

  if (rowType) {
    return (
      <Box className={classes.rowCard}>
        <Box className={classes.rowIconWrapper}>
          <img src={(field.iconPath as unknown) as string} />
        </Box>

        <Box className={classes.rowFieldInfo}>
          <span className={classes.rowFieldName}>{name}</span>
          <span className={classes.secondaryName}>
            {t(field.title)}
            {type === 'integrated' ? ` · ${t('customProcesses.creationPage.processForm.fieldChips.integrated')}` : ''}
          </span>
        </Box>
      </Box>
    );
  }

  return (
    <Tooltip
      title={disabled &&
      (selectedItemType === 'condition' ||
        selectedItemType === 'parallelBranchesGroups' ||
        isConditionStep ||
        isParallelStep)
        ? t('fields.dontAddField') : disabled
          ? t('fields.addFieldDisabled') : ''}>
      <Box className={cn(classes.card, {
        [classes.cardDragged]: isDragged,
        [classes.cardDisabled]: disabled,
      })}>
        <img src={DragIndicator} className={classes.dragIndicator} alt=''/>

        <Box className={classes.iconWrapper}>
          <img src={(field.iconPath as unknown) as string} />
        </Box>

        <Tooltip title={(!disabled && (selectedItemType === 'condition' || selectedItemType === 'parallelBranchesGroups')) ? t(field.title) : ''} placement="bottom" arrow enterDelay={500}
                 classes={{ popper: classes.cardTooltipPopper, tooltip: classes.cardTooltip }}>
          <span className={classes.name}>{t(field.title)}</span>
        </Tooltip>
      </Box>
    </Tooltip>
  );
};
