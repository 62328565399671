import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useTreeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      color: '#262842',
      '&$selected > $content, &$selected > $content $label': {
        backgroundColor: '#fff',
      },
      '&$expanded > $content, &$expanded > $content $label': {
        backgroundColor: '#fff',
      },
      '&$expanded:hover > $content, &$expanded:hover > $content $label': {
        backgroundColor: '#fff',
      },
    },
    content: {
      height: 40,
      width: '100%',
      maxWidth: '100%',
      color: '#262842',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      '&:hover': {
        backgroundColor: 'rgb(243 244 246)',
      },
    },
    group: {
      marginLeft: 26,
      '& $content': {
        paddingLeft: theme.spacing(2),
      },
    },
    expanded: {
      '& > $content': {
        backgroundColor: '#fff',
      },
      '& > $content $label': {
        backgroundColor: '#fff',
      },
    },
    selected: {},
    iconContainer: {},
    hideIconContainer: {
      visibility: 'hidden',
    },
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
      maxWidth: '100%',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
    },
    labelIcon: {},
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1,
      maxWidth: '100%',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    labelTextDisabled: {
      color: '#A9A9B8 !important',
    },
    tooltipPopper: {
      pointerEvents: 'all',
    },
    tooltip: {
      color: '#F7F8FC',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '18px',
      maxWidth: 240,
      width: 'fit-content',
      padding: '8px 12px',
      borderRadius: 10,
    },
  })
);

export const useTreeStyles = makeStyles(
  createStyles({
    root: {
      flexGrow: 1,
    },
  })
);

export const useStyles = makeStyles(
  createStyles({
    valuesListWrapper: {
      width: '100%',
      display: 'flex',
      // @ts-ignore
      flexDirection: 'row !important',
      flexWrap: 'wrap',
      maxHeight: 'calc(24px *2 + 6px)',
      overflowY: 'hidden',
      gap: 4,
      marginTop: 8,
    },
    valueChip: {
      height: 24,
      whiteSpace: 'nowrap',
      padding: '4px 6px',
      paddingTop: 5,
      borderRadius: 6,
      background: '#F0F0F7',
      color: '#262842',
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 400,
      display: 'block',
      maxWidth: 204,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    showAllButton: {
      color: '#007994',
      marginTop: 8,
    },
    modalBody: {
      width: 500,
      background: '#fff',
      border: '1px solid rgba(38, 40, 66, 0.08)',
      boxShadow: '0px 16px 32px rgba(38, 40, 66, 0.08)',
      borderRadius: 12,

      overflow: 'hidden',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 99,
    },
    modalHeader: {
      padding: '12px 16px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid rgba(38, 40, 66, 0.08)',
    },
    modalContent: {
      padding: 16,
      paddingTop: 0,
      paddingBottom: 0,
      maxHeight: 400,
      overflowY: 'scroll',
    },
    modalFooter: {
      display: 'flex',
      justifyContent: 'end',
      gap: 12,
      padding: '12px 16px',
      background: '#F7F8FC',
      borderTop: '1px solid rgba(38, 40, 66, 0.08);',
    },
    modalButton: {
      borderRadius: 10,
      height: 40,
    },
    closeIcon: {
      '& > path': {
        fill: '#6D7C8B',
      },
    },
    title: {
      fontSize: 16,
      fontWeight: 500,
      color: '#262842',
    },
    description: {
      marginBottom: 32,
      fontSize: 14,
      color: '#6D6E85',

      '& span': {
        color: '#262842',
      },
    },
    treeRoot: {},
    tooltipPopper: {
      pointerEvents: 'all',
    },
    tooltip: {
      color: '#F7F8FC',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '18px',
      maxWidth: 240,
      width: 'fit-content',
      padding: '8px 12px',
      borderRadius: 10,
    },
    emptyValueMessage: {
      fontSize: 14,
      color: '#8B8C9E',
      fontStyle: 'normal',
      fontWeight: 400,
    },
  })
);
