import { useMemo } from "react";

import { parseJSON } from "utils/general";

export const LOCALSTORAGE_PROFILE_KEY = 'profile';
export const LOCALSTORAGE_COMPANY_KEY = 'workspace';

type Profile = {
  id: string;
  email: string;
  nickname: string;
  companyId?: string;
  companyName?: string;
  isHolding?: boolean;
  companyLogo?: string;
}

type Workspace = {
  id: string;
  isHolding: boolean;
  organization: {
    shortName?: string;
    name?: string;
    logo?: string;
  }
}

type Company = {
  companyId: string;
  companyName: string;
  isHolding: boolean;
  companyLogo?: string;
}

export const useUserProfile = (): Profile => {
  const company = useMemo((): Company => {
    const currentCompanyJSON: string = localStorage.getItem(LOCALSTORAGE_COMPANY_KEY);
    const {
      id,
      isHolding,
      organization: {
        shortName,
        name,
        logo
      }
    }: Workspace = parseJSON(currentCompanyJSON);

    return {
      companyId: id || '',
      companyName: shortName || name || '',
      isHolding,
      companyLogo: logo || '',
    }
  }, []);

  const profile = useMemo((): Profile => {
    const currentProfileJSON: string = localStorage.getItem(LOCALSTORAGE_PROFILE_KEY);
    const { id, email, nickname }: Profile = parseJSON(currentProfileJSON);

    return {
      id,
      email,
      nickname
    }
  }, []);

  return {
    ...profile,
    ...company
  }
}
