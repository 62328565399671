import React, { useEffect, useMemo, useState } from 'react';
import { Card, CardContent, Typography, Box, Accordion, AccordionSummary, AccordionDetails, Tooltip } from '@mui/material';
import { Close, Check, OpenInNew, ExpandMoreRounded } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import PdfIcon from 'assets/images/icons/pdf.svg';
import EGovIcon from 'assets/images/icons/e-gov.svg';
import { ReactComponent as WarningIcon } from 'assets/images/icons/warning_icon.svg';
import { getDocument } from 'api/requests';
import { useUserProfile } from 'hooks';
import { Docflow } from 'types';
import { numberWithSpaces } from 'utils/general';
import { limitStringLength } from 'utils/user';

import { useStyles } from './useStyles';
import { OverflowTooltip } from 'components/OverflowTooltip/overflow-tooltip';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { prepareDocumentForBpm } from './utils';

interface DocumentCardProps {
  document: Docflow.DocflowDocument | Docflow.ProcessDocument;
  openDocument?: (documentId: number) => void;
  selectedFormDocuments?: Array<Docflow.ProcessDocument>;
  setValue?: UseFormSetValue<FieldValues>;
  onDelete?: (documentId: number) => void;
  sum?: number;
  currency?: string;
}

export const DocumentCard: React.FC<DocumentCardProps> = ({
  document,
  onDelete,
  openDocument,
  sum,
  currency,
  selectedFormDocuments,
  setValue,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { id } = useUserProfile();
  const [fullDocument, setFullDocument] = useState<Docflow.FullDocflowDocument>();
  const [isHovered, setIsHovered] = useState<boolean>(false);

  useEffect(() => {
    getDocument(document.id).then((doc) => {
      setFullDocument(doc);
      if (setValue && selectedFormDocuments) {
        // setValue('docFlowDocuments', [...selectedFormDocuments.filter((item) => item.id !== doc.id), prepareDocumentForBpm(doc)]);
        setValue('docFlowDocuments', [prepareDocumentForBpm(doc)]);
      }
    });
  }, [document]);

  const isSigned = useMemo(() => fullDocument?.signatories?.some((signatory) => signatory.userId === id && signatory.isSigned), [
    fullDocument,
  ]);

  return (
    <Card className={classes.card}>
      <CardContent style={{ padding: '8px 12px 0' }} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        <div className={classes.documentHeader}>
          <img className={classes.icon} src={PdfIcon} alt="Pdf" />
          <div style={{ width: 'calc(100% - 36px)' }}>
            <div className={classes.documentTitleContainer}>
              <Tooltip title={document.title}>
                <Typography className={classes.documentTitle}>{document.title}</Typography>
              </Tooltip>
              {isSigned && (
                <span className={classes.documentSigned}>
                  <img src={EGovIcon} alt="E-Gov" />
                  <Typography className={classes.documentSignedText}>{t('DocumentProcessFromDocflow.EGovSigned')}</Typography>
                </span>
              )}
            </div>
            <Typography className={classes.documentDetails}>
              {limitStringLength(
                `${t('fields.filterDocument.number')}: ${fullDocument?.number || ''} · ${t('fields.filterDocument.linkedDocuments', {
                  N: fullDocument?.documentLinks?.length || 0,
                })}`,
                40
              )}
            </Typography>
          </div>

          {isHovered && !isSigned && (
            <div className={classes.iconButtons}>
              {openDocument ? (
                <Tooltip title={t('fields.filterDocument.showDocument')}>
                  <div className={classes.iconButton} onClick={() => openDocument(document.id)}>
                    <OpenInNew style={{ width: 20, height: 20 }} color="inherit" />
                  </div>
                </Tooltip>
              ) : null}
              {onDelete ? (
                <div className={classes.iconButton} onClick={() => onDelete(document.id)}>
                  <Close style={{ width: 20, height: 20 }} color="inherit" />
                </div>
              ) : null}
            </div>
          )}
        </div>

        {fullDocument?.counterparties?.length || fullDocument?.payments ? (
          <Accordion defaultExpanded={true} classes={{ root: classes.accordionRoot }}>
            <AccordionSummary
              expandIcon={<ExpandMoreRounded />}
              aria-controls="document-fields-content"
              id="document-fields-header"
              className={classes.accordionSummary}
              classes={{ content: classes.accordionSummaryContent }}
            >
              <Typography className={classes.fieldLabel}>{t('fields.filterDocument.DocumentFields')}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {fullDocument && (
                <div className={classes.fieldsContainer}>
                  {fullDocument?.counterparties?.length ? (
                    <div className={classes.fieldWrapper}>
                      <Typography className={classes.fieldValue}>{t('fields.filterDocument.Counterparty')}</Typography>
                      <Box>
                        {fullDocument.counterparties.map((counterparty) => (
                          <Box style={{ marginBottom: '8px' }}>
                            <Tooltip title={counterparty.name}>
                              <Typography className={classes.fieldValue}>{limitStringLength(counterparty.name, 20)}</Typography>
                            </Tooltip>
                            <Tooltip title={counterparty.companyCode}>
                              <Typography className={classes.fieldInfo}>BIN {limitStringLength(counterparty.companyCode, 20)}</Typography>
                            </Tooltip>
                          </Box>
                        ))}
                      </Box>
                    </div>
                  ) : null}
                  {fullDocument?.payments && (
                    <>
                      <div className={classes.fieldWrapper}>
                        <Typography className={classes.fieldValue}>{t('fields.filterDocument.ContractValue')}</Typography>
                        <Box>
                          <Typography className={classes.fieldValue}>
                            {numberWithSpaces(fullDocument?.payments.contractValue)} {fullDocument?.payments.currency}
                          </Typography>
                          {fullDocument?.payments.isVAT && (
                            <Typography className={classes.fieldInfo}>{t('fields.filterDocument.IncludingVat')}</Typography>
                          )}
                        </Box>
                      </div>
                      {typeof fullDocument?.payments.paymentBalance === 'number' && (
                        <div className={classes.fieldWrapper}>
                          <Typography className={classes.fieldValue}>{t('fields.filterDocument.PaymentBalance')}</Typography>
                          <Typography className={classes.fieldValue}>
                            {numberWithSpaces(fullDocument?.payments.paymentBalance)} {fullDocument?.payments.currency}
                          </Typography>
                        </div>
                      )}
                    </>
                  )}
                  {sum &&
                    fullDocument?.payments?.currency &&
                    (currency !== fullDocument?.payments?.currency ? (
                      <div className={classes.sumValidation} style={{ backgroundColor: '#FFE8C2' }}>
                        <div className={classes.iconWrapper}>
                          <WarningIcon />
                        </div>
                        <div>
                          <Typography className={classes.sumValidationTitle}>{t('fields.filterDocument.CurrencyMismatch')}</Typography>
                          <Typography className={classes.sumValidationSubTitle}>
                            {t('fields.filterDocument.CurrencyMismatchSubtitle')}
                          </Typography>
                        </div>
                      </div>
                    ) : fullDocument?.payments?.paymentBalance && sum > fullDocument?.payments?.paymentBalance ? (
                      <div className={classes.sumValidation} style={{ backgroundColor: '#FFE8C2' }}>
                        <div className={classes.iconWrapper}>
                          <WarningIcon />
                        </div>
                        <div>
                          <Typography className={classes.sumValidationTitle}>{t('fields.filterDocument.ExceedsPaymentBalance')}</Typography>
                          <Typography className={classes.sumValidationSubTitle}>
                            {t('fields.filterDocument.ExceedsPaymentBalanceSubtitle')}
                          </Typography>
                        </div>
                      </div>
                    ) : fullDocument?.payments?.paymentBalance && sum <= fullDocument?.payments?.paymentBalance ? (
                      <div className={classes.sumValidation} style={{ backgroundColor: '#DBFCEE' }}>
                        <Check style={{ fontSize: 24, color: '#2DB77B' }} />
                        <div>
                          <Typography className={classes.sumValidationTitle}>{t('fields.filterDocument.WithinPaymentBalance')}</Typography>
                          <Typography className={classes.sumValidationSubTitle}>
                            {t('fields.filterDocument.WithinPaymentBalanceSubtitle')}
                          </Typography>
                        </div>
                      </div>
                    ) : null)}
                </div>
              )}
            </AccordionDetails>
          </Accordion>
        ) : null}
      </CardContent>
    </Card>
  );
};
