const MONDAY = 1;
const DAYS_COUNT = 42;
export type Day = {
  date: Date;
  isNextMonth: boolean;
  isPrevMonth: boolean;
};
export type Calendar = {
  [key: string]: Array<Day>;
};

export default class CalendarClass {
  constructor(private readonly date: Date) {}
  private readonly _date = this.date;

  getMatrix(): Calendar {
    const result = this._generateCalendar();
    const calendar: Calendar = {};

    let i = 0;
    result.forEach((day) => {
      if (day.date?.getDay() === MONDAY) {
        i++;
      }

      if (calendar.hasOwnProperty(`week_${i}`)) {
        calendar[`week_${i}`].push(day);
      } else {
        calendar[`week_${i}`] = [];
        calendar[`week_${i}`].push(day);
      }
    });

    return calendar;
  }

  private _generateCalendar(): Array<Day> {
    const daysOfInputMonth = this._getDaysOfMonth(
      this._date?.getFullYear(),
      this._date?.getMonth()
    );
    const withPreviousDays = this._addPreviousMonthDays(daysOfInputMonth);
    const result = this._addNextMonthDays(withPreviousDays);
    return result;
  }

  private _getDaysOfMonth(year: number, month: number): Array<Day> {
    const date = new Date(year, month, 1);
    const days = [];
    while (date.getMonth() === month) {
      const day = new Date(date?.getFullYear(), date?.getMonth(), date?.getDate());
      days.push({
        date: day,
        isNextMonth: false,
        isPrevMonth: false,
      });
      date.setDate(date.getDate() + 1);
    }

    return days;
  }

  private _addPreviousMonthDays(days: Array<Day>): Array<Day> {
    const [year, month] = this.determiningPreviousMonth();
    const prevDays = this._getDaysOfMonth(year, month);
    const weekdayOfCurrentMonth = days[0]?.date?.getDay();

    for (
      let i = 0, j = prevDays.length - 1;
      i < (weekdayOfCurrentMonth || 7);
      i++, j--
    ) {
      const day = { ...prevDays[j], isPrevMonth: true };
      days.unshift(day);
    }

    return days;
  }

  private _addNextMonthDays(days: Array<Day>): Array<Day> {
    const [year, month] = this.determiningNextMonth();
    const nextMonth = this._getDaysOfMonth(year, month);

    for (let i = days.length - 1, j = 0; i < DAYS_COUNT; i++, j++) {
      const day = { ...nextMonth[j], isNextMonth: true };
      days.push(day);
    }

    return days;
  }

  private determiningPreviousMonth(): Array<number> {
    const date = new Date(this._date?.getTime());
    date.setMonth(date.getMonth() - 1);
    return [date?.getFullYear(), date?.getMonth()];
  }

  private determiningNextMonth(): Array<number> {
    const date = new Date(this._date?.getTime());
    date.setMonth(date?.getMonth() + 1);
    return [date?.getFullYear(), date?.getMonth()];
  }
}
