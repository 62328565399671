import { useCallback, useMemo } from 'react';
import queryString, { ParsedQuery } from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

type Params = {
  currentPage?: number;
  completed?: boolean;
  type?: 'requests' | 'approvals' | 'search' | 'monitoring';
  pageSize?: number;
};

export const useRequestParams = ({
  currentPage,
  completed,
  type = 'requests',
  pageSize = 10,
}: Params) => {
  const history = useHistory();
  const { pathname, search } = useLocation();

  const urlParams = useMemo(() =>
      queryString.parse(search) as ParsedQuery<any>,
    [search]);

  const getSearchQueryObject = useCallback((values): Record<string, string> => {
    const {
      processDefinitionName,
      assignee,
      initiator,
      legalEntity,
      searchText,
      fromDate,
      tillDate,
      urgent,
      page
    } = values;

    return {
      ...(fromDate && { fromDate }),
      ...(tillDate && { tillDate }),
      ...(assignee && { assignee }),
      ...(initiator && { initiator }),
      ...(legalEntity && { legalEntity }),
      ...(processDefinitionName && { processDefinitionName }),
      ...(searchText && { searchText }),
      ...(urgent && { urgent }),
      ...((!!page || page === 0) && { page }),
    };
  }, []);

  const getRequestParameters = useCallback(
    (formValues = { pagination: {}}) => {
      return {
        pagination: {
          ...formValues.pagination,
          size: pageSize,
          page: currentPage - 1,
          sort: 'taskStartDate,DESC'
        },
        ...formValues,
      }
    },
    [getSearchQueryObject, type, completed, currentPage, pageSize]
  );

  const updateHistory = useCallback(formValues => {
    const searchQueryURL = new URLSearchParams(getSearchQueryObject(formValues)).toString();

    const historyParameters = {
      pathname,
      search: `?${searchQueryURL}`,
    };

    history.replace(historyParameters);
    }, [history, pathname, getSearchQueryObject]);

  return {
    updateHistory,
    getRequestParameters,
    urlParams
  };
};
