import makeStyles from '@mui/styles/makeStyles';

const useStyles = ({percentage, showErrorIcon, showSuccessIcon}) => makeStyles(() => ({
  massApproveLoader: {
    display: (showErrorIcon || showSuccessIcon) ? 'flex' : 'block', 
    backgroundColor: '#262842',
    border: '1px solid #2628421F',
    borderRadius: '10px',
    bottom: '24px',
    gap: '12px',
    height: '56px',
    padding: '9px 16px',
    position: 'absolute',
    right: '24px',
    width: '373px',
    zIndex: 1001,
  },
  statusText: {
    color: '#FFFFFF',
  },
  loadTracker: {
    width: '100%',
    height: '8px',
    backgroundColor: '#FFFFFF1F',
    borderRadius: '8px',
  },
  loadTrackerBar: {
    width: `${percentage}%`,
    height: '8px',
    marginTop: '8px',
    borderRadius: '8px',
    backgroundColor: '#2DB77B',
  },
  messageWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  errorIcon: {
    width: '20px',
    height: '20px',
    marginRight: '14px'
  },
  successIcon: {
    width: '20px',
    height: '20px',
    marginRight: '14px'
  },
  closeIcon: {
    width: '20px',
    height: '20px',
  },
  blockWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent:'space-between',
    alignItems: 'center',
  }
}));

export default useStyles;