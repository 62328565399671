import i18next from "i18next";
import { getLocalizationByHoldingId } from 'api/requests/dictionary-api';
import { supportedLngs } from './index';
import { setHoldingTranslationsLoaded } from '../store/main';

export const getHoldingTranslations = async (dispatch) => {
  try {
    for (const lang of supportedLngs) {
      const translationsLocale = await getLocalizationByHoldingId(lang, 'processesV2');
      i18next.addResourceBundle(lang, 'processesV2', translationsLocale, true);
    }
  } catch (error) {
    console.log('Error loading holding specific translations', error);
  } finally {
    dispatch(setHoldingTranslationsLoaded(true));
  }
};
