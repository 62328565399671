import { handleActions } from 'redux-actions';

import { MAIN_ACTIONS } from './main.actions';

import uniq from 'lodash/uniq';

export const initState = {
  customModalParams: {
    open: false,
    loading: false,
    sysName: '',
    instanceFieldsPrefix: '',
    focusedLinkedInstance: null,
    focusedLinkedInstanceFieldName: '',
    fillWithInstanceValues: false,
    refreshEntities: false,
    clearFields: false,
    saveDataToMainForm: false,
  },
  sideMenuOpen: true,
  readStatus: {
    tasks: [],
    comments: [],
    attachments: [],
  },
  holdingTranslationsLoaded: false,
};

type InitState = typeof initState;

const {
  SET_CUSTOM_MODAL_PARAMS,
  CLOSE_CUSTOM_MODAL,
  REFRESH_ENTITIES,
  SET_SIDE_MENU_OPEN,
  TOGGLE_SIDE_MENU_OPEN,
  RESTORE_SIDE_MENU_OPEN_STATE,
  READ_TASK,
  READ_TASK_COMMENTS,
  READ_TASK_ATTACHMENTS,
  SET_HOLDING_TRANSLATIONS_LOADED,
} = MAIN_ACTIONS;

export default handleActions(
  {
    [SET_CUSTOM_MODAL_PARAMS]: (state: InitState, { payload }) => ({
      ...state,
      customModalParams: {
        ...state.customModalParams,
        ...payload,
      },
    }),
    [CLOSE_CUSTOM_MODAL]: (state: InitState) => ({
      ...state,
      customModalParams: {
        ...state.customModalParams,
        open: false,
        fillWithInstanceValues: false,
      },
    }),
    [REFRESH_ENTITIES]: (state: InitState, { payload }) => ({
      ...state,
      customModalParams: {
        ...state.customModalParams,
        refreshEntities: payload,
      },
    }),
    [SET_SIDE_MENU_OPEN]: (state: InitState, { payload }) => ({
      ...state,
      sideMenuOpen: payload,
    }),
    [RESTORE_SIDE_MENU_OPEN_STATE]: (state: InitState, { payload }) => ({
      ...state,
      sideMenuOpen: payload,
    }),
    [TOGGLE_SIDE_MENU_OPEN]: (state: InitState, { payload }) => ({
      ...state,
      sideMenuOpen: !state.sideMenuOpen,
    }),
    [READ_TASK]: (state: InitState, { payload }) => ({
      ...state,
      readStatus: {
        ...state.readStatus,
        tasks: uniq([...state.readStatus.tasks, payload]),
      },
    }),
    [READ_TASK_COMMENTS]: (state: InitState, { payload }) => ({
      ...state,
      readStatus: {
        ...state.readStatus,
        comments: uniq([...state.readStatus.comments, payload]),
      },
    }),
    [READ_TASK_ATTACHMENTS]: (state: InitState, { payload }) => ({
      ...state,
      readStatus: {
        ...state.readStatus,
        attachments: uniq([...state.readStatus.attachments, payload]),
      },
    }),
    [SET_HOLDING_TRANSLATIONS_LOADED]: (state: InitState, { payload }) => ({
      ...state,
      holdingTranslationsLoaded: payload,
    }),
  },
  initState,
);
