import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    marginLeft: -16,
    width: 'calc(100% + 16px) !important',
  },
  scrollbar: {
    width: '100%',
    height: 'calc(100vh - 300px) !important',
    paddingRight: 12,
  },
  activePanelScrollbar: {
    backgroundColor: '#F5F8FA',
    height: 'calc(100vh - 300px - 59px) !important',
  },
  tableFooterHint: {
    color: '#6D6E85'
  },
}));

export default useStyles;
