import React, { memo } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useStyles from "./useStyles";

export const CustomSnackbar = memo(() => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box className={classes.customSnackbar}>
      <Typography className={classes.customSnackbarTitle}>{t('task_data_view.updating_data')}</Typography>
    </Box>
  )
});
