import { handleActions } from 'redux-actions';
import { FULFILLED, PENDING, REJECTED } from 'store/constants';

import { SEARCH_ACTIONS } from './search.actions';

export const initState = {
  searchList: {
    data: null,
    error: '',
    loading: false,
    currentPage: 1,
    pageSize: 10,
    totalPages: 0,
    totalElements: 0,
    needRefresh: false
  },
  filters: null
};

type InitState = typeof initState & {
  filters: any
};

const {
  SEARCH_REQUESTS,
  SET_CURRENT_PAGE,
  SET_CURRENT_PAGE_SIZE,
  SET_FILTERS
} = SEARCH_ACTIONS;

export default handleActions(
  {
    [SEARCH_REQUESTS + PENDING]: (state: InitState) => ({
      ...state,
      searchList: {
        ...state.searchList,
        loading: true,
        needRefresh: false,
      },
    }),
    [SEARCH_REQUESTS + FULFILLED]: (state: InitState, { payload }) => ({
      ...state,
      searchList: {
        ...state.searchList,
        loading: false,
        needRefresh: false,
        data: payload?.content,
        totalPages: payload?.totalPages || 0,
        totalElements: payload?.totalElements || 0
      },
    }),
    [SEARCH_REQUESTS + REJECTED]: (state: InitState, { payload }) => ({
      ...state,
      searchList: {
        ...state.searchList,
        loading: false,
        needRefresh: false,
        error: payload?.message
      },
    }),
    [SET_CURRENT_PAGE]: (state: InitState, { payload }) => ({
      ...state,
      searchList: {
        ...state.searchList,
        currentPage: payload?.page ?? 0,
        needRefresh: payload?.refresh ?? true,
      },
    }),
    [SET_CURRENT_PAGE_SIZE]: (state: InitState, { payload }) => ({
      ...state,
      searchList: {
        ...state.searchList,
        pageSize: payload,
        currentPage: 1,
        needRefresh: true,
      },
    }),
    [SET_FILTERS]: (state: InitState, { payload }) => ({
      ...state,
      filters: payload,
    }),
  },
  initState
);
