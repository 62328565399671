import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ spacing, palette }) => ({
  popover: {
    position: 'absolute',
    top: '50vh',
    left: '50vw',
  },
  templateNameInput: {
    marginTop: spacing(2),
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: spacing(5, 6, 6, 6),
    background: '#DF5857',
    color: palette.common.white,
  },
  headerTitle: {
    fontSize: 18,
    fontWeight: 500,
  },
  headerSubtitle: {
    fontSize: 14,
    fontWeight: 400,
    marginTop: spacing(3),
  },
  closeButton: {
    position: 'absolute',
    top: spacing(7),
    right: spacing(7),
  },
  deleteButton: {
    backgroundColor: '#DF5857',
    '&:hover': {
      backgroundColor: '#DF5857',
    },
  },
  content: {},
}));

export default useStyles;
