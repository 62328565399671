import JsFileDownloader from 'js-file-downloader';

const getFile = (response, type, filename): File | Blob => {
  let file;
  const options = { type };

  try {
    file = new File([response.data], filename, options);
  } catch (e) {
    file = new Blob([response.data], options);
    file.name = filename;
    file.lastModifiedDate = new Date();
  }
  return file
}

export const saveFileFromAxiosResponse = (response, type, filename) => {
  const file = getFile(response, type, filename);
  const objectUrl = window.URL.createObjectURL(file);
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = objectUrl;
  link.download = filename;

  let event;
  try {
    event = new MouseEvent('click');
  } catch (e) {
    event = document.createEvent('MouseEvent');
    event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
  }

  link.dispatchEvent(event);

  setTimeout(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (window.URL || window.webkitURL || window)?.revokeObjectURL(objectUrl);
  }, 1000 * 40);
};

export const downloadAttachmentFromUrl = (
  url,
  filename,
): Promise<any> => {
  return new JsFileDownloader({
    url: url,
    filename,
  }) as any;
};
