import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import useDeleteProcessStepFieldDialogStyles from './DocumentFieldsLinkModal.useStyles';
import CloseIcon from 'assets/images/icons/close-icon-grey.svg';
import LinkIcon from 'assets/images/icons/link_regular.svg';
import {
  AllProcessFieldsListItem,
  DocumentTemplate,
  Language,
  ParallelBranchesGroup,
} from '../../../../../../TemplateProcesses.types';
import { FIELDS } from '../../../../ProcessSetupSidePanel.constants';

import ChevronDown from 'assets/images/icons/chevron_down_regular.svg';
import {limitStringLength} from "../../../../../../../../utils/user";

const Icon = () => {
  return <img style={{ position: 'absolute', right: 10, top: 9, pointerEvents: 'none' }} src={ChevronDown} alt=""/>;
};

interface DocumentFieldsLinkModalParams {
  isOpen: boolean;
  onClose: () => void;
  onSave: (fieldsLinks: {[key:string]: string}) => void;
  currentDocument: DocumentTemplate | null;
  availableFields: {[key: string]: AllProcessFieldsListItem[]}
  currentProcessStepOrder?: number;
  selectedDocumentFields: string[];
  allProcessStepsFields: AllProcessFieldsListItem[];
  parallelBranchesGroups: ParallelBranchesGroup[];
  currentLanguage: Language;
}

export const DocumentFieldsLinkModal = ({
  isOpen,
  onClose,
  onSave,
  currentProcessStepOrder,
  currentDocument,
  availableFields,
  selectedDocumentFields,
  allProcessStepsFields,
  parallelBranchesGroups,
  currentLanguage,
}: DocumentFieldsLinkModalParams): ReactElement => {
  const { t } = useTranslation();
  const classes = useDeleteProcessStepFieldDialogStyles();

  const [fieldsLinks, setFieldsLinks] = useState({});

  useEffect(() => {
    const tempFieldsLinks = [];
    selectedDocumentFields.forEach(fieldId => {
      const documentField = currentDocument.fields.find(field => field.id === fieldId);
      tempFieldsLinks[documentField.id] = documentField.processFieldId;
    });
    setFieldsLinks(tempFieldsLinks);
  }, [selectedDocumentFields, currentDocument]);

  const documentFields = useMemo(() => {
    if (!currentDocument) {
      return [];
    }
    return currentDocument?.fields?.filter(field => selectedDocumentFields?.includes(field.id));
  }, [currentDocument, selectedDocumentFields]);

  const handleSave = () => {
    onSave(fieldsLinks);
    onClose();
  };

  const handleClearAll = () => {
    const tempFieldsLinks = [];
    selectedDocumentFields.forEach(fieldId => {
      tempFieldsLinks[fieldId] = '';
    });
    setFieldsLinks(tempFieldsLinks);
  };

  const handleFieldLink = (documentFieldId: string, processFieldId: string) => {
    const linksTemp = {
      ...fieldsLinks
    };
    linksTemp[documentFieldId] = processFieldId;
    setFieldsLinks(linksTemp);
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      classes={{ paper: classes.dialog }}
      BackdropProps={{style: {backgroundColor: 'rgba(38, 40, 66, 0.32)'}}}
      disableEnforceFocus
    >
      <Box className={classes.dialogHeader}>
        <Typography className={classes.dialogTitle}>
          {t('customProcesses.documentFieldsLinkModal.title')}
        </Typography>
        <IconButton onClick={onClose} size="large">
          <img src={CloseIcon} className={classes.dialogIcon}/>
        </IconButton>
      </Box>

      <DialogContent className={classes.dialogContent}>
        <p className={classes.description}>{t('customProcesses.documentFieldsLinkModal.description')}</p>

        <Box className={classes.fieldsBlock}>

          <Box className={classes.fieldsBlockRow}>
            <div className={cn(classes.sideColumnCell, classes.columnTitle)}>{t('customProcesses.documentFieldsLinkModal.columns.templateFields')}</div>
            <div style={{minWidth: 20}}></div>
            <div className={cn(classes.sideColumnCell, classes.columnTitle)}>{t('customProcesses.documentFieldsLinkModal.columns.filledBy')}</div>
          </Box>

          {documentFields.map(fieldData =>
            <Box className={classes.fieldsBlockRow}>
              <div className={classes.sideColumnCell}>
                <div className={classes.fieldCard}>
                  <Tooltip title={fieldData.name}>
                    <span className={classes.fieldName}>{limitStringLength(fieldData.name, 30)}</span>
                  </Tooltip>
                  <Tooltip title={t(FIELDS.find(v => v.key === fieldData.type)?.title, {defaultValue: fieldData.type})}>
                    <span className={classes.fieldType}>{limitStringLength(t(FIELDS.find(v => v.key === fieldData.type)?.title, {defaultValue: fieldData.type}), 30)}</span>
                  </Tooltip>
                </div>
              </div>

              <div className={classes.centralColumnCell}>
                <img alt='' src={LinkIcon} className={classes.linkIcon}/>
              </div>

              <div className={classes.sideColumnCell}>
                <Select
                  IconComponent={Icon}
                  onChange={({ target: { value } }) => {
                    handleFieldLink(fieldData.id, value as string);
                  }}
                  value={fieldsLinks[fieldData.id]}
                  className={classes.fieldSelect}
                  error={availableFields[fieldData.id].length === 0}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        borderRadius: 10,
                      },
                    },
                    MenuListProps: { 'aria-labelledby': 'basic-button' },
                    style: {
                      marginTop: 10,
                      marginLeft: -8,
                      border: '1px solid rgba(38, 40, 66, 0.08)',
                      boxShadow: '0px 8px 16px rgba(38, 40, 66, 0.04)',
                    },
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  }}
                  renderValue={() => {
                    const selectedFieldData = allProcessStepsFields.find(item => item.field.id === fieldsLinks[fieldData.id]);
                    return <div className={classes.fieldInfo}>
                      <Tooltip title={selectedFieldData?.field?.hint[currentLanguage]}>
                        <span className={classes.fieldName}>
                        {limitStringLength(selectedFieldData?.field?.hint[currentLanguage], 30)}
                      </span>
                      </Tooltip>
                      <Tooltip
                        title={t(FIELDS.find(v => v.key === selectedFieldData?.field?.component)?.title, {defaultValue: selectedFieldData?.field?.component})}
                      >
                        <span className={classes.fieldType}>
                        {limitStringLength(t(FIELDS.find(v => v.key === selectedFieldData?.field?.component)?.title, {defaultValue: selectedFieldData?.field?.component}), 20)} · {t('customProcesses.documentFieldsLinkModal.fieldStep', {stepOrder: selectedFieldData.firstAppearanceStep})}
                      </span>
                      </Tooltip>
                    </div>
                  }}
                >
                  {availableFields[fieldData.id].map(fieldInfo => {
                    const { field: {hint, component, id}, firstAppearanceStep} = fieldInfo as any;

                    return <MenuItem
                      value={id}
                      className={classes.fieldSelectItem}
                    >
                      <div className={classes.fieldInfo}>
                         <Tooltip title={hint[currentLanguage]}>
                           <span className={classes.fieldName}>
                             {limitStringLength(hint[currentLanguage], 30)}
                           </span>
                         </Tooltip>
                        <Tooltip title={t(FIELDS.find(v => v.key === component)?.title, {defaultValue: component})}>
                          <span className={classes.fieldType}>
                            {limitStringLength(t(FIELDS.find(v => v.key === component)?.title, {defaultValue: component}), 20)} · {t('customProcesses.documentFieldsLinkModal.fieldStep', {stepOrder: firstAppearanceStep})}
                          </span>
                        </Tooltip>
                      </div>
                    </MenuItem>;
                  })}
                </Select>
                {availableFields[fieldData.id].length === 0
                 ? <div className={classes.errorMessage}>
                   <span>{t('customProcesses.documentFieldsLinkModal.errors.noFields')}</span>
                 </div>
                 : null
                }
              </div>
            </Box>
          )}
        </Box>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button
          className={classes.dialogActionsButton}
          variant="contained"
          color="secondary"
          onClick={onClose}
        >
          {t('template_delete_modal.button_cancel')}
        </Button>

        <Button
          className={classes.dialogActionsButton}
          variant="contained"
          color="secondary"
          onClick={handleClearAll}
          disabled={Object.values(fieldsLinks).every(v => !v)}
        >
          {t('customProcesses.documentFieldsLinkModal.buttons.clearAll')}
        </Button>

        <Button
          className={classes.dialogActionsButton}
          variant="contained"
          color="primary"
          onClick={handleSave}
        >
          {t('customProcesses.documentFieldsLinkModal.buttons.apply')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
