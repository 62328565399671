import { useCallback, useEffect, useMemo, useRef } from "react";

import { useDispatch } from "react-redux";
import { routesList } from 'router';
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { clearSelectedApproval, clearSelectedReviewedCurrentTask, useSelectedApproval } from "store/approvals";
import { clearSelectedRequest, clearTaskData, getTaskById, useSelectedRequest } from "store/requests";
import { CHANGE_ASSIGNEE_AVAILABLE_ROUTES } from "../components/TaskDetailsContent";

export const useTaskDetails = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const mounted = useRef<boolean>();
  const selectedApprovalId = useSelectedApproval();
  const selectedRequestId = useSelectedRequest();
  const { t } = useTranslation();
  const routerMatch = useRouteMatch();
  const history = useHistory();

  const isFullScreen = useMemo(() => {
    return routerMatch.path === "/task/:id" || routerMatch.path === "/process/:id"
  }, [routerMatch])

  const close = () => {
    clearTasksData();

    if (CHANGE_ASSIGNEE_AVAILABLE_ROUTES.includes(routerMatch.path)) {
      history.push(routesList(t).approvals.path);
    }
  }

  const taskId = (selectedApprovalId || selectedRequestId)

  const clearTasksData = useCallback( () => {
    dispatch(clearSelectedRequest())
    dispatch(clearSelectedApproval())
    dispatch(clearSelectedReviewedCurrentTask());
    dispatch(clearTaskData())
  }, [])

  useEffect(() => {
    return () => {
      clearTasksData()
    }
  }, [])

  useEffect(() => {
    if(mounted.current) {
      clearTasksData();
    } else {
      mounted.current = true;
    }
  }, [location.pathname])

  useEffect(() => {
    if (taskId){
      dispatch(getTaskById(taskId))
    }
  }, [taskId])

  return {
    close,
    isFullScreen
  }
}
