import {useState} from "react";

export const useDialogState = () => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const handleDialogOpen = (): void => {
    setIsDialogOpen(true);
  }

  const handleDialogClose = (): void => {
    setIsDialogOpen(false);
  }

  return {
    isDialogOpen,
    handleDialogOpen,
    handleDialogClose
  }
}
