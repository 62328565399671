import React from "react";
import { Box, Typography } from '@mui/material';

import useStyles from "./useStyles";
import NoResultsImage from "assets/images/no-results.png";

const NO_RESULTS_TITLE = "No results found";
const NO_RESULTS_SUBTITLE = "No results found";

export const SearchNoResults = () => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Box display="flex" flexDirection="column">
        <Typography className={classes.title}>{NO_RESULTS_TITLE}</Typography>
        <Box pt={1}>
          <Typography className={classes.subtitle}>{NO_RESULTS_SUBTITLE}</Typography>
        </Box>
      </Box>

      <Box className={classes.imageWrapper}>
        <img
          src={NoResultsImage}
          className={classes.imageWrapperImage}
          alt=""
        />
      </Box>
    </Box>
  )
};
