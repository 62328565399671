import React, { memo, ReactElement, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from "react-i18next";

import { UserInfo } from 'components';

import { useUsersState } from 'store/users';
import { getUserDataByIdOrLogName } from 'utils/user';
import { UserType } from 'types';

type AssigneeProps = {
  assignee: string;
  companyId?: string;
}

export const Assignee = memo(({ assignee, companyId }: AssigneeProps): ReactElement => {
  const { t } = useTranslation();
  const { users } = useUsersState();

  const [user, setUser] = useState<UserType | null>(null);

  const getUserData = async (assigneeKey: string) => {
    const assigneeId = Object.keys(users).find((hcmsUserId: string) => {
      const { logName, id }: UserType = users[hcmsUserId];

      // Данные от creator-a могут приходить ввиде logName-a либо griffon id
      return [logName, id].includes(assignee);
    });

    if (assigneeId) {
      setUser(users[assigneeId]);
      return;
    }

    const userInfo = await getUserDataByIdOrLogName(assigneeKey, companyId);
    if (userInfo) {
      setUser(userInfo);
    }
  };

  useEffect(() => {
    getUserData(assignee);
  }, [assignee, users]);

  if (user) {
    return (
      <UserInfo
        user={user}
        showRole={false}
        avatarSize={16}
        smallName
      />
    );
  }

  return (
    <Typography style={{fontSize: 12, color: '#465564'}}>{t("task_data_view.employee_not_found")}</Typography>
  );
});
