import React, { useMemo, useState } from 'react';
import { Box, Button, Popover, Typography } from '@mui/material';
import { CloseButton, UserInfo } from 'components';
import { useTranslation } from 'react-i18next';
import useStyles from './useStyles';
import MailIcon from "assets/images/icons/mail_regular.svg"

type Props = {
  users: any[]
  onClose: () => void
  title: string;
}

export const AssigneeListModal = ({users, onClose, title}: Props) => {
  const classes = useStyles()
  const { t } = useTranslation();

  const departmentName = useMemo(() => {
    return users.every(user => users[0].departmentName === user.departmentName) ? users[0].departmentName : null
  }, [users])

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [hoveredUser, setHoveredUser] = useState(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>, user: any) => {
    setAnchorEl(event.currentTarget);
    setHoveredUser(user)
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setHoveredUser(null)
  };

  const open = Boolean(anchorEl);

  return <Box className={classes.root}>
    <Box className={classes.content}>
      <Box className={classes.header}>
        <div>
          <Typography component="h4">
            {title}
          </Typography>

          {departmentName && <Typography component="p">
            {departmentName}
          </Typography>}
        </div>
        <CloseButton onClick={onClose} />
      </Box>

      <Box className={classes.body}>
        {users.map(user => {
          return <Box
                className={classes.userInfoSm}
                onMouseEnter={(e) => handlePopoverOpen(e, user)}
                onMouseLeave={handlePopoverClose}
          >
            <UserInfo user={user} avatarSize={32} showPosition />
          </Box>
        })}
      </Box>

      <Box className={classes.footer}>
        <Button color="secondary" variant="contained" onClick={onClose}>{t("filters.filter_button_close")}</Button>
      </Box>

      <Popover
        id="user-details"
        open={open}
        style={{
          pointerEvents: 'none',
        }}
        PaperProps={{
          style: {
            borderRadius: 12
          }
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box className={classes.userInfoMd}>
          <UserInfo user={hoveredUser} avatarSize={44} showPosition />
          {
            hoveredUser?.email && <Box className={classes.mailBlock}>
            <img src={MailIcon} alt='' />
            <Typography component="p">{hoveredUser?.email}</Typography>
          </Box>
          }
        </Box>
      </Popover>

    </Box>
  </Box>
}
