import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';

import {
  deleteAutoSaveTemplate, getTemplateBySysName, getTemplateProcessBySysName,
  deleteAutoSaveTemplateRequest,
  getTemplateProcessDraftBySysName,
  saveTemplateProcessRequest,
  saveTemplateRequest,
  getTemplateDraftBySysName,
} from 'api/requests';

import { FormProcess, Language, ProcessStep } from '../../../TemplateProcesses.types';
import { useRouteMatch } from 'react-router-dom';

export const useProcessAutoSave = ({
  fields,
  conditions,
  documents,
  initialValues,
  parallelBranchesGroups,
  editedVersionValues,
  publishedVersionValues,
  allProcessStepsFields,
  summaryFields,
  directManagerSteps,
  performerSelectionSteps,
  autoSaveProcessSysName,
  isAutostartActive,
  autostartSettings,
  fieldsSettingsForm,
  isEditDisabled,
  setLoading,
  setCurrentProcessIndex,
  setSelectedItemType,
  setCurrentConditionId,
  setFocusedStepFieldId,
  setIsSubmitAvailable,
  setEditDisabled,
  setVersionToggleOpen,
  setVersionToggleAnchorEl,
  setEditedVersionValues,
  getBeatifiedData,
  initiateProcessSteps,
  initiateAutostartSettings,
  initiateProcessSettings,
}) => {
  const { i18n, t } = useTranslation();
  const [loadingAutosave, setLoadingAutosave] = useState(false);

  const routeMatch = useRouteMatch();

  const isTemplatesPage = useMemo(() => {
    return routeMatch.path === "/templates/:id" || routeMatch.path === "/templates"
  }, [routeMatch])

  const handleChangesRollback = async () => {
    try {
      setLoading(() => true);
      setIsSubmitAvailable(() => false);
      // make rollback request
      if (isTemplatesPage){
        await deleteAutoSaveTemplateRequest(autoSaveProcessSysName?.current)
      } else {
        await deleteAutoSaveTemplate(autoSaveProcessSysName?.current);
      }
      // load current process draft
      let processDraftData = null
      if (isTemplatesPage){
        processDraftData = await getTemplateDraftBySysName(publishedVersionValues.processSysName);
      } else {
        processDraftData = await getTemplateProcessDraftBySysName(publishedVersionValues.processSysName);
      }
      // initiate process form with this draft data
      initiateProcessSteps(processDraftData);
      initiateAutostartSettings(processDraftData);
      const templateSettingsValues = [
        {
          processName: processDraftData.processName,
          description: processDraftData.description,
          language: i18n.language as Language,
        },
        ...processDraftData.languages.filter(({ language }) => language !== i18n.language as Language),
      ] as FormProcess[];
      initiateProcessSettings({
        formProcess: templateSettingsValues,
        processIcon: processDraftData.iconPath,
        processGroup: processDraftData.category,
      });
      // focus on 1st step
      setCurrentProcessIndex(0);
      setCurrentConditionId('');
      setSelectedItemType('processStep');
      setFocusedStepFieldId('');
    } catch (error) {
      NotificationManager.error(t(error?.message || 'errors.somethingIsWrong'), `${t('customProcesses.notifications.error')}!`);
    } finally {
      setLoading(() => false);
    }
  };

  const handleProcessStepsAutoSave = useCallback(async (processSteps: ProcessStep[]): Promise<void> => {
    if (loadingAutosave) {
      return
    }

    setLoadingAutosave(true)

    try {
      if (isTemplatesPage){
        const template = getBeatifiedData(processSteps, true);
        template.visible = false;

        const { processSysName } = await saveTemplateRequest(template);

        if (!autoSaveProcessSysName.current) {
          autoSaveProcessSysName.current = processSysName;
        }
      } else {
        const templateProcess = getBeatifiedData(processSteps, true);

        const { processSysName } = await saveTemplateProcessRequest(templateProcess);

        if (!autoSaveProcessSysName.current) {
          autoSaveProcessSysName.current = processSysName;
        }
      }
    } catch (error) {
      console.log('error', error);
      throw error;
    } finally {
      setLoadingAutosave(false)
    }
  }, [
    conditions,
    initialValues,
    documents,
    parallelBranchesGroups,
    fieldsSettingsForm,
    allProcessStepsFields,
    directManagerSteps,
    performerSelectionSteps,
    isAutostartActive,
    autostartSettings,
    summaryFields,
    autoSaveProcessSysName,
  ]);

  const handleDisplayedVersionToggle = async () => {
    try {
      setLoading(() => true);
      if (isEditDisabled) {
        // Open version with unsaved changed
        // initiate form with saved values
        initiateProcessSteps(editedVersionValues);
        initiateAutostartSettings(editedVersionValues);
        const templateSettingsValues = [
          {
            processName: editedVersionValues.processName,
            description: editedVersionValues.description,
            language: i18n.language as Language,
          },
          ...editedVersionValues.languages.filter(({ language }) => language !== i18n.language as Language),
        ] as FormProcess[];
        initiateProcessSettings({
          formProcess: templateSettingsValues,
          processIcon: editedVersionValues.iconPath,
          processGroup: editedVersionValues.category,
        });
        // enable editing
        setEditDisabled(() => false);
        setVersionToggleOpen(() => false);
        setVersionToggleAnchorEl(null);
      } else {
        // Open published version
        // disable editing
        setEditDisabled(() => true);
        // save current form params as beautified values
        const processSteps = fields as unknown as ProcessStep[];
        const processValues = getBeatifiedData(processSteps);
        setEditedVersionValues(processValues);
        // get published version values
        let publishedProcessData = null;
        if (isTemplatesPage){
          publishedProcessData = await getTemplateBySysName(publishedVersionValues?.processSysName);
        } else {
          publishedProcessData = await getTemplateProcessBySysName(publishedVersionValues?.processSysName);
        }

        // initiate form with published version values
        initiateProcessSteps(publishedProcessData);
        initiateAutostartSettings(publishedProcessData);
        const templateSettingsValues = [
          {
            processName: publishedProcessData.processName,
            description: publishedProcessData.description,
            language: i18n.language as Language,
          },
          ...publishedProcessData.languages.filter(({ language }) => language !== i18n.language as Language),
        ] as FormProcess[];
        initiateProcessSettings({
          formProcess: templateSettingsValues,
          processIcon: publishedProcessData.iconPath,
          processGroup: publishedProcessData.category,
        });
        setVersionToggleOpen(() => false);
      }
    } catch (error) {
      NotificationManager.error(t(error?.message || 'errors.somethingIsWrong'), `${t('customProcesses.notifications.error')}!`);
    } finally {
      setLoading(() => false);
    }
  };

  return {
    handleChangesRollback,
    handleProcessStepsAutoSave,
    handleDisplayedVersionToggle,
  }
}
