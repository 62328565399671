import { ConditionStep, ParallelBranchesGroup, ProcessStep } from './TemplateProcesses.types';

export const getDisplayedStepOrder = (processStep: ProcessStep, conditions: ConditionStep[], parallelBranchesGroups: ParallelBranchesGroup[]) => {
  if (!processStep) {
    return '';
  }
  const previousConditionsList = conditions?.filter(c => c.previousStepOrder < processStep?.stepOrder
    && ((c.hasPositiveBranch && !c.positiveBranch.includes(processStep?.stepOrder)) || !c.hasPositiveBranch)
    && ((c.hasNegativeBranch && !c.negativeBranch.includes(processStep?.stepOrder)) || !c.hasNegativeBranch))
    || [];
  const previousParallelBranchesGroups = parallelBranchesGroups.filter(g => g.previousStepOrder < processStep?.stepOrder && !g.steps.flat().includes(processStep?.stepOrder)) || [];
  const previousConditionsDelta = previousConditionsList.map(condition =>
    [
      ...(condition?.hasPositiveBranch ? condition?.positiveBranch : []),
      ...(condition?.hasNegativeBranch ? condition?.negativeBranch : []),
    ].length - 1)
    .reduce((acc, v) => acc + v, 0);
  const previousParallelBranchesDelta = previousParallelBranchesGroups.map(group => group.steps.flat().length - 1).reduce((acc, v) => acc + v, 0);
  const stepOrderDelta = previousConditionsDelta + previousParallelBranchesDelta;

  if (processStep?.isConditionBranchStep) {
    const stepCondition = conditions.find(c => [...c.positiveBranch, ...c.negativeBranch].includes(processStep?.stepOrder));
    if (stepCondition?.positiveBranch.includes(processStep?.stepOrder)) {
      const stepIndex = stepCondition?.positiveBranch.findIndex(v => v === processStep?.stepOrder);
      const isMultiStepBranch = stepCondition?.positiveBranch.length > 1;
      return `${stepCondition?.previousStepOrder + 1 - stepOrderDelta}.1${isMultiStepBranch ? `.${stepIndex + 1}` : ''}`;
    } else {
      const stepIndex = stepCondition?.negativeBranch.findIndex(v => v === processStep?.stepOrder);
      const isMultiStepBranch = stepCondition?.negativeBranch.length > 1;
      return `${stepCondition?.previousStepOrder + 1 - stepOrderDelta}.2${isMultiStepBranch ? `.${stepIndex + 1}` : ''}`;
    }
  }

  if (processStep?.isParallelBranchesGroupStep) {
    const stepParallelBranchesGroup = parallelBranchesGroups.find(g => g.steps.flat().includes(processStep?.stepOrder));
    const branchIndex = stepParallelBranchesGroup?.steps.findIndex(b => b.some(v => v === processStep?.stepOrder));
    const stepIndex = stepParallelBranchesGroup?.steps[branchIndex].findIndex(v => v === processStep?.stepOrder);
    const isSingleStepBranch = stepParallelBranchesGroup?.steps[branchIndex]?.length === 1;
    return `${stepParallelBranchesGroup?.previousStepOrder + 1 - stepOrderDelta}.${branchIndex + 1}${isSingleStepBranch
                                                                                                     ? ''
                                                                                                     : `.${stepIndex + 1}`}`;
  }

  return processStep?.stepOrder - stepOrderDelta;
};
