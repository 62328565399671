import { useCallback, useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { getTaskHistoryStructured, editDocument, getDocflowDocumentById } from 'api/requests';
import useTaskHistory from 'components/TaskDetails/components/Tabs/HistoryTab/useTaskHistory';
import { useUserProfile } from "hooks";
import { useRequestActiveStep, useSkippedTaskSteps, setActiveStep, useTaskSteps, createAfterSubmitAction, useTaskState } from 'store/requests';

export default (formMethods, _saveDraft: () => Promise<unknown>) => {
  const dispatch = useDispatch();
  const { data: bpmTask } = useTaskState();
  const { id, companyId } = useUserProfile();
  const { parseStructuredHistory } = useTaskHistory({ isActiveTab: false });
  // List of assignees that need to sign docflowDocument at any step during process
  const [signatureRequiredAssignees, setSignatureRequiredAssignees] = useState<Array<string>>([]);
  const [isDocumentEditing, setIsDocumentEditing] = useState<boolean>(false);
  const { activeStep } = useRequestActiveStep();
  const { skippedSteps } = useSkippedTaskSteps();
  const taskSteps = useTaskSteps();

  const isFirstStep = useMemo(() => bpmTask?.currentAction?.stepperOrder === 1, [bpmTask]);

  useEffect(() => {
    if(bpmTask) {
      const docflowField = bpmTask?.attributes?.find(attr =>  attr.name.includes('documentFromDocflow-'));
      if(docflowField) {
        const params = JSON.parse(docflowField?.componentParams || '{}');
        const signRequiredSteps = params?.signRequiredSteps || [];
        if(signRequiredSteps?.length) {
          // getting all process' assignees from task history
          getTaskHistoryStructured(bpmTask?.processInstanceId).then(history => {
            const steps = parseStructuredHistory(history);
            const result = [];
            steps.forEach(step => { // checking which assignee needs to sign document and on which step
              if(signRequiredSteps.includes(step?.data?.stepperOrder) && !result.includes(step?.data?.assignee)) {
                if(step?.data?.stepperOrder === 1 && isFirstStep) {
                  result.push(id);
                } else {
                  result.push(step?.data?.assignee);
                }
              }
            });
            setSignatureRequiredAssignees(result);
          });
        }
      }
    }
  }, [bpmTask]);

  const validateStep = (errors: { [key: string]: unknown; }, attrs: { name?: string; }[]): boolean => {
    let stepValid = true;
    for (const attr of attrs) {
      if (attr?.name && errors[attr.name]) {
        stepValid = false;
        break;
      }
    }
    return stepValid;
  }

  const handleClickNext = async () => {
    formMethods.trigger().then(async () => {
      if (validateStep(formMethods.formState.errors, Object.values(taskSteps[activeStep].attrGroups).flat())) {
        formMethods.clearErrors();
        let nextStep = activeStep + 1;
        while (skippedSteps.includes(nextStep + 1)) {
          nextStep++;
        }

        // we need to replace document's signatories by assignees that need to sign docflowDocument at any step during process
        const docflowDocuments = formMethods.getValues()?.docFlowDocuments || [];
        console.log('docflowDocuments next', docflowDocuments);
        console.log('signatureRequiredAssignees next', signatureRequiredAssignees);
        if(docflowDocuments?.length && signatureRequiredAssignees.length) {
          setIsDocumentEditing(true);
          try {
            const document = await getDocflowDocumentById(docflowDocuments[0].id);
            console.log('document next', document);
            const counterpartyIds = document?.counterparties.length > 0
              ? document.counterparties.map(counterparty => counterparty.id)
              : [];
            await editDocument({
              ...document,
              signatories: signatureRequiredAssignees.map(assignee => ({
                userId: assignee,
                workspaceId: companyId,
                isSigned: false,
                groupId: ''
              })),
              counterpartyIds,
              processId: bpmTask?.processInstanceId
            });
          } catch(err) {
            console.error('Edit document api error; useActionBar file;', err);
          } finally {
            setIsDocumentEditing(false);
          }
        }

        dispatch(setActiveStep(nextStep));
      } else {
        formMethods.trigger();
      }
    });
  };

  const handleClickPrev = useCallback(() => {
    let previousStep = activeStep - 1;
    while (skippedSteps.includes(previousStep + 1)) {
      previousStep -= 1;
    }
    dispatch(setActiveStep(previousStep));
  }, [dispatch, skippedSteps, activeStep]);

  // Deprecated.
  const setCreateNewTask = useCallback(() => dispatch(createAfterSubmitAction(true)), [dispatch]);

  return {
    handleClickNext,
    handleClickPrev,
    setCreateNewTask,
    isDocumentEditing
  }
}
