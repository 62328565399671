import React from 'react';
import { useFormContext } from 'react-hook-form';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import useStyles from './useStyles';
import blueFlag from 'assets/images/emojis/blue-flag.png';
import redFlag from 'assets/images/emojis/red-flag.png';

type Props = {
  setValue: (val: boolean) => void;
  name: string;
  checked: boolean;
  disabled: boolean;
};

export const UrgentToggle = ({
  name,
  setValue,
  checked,
  disabled,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { register } = useFormContext();

  const handleClick = (value: boolean) => {
    if (!disabled) {
      setValue(value);
    }
  }

  return (
    <>
      <input hidden {...register(name)} />
      <div
        className={cn(classes.urgentToggle, { [classes.disabledToggle]: disabled })}
      >
        <div
          className={cn(classes.option, { [classes.selectedOption]: !checked })}
          onClick={() => handleClick(false)}
        >
          <img
            className={classes.optionIcon}
            src={blueFlag}
            alt={t('form_components.urgent.medium')}
          />
          {t('form_components.urgent.medium')}
        </div>
        <div
          className={cn(classes.option, { [classes.selectedOption]: checked })}
          onClick={() => handleClick(true)}
        >
          <img
            className={classes.optionIcon}
            src={redFlag}
            alt={t('form_components.urgent.high')}
          />
          {t('form_components.urgent.high')}
        </div>
      </div>
    </>
  );
};
