import React, {useState, useEffect, useRef, useMemo} from 'react';
import {
  Box,
  Typography,
  Menu,
  Autocomplete,
  TextField,
  Collapse,
  CircularProgress,
  InputAdornment,
  IconButton,
  MenuItem,
  Chip,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import ResetIcon from '@mui/icons-material/Refresh';
import FilterIcon from '@mui/icons-material/FilterAltOutlined';
import RemoveIcon from '@mui/icons-material/Close';
import CalendarIcon from '@mui/icons-material/DateRange';
import classnames from 'classnames';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';

import { Docflow } from 'types';
import { useStyles } from './SelectWithFilter.styles';
import {limitStringLength} from "../../../../../../../utils/user";

export interface FilterComponentProps {
  selectedDocument: Array<Docflow.DocflowDocument | Docflow.ProcessDocument>;
  setSelectedDocument: (documents: Array<Docflow.DocflowDocument | Docflow.ProcessDocument>) => void;
  deleteDocument: (documentId: number) => void;
  documentList: Array<Docflow.DocflowDocument>;
  documentTypes: { id: string; name: string }[];
  counterparties: { id: string; name: string }[];
  onFilterChange: (filters: {
    documentTypeIds: string[];
    counterpartyIds: string[];
    dateRange: { startDate?: Moment; endDate?: Moment };
  }) => void;
  popperWidth?: number | string;
  multiSelect?: boolean;
  label?: string;
  placeholder?: string;
  loadingDocuments?: boolean;
  showCount?: boolean;
  hasError: boolean;
}

const SelectDocumentWithFilter: React.FC<FilterComponentProps> = ({
  selectedDocument,
  setSelectedDocument,
  deleteDocument,
  documentList,
  documentTypes,
  counterparties,
  onFilterChange,
  popperWidth = 300,
  multiSelect = false,
  placeholder,
  label,
  loadingDocuments = false,
  hasError,
  // showCount = false,
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [selectedDocumentTypes, setSelectedDocumentTypes] = useState<string[]>([]);
  const [selectedCounterparties, setSelectedCounterparties] = useState<string[]>([]);
  const [selectedDateRange, setSelectedDateRange] = useState<{ startDate?: Moment | null; endDate?: Moment | null }>({
    startDate: null,
    endDate: null,
  });
  const [selectedPreset, setSelectedPreset] = useState<string | null>(null);
  // const [expandedItem, setExpandedItem] = useState<string | null>(null);
  const [documentTypeExpanded, setDocumentTypeExpanded] = useState<boolean>(false);
  const [counterpartyExpanded, setCounterpartyExpanded] = useState<boolean>(false);
  const [dateExpanded, setDateExpanded] = useState<boolean>(false);
  const [anchorElFilter, setAnchorElFilter] = useState<null | HTMLElement>(null);
  const [anchorElDocumentList, setAnchorElDocumentList] = useState<null | HTMLElement>(null);
  const [menuWidth, setMenuWidth] = useState<number | string>(popperWidth);
  const openFilter = Boolean(anchorElFilter);
  const openDocumentList = Boolean(anchorElDocumentList);
  const [customDate, setCustomDate] = useState<Moment | null>(null);
  const buttonBaseRef = useRef(null);
  const wrapperRef = useRef(null);

  useEffect(() => {
    onFilterChange({
      documentTypeIds: selectedDocumentTypes,
      counterpartyIds: selectedCounterparties,
      dateRange: selectedDateRange,
    });
  }, [selectedDocumentTypes, selectedCounterparties, selectedDateRange]);

  const handleFilterIconClick = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    if (wrapperRef.current) {
      const wrapperWidth = wrapperRef.current.getBoundingClientRect().width;
      setMenuWidth(wrapperWidth);
    }
    event.stopPropagation();
    setAnchorElFilter(buttonBaseRef.current);
    setAnchorElDocumentList(buttonBaseRef.current);
  };

  const handleButtonBaseClick = (event: React.MouseEvent<HTMLElement>) => {
    if (wrapperRef.current) {
      const wrapperWidth = wrapperRef.current.getBoundingClientRect().width;
      setMenuWidth(wrapperWidth);
    }
    setAnchorElDocumentList(event.currentTarget);
  };

  const handleDocumentSelection = (document) => {
    if (multiSelect) {
      if (selectedDocument.some((doc) => doc.id === document.id)) {
        deleteDocument(document.id);
      } else {
        setSelectedDocument([...selectedDocument, document]);
      }
    } else {
      setSelectedDocument([document]);
    }
    setAnchorElDocumentList(null);
  };

  // const handleExpandToggle = (item: string) => {
  //   setExpandedItem(expandedItem === item ? null : item);
  // };

  const handleDateRangeChange = (startDate: Moment, endDate: Moment) => {
    setSelectedDateRange({ startDate, endDate });
  };

  const handlePresetDateSelection = (preset: string) => {
    const today = moment().startOf('day');
    let startDate: Moment;
    let endDate: Moment;

    switch (preset) {
      case 'Today':
        startDate = today;
        endDate = today;
        break;
      case 'Yesterday':
        startDate = moment().subtract(1, 'days').startOf('day');
        endDate = startDate;
        break;
      case 'This week (Mon - Today)':
        startDate = moment().startOf('week');
        endDate = today;
        break;
      case 'Last week':
        startDate = moment().subtract(1, 'week').startOf('week');
        endDate = moment().subtract(1, 'week').endOf('week');
        break;
      default:
        startDate = today;
        endDate = today;
    }

    setSelectedPreset(preset);
    handleDateRangeChange(startDate, endDate);
  };

  const handleCustomDateChange = (date: Moment | null) => {
    if (date) {
      setCustomDate(date);
      handleDateRangeChange(date, date);
      setSelectedPreset('Custom');
    }
  };

  const handleResetFilters = () => {
    setSelectedDocumentTypes([]);
    setSelectedCounterparties([]);
    setSelectedDateRange({ startDate: undefined, endDate: undefined });
    setCustomDate(null);
    setSelectedPreset(null);
  };

  const getPresetSelectionStatus = (presetLabel: string) => {
    return selectedPreset === presetLabel;
  };

  const presets = {
    Today: {
      title: t('fields.filterDocument.Today'),
      startDate: moment().startOf('day'),
      endDate: moment().startOf('day'),
    },
    Yesterday: {
      title: t('fields.filterDocument.Yesterday'),
      startDate: moment().subtract(1, 'days').startOf('day'),
      endDate: moment().subtract(1, 'days').startOf('day'),
    },
    'This week (Mon - Today)': {
      title: t('fields.filterDocument.ThisWeek'),
      startDate: moment().startOf('week'),
      endDate: moment().startOf('day'),
    },
    'Last week': {
      title: t('fields.filterDocument.LastWeek'),
      startDate: moment().subtract(1, 'week').startOf('week'),
      endDate: moment().subtract(1, 'week').endOf('week'),
    },
    Custom: {
      title: t('fields.filterDocument.Custom'),
      startDate: customDate || moment(),
      endDate: customDate || moment(),
    },
  };

  const isAnyFilterSelected = useMemo(() => {
    return (
      selectedDocumentTypes.length > 0 ||
      selectedCounterparties.length > 0 ||
      (selectedDateRange.startDate && selectedDateRange.endDate)
    );
  }, [selectedDocumentTypes, selectedCounterparties, selectedDateRange]);

  return (
    <div ref={wrapperRef} className={classes.wrapper} style={{ width: popperWidth, flexDirection: label ? 'column' : 'row' }}>
      <Typography className={classnames(classes.labelStyle, {
        [classes.labelError]: hasError,
      })}>
        {label}
        {/* {showCount && ` (${selectedDocument.length}/${documentList.length})`} */}
        <span style={{ color: '#D6331F' }}> *</span>
      </Typography>
      <Box ref={buttonBaseRef}
           onClick={handleButtonBaseClick}
           className={classnames(classes.outlinedButton, {
             [classes.outlinedErrorButton]: hasError
           })}

      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <Typography className={classes.documentPlaceholder}>{placeholder}</Typography>
          <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
            <FilterIcon onClick={handleFilterIconClick} className={classes.iconColor} />
            <ExpandMoreIcon className={classes.iconColor} />
          </div>
        </Box>
      </Box>

      <Menu
        className={classes.menu}
        anchorEl={anchorElDocumentList}
        open={openDocumentList}
        onClose={() => setAnchorElDocumentList(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPaper-root': {
            width: menuWidth,
            maxHeight: 210,
            mt: 1,
            border: '1px solid rgba(38, 40, 66, 0.12)',
            borderRadius: '10px',
            boxShadow: '0px 8px 16px 0px rgba(38, 40, 66, 0.1)',
          },
        }}
      >
        {loadingDocuments ? (
          <Box sx={{ padding: 2 }}>
            <CircularProgress size={20} />
          </Box>
        ) : (
          documentList.map((document) => (
            <Box
              key={document.id}
              className={classnames(classes.docItem, {
                [classes.docItemSelected]: selectedDocument.some((doc) => doc.id === document.id),
              })}
              onClick={() => handleDocumentSelection(document)}
            >
              <div>
                <Typography className={classes.docItemTitle}>{document.title}</Typography>
                <Typography className={classes.docItemDescription}> {t('glossary.number')}: {document.number} </Typography>
              </div>
              {selectedDocument.some((doc) => doc.id === document.id) && <CheckIcon />}
            </Box>
            ))
        )}
      </Menu>

      <Menu
        anchorEl={anchorElFilter}
        open={openFilter}
        onClose={() => setAnchorElFilter(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            marginTop: 8,
            marginLeft: 4,
            border: '1px solid rgba(38, 40, 66, 0.12)',
            borderRadius: '10px',
            boxShadow: '0px 8px 16px 0px rgba(38, 40, 66, 0.1)',
          },
        }}
      >
        <MenuItem className={classes.filterItem}>
          <Box className={classes.accordionItem} onClick={() => setDocumentTypeExpanded((prev) => !prev)}>
            <Box className={classes.accordionHeader}>
              <Typography>{t('DocumentProcessFromDocflow.DocumentType')}</Typography>
              <ExpandMoreIcon className={classnames({ [classes.rotateIcon]: documentTypeExpanded })} />
            </Box>
            <Collapse in={documentTypeExpanded} onClick={(e) => e.stopPropagation()}>
              <Box className={classes.accordionContent}>
                <Autocomplete
                  multiple
                  disablePortal
                  limitTags={1}
                  options={documentTypes}
                  classes={{
                    root: classes.autocompleteRoot,
                  }}
                  getOptionLabel={(option) => option.name}
                  value={documentTypes.filter((type) => selectedDocumentTypes.includes(type.id))}
                  onChange={(event, newValue) => {
                    setSelectedDocumentTypes(newValue.map((type) => type.id));
                  }}
                  renderInput={(params) => <TextField {...params} placeholder={t('fields.filterDocument.TypeDocumentPlaceholder')} />}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      className={classnames(classes.filterMenuListItem, {
                        [classes.filterMenuListItemSelected]: selectedDocumentTypes.includes(option.id),
                      })}
                    >
                      {option.name}
                      {selectedDocumentTypes.includes(option.id) && <CheckIcon />}
                    </li>
                  )}
                  renderTags={(value, getTagProps) => (
                    <>
                      {value.slice(0, 1).map((option, index) => (
                        <Chip
                          key={index}
                          label={option.name}
                          sx={{
                            border: '1px solid rgba(38, 40, 66, 0.12)',
                            borderRadius: '6px',
                            background: 'rgb(255, 255, 255)',
                            height: '24px',
                          }}
                          className={classes.tag}
                          deleteIcon={<RemoveIcon />}
                          {...getTagProps({ index })}
                        />
                      ))}
                      {value.length > 1 && <Chip label={`+${value.length - 1}`} className={classes.tag} />}
                    </>
                  )}
                />
              </Box>
            </Collapse>
          </Box>
        </MenuItem>

        <MenuItem className={classes.filterItem}>
          <Box className={classes.accordionItem} onClick={() => setCounterpartyExpanded((prev) => !prev)}>
            <Box className={classes.accordionHeader}>
              <Typography>{t('DocumentProcessFromDocflow.Counterparty')}</Typography>
              <ExpandMoreIcon className={classnames({ [classes.rotateIcon]: counterpartyExpanded })} />
            </Box>
            <Collapse in={counterpartyExpanded} onClick={(e) => e.stopPropagation()}>
              <Box className={classes.accordionContent}>
                <Autocomplete
                  multiple
                  disablePortal
                  limitTags={1}
                  options={counterparties}
                  getOptionLabel={(option) => option.name}
                  value={counterparties.filter((party) => selectedCounterparties.includes(party.id))}
                  onChange={(event, newValue) => {
                    setSelectedCounterparties(newValue.map((party) => party.id));
                  }}
                  classes={{
                    root: classes.autocompleteRoot,
                  }}
                  renderInput={(params) => <TextField {...params} placeholder={t('fields.filterDocument.CounterpartyPlaceholder')} />}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      className={classnames(classes.filterMenuListItem, {
                        [classes.filterMenuListItemSelected]: selectedCounterparties.includes(option.id),
                      })}
                    >
                      {option.name}
                      {selectedCounterparties.includes(option.id) && <CheckIcon />}
                    </li>
                  )}
                  renderTags={(value, getTagProps) => (
                    <>
                      {value.slice(0, 1).map((option, index) => (
                        <Chip
                          key={index}
                          label={option.name}
                          sx={{
                            border: '1px solid rgba(38, 40, 66, 0.12)',
                            borderRadius: '6px',
                            background: 'rgb(255, 255, 255)',
                            height: '24px',
                          }}
                          className={classes.tag}
                          deleteIcon={<RemoveIcon />}
                          {...getTagProps({ index })}
                        />
                      ))}
                      {value.length > 1 && <Chip label={`+${value.length - 1}`} className={classes.tag} />}
                    </>
                  )}
                />
              </Box>
            </Collapse>
          </Box>
        </MenuItem>

        <MenuItem className={classes.filterItem}>
          <Box className={classes.accordionItem} onClick={() => setDateExpanded((prev) => !prev)}>
            <Box className={classes.accordionHeader}>
              <Typography>{t('fields.filterDocument.CreatedDate')}</Typography>
              <ExpandMoreIcon className={classnames({ [classes.rotateIcon]: dateExpanded })} />
            </Box>
            <Collapse in={dateExpanded} onClick={(e) => e.stopPropagation()}>
              <Box>
                {Object.entries(presets).map(([label, { title }]) => (
                  <Box
                    key={label}
                    className={classnames(classes.dateItem, {
                      [classes.dateItemSelected]: getPresetSelectionStatus(label),
                    })}
                    onClick={() => handlePresetDateSelection(label)}
                  >
                    {title} {getPresetSelectionStatus(label) && <CheckIcon />}
                  </Box>
                ))}
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    value={customDate}
                    onChange={(newValue) => {
                      handleCustomDateChange(newValue);
                    }}
                    className={classes.customDate}
                    slots={{
                      // @ts-ignore
                      input: (params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          fullWidth
                          placeholder={t('fields.filterDocument.DatePlaceholder')}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton>
                                  <CalendarIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                            pattern: '\\d{2}\\.\\d{2}\\.\\д{4}',
                          }}
                        />
                      ),
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Collapse>
          </Box>
        </MenuItem>

        {isAnyFilterSelected &&
          <MenuItem className={classes.resetFilterWrapper} onClick={handleResetFilters}>
            <Box className={classes.resetFilterButton}>
              <ResetIcon />
              <Typography>{t('fields.filterDocument.ResetFilters')}</Typography>
            </Box>
          </MenuItem>
        }
      </Menu>
    </div>
  );
};

export default SelectDocumentWithFilter;
