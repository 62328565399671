import React, { useState } from 'react';
import { Box, IconButton, Popover, Typography } from '@mui/material';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  popoverContent: {
    maxWidth: '250px',
    padding: '12px'
  }
});

export const NoUserPopover = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles();
  const open = Boolean(anchorEl);
  const popoverId = open ? 'user-help-popover' : undefined;

  const handleOpenHistory = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseHistory = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton aria-describedby={popoverId} size="small" onClick={handleOpenHistory}>
        <HelpOutlinedIcon fontSize="small" />
      </IconButton>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseHistory}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        // className={classes.popover}
      >
        <Box className={classes.popoverContent}>
          <Typography>
            It seems like there is no user in Okta here! Dont worry it will be updated soon!
          </Typography>
        </Box>
      </Popover>
    </>
  );
}
