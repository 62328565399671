import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ spacing, palette }) => ({
  tabContentWrapper: {
    paddingTop: 20,
    paddingRight: 24,
  },
  documentsListSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    marginBottom: 16,
  },
  documentsListSectionTitle: {
    margin: 0,
    padding: 0,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '18px',
    color: '#6D6E85',
  },
  documentsListWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
}));
