import { baseV1ApiClient } from '../clients';
import { commonExceptionHandler } from '../handlers';

export const getLocalizationByHoldingId = async (lang, module) => {
  try {
    const workspaceData = JSON.parse(localStorage.getItem('workspace'));
    const holdingId = workspaceData?.holdingId || '';

    const response = await baseV1ApiClient.request({
      method: "GET",
      url: '/dictionary-api/localization/default/all',
      params: {
        lang,
        module,
        holdingId
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
}

export const addTranslationsByHoldingId = async (lang, module, translations) => {
  try {
    const workspaceData = JSON.parse(localStorage.getItem('workspace'));
    const holdingId = workspaceData?.holdingId || '';

    const response = await baseV1ApiClient.request({
      method: "POST",
      url: '/dictionary-api/localization/default/decode/json',
      params: {
        lang,
        module,
        holdingId
      },
      data: translations
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
}

export const getAvailableLanguages = async () => {
  try {
    const response = await baseV1ApiClient.request({
      method: "GET",
      url: '/dictionary-api/language/all',
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
}
