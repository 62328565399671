import React, { memo, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import {Box, CircularProgress, TextField} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTranslation } from 'react-i18next';

import { UserInfo } from 'components';

import { useUserProfile } from 'hooks';
import { useUsersState } from 'store/users';
import { compareUsers } from 'utils/user';
import { getHCMSUsersListByCompany } from 'api/requests';

import { HcmsUserModel, UserType } from 'types';
import { User } from 'models/User.model';

type Props = {
  name?: string;
  value: {
    [key: string]: string;
  };
  label: string;
  margin?: string;
  error?: string;
  disabled?: boolean;
  valueInBpmTask?: string;
  companyId?: string;
  onSelect: (val: string | { [key: string]: string; }) => void;
  preventClearOnLoad?: boolean;
}

export const SelectUsers = memo(({
  name,
  onSelect,
  label = '',
  error,
  value = null,
  margin = '',
  disabled = false,
  valueInBpmTask = '',
  companyId,
  preventClearOnLoad = false
}: Props) => {
  const { users } = useUsersState();
  const { id: profileId } = useUserProfile();

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [usersList, setUsersList] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const previousCompanyId = useRef('');

  const loadProfileCompanyUsers = async (companyId?: string): Promise<void> => {
    let clearValue = false;
    try {
      if (companyId && previousCompanyId.current !== companyId) {
        previousCompanyId.current = companyId;
        setIsLoading(true);

        const companyUsersList = await getHCMSUsersListByCompany(companyId);
        const mappedUsersList = companyUsersList
          .map((data: HcmsUserModel) => User.from(data))
          .map((user: User) => user.data());
        clearValue = !mappedUsersList.find(user => user?.id === value?.id || (!value?.id && user?.id === valueInBpmTask));

        setUsersList(mappedUsersList);
      }
    } catch (error) {
      setUsersList([]);
    } finally {
      if (!preventClearOnLoad && clearValue) {
        handleClearValue();
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadProfileCompanyUsers(companyId);
  }, [value, companyId, setUsersList, users]);

  const userOptions = useMemo(() =>
    usersList
      .sort(compareUsers)
      .map((user: UserType) => ({
        ...user,
        letter: user?.fullName[0]?.toUpperCase() || '',
      })),
    [usersList]);

  const getOptionSelected = (option, value) =>
    value && (option.logName === value.logName || option.id === value.id);

  const getOptionLabel = option => {
    const user = userOptions.find(({ logName, id }) =>
      logName === option.logName || id === option.id);

    if (user) {
      return user.fullName;
    }

    return '';
  };

  const handleClearValue = (): void => {
    onSelect(null);
    setInputValue('');
  }

  const onChange = (_, val): void => {
    onSelect(val);
    setInputValue(value && value.fullName ? value.fullName : '');
  };

  const onInputChange = (_, val): void => {
    setInputValue(val);
  };

  useEffect(() => {
    if (value) {
      setInputValue(value?.fullName || '');
    }
  }, [value]);

  const textFieldOptions: { margin?: any; } = {};
  if (margin) {
    textFieldOptions.margin = margin;
  }

  const renderInput = params => (
    <TextField
      {...params}
      {...textFieldOptions}
      label={label}
      placeholder={t('form_components.select.placeholder')}
      inputProps={{ ...params.inputProps, 'data-selenium': name }}
      error={Boolean(error)}
      helperText={error ? error : ''}
    />
  );

  const getIcon = (): ReactElement => {
    if (isLoading) {
      return (
        <Box mr={1}>
          <CircularProgress size={16}/>
        </Box>
      );
    }

    return <ArrowDropDownIcon />;
  };

  return (
    <Autocomplete
      fullWidth
      forcePopupIcon
      options={userOptions}
      disabled={disabled || isLoading}
      value={value}
      inputValue={inputValue}
      onChange={onChange}
      getOptionLabel={getOptionLabel}
      groupBy={option => option.letter}
      onInputChange={onInputChange}
      popupIcon={getIcon()}
      isOptionEqualToValue={getOptionSelected}
      renderInput={params => renderInput(params)}
      renderOption={(props, option) => <li {...props}><UserInfo user={option}/></li>}
    />
  );
});
