import { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { parseJSON } from 'utils/general';
import { useTaskState } from 'store/requests';
import { AttributeType, TaskParametersType } from 'types';

export const useFieldValueChange = (attribute: TaskParametersType) => {
  const { watch, getValues, setValue } = useFormContext();
  const { data: bpmTask } = useTaskState();

  const formValues = getValues();
  const attributeValue = watch(attribute.name);

  const behaviour = useMemo(() =>
      parseJSON(attribute.behaviour),
    [attribute.behaviour]);

  const changeValueOnFields = useMemo(() => {
    const { changeValueOn } = behaviour;

    if (bpmTask && Array.isArray(changeValueOn) && changeValueOn.length) {
      return changeValueOn.map(changeValueOnCondition => {
        const {
          selectedComponentName,
          selectedComponentNewValue,
          currentComponentChangeValue,
          currentComponentChangeValueCondition
        } = changeValueOnCondition;

        const selectedAttribute = bpmTask.getAttributeBySysName(selectedComponentName);

        return {
          selectedAttribute,
          selectedComponentNewValue,
          currentComponentChangeValue,
          currentComponentChangeValueCondition
        };
      });
    }

    return [];
  }, [bpmTask, behaviour]);

  const isComponentChangeValueConditionCorrect = useCallback(
    ({
       currentComponentChangeValueCondition,
       currentComponentChangeValue,
       convertedAttributeValue
     }) => {
      switch (currentComponentChangeValueCondition) {
        case '===':
          return convertedAttributeValue === currentComponentChangeValue;

        case '!==':
          return convertedAttributeValue !== currentComponentChangeValue;

        case '>':
          return convertedAttributeValue > currentComponentChangeValue;

        case '<':
          return convertedAttributeValue < currentComponentChangeValue;

        default:
          return false;
      }
    },
    []
  );

  const getBooleanValueFromString = useCallback(booleanValue => {
    switch (booleanValue) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        return false;
    }
  }, []);

  const convertValueByType = useCallback(
    attributeValue => {
      switch (attribute.type) {
        case AttributeType.BOOLEAN:
          return getBooleanValueFromString(attributeValue);

        case AttributeType.DOUBLE: {
          const floatNumberWithoutSpaces = `${attributeValue}`.replace(/ /g, '');
          return parseFloat(floatNumberWithoutSpaces);
        }

        case AttributeType.INTEGER:
          return parseInt(attributeValue);

        case AttributeType.DATETIME:
          return new Date(attributeValue);

        default:
          return attributeValue;
      }
    },
    [attribute.type, getBooleanValueFromString]);

  useEffect(() => {
    changeValueOnFields.length &&
    changeValueOnFields.forEach((changeValueOnCondition) => {
      const {
        selectedAttribute,
        selectedComponentNewValue,
        currentComponentChangeValue,
        currentComponentChangeValueCondition,
      } = changeValueOnCondition;

      const convertedAttributeValue = convertValueByType(attributeValue);
      const selectedAttributeValue = getValues(selectedAttribute.name);

      const isConditionCorrect = isComponentChangeValueConditionCorrect({
        currentComponentChangeValueCondition,
        currentComponentChangeValue,
        convertedAttributeValue,
      });

      isConditionCorrect &&
      selectedAttributeValue !== selectedComponentNewValue &&
      setValue(selectedAttribute.name, selectedComponentNewValue);
    });
  }, [
    attributeValue,
    changeValueOnFields,
    convertValueByType,
    isComponentChangeValueConditionCorrect,
    setValue,
    getValues,
    formValues,
  ]);
}
