import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  formGroupTitle: {
    width: 120,
    minWidth: 120,
    paddingRight: theme.spacing(4),
  },
}));

export default useStyles;
