import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Box, Checkbox, FormControlLabel, Radio, Tooltip, Typography } from '@mui/material';
import cn from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import Scrollbars from 'react-custom-scrollbars';

import { useUsersState } from 'store/users';

import useDirectManagerCheckboxStyles from './RequestInitiator.useStyles';
import { ConditionStep, ParallelBranchesGroup, ProcessStep } from '../../TemplateProcesses.types';

import InfoIcon from 'assets/images/icons/info_outline.svg';
import { ReactComponent as CheckboxUncheckedIcon } from 'assets/images/icons/checkbox-unchecked.svg';
import { ReactComponent as CheckboxCheckedIcon } from 'assets/images/icons/checkbox-checked.svg';

interface DirectManagerCheckboxProps {
  currentStep: ProcessStep;
  processSteps: ProcessStep[];
  conditions: ConditionStep[];
  parallelBranchesGroups: ParallelBranchesGroup[];
  isRequestInitiatorStep: boolean;
  isAutostartActive: boolean;
  requestInitiatorSteps: number[];
  disabled?: boolean;
  setCurrentStepDMs: (value: string[]) => void;
  handleRequestInitiatorToggle: (value: boolean) => void;
  onSelect?: (newState: string) => void;
  isSelected?: boolean;
  handleDropdownClear?: () => void;
}

export const RequestInitiator = ({
  currentStep,
  processSteps = [],
  conditions = [],
  parallelBranchesGroups = [],
                                   isRequestInitiatorStep = false,
  isAutostartActive = false,
                                   requestInitiatorSteps = [],
  disabled,
  setCurrentStepDMs,
                                   handleRequestInitiatorToggle,
                                   handleDropdownClear,
                                   onSelect,
                                   isSelected
}: DirectManagerCheckboxProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useDirectManagerCheckboxStyles();
  const { users } = useUsersState();


  const [assigneesDMs, setAssigneesDMs] = useState<string[]>([]);
  const [assigneesWithoutDMs, setAssigneesWithoutDMs] = useState<string[]>([]);

  const helpTooltipContent = <div className={classes.helpTooltipContent}>
    <p>{t('customProcesses.creationPage.processForm.requestInitiator.help1')}</p>

  </div>;


  useEffect(() => {
    if (currentStep?.stepOrder === 1 || !isRequestInitiatorStep) {
      return;
    }

    let startStepOrder = currentStep?.stepOrder - 1;
    const checkStepsList = [];
    if (currentStep?.isConditionBranchStep) {
      // get condition
      const currentStepCondition = conditions?.find(c => [
        ...(c?.hasPositiveBranch ? c?.positiveBranch : []),
        ...(c?.hasNegativeBranch ? c.negativeBranch : []),
      ].includes(currentStep?.stepOrder));
      // set startStepOrder to condition previous step
      startStepOrder = currentStepCondition?.previousStepOrder || currentStep?.stepOrder - 1;
      // add steps from current branch if current step is not the first step in this branch
      const isPositiveBranchStep = (currentStepCondition?.hasPositiveBranch && currentStepCondition?.positiveBranch.includes(currentStep?.stepOrder));
      const branchSteps = (isPositiveBranchStep ? currentStepCondition?.positiveBranch : currentStepCondition?.negativeBranch) || [];
      if (branchSteps) {
        const stepPosition = branchSteps.findIndex(v => v === currentStep?.stepOrder);
        if (stepPosition > 0) {
          const branchStepsBeforeCurrent = branchSteps.slice(0, stepPosition);
          checkStepsList.push(...branchStepsBeforeCurrent.reverse());
        }
      }
    }
    if (currentStep?.isParallelBranchesGroupStep) {
      // get parallel steps group
      const currentStepParallelStepsGroup = parallelBranchesGroups?.find(g => g.steps.flat().includes(currentStep?.stepOrder));
      // set startStepOrder to group previous step
      startStepOrder = currentStepParallelStepsGroup?.previousStepOrder || currentStep?.stepOrder - 1;
      // add steps from current branch if current step is not the first step in this branch
      const branchSteps = currentStepParallelStepsGroup?.steps.find(b => b.includes(currentStep?.stepOrder));
      if (branchSteps) {
        const stepPosition = branchSteps.findIndex(v => v === currentStep?.stepOrder);
        if (stepPosition > 0) {
          const branchStepsBeforeCurrent = branchSteps.slice(0, stepPosition);
          checkStepsList.push(...branchStepsBeforeCurrent.reverse());
        }
      }
    }
    checkStepsList.push(startStepOrder);
    for (let i = startStepOrder - 1; i > 0; i--) {
      checkStepsList.push(i);
    }
    let chainSteps = [];
    for (const checkStepOrder of checkStepsList) {
      if (requestInitiatorSteps.includes(checkStepOrder)) {
        chainSteps.push(checkStepOrder);
      } else {
        chainSteps.push(checkStepOrder);
        break;
      }
    }
    chainSteps = chainSteps.reverse();

    let previousStepsDMs = [];
    let displayAssignees = [];
    let displayWithoutDMs = [];
    for (let i = 0; i < chainSteps.length; i++) {
      const stepOrder = chainSteps[i];
      const stepInfo = processSteps.find(step => step?.stepOrder === stepOrder);

      const stepUsers = previousStepsDMs?.length
                        ? previousStepsDMs
                        : stepInfo?.assigneeId?.map(userId => users[userId]).filter(Boolean);
      const stepsDMs = stepUsers?.filter(user => user.reportsToId).map(user => users[user.reportsToId]).filter(Boolean) || [];
      const stepUsersWithoutDms = stepUsers?.filter(user => !user.reportsToId).map(user => user.fullName) || [];

      previousStepsDMs = stepsDMs;
      displayAssignees = stepUsers;
      displayWithoutDMs = stepUsersWithoutDms;
    }
    const uniqueAssigneesDMs = displayAssignees?.map(user => user.reportsTo).filter((v, i, a) => Boolean(v) && a.indexOf(v) === i);
    const uniqueAssigneesDMsIds = displayAssignees?.map(user => user.reportsToId).filter((v, i, a) => Boolean(v) && a.indexOf(v) === i);
    const uniqueAssigneesWithoutDms = displayWithoutDMs?.filter((v, i, a) => Boolean(v) && a.indexOf(v) === i);
    setAssigneesDMs(uniqueAssigneesDMs);
    setCurrentStepDMs(uniqueAssigneesDMsIds);
    setAssigneesWithoutDMs(uniqueAssigneesWithoutDms);
  }, [currentStep, processSteps, isRequestInitiatorStep, requestInitiatorSteps, setCurrentStepDMs]);

  const directManagersTooltipContent = useMemo(() => (
    <Scrollbars className={classes.usersTooltipContent}>{
      <ul>
        {assigneesDMs?.map(user => <li>{user}</li>)}
      </ul>
    }</Scrollbars>), [assigneesDMs]);

  const assigneesWithoutDirectManagersTooltipContent = useMemo(() => (
    <Scrollbars className={classes.usersTooltipContent}>{
      <ul>
        {assigneesWithoutDMs?.map(user => <li>{user}</li>)}
      </ul>
    }</Scrollbars>), [assigneesWithoutDMs]);

  return (
    <Box className={cn(classes.DirectManagerCheckbox)}>
      {(currentStep?.stepOrder > 1) &&
        !currentStep?.parallel &&
        !(currentStep?.stepOrder > 1 && processSteps[currentStep?.stepOrder - 2].parallel) && (
          <>
            <Box mt={2} className={classes.checkboxWrapper}>
              <FormControlLabel
                //className={classes.checkbox}
                control={
                  <Radio
                    checked={!disabled && isSelected}
                    //color="primary"
                    disabled={disabled}
                    classes={{
                      root: classes.root,
                      checked: classes.checked,
                      disabled: classes.disabled
                    }}
                    onChange={() => {
                      onSelect('requestInitiator')
                      handleDropdownClear()
                    }}
                  />
                }
                classes={{ label: classes.checkboxLabel }}
                label={t('customProcesses.creationPage.processForm.fields.requestInitiatorCheckbox')}
              />

              {/*{!!assigneesDMs?.length && isRequestInitiatorStep && (*/}
              {/*  <Tooltip*/}
              {/*    classes={{*/}
              {/*      popper: classes.tooltipPopper,*/}
              {/*      tooltip: classes.usersListTooltip,*/}
              {/*    }}*/}
              {/*    arrow*/}
              {/*    placement="bottom"*/}
              {/*    title={directManagersTooltipContent}*/}
              {/*    leaveDelay={500}*/}
              {/*  >*/}
              {/*    <Typography className={classes.defaultCursor}> ({assigneesDMs.length})</Typography>*/}
              {/*  </Tooltip>*/}
              {/*)}*/}

              <Tooltip
                classes={{
                  popper: classes.tooltipPopper,
                  tooltip: classes.helpTooltip,
                }}
                arrow
                placement="bottom"
                title={helpTooltipContent}
                leaveDelay={500}
              >
                <img src={InfoIcon} className={classes.infoIcon} />
              </Tooltip>
            </Box>
          </>
        )}
    </Box>
  );
};

