import makeStyles from '@mui/styles/makeStyles';

type Props = {
  isModalHidden: boolean
}

export default makeStyles(() => ({
  modalBody: {
    width: 500,
    background: '#fff',
    border: "1px solid rgba(38, 40, 66, 0.08)",
    boxShadow: "0px 16px 32px rgba(38, 40, 66, 0.08)",
    borderRadius: 12,

    overflow: 'hidden',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 99,
    visibility: ({isModalHidden}: Props) => isModalHidden ? "hidden" : "visible"
  },
  modalHeader: {
    padding: 12,
    paddingLeft: 24,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    borderBottom: "1px solid rgba(38, 40, 66, 0.08)"
  },
  modalContent: {
    padding: 24,
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'end',
    gap: 8,
    padding: "12px 16px",
    background: '#F0F0F7',
    borderTop: '1px solid rgba(38, 40, 66, 0.08);'
  },
  modalButton: {
    borderRadius: 10,
    height: 40,
  },
  closeIcon: {
    '& > path': {
      fill: '#6D7C8B'
    }
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    color: '#262842'
  },
  description: {
    marginBottom: 60,
    fontSize: 16,
    color: '#8B8C9E',
  },
  currentAssigneeInfo: {
    padding: 12,
    border: "1px solid rgba(38, 40, 66, 0.12)",
    borderRadius: 8,
    marginBottom: 16
  },
  stepName: {
    marginBottom: 8,
    fontSize: 14,
    color: "#6D6E85",
    fontWeight: 500,

    "& span": {
      fontWeight: 400,
      color: "#262842"
    }
  },
  userSelect: {
    "& > p": {
      fontWeight: 500,
      fontSize: 14,
      color: "#6D6E85"
    }
  }
}))
