import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ palette, spacing }) => ({
  root: {
    clear: 'both',
  },
  groupTitle: {
    fontSize: 14,
    fontWeight: 600,
    color: palette.grey[600],
    marginBottom: spacing(1),
  },
  groupFileTypes: {
    fontSize: 12,
    fontWeight: 400,
    color: palette.grey[500],
  },
}));
