import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'relative',
  },
  content: {
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 auto',
    position: 'relative',
    paddingRight: spacing(6),
    transitionProperty: 'width, max-width',
    transitionDuration: '700ms',
    zIndex: ({ zIndexContent }: { zIndexContent: number }) =>
      zIndexContent || 1,
  },
  contentRequests: {
    width: 'calc(100%)',
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 auto',
    position: 'relative',
    zIndex: 1,
    paddingRight: 24,
    transitionProperty: 'width, max-width',
    transitionDuration: '700ms',
  },
  headerRequests: {
    paddingLeft: 24,
    paddingRight: 0,
  },
  mainTabsRequests: {
    padding: '0 24px',
  },
  contentWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  dataViewWrapper: {
    width: '100%',
    height: 'calc(100vh - 48px - 24px)',
    position: 'absolute',
  },
  backgroundPartWrapper: {
    width: 'calc(100% + 48px)',
    // minHeight: '100%',
    flexGrow: 1,
    marginLeft: -24,
    background: '#F7F8FC',
  },
  backgroundPartWrapperRequests: {
    width: 'calc(100% + 24px)',
    flexGrow: 1,
    background: '#F7F8FC',
    display: 'flex',
    flexDirection: 'column',
  },
  backgroundPartWrapperApprovals: {
    width: 'calc(100% + 48px)',
    flexGrow: 1,
    marginLeft: -24,
    background: '#F7F8FC',
    display: 'flex',
    flexDirection: 'column',
  },
}));
