import { handleActions } from 'redux-actions';
import { MONITORING_ACTIONS } from './monitoring.actions';

export const initState = {
  list: {
    data: null,
    error: '',
    loading: false,
    currentPage: 1,
    pageSize: 10,
    completed: false,
    needRefresh: false,
  },
  counters: {
    active: -1,
    completed: -1
  },
  visiblePanel: false,
  selected: null as string
};

type InitState = typeof initState;

const { 
  GET_MONITORING_LIST, 
  GET_ACTIVE_MONITORING_COUNT, 
  SET_ACTIVE_MONITORING_COUNT, 
  TOGGLE_MONITORING_PANEL
} = MONITORING_ACTIONS;

export default handleActions({
  [GET_MONITORING_LIST]: (state: InitState, {payload}) => ({
    ...state,
    list: {
      ...state.list,
      data: payload
    }
  }),
  [GET_ACTIVE_MONITORING_COUNT]: (state: InitState, { payload }) => ({
    ...state,
    counters: {
      ...state.counters,
      active: payload
    },
  }),
  [SET_ACTIVE_MONITORING_COUNT]: (state: InitState, { payload }) => ({
    ...state,
    counters: {
      ...state.counters,
      active: payload
    },
  }),
  [TOGGLE_MONITORING_PANEL]: (state: InitState) => ({
    ...state,
    visiblePanel: !state.visiblePanel,
  }),
}, initState);