import { ConditionStep } from '../../TemplateProcesses.types';

export const isFieldFromCurrentConditionBranch = (
  firstAppearanceStep: number,
  currentStepOrder: number,
  conditions: ConditionStep[]
) => {
  if (!currentStepOrder || !firstAppearanceStep) return false;

  const currentCondition = conditions.find(
    (condition) =>
      condition.positiveBranch.includes(currentStepOrder) ||
      condition.negativeBranch.includes(currentStepOrder)
  );

  if (!currentCondition) return false;

  return (
    currentCondition.positiveBranch.includes(firstAppearanceStep) ||
    currentCondition.negativeBranch.includes(firstAppearanceStep)
  );
};
