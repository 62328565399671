import {baseV1ApiClient} from "../clients";
import {commonExceptionHandler} from "../handlers";
import {getProfileCompanyDataFromLocalStorage} from "utils/user";

import {CompanyRoleInfo, CompanyUserInfo, UserPermission} from "types";

const EXTENDED_TIMEOUT = 2 * 60 * 1000

export const getUserACLSettingsRequest = async (): Promise<CompanyUserInfo[]> => {
  try {
    const { id: companyId } = getProfileCompanyDataFromLocalStorage();

    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: 'dms-interlayer-acl/company/settings/personal/info/user',
      headers: {
        companyId,
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
}

export const getCompanySettingsRoles = async (): Promise<CompanyRoleInfo[]> => {
  try {
    const { id: companyId } = getProfileCompanyDataFromLocalStorage();

    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: 'dms-interlayer-acl/company/settings/roles/info',
      timeout: 2 * 60 * 1000,
      headers: {
        companyId,
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
}

export const getCompanySettingsUsers = async (): Promise<CompanyUserInfo[]> => {
  try {
    const { id: companyId } = getProfileCompanyDataFromLocalStorage();

    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: `dms-interlayer-acl/company/settings/personal/info`,
      timeout: 2 * 60 * 1000,
      headers: {
        companyId,
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
}

export const getAllUserPermissions = async (): Promise<UserPermission[]> => {
  try {
    const { id: companyId } = getProfileCompanyDataFromLocalStorage();

    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: `dms-interlayer-acl/directory/permissions/all`,
      headers: {
        companyId,
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
}

export const changeSettingsByRole = async ({ data }): Promise<string> => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'POST',
      url: `dms-interlayer-acl/company/settings/roles`,
      data,
      timeout: EXTENDED_TIMEOUT
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
}

export const changePersonalSettings = async ({ data }): Promise<string> => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'POST',
      url: `dms-interlayer-acl/company/settings/personal`,
      data
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
}

export const getInfoByRoleChangeSettings = async ({ data }): Promise<{
  personalAccessSettings: number;
  personalStartProcessSettings: number;
}> => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'POST',
      url: `dms-interlayer-acl/company/settings/roles/users/number`,
      data,
      timeout: EXTENDED_TIMEOUT
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
}

export const getDefaultCompanyProcesses = async (): Promise<{
  id: string;
  publicProcesses: string[];
  notAvailableCompanies: string[];
}> => {
  try {
    const response = await baseV1ApiClient.request({
      method: "GET",
      url: 'dms-interlayer-acl/start/access/default/processes'
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
}

export const checkAccessToAdminSettings = async (): Promise<boolean> => {
  try {
    const { id: companyId } = getProfileCompanyDataFromLocalStorage();

    const response = await baseV1ApiClient.request({
      method: "GET",
      url: 'dms-interlayer-acl/access/V3/admin',
      headers: {
        companyId
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
}

export const checkUserPermission = async (permissionName: string): Promise<boolean> => {
  try {
    const { id: userId } = JSON.parse(localStorage.getItem('profile'));

    const response = await baseV1ApiClient.request({
      method: "POST",
      url: 'dms-interlayer-acl/access/V3/permissions',
      params: {
        userId,
        permissionName
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
}
