import makeStyles from '@mui/styles/makeStyles';

const useReadOnlyStyles = makeStyles(({ spacing, palette }) => ({
  titleVariant: {
    fontSize: 16,
    fontWeight: 600,
    color: palette.grey[700],
  },
  taskDetailsReadOnlyWithoutHint: {
    width: '100% !important',
    maxWidth: 600,
  },
}));

export default useReadOnlyStyles;
