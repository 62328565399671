import React, { memo } from 'react';
import { FormControl, Typography } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';

import { DatePicker } from 'components';

import useDate from './useDate';

type Props = {
  hint: string;
  name: string;
  rules?: {
    [key: string]: any;
  };
  params: {
    readOnly: boolean;
    disablePast: boolean;
    disableFuture: boolean;
    disableWeekends: boolean;
    disableHolidays: boolean;
    disablePresent: boolean;
    comparisonComponentSysName: string;
    comparisonComponentName: string;
    comparisonType: string;
    placeholder?: string;
  };
  isDisabled?: boolean;
};

export const Date = memo(({ hint, name, rules, params, isDisabled = false }: Props) => {
  const {
    watch,
    control,
    formState: { errors },
  } = useFormContext();


  const { readOnly, disablePast, disableFuture } = params;

  const { rules: componentRules, defaultValue, handleChange, checkDateDisabled } = useDate({
    name,
    rules,
    params,
  });

  return (
    <FormControl error={!!errors[name]}>
      <Typography component={'label'}>{hint}</Typography>
      <Controller
        name={name}
        control={control}
        rules={componentRules}
        defaultValue={watch(name)}
        render={() => (
          <DatePicker
            fullWidth
            margin="none"
            name={name}
            // label={hint}
            value={defaultValue}
            placeholder={params?.placeholder || ''}
            error={!!errors[name]}
            helperText={errors[name] && errors[name].message}
            disabled={!!readOnly || isDisabled}
            disablePast={!!disablePast}
            disableFuture={!!disableFuture}
            checkDateDisabled={checkDateDisabled}
            onChange={handleChange}
          />
        )}
      />
    </FormControl>
  );
});
