import { useFormContext } from 'react-hook-form';
import { useState } from 'react';

import { getCompanyDataById } from 'api/requests';
import { useTaskState } from 'store/requests';

const useCompanySelect = (name, innerFields) => {
  const {
    control,
    setValue: setFormValue,
    watch,
    trigger,
    formState: {
      errors,
    },
  } = useFormContext();
  const {data: bpmTask} = useTaskState();

  const [value, setValue] = useState(watch(name) ? {shortName: watch(name)} : {shortName: ''});

  const handleChange = async (val, isDefaultValueSetting=false, isClearing = false) => {
    // условие предотвращает перезапись уже записанного в форме значения
    // значением выбранной в профиле компании
    if (val && !(isDefaultValueSetting && !!value?.shortName && !bpmTask?.isFirstStep)) {
      setValue(val);
      setFormValue(name, val?.shortName);

      if (!Object.keys(val).includes('fields')) {
        val.fields = {...val};
      }

      const innerFieldsList = Object.keys(val.fields);
      if (!innerFields.every(field => innerFieldsList.includes(field[1]))) {
        const companyData = await getCompanyDataById(val.id);
        val.fields = companyData ? {...val.fields, ...companyData.fields} : val.fields;
      }

      innerFields.length &&
      innerFields.forEach(field => setFormValue(field[0], val.fields[field[1]]));

      errors[name] && trigger(name);
    }

    if (isClearing) {
      setValue({ shortName: '' });
      setFormValue(name, val);
      innerFields.length && innerFields.forEach(field => setFormValue(field[0], ''));
    }
  };

  return {
    handleChange,
    value,
    control,
    error: errors[name]?.message || ''
  };
};

export default useCompanySelect;
