import React, { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { Box, Button, IconButton, Modal, TextField, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import cn from 'classnames';

import { AppTextField, CloseButton, Spinner } from 'components';
import { TemplateProcess } from '../../TemplateProcesses.types';
import { GroupSelect } from "pages/TemplateProcesses/components/GroupSelect"
import WarningIcon from 'assets/images/icons/warning-sign.svg';

import useStyles from './SaveAsTemplateDialog.useStyles';
import { ProcessContextType } from 'types';
import ProcessContext from 'contexts/ProcessContext';
interface SaveAsTemplateDialogProps {
  processData: Partial<TemplateProcess>,
  isOpen: boolean;
  onClose: () => void;
  onSave: (templateName: string, templateDescription: string, category: string, open?: boolean) => Promise<boolean>;
}

const CUSTOM_PROCESSES_ID = "custom_process_template";

const SUFFIX = ' - template';

const MAX_NAME_LENGTH = 255;
const MAX_DESCRIPTION_LENGTH = 500;

export const SaveAsTemplateDialog = ({
  processData,
  isOpen,
  onClose,
  onSave,
}: SaveAsTemplateDialogProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);
  const [isSaveOpenLoading, setIsSaveOpenLoading] = useState<boolean>(false);
  const [category, setCategory] = useState(CUSTOM_PROCESSES_ID)
  const { groups }: Partial<ProcessContextType> = useContext(ProcessContext);


  const handleSave = async (open?: true) => {
    if (open) {
      setIsSaveOpenLoading(true);
    } else {
      setIsSaveLoading(true);
    }

    try {
      const isSuccess = await onSave(name, description, category, open);

      if (isSuccess) {
        onClose();
      }
    } catch (error) {
      setIsSaveLoading(false);
      setIsSaveOpenLoading(false)
    } finally {
      setIsSaveLoading(false);
      setIsSaveOpenLoading(false)
    }

  };

  const handleNameChange = (value: string) => {
    setName(value);
  };

  const handleDescriptionChange = (value: string) => {
    setDescription(value);
  };

  useEffect(() => {
    if (isOpen) {
      setName(processData?.processName + SUFFIX);
      setDescription("")

      if (groups?.find(g => g.sysName === processData?.category)?.defaultGroup){
        setCategory(processData?.category);
      } else {
        setCategory(CUSTOM_PROCESSES_ID)
      }
    }
  }, [processData, isOpen, groups]);

  const isButtonDisabled = useMemo(() => {
    return name.trim().length === 0
    || description.trim().length === 0
    || name?.length > MAX_NAME_LENGTH
    || description?.length > MAX_DESCRIPTION_LENGTH
    || category?.trim().length === 0
  }, [name, description, category]);

  return (
    <Modal open={isOpen} disableEnforceFocus>
      <Box className={classes.modalBody}>
        <div className={classes.modalHeader}>
          <Typography className={classes.title}>
            {t('templates.saveAsTemplate.title')}
          </Typography>

          <CloseButton onClick={onClose} />
        </div>

        <div className={classes.modalContent}>
          <Box mb="16px">
            <GroupSelect
              label={t('customProcesses.creationPage.fields.category')}
              onlyDefaultGroups
              selectedGroupSysName={category}
              handleGroupSelect={groupSysName => setCategory(groupSysName)}
            />
          </Box>


          <AppTextField
            label={t('customProcesses.copyProcessDialog.fields.name')}
            placeholder={''}
            className={cn(classes.nameInput, classes.nameInputRequired)}
            fullWidth
            value={name}
            onChange={(e) => handleNameChange(e.target.value)}
            error={name?.length > MAX_NAME_LENGTH}
            helperText={
              <div className={classes.inputErrorWrapper}>
                <div>
                  {
                    name?.length > MAX_NAME_LENGTH
                    ? <div className={classes.helperTextError}>
                      <img src={WarningIcon} alt="warning"/>
                      {t('customProcesses.creationPage.processFormErrors.valueTooLong')}
                    </div>
                    : null
                  }
                </div>

                <div className={classes.helperTextError}>
                  {
                    name?.length > MAX_NAME_LENGTH
                    ? `${name?.length}/${MAX_NAME_LENGTH}`
                    : null
                  }
                </div>
              </div>
            }
          />

          <AppTextField
            label={t('fields.description')}
            placeholder={''}
            value={description}
            onChange={(e) => handleDescriptionChange(e.target.value)}
            className={cn(classes.nameInput, classes.nameInputRequired)}
            fullWidth
            multiline
            rows={4}
            minRows={4}
            maxRows={4}
            size="medium"
            error={description?.length > MAX_DESCRIPTION_LENGTH}
            helperText={
              <div className={classes.inputErrorWrapper}>
                <div>
                  {
                    description?.length > MAX_DESCRIPTION_LENGTH
                    ? <div className={classes.helperTextError}>
                      <img src={WarningIcon} alt="warning"/>
                      {t('customProcesses.creationPage.processFormErrors.valueTooLong')}
                    </div>
                    : null
                  }
                </div>

                <div className={classes.helperTextError}>
                  {
                    description?.length > MAX_DESCRIPTION_LENGTH
                    ? `${description?.length}/${MAX_DESCRIPTION_LENGTH}`
                    : null
                  }
                </div>
              </div>
            }
          />
        </div>

        <div className={classes.modalFooter}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.modalButton}
            onClick={onClose}>
            {t('template_delete_modal.button_cancel', { defaultValue: 'Cancel' })}
          </Button>

          <Button
            variant="contained"
            color="secondary"
            className={classes.modalButton}
            endIcon={isSaveOpenLoading ? <Spinner size={14} absolute={false} buttonVersion/> : null}
            disabled={isButtonDisabled}
            onClick={() => {
              handleSave(true)
            }}
            >
            {t('templates.saveAndOpen', { defaultValue: 'Save & Open' })}
          </Button>

          <Button
            variant="contained"
            color="primary"
            className={classes.modalButton}
            onClick={() => handleSave()}
            endIcon={isSaveLoading ? <Spinner size={14} absolute={false} white buttonVersion/> : null}
            disabled={isButtonDisabled}
            >
            {t('buttons.button_save', { defaultValue: 'Save' })}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

