import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Menu,
  MenuItem,
  CircularProgress,
  ButtonBase,
} from '@mui/material';
import classNames from 'classnames';

import { TemplateProcess, TemplateProcessTableColumn, TemplateProcessTableMenuButton } from '../../TemplateProcesses.types';

import useTemplateProcessesTableStyles from './TemplateProcessesTable.useStyles';
import { TEMPLATE_PROCESSES_TABLE_COLUMNS } from '../../TemplateProcesses.constants';
import { getSortString } from 'pages/TemplateProcesses/TemplateProcesses';
import {environment} from "../../../../environments/environment";

interface TemplateProcessesTableProps {
  rows: Partial<TemplateProcess>[];
  menuButtons: Partial<TemplateProcessTableMenuButton>[];
  autostartMenuButtons?: TemplateProcessTableMenuButton[];
  anchorElement: Element | null;
  autostartAnchorElement?: Element | null;
  renderCell?: (column: TemplateProcessTableColumn, row: Partial<TemplateProcess>) => ReactElement;
  onMenuClose: () => void;
  onAutostartMenuClose?: () => void;
  sort?: string[];
  setSort?: (arg: [string, string]) => void;
}

export const TemplateProcessesTable = ({
  rows,
  menuButtons = [],
  autostartMenuButtons = [],
  anchorElement,
  autostartAnchorElement,
  renderCell,
  onMenuClose,
  onAutostartMenuClose,
  sort,
  setSort,
}: TemplateProcessesTableProps): ReactElement => {
  const classes = useTemplateProcessesTableStyles();

  const { t } = useTranslation();
  const isEuOrUsRegion = environment.region === 'EU' || environment.region === 'US';

  const [currentButtonAction, setCurrentButtonAction] = useState<string | null>(null);

  const handleMenuItemClick = async (button: TemplateProcessTableMenuButton): Promise<void> => {
    try {
      setCurrentButtonAction(button.title);
      await button.onClick();
      onMenuClose();
    } finally {
      setCurrentButtonAction(null);
    }
  };

  const handleAutostartMenuItemClick = async (button: TemplateProcessTableMenuButton): Promise<void> => {
    try {
      await button.onClick();
      onAutostartMenuClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handleSort = (sortArray) => {
    const sortPropValue = getSortString(sort);
    const sortValue = getSortString(sortArray);

    if (sortPropValue === sortValue) return;

    setSort(sortArray);
  };

  const renderHeaderRowsHelper = columns => columns.map((column: {label: string; id: string; width?: number | string, isCentered?: boolean}) => {
    return !(isEuOrUsRegion && column.id === 'docflowTemplatesCount') && (

      <TableCell key={column.id} className={classes.tableHeaderRowCell} width={column.width} style={{width: column.width}}>
        <Box display='flex' alignItems="center" justifyContent={column.isCentered ? 'center' : ''}>
          <Box>{t(column.label)}</Box>

            {typeof setSort === 'function' && column.id !== 'processMenu' && (
              <Box>
                <ButtonBase onClick={() => handleSort([column.id, 'ASC'])} className={classNames(classes.sortButton)}>
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.64 0.475277C4.68664 0.426886 4.74256 0.388395 4.80441 0.362105C4.86626 0.335815 4.93279 0.322264 5 0.322264C5.06721 0.322264 5.13373 0.335815 5.19558 0.362105C5.25743 0.388395 5.31335 0.426886 5.35999 0.475277L9.52666 4.80861C9.57449 4.85541 9.61245 4.91133 9.63827 4.97306C9.66409 5.03479 9.67726 5.10108 9.677 5.16799C9.67673 5.23491 9.66304 5.30109 9.63673 5.36262C9.61042 5.42414 9.57202 5.47976 9.52382 5.52618C9.47562 5.57259 9.41859 5.60886 9.35612 5.63283C9.29365 5.6568 9.227 5.66799 9.16012 5.66573C9.09324 5.66347 9.0275 5.64781 8.96678 5.61968C8.90607 5.59154 8.85162 5.55151 8.80666 5.50194L5 1.54328L1.19333 5.50194C1.14837 5.55151 1.09392 5.59154 1.03321 5.61968C0.972493 5.64781 0.906749 5.66347 0.839871 5.66573C0.772994 5.66799 0.706344 5.6568 0.643869 5.63283C0.581395 5.60886 0.524367 5.57259 0.476167 5.52618C0.427966 5.47976 0.389573 5.42414 0.363262 5.36262C0.336951 5.30109 0.323256 5.23491 0.322991 5.16799C0.322727 5.10108 0.335897 5.03479 0.361721 4.97306C0.387545 4.91133 0.425496 4.85541 0.473328 4.80861L4.64 0.475277Z"
                      fill={getSortString([column.id, 'ASC']) === getSortString(sort) ? '#007994' : '#8B8C9E'}
                    />
                  </svg>
                </ButtonBase>

                <ButtonBase onClick={() => handleSort([column.id, 'DESC'])} className={classNames(classes.sortButton)}>
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.36 5.513C5.31336 5.56139 5.25744 5.59989 5.19559 5.62618C5.13374 5.65247 5.06721 5.66602 5 5.66602C4.93279 5.66602 4.86627 5.65247 4.80442 5.62618C4.74257 5.59989 4.68665 5.56139 4.64001 5.513L0.473338 1.17967C0.425506 1.13288 0.387554 1.07695 0.36173 1.01522C0.335907 0.95349 0.322737 0.887202 0.323002 0.820287C0.323267 0.753372 0.336961 0.687191 0.363272 0.625665C0.389583 0.564139 0.427977 0.508522 0.476177 0.462106C0.524378 0.415691 0.581405 0.379423 0.64388 0.355451C0.706354 0.331479 0.773005 0.320291 0.839882 0.322551C0.90676 0.32481 0.972503 0.340472 1.03322 0.368606C1.09393 0.39674 1.14838 0.436774 1.19334 0.486337L5 4.445L8.80667 0.486337C8.85163 0.436774 8.90608 0.39674 8.96679 0.368606C9.02751 0.340472 9.09325 0.32481 9.16013 0.322551C9.22701 0.320291 9.29366 0.331479 9.35613 0.355451C9.41861 0.379423 9.47563 0.415691 9.52383 0.462106C9.57203 0.508522 9.61043 0.564139 9.63674 0.625665C9.66305 0.687191 9.67674 0.753372 9.67701 0.820287C9.67727 0.887202 9.6641 0.95349 9.63828 1.01522C9.61246 1.07695 9.5745 1.13288 9.52667 1.17967L5.36 5.513Z"
                      fill={getSortString([column.id, 'DESC']) === getSortString(sort) ? '#007994' : '#8B8C9E'}
                    />
                  </svg>
                </ButtonBase>
              </Box>
            )}
          </Box>
        </TableCell>
      );
    });

  return (
    <Box display="flex" flexDirection="column" className={classes.mainBox}>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table className={classNames(classes.table)} stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow className={classes.tableHeaderRow}>{renderHeaderRowsHelper(TEMPLATE_PROCESSES_TABLE_COLUMNS)}</TableRow>
            </TableHead>

            <TableBody>
              {rows.map((row) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.processSysName}>
                    {TEMPLATE_PROCESSES_TABLE_COLUMNS
                      .filter(column => !(isEuOrUsRegion && column.id === 'docflowTemplatesCount'))
                      .map((column): ReactElement => renderCell(column, row))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Menu
        id="actions-menu"
        open={Boolean(anchorElement)}
        anchorEl={anchorElement}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
        onClose={onMenuClose}
        PaperProps={{
          style: {
            border: '1px solid rgba(38, 40, 66, 0.08)',
            boxShadow: '0px 8px 16px rgba(38, 40, 66, 0.04)',
            borderRadius: 10,
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {menuButtons.map((button: TemplateProcessTableMenuButton) => (
          <MenuItem key={button.title} className={classes.tableBodyActionMenuListItem} onClick={() => handleMenuItemClick(button)}>
            <Box display="flex" alignItems="center">
              <img src={button.icon} className={classes.tableBodyActionMenuListItemIcon} />

              <Typography className={classes.tableBodyActionMenuListItemText}>{button.title}</Typography>

              <Box ml={1}>
                <CircularProgress size={8} style={{ color: currentButtonAction === button.title ? 'inherit' : 'white' }} />
              </Box>
            </Box>
          </MenuItem>
        ))}
      </Menu>

      <Menu
        id="autostart-actions-menu"
        open={Boolean(autostartAnchorElement)}
        anchorEl={autostartAnchorElement}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
        onClose={onAutostartMenuClose}
        PaperProps={{
          style: {
            border: '1px solid rgba(38, 40, 66, 0.08)',
            boxShadow: '0px 8px 16px rgba(38, 40, 66, 0.04)',
            borderRadius: 10,
          },
        }}
      >
        {autostartMenuButtons.map((button) => (
          <MenuItem key={button.title} className={classes.tableBodyActionMenuListItem} onClick={() => handleAutostartMenuItemClick(button)}>
            <Box display="flex" alignItems="center">
              <img src={button.icon} className={classes.tableBodyActionMenuListItemIcon} />

              <Typography className={classes.tableBodyActionMenuListItemText}>{button.title}</Typography>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
