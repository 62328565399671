import { useMemo } from "react";

import { useUserProfile } from "hooks";
import { useUsersState } from 'store/users';
import { useTaskState } from "store/requests";

const TIME_OFF_SYS_NAME = 'time_off';
const TIME_OFF_SWITCH_NAME = 'anotherEmployee';
const TIME_OFF_HR_COMPANY = 'HR';

type Props = {
  name?: string;
}

export default function useBpmSwitchTimeOff({ name }: Props) {
  const { data: bpmTask } = useTaskState();
  const { users } = useUsersState();
  const { id: profileId } = useUserProfile();

  const isTimeOff = useMemo((): boolean =>
      bpmTask && bpmTask.businessEntity.sysName === TIME_OFF_SYS_NAME,
    [bpmTask]);

  const isTimeOffAnotherEmployeeSwitch = useMemo((): boolean =>
      name === TIME_OFF_SWITCH_NAME,
    [name]);

  const isTimeOffNotHRUser = useMemo((): boolean => {
    if (bpmTask && (!isTimeOff || !bpmTask.isFirstStep || !isTimeOffAnotherEmployeeSwitch)) {
      return false;
    }

    return !users[profileId]?.division.includes(TIME_OFF_HR_COMPANY);
  }, [
    profileId,
    users,
    isTimeOff,
    isTimeOffAnotherEmployeeSwitch,
    bpmTask,
  ]);

  return {
    isTimeOffNotHRUser
  }
}
