import { Reducer, useMemo, useReducer, useState } from "react";
import { TemplatesRequestFilterValues } from '../Templates.types'

const defaultPagination = {
  page: 0,
  pageSize: 25,
}
const filterInitialValues: TemplatesRequestFilterValues = {
  processName: '',
  page: defaultPagination.page,
  pageSize: defaultPagination.pageSize,
};

export const useFilter = () => {
  const [currentFilterValues, setCurrentFilterValues] = useReducer<
    Reducer<TemplatesRequestFilterValues, Partial<TemplatesRequestFilterValues>>
  >((prevState, state) => ({
    ...prevState,
    ...state,
  }), {
    ...filterInitialValues,
    pageSize: defaultPagination.pageSize,
    page: defaultPagination.page
  });

  return {
    currentFilterValues,
    setCurrentFilterValues,
  }
}



