import { billingService } from '@dar-dms/topbar';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { Subscription } from 'types/billing';

export const MODULE_KEY = 'processes';
export const TEMPLATE_PROCESSES_PAGE_COMPONENT = 'wf_builder_v';
export const INTERNAL_MEMO_PROCESSES_COMPONENT = 'int_memo_v';
export const INTERNAL_MEMO_PROCESSES_COMPONENT_RESTRICTED_PROCESSES = ['memo_v1', 'general_memo_v1'];

export const useBilling = () => {
  const [subscription, setSubscription] = useState<Subscription>();
  const location = useLocation();
  const history = useHistory();
  const modules = (billingService.getModules() || []) as string[];

  const hasModuleAccess = useMemo(() => {
    try {
      return modules?.includes(MODULE_KEY);
    } catch (e) {
      console.log('Error checking access permission\n', e);
      return false;
    }
  }, [location.pathname, modules]);

  useEffect(() => {
    if (billingService) {
      const billing = billingService.subscription.subscribe(
        (sub: Subscription) => {
          setSubscription(sub);
        },
      );
      return () => {
        billing.unsubscribe();
      };
    }
  }, [history]);

  const tariffLimits = useMemo(() => {
    return subscription?.tariff?.limits || {};
  }, [subscription]);

  const hasTemplateProcessesAccess = useMemo(() => {
    return billingService.getPermission({
      module: MODULE_KEY,
      component: TEMPLATE_PROCESSES_PAGE_COMPONENT,
    })
  }, [subscription]);

  const hasInternalMemoProcessesAccess = useMemo(() => {
    return billingService.getPermission({
      module: MODULE_KEY,
      component: INTERNAL_MEMO_PROCESSES_COMPONENT,
    })
  }, [subscription]);

  const isInternalMemoProcess = processSysName => INTERNAL_MEMO_PROCESSES_COMPONENT_RESTRICTED_PROCESSES.includes(processSysName);

  return {
    subscription,
    tariffLimits,
    hasModuleAccess,
    hasInternalMemoProcessesAccess,
    hasTemplateProcessesAccess,
    isInternalMemoProcess,
  }
}
