import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ spacing, palette }) => ({
  popover: {
    position: 'absolute',
    top: '50vh',
    left: '50vw',
  },
  header: {},
  dialogContentWrapper: {
    marginTop: 23,
    marginBottom: 31,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    width: 120,
    height: 120,
    marginBottom: 24
  },
  title: {
    marginBottom: 12,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 20,
    color: '#262842',
  },
  message: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#6D6E85',
  },
  footer: {
    background: "#F7F8FC",
    borderTop: 0,
  },
  btn: {
    borderRadius: 8,

    "&:disabled": {
      background: "#F0F0F7",
      color: "#A9A9B8 !important",
      borderColor: "#F0F0F7"
    }
  },
  btnSecondary: {
    border: "1px solid rgba(38, 40, 66, 0.12)",
    height: 32,
  }
}));

export default useStyles;
