import React, { useState, useMemo, ReactElement, useEffect, useRef, useCallback } from 'react';
import {
  FormControl,
  TextField,
  InputAdornment,
  Box,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
  ClickAwayListener,
  List,
  ListItem, Tooltip, Popper, IconButton,
} from '@mui/material';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars';

import { UserType } from 'types';
import { useUserProfile } from 'hooks';
import { UserInfo } from 'components';
import { useUsersState } from 'store/users';

import { UserDropdownCard } from './UserDropdownCard';
import { containsText } from './UserDropdown.utils';
import useUserDropdownStyles from './UserDropdownWithDepartments.useStyles';
import { DepartmentInfo, UserSelectDepartmentData } from '../../TemplateProcesses.types';

import SearchIcon from 'assets/images/icons/search-icon-thin.svg';
import ListArrow from 'assets/images/icons/list-arrow-thin.svg';
import PersonIcon from 'assets/images/icons/new-user-icon.svg';
import WarningIcon from 'assets/images/icons/warning-sign.svg';
import CloseIcon from 'assets/images/icons/close-icon.svg';
import PlusIcon from 'assets/images/icons/tertiary-plus-sign.svg';
import ChevronDown from 'assets/images/icons/chevron_down_regular.svg';

const ALL_USERS_KEY = 'all_users';
const NO_DEPARTMENT_KEY = 'no_department';
const MAX_PARALLEL_ASSIGNEE = 10;

interface UserDropdownProps {
  placeholder?: string;
  activeUsersId: string[];
  currentStepOrder: number;
  handleUsersSelect: (usersId: string[]) => void;
  departmentsList: DepartmentInfo[];
  allowOnlyOneDepartment?: boolean;
  disabled?: boolean;
  error?: boolean;
  parallelAssigneeErrors?: { required: boolean, repeated: boolean }[];
  helperText?: any;
  isPerformerStep?: boolean;
  isAutostartActive?: boolean;
  isParallelAssigneeSelect?: boolean;
  showAllUsersGroup?: boolean;
  showAllDepartmentsGroup?: boolean;
  showWithoutDepartmentView?: boolean;
  isConditionModalVariant?: boolean;
  isSignRequired?: boolean;
}

export const UserDropdownWithDepartments = ({
  placeholder = 'Инициатор',
  activeUsersId,
  currentStepOrder = 0,
  disabled = false,
  error = false,
  helperText,
  isPerformerStep = false,
  isAutostartActive = false,
  handleUsersSelect,
  departmentsList = [],
  allowOnlyOneDepartment = false,
  showAllUsersGroup = true,
  showAllDepartmentsGroup = false,
  isParallelAssigneeSelect = false,
  parallelAssigneeErrors,
  isConditionModalVariant = false,
  showWithoutDepartmentView = false,
  isSignRequired
}: UserDropdownProps): ReactElement => {
  const { t, i18n } = useTranslation();
  const classes = useUserDropdownStyles({});
  const userListDropdownAnchor = useRef<HTMLElement>();

  const { companyName } = useUserProfile();

  const { users } = useUsersState();

  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const [searchText, setSearchText] = useState<string>('');
  const [selectedUsersId, setSelectedUsersId] = useState<string[]>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<string>(ALL_USERS_KEY);
  const [currentAssigneeSelectIndex, setCurrentAssigneeSelectIndex] = useState<number>(0);

  const usersListTopRef = useRef(null);
  const departmentsListTopRef = useRef(null);
  const searchInputRef = useRef(null);

  const userOptions = useMemo((): UserType[] =>
      Object.values(users),
    [users]);

  const groupedUserOptions = useMemo(() =>
      userOptions.reduce((acc, user) => {
        const userDepartment = user?.department || NO_DEPARTMENT_KEY;
        if (!Object.keys(acc).includes(userDepartment)) {
          acc[userDepartment] = [];
        }
        acc[userDepartment].push(user);
        return acc;
      }, {}),
    [userOptions, departmentsList]);

  const departments = useMemo((): UserSelectDepartmentData[] => {
    const departmentsCounts = Object.keys(groupedUserOptions)
      .reduce((acc, key) => {
        const departmentInfo = departmentsList.find(({ id }) => id === key);
        let departmentName = key;
        if (departmentInfo) {
          departmentName = Object.keys(departmentInfo.localization).includes(i18n.language)
                           ? departmentInfo.localization[i18n.language]
                           : departmentInfo.value;
        }
        if (key === NO_DEPARTMENT_KEY) {
          departmentName = t('form_components.select_users.no_department_name', { defaultValue: NO_DEPARTMENT_KEY });
        }
        return {
          ...acc,
          [key]: { key, name: departmentName, selected: 0, total: groupedUserOptions[key]?.length },
        };
      }, {});

    if (selectedUsersId) {
      for (const userId of selectedUsersId) {
        const user = users[userId];
        if (user) {
          const userDepartment = user?.department || NO_DEPARTMENT_KEY;
          departmentsCounts[userDepartment].selected += 1;
        }
      }
    }

    return (Object.values(departmentsCounts) as UserSelectDepartmentData[])
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [groupedUserOptions, selectedUsersId]);

  const noDepartmentInfo = useMemo(() => {
    const departmentInfo = departments?.find(({ key }) => key === NO_DEPARTMENT_KEY);
    if (departmentInfo) {
      return departmentInfo;
    }

    return { total: 0, selected: 0 };
  }, [departments]);

  const isUserSelectInCurrentDepartmentAllowed = useMemo(() => {
    if (!allowOnlyOneDepartment) {
      return true;
    }

    const departmentsWithSelection = departments?.filter(department => !!department.selected);
    if (!departmentsWithSelection?.length) {
      return true;
    }

    return selectedDepartment === departmentsWithSelection[0].key;
  }, [allowOnlyOneDepartment, departments, selectedDepartment]);

  const isUserSelectUserAllowed = useCallback((user: UserType) => {
    if (!allowOnlyOneDepartment) {
      return true;
    }

    const departmentsWithSelection = departments?.filter(department => !!department.selected);
    if (!departmentsWithSelection?.length) {
      return true;
    }

    if (!selectedUsersId.length) {
      return true;
    }

    return users[selectedUsersId[0]]?.department === user?.department ||
      (!users[selectedUsersId[0]]?.department && !user?.department);
  }, [allowOnlyOneDepartment, departments, selectedDepartment]);

  useEffect(() => {
    setSelectedUsersId(activeUsersId);
    setIsAllSelected(false);
    setSearchText('');
    setSelectedDepartment(ALL_USERS_KEY);
    setHasChanges(false);
  }, [currentStepOrder, isParallelAssigneeSelect]);

  const displayedOptions = useMemo((): UserType[] =>
      userOptions
        .filter((option: UserType) =>
          containsText(option.fullName, searchText) &&
          (option.department === selectedDepartment ||
            (!option.department && selectedDepartment === NO_DEPARTMENT_KEY) ||
            selectedDepartment === ALL_USERS_KEY
          ) &&
          !selectedUsersId?.includes(option.id),
        ),
    [userOptions, selectedDepartment, searchText, selectedUsersId]);

  const displayedOptionsAllUsers = useMemo((): UserType[] =>
      userOptions
        .filter((option: UserType) =>
          containsText(option.fullName, searchText) &&
          (option.department === selectedDepartment ||
            (!option.department && selectedDepartment === NO_DEPARTMENT_KEY) ||
            selectedDepartment === ALL_USERS_KEY
          )),
    [userOptions, selectedDepartment, searchText, selectedUsersId]);

  const displayedSelectedOptions = useMemo((): UserType[] =>
      selectedUsersId
        ?.map(userId => users[userId])
        .filter(Boolean)
        .filter((option: UserType) =>
          containsText(option.fullName, searchText) &&
          (option.department === selectedDepartment ||
            (!option.department && selectedDepartment === NO_DEPARTMENT_KEY) ||
            selectedDepartment === ALL_USERS_KEY
          ),
        ),
    [selectedDepartment, searchText, selectedUsersId, users]);

  const handleMenuClose = (resetState = false): void => {
    if (isDropdownOpen) {
      usersListTopRef.current.scrollIntoView();
      !showWithoutDepartmentView && departmentsListTopRef.current.scrollIntoView();
      setIsDropdownOpen(false);
      setHasChanges(false);
      setSelectedDepartment(ALL_USERS_KEY);

      if (resetState) {
        setSearchText('');
        setSelectedUsersId(activeUsersId);
      }
    }
  };

  const handleMenuOpen = (): void => {
    if (!disabled) {
      setIsDropdownOpen(true);
      setHasChanges(false);
      setSelectedDepartment(ALL_USERS_KEY);
      setSearchText('');
      if (!isParallelAssigneeSelect) {
        setSelectedUsersId(activeUsersId.filter(v => !!v));
      }
    }
  };

  const handleSelectAll = (): void => {
    setHasChanges(true);
    if (selectedDepartment === ALL_USERS_KEY) {
      if (isAllSelected) {
        setIsAllSelected(false);
        setSelectedUsersId([]);
        return;
      }

      setIsAllSelected(true);
      setSelectedUsersId(displayedOptions.map(userOption => userOption.id));
    } else {
      const departmentInfo = departments.find(({ key }) => key === selectedDepartment);

      if (departmentInfo) {
        const departmentUsersId = groupedUserOptions[selectedDepartment].map(userOption => userOption.id);
        const filteredSelectedUsersId = selectedUsersId.filter(userId => !departmentUsersId.includes(userId));
        if (departmentInfo.selected === departmentInfo.total) {
          setSelectedUsersId(filteredSelectedUsersId);
        } else {
          setSelectedUsersId([...filteredSelectedUsersId, ...departmentUsersId]);
        }
      }
    }
  };

  const handleSelectDepartment = (department): void => {
    setSelectedDepartment(department);
  };

  const handleSelectOption = (selectedOption): void => {
    setHasChanges(true);
    if (selectedUsersId.includes(selectedOption.id)) {
      setSelectedUsersId(prevValue => prevValue.filter(userId => userId !== selectedOption.id));
      return;
    }

    setSelectedUsersId(prevValue => [...prevValue, selectedOption.id]);
  };

  const handleDropdownClear = (): void => {
    setSearchText('');
    setIsAllSelected(false);
    setSelectedUsersId([]);
    handleUsersSelect([]);
  };

  const handleDropdownSubmit = (usersList?: any[]): void => {
    handleUsersSelect(usersList || selectedUsersId);
    handleMenuClose();
  };

  const handleAddParallelAssignee = (): void => {
    setSelectedUsersId([...selectedUsersId, '']);
    handleDropdownSubmit([...selectedUsersId, '']);
  };

  const handleSelectParallelAssignee = (selectedOption) => {
    setHasChanges(true);
    const newUsersList = selectedUsersId;
    newUsersList[currentAssigneeSelectIndex] = selectedOption.id;
    setSelectedUsersId(newUsersList);
    handleDropdownSubmit(newUsersList);
  };

  const selectAllLabel = useMemo(() => {
    if (selectedDepartment === ALL_USERS_KEY) {
      return `${t('form_components.select_users.select_all_users')} (${selectedUsersId?.length}/${userOptions?.length})`;
    }

    const departmentObject = departments.find(({ key }) => key === selectedDepartment);
    return `${t('form_components.select_users.select_all_members')} (${departmentObject?.selected}/${departmentObject?.total})`;
  }, [selectedDepartment, departments]);

  const selectAllState = useMemo((): { checked: boolean, indeterminate: boolean } => {
    if (selectedDepartment === ALL_USERS_KEY) {
      return {
        checked: selectedUsersId?.length === userOptions?.length,
        indeterminate: selectedUsersId?.length > 0,
      };
    }

    const departmentObject = departments.find(({ key }) => key === selectedDepartment);
    return {
      checked: departmentObject.selected === departmentObject.total,
      indeterminate: departmentObject.selected > 0,
    };
  }, [selectedDepartment, departments]);

  const assigneeTooltip = useMemo(() => {
    if (selectedUsersId?.length <= 1) {
      return null;
    }

    const tooltipAssignees = selectedUsersId?.map(userId => users[userId]).filter(Boolean);

    const tooltipAssigneesDividedByDepartments =
      tooltipAssignees?.reduce((acc, assignee): { [key: string]: UserSelectDepartmentData } => {
        const userDepartmentKey = assignee?.department || 'no_department';
        if (!Object.keys(acc).includes(userDepartmentKey)) {
          let departmentName = userDepartmentKey === 'no_department'
                               ? t('form_components.select_users.no_department')
                               : userDepartmentKey;

          const departmentInfo = departmentsList.find(({ id }) => id === userDepartmentKey);
          if (departmentInfo) {
            departmentName = Object.keys(departmentInfo.localization).includes(i18n.language)
                             ? departmentInfo.localization[i18n.language]
                             : departmentInfo.value;
          }

          acc[userDepartmentKey] = {
            key: userDepartmentKey,
            name: departmentName,
            users: [],
          };
        }
        acc[userDepartmentKey].users.push(assignee);
        return acc;
      }, {});

    const tooltipAssigneesSorted = (Object.values(tooltipAssigneesDividedByDepartments || []) as UserSelectDepartmentData[])
      // sort placing 'no department assign' group to the end
      .sort((a, b) => a.key === 'no_department' ? 1 : a.name.localeCompare(b.name))
      .map(department => ({
        ...department,
        users: department.users.sort((a, b) => a.fullName.localeCompare(b.fullName)),
      }));

    const tooltipContent = <Scrollbars style={{ width: 160, height: 160 }} className={classes.tooltipContent}>{
      tooltipAssigneesSorted.map(department => {
        return <>
          <span>{department.name}</span>
          <ul>
            {department.users?.map(user => <li>{user?.fullName}</li>)}
          </ul>
        </>;
      })
    }
    </Scrollbars>;

    return (
      <Tooltip
        arrow
        placement="top"
        title={tooltipContent}
        leaveDelay={500}
        classes={{
          popper: classes.assigneesTooltipPopper,
          tooltip: classes.assigneesTooltip,
        }}>
        <span className={classes.userDropdownValueCount}>(+{tooltipAssignees?.length - 1})</span>
      </Tooltip>);
  }, [selectedUsersId, departmentsList]);

  const usersListTitle = useMemo(() => {
    if (selectedDepartment === ALL_USERS_KEY) {
      return allowOnlyOneDepartment
             ? t('form_components.select_users.all_departments')
             : t('form_components.select_users.all_users');
    }
    if (selectedDepartment === NO_DEPARTMENT_KEY) {
      return t('form_components.select_users.no_department');
    }
    const selectedDepartmentInfo = departments.find(({ key }) => key === selectedDepartment);
    return selectedDepartmentInfo?.name;
  }, [selectedDepartment, departments]);

  const additionalPlaceholder = useMemo(() => {
    if (isPerformerStep) {
      return t('customProcesses.readonlyView.assigneeSelectedOnPreviousStep');
    }

    if (isAutostartActive && currentStepOrder === 1) {
      return companyName;
    }

    if (isConditionModalVariant) {
      return t('customProcesses.creationPage.conditionSetupModal.selectValue');
    }

    return t('form_components.select.placeholder');
  }, [placeholder, companyName, isPerformerStep, isAutostartActive, isConditionModalVariant, currentStepOrder]);

  const renderAssigneeField = useCallback((value: string[], index = 0) => {
    if (isConditionModalVariant) {
      return <Button
        variant="contained"
        color="secondary"
        className={cn('userFieldModal', classes.userModalVariantDropdown)}
        onClick={(event) => {
          handleMenuOpen();
          setCurrentAssigneeSelectIndex(index);

          const buttons = document.getElementsByClassName('userFieldModal');
          for (let i = 0; i < buttons.length; i++) {
            if (buttons.item(i).contains(event.target as Node)) {
              userListDropdownAnchor.current = buttons.item(0) as HTMLElement;
            }
          }
        }}>
        <Box display="flex" alignItems="center">
          <img src={PersonIcon} className={classes.userDropdownIcon}/>

          <Box ml={2}>
            {(value?.length && !!value[0]) ? (
              <Box display="flex" alignItems="center">
                <UserInfo user={users[value[0]]} avatarSize={20}/>
                {value?.length > 1 && (
                  <Box ml={2}>
                    {assigneeTooltip}
                  </Box>
                )}
              </Box>
            ) : (
               <Typography className={classes.userDropdownValue}>
                 {additionalPlaceholder}
               </Typography>
             )}
          </Box>
        </Box>

        {
          !disabled &&
          <img src={ChevronDown}
               className={cn(classes.userDropdownIcon, { [classes.userDropdownActive]: isDropdownOpen && currentAssigneeSelectIndex === index })}
               alt=""/>
        }
      </Button>;
    }

    return (
      <Box className={classes.userDropdownWrapper}>
        <Box className={classes.userDropdownFieldWrapper}>
          <Button
            variant="contained"
            color="secondary"
            className={cn('userField', classes.userDropdown, {
              [classes.userDropdownDisabled]: disabled,
              [classes.userDropdownError]: error,
              [classes.parallelAssigneeDropdown]: isParallelAssigneeSelect,
            })}
            onClick={(event) => {
              handleMenuOpen();
              setCurrentAssigneeSelectIndex(index);

              const buttons = document.getElementsByClassName('userField');
              for (let i = 0; i < buttons.length; i++) {
                if (buttons.item(i).contains(event.target as Node)) {
                  userListDropdownAnchor.current = buttons.item(i) as HTMLElement;
                }
              }
            }}>
            <Box display="flex" alignItems="center">
              <img src={PersonIcon} className={classes.userDropdownIcon}/>

              <Box ml={1}>
                <Typography
                  className={cn(classes.userDropdownTitle, { [classes.userDropdownTitleDisabled]: disabled })}>{placeholder}:</Typography>
              </Box>

              <Box ml={2}>
                {(value?.length && !!value[0]) ? (
                  <Box display="flex" alignItems="center">
                    <UserInfo user={users[value[0]]} avatarSize={20}/>
                    {value?.length > 1 && (
                      <Box ml={2}>
                        {assigneeTooltip}
                      </Box>
                    )}
                  </Box>
                ) : (
                   <Typography className={classes.userDropdownValue}>
                     {additionalPlaceholder}
                   </Typography>
                 )}
              </Box>
            </Box>

            {
              !disabled &&
              <img src={ChevronDown}
                   className={cn(classes.userDropdownIcon, { [classes.userDropdownActive]: isDropdownOpen && currentAssigneeSelectIndex === index })}
                   alt=""/>
            }
          </Button>

          {isParallelAssigneeSelect &&
            <Box className={classes.userDropdownFieldDeleteButtonWrapper}>
              {index > 0 && <IconButton
                onClick={() => {
                  const newSelectedUsersId = [...selectedUsersId.slice(0, index), ...selectedUsersId.slice(index + 1)];
                  setSelectedUsersId(newSelectedUsersId);
                  handleUsersSelect(newSelectedUsersId);
                }
                }
                size="large">
                <img src={CloseIcon}/>
              </IconButton>}
            </Box>
          }
        </Box>

        {(parallelAssigneeErrors &&
            (parallelAssigneeErrors[index]?.required || parallelAssigneeErrors[index]?.repeated))
          &&
          <div className={classes.processStepFormErrorMessage}>
            <img src={WarningIcon} alt="warning"/>
            <span>
              {parallelAssigneeErrors[index]?.required && t('customProcesses.creationPage.processFormErrors.parallelAssigneeRequired')}
              {parallelAssigneeErrors[index]?.repeated && t('customProcesses.creationPage.processFormErrors.parallelAssigneeRepeated')}
            </span>
          </div>}
      </Box>
    );
  }, [
    additionalPlaceholder,
    assigneeTooltip,
    currentAssigneeSelectIndex,
    disabled,
    error,
    handleMenuOpen,
    handleUsersSelect,
    isDropdownOpen,
    isParallelAssigneeSelect,
    parallelAssigneeErrors,
    placeholder,
    selectedUsersId,
    users]);

  const assigneeFields = useMemo(() =>
      <>{
        isParallelAssigneeSelect
        ? activeUsersId.map((userId, index) =>
          renderAssigneeField([userId], index),
        )
        : renderAssigneeField(activeUsersId)
      }</>
    , [isParallelAssigneeSelect, selectedUsersId, activeUsersId, renderAssigneeField]);

  return (
    <ClickAwayListener onClickAway={() => handleMenuClose(true)}>
      <FormControl fullWidth={!isConditionModalVariant}>
        {assigneeFields}

        {isParallelAssigneeSelect &&
          <Button
            color="secondary"
            disabled={selectedUsersId.length >= MAX_PARALLEL_ASSIGNEE}
            onClick={handleAddParallelAssignee}
            variant="text"
            startIcon={<img src={PlusIcon} alt=""/>}
            className={classes.addAssigneeButton}
          >
            {t('customProcesses.creationForm.assigneeField.addParallelAssigneeButton')}
          </Button>
        }

        {(!isParallelAssigneeSelect && error) && <>{helperText}</>}

        <Popper
          id="user-select-dropdown"
          open={true}
          anchorEl={userListDropdownAnchor.current}
          className={classes.userDropdownMenuPopper}
          placement="bottom-start"
          keepMounted
        >
          <Box className={cn(classes.userDropdownMenu, { [classes.userDropdownMenuActive]: isDropdownOpen })}>
            <Box className={classes.userDropdownMenuHeader}>
              <Box className={classes.userDropdownDepartmentsTitleWrapper}>
                <Typography className={classes.userDropdownHeaderTitle}>
                  {t('form_components.select_users.departments')}
                </Typography>
              </Box>

              <Box className={classes.userDropdownUsersListTitleWrapper}>
                <Typography className={classes.userDropdownHeaderTitle}>
                  {usersListTitle}
                </Typography>
              </Box>
            </Box>

            <Box className={classes.userDropdownMenuContent}>
              <Box className={classes.userDropdownDepartmentsListWrapper}>
                <Scrollbars className={classes.listScrollbars}>
                  <List className={classes.userDropdownDepartmentsList} disablePadding>
                    <div ref={departmentsListTopRef}/>
                    {(showAllUsersGroup || showAllDepartmentsGroup) &&
                      (<ListItem
                          className={cn(classes.userDropdownDepartmentsListItem, {
                            [classes.userDropdownDepartmentsListItemActive]: selectedDepartment === ALL_USERS_KEY,
                          })}
                          onClick={() => handleSelectDepartment(ALL_USERS_KEY)}
                        >
                          <>
                            <Tooltip
                              title={showAllUsersGroup
                                     ? t('form_components.select_users.all_users')
                                     : t('form_components.select_users.all_departments')}
                              enterDelay={300}
                              placement="top"
                              arrow>
                              <>
                                {showAllUsersGroup &&
                                  <span>{t('form_components.select_users.all_users')}</span>
                                }
                                {showAllDepartmentsGroup &&
                                  <span>{t('form_components.select_users.all_departments')}</span>
                                }
                              </>
                            </Tooltip>
                            <div className={classes.userDropdownDepartmentsListItemRightPart}>
                              {showAllUsersGroup &&
                                <span>({selectedUsersId?.length}/{userOptions?.length})</span>
                              }
                              <img src={ListArrow}/>
                            </div>
                          </>
                        </ListItem>
                      )}

                    {
                      departments
                        ?.filter(({ key }) => key !== NO_DEPARTMENT_KEY)
                        .map(({ key, name, selected, total }) =>
                          <ListItem
                            className={cn(classes.userDropdownDepartmentsListItem, {
                              [classes.userDropdownDepartmentsListItemActive]: selectedDepartment === key,
                            })}
                            onClick={() => handleSelectDepartment(key)}
                          >
                            <Tooltip
                              title={name}
                              enterDelay={300}
                              placement="top"
                              arrow>
                              <span className={classes.userDropdownDepartmentsListDepartmentName}>{name}</span>
                            </Tooltip>
                            <div className={classes.userDropdownDepartmentsListItemRightPart}>
                              <span>({selected}/{total})</span>
                              <img src={ListArrow}/>
                            </div>
                          </ListItem>)
                    }

                    {(noDepartmentInfo?.total > 0) &&
                      <ListItem
                        className={cn(classes.userDropdownDepartmentsListItem, {
                          [classes.userDropdownDepartmentsListItemActive]: selectedDepartment === NO_DEPARTMENT_KEY,
                        })}
                        onClick={() => handleSelectDepartment(NO_DEPARTMENT_KEY)}
                      >
                        <Tooltip
                          title={t('form_components.select_users.no_department')}
                          enterDelay={300}
                          placement="top"
                          arrow>
                          <span className={classes.userDropdownDepartmentsListDepartmentName}>{t('form_components.select_users.no_department')}</span>
                        </Tooltip>
                        <div className={classes.userDropdownDepartmentsListItemRightPart}>
                          <span>({noDepartmentInfo.selected}/{noDepartmentInfo.total})</span>
                          <img src={ListArrow}/>
                        </div>
                      </ListItem>
                    }
                  </List>
                </Scrollbars>
              </Box>

              <Box className={classes.userDropdownUsersListWrapper}>
                <Scrollbars className={classes.listScrollbars}>
                  <div ref={usersListTopRef}/>

                  <Box className={classes.userDropdownMenuSearchWrapper}>
                    <TextField
                      autoFocus
                      fullWidth
                      size="small"
                      value={searchText}
                      inputRef={searchInputRef}
                      onClick={(event) => {
                        searchInputRef?.current?.focus();
                      }}
                      placeholder={t('form_components.select.search_placeholder')}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src={SearchIcon} className={classes.userDropdownIcon}/>
                          </InputAdornment>
                        ),
                      }}
                      className={classes.userDropdownMenuSearchTextField}
                      onChange={(event) => {
                        setSearchText(event.target.value);
                      }}
                    />
                  </Box>


                  {
                    isParallelAssigneeSelect
                    ? <>
                      {displayedOptionsAllUsers
                        ?.map((option: UserType) => (
                          <Box
                            key={option.id}
                            className={cn(classes.menuItem, classes.menuItemSingleChoice)}
                            onClick={() => handleSelectParallelAssignee(option)}
                          >
                            <UserDropdownCard
                              key={option.id}
                              user={option}
                              showDepartmentName={showAllDepartmentsGroup && selectedDepartment === ALL_USERS_KEY}
                              departments={departments}
                            />
                          </Box>
                        ))}
                    </>
                    :
                    <>
                      <Box className={classes.usersListTopPartWrapper}>

                        {allowOnlyOneDepartment && (
                          <div className={classes.UserDropdownListWarningMessage}>
                            {t('form_components.select_users.onlyOneDepartmentAllowed')}
                          </div>
                        )}

                        {!(showAllDepartmentsGroup && selectedDepartment === ALL_USERS_KEY) && !isSignRequired &&
                          (<FormControlLabel
                            control={<Checkbox
                              color={'default'}
                              className={cn(classes.UserDropdownListCheckbox, classes.checkbox, {
                                [classes.checkboxDisabled]: !isUserSelectInCurrentDepartmentAllowed,
                                [classes.checkboxChecked]: selectAllState.indeterminate || selectAllState.checked,
                              })}
                              checked={selectAllState.checked}
                              indeterminate={selectAllState.indeterminate && !selectAllState.checked}
                            />}
                            label={selectAllLabel}
                            onChange={handleSelectAll}
                            disabled={!isUserSelectInCurrentDepartmentAllowed}
                            className={classes.UserDropdownListSelectAll}
                          />)}

                        {displayedSelectedOptions
                          ?.map((option: UserType) => (
                            <Box
                              key={option.id}
                              className={classes.menuItem}
                              onClick={() => isUserSelectUserAllowed(option) && handleSelectOption(option)}
                            >
                              <Checkbox
                                color="default"
                                checked={selectedUsersId.includes(option.id)}
                                disabled={!isUserSelectUserAllowed(option)}
                                className={cn(classes.UserDropdownListCheckbox, classes.checkbox,
                                  {
                                    [classes.checkboxDisabled]: !isUserSelectUserAllowed(option),
                                    [classes.checkboxChecked]: selectedUsersId.includes(option.id),
                                  })}
                              />
                              <UserDropdownCard
                                key={option.id}
                                user={option}
                                showDepartmentName={showAllDepartmentsGroup && selectedDepartment === ALL_USERS_KEY}
                                departments={departments}
                              />
                            </Box>
                          ))}
                      </Box>

                      {displayedOptions.map((option: UserType) => (
                        <Box key={option.id} className={classes.menuItem}>
                          <Box
                            key={option.id}
                            className={classes.menuItem}
                            style={{ pointerEvents: !isUserSelectUserAllowed(option) || (isSignRequired && selectedUsersId?.length > 0) ? 'none' : 'all'  }}
                            onClick={() => isUserSelectUserAllowed(option) && handleSelectOption(option)}
                          >
                            <Checkbox
                              color="default"
                              checked={selectedUsersId?.includes(option.id)}
                              disabled={!isUserSelectUserAllowed(option) || (isSignRequired && selectedUsersId?.length > 0)}
                              className={cn(classes.UserDropdownListCheckbox, classes.checkbox,
                                {
                                  [classes.checkboxDisabled]: !isUserSelectUserAllowed(option) || (isSignRequired && selectedUsersId?.length > 0),
                                  [classes.checkboxChecked]: selectedUsersId?.includes(option.id),
                                })}
                            />
                            <UserDropdownCard
                              key={option.id}
                              user={option}
                              showDepartmentName={showAllDepartmentsGroup && selectedDepartment === ALL_USERS_KEY}
                              departments={departments}
                            />
                          </Box>
                        </Box>
                      ))}
                    </>
                  }
                </Scrollbars>
              </Box>
            </Box>

            {!isParallelAssigneeSelect &&
              <Box className={classes.userDropdownMenuFooter}>
                <Button variant="text" onClick={handleDropdownClear} disabled={!selectedUsersId?.length}>
                  {t('form_components.select.clear')}
                </Button>
                <Button variant="text" onClick={() => handleDropdownSubmit()}
                        disabled={!hasChanges || !selectedUsersId?.length}>
                  {t('form_components.select.apply')}
                </Button>
              </Box>
            }
          </Box>
        </Popper>
      </FormControl>
    </ClickAwayListener>
  );
};
