import React, { useMemo } from 'react';
import classNames from 'classnames';
import { theme } from '@dartech/dms-ui';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import AttachmentItem from '../attachment-item';
import { Scrollbars } from 'react-custom-scrollbars';
import { AttachmentItemType } from 'attachments-types';

import { Styled } from './attachments-list.styles';


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {
  }
}


export enum VariantSelect {
  Big = 'big',
  Compact = 'compact',
  CompactDashed = 'compact-dashed'
}

interface AttachmentsListProps {
  variant?: VariantSelect;
  attachments?: AttachmentItemType[];
  showWithoutScroll?: boolean;
  readOnly?: boolean;
  rightSideAttachments?: boolean;
  onClick?: (attachment: AttachmentItemType) => void | undefined;
  onDelete?: (attachment: any) => void | undefined;
  onUndo?: (attachment: any) => void | undefined;
}

const ONE_SCREEN_ATTACHMENTS_COUNT = 6;

const AttachmentsList: React.FC<AttachmentsListProps> = ({
                                                           variant = VariantSelect.Big,
                                                           attachments = [],
                                                           showWithoutScroll = false,
                                                           readOnly = false,
                                                           rightSideAttachments = false,
                                                           onClick = undefined,
                                                           onDelete = undefined,
                                                           onUndo = undefined
                                                         }) => {
  const notDeletedAttachments = useMemo(
    () => attachments.filter((attachment) => !attachment.isDeleted),
    [attachments]
  );

  const attachmentListWrapperClassNames = useMemo(() => classNames('AttachmentsListRoot', {
    AttachmentsListWrapper: attachments.length && !showWithoutScroll && variant === 'big',
    AttachmentListReadOnly: readOnly,
    AttachmentsOnRightSide: rightSideAttachments
  }), [attachments, showWithoutScroll, variant, readOnly, rightSideAttachments]);

  const attachmentsListClassNames = useMemo(() => classNames('AttachmentsList', {
    AttachmentsListNoScrolling: showWithoutScroll
  }), [showWithoutScroll]);

  const attachmentListCompactClassNames = useMemo(() => classNames('AttachmentListCompact', {
    AttachmentListCompactDashed: variant === 'compact-dashed'
  }), [variant]);

  if (!attachments.length) {
    return null;
  }

  return (
    <Styled.AttachmentsList>
      <div className={attachmentListWrapperClassNames}>
        {variant === 'big' ? (
            attachments.length > ONE_SCREEN_ATTACHMENTS_COUNT &&
            !showWithoutScroll ? (
              <Scrollbars style={{ width: 608, height: 296 }}>
                <div className="AttachmentsList">
                  {notDeletedAttachments.map((attachment) => (
                    <AttachmentItem
                      readOnly={readOnly}
                      attachment={attachment}
                      onDelete={onDelete}
                      onClick={onClick}
                      onUndo={onUndo}
                    />
                  ))}
                </div>
              </Scrollbars>
            ) : (
              <div className={attachmentsListClassNames}>
                {notDeletedAttachments.map((attachment) => (
                  <AttachmentItem
                    readOnly={readOnly}
                    attachment={attachment}
                    onDelete={onDelete}
                    onClick={onClick}
                    onUndo={onUndo}
                  />
                ))}
              </div>
            )
          ) :
          <div className={attachmentListCompactClassNames}>
            {attachments.map((attachment) => (
              <AttachmentItem
                readOnly={readOnly}
                attachment={attachment}
                variant={variant}
                onClick={onClick}
                onDelete={onDelete}
                onUndo={onUndo}
              />
            ))}
          </div>
        }
      </div>
    </Styled.AttachmentsList>
  );
};

export default AttachmentsList;
