import React, { memo, ReactElement } from 'react';
import {
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Box,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import useRadioButtonGroup from './useRadioButtonGroup';

type Props = {
  name: string;
  hint: string;
  localizedOptions: {label: string, value: string}[];
  params: {
    childrenComponentSysName?: string;
  };
  rules: {
    [key: string]: any
  };
  isActive: boolean;
};

type RadioButtonGroupProps = {
  isDisabled?: boolean;
  inputValue?: string;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const RadioButtonGroup = memo(({
  hint,
  name,
  localizedOptions = [],
  params,
  rules,
  isActive
}: Props): ReactElement => {
  const {
    control,
    formState: {
      errors
    }
  } = useFormContext();

  const {
    inputValue,
    handleChange
  }: RadioButtonGroupProps = useRadioButtonGroup({
    name,
    params
  })

  return (
    <Box display="flex" flexDirection="column" mt={-3}>
      <FormLabel component='label'>{hint}</FormLabel>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={() => (
          <FormControl component="fieldset" error={!!errors[name]}>
            <RadioGroup data-selenium={name} row value={inputValue} onChange={handleChange}>
              <Box display="flex" flexDirection="column">
                {localizedOptions.map((option, key) => (
                  <FormControlLabel
                    key={key}
                    label={option.label}
                    value={option.value}
                    disabled={!isActive}
                    control={<Radio color="secondary"/>}
                  />
                ))}
              </Box>
            </RadioGroup>

            {!!errors[name] && (
              <FormHelperText error>{errors[name].message}</FormHelperText>
            )}
          </FormControl>
        )}
      />
    </Box>
  );
});
