import React, { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';

import { useDialogState, useUsersRole } from 'hooks';

import { useProcessStepForm } from './useProcessStepForm';
import { useStepFieldsActions } from './useStepFieldsActions';
import { useProcessPublication } from './useProcessPublication';
import { useProcessSteps } from './useProcessSteps';
import { useProcessAutoSave } from './useProcessAutoSave';

import {
  AllProcessFieldsListItem,
  AutostartSettings,
  ConditionStep,
  FormProcess,
  Language,
  ParallelBranchesGroup,
  ProcessStep,
} from '../../../TemplateProcesses.types';
import { DEFAULT_PROCESS, INITIAL_AUTOSTART_SETTINGS } from '../SetupTemplateProcessDialog.constants';
import {
  GLOSSARY_FIELD_DIRECTORY_PARAM,
  GLOSSARY_FIELD_KEY,
} from '../../ProcessSetupSidePanel/ProcessSetupSidePanel.constants';
import { useProcessConditions } from './useProcessConditions';
import { useParallelBranchesGroups } from './useParallelBranchesGroups';
import { useRouteMatch } from 'react-router-dom';
import { useProcessDocuments } from './useProcessDocuments';
import { useDragAndDropActions } from './useDragAndDropActions';
import { getGlossaryAsTree } from '../../../../../api/requests';

type SetupTab = 'settings' | 'steps';

export const useSetupTemplateProcessDialog = ({
  isOpen,
  initialValues,
  publishedVersionValues,
  templateSettings,
  haveTemplateSettingsChanged,
  onClose,
  onFormSubmit,
  onBackButtonClick,
  setTemplateSettings,
  needResetProcessForm,
  setNeedResetProcessForm,
  handleAutostartActionsClick,
  handlePublishedProcessesLimitDialogOpen,
}) => {
  const { t, i18n } = useTranslation();

  const gridPathRef = useRef(null);
  const nameFieldRef = useRef(null);

  const routeMatch = useRouteMatch();

  const isTemplatePage = useMemo(() => {
    return routeMatch.path === '/templates/:id';
  }, [routeMatch]);

  const {
    isTemplateProcessEditingAvailable,
    isTemplateProcessCreationAvailable,
  } = useUsersRole();

  const { isDialogOpen, handleDialogOpen, handleDialogClose } = useDialogState();
  const {
    isDialogOpen: isDeleteDialogOpen,
    handleDialogOpen: handleDeleteDialogOpen,
    handleDialogClose: handleDeleteDialogClose,
  } = useDialogState();
  const {
    isDialogOpen: isMaximumFieldsDialogOpen,
    handleDialogOpen: handleMaximumFieldsDialogOpen,
    handleDialogClose: handleMaximumFieldsDialogClose,
  } = useDialogState();
  const {
    isDialogOpen: isAutostartActivationConfirmationDialogOpen,
    handleDialogOpen: handleAutostartActivationConfirmationDialogOpen,
    handleDialogClose: handleAutostartActivationConfirmationDialogClose,
  } = useDialogState();
  const {
    isDialogOpen: isAutostartActivationNotPossibleDialogOpen,
    handleDialogOpen: handleAutostartActivationNotPossibleDialogOpen,
    handleDialogClose: handleAutostartActivationNotPossibleDialogClose,
  } = useDialogState();
  const {
    isDialogOpen: isConditionAdditionNotPossibleDialogOpen,
    handleDialogOpen: handleConditionAdditionNotPossibleDialogOpen,
    handleDialogClose: handleConditionAdditionNotPossibleDialogClose,
  } = useDialogState();
  const {
    isDialogOpen: isDocumentSignatureStepRemovalDialogOpen,
    handleDialogOpen: handleDocumentSignatureStepRemovalDialogOpen,
    handleDialogClose: handleDocumentSignatureStepRemovalDialogClose,
  } = useDialogState();
  const {
    isDialogOpen: isAutostartDisablingConfirmationDialogOpen,
    handleDialogOpen: handleAutostartDisablingConfirmationDialogOpen,
    handleDialogClose: handleAutostartDisablingConfirmationDialogClose,
  } = useDialogState();
  const {
    isDialogOpen: isChangesRollbackConfirmationDialogOpen,
    handleDialogOpen: handleChangesRollbackConfirmationDialogOpen,
    handleDialogClose: handleChangesRollbackConfirmationDialogClose,
  } = useDialogState();
  const {
    isDialogOpen: isConditionModalOpen,
    handleDialogOpen: handleConditionModalOpen,
    handleDialogClose: handleConditionModalClose,
  } = useDialogState();
  const {
    isDialogOpen: isCardPreviewDialogOpen,
    handleDialogOpen: handleCardPreviewDialogOpen,
    handleDialogClose: handleCardPreviewDialogClose,
  } = useDialogState();
  const {
    isDialogOpen: isCardPreviewSavingTypeDialogOpen,
    handleDialogOpen: handleCardPreviewSavingTypeDialogOpen,
    handleDialogClose: handleCardPreviewSavingTypeDialogClose,
  } = useDialogState();
  const {
    isDialogOpen: isHidePreviewFieldConfirmationModalOpen,
    handleDialogOpen: handleHidePreviewFieldConfirmationModalOpen,
    handleDialogClose: handleHidePreviewFieldConfirmationModalClose,
  } = useDialogState();

  // TODO: Сделать инициализацию данных вместе с initialValues /
  //  Исправить использование useRef
  const autoSaveProcessSysName = useRef(null);

  useEffect(() => {
    if (!isTemplatePage) return;
    if (initialValues?.processAutoSaveId) {
      autoSaveProcessSysName.current = initialValues?.processAutoSaveId;
    }
  }, [initialValues]);

  const processIntegrations = useMemo(() => {
    return ((initialValues?.integration || '') as string).split(';').filter(Boolean);
  }, [initialValues, isOpen]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [versionToggleAnchorEl, setVersionToggleAnchorEl] = useState<null | HTMLElement>(null);
  const [editedVersionValues, setEditedVersionValues] = useState(null);
  const [currentProcessIndex, setCurrentProcessIndex] = useState<number>(0);
  const [currentConditionId, setCurrentConditionId] = useState<string>('');
  const [currentParallelBranchesGroupId, setCurrentParallelBranchesGroupId] = useState<string>('');
  const [selectedItemType, setSelectedItemType] = useState<'processStep' | 'condition' | 'parallelBranchesGroups'>('processStep');
  const [loading, setLoading] = useState<boolean>(false);
  const [directories, setDirectories] = useState([]);
  const [allDirectories, setAllDirectories] = useState([]);

  const [isProcessPublishing, setIsProcessPublishing] = useState<boolean>(false);
  const [directManagerSteps, setDirectManagerSteps] = useState<number[]>([]);
  const [performerSelectionSteps, setPerformerSelectionSteps] = useState<number[]>([]);
  const [isAutostartActive, setAutostartActive] = useState<boolean>(false);
  const [autostartSettings, setAutostartSettings] = useState<AutostartSettings>(INITIAL_AUTOSTART_SETTINGS as AutostartSettings);

  const [languageTabs, setLanguageTabs] = useState<Language[]>([i18n.language as Language]);
  const [languageTabsWithErrors, setLanguageTabsWithErrors] = useState<Language[]>([]);
  const [languagesWithErrors, setLanguagesWithErrors] = useState<Language[]>([]);
  const [tabsWithErrors, setTabsWithErrors] = useState<SetupTab[]>([]);
  const [currentLanguageTab, setCurrentLanguageTab] = useState<Language>(i18n.language as Language);

  const [processIcon, setProcessIcon] = useState<string | null>(null);
  const [processGroup, setProcessGroup] = useState<string | null>(null);

  const [isVersionToggleOpen, setVersionToggleOpen] = useState<boolean>(false);
  const [isEditDisabled, setEditDisabled] = useState<boolean>(false);
  const [isSubmitAvailable, setIsSubmitAvailable] = useState<boolean>(!initialValues?.published);
  const [currentLanguage, setCurrentLanguage] = useState<Language | null>(null);

  const [currentTab, setCurrentTab] = useState<SetupTab>('steps');

  const [isDragActive, setDragActive] = useState<boolean>(false);
  const [dragType, setDragType] = useState<'fields' | 'documents'>('fields');
  const [focusedStepFieldId, setFocusedStepFieldId] = useState<string>('');
  const [focusedDocumentId, setFocusedDocumentId] = useState<string>('');
  const [previewedDocumentTemplateId, setPreviewedDocumentTemplateId] = useState<number>(-1);
  const [previewDocumentToolbarFields, setPreviewDocumentToolbarFields] = useState<{ title: string, value: string }[]>([]);
  const [previewDocumentSignatories, setPreviewDocumentSignatories] = useState<{value: string, stepOrder: string}[]>([]);
  const [isNewField, setIsNewField] = useState<boolean>(false);
  const [isNewDocument, setIsNewDocument] = useState<boolean>(false);
  const [selectedStepField, setSelectedStepField] = useState<string>('');
  const [summaryFields, setSummaryFields] = useState([]); // used to show fields in card preview
  // on new step creation we copy summaryFields from previous step, but we can't use steps saved as previewSavingType === 'current'
  const [currentTypeSummaryFields, setCurrentTypeSummaryFields] = useState<string[]>([]); // contains stepOrder of steps saved as `previewSavingType === 'current'

  const [conditions, setConditions] = useState<ConditionStep[]>([]);
  const [parallelBranchesGroups, setParallelBranchesGroups] = useState<ParallelBranchesGroup[]>([]);
  // TODO - correct types for documents list
  const [documents, setDocuments] = useState<any[]>([]);

  const [formErrors, setFormErrors] = useState([]);

  const [firstErrorStepIndex, setFirstErrorStepIndex] = useState<null | number>(null);
  const [firstErrorStepEl, setFirstErrorStepEl] = useState<any>(null);

  const isReadonlyView = useMemo(() => !((initialValues && isTemplateProcessEditingAvailable) || (!initialValues && isTemplateProcessCreationAvailable)),
    [isTemplateProcessCreationAvailable, isTemplateProcessEditingAvailable, initialValues]);

  const languages = useMemo((): Language[] => languageTabs,
    [languageTabs]);


  const { register, control, clearErrors, handleSubmit, reset, formState, trigger } = useForm({
    shouldUnregister: false,
    defaultValues: {
      processSteps: DEFAULT_PROCESS,
      formProcess: [
        {
          language: i18n.language as Language,
          processName: '',
          description: '',
        },
      ],
    },
  });

  const { fields, append, replace, update: formUpdate } = useFieldArray({
    control,
    name: 'processSteps',
  });

  const {
    fields: fieldsSettingsForm,
    append: appendSettingsForm,
    replace: replaceSettingsForm,
    update: formUpdateSettingsForm,
    remove: removeSettingsForm,
  } = useFieldArray({
    control,
    name: 'formProcess',
  });

  const allProcessStepsFields = useMemo((): AllProcessFieldsListItem[] => {
    const processFields = fields as unknown as ProcessStep[];

    const stepsFields = processFields?.map(processField => processField?.fields?.map(field => {
      const fieldStepParallelStepsGroup = parallelBranchesGroups?.find(g => g.steps.flat().includes(processField?.stepOrder));
      return {
        field,
        firstAppearanceStep: processField?.stepOrder,
        isFieldFromConditionStep: processField.isConditionBranchStep,
        isFieldFromParallelBranchesGroupStep: processField.isParallelBranchesGroupStep,
        fieldParallelBranchesGroupStep: fieldStepParallelStepsGroup ? fieldStepParallelStepsGroup.id : '',
      };
    })).flat();
    return stepsFields.filter((field, i, a) =>
      a.findIndex(f => f?.field.id === field?.field.id) === i);
  }, [fields, parallelBranchesGroups]);

  const allUsedGlossaries = useMemo(() =>
      allProcessStepsFields.reduce((acc, item) => {
        if (item.field.component === GLOSSARY_FIELD_KEY && !acc.includes(item.field.params[GLOSSARY_FIELD_DIRECTORY_PARAM])) {
          acc = [...acc, (item.field.params[GLOSSARY_FIELD_DIRECTORY_PARAM] as string)?.replace('parent/', '')];
        }
        return acc;
      }, [])
    , [allProcessStepsFields]);

  const update = useCallback((index, values) => {
    setIsSubmitAvailable(true);
    formUpdate(index, values);
  }, [setIsSubmitAvailable, formUpdate]);

  useEffect(() => {
    getGlossaryAsTree(null, true).then(directories => {
      setDirectories(directories);
    }).catch((error) => {
      console.log('error loading glossaries as tree structure');
      NotificationManager.error(t('errors.somethingIsWrong'), `${t('customProcesses.notifications.error')}!`);
    });
  }, []);

  useEffect(() => {
    getGlossaryAsTree(null, true, false).then(directories => {
      setAllDirectories(directories);
    }).catch((error) => {
      console.log('error loading glossaries as tree structure');
      NotificationManager.error(t('errors.somethingIsWrong'), `${t('customProcesses.notifications.error')}!`);
    });
  }, []);

  const clearData = (): void => {
    setAnchorEl(null);
    setCurrentProcessIndex(0);
    setSelectedItemType('processStep');
    setConditions([]);
    setDocuments([]);
    setParallelBranchesGroups([]);
    setIsProcessPublishing(false);
    setCurrentLanguage(null);
    setFocusedStepFieldId('');
    setFocusedDocumentId('');
    setSummaryFields([]);
    setTabsWithErrors([]);
    setCurrentTab('steps');
    setLanguagesWithErrors([]);
    setFormErrors([]);
    setAutostartSettings(() => INITIAL_AUTOSTART_SETTINGS as AutostartSettings);
    setAutostartActive(() => false);
    setEditDisabled(() => false);
    setVersionToggleAnchorEl(() => null);
    setVersionToggleOpen(() => false);
    setDirectManagerSteps(() => []);
    setPerformerSelectionSteps(() => []);
    autoSaveProcessSysName.current = null;

    reset({
      processSteps: DEFAULT_PROCESS,
    });
  };

  const {
    hasWorkCalendar,
    assigneeTypeChanged,
    initiateProcessSteps,
    initiateAutostartSettings,
    initiateProcessSettings,
    handleAnchorClose,
    handleStepNameChange,
    handleStepDescriptionChange,
    handleInitiatorsChange,
    handleAssigneeTypeChange,
    handleDirectManagerToggle,
    handlePerformerSelectionToggle,
    handleSLACheckboxToggle,
    handleStepExecutionTimeChange,
    handleActiveLanguageSelect,
    handleAutostartChange,
    handleAutostartDisablingConfirmationDialogConfirm,
    handleAutostartActivationConfirmationDialogConfirm,
  } = useProcessStepForm({
    fields,
    initialValues,
    templateSettings,
    currentProcessIndex,
    allProcessStepsFields,
    directManagerSteps,
    performerSelectionSteps,
    formErrors,
    languages,
    currentLanguage,
    isOpen,
    isAutostartActive,
    needResetProcessForm,
    conditions,
    parallelBranchesGroups,
    documents,
    setParallelBranchesGroups,
    setConditions,
    setDocuments,
    setFormErrors,
    setProcessIcon,
    setProcessGroup,
    setSummaryFields,
    setDirectManagerSteps,
    setCurrentLanguage,
    setPerformerSelectionSteps,
    setAutostartSettings,
    setLanguageTabs,
    setAutostartActive,
    setAnchorEl,
    setNeedResetProcessForm,
    clearData,
    replaceSettingsForm,
    update,
    replace,
    handleAutostartDisablingConfirmationDialogOpen,
    handleAutostartActivationConfirmationDialogOpen,
    handleAutostartActivationNotPossibleDialogOpen
  });

  const {
    hasErrors,
    handleCheck,
    getBeatifiedData,
    handleDialogSubmit,
  } = useProcessPublication({
    fields,
    conditions,
    parallelBranchesGroups,
    setNeedResetProcessForm,
    documents,
    initialValues,
    publishedVersionValues,
    summaryFields,
    processIcon,
    processGroup,
    isAutostartActive,
    autostartSettings,
    autoSaveProcessSysName,
    languages,
    directManagerSteps,
    performerSelectionSteps,
    allProcessStepsFields,
    languageTabs,
    fieldsSettingsForm,
    trigger,
    clearData,
    setFormErrors,
    clearErrors,
    formErrors,
    setCurrentTab,
    setCurrentLanguage,
    setLanguageTabsWithErrors,
    setLanguagesWithErrors,
    setCurrentProcessIndex,
    setCurrentParallelBranchesGroupId,
    setCurrentConditionId,
    setSelectedItemType,
    setTabsWithErrors,
    setFirstErrorStepIndex,
    setIsProcessPublishing,
    setFocusedStepFieldId,
    setFocusedDocumentId,
    setIsNewDocument,
    setIsNewField,
    onFormSubmit,
    handleSubmit,
    onClose,
    handlePublishedProcessesLimitDialogOpen,
  });

  const {
    handleStepDelete,
    handleAddPreviousStep,
    handleAddProcessStep,
    handleProcessSelect,
    handleConditionSelect,
    handleAddProcessConditionStep,
  } = useProcessSteps({
      fields,
      allProcessStepsFields,
      performerSelectionSteps,
      conditions,
      setConditions,
      parallelBranchesGroups,
      setParallelBranchesGroups,
      isAutostartActive,
      languages,
      currentLanguage,
      nameFieldRef,
      gridPathRef,
      firstErrorStepEl,
      setSummaryFields,
      setIsSubmitAvailable,
      setCurrentProcessIndex,
      setCurrentConditionId,
      setSelectedItemType,
      setFocusedStepFieldId,
      setFocusedDocumentId,
      setSelectedStepField,
      setDirectManagerSteps,
      setPerformerSelectionSteps,
      setFirstErrorStepEl,
      setFirstErrorStepIndex,
      append,
      replace,
      handleConditionAdditionNotPossibleDialogOpen,
      currentTypeSummaryFields,
      setCurrentTypeSummaryFields
    },
  );

  const {
    handleProcessPreviewSavingStart,
    updateStepPreview,
    handleStepFieldDeleteConfirmation,
    handleStepFieldDeleteStart,
    handleStepFieldValuesChange,
    toggleStepFieldVisibility,
    handleStepFieldsAdd,
    handleStepFieldsMove,
    toggleStepFieldVisibilityWithOptionalConfirmation,
    handleToggleSelectedFieldVisibilityConfirmation,
  } = useStepFieldsActions({
      fields,
      conditions,
      parallelBranchesGroups,
      currentProcessIndex,
      setIsSubmitAvailable,
      focusedStepFieldId,
      selectedStepField,
      documents,
      setDocuments,
      allProcessStepsFields,
      summaryFields,
      languages,
      update,
      replace,
      setSelectedStepField,
      setFocusedStepFieldId,
      setFocusedDocumentId,
      setIsNewField,
      setConditions,
      setSummaryFields,
      handleMaximumFieldsDialogOpen,
      handleDeleteDialogOpen,
      handleCardPreviewSavingTypeDialogOpen,
      handleHidePreviewFieldConfirmationModalOpen,
      setCurrentTypeSummaryFields
    },
  );

  const {
    handleChangesRollback,
    handleProcessStepsAutoSave,
    handleDisplayedVersionToggle,
  } = useProcessAutoSave({
    fields,
    conditions,
    initialValues,
    parallelBranchesGroups,
    documents,
    editedVersionValues,
    publishedVersionValues,
    allProcessStepsFields,
    summaryFields,
    directManagerSteps,
    performerSelectionSteps,
    autoSaveProcessSysName,
    isAutostartActive,
    autostartSettings,
    fieldsSettingsForm,
    isEditDisabled,
    setLoading,
    setCurrentProcessIndex,
    setCurrentConditionId,
    setSelectedItemType,
    setFocusedStepFieldId,
    setIsSubmitAvailable,
    setEditDisabled,
    setVersionToggleOpen,
    setVersionToggleAnchorEl,
    setEditedVersionValues,
    getBeatifiedData,
    initiateProcessSteps,
    initiateAutostartSettings,
    initiateProcessSettings,
  });

  const {
    handleConditionValuesChange,
    handleConditionDelete,
    handleConditionBranchStepAdd,
  } = useProcessConditions({
    fields,
    conditions,
    parallelBranchesGroups,
    performerSelectionSteps,
    allProcessStepsFields,
    languages,
    setFocusedStepFieldId,
    nameFieldRef,
    setDirectManagerSteps,
    setPerformerSelectionSteps,
    setSelectedStepField,
    setSummaryFields,
    replace,
    setConditions,
    setParallelBranchesGroups,
    setCurrentProcessIndex,
    setSelectedItemType,
    setIsSubmitAvailable,
    handleStepDelete,
    currentTypeSummaryFields
  });

  const {
    handleAddParallelStepsGroup,
    handleParallelStepsGroupSelect,
    handleParallelStepsGroupValuesChange,
    handleParallelStepsGroupBranchAdd,
    handleParallelStepsGroupBranchDelete,
    handleParallelStepsGroupDelete,
    handleParallelBranchStepAdd,
  } = useParallelBranchesGroups({
    fields,
    allProcessStepsFields,
    conditions,
    setConditions,
    parallelBranchesGroups,
    setParallelBranchesGroups,
    languages,
    nameFieldRef,
    gridPathRef,
    setIsSubmitAvailable,
    setCurrentProcessIndex,
    setCurrentParallelBranchesGroupId,
    setSelectedItemType,
    setFocusedStepFieldId,
    setFocusedDocumentId,
    setDirectManagerSteps,
    setPerformerSelectionSteps,
    setSelectedStepField,
    setSummaryFields,
    append,
    replace,
    currentTypeSummaryFields,
  });

  const {
    handleDocumentAdd,
    handleDocumentVisibilityChange,
    handleDocumentFieldsAdd,
    handleDocumentParamsChange,
    handleDocumentChangeUndo,
    handleDocumentDelete,
    handleDocumentPreviewOpen,
    handleDocumentPreviewClose
  } = useProcessDocuments({
    fields,
    conditions,
    parallelBranchesGroups,
    directories,
    allDirectories,
    documents,
    currentProcessIndex,
    focusedDocumentId,
    isNewDocument,
    setFocusedDocumentId,
    setDocuments,
    handleStepFieldsAdd,
    handleStepsFieldsDelete: handleStepFieldDeleteConfirmation,
    setIsNewDocument,
    setIsSubmitAvailable,
    setPreviewedDocumentTemplateId,
    setPreviewDocumentToolbarFields,
    setPreviewDocumentSignatories,
    replace
  });

  const {
    handleDragStart,
    handleDragEnd,
  } = useDragAndDropActions({
      documents,
      focusedDocumentId,
      dragType,
      setDragActive,
      setDragType,
      setDocuments,
      handleStepFieldsAdd,
      handleStepFieldsMove,
      handleDocumentAdd,
      // handleDocumentMove,
      handleDocumentVisibilityChange,
    },
  );

  const processNames = useMemo((): { [key: string]: string } =>
      (fieldsSettingsForm as unknown as FormProcess[])?.reduce((acc, item) => ({
        ...acc,
        [item.language]: item.processName,
      }), {}) ?? {},
    [fieldsSettingsForm]);

  const processDisplayName = useMemo((): string => processNames[currentLanguage] || '',
    [currentLanguage, processNames]);

  const processDescriptions = useMemo((): { [key: string]: string } =>
      (fieldsSettingsForm as unknown as FormProcess[])?.reduce((acc, item) => ({
        ...acc,
        [item.language]: item.description,
      }), {}) ?? {},
    [fieldsSettingsForm]);

  const processDisplayDescription = useMemo((): string => processDescriptions[currentLanguage] || '',
    [currentLanguage, processDescriptions]);

  const updateSettingsForm = useCallback((index, values) => {
    setIsSubmitAvailable(true);
    formUpdateSettingsForm(index, values);
  }, [setIsSubmitAvailable, formUpdateSettingsForm]);

  useEffect(() => {
    if (!isOpen) {
      setIsSubmitAvailable(false);
    } else {
      setIsSubmitAvailable(!publishedVersionValues?.published || haveTemplateSettingsChanged || publishedVersionValues?.draftIsChange);
    }
  }, [isOpen, initialValues, haveTemplateSettingsChanged, publishedVersionValues]);

  const handleAnchorClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const onAutostartActionsClick = (event: MouseEvent<HTMLElement> | MouseEvent<HTMLButtonElement>): void => {
    handleAutostartActionsClick(event, null);
  };

  const handleAutostartSettingsChange = (value) => {
    setIsSubmitAvailable(true);
    setAutostartSettings(() => value);
  };

  const handleBackButtonClick = () => {
    onBackButtonClick();
    setCurrentTab('steps');
    const templateProcessSettings = {
      formProcess: fieldsSettingsForm,
      processIcon,
    };
    clearData();
    handleAnchorClose();
    setTemplateSettings(templateProcessSettings);
    setNeedResetProcessForm(() => true);
  };

  useEffect(() => {
    setCurrentLanguageTab(languages[0]);
  }, [currentTab]);

  return {
    fields,
    currentProcessIndex,
    currentConditionId,
    currentParallelBranchesGroupId,
    selectedItemType,
    processIntegrations,
    allProcessStepsFields,
    allUsedGlossaries,
    focusedStepFieldId,
    isNewField,
    directManagerSteps,
    performerSelectionSteps,
    summaryFields,
    processIcon,
    processGroup,
    isAutostartActive,
    autostartSettings,
    autoSaveProcessSysName,
    processDisplayName,
    processDisplayDescription,
    fieldsSettingsForm,
    formState,
    formErrors,
    anchorEl,
    gridPathRef,
    nameFieldRef,
    versionToggleAnchorEl,
    firstErrorStepEl,
    currentTab,
    tabsWithErrors,
    hasErrors,
    conditions,
    parallelBranchesGroups,
    documents,
    languages,
    currentLanguage,
    currentLanguageTab,
    languageTabs,
    languagesWithErrors,
    languageTabsWithErrors,
    firstErrorStepIndex,
    loading,
    isVersionToggleOpen,
    isReadonlyView,
    isSubmitAvailable,
    isEditDisabled,
    isProcessPublishing,
    isDragActive,
    dragType,
    hasWorkCalendar,
    assigneeTypeChanged,
    focusedDocumentId,
    selectedStepField,
    previewDocumentToolbarFields,
    previewDocumentSignatories,
    previewedDocumentTemplateId,
    setPreviewedDocumentTemplateId,
    setFocusedDocumentId,
    setConditions,
    setParallelBranchesGroups,
    setCurrentTab,
    setVersionToggleAnchorEl,
    setVersionToggleOpen,
    setIsSubmitAvailable,
    setCurrentLanguageTab,
    setLanguageTabs,
    setProcessIcon,
    setProcessGroup,
    setFirstErrorStepEl,
    setFocusedStepFieldId,
    setIsNewField,
    onAutostartActionsClick,
    handleBackButtonClick,
    handleDragStart,
    handleDragEnd,
    handleAnchorClose,
    handleAnchorClick,
    handleCheck,
    handleDialogSubmit,
    handleProcessStepsAutoSave,
    handleDisplayedVersionToggle,
    handleChangesRollback,
    handleActiveLanguageSelect,
    handleAddPreviousStep,
    handleAddProcessStep,
    handleAddProcessConditionStep,
    handleProcessSelect,
    handleConditionSelect,
    handleStepFieldValuesChange,
    handleAutostartChange,
    handleAutostartSettingsChange,
    handleStepNameChange,
    handleStepDescriptionChange,
    handleInitiatorsChange,
    handleAssigneeTypeChange,
    handleDirectManagerToggle,
    handlePerformerSelectionToggle,
    handleSLACheckboxToggle,
    handleStepExecutionTimeChange,
    handleStepDelete,
    handleStepFieldDeleteStart,
    handleProcessPreviewSavingStart,
    updateStepPreview,
    toggleStepFieldVisibility,
    toggleStepFieldVisibilityWithOptionalConfirmation,
    handleToggleSelectedFieldVisibilityConfirmation,
    clearErrors,
    register,
    control,
    trigger,
    updateSettingsForm,
    appendSettingsForm,
    setCurrentLanguage,
    removeSettingsForm,
    isDialogOpen,
    handleDialogOpen,
    handleDialogClose,
    isDeleteDialogOpen,
    handleDeleteDialogClose,
    handleStepFieldDeleteConfirmation,
    isMaximumFieldsDialogOpen,
    handleMaximumFieldsDialogClose,
    isAutostartDisablingConfirmationDialogOpen,
    handleAutostartDisablingConfirmationDialogClose,
    handleAutostartDisablingConfirmationDialogConfirm,
    isAutostartActivationConfirmationDialogOpen,
    handleAutostartActivationConfirmationDialogClose,
    handleAutostartActivationConfirmationDialogConfirm,
    isConditionAdditionNotPossibleDialogOpen,
    handleConditionAdditionNotPossibleDialogClose,
    handleConditionAdditionNotPossibleDialogOpen,
    isAutostartActivationNotPossibleDialogOpen,
    handleAutostartActivationNotPossibleDialogOpen,
    handleAutostartActivationNotPossibleDialogClose,
    isDocumentSignatureStepRemovalDialogOpen,
    handleDocumentSignatureStepRemovalDialogOpen,
    handleDocumentSignatureStepRemovalDialogClose,
    isChangesRollbackConfirmationDialogOpen,
    handleChangesRollbackConfirmationDialogOpen,
    handleChangesRollbackConfirmationDialogClose,
    isConditionModalOpen,
    handleConditionModalOpen,
    handleConditionModalClose,
    isCardPreviewDialogOpen,
    handleCardPreviewDialogOpen,
    handleCardPreviewDialogClose,
    isCardPreviewSavingTypeDialogOpen,
    handleCardPreviewSavingTypeDialogOpen,
    handleCardPreviewSavingTypeDialogClose,
    isHidePreviewFieldConfirmationModalOpen,
    handleHidePreviewFieldConfirmationModalOpen,
    handleHidePreviewFieldConfirmationModalClose,
    handleConditionValuesChange,
    handleConditionDelete,
    handleAddParallelStepsGroup,
    handleParallelStepsGroupSelect,
    handleParallelStepsGroupValuesChange,
    handleParallelStepsGroupBranchAdd,
    handleParallelStepsGroupBranchDelete,
    handleParallelStepsGroupDelete,
    handleParallelBranchStepAdd,
    handleConditionBranchStepAdd,
    initiateProcessSteps,
    setFormErrors,
    handleDocumentAdd,
    handleDocumentFieldsAdd,
    handleDocumentParamsChange,
    handleDocumentChangeUndo,
    setIsNewDocument,
    handleDocumentDelete,
    handleDocumentVisibilityChange,
    handleDocumentPreviewOpen,
    handleDocumentPreviewClose
  };
};
