export const DATE_COMPARISON_TYPES = {
  AFTER: '>',
  SAME_OR_AFTER: '>=',
  BEFORE: '<',
  SAME_OR_BEFORE: '<='
}


export const DATE_COMPARISON_TYPES_LOCALIZATION_KEYS = {
  '>': 'form_components.date.errors.mustBeAfter',
  '>=': 'form_components.date.errors.mustBeAfterOrEqual',
  '<': 'form_components.date.errors.mustBeBefore',
  '<=': 'form_components.date.errors.mustBeBeforeOrEqual'
}
