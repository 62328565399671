import {useEffect, useState} from "react";
import { useDispatch } from 'react-redux';

import {getRandomNumber} from "./utils";
import {getProcessIconsList, useProcessIconList} from "store/requests";

interface TemplateIconSelectButtonHookProps {
  activeIcon: null | string;
  handleIconSelect: (processIcon: string) => void;
}

export const useTemplateIconSelectButton = ({
  activeIcon,
  handleIconSelect
}: TemplateIconSelectButtonHookProps) => {
  const dispatch = useDispatch();

  const { data: processIcons, errorMessage, status } = useProcessIconList()

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [selectedProcessIcon, setSelectedProcessIcon] = useState<string | null>(null);

  const handleProcessIconSelect = (processIcon: string): void => {
    if (processIcon === selectedProcessIcon) {
      setSelectedProcessIcon(null);
      return;
    }

    setSelectedProcessIcon(processIcon);
  }

  const handleDialogOpen = (): void => {
    setIsDialogOpen(true);
  }

  const handleDialogClose = (): void => {
    setIsDialogOpen(false);
  }

  useEffect(() => {
    if (processIcons.length) {
      const isActiveIconExists = Boolean(
        activeIcon &&
        processIcons.find((processIcon: string) => processIcon === activeIcon)
      );

      if (isActiveIconExists) {
        setSelectedProcessIcon(activeIcon);
        return;
      }

      const randomProcessIconIndex = getRandomNumber(0, processIcons.length);
      const randomProcessIcon = processIcons[randomProcessIconIndex];

      handleIconSelect(randomProcessIcon);
      setSelectedProcessIcon(randomProcessIcon);
    }
  }, [processIcons, activeIcon]);

  useEffect(() => {
    if (!errorMessage && !processIcons?.length) {
      dispatch(getProcessIconsList());
    }
  }, [dispatch]);

  return {
    isDialogOpen,
    selectedProcessIcon,
    handleDialogOpen,
    handleDialogClose,
    handleProcessIconSelect
  }
}
