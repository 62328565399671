import React  from 'react';
import MaskedInput from 'react-text-mask';

export const CustomMaskedInput = ({ mask = [], showMask = true, ...props }) => (
  <MaskedInput
    {...props}
    mask={mask}
    placeholderChar={'\u2000'}
    showMask={showMask}
    guide={false}
  />
);
