import { createAction } from 'redux-actions';

import { 
  getHCMSUsersListByCompany, 
  getOldIdUsersListByCompany, 
  getPositions, 
} from 'api/requests';

export const USER_ACTIONS = {
  HCMS_USERS_LIST: 'HCMS_USERS_LIST',
  OLD_ID_USERS_LIST: "OLD_ID_USERS_LIST",
  USERS_POSITIONS_LIST: "USERS_POSITIONS_LIST",
};

export const getHCMSUsersAction = createAction(
  USER_ACTIONS.HCMS_USERS_LIST,
  (companyId) => getHCMSUsersListByCompany(companyId),
);

export const getUsersPositionsAction = createAction(
  USER_ACTIONS.USERS_POSITIONS_LIST,
  () => getPositions(),
);

export const getOldIdUsersAction = createAction(
  USER_ACTIONS.OLD_ID_USERS_LIST,
  () => getOldIdUsersListByCompany(),
);
