import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Box, Button, Menu, MenuItem, Modal, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';

import SettingsIcon from 'assets/images/icons/settings_icon.svg';
import { ErrorModal, Spinner } from 'components';
import { clearTaskData, getTaskById, setDocumentSignatureErrorStatus, setTaskLoading, useTaskState } from 'store/requests';
import useStyles from './useStyles';
import { DataViewHeader } from '../DataViewHeader';
import useTaskForm from 'pages/Task/TaskForm/useTaskForm';
import { FormProvider } from 'react-hook-form';
import useTaskDataView from '../../hooks/useTaskDataView';
import { CombinedStepType, StepType } from '../Tabs/HistoryTab/history.types';
import { DataViewContent } from '../DataViewContent';
import { DataViewTabs } from '../DataViewTabs';

import RefreshModalIcon from 'assets/images/icons/refresh-page-modal-icon.svg';
import { ActionCommentDialog } from 'pages/Task/TaskForm/ActionCommentDialog';
import { SelectEmployeeDialog } from 'pages/Task/TaskForm/SelectEmployeeDialog';
import { TaskPdfGenerator } from 'pages/Task/TaskForm/TaskPdfGenerator';
import { FormButtonList } from 'pages/Task/TaskForm/ActionsBar/FormButtonList';
import { ATTACHMENTS_TAB_NAME, INFORMATION_TAB_NAME } from '../Tabs/FormTab/constants';
import { useUserProfile, useUsersRole } from 'hooks';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ChangeAssigneeCallbackContext, ChangeAssigneeData } from 'pages';
import { ChangeAssignee } from 'components/Modal/ChangeAssignee';
import { ConfirmationModal } from 'components/Modal/ConfirmationModal';
import { useDispatch } from 'react-redux';
import { deleteAdminPanelRequest } from 'api/requests';
import { BpmTaskExtraTab } from 'components/TaskDetails/types';
import { setSelectedTask, setSelectedTaskWatchers } from 'store/approvals';
import { TaskParametersType, UserType } from 'types';

// import { ValidationErrorSnackbar } from '../ValidationErrorSnackbar/ValidationErrorSnackbar';
import { RequestStatusesEnum } from '../../../../pages/AdminPanel/AdminPanel.types';
import {
  ACTIONS_WITHOUT_DOCUMENT_SIGNATURE_VALIDATION
} from '../../../../pages/Task/TaskForm/ActionsBar/FormButtonList/useFormButtonList';

type Props = {
  handleClose: () => void;
};

export const CHANGE_ASSIGNEE_AVAILABLE_ROUTES = ['/process/:id', '/task/:id'];

const ATTACHMENTS_DEFAULT_VALUE = 'file_attachment';

export const TaskDetailsContent = ({ handleClose }: Props) => {
  const { data: bpmTask, loading, componentsDataLoadedTable, documents } = useTaskState();
  const { isUserAdmin } = useUsersRole();
  const { id: profileId } = useUserProfile();
  const { t } = useTranslation();

  const isCurrentUserAssignee = useMemo(() => bpmTask?.isAssignee(profileId), [profileId]);

  const [changeAssigneeData, setChangeAssigneeData] = useState<ChangeAssigneeData>(null);
  const [isChangeAssigneeAvailiable, setIsChangeAssigneeAvailiable] = useState(false);
  const routerMatch = useRouteMatch();
  const dispatch = useDispatch();
  const classes = useStyles();
  const isButtonClicked = useRef(false);
  const buttonActionArgs = useRef(null);

  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);

  const [steps, setSteps] = useState<(StepType & CombinedStepType)[]>([]);
  const [isVisibleDeleteModal, setIsVisibleDeleteModal] = useState(false);
  const [isErrorModalOpen, setErrorModalOpen] = useState(false);
  const [isCurrentUserWatcher, setIsUserCurrentWatcher] = useState(false);
  const [selectedWatchers, setSelectedWatchers] = useState<UserType[]>([]);
  const [isValidationErrorSnackbarOpen, setValidationErrorSnackbarOpen] = useState<boolean>(false);

  const {
    watchers,
    tabsList,
    tabsWithErrors,
    unreadTabs,
    handleTabChange,
    activeTab,
    setActiveTab,
    setTabCounter,
    setTabsWithErrors,
    scrollbarsRef,
    isShownPersonalInfo,
    errorMessageLines,
    setErrorMessageLines,
  } = useTaskDataView({
    steps,
  });

  const {
    formMethods,
    commentPopoverInfo,
    buttonDisabled,
    setButtonDisabled,
    alwaysActiveButtons,
    setAlwaysActiveButtons,
    selectEmployeePopoverInfo,
    setActionComment,
    loading: taskFormLoading,
    error: formError,
    handleAction,
    setActionEmployee,
    commentPopoverSubmitAction,
    formErrorsRefresh,
    setAssigneeVariableName,
  } = useTaskForm({ selectedWatchers });

  const handleWatchersSelect = (_selectedWatchersIdList, selectedWatcherObjectList) => {
    setSelectedWatchers(selectedWatcherObjectList);
  };

  const loadTask = () => {
    dispatch(setTaskLoading());
    dispatch(getTaskById(bpmTask.businessTask.taskId));
  };

  const handleDeleteRequest = async () => {
    if (!bpmTask?.processInstanceId) return;
    return deleteAdminPanelRequest(bpmTask?.processInstanceId);
  };

  const onSuccessDeleteRequest = () => {
    dispatch(clearTaskData());
    NotificationManager.success(t('customProcesses.notifications.deletion_success'));
    history.replace('/approvals');
  };

  const handleButtonClick = async (arg: any) => {
    setErrorMessageLines(() => []);
    if (arg?.componentParams?.useWithoutRequiredFieldsFilled) {
      handleAction(arg);
      return;
    }

    try {
      isButtonClicked.current = true;
      buttonActionArgs.current = arg;
      await formMethods.trigger();
    } catch (error) {
      console.warn(error);
    }
  };

  const checkValidationErrors = () => {
    const { errors } = formMethods.formState;

    let hasErrorsNotInModal = false;
    const fieldsWithErrors = Object.keys(errors);
    fieldsWithErrors.forEach((fieldName) => {
      const fieldAttribute = bpmTask.attributes.find((attr) => attr.name === fieldName);
      if (fieldAttribute) {
        const fieldGroup = fieldAttribute.groupName;
        if (fieldGroup && !fieldGroup.includes('#hidden')) {
          hasErrorsNotInModal = true;
        }
      }
    });

    return !hasErrorsNotInModal;
  };

  useEffect(() => {
    if (!formMethods.formState.isValidating && isButtonClicked.current) {
      const valid = checkValidationErrors();

      const shouldSkipDocumentsSignaturesValidation = ACTIONS_WITHOUT_DOCUMENT_SIGNATURE_VALIDATION.includes(buttonActionArgs?.current?.action);

      let hasNotSignedDocuments = false;
      if (!shouldSkipDocumentsSignaturesValidation) {
        hasNotSignedDocuments = documents.some(
          (document) => document.signRequiredSteps.includes(bpmTask.currentAction.stepperOrder) && !document.isSigned
        );
        dispatch(setDocumentSignatureErrorStatus(hasNotSignedDocuments));
      }

      if (valid && (!hasNotSignedDocuments || shouldSkipDocumentsSignaturesValidation)) {
        handleAction(buttonActionArgs.current);
      } else {
        const errorMessages = [];
        const fieldsWithRequiredErrors = Object.keys(formMethods.formState.errors).filter(fieldName => {
          return formMethods.formState.errors[fieldName].type === 'required';
        });

        if (fieldsWithRequiredErrors.length > 0) {
          errorMessages.push('fields');
        }
        if (hasNotSignedDocuments) {
          errorMessages.push('documents');
        }
        setErrorMessageLines(() => errorMessages);
      }

      isButtonClicked.current = false;
      buttonActionArgs.current = null;
    }
  }, [formMethods.formState.isValidating]);

  const isComponentsDataLoading: boolean = useMemo((): boolean => Object.values(componentsDataLoadedTable).some((isLoading) => isLoading), [
    componentsDataLoadedTable,
  ]);

  const changeAssigneeDataCallbackContextValue = useMemo(() => {
    return {
      action: ({ assigneeId, taskId, stepName }) => {
        setChangeAssigneeData({
          assigneeId,
          taskId,
          stepName,
        });
      },
      isAvailiable: isChangeAssigneeAvailiable,
    };
  }, [isChangeAssigneeAvailiable]);

  const ButtonManageRequest = useMemo(() => {
    if (
      loading ||
      !isUserAdmin ||
      !CHANGE_ASSIGNEE_AVAILABLE_ROUTES.includes(routerMatch.path) ||
      bpmTask?.businessTask?.taskStatus === RequestStatusesEnum.Completed
    ) {
      return null;
    }

    return (
      <Button onClick={(e) => setAnchorEl(e.currentTarget)} color="secondary" className={classes.button}>
        <img src={SettingsIcon} alt="settings icon" />
        {t('buttons.manageRequest')}
      </Button>
    );
  }, [isUserAdmin, routerMatch.path, loading]);

  const validateDocumentsSignatures = useMemo(() => {
    const docflowField = bpmTask?.attributes?.find((attr) => attr.name.includes('documentFromDocflow-'));
    if (docflowField && docflowField?.component !== 'hidden-field') {
      const params = JSON.parse(docflowField?.componentParams || '{}');
      const signRequiredSteps = params?.signRequiredSteps || [];
      if (signRequiredSteps?.includes(bpmTask?.currentAction?.stepperOrder)) {
        return true;
      }
    }
    return false;
  }, [bpmTask]);

  const ButtonsComponent = useMemo(() => {
    return (
      <FormButtonList
        taskDetailsVariant
        showButtonsOnBothSides
        checkIfTaskDataIsOutdated
        onOutdatedTaskClick={() => setErrorModalOpen(true)}
        alwaysActiveButtons={alwaysActiveButtons}
        disabled={buttonDisabled || isComponentsDataLoading}
        isWatcher={isCurrentUserWatcher}
        buttonDisabled={buttonDisabled}
        onClick={handleButtonClick}
        extraButtons={ButtonManageRequest}
        extraButtonsIndex={1}
        validateDocumentsSignatures={validateDocumentsSignatures}
      />
    );
  }, [alwaysActiveButtons, buttonDisabled, handleAction, isComponentsDataLoading, watchers, profileId]);

  useEffect(() => {
    if (!isUserAdmin) return;

    if (CHANGE_ASSIGNEE_AVAILABLE_ROUTES.includes(routerMatch.path)) {
      setIsChangeAssigneeAvailiable(true);
    }
  }, [isUserAdmin, routerMatch]);

  useEffect(() => {
    const fieldsWithErrors = Object.keys(formMethods.formState?.errors);
    const allStateAttributes = Object.values(bpmTask.currentStateGroups).flat();
    const tabsWithErrors: string[] = [];
    bpmTask.taskDetailsTabs.forEach(({ attributes, description }: BpmTaskExtraTab) => {
      attributes.forEach(({ id, name, groupName, defaultValue }: TaskParametersType) => {
        if (!fieldsWithErrors.includes(name)) {
          return;
        }

        // do not count errors fields from hidden groups (in modals)
        if (groupName && groupName.includes('#hidden')) {
          formMethods.clearErrors(name as any);
          return;
        }

        const currentAttribute = allStateAttributes.find(({ attributeId }: any) => attributeId === id) as {
          [key: string]: any;
        };
        const isAttachment = defaultValue === ATTACHMENTS_DEFAULT_VALUE || currentAttribute?.component === 'file';

        // do not add Information tab to this list for attachments components
        // from this tab since they are shown only in the Attachments tab
        if (!(description.toLowerCase() === INFORMATION_TAB_NAME && isAttachment)) {
          tabsWithErrors.push(description);
        }

        if (isAttachment) {
          tabsWithErrors.push(ATTACHMENTS_TAB_NAME);
        }
      });
    });

    const uniqueTabsWithErrors = [...new Set(tabsWithErrors)];
    setTabsWithErrors(uniqueTabsWithErrors.map((tabName: string) => tabName.toLowerCase()));
  }, [formMethods.formState.isValidating]);

  useEffect(() => {
    setIsUserCurrentWatcher(watchers.some((item) => item?.id === profileId));
  }, [watchers, profileId]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedTaskWatchers([]));
      dispatch(
        setSelectedTask({
          selectedUsersId: [],
          selectedUsersObject: [],
        })
      );
    };
  }, []);

  const taskContent = useMemo(() => {
    return (
      <>
        {taskFormLoading ? (
          <Spinner />
        ) : (
          <>
            <DataViewHeader handleClose={handleClose} ButtonsComponent={ButtonsComponent} watchers={watchers} />
            <Box style={{ height: '100%' }} display="flex">
              {isShownPersonalInfo && (
                <Box width="45%">
                  <DataViewContent
                    isCurrentUserWatcher={isCurrentUserWatcher}
                    isCurrentUserAssignee={isCurrentUserAssignee}
                    handleWatchersSelect={handleWatchersSelect}
                    setTabCounter={setTabCounter}
                    tabsList={[INFORMATION_TAB_NAME]}
                    activeTab={INFORMATION_TAB_NAME}
                    setActiveTab={setActiveTab}
                    scrollbarsRef={scrollbarsRef}
                    setSteps={setSteps}
                    formMethods={formMethods}
                    checkValidationErrors={checkValidationErrors}
                    setButtonDisabled={setButtonDisabled}
                    taskFormLoading={taskFormLoading}
                    handleAction={handleAction}
                    isValidationErrorSnackbarOpen={isValidationErrorSnackbarOpen}
                    setValidationErrorSnackbarOpen={setValidationErrorSnackbarOpen}
                    formErrorsRefresh={formErrorsRefresh}
                    setAlwaysActiveButtons={setAlwaysActiveButtons}
                    formError={formError}
                    setErrorMessageLines={setErrorMessageLines}
                  />
                </Box>
              )}

              <Box width={isShownPersonalInfo ? '55%' : '100%'} borderLeft="1px solid rgba(38, 40, 66, 0.08)">
                <DataViewTabs
                  tabsList={tabsList.filter((tab) => tab.name !== INFORMATION_TAB_NAME)}
                  tabsWithErrors={tabsWithErrors}
                  onClick={handleTabChange}
                  activeTab={activeTab}
                  unreadTabs={unreadTabs}
                />
                <DataViewContent
                  isCurrentUserWatcher={isCurrentUserWatcher}
                  isCurrentUserAssignee={isCurrentUserAssignee}
                  handleWatchersSelect={handleWatchersSelect}
                  setTabCounter={setTabCounter}
                  tabsList={tabsList.filter((tab) => tab.name !== INFORMATION_TAB_NAME)}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  scrollbarsRef={scrollbarsRef}
                  setSteps={setSteps}
                  formMethods={formMethods}
                  setButtonDisabled={setButtonDisabled}
                  taskFormLoading={taskFormLoading}
                  handleAction={handleAction}
                  isValidationErrorSnackbarOpen={isValidationErrorSnackbarOpen}
                  setValidationErrorSnackbarOpen={setValidationErrorSnackbarOpen}
                  formErrorsRefresh={formErrorsRefresh}
                  setAlwaysActiveButtons={setAlwaysActiveButtons}
                  formError={formError}
                  disableRenderInformationTab
                  setErrorMessageLines={setErrorMessageLines}
                  checkValidationErrors={checkValidationErrors}
                />
              </Box>
            </Box>
          </>
        )}

        {/* {errorMessageLines?.length > 0 && (
          <ValidationErrorSnackbar onClose={() => setErrorMessageLines([])} errorMessageLines={errorMessageLines} />
        )} */}

        <ErrorModal
          open={isErrorModalOpen}
          onClose={() => setErrorModalOpen(false)}
          icon={RefreshModalIcon}
          title={t('errorModal.outdatedTask.title')}
          message={t('errorModal.outdatedTask.message')}
        />

        <ActionCommentDialog
          open={commentPopoverInfo.open}
          isCommentRequired={commentPopoverInfo.isCommentRequired}
          commentedAction={commentPopoverInfo.commentedAction}
          actionOptions={commentPopoverInfo}
          onClick={handleAction}
          submitAction={commentPopoverSubmitAction}
          onCommentChange={(comment: string) => setActionComment(comment)}
          setAssigneeVariableName={setAssigneeVariableName}
          isTemplate={bpmTask.isTemplateProcessTask}
        />

        <SelectEmployeeDialog
          open={selectEmployeePopoverInfo.open}
          isCommentRequired={selectEmployeePopoverInfo.isCommentRequired}
          showOnlyPossiblePerformers={selectEmployeePopoverInfo.showOnlyPossiblePerformers}
          onEmployeeChange={(username: string) => setActionEmployee(username)}
          onClick={handleAction}
          onCommentChange={(comment: string) => setActionComment(comment)}
        />
      </>
    );
  }, [
    ButtonsComponent,
    activeTab,
    bpmTask.isTemplateProcessTask,
    commentPopoverInfo,
    commentPopoverSubmitAction,
    formError,
    formErrorsRefresh,
    formMethods,
    handleAction,
    handleClose,
    handleTabChange,
    isErrorModalOpen,
    isShownPersonalInfo,
    isValidationErrorSnackbarOpen,
    scrollbarsRef,
    selectEmployeePopoverInfo.isCommentRequired,
    selectEmployeePopoverInfo.open,
    selectEmployeePopoverInfo.showOnlyPossiblePerformers,
    setActionComment,
    setActionEmployee,
    setActiveTab,
    setAlwaysActiveButtons,
    setAssigneeVariableName,
    setButtonDisabled,
    setTabCounter,
    setTabsWithErrors,
    t,
    tabsList,
    tabsWithErrors,
    taskFormLoading,
    unreadTabs,
  ]);

  return (
    <ChangeAssigneeCallbackContext.Provider
      value={{
        ...changeAssigneeDataCallbackContextValue,
      }}
    >
      <FormProvider {...formMethods}>
        {loading && <Spinner absolute />}

        {taskContent}

        <Modal open={Boolean(changeAssigneeData && changeAssigneeData?.assigneeId)}>
          <ChangeAssignee
            data={
              changeAssigneeData
                ? {
                    assigneeId: changeAssigneeData?.assigneeId,
                    taskId: changeAssigneeData?.taskId,
                    stepName: changeAssigneeData?.stepName,
                  }
                : null
            }
            close={() => setChangeAssigneeData(null)}
            onSuccess={loadTask}
          />
        </Modal>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
            style: {
              width: 200,
              border: '1px solid rgba(38, 40, 66, 0.08)',
              boxShadow: '0px 8px 16px rgba(38, 40, 66, 0.04)',
              background: 'white',
              borderRadius: 10,
            },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            style: {
              paddingBottom: 8,
              border: 'none',
              boxShadow: 'none',
              background: 'transparent',
            },
          }}
        >
          {!bpmTask?.businessTask.parallel && bpmTask?.businessTask.pending && bpmTask?.businessTask.assignee && (
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setChangeAssigneeData({
                  assigneeId: bpmTask.businessTask.assignee,
                  taskId: bpmTask.businessTask.taskId,
                  stepName: t(`constructor-${bpmTask.businessTask.processSysName}.actions.${bpmTask.currentAction.sysName}.name`, {
                    defaultValue: bpmTask.currentAction.name,
                  }),
                });
              }}
            >
              <Typography>{t('AdminPanel.actions.changeAssignee')}</Typography>
            </MenuItem>
          )}

          <MenuItem
            onClick={() => {
              setIsVisibleDeleteModal(true);
              setAnchorEl(null);
            }}
          >
            <Typography>{t('customProcesses.table.actions.delete')}</Typography>
          </MenuItem>
        </Menu>

        <Modal open={isVisibleDeleteModal}>
          <ConfirmationModal
            description={t('AdminPanel.actions.deleteRequest')}
            title={t('AdminPanel.actions.deleteRequest')}
            okButtonText={t('customProcesses.creationPage.buttons.delete')}
            close={() => {
              setIsVisibleDeleteModal(false);
            }}
            onSuccessAction={onSuccessDeleteRequest}
            action={handleDeleteRequest}
          />
        </Modal>

        <TaskPdfGenerator />
      </FormProvider>
    </ChangeAssigneeCallbackContext.Provider>
  );
};
