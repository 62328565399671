import { Reducer, useMemo, useReducer, useState } from "react";

import compact from "lodash/compact";

import { AdminPanelRequestsFilterValues, Pagination, RequestStatusesEnum } from '../AdminPanel.types'

const defaultPagination: Pagination = {
  page: 0,
  size: 25,
  sort: 'taskStartDate,DESC'
}
const filterInitialValues: AdminPanelRequestsFilterValues = {
  searchText: '',
  processes: null,
  initiator: [],
  assignee: [],
  fromDate: null,
  tillDate: null,
  status: RequestStatusesEnum.InProgress,
  pagination: defaultPagination,
};

export const useFilter = () => {
  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);
  const [currentFilterValues, setCurrentFilterValues] = useReducer<
      Reducer<AdminPanelRequestsFilterValues, Partial<AdminPanelRequestsFilterValues>>
    >((prevState, state) => ({
    ...prevState,
    ...state
  }), filterInitialValues)

  const usedFilterCountNumber = useMemo(() => {
    const filters = compact(Object.values({
      ...currentFilterValues,
      searchText: null,
      pagination: null,
      fromDate: null,
      tillDate: null,
      startDate: (currentFilterValues.fromDate || currentFilterValues.tillDate)
    })).filter(el => {
      if (Array.isArray(el) && el.length === 0) return false;
      return true;
    });

    return filters.length;

  }, [currentFilterValues]);

  return {
    currentFilterValues,
    setCurrentFilterValues,
    usedFilterCountNumber,
    isFilterVisible,
    setIsFilterVisible
  }
}



