import { CancelTokenSource } from "axios";

import Attachments from './attachments.component';

type AttachmentItemType = {
  randomId?: number;
  id?: number;
  filename: string;
  type: any;
  file?: File | null;
  error?: string;
  isLoading?: boolean;
  loadingProgress?: number;
  isDeleted?: boolean;
  size?: number;
  cancelToken: CancelTokenSource,
  assignee?: string;
  creationDate?: string;
  step?: string;
};

export {AttachmentItemType};
export default Attachments;
