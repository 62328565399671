import makeStyles from '@mui/styles/makeStyles';

// @ts-ignore
export default makeStyles(({ spacing }) => ({
  dialogRoot: {
  },
  dialog: {
    width: 800,
    borderRadius: 12,
    border: '1px solid rgba(38, 40, 66, 0.08)',
  },
  dialogHeader: {
    display: 'flex',
    paddingLeft: 16,
    paddingRight: 20,
    height: 48,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dialogTitle: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.3,
    color: '#262842',
  },
  dialogSubtitle: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.3,
    color: '#8B8C9E',
    margin: spacing(2, 0, 15),
  },
  dialogIcon: {
    '& > path': {
      fill: '#6D7C8B',
    },
  },
  dialogContent: {
    padding: 0,
  },
  dialogActions: {
    height: 64,
    backgroundColor: '#F7F8FC',
  },
  dialogActionsButton: {
    height: 40,
  },
  conditionFieldsWrapper: {
    height: 64,
    margin: 16,
    marginBottom: 72,
    border: '1px solid rgba(38, 40, 66, 0.12)',
    borderRadius: 12,
  },
  conditionField: {
    width: 240,
    height: 40,
    marginLeft: 12,
    marginTop: 12,
    border: '1px solid rgba(38, 40, 66, 0.12)',
    borderRadius: 10,
  },
  conditionFieldText: {
    '& div': {
      width: 240,
      height: 40,
      marginLeft: 12,
      marginTop: 12,
      border: '1px solid rgba(38, 40, 66, 0.12)',
      borderRadius: 10,
    },
  },
  emptyFieldsListMessageWrapper: {
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 8,
    paddingBottom: 8,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#8B8C9E',
    '& p': {
      margin: 0,
      padding: 0,
      paddingBottom: 2,
    },
    '& ul': {
      paddingLeft: 18,
      listStyle: 'disc',
    },
  },
  fieldsMenuItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    maxWidth: '100%',
    overflow: 'hidden',
  },
  fieldsMenuItemFieldName: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 14,
    fontWeight: 400,
    color: '#262842'
  },
  fieldsMenuItemStepInfo: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 12,
    fontWeight: 400,
    color: '#8B8C9E'
  },
  documentsListWrapper: {
    height: 400,
    overflowY: 'auto',
    // paddingLeft: 24,
    // paddingRight: 24,
  },
  filtersWrapper: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 12,
  }
}));
