import React, { ReactElement, useMemo } from 'react';
import { Typography, Box } from '@mui/material';

import { useTaskState } from 'store/requests';

import useStyles from './useStyles';

export const RejectionReason = (): ReactElement => {
  const classes = useStyles();

  const { data: bpmTask } = useTaskState();

  const text = useMemo((): string =>
      bpmTask ? bpmTask.instance.errorClientMessage : '',
    [bpmTask]);

  if (text) {
    return (
      <Box display="flex" flexDirection="column">
        <Typography variant="h5" className={classes.title}>
          Rejection Reason
        </Typography>

        <Typography className={classes.reason}>{text}</Typography>
      </Box>
    );
  }

  return null;
};
