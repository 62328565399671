import React, { memo, useCallback } from 'react';
import { Checkbox as MuiCheckbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { useTaskState } from 'store/requests';

type Props = {
  hint?: string;
  name?: string;
  rules?: { [key: string]: any };
  params?: { [key: string]: any }
}

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  [key: string]: string | undefined
}

export const Checkbox = memo(({ hint, name, rules, params }: Props) => {
  const { formState: { errors }, control } = useFormContext();

  const { data: bpmTask } = useTaskState();
  const inputProps: InputProps = { 'data-selenium': name }

  const handleChange = useCallback((event, onChange) => {
    if (bpmTask.isCompleted || params.readOnly) {
      return;
    }

    onChange(event.target.checked);
  }, [bpmTask.isCompleted, params.readOnly]);

  return (
    <FormControl error={!!errors[name]}>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({field: { value, onChange}}) => (
          <FormControlLabel
            label={hint}
            checked={!!value}
            onChange={e => handleChange(e, onChange)}
            control={<MuiCheckbox color="primary" inputProps={inputProps} />}
          />
        )}
      />
      {!!errors[name] && <FormHelperText>{errors[name].message}</FormHelperText>}
    </FormControl>
  );
}, (prevProps, nextProps) =>
  prevProps.name === nextProps.name &&
  prevProps.hint === nextProps.hint &&
  Object.keys(prevProps.rules).length === Object.keys(nextProps.rules).length &&
  Object.keys(prevProps.params).length === Object.keys(nextProps.params).length);
