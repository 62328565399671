import React, { createContext, useContext, ComponentType } from 'react';
import { ProcessContextType } from 'types';

const ProcessContext = createContext<Partial<ProcessContextType>>({});

export function withProcessContext<P extends ProcessContextType = ProcessContextType>(WrappedComponent: ComponentType<P>) {
  const ComponentWidthContext = (props: Omit<P, keyof ProcessContextType>) => {
    const processContext = useContext(ProcessContext);
    return <WrappedComponent {...processContext} {...(props as P)} />;
  };

  return ComponentWidthContext;
}

export default ProcessContext;
