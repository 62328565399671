import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { DocumentTemplateField, ParallelBranchesGroup, ProcessStep } from '../../../../TemplateProcesses.types';
import useDocumentsTabStyles from './DocumentsTab.useStyles';
import { useDocumentsTab } from './useDocumentsTab';
import { Spinner } from 'components';
import { DocumentsList } from './components/DocumentsList/DocumentsList';
import { DocumentParamsForm } from './components/DocumentParamsForm/DocumentParamsForm';
import { AllProcessFieldsListItem, DocumentTemplate, Language } from '../../../../TemplateProcesses.types';
import { DocumentsListFilters } from './components/DocumentsListFilters/DocumentsListFilters';

interface DocumentsTabProps {
  focusedDocumentId: string;
  setFocusedDocumentId: (value: string) => void;
  processDocuments: DocumentTemplate[];
  parallelBranchesGroups: ParallelBranchesGroup[];
  handleDocumentFieldsAdd: (fields: DocumentTemplateField[]) => void;
  handleDocumentParamsChange: (document: DocumentTemplate) => void;
  handleDocumentPreviewOpen: (documentId: number) => void;
  handleDocumentChangeUndo: (addedFields: string[], linkedFields: string[], hasSignatureStateChanged: boolean) => void;
  handleDocumentSignatureStepRemovalDialogOpen: () => void;
  allProcessStepsFields: AllProcessFieldsListItem[];
  currentLanguage: Language;
  currentProcessStepOrder: number;
  currentStep: ProcessStep;
  formErrors: {[key: string]: any}[]
}

export const DocumentsTab = ({
  focusedDocumentId,
  setFocusedDocumentId,
  processDocuments,
  parallelBranchesGroups,
  handleDocumentFieldsAdd,
  handleDocumentParamsChange,
  handleDocumentChangeUndo,
  handleDocumentPreviewOpen,
  handleDocumentSignatureStepRemovalDialogOpen,
  allProcessStepsFields,
  currentLanguage,
  currentProcessStepOrder,
  currentStep,
  formErrors
}: DocumentsTabProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useDocumentsTabStyles();

  const {
    groupedDocumentsList,
    loading,
    directories,
    allDirectories,
    selectedDocument,
    searchValue,
    handleSearchChange,
    departmentValue,
    handleDepartmentChange,
    typeValue,
    handleTypeChange,
  } = useDocumentsTab({
    focusedDocumentId,
    processDocuments
  });

  if (focusedDocumentId) {
    return <div className={classes.DocumentsTab}>
      <DocumentParamsForm
        document={selectedDocument}
        focusedDocumentId={focusedDocumentId}
        parallelBranchesGroups={parallelBranchesGroups}
        setFocusedDocumentId={setFocusedDocumentId}
        handleDocumentFieldsAdd={handleDocumentFieldsAdd}
        handleDocumentParamsChange={handleDocumentParamsChange}
        allProcessStepsFields={allProcessStepsFields}
        currentLanguage={currentLanguage}
        currentProcessStepOrder={currentProcessStepOrder}
        currentStep={currentStep}
        formErrors={formErrors}
        handleDocumentChangeUndo={handleDocumentChangeUndo}
        handleDocumentPreviewOpen={handleDocumentPreviewOpen}
        handleDocumentSignatureStepRemovalDialogOpen={handleDocumentSignatureStepRemovalDialogOpen}
      />
    </div>;
  }

  return (<div className={classes.DocumentsTab}>
      <div className={classes.header}>
        <h3 className={classes.title}>{t('customProcesses.tabs.documentsTitle')}</h3>
        <p className={classes.description}>{t('customProcesses.tabs.documentsDescription')}</p>
      </div>

      <DocumentsListFilters
        searchValue={searchValue}
        directories={directories}
        department={departmentValue}
        type={typeValue}
        onSearchChange={handleSearchChange}
        onDepartmentSelect={handleDepartmentChange}
        onTypeSelect={handleTypeChange}
        />

      {
        (loading || allDirectories.length === 0)
        ? <Spinner absolute={false}/>
        : <DocumentsList
          groupedDocumentsList={groupedDocumentsList}
          selectedDocument={focusedDocumentId}
          onDocumentClick={() => {
          }}
          searchText={searchValue}
          isFiltered={!!(searchValue || departmentValue || typeValue)}
        />
      }
    </div>
  );
};
