import React, { ReactElement, useState } from 'react';
import { Box, Button, IconButton, Modal, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import CloseIcon from 'assets/images/icons/close-icon-thin-light-grey.svg';
import { Spinner } from 'components';

import useOpenProcessConfirmationDialogStyles from './OpenProcessConfirmationDialog.useStyles';

interface ActionConfirmationDialogProps {
  processCopyParams: {[key: string]: any}
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (processCopyName: string, selectedWorkspaces: string[], refreshProcessesList: boolean, actionType: 'copy' | 'duplicate', openCreatedProcess?: boolean) => Promise<boolean>;
}

export const OpenProcessConfirmationDialog = ({
  processCopyParams,
  isOpen,
  onClose,
  onConfirm,
}: ActionConfirmationDialogProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useOpenProcessConfirmationDialogStyles();
  const [loading, setLoading] = useState<boolean>(false);

  const handleConfirm = async () => {
    setLoading(() => true);
    const isSuccess = await onConfirm(processCopyParams.processCopyName, [processCopyParams.targetWorkspaceId], true, 'copy', true);
    setLoading(() => false);
    if (isSuccess) {
      onClose();
    }
  };

  return (
    <Modal open={isOpen}>
      <Box className={classes.modalBody}>
        <div className={classes.modalHeader}>
          <Typography className={classes.title}>
            {t('customProcesses.openProcessConfirmationDialog.title')}
          </Typography>

          <IconButton disabled={loading} onClick={onClose} size="large">
            <img src={CloseIcon} alt={"Close action confirmation modal"} className={classes.closeIcon}/>
          </IconButton>
        </div>

        <div className={classes.modalContent}>
          <Typography className={classes.description}>

            <Trans i18nKey='customProcesses.openProcessConfirmationDialog.message' values={{workspaceName: processCopyParams.targetWorkspaceName}}>
              You are going to the workspace <span>WORKSPACE_NAME</span>
            </Trans>
          </Typography>
        </div>

        <div className={classes.modalFooter}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.modalButton}
            onClick={onClose}
            disabled={loading}
          >
            {t('template_delete_modal.button_cancel')}
          </Button>

          <Button
            variant="contained"
            color="primary"
            className={classes.modalButton}
            onClick={handleConfirm}
            disabled={loading}
            endIcon={loading ? <Spinner size={14} absolute={false} white buttonVersion/> : null}
          >
            {t('customProcesses.openProcessConfirmationDialog.copyAndOpenButton', { defaultValue: 'Copy & Open' })}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
