import moment from 'moment/moment';
import { nearestFutureMinutes } from 'utils/time';
import { ConditionStep, Language, ProcessStep } from '../../TemplateProcesses.types';

export const STEP_TITLE_LANGUAGE_DEFAULT = {
  [Language.RUSSIAN]: 'Формирование запроса',
  [Language.KAZAKH]: 'Өтінімді қалыптастыру',
  [Language.ENGLISH]: 'Request creation',
  [Language.BAHASA]: 'Membentuk permintaan',
  [Language.GERMAN]: 'Formation anfordern',
};

export const STEP_TITLE_LANGUAGE_DIRECT_MANAGER = {
  [Language.RUSSIAN]: 'Прямой руководитель',
  [Language.KAZAKH]: 'Тікелей жетекші',
  [Language.ENGLISH]: 'Direct manager',
  [Language.BAHASA]: 'Kepala langsung',
  [Language.GERMAN]: 'Direkter Manager',
};

export const STEP_TITLE_LANGUAGE_REQUEST_INITIATOR = {
  [Language.RUSSIAN]: 'Инициатор заявки',
  [Language.KAZAKH]: 'Өтінім бастамашы',
  [Language.ENGLISH]: 'Request initiator',
  [Language.BAHASA]: 'Kepala langsung',
  [Language.GERMAN]: 'Request initiator',
};

export const STEP_TITLE_LANGUAGE_PERFORMER = {
  [Language.RUSSIAN]: 'Исполнитель',
  [Language.KAZAKH]: 'Орындаушы',
  [Language.ENGLISH]: 'Performer',
  [Language.BAHASA]: 'Pelaksanan',
  [Language.GERMAN]: 'Ausführender',
};

export const DEFAULT_PROCESS = [
  {
    stepOrder: 1,
    stepName: {
      [Language.RUSSIAN]: STEP_TITLE_LANGUAGE_DEFAULT[Language.RUSSIAN],
      [Language.KAZAKH]: STEP_TITLE_LANGUAGE_DEFAULT[Language.KAZAKH],
      [Language.ENGLISH]: STEP_TITLE_LANGUAGE_DEFAULT[Language.ENGLISH],
      [Language.BAHASA]: STEP_TITLE_LANGUAGE_DEFAULT[Language.BAHASA],
      [Language.GERMAN]: STEP_TITLE_LANGUAGE_DEFAULT[Language.GERMAN],
    },
    stepDescription: {
      [Language.RUSSIAN]: "",
      [Language.KAZAKH]: "",
      [Language.ENGLISH]: "",
      [Language.BAHASA]: "",
      [Language.GERMAN]: "",
    },
    assigneeId: [],
    fields: [],
    timer: '',
    hasTimer: false,
    parallel: false,
    summaryFields: []
  },
  {
    stepOrder: 2,
    stepName: {
      [Language.RUSSIAN]: '',
      [Language.KAZAKH]: '',
      [Language.ENGLISH]: '',
      [Language.BAHASA]: '',
      [Language.GERMAN]: '',
    },
    stepDescription: {
      [Language.RUSSIAN]: undefined,
      [Language.KAZAKH]: undefined,
      [Language.ENGLISH]: undefined,
      [Language.BAHASA]: undefined,
      [Language.GERMAN]: undefined,
    },
    assigneeId: [],
    fields: [],
    timer: '',
    hasTimer: false,
    parallel: false,
    summaryFields: []
  },
] as Partial<ProcessStep & ConditionStep>[];

export const WEEKDAYS = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

export const INITIAL_AUTOSTART_SETTINGS = {
  repeatUnit: 'day',
  repeatCount: 1,
  startDate: nearestFutureMinutes(15, moment()).toDate(),
  repeatDays: [WEEKDAYS[moment().isoWeekday() - 1]],
  endingType: 'never',
  endingDate: nearestFutureMinutes(15, moment()).toDate(),
  endingRepetitionsCount: 1,
};
