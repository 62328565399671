import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  newRequestButton: {
    display: "flex",
    height: 40,
    margin: '16px auto 0 auto',
    fontWeight: 500,
    color: '#FFFFFF',
  },
  additionalMessage: {
    marginTop: 4,
    fontWeight: 400,
    fontSize: 14,
    color: '#6D6E85',
    textAlign: 'center'
  },
  mainMessage: {
    marginTop: 16,
    fontWeight: 500,
    fontSize: 16,
    color: "#6D6E85",
    textAlign: 'center'
  }
}));
