import { useState } from "react";

import moment from "moment";
import { useTranslation } from "react-i18next";
import isArray from 'lodash/isArray';

import { useUsersState } from "store/users";
import { getDirectoryInfo, getGlossaryByCode } from 'api/requests';
import { environment } from '../../environments/environment';

export const useFormatConditionValue = () => {
  const [conditionValueFormatted, setConditionValueFormatted] = useState<string>('');
  const { users } = useUsersState();
  const { t, i18n } = useTranslation();

  const currentRegionFormat = environment.region === 'US' ? 'MM/DD/YYYY' : 'DD.MM.YYYY';

  const formatConditionValue = async (
    condition: {
      fieldType: string,
      value: string | any,
      fieldParams?: {
        [key: string]: any
      },
    },
  ) => {
    if (!condition) return
    const { fieldType, value, fieldParams = {} } = condition;
    let result = value
    try {
      if (fieldType === 'dateLineArea') {
        result = moment(value).format(currentRegionFormat);
      } else if (fieldType === 'userList') {
        let valueArray;
        if (isArray(value)) {
          valueArray = value;
        } else {
          valueArray = (value as string).split(',');
        }
        if (valueArray?.length) {
          const firstSelectedUser = users[valueArray[0]];
          const fullName = firstSelectedUser?.fullName || t('task_data_view.employee_not_found');
          result = `${fullName}${valueArray.length === 1 ? '' : ` +(${valueArray.length - 1})`}`;
        }
      } else if (condition.fieldType === 'systemGlossary') {
        const glossaryOptions = await getGlossaryByCode(fieldParams?.glossaryIndexName);
        const selectedOption = glossaryOptions?.data?.find(item => item.id === value);
        result = selectedOption?.additionalFields[i18n.language] || selectedOption?.value || value;
      } else if (condition.fieldType === 'glossary') {
        const info = await getDirectoryInfo(value as string)
        result = info?.value
      } else if (condition.fieldType === 'glossaryMultiSelect') {
        const valuesList = value?.split(',')
        const info = await getDirectoryInfo(valuesList[0] as string)
        result = info?.value + (valuesList.length > 1 ? ` (+${valuesList.length - 1})` : '');
      }

      setConditionValueFormatted(result as string)

      return result
    } catch (error) {
      console.log('error formatting condition value');
      setConditionValueFormatted(value);
      return value;
    }
  }

  return { formatConditionValue, conditionValueFormatted }
}
