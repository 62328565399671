import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { useTaskState } from 'store/requests';
import { getWorkCalendar } from 'api/requests';

import {
  DATE_COMPARISON_TYPES,
  DATE_COMPARISON_TYPES_LOCALIZATION_KEYS,
} from 'constants/date';

type Props = {
  name: string;
  rules?: {
    [key: string]: any
  }
  params: {
    disablePresent: boolean,
    disableWeekends: boolean,
    comparisonComponentSysName: string,
    comparisonComponentName: string,
    comparisonType: string
  }
}

const useDate = ({
  name,
  rules: propsRules,
  params: {
    disablePresent = false,
    disableWeekends = false,
    comparisonComponentSysName = '',
    comparisonComponentName = '',
    comparisonType = '',
  }
}: Props) => {
  const { data: bpmTask } = useTaskState();
  const {t} = useTranslation();

  const {
    watch,
    setValue,
    getValues,
  } = useFormContext();

  const defaultValue = watch(name);
  const comparisonAttribute = comparisonComponentName
                              ? bpmTask.getAttributeByName(comparisonComponentName)
                              : bpmTask.getAttributeBySysName(comparisonComponentSysName);
  const comparisonComponentValue = comparisonAttribute ? watch(comparisonAttribute?.name) : '';

  const [holidays, setHolidays] = useState([]);

  useEffect(() => {
    const fetchHolidays = async () => {
      try {
        const { days } = await getWorkCalendar(moment().year());
        const holidaysList = Object.keys(days).filter(day => days[day]?.holiday);
        setHolidays(holidaysList);
      } catch (e) {
        setHolidays([]);
      }
    };

    disableWeekends &&
    fetchHolidays();
  }, [disableWeekends]);

  const handleChange = value => {
    setValue(name, value);
  }

  const validateDate = useCallback(value => {
    if (!propsRules?.required && !value) {
      return true;
    }

    if (!moment(value).isValid()) {
      return t('form_components.date.errors.invalidFormat')
    }

    if (disablePresent && moment(value).isSame(moment(), 'd')) {
      return t('form_components.date.errors.presentDayDisabled')
    }

    if ((comparisonComponentSysName || comparisonComponentName) && comparisonType && checkDateDisabled(value)) {
      const localizationTemplate = DATE_COMPARISON_TYPES_LOCALIZATION_KEYS[comparisonType];
      const comparisonAttribute = comparisonComponentName
                                  ? bpmTask.getAttributeByName(comparisonComponentName)
                                  : bpmTask.getAttributeBySysName(comparisonComponentSysName);
      const hintTranslationNewFormatKey = `constructor-${bpmTask.processSysName}.attributes.${comparisonAttribute?.sysName?.replaceAll('::', '-')}.hint`;

      return t(localizationTemplate, {fieldName: t(hintTranslationNewFormatKey, {defaultValue: comparisonAttribute?.hint})})
    }

    return true;
  }, [
    name,
    disablePresent,
    comparisonType,
    comparisonComponentSysName,
  ]);

  // TODO - check if it works correctly for holidays with strict comparison
  useEffect(() => {
    if (comparisonComponentValue && comparisonType) {
      let tempDate = defaultValue;
      let count = 0;
      while (count < 1000) {
        const isPossibleDate = !checkDateDisabled(tempDate);
        if (!isPossibleDate) {
          if ([DATE_COMPARISON_TYPES.BEFORE, DATE_COMPARISON_TYPES.SAME_OR_BEFORE].includes(comparisonType)) {
            tempDate = moment(tempDate).subtract(1, 'day').toISOString();
          } else {
            tempDate = moment(tempDate).add(1, 'day').toISOString();
          }
          count++; // prevent infinite cycle
        } else {
          break;
        }
      }
      setValue(name, tempDate);
    }
  }, [comparisonComponentValue, comparisonType]);

  const checkDateDisabled = useCallback((date: Date) => {
    if (!!disablePresent && moment().isSame(date, 'd')) {
      return true;
    }

    if (disableWeekends && holidays.includes(moment(date).format('YYYY-MM-DD'))) {
      return true;
    }

    // запрет выбора дат на основе сравнения со значением поля comparisonComponentSysName
    // comparisonType - тип сравнения, возможные значения: >, >=, <, <=
    // даты, не подходящие под условие, не доступны для выбора
    if ((comparisonComponentSysName || comparisonComponentName) && comparisonType) {
      const comparisonAttribute = comparisonComponentName
                                  ? bpmTask.getAttributeByName(comparisonComponentName)
                                  : bpmTask.getAttributeBySysName(comparisonComponentSysName);
      const comparisonComponentValue = comparisonAttribute ? watch(comparisonAttribute?.name) : '';

      switch (comparisonType) {
        case DATE_COMPARISON_TYPES.BEFORE:
          return !moment(date).isBefore(moment(comparisonComponentValue), 'd');

        case DATE_COMPARISON_TYPES.SAME_OR_BEFORE:
          return !moment(date).isSameOrBefore(moment(comparisonComponentValue), 'd');

        case DATE_COMPARISON_TYPES.AFTER:
          return !moment(date).isAfter(moment(comparisonComponentValue), 'd');

        case DATE_COMPARISON_TYPES.SAME_OR_AFTER:
          return !moment(date).isSameOrAfter(moment(comparisonComponentValue), 'd');

        default:
          return false;
      }
    }

    return false;
  }, [
    name,
    disablePresent,
    disableWeekends,
    holidays,
    comparisonComponentSysName,
    comparisonComponentName,
    comparisonComponentValue,
    comparisonType,
  ]);

  const rules = useMemo(() => ({
    ...propsRules,
    validate: validateDate
  }), [propsRules, validateDate]);

  useEffect(() => {
    const date = getValues(name);

    if (date && disablePresent && moment(date).isSame(moment(), 'd')) {
      setValue(name, moment().add(1, 'd').toDate());
    }
  }, [getValues, name, disablePresent, setValue]);

  return {
    rules,
    defaultValue,
    handleChange,
    checkDateDisabled
  }
}

export default useDate;
