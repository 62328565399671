import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import cn from 'classnames';
import uniqueId from 'lodash/uniqueId';
import { Scrollbars } from 'react-custom-scrollbars';
import {Box, TextField, Checkbox, FormControlLabel, Button, Tooltip} from '@mui/material';

import { AppTextField } from "components";

import Portal from 'utils/portal';
import useStyles from './useStyles';

import searchIcon from 'assets/images/icons/search-icon.svg';
import dropdownArrowIcon from 'assets/images/icons/dropdown-arrow-icon.svg';
import { useTranslation } from 'react-i18next';

type Props = {
  onSelect?: (value) => void;
  label?: string;
  placeholder?: string;
  searchPlaceholder?: string;
  options?: string[];
  selectedOptions?: string[];
  disabled?: boolean;
  analyticsFiltersDesignVariant?: boolean;
  hasValueChanged?: boolean;
};

export const MultipleSelect = ({
  onSelect,
  label,
  placeholder = '',
  searchPlaceholder = '',
  options,
  selectedOptions,
  disabled = false,
  analyticsFiltersDesignVariant = false,
  hasValueChanged = false,
}: Props) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const textFieldRef = useRef(null);
  const [dropdownId] = useState(uniqueId('multiple-select-dropdown-'));

  const [open, setOpen] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState('');

  const dropdownOptions = useMemo(() => {
    if (!textFieldRef.current) {
      return { x: 0, y: 0, maxDropdownHeight: 0 };
    }

    const boundRect = textFieldRef.current.getBoundingClientRect();
    const maxDropdownHeight = window.innerHeight - boundRect.top - 27 - 20;
    const options = {
      x: boundRect.left - 10,
      y: boundRect.top + 27,
      maxDropdownHeight,
    };

    return options;
  }, [textFieldRef.current]);

  const classes = useStyles(dropdownOptions);

  const selectAllChecked = useMemo(
    () =>
      selectedOptions.length > 0 && selectedOptions.length === options.length,
    [selectedOptions, options]
  );

  const filteredOptions = useMemo(
    () =>
      options
        .filter((option) =>
          option.toLowerCase().includes(searchInputValue.toLowerCase())
        )
        .sort(),
    [searchInputValue, options]
  );

  const handleSelect = useCallback(
    (value) => {
      if (selectedOptions.includes(value)) {
        onSelect && onSelect(selectedOptions.filter((v) => v !== value));
      } else {
        onSelect && onSelect([...selectedOptions, value]);
      }
      setSearchInputValue('');
    },
    [onSelect, setSearchInputValue]
  );

  const handleSelectAll = useCallback(() => {
    const missedElements = [];
    options.forEach((element) => {
      if (!selectedOptions.includes(element)) {
        missedElements.push(element);
      }
    });

    if (missedElements.length > 0) {
      const selectedElements = [...options, ...missedElements].reduce(
        (array, id) => (array.includes(id) ? array : [...array, id]),
        []
      );
      onSelect && onSelect(selectedElements);
    } else {
      onSelect && onSelect([]);
    }
    setSearchInputValue('');
  }, [onSelect, selectedOptions, setSearchInputValue]);

  const handleClear = useCallback(() => {
    onSelect && onSelect([]);
    setSearchInputValue('');
  }, [onSelect, setSearchInputValue]);

  const handleClickOutside = useCallback(
    (event) => {
      if (
        ref.current &&
        !ref.current?.contains(event.target) &&
        open
      ) {
        setOpen(() => false);
      }
    },
    [ref, open, setOpen]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const tooltipText = useMemo(() => selectedOptions.sort().join(', '), [
    selectedOptions,
  ]);

  const textFieldValue = useMemo(
    () =>
      selectedOptions.length
        ? selectedOptions[0] +
          (selectedOptions.length > 1
            ? ` (+${selectedOptions.length - 1})`
            : '')
        : '',
    [selectedOptions]
  );

  return (
    <>
      <Tooltip title={tooltipText} placement="top" arrow>
        <AppTextField
          className={cn(classes.textField, {
            [classes.analyticsFiltersVariant]: analyticsFiltersDesignVariant,
            [classes.valueChanged]:
              analyticsFiltersDesignVariant && hasValueChanged,
          })}
          label={label}
          value={textFieldValue}
          placeholder={placeholder || t('form_components.select.placeholder')}
          disabled={disabled}
          onClick={() => !disabled && setOpen((v) => !v)}
          inputRef={textFieldRef}
          InputProps={{
            endAdornment: (
              <img src={dropdownArrowIcon} style={{ marginRight: 4 }} />
            ),
            className: 'multiple-select-text-field',
          }}
        />
      </Tooltip>

      {open && (
        <Portal id={dropdownId}>
          <div
            className={cn(classes.dropdownWrapper, {
              [classes.dropdownWrapperCompact]: analyticsFiltersDesignVariant,
            })}
            ref={ref}
          >
            <Box className={classes.searchWrapper}>
              <TextField
                className={classes.searchInput}
                placeholder={searchPlaceholder || t('form_components.select.search_placeholder')}
                value={searchInputValue}
                onChange={(e) => setSearchInputValue(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <img src={searchIcon} style={{ marginRight: 8 }} />
                  ),
                }}
              />
            </Box>

            <Box className={classes.optionsWrapper}>
              <Scrollbars style={{ width: '100%', height: '100%' }}>
                <Box className={classes.selectAllWrapper}>
                  <FormControlLabel
                    control={<Checkbox color="default" className={cn(classes.checkbox, {[classes.checkboxChecked]: selectAllChecked})}/>}
                    label={t('form_components.selectGlossary.selectAll')}
                    checked={selectAllChecked}
                    onChange={handleSelectAll}
                  />
                </Box>

                <Box>
                  {filteredOptions?.map((item) => (
                    <div key={item}>
                      <FormControlLabel
                        control={<Checkbox color="default" className={cn(classes.option, classes.checkbox, {[classes.checkboxChecked]: selectedOptions.includes(item)})} />}
                        label={item}
                        checked={selectedOptions.includes(item)}
                        onChange={() => handleSelect(item)}
                      />
                    </div>
                  ))}
                </Box>
              </Scrollbars>
            </Box>

            <Box className={classes.clearWrapper}>
              <Button variant="text" onClick={handleClear}>
                {t('form_components.select.clear')}
              </Button>
            </Box>
          </div>
        </Portal>
      )}
    </>
  );
};
