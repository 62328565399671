import React, { useMemo, useState, useEffect, useRef, memo, ReactElement } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { filterUsers } from 'pages/Task/TaskForm/FormFields/Fields/ReadOnlyUser/utils';
import { getProfileCompanyDataFromLocalStorage, getUserDataByIdOrLogName } from 'utils/user';
import { SelectUsers } from 'components/User/SelectUsers';
import { useTaskState } from 'store/requests';
import { useUserSelect } from './useUserSelect';
import { useUsersState } from 'store/users';

import DeleteIcon from 'assets/images/icons/trash.svg';
import useStyles from './useStyles';

const OFFBOARDING_PROCESS_SYSNAME = 'offboarding';
const OFFBOARDING_TERMINATION_TYPES_WITH_INITIATOR_TERMINATION = [
  'Dismissal initiated by employee',
  'Termination of a civil contract',
];
const OFFBOARDING_TERMINATION_TYPE_FIELD_NAME = 'terminationType';
const OFFBOARDING_EMPLOYEE_FIELD_NAME = 'employeeSelect';

type Props = {
  name: string;
  hint: string;
  params: {
    multiple: boolean;
    multipleMapping: string;
  };
  rules: { [key: string]: string };
  isDisabled?: boolean;
};

export const UserSelect = memo(({ name, hint, params, rules, isDisabled = false }: Props): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { control, formState: { errors } } = useFormContext();

  const { users } = useUsersState();
  const { value, watch, setValue, handleChange } = useUserSelect({ name, params });

  const [disabled, setDisabled] = useState(false);
  const isComponentMounted = useRef(false);

  const { data: bpmTask } = useTaskState();

  const profileCompanyId = getProfileCompanyDataFromLocalStorage()?.id;
  const selectedCompanyId = watch('companyId') || bpmTask.values['companyId'] || profileCompanyId;
  const multiple = !!params?.multiple;
  const multipleMapping = params?.multipleMapping || null;

  const isOffboardingSelect = useMemo(() =>
    bpmTask &&
    bpmTask.processSysName === OFFBOARDING_PROCESS_SYSNAME &&
    name === OFFBOARDING_EMPLOYEE_FIELD_NAME,
    [bpmTask, name]);

  useEffect(() => {
    const checkOffboardingTypeUpdates = async () => {
      if (
        bpmTask &&
        bpmTask.processSysName === OFFBOARDING_PROCESS_SYSNAME &&
        name === OFFBOARDING_EMPLOYEE_FIELD_NAME
      ) {
        if (
          OFFBOARDING_TERMINATION_TYPES_WITH_INITIATOR_TERMINATION.includes(
            watch(OFFBOARDING_TERMINATION_TYPE_FIELD_NAME)
          )
        ) {
          if (!isComponentMounted.current) {
            return;
          }
          setDisabled(() => true);

          const userValues = Object.values(users);
          const selectedEmployee = userValues.find(
            ({ logName, id }) => logName === bpmTask.initiator || id === bpmTask.initiator
          );

          if (!selectedEmployee) {
            try {
              const loadedInitiator = await getUserDataByIdOrLogName(bpmTask.initiator, selectedCompanyId);
              if (loadedInitiator) {
                handleEmployeeSelect(loadedInitiator, 0);
                return;
              }
            } catch (error) {
              console.error(error)
            }
          }

          handleEmployeeSelect(selectedEmployee, 0);
          return;
        } else if (disabled) {
          setDisabled(() => false);
        }

        isComponentMounted.current && handleEmployeeSelect(null);
      }

      isComponentMounted.current = true;
    };

    checkOffboardingTypeUpdates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, watch(OFFBOARDING_TERMINATION_TYPE_FIELD_NAME), bpmTask, name]);

  const [storedUsers, setStoredUsers] = useState([]);

  const getStoredUsers = async (multiple, value, users) => {
    if (multiple) {
      if (Array.isArray(value) && value.length) {
        const filteredUsersList = await filterUsers(value, users, selectedCompanyId);
        setStoredUsers(filteredUsersList);
        if (selectedUsers?.length === 1 && !selectedUsers[0] && value.length > 0) {
          setSelectedUsers(filteredUsersList);
        }
        return;
      }
    } else {
      if (typeof value === 'string') {
        const filteredUsersList = await filterUsers([value], users, selectedCompanyId);
        setStoredUsers(filteredUsersList);
        if (selectedUsers?.length === 1 && !selectedUsers[0] && value.length > 0) {
          setSelectedUsers(filteredUsersList);
        }
        return;
      }
    }
  }

  useEffect(() => {
    getStoredUsers(multiple, value, users);
  }, [multiple, value, users]);

  const [selectedUsers, setSelectedUsers] = useState(
    storedUsers.length ? storedUsers : [null]
  );

  useEffect(() => {
    if (multiple) {
      const userList = selectedUsers.filter((e) => e);
      if (multipleMapping) {
        handleChange(
          null,
          userList.map((user) => user[multipleMapping])
        );
      } else {
        handleChange(null, userList);
      }
    } else {
      handleChange(null, selectedUsers[0]);
    }
  }, [multiple, multipleMapping, selectedUsers]);

  const handleEmployeeSelect = (user, index = null) => {
    if (multiple) {
      const userList = getCopySelectedUsers();
      userList[index] = user;

      setSelectedUsers(userList);
      return;
    }

    setSelectedUsers([user]);
    setStoredUsers([user]);
  };

  const addItemSelect = () => {
    const userList = getCopySelectedUsers();

    setSelectedUsers([...userList, null]);
  };

  const deleteItemSelect = (index) => {
    const userList = getCopySelectedUsers();
    userList.splice(index, 1);

    setSelectedUsers(userList);
  };

  const getCopySelectedUsers = () => Object.assign([], selectedUsers);

  if (multiple) {
    return (
      <div>
        <FormControl fullWidth>
          <Controller name={name} control={control} rules={rules} render={() => null} />

          {selectedUsers.map((userData, index) => (
            <div key={index} className={classes.multipleSelectField}>
              <SelectUsers
                name={name}
                onSelect={(user) => handleEmployeeSelect(user, index)}
                label={`${t('form_components.user-select.user')} ${index + 1}`}
                margin="normal"
                value={userData}
                error={!!errors[name] && errors[name].message}
                disabled={disabled || isDisabled}
                companyId={selectedCompanyId}
              />
              {index > 0 && (
                <span
                  className={classes.iconSpan}
                  onClick={() => deleteItemSelect(index)}
                >
                <img
                  className={classes.imageIcon}
                  src={DeleteIcon as string}
                  alt="delete"
                />
              </span>
              )}
            </div>
          ))}
          <span className={classes.link} onClick={addItemSelect}>+ {t('form_components.user-select.add')}</span>
        </FormControl>
      </div>
    );
  }

  return (
    <FormControl fullWidth>
      <Controller name={name} control={control} rules={rules} render={() => null} />
      <SelectUsers
        name={name}
        onSelect={(user) => handleEmployeeSelect(user)}
        label={hint}
        margin="normal"
        error={!!errors[name] && errors[name].message}
        value={(storedUsers && storedUsers.length) ? storedUsers[0] : ''}
        preventClearOnLoad={isOffboardingSelect}
        valueInBpmTask={bpmTask?.values[name]}
        disabled={disabled || isDisabled}
        companyId={selectedCompanyId}
      />
    </FormControl>
  );
});
