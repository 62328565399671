import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Box, Dialog, Button, IconButton, Slide, Typography } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useUserProfile } from 'hooks';

import { FormProcess, Language, TemplateProcess, TemplateProcessSettings } from '../../TemplateProcesses.types';

import useStyles from './CreateTemplateProcessesDialog.useStyles';
import { TemplateProcessSettingsForm } from '../TemplateProcessSettingsForm/TemplateProcessSettingsForm';
import { ChevronRight } from '@mui/icons-material';
import { MAX_DESCRIPTION_LENGTH, MAX_NAME_LENGTH } from '../../TemplateProcesses.constants';

interface CreateTemplateProcessesProps {
  initialValues: TemplateProcess | null;
  isOpen: boolean;
  onValuesChange: () => void;
  onClose: () => void;
  onSubmit: (values: TemplateProcessSettings) => void;
  availableLocales: string[];
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} children={props.children}/>;
});

export const CreateTemplateProcessesDialog = ({
  initialValues,
  isOpen,
  onClose,
  onValuesChange,
  availableLocales,
  onSubmit: onFormSubmit,
}: CreateTemplateProcessesProps): ReactElement => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const { companyName } = useUserProfile();

  const [languageTabs, setLanguageTabs] = useState<Language[]>([i18n.language as Language]);
  const [languageTabsWithErrors, setLanguageTabsWithErrors] = useState<Language[]>([]);
  const [currentLanguageTab, setCurrentLanguageTab] = useState<Language>(i18n.language as Language);

  const [processIcon, setProcessIcon] = useState<string | null>(null);
  const [processGroup, setProcessGroup] = useState<string | null>('custom_process_template');
  const [submitClicked, setSubmitClicked] = useState(false);
  const [formErrors, setFormErrors] = useState({name: [], description: [], icon: false})

  const { clearErrors, reset, register, formState: { errors }, control, handleSubmit } = useForm<{
    formProcess: FormProcess[]
  }>({
    shouldUnregister: false,
    defaultValues: {
      formProcess: [
        {
          language: i18n.language as Language,
          processName: '',
          description: '',
        },
      ],
    },
  });

  const { fields, append, replace, update: formUpdate, remove } = useFieldArray({
    control,
    name: 'formProcess',
  });

  const update = useCallback((index, values) => {
    onValuesChange();
    formUpdate(index, values);
  }, [onValuesChange, formUpdate]);

  const validateForm = (processForm: any[]) => {
    const validationErrors = {name: [], description: [], icon: false};
    const tabsWithErrors = [];
    for (const fieldsGroup of processForm) {
      if (!fieldsGroup?.processName.trim()) {
        validationErrors.name.push(fieldsGroup?.language);
      }
      if (!fieldsGroup?.description.trim()) {
        validationErrors.description.push(fieldsGroup?.language);
      }
      if (!fieldsGroup?.processName.trim() || !fieldsGroup?.description.trim() || fieldsGroup?.processName?.length > MAX_NAME_LENGTH || fieldsGroup?.description?.length > MAX_DESCRIPTION_LENGTH) {
        tabsWithErrors.push(fieldsGroup?.language);
      }
    }
    if (!processIcon) {
      validationErrors.icon = true;
    }
    setFormErrors(validationErrors);
    setLanguageTabsWithErrors(tabsWithErrors);
    return tabsWithErrors.length === 0;
  };

  const handleDialogSubmit = (event): void => {
    event.preventDefault();
    setSubmitClicked(() => true);
    clearErrors();
    setLanguageTabsWithErrors([]);

    const isValid = validateForm(fields);
    if (isValid) {
      setSubmitClicked(() => false);
      handleSubmit(handleFormSubmit, handleFormError)();
    }
  };

  const handleFormSubmit = (payload: { formProcess: FormProcess[] }): void => {
    onFormSubmit({
      formProcess: payload.formProcess,
      processIcon,
      processGroup,
    });
  };

  const handleFormError = (errors): void => {
    console.log('onerror', errors);
  };

  const initiateFormValues = (): void => {
    const formValues = [
      {
        processName: initialValues.processName,
        description: initialValues.description,
        language: i18n.language as Language,
      },
      ...initialValues.languages.filter(({ language }) => language !== (i18n.language as Language)),
    ] as FormProcess[];

    setProcessIcon(initialValues.iconPath);
    setProcessIcon(initialValues.category);
    setLanguageTabs(formValues.map(({ language }) => language));

    replace(formValues);
  };

  const clearData = (): void => {
    setLanguageTabs([i18n.language as Language]);
    setCurrentLanguageTab(i18n.language as Language);
    setProcessIcon(null);
    // setAnchorEl(null);

    reset({
      formProcess: [
        {
          language: i18n.language as Language,
          processName: '',
          description: '',
        },
      ],
    });
  };

  useEffect(() => {
    if (initialValues) {
      initiateFormValues();
    }

    return () => {
      clearData();
    };
  }, [initialValues]);

  useEffect(() => {
    if (!isOpen) {
      clearData();
    }
  }, [isOpen]);

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <form onSubmit={handleDialogSubmit}>
        <Box className={classes.dialogHeader}>
          <Typography variant="h3">{t('customProcesses.createProcess.header')}</Typography>
          <IconButton onClick={onClose} size="large">
            <CloseIcon className={classes.dialogHeaderButtonIcon}/>
          </IconButton>
        </Box>

        <Box className={classes.dialogContent}>
          <Box display="flex" alignItems="flex-start">
            <Typography className={classes.dialogContentHintBlockTitle}>{t('customProcesses.workspace')}:</Typography>
            <Typography className={classes.dialogContentHintBlockSubtitle}>{companyName}</Typography>
          </Box>

          <Box className={classes.dialogContentForm}>
            <Box display="flex" flexDirection="column" textAlign="center">
              <Typography
                className={classes.dialogContentFormTitle}>{t('customProcesses.creationPage.title')}</Typography>
              <Typography
                className={classes.dialogContentFormSubtitle}>{t('customProcesses.creationPage.message')}</Typography>
            </Box>

            <TemplateProcessSettingsForm
              isReadonlyView={false}
              validateIcon={submitClicked}
              selectedProcessLanguage={''}
              onValuesChange={onValuesChange}
              fields={fields as unknown as FormProcess[]}
              register={register}
              update={update}
              append={append}
              remove={remove}
              errors={errors}
              formErrors={formErrors}
              processIcon={processIcon}
              setProcessIcon={setProcessIcon}
              processGroup={processGroup}
              setProcessGroup={setProcessGroup}
              availableLocales={availableLocales}
              languageTabs={languageTabs}
              setLanguageTabs={setLanguageTabs}
              currentLanguageTab={currentLanguageTab}
              setCurrentLanguageTab={setCurrentLanguageTab}
              languageTabsWithErrors={languageTabsWithErrors}
            />
          </Box>
        </Box>

        <Box className={classes.dialogFooter}>
          <Button variant="text" onClick={onClose}>{t('customProcesses.creationPage.buttons.cancel')}</Button>
          <Button
            className={classes.publishBtn}
            variant="contained"
            type="submit"
            color="primary"
            endIcon={<ChevronRight />}
          >
            {t('customProcesses.creationPage.buttons.next')}
          </Button>
        </Box>
      </form>
    </Dialog>
  );
};
