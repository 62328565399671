import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  setDraftsListRefreshStatus,
  setDraftsRequestsCountAction,
  useDraftsListNeedRefreshStatus,
} from 'store/requests';
import { draftsMassDeleteRequest, getMyRequests } from 'api/requests';

const PAGE_SIZE = 10;

const useDraftsList = ({ searchText }: { searchText: string }) => {
  const dispatch = useDispatch();
  const needRefresh = useDraftsListNeedRefreshStatus();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [savedDrafts, setSavedDrafts] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(1);

  const requestData = useMemo(() => ({
      pagination: {
        size: pageSize,
        page: currentPage - 1,
        sort: 'taskStartDate,DESC',
      },
      searchText,
      taskCompleted: false,
      completed: false,
      archive: false,
      pending: false,
      draft: true,
    }), [currentPage, pageSize, searchText]);

  const loadDrafts = useCallback(async () => {
    if (!(loading && needRefresh)) {
      setLoading(true);

      try {
        const drafts = await getMyRequests(requestData);

        setData(drafts);
        dispatch(setDraftsListRefreshStatus(false));
        dispatch(setDraftsRequestsCountAction(drafts.totalElements));
      } catch (error) {
        setError(error.message || 'Error loading drafts');
      } finally {
        setLoading(false);
      }
    }
  }, [requestData, needRefresh]);

  useEffect(() => {
    loadDrafts();
  }, [loadDrafts]);

  useEffect(() => {
    if (data?.content && data?.totalPages) {
      setSavedDrafts(data.content);
      setTotalPages(data.totalPages);
      setTotalElements(data.totalElements);
    } else {
      setSavedDrafts([]);
      setTotalPages(0);
    }
  }, [data]);

  const handlePageChange = (_, newPage) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
  };

  const handleDraftsMassDelete = async (action, selectedTickets): Promise<void> => {
    try {
      const processInstanceId = Object
        .values(selectedTickets)
        .map(({ processInstanceId }) => processInstanceId);

      await draftsMassDeleteRequest({
        data: {
          processInstanceId
        }
      });
      await loadDrafts();
    } catch (error) {
      setError(error.message || 'Error loading drafts');
      throw error;
    }
  };

  return {
    savedDrafts,
    currentPage,
    totalPages,
    totalElements,
    error,
    loading,
    pageSize,
    setSavedDrafts,
    handlePageChange,
    handlePageSizeChange,
    handleDraftsMassDelete,
  };
};

export default useDraftsList;
