import React, { useState, useCallback } from 'react';
import { Box, FormControl, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { UrgentToggle, AppTextField } from 'components';

import {
  URGENT_DECLINE_TEXTAREA_NAME,
  URGENT_SWITCH_NAME,
} from '../constants';
import useStyles from '../useStyles';

type Props = {
  isUrgentReadOnly: boolean;
  isUrgentRequest: boolean;
  urgentReason: string;
  urgentDeclineReason: string;
  setUrgentRequestValue: (value: boolean) => void;
  handleUrgentDecline: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const BpmUrgentRequestCheck: React.FunctionComponent<Props> = ({
  isUrgentReadOnly,
  isUrgentRequest,
  urgentReason,
  urgentDeclineReason,
  handleUrgentDecline,
  setUrgentRequestValue,
}) => {
  const { t } = useTranslation();
  const { register, formState: { errors }} = useFormContext();
  // for not showing urgent decline reason if urgent was turned off on rework
  const [isToggleValueChanged, setToggleValueChanged] = useState(false);

  const handleChange = useCallback(
    (value) => {
      setUrgentRequestValue(value);
      if (value !== isUrgentRequest) {
        setToggleValueChanged(true);
      }
    },
    [setToggleValueChanged, setUrgentRequestValue]
  );

  const classes = useStyles();

  if (!register) {
    return null;
  }

  return (
    <Box py={3} display="flex" width="100%">
      <Typography variant="h5" className={classes.formGroupTitle}>
        {t('form_components.urgent.title')}
      </Typography>
      <Box display="flex" flexDirection="column" width="100%">
        <UrgentToggle
          {...register(URGENT_SWITCH_NAME)}
          disabled={isUrgentReadOnly}
          checked={isUrgentRequest}
          setValue={handleChange}
        />
        <Box mt={2}>
          <Box mb={1}>
            <Typography>{t('form_components.urgent.reasonOnTitle')}</Typography>
          </Box>
          <Typography color="secondary">{urgentReason}</Typography>
        </Box>

        {isToggleValueChanged && !isUrgentRequest && (
          <Box mt={3}>
            <FormControl fullWidth>
              <AppTextField
                fullWidth
                multiline
                rows={2}
                minRows={2}
                maxRows={5}
                label={t('form_components.urgent.reasonOffTitle')}
                disabled={isUrgentReadOnly}
                {...register(URGENT_DECLINE_TEXTAREA_NAME, {
                  required: t('form_components.required_errors.default') as string,
                })}
                defaultValue={urgentDeclineReason}
                onChange={handleUrgentDecline}
                error={Boolean(errors[URGENT_DECLINE_TEXTAREA_NAME])}
                helperText={
                  Boolean(errors[URGENT_DECLINE_TEXTAREA_NAME]) &&
                  errors[URGENT_DECLINE_TEXTAREA_NAME].message
                }
              />
            </FormControl>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BpmUrgentRequestCheck;
