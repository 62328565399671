import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, LinearProgress, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Spinner } from 'components';

import { useTaskState } from 'store/requests';
import { getEntityInstanceById, getTaskHistoryV2 } from 'api/requests';

import useStyles from './useStyles';
import DoneIcon from 'assets/images/icons/done.svg';
import StarOrangeIcon from 'assets/images/icons/star-orange.svg';
import AccessTimeIcon from 'assets/images/icons/access-time.svg';

const LOADING_ERROR = 'Error loading suppliers';
const SUPPLIER_NAME_FIELD_TYPE = 'string';
const SUPPLIER_NAME_FIELD = 'supplier';
const SUPPLIER_VOTES_FIELD_TYPE = 'string';
const SUPPLIER_VOTES_FIELD = 'votes';
const TENDER_COMMITTEE_ACTION_SYSNAME = 'procurement_tc_new';

const SupplierCard = ({ supplier, totalVotesCount, isWinner }) => {
  const classes = useStyles();

  const supplierName = useMemo(() => {
    if (!supplier) {
      return 'Malformed task';
    }

    const supplierNameTypeAttributes =
      supplier[SUPPLIER_NAME_FIELD_TYPE + 'Attributes'];
    const nameAttribute = supplierNameTypeAttributes.find(
      ({ name }) => name === SUPPLIER_NAME_FIELD
    );

    return nameAttribute?.value;
  }, [supplier]);

  const supplierVotesValue = useMemo(() => {
    if (!supplier || totalVotesCount === 0) {
      return 0;
    }

    const supplierVotesTypeAttributes =
      supplier[SUPPLIER_VOTES_FIELD_TYPE + 'Attributes'];
    const votesAttribute = supplierVotesTypeAttributes.find(
      ({ name }) => name === SUPPLIER_VOTES_FIELD
    );
    const votesCount =
      (votesAttribute?.value || '').split(',').filter((v) => !!v).length || 0;

    return Math.round((votesCount * 100) / totalVotesCount);
  }, [supplier]);

  return (
    <Box display="flex" flexDirection="column" className={classes.supplierCard}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography className={classes.supplierCardTitle}>
          {supplierName}
        </Typography>

        <Box display="flex" alignItems="center" justifyContent="space-end">
          {isWinner && (
            <Box mr={1}>
              <img src={StarOrangeIcon} className={classes.statusIcon} alt="" />
            </Box>
          )}

          <Typography className={classes.votesPercent}>
            {supplierVotesValue}%
          </Typography>
        </Box>
      </Box>

      <Box pt={1}>
        <LinearProgress
          className={classes.linearProgressBar}
          variant="determinate"
          value={supplierVotesValue}
        />
      </Box>
    </Box>
  );
};

const NoSupplierError = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography>{t('form_components.votingResults.noSuppliers')}</Typography>
    </Box>
  );
};

type Props = {
  name: string;
  suppliersListAttribute?: string;
  dependentModalAttributeSysName?: string;
  handleAction: (params: {
    action: string;
    tplName?: string;
    componentParams?: { [key: string]: any };
    instanceFormFieldName?: string;
    instance?: any;
  }) => void;
};

export const SuppliersVotingResult = ({
  name,
  suppliersListAttribute = '',
  handleAction = null,
  dependentModalAttributeSysName = '',
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { data: bpmTask } = useTaskState();
  const { watch } = useFormContext();

  const [instancesList, setInstancesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [isCompleted, setCompleted] = useState(false);

  const suppliersListAtrributeName = useMemo(() => {
    if (!bpmTask || !suppliersListAttribute) {
      return null;
    }

    const listAttribute = bpmTask.getAttributeBySysName(suppliersListAttribute);
    return listAttribute?.name;
  }, [suppliersListAttribute, bpmTask]);

  const value = watch(suppliersListAtrributeName);

  useEffect(() => {
    const fetch = async (idsList) => {
      try {
        const loadingInstances = idsList.map(async (id) => {
          const data = await getEntityInstanceById(id);
          return data;
        });
        const loadedInstances = await Promise.all(loadingInstances);
        setInstancesList(loadedInstances);
      } catch (error) {
        setError(LOADING_ERROR);
      } finally {
        setLoading(false);
      }
    };

    const parsedValue = value?.split(',')?.filter((v) => !!v) || [];
    if (parsedValue?.length !== instancesList?.length && !loading) {
      try {
        setError('');
        setLoading(true);
        fetch(parsedValue);
      } catch (error) {
        setError(LOADING_ERROR);
      }
    }
  }, [value]);

  const totalVotesCount = useMemo(
    () =>
      instancesList.reduce((counter, supplier) => {
        if (!supplier) {
          return counter;
        }

        const supplierVotesTypeAttributes =
          supplier[SUPPLIER_VOTES_FIELD_TYPE + 'Attributes'];
        const votesAttribute = supplierVotesTypeAttributes.find(
          ({ name }) => name === SUPPLIER_VOTES_FIELD
        );

        const votesCount =
          (votesAttribute?.value || '').split(',').filter((v) => !!v).length ||
          0;

        return counter + votesCount;
      }, 0),
    [instancesList]
  );

  useEffect(() => {
    const fetch = async () => {
      const taskHistory = await getTaskHistoryV2(bpmTask.processInstanceId);

      const steps = [...taskHistory?.pastTasks, ...taskHistory?.futureTasks];
      const votingStepsCount = steps.filter(
        ({ actionSysName }) => actionSysName === TENDER_COMMITTEE_ACTION_SYSNAME
      ).length;

      // voting is completed if all assignees have voted, and number of votes
      // is equal to the number of assignees (and history items for voting step)
      // or if request is completed
      setCompleted(
        totalVotesCount === votingStepsCount || bpmTask?.isCompleted
      );
    };

    fetch();
  }, [totalVotesCount, bpmTask, setCompleted]);

  const statusText = useMemo(
    () => (isCompleted ? t('form_components.votingResults.completed') : t('form_components.votingResults.inProgress')),
    [isCompleted]
  );

  const statusIcon = useMemo(() => (isCompleted ? DoneIcon : AccessTimeIcon), [
    isCompleted,
  ]);

  const isSuppliersExists = useMemo(() => !loading && !!instancesList?.length, [
    loading,
    instancesList,
  ]);

  const winnersIds = useMemo(() => {
    if (!isCompleted) {
      return [];
    }

    const votesCounts = instancesList.map((supplier) => {
      if (!supplier) {
        return {
          id: 0,
          count: 0,
        };
      }

      const supplierVotesTypeAttributes =
        supplier[SUPPLIER_VOTES_FIELD_TYPE + 'Attributes'];
      const votesAttribute = supplierVotesTypeAttributes.find(
        ({ name }) => name === SUPPLIER_VOTES_FIELD
      );
      const votesCount = votesAttribute?.value?.length || 0;
      return {
        id: +supplier?.id,
        count: +votesCount,
      };
    });

    const maximumVotesCount = Math.max(
      ...votesCounts.map(({ count }) => count)
    );

    const winners = votesCounts
      .filter(({ count }) => count === maximumVotesCount)
      .map(({ id }) => id);

    return winners;
  }, [isCompleted, instancesList]);

  const handleDetailsClick = () => {
    const actionParams = {
      action: 'OPEN MODAL',
      componentParams: {
        isModalConnected: true,
        dependentModalAttributeSysName,
      },
    };
    handleAction(actionParams);
  };

  if (error) {
    return (
      <Box>
        <Typography>{error}</Typography>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <img src={statusIcon} className={classes.statusIcon} alt="" />

          <Box ml={2}>
            <Typography className={classes.statusText}>{statusText}</Typography>
          </Box>
        </Box>

        <Link className={classes.detailsLink} onClick={handleDetailsClick}>
          {t('form_components.votingResults.details')}
        </Link>
      </Box>

      <Box display="flex" flexDirection="column" pt={4}>
        {loading ? (
          <Spinner />
        ) : !isSuppliersExists ? (
          <NoSupplierError />
        ) : (
          instancesList.map((supplier, supplierIndex) => (
            <SupplierCard
              key={supplierIndex}
              supplier={supplier}
              totalVotesCount={totalVotesCount}
              isWinner={isCompleted && winnersIds.includes(supplier.id)}
            />
          ))
        )}
      </Box>
    </Box>
  );
};
