import React, { useState, useMemo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  URGENT_SWITCH_VALUE__DEFAULT,
  URGENT_TEXTAREA_VALUE__DEFAULT,
  URGENT_DECLINE_VALUE__DEFAULT,
  URGENT_SWITCH_NAME,
  URGENT_TEXTAREA_NAME,
  URGENT_DECLINE_TEXTAREA_NAME,
  URGENT_PROCESSES,
} from './constants';
import { useTaskState } from 'store/requests';

type Urgent = {
  urgent: boolean;
  reason: string | undefined;
};

export default function useBpmUrgentRequest() {
  const { setValue } = useFormContext();
  const { data: bpmTask } = useTaskState();

  const { urgent, reason }: Urgent = useMemo<Urgent>(
    () => (bpmTask ? bpmTask.entity.urgent : { urgent: false, reason: '' }),
    [bpmTask]
  );

  const [isUrgentRequest, setUrgentRequest] = useState<boolean>(
    urgent || URGENT_SWITCH_VALUE__DEFAULT
  );
  const [urgentReason, setUrgentReason] = useState<string>(
    reason || URGENT_TEXTAREA_VALUE__DEFAULT
  );
  const [urgentDeclineReason, setUrgentDeclineReason] = useState<string>(
    URGENT_DECLINE_VALUE__DEFAULT
  );

  useEffect(() => {
    setUrgentRequest(urgent);
    setUrgentReason(reason);
    setValue(URGENT_SWITCH_NAME, urgent);
    setValue(URGENT_TEXTAREA_NAME, reason);
  }, [urgent, reason]);

  const isUrgentEmpty = useMemo<boolean>(() => !urgent && !reason, [
    urgent,
    reason,
  ]);

  const isUrgentReadOnly = useMemo<boolean>(
    () => bpmTask && bpmTask.isStepCompleted,
    [bpmTask]
  );

  const isUrgentFirstStep = useMemo<boolean>(
    () => bpmTask && bpmTask.isFirstStep,
    [bpmTask]
  );

  const isUrgentProcess = useMemo<boolean>(
    () => bpmTask && URGENT_PROCESSES.includes(bpmTask.businessEntity.sysName),
    [bpmTask]
  );

  const isUrgentMark = useMemo<boolean>(
    () => bpmTask && bpmTask.currentAction.urgentMark,
    [bpmTask]
  );

  const setUrgentRequestValue = (value): void => {
    setUrgentRequest(value);
    setValue(URGENT_SWITCH_NAME, value);
  };

  const handleUrgentReason = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setUrgentReason(event.target.value);
    setValue(URGENT_TEXTAREA_NAME, event.target.value);
  };

  const handleUrgentDecline = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setUrgentDeclineReason(event.target.value);
    setValue(URGENT_DECLINE_TEXTAREA_NAME, event.target.value);
  };

  return {
    isUrgentProcess,
    isUrgentMark,
    isUrgentEmpty,
    isUrgentReadOnly,
    isUrgentRequest,
    isUrgentFirstStep,
    urgentReason,
    urgentDeclineReason,
    handleUrgentReason,
    setUrgentRequestValue,
    handleUrgentDecline,
  };
}
