import React, { FC, useEffect, useState, useMemo } from 'react';

import { Typography, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTreeItemStyles, useTreeStyles } from './ReadOnlyMultiSelectGlossary.styles';
import cn from 'classnames';
import { TreeView, TreeItem } from '@mui/x-tree-view';

import { ModalTreeNodeStyles } from 'pages/Task/TaskForm/types';
import SelectedValueIcon from 'assets/images/icons/checkmark_circle_regular.svg';
import ChevronRightIcon from 'assets/images/icons/chevron_right_grey.svg';
import ChevronDownIcon from 'assets/images/icons/chevron_down_grey.svg';

export const ModalTreeNode: FC<ModalTreeNodeStyles> = (props) => {
  const classes = useTreeItemStyles();
  const treeClasses = useTreeStyles();
  const { t, i18n } = useTranslation();
  const [childNodes, setChildNodes] = useState(null);
  const [expanded, setExpanded] = React.useState([props.id]);
  const label = props?.localization[i18n.language] || props.directoryName || props.value;

  const handleChange = (event, nodes) => {
    const expandingNodes = nodes.filter(x => !expanded.includes(x));
    setExpanded(nodes);
    if (expandingNodes[0]) {
      const child = expandingNodes[0];
      getChildNodes(child);
    }
  };

  const getChildNodes = (id: string) => {
    const filteredChildren =  props.children
      .filter(node => node.data.status === 'PUBLISHED' && (props.selectedIds.includes(node.data.id) || props.treeStructureNodes[node.id]?.selectedChildCount > 0));

    setChildNodes(
      filteredChildren.map(
          node => <ModalTreeNode
            key={node.id}
            item={node.data}
            children={node.children}
            {...node.data}
            selectedIds={props.selectedIds}
            treeStructureNodes={props.treeStructureNodes}
          />,
        ),
    );
  }

  useEffect(() => {
    if (expanded) {
      // const child = expandingNodes[0];
      getChildNodes(null);
    }
  }, [props.selectedIds, props.treeStructureNodes])

  const nodeIcon = useMemo(() => {
    if (props.children.length === 0) {
      return <img src={SelectedValueIcon}/>;
    }

    if (expanded.length === 0) {
      return <img src={ChevronRightIcon}/>;
    }

    return <img src={ChevronDownIcon}/>;
  }, [props, expanded])

  return (
    <TreeView
      className={treeClasses.root}
      // defaultCollapseIcon={<ExpandMoreIcon/>}
      // defaultExpandIcon={<ChevronRightIcon/>}
      expanded={expanded}
      onNodeToggle={handleChange}
    >
      <TreeItem
        nodeId={props.id}
        icon={nodeIcon}
        label={<div className={classes.labelRoot}>
          <Tooltip title={label} placement="top" arrow enterDelay={500} classes={{popper: classes.tooltipPopper, tooltip: classes.tooltip}}>
            <Typography variant="body2" className={cn(classes.labelText)}>
              {label}
            </Typography>
          </Tooltip>
        </div>}
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          selected: classes.selected,
          group: classes.group,
          label: classes.label,
          iconContainer: classes.iconContainer,
        }}
      >
        {childNodes}
      </TreeItem>
    </TreeView>
  );
};
