import { getInstance } from 'api/requests';

export const getAttachmentInformationById = async ({ attachmentId, handleError = () => {} }) => {
  try {
    const instance = await getInstance(attachmentId);
    return {
      name: instance.stringAttributes.find(attr => attr.name === 'Название').value,
      type: instance.stringAttributes.find(attr => attr.name === 'Тип').value,
      fileInstanceId: instance.stringAttributes.find(attr => attr.name === 'Файл').value
    };
  } catch (e) {
    handleError();
  }
};

export const convertFileToBase64 = file => {
  return new Promise((resolve, reject) => {
    const handlerReaderOnLoad = reader => {
      const { result } = reader;

      if (result.indexOf(',') > -1) {
        const endOfUnnecessaryInformationIndex = result.indexOf(',') + 1;
        const accurateResult = result.substring(endOfUnnecessaryInformationIndex);
        return resolve(accurateResult);
      }

      return resolve(result);
    };
    const handlerReaderOnError = error => {
      return reject(error);
    };

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => handlerReaderOnLoad(reader);
    reader.onerror = error => handlerReaderOnError(error);
  });
};
