import { useMemo, useState } from 'react';

import { NotificationManager } from 'react-notifications';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';

import { useBilling, useUserProfile } from 'hooks';
import { getPublishedProcessesCount, makeTemplateProcessRequest, makeTemplateRequest } from 'api/requests';
import { getAvailableTemplateProcessesListAction } from 'store/requests';
import { getHoldingTranslations } from 'i18/utils';

import { FormProcess, Language, ProcessStep, TemplateProcess } from '../../../TemplateProcesses.types';
import {
  DM_CHECK_ASSIGNEE,
  MAX_DESCRIPTION_LENGTH,
  MAX_NAME_LENGTH,
  MAX_REPEAT_COUNTS,
  PERFORMER_STEP_ASSIGNEE,
  PROCESS_NAME_DEFAULT,
} from '../../../TemplateProcesses.constants';
import {
  GLOSSARY_FIELD_DIRECTORY_CODE_PARAM,
  GLOSSARY_FIELD_DIRECTORY_PARAM,
  GLOSSARY_FIELD_KEY,
  GLOSSARY_MULTI_SELECT_FIELD_KEY,
  HIDDEN_FIELD_SUFFIX, NOT_REQUIRED_EDITABLE_FIELD_SUFFIX,
  READONLY_FIELD_SUFFIX,
} from '../../ProcessSetupSidePanel/ProcessSetupSidePanel.constants';
import { getDisplayedStepOrder } from '../../../TemplateProcesses.utils';
import { setHoldingTranslationsLoaded } from '../../../../../store/main';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { getProcessStepsPublicationParams } from './utils';
import {
  CONDITION_STEPS_LIMIT,
  DESRIPTION_TEXT_LIMIT,
  // PARALLEL_STEPS_LIMIT,
  // getParallelAndConditionStepsCount,
} from '../../ProcessSetupSidePanel';
import { getCorrectTimeWithISOFormat } from 'utils/time';

const TARIFF_LIMITS_KEY = 'process_published.limit';
// bro add validation somwhere here
export const useProcessPublication = ({
  documents,
  allProcessStepsFields,
  autoSaveProcessSysName,
  autostartSettings,
  setNeedResetProcessForm,
  clearData,
  clearErrors,
  conditions,
  directManagerSteps,
  fields,
  fieldsSettingsForm,
  formErrors,
  handlePublishedProcessesLimitDialogOpen,
  handleSubmit,
  initialValues,
  isAutostartActive,
  languages,
  languageTabs,
  onClose,
  onFormSubmit,
  parallelBranchesGroups,
  performerSelectionSteps,
  processGroup,
  processIcon,
  publishedVersionValues,
  setCurrentConditionId,
  setCurrentLanguage,
  setCurrentParallelBranchesGroupId,
  setCurrentProcessIndex,
  setCurrentTab,
  setFirstErrorStepIndex,
  setFocusedStepFieldId,
  setFormErrors,
  setIsNewField,
  setFocusedDocumentId,
  setIsNewDocument,
  setIsProcessPublishing,
  setLanguagesWithErrors,
  setLanguageTabsWithErrors,
  setSelectedItemType,
  setTabsWithErrors,
  summaryFields,
  trigger,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { companyId } = useUserProfile();
  const { tariffLimits } = useBilling();
  const routeMatch = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const isTemplatesPage = useMemo(() => {
    return routeMatch.path === '/templates/:id' || routeMatch.path === '/templates';
  }, [routeMatch]);
  const [dataProcess, setDataProcess] = useState();

  const validateProcessSettingsValues = async (): Promise<boolean> => {
    await trigger('formProcess');
    const languageTabsWithErrors = [];
    for (const fieldsGroup of fieldsSettingsForm as any[]) {
      if (!fieldsGroup?.processName.trim() || !fieldsGroup?.description.trim() || fieldsGroup?.processName?.length > MAX_NAME_LENGTH || fieldsGroup?.description?.length > MAX_DESCRIPTION_LENGTH) {
        languageTabsWithErrors.push(fieldsGroup?.language);
      }
    }

    setLanguageTabsWithErrors(languageTabsWithErrors);
    if (isTemplatesPage) {
      setTabsWithErrors(tabs => languageTabsWithErrors.length > 0
                                ? [...tabs, 'settings']
                                : []);
    } else {
      setTabsWithErrors(tabs => languageTabsWithErrors.length > 0
                                ? [...tabs, 'settings']
                                : tabs.filter(tab => tab !== 'settings'));
    }
    if (languageTabsWithErrors.length === 0) {
      // fix errors sometimes shown for filled fields
      clearErrors('formProcess');
    }
    return languageTabsWithErrors.length === 0;
  };

  const validateForm = () => {
    const processSteps = fields as unknown as ProcessStep[];
    const errors = [];
    const langsWithErrors = [];


    for (const step of processSteps) {
      errors.push({
        stepName: [],
        stepDescription: [],
        assigneeId: false,
        parallelAssignees: step.assigneeId?.map(() => ({ required: false, repeated: false })),
        fields: false,
        errorFieldsIds: [],
        timer: false,
        autostartRepeatCount: false,
        autostartEndRepeatCount: false,
        scriptName: [],
        scriptCondition: false,
        parallelBranchesName: [],
        documents: [],
        documentsWithoutSignature: [],
        lengthLimit: {
          stepName: [],
          scriptName: [],
          parallelBranchesName: [],
        },
      });

      for (const lang of languageTabs) {
        if (!step.stepName[lang].trim()) {
          errors[step.stepOrder - 1].stepName.push(lang);
          if (!langsWithErrors.includes(lang)) {
            langsWithErrors.push(lang);
          }
        }
        if (step?.stepName[lang]?.length > MAX_NAME_LENGTH) {
          errors[step.stepOrder - 1].lengthLimit.stepName.push(lang);
        }
      }

      const stepDescriptionKeys = Object.keys(step.stepDescription);

      if (stepDescriptionKeys.length > 1) {
        const stepDescriptionValues = Object.values(step.stepDescription);

        const isEveryFilled = stepDescriptionValues.every(value => Boolean(value));
        const isEveryNotFilled = stepDescriptionValues.every(value => !value);

        if (!isEveryNotFilled && !isEveryFilled) {
          for (const lang of languageTabs) {
            if (!step.stepDescription[lang].trim()) {
              errors[step.stepOrder - 1].stepDescription.push(lang);
              if (!langsWithErrors.includes(lang)) {
                langsWithErrors.push(lang);
              }
            }
          }
        }
      }

      if (step.assigneeId?.length === 0 && !(isAutostartActive && step.stepOrder === 1) && !directManagerSteps.includes(step.stepOrder) && !performerSelectionSteps.includes(step.stepOrder - 1)) {
        if (isTemplatesPage) {
          errors[step.stepOrder - 1].assigneeId = false;
        } else {
          errors[step.stepOrder - 1].assigneeId = true;
        }
      }

      if (step.parallel) {
        for (let i = 0; i < step.assigneeId?.length; i++) {
          if (!step?.assigneeId[i]) {
            errors[step.stepOrder - 1].parallelAssignees[i].required = true;
            errors[step.stepOrder - 1].assigneeId = true;
          }
          if (step.assigneeId?.[i] && step.assigneeId.indexOf(step.assigneeId?.[i]) !== i) {
            errors[step.stepOrder - 1].parallelAssignees[i].repeated = true;
            errors[step.stepOrder - 1].assigneeId = true;
          }
        }
      }

      if (step.fields.length === 0 && !(isAutostartActive && step.stepOrder === 1)) {
        errors[step.stepOrder - 1].fields = true;
      }

      const languagesWithEmptyLabels = [];
      const fieldsWithErrors = [];
      const paramsWithErrors = {};
      step.fields.forEach(field => {
        paramsWithErrors[field.id] = [];
        if ([GLOSSARY_FIELD_KEY, GLOSSARY_MULTI_SELECT_FIELD_KEY].includes(field.component)) {
          if (isTemplatesPage) {
            if (!field.params[GLOSSARY_FIELD_DIRECTORY_CODE_PARAM]) {
              paramsWithErrors[field.id].push(GLOSSARY_FIELD_DIRECTORY_CODE_PARAM);
              if (!fieldsWithErrors.includes(field.id)) {
                fieldsWithErrors.push(field.id);
              }
            }
          } else {
            if (!(field.params[GLOSSARY_FIELD_DIRECTORY_PARAM] || field.params[GLOSSARY_FIELD_DIRECTORY_CODE_PARAM])) {
              paramsWithErrors[field.id].push(GLOSSARY_FIELD_DIRECTORY_PARAM);
              if (!fieldsWithErrors.includes(field.id)) {
                fieldsWithErrors.push(field.id);
              }
            }
          }
        }

        const hintFieldValueValidation = Object.keys(field.hint).filter(lang => field.hint[lang].trim().length === 0);
        hintFieldValueValidation.forEach(([lang, value]) => {
          if (value?.length === 0 && !languagesWithEmptyLabels.includes(lang)) {
            languagesWithEmptyLabels.push(lang);
          }
          if (!fieldsWithErrors.includes(field.id)) {
            fieldsWithErrors.push(field.id);
          }
        });

        const placeholderFieldValueValidation = Object.keys(field.placeholder).filter(lang => field.placeholder[lang].length > DESRIPTION_TEXT_LIMIT);
        placeholderFieldValueValidation.forEach(l => {
          if (!fieldsWithErrors.includes(field.id)) {
            fieldsWithErrors.push(field.id);
          }
        });
      });

      languagesWithEmptyLabels.forEach(lang => {
        if (!langsWithErrors.includes(lang)) {
          langsWithErrors.push(lang);
        }
      });

      errors[step.stepOrder - 1].errorFieldsIds = fieldsWithErrors;
      errors[step.stepOrder - 1].paramsWithErrors = paramsWithErrors;
      errors[step.stepOrder - 1].fields = errors[step.stepOrder - 1].fields || fieldsWithErrors.length > 0;

      if (step.hasTimer && ((step.timer as string).length > 3 || !step.timer)) {
        errors[step.stepOrder - 1].timer = true;
      }

      if (isAutostartActive && step.stepOrder === 1) {
        if (autostartSettings.repeatCount === 0 || autostartSettings.repeatCount > MAX_REPEAT_COUNTS[autostartSettings.repeatUnit]) {
          errors[step.stepOrder - 1].autostartRepeatCount = true;
        }
        if (autostartSettings.endingType === 'after' &&
          (autostartSettings.endingRepetitionsCount === 0 || autostartSettings.endingRepetitionsCount > MAX_REPEAT_COUNTS[autostartSettings.repeatUnit])) {
          errors[step.stepOrder - 1].autostartEndRepeatCount = true;
        }
      }

      const stepCondition = conditions.find(c => c?.previousStepOrder === step.stepOrder);
      if (stepCondition) {
        for (const lang of languageTabs) {
          if (!stepCondition?.stepName[lang]?.trim()) {
            errors[step.stepOrder - 1].scriptName.push(lang);
            if (!langsWithErrors.includes(lang)) {
              langsWithErrors.push(lang);
            }
          }
          if (stepCondition?.stepName[lang]?.length > MAX_NAME_LENGTH) {
            errors[step.stepOrder - 1].lengthLimit.scriptName.push(lang);
          }
        }

        if (!stepCondition?.condition?.field) {
          if (isTemplatesPage) {
            errors[step.stepOrder - 1].scriptCondition = false;
          } else {
            errors[step.stepOrder - 1].scriptCondition = true;
          }
        }
      }

      const stepParallelBranches = parallelBranchesGroups.find(g => g?.previousStepOrder === step?.stepOrder);
      if (stepParallelBranches) {
        for (const lang of languageTabs) {
          if (!stepParallelBranches?.stepName[lang]?.trim()) {
            errors[step.stepOrder - 1].parallelBranchesName.push(lang);
            if (!langsWithErrors.includes(lang)) {
              langsWithErrors.push(lang);
            }
          }
          if (stepParallelBranches?.stepName[lang]?.length > MAX_NAME_LENGTH) {
            errors[step.stepOrder - 1].lengthLimit.parallelBranchesName.push(lang);
          }
        }
      }

      documents.forEach(doc => {
        const hasNotLinkedFields = doc.fields.some(field => !field.processFieldId);
        if (hasNotLinkedFields && doc.firstAppearanceStep <= step.stepOrder) {
          errors[step.stepOrder - 1].documents.push(doc.uniqueId);
        }
        if (doc.signatureSteps.length === 0) {
          errors[step.stepOrder - 1].documentsWithoutSignature.push(doc.uniqueId);
        }
      });
    }

    // const { parallelCount, conditionCount } = getParallelAndConditionStepsCount(parallelBranchesGroups, conditions);

    // const stepTypeLimitErrors = {
    //   parallelStepsCountError: false,
    //   conditionStepsCountError: false,
    // };
    // if (parallelCount > PARALLEL_STEPS_LIMIT) {
    //   stepTypeLimitErrors.parallelStepsCountError = true;
    // }
    // if (conditionCount > CONDITION_STEPS_LIMIT) {
    //   stepTypeLimitErrors.conditionStepsCountError = true;
    // }

    setLanguagesWithErrors(langsWithErrors);
    setFormErrors(errors);
    return { errors };
  };

  const displayValidationErrors = (errors, hasProcessSettingsErrors = false, isMinimumNumberOfStepsReached = true) => {
    if (hasProcessSettingsErrors) {
      setCurrentTab('settings');
    }

    if (!isMinimumNumberOfStepsReached) {
      NotificationManager.error(t('customProcesses.notifications.singleStepProcessError'), `${t('customProcesses.notifications.error')}!`);
    }

    const firstInvalidStepIndex = errors.findIndex(stepErrors =>
      stepErrors.stepName.length > 0 ||
      stepErrors.lengthLimit.stepName.length > 0 ||
      stepErrors.stepDescription.length > 0 ||
      stepErrors.assigneeId ||
      stepErrors.fields ||
      stepErrors.errorFieldsIds.length > 0 ||
      stepErrors.autostartRepeatCount ||
      stepErrors.autostartEndRepeatCount ||
      stepErrors.timer ||
      stepErrors?.documents?.length > 0 ||
      stepErrors?.documentsWithoutSignature?.length > 0);

    const firstInvalidConditionIndex = errors.findIndex(stepErrors => stepErrors.scriptName.length > 0 || stepErrors.lengthLimit.scriptName.length > 0 || stepErrors.scriptCondition);
    const stepCondition = conditions.find(c => c?.previousStepOrder === firstInvalidConditionIndex + 1);

    const firstInvalidParallelBranchesIndex = errors.findIndex(stepErrors => stepErrors.parallelBranchesName.length > 0 || stepErrors.lengthLimit.parallelBranchesName.length > 0);
    const stepParallelBranches = parallelBranchesGroups.find(g => g?.previousStepOrder === firstInvalidParallelBranchesIndex + 1);

    if (
      (firstInvalidParallelBranchesIndex < firstInvalidConditionIndex ||
        firstInvalidConditionIndex === -1) &&
      (firstInvalidParallelBranchesIndex < firstInvalidStepIndex ||
        firstInvalidStepIndex === -1) &&
      stepParallelBranches
    ) {
      const stepErrors = errors[firstInvalidParallelBranchesIndex];
      if (stepErrors.parallelBranchesName.length > 0 || stepErrors.lengthLimit.parallelBranchesName.length > 0) {
        for (const lang of languages) {
          if (stepErrors.parallelBranchesName.includes(lang) || stepErrors.lengthLimit.parallelBranchesName.includes(lang)) {
            setCurrentLanguage(lang as Language);
            break;
          }
        }
      }

      setCurrentParallelBranchesGroupId(stepParallelBranches?.id);
      setSelectedItemType('parallelBranchesGroups');
    } else if ((firstInvalidConditionIndex < firstInvalidStepIndex || firstInvalidStepIndex === -1) && stepCondition) {
      const stepErrors = errors[firstInvalidConditionIndex];
      if (stepErrors.scriptName.length > 0 || stepErrors.lengthLimit.scriptName.length > 0) {
        for (const lang of languages) {
          if (stepErrors.scriptName.includes(lang) || stepErrors.lengthLimit.scriptName.includes(lang)) {
            setCurrentLanguage(lang as Language);
            break;
          }
        }
      }

      setCurrentConditionId(stepCondition?.id);
      setSelectedItemType('condition');
    } else {
      if (firstInvalidStepIndex === -1) return;
      const stepErrors = errors[firstInvalidStepIndex];
      if (stepErrors?.stepName.length > 0 || stepErrors?.lengthLimit.stepName.length > 0) {
        for (const lang of languages) {
          if (stepErrors.stepName.includes(lang) || stepErrors.lengthLimit.stepName.includes(lang)) {
            setCurrentLanguage(lang as Language);
            break;
          }
        }
      }

      if (stepErrors?.stepDescription.length > 0) {
        for (const lang of languages) {
          if (stepErrors?.stepDescription.includes(lang)) {
            setCurrentLanguage(lang as Language);
            break;
          }
        }
      }
      try {
        if (errors[firstInvalidStepIndex].documents.length > 0) {
          setIsNewDocument(false);
          setFocusedDocumentId(errors[firstInvalidStepIndex].documents[0]);
        }

        if (errors?.[firstInvalidStepIndex]?.errorFieldsIds?.length > 0) {
          setIsNewField(false);
          setFocusedStepFieldId(errors?.[firstInvalidStepIndex]?.errorFieldsIds?.[0]);
          setFocusedDocumentId('');
        }
        setCurrentProcessIndex(firstInvalidStepIndex);
        setSelectedItemType('processStep');
      } catch (e) {
        console.error('Seems like for logical error:', e);
      }
    }
  };

  const hasErrors = useMemo(() =>
      formErrors.some(stepErrors =>
        stepErrors.stepName.length > 0 ||
        stepErrors?.stepDescription.length > 0 ||
        stepErrors.assigneeId ||
        stepErrors.fields ||
        stepErrors.errorFieldsIds.length > 0 ||
        stepErrors.autostartRepeatCount ||
        stepErrors.autostartEndRepeatCount ||
        stepErrors.timer ||
        stepErrors.scriptCondition ||
        stepErrors.scriptName.length > 0 ||
        stepErrors.parallelBranchesName.length > 0 ||
        stepErrors.lengthLimit.stepName.length > 0 ||
        stepErrors.lengthLimit.scriptName.length > 0 ||
        stepErrors.lengthLimit.parallelBranchesName.length > 0 ||
        stepErrors?.documents?.length > 0 ||
        stepErrors?.documentsWithoutSignature?.length > 0),
    [formErrors]);

  const handleCheck = async () => {
    const isSettingsFormValid = await validateProcessSettingsValues();
    const { errors } = validateForm();
    // const { conditionStepsCountError, parallelStepsCountError } = stepTypeLimitErrors;
    const hasErrors = errors.some(stepErrors =>
      stepErrors.stepName.length > 0 ||
      stepErrors?.stepDescription.length > 0 ||
      (stepErrors.assigneeId && !isTemplatesPage) ||
      stepErrors.fields ||
      stepErrors.errorFieldsIds.length > 0 ||
      stepErrors.autostartRepeatCount ||
      stepErrors.autostartEndRepeatCount ||
      stepErrors.timer ||
      stepErrors.scriptName.length > 0 ||
      (stepErrors.scriptCondition && !isTemplatesPage) ||
      stepErrors.parallelBranchesName.length > 0 ||
      stepErrors.lengthLimit.stepName.length > 0 ||
      stepErrors.lengthLimit.scriptName.length > 0 ||
      stepErrors.lengthLimit.parallelBranchesName.length > 0 ||
      stepErrors?.documents?.length > 0 ||
      stepErrors?.documentsWithoutSignature?.length > 0,
    );

    const isMinimumNumberOfStepsReached = fields.length > 1;

    if (hasErrors || !isSettingsFormValid || !isMinimumNumberOfStepsReached) {
      if (isTemplatesPage) {
        if (hasErrors) {
          setTabsWithErrors(['steps']);
        } else if (!isSettingsFormValid) {
          setTabsWithErrors(['settings']);
        } else {
          setTabsWithErrors(tabs => tabs.filter(tab => tab !== 'settings' || tab !== 'steps'));
        }
      } else {
        setTabsWithErrors(tabs => hasErrors
                                  ? tabs.filter(tab => tab !== 'steps')
                                  : [...tabs, 'steps']);
      }
      displayValidationErrors(errors, !isSettingsFormValid, isMinimumNumberOfStepsReached);
    } else {
      NotificationManager.success(t('customProcesses.notifications.formIsValid'));
    }

    const errorStepIndex = errors.findIndex(error => {
      const {
        assigneeId,
        fields,
        stepName,
        stepDescription,
        timer,
        autostartRepeatCount,
        autostartEndRepeatCount,
        documents,
      } = error;

      return (assigneeId && !isTemplatesPage) || fields || stepName.length > 0 || stepDescription.length > 0 || timer || autostartRepeatCount || autostartEndRepeatCount || documents.length > 0;
    });

    setFirstErrorStepIndex(errorStepIndex);
  };

  const getBeautifiedAutostartSettings = () => {
    let recurrenceRule = '';
    switch (autostartSettings.repeatUnit) {
      case 'day':
        recurrenceRule = `RRULE:FREQ=DAILY;COUNT=${autostartSettings.repeatCount}`;
        break;
      case 'week':
        recurrenceRule = `RRULE:FREQ=WEEKLY;COUNT=${autostartSettings.repeatCount};BYDAY=${autostartSettings.repeatDays.join(',')}`;
        break;
      case 'month':
        recurrenceRule = `RRULE:FREQ=MONTHLY;COUNT=${autostartSettings.repeatCount}`;
        break;
      case 'year':
        recurrenceRule = `RRULE:FREQ=YEARLY;COUNT=${autostartSettings.repeatCount}`;
        break;
    }

    return {
      startDate: autostartSettings.startDate.toISOString(),
      endDate: autostartSettings.endingType === 'date' ? autostartSettings.endingDate.toISOString() : null,
      endingNever: autostartSettings.endingType === 'never',
      endingCount: autostartSettings.endingType === 'after' ? autostartSettings.endingRepetitionsCount : null,
      recurrenceRule,
    };
  };

  const getBeatifiedData = (processSteps: ProcessStep[], isAutoSave = false): TemplateProcess => {
    const defaultProcessLanguage = languages[0] as Language;
    //TODO - use correct types instead of any
    const {
      processName,
      description,
    } = (fieldsSettingsForm as any).find(({ language }: FormProcess) => language === defaultProcessLanguage);

    // TODO: Исправить когда сделаем инициализацию с initialValues
    const defaultParameters = {
      companyId,
      processSysName: publishedVersionValues?.processSysName ?? (autoSaveProcessSysName?.current || '0'),
      processAutoSaveId: autoSaveProcessSysName?.current,
      creator: initialValues?.creator ?? '0',
      createdDate: initialValues?.createdDate ?? getCorrectTimeWithISOFormat(),
      lastUpdated: initialValues?.lastUpdated ?? getCorrectTimeWithISOFormat(),
      published: initialValues?.published ?? false,
      autoStart: initialValues?.autoStart || null,
    };

    const extraDefaultParameters =
      (isAutoSave)
      ? {
          processSysName: (initialValues?.processSysName === '1' && !autoSaveProcessSysName?.current)
                          || (!initialValues && !autoSaveProcessSysName?.current)
                          ? '0'
                          : autoSaveProcessSysName?.current || initialValues?.processSysName,
          processPublishedId: publishedVersionValues?.processSysName,
          visible: publishedVersionValues?.published ? false : null,
        }
      : {};

    const processLanguagesWithDefaultName = fieldsSettingsForm.map(languageData => ({
      ...languageData,
      processName: languageData.processName || PROCESS_NAME_DEFAULT[languageData.language],
    }));

    return {
      ...defaultParameters,
      ...extraDefaultParameters,
      processName: processName || PROCESS_NAME_DEFAULT[defaultProcessLanguage],
      description,
      iconPath: processIcon,
      category: processGroup || 'custom_process_template',
      languages: processLanguagesWithDefaultName,
      stepsCount: processSteps.length,
      integration: initialValues?.integration || null,
      performerList: performerSelectionSteps?.filter((value, index, array) => array.indexOf(value) === index),
      summary_new: null,
      summaryFields: [],
      autoStart: isAutostartActive ? 'ACTIVE' : 'INACTIVE',
      recurrenceAutoStart: getBeautifiedAutostartSettings(),
      generateAttributes: allProcessStepsFields.map(item => (
        {
          name: item?.field?.id,
          sysName: item?.field?.component,
          attributeLanguageDTO: languages.map(language => ({
            language,
            hint: item?.field.hint[language],
            placeholder: item?.field.placeholder[language],
          })),
          documentTemplateField: item?.field?.documentId || '',
          type: item?.field?.type || null,
          integration: item?.field?.integration || null,
        })),
      docflowTemplates: documents.map((doc, documentIndex) => {
        return {
          attachmentId: doc.id,
          id: doc?.backUniqueId,
          docFlowTemplateId: doc.uniqueId,
          department: doc?.department || '',
          type: doc?.type || '',
          fields: doc.fields.map(field => ({
            docFlowTemplateFieldId: field.id,
            id: field?.backUniqueId,
            docFlowTemplateId: doc.id,
            processFieldId: field.processFieldId,
            templateFieldId: field.id,
            templateFieldName: field.name,
            templateId: doc.id,
            type: field.type,
            hint: field.hint,
            langCode: field.language ? field.language.code : null
          })),
          firstAppearanceStep: doc.firstAppearanceStep,
          templateOrder: documentIndex + 1,
          processSysId: '',
          signRequiredSteps: doc.signatureSteps || [],
          documentHiddenSteps: doc.hiddenSteps || [],
          title: doc.title,
          description: doc.description || '',
        };
      }),
      steps: processSteps.map((processStep: ProcessStep) => {
        const {
          previousSteps,
          possibleNextSteps,
          branchesEndStep,
          branchesEndScriptModel,
          parallelJoin,
          conditionJoin,
          parallelBranchName,
          isStepWithCondition,
          scriptModel,
          hasSignature,
          summaryFields,
        } = getProcessStepsPublicationParams({
          processStep,
          processSteps,
          conditions,
          parallelBranchesGroups,
          documents,
          allProcessStepsFields,
          defaultProcessLanguage,
        });

        return {
          stepName: processStep.stepName[defaultProcessLanguage],
          stepDescription: processStep?.stepDescription[defaultProcessLanguage],
          stepOrder: processStep.stepOrder,
          displayedStepOrder: getDisplayedStepOrder(processStep, conditions, parallelBranchesGroups),
          assigneeId: directManagerSteps.includes(processStep.stepOrder)
                      ? [DM_CHECK_ASSIGNEE]
                      : performerSelectionSteps.includes(processStep.stepOrder - 1)
                        ? [PERFORMER_STEP_ASSIGNEE]
                        : processStep.assigneeId,
          timer: processStep.hasTimer ? +processStep.timer : 0,
          parallel: processStep.parallel ?? false,
          attributes: processStep.fields?.map(field => {
              let fieldSysName = field.component; // required editable field
              if (field.isHidden) {
                fieldSysName = field.component + HIDDEN_FIELD_SUFFIX;
              } else if (!field.isRequired) {
                if (field.isEditable) {
                  fieldSysName = field.component + NOT_REQUIRED_EDITABLE_FIELD_SUFFIX;
                } else {
                  fieldSysName = field.component + READONLY_FIELD_SUFFIX;
                }
              }

              return {
                name: field.id,
                state_order: field.order,
                sysName: fieldSysName,
                attributeLanguageDTO: languages.map(language => ({
                  language,
                  hint: field.hint[language],
                  placeholder: field.placeholder[language],
                })),
                params: {
                  ...(field.params || {}),
                  placeholder: field.placeholder[defaultProcessLanguage] || '',
                },
              };
            },
          ),
          prevStep: previousSteps,
          possibleSteps: possibleNextSteps,
          branchesEndStep: branchesEndStep,
          branchesEndScriptModel: branchesEndScriptModel,
          parallelBranch: processStep.isParallelBranchesGroupStep ?? false,
          parallelJoin: parallelJoin,
          conditionJoin: conditionJoin,
          parallelBranchName: parallelBranchName,
          script: isStepWithCondition,
          scriptModel: scriptModel,
          hasSignature: hasSignature,
          summaryFields: summaryFields,
        };
      }),
      stepsLanguage: processSteps.flatMap(processStep => {
          const {
            stepCondition,
            stepNextParallelGroup,
          } = getProcessStepsPublicationParams({
            processStep,
            processSteps,
            conditions,
            parallelBranchesGroups,
            documents,
            allProcessStepsFields,
            defaultProcessLanguage,
          });

          return languages.map((language: Language) => ({
            language,
            stepOrder: processStep.stepOrder,
            name: processStep.stepName[language],
            stepDescription: processStep?.stepDescription[language],
            scriptName: stepCondition ? stepCondition?.stepName[language] : '',
            parallelBranchName: stepNextParallelGroup ? stepNextParallelGroup.stepName[language] : '',
          }));
        },
      ),
    };
  };

  const getSuccessAlert = (): string => {
    if (isAutostartActive) {
      return t('customProcesses.notifications.autostart_started_success');
    }
    const query = queryString.parse(location.search);
    if (initialValues && !Boolean(query.useTemplate)) {
      return t('customProcesses.notifications.changing_success');
    }

    return t('customProcesses.notifications.publication_success');
  };

  const filterOnlySelectedLanguagesSteps = (processSteps): ProcessStep[] =>
    processSteps.map(processStep => {
      const filteredProcessStepName = languages.reduce((acc, lang) => ({
        ...acc,
        [lang]: processStep.stepName[lang],
      }), {});

      return {
        ...processStep,
        stepName: filteredProcessStepName,
      };
    });

  const onSubmit = async (payload: { processSteps: ProcessStep[] }): Promise<void> => {
    const filteredProcessSteps = filterOnlySelectedLanguagesSteps(payload.processSteps);
    const isSettingsFormValid = await validateProcessSettingsValues();
    const { errors } = validateForm();
    // const { conditionStepsCountError, parallelStepsCountError } = stepTypeLimitErrors;
    const hasStepsFormErrors = errors.some(stepErrors =>
      stepErrors.stepName.length > 0 ||
      stepErrors.stepDescription.length > 0 ||
      stepErrors.assigneeId ||
      stepErrors.fields ||
      stepErrors.timer ||
      stepErrors.autostartRepeatCount ||
      stepErrors.autostartEndRepeatCount ||
      stepErrors.scriptName.length > 0 ||
      stepErrors.scriptCondition ||
      stepErrors.parallelBranchesName.length > 0);
    const isMinimumNumberOfStepsReached = payload.processSteps.length > 1;

    if (hasStepsFormErrors || !isSettingsFormValid || !isMinimumNumberOfStepsReached) {
      setTabsWithErrors(tabs => hasStepsFormErrors
                                ? tabs.filter(tab => tab !== 'steps')
                                : [...tabs, 'steps']);
      displayValidationErrors(errors, !isSettingsFormValid, isMinimumNumberOfStepsReached);
      return;
    }

    setIsProcessPublishing(true);

    const data = getBeatifiedData(filteredProcessSteps);


    try {
      if (isTemplatesPage) {
        await makeTemplateRequest(data);
      } else {
        // @ts-ignore
        setDataProcess(data)
        await makeTemplateProcessRequest(data);
      }

      if (autoSaveProcessSysName.current) {
        autoSaveProcessSysName.current = null;
      }

      if (isTemplatesPage) {
        history.replace('/templates');
        NotificationManager.success(t('templates.notifications.created'));
      } else {
        history.replace('/template-processes');
        NotificationManager.success(getSuccessAlert(), `${t('customProcesses.notifications.success')}!`);
      }
      onClose();
      onFormSubmit();
      clearData();
      setNeedResetProcessForm(true);
    } catch (error) {
      NotificationManager.error(t(error?.message), `${t('customProcesses.notifications.error')}!`);
      throw error;
    } finally {
      setIsProcessPublishing(false);
      dispatch(getAvailableTemplateProcessesListAction());
      dispatch(setHoldingTranslationsLoaded(false));
      getHoldingTranslations(dispatch);
    }
  };

  const onError = (event): void => {
    console.log('Error / error: ', event);
    handleCheck();
  };

  const handleDialogSubmit = async (event) => {
    event.preventDefault();
    clearErrors();

    if (Object.keys(tariffLimits).includes(TARIFF_LIMITS_KEY) && !(initialValues && publishedVersionValues?.published)) {
      setIsProcessPublishing(() => true);
      try {
        const allowedPublishedCustomProcesses = +tariffLimits[TARIFF_LIMITS_KEY];
        const publishedCustomProcessesCount = await getPublishedProcessesCount();
        if (publishedCustomProcessesCount >= allowedPublishedCustomProcesses) {
          handlePublishedProcessesLimitDialogOpen();
          return;
        }
      } catch (error) {
        NotificationManager.error(t('errors.somethingIsWrong'), `${t('customProcesses.notifications.error')}!`);
        return;
      } finally {
        setIsProcessPublishing(() => false);
      }
    }

    handleSubmit(onSubmit, onError)();
  };

  return {
    hasErrors,
    handleCheck,
    getBeatifiedData,
    handleDialogSubmit,
  };
};
