import React, { Suspense, lazy } from 'react';

import {
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Upgrade } from '@dar-dms/utils';
import { routesList } from 'router';

import { Spinner } from 'components';
import { useUsersRole, useBilling } from 'hooks';

import { RestrictedRoute } from './RestrictedRoute';
import { UPGRADE_REDIRECT_PATH } from './constants';

import UpgradePageImageEn from 'assets/images/upgrade-images/upgrade-page-image-en.svg'
import UpgradePageImageRu from 'assets/images/upgrade-images/upgrade-page-image-ru.svg'
import UpgradePageImageKz from 'assets/images/upgrade-images/upgrade-page-image-kz.svg'
import UpgradePageImageId from 'assets/images/upgrade-images/upgrade-page-image-id.svg'
import UpgradePageImageDe from 'assets/images/upgrade-images/upgrade-page-image-de.svg'

const UpgradePageImages = {
  'en': UpgradePageImageEn,
  'ru': UpgradePageImageRu,
  'kz': UpgradePageImageKz,
  'id': UpgradePageImageId,
  'de': UpgradePageImageDe,
}

const RequestsPage = lazy(() => import('pages/Requests'));
const ApprovalsPage = lazy(() => import('pages/Approvals'));
const MonitoringPage = lazy(() => import('pages/Monitoring'));
const SearchPage = lazy(() => import('pages/Search'));
const TaskPage = lazy(() => import('pages/Task'));
const ProcessRequestPage = lazy(() => import('pages/RequestPage'));
const AnalyticsPage = lazy(() => import('pages/Analytics'));
const SettingsPage = lazy(() => import('pages/Settings'));
const TemplateProcessesPage = lazy(() => import('pages/TemplateProcesses'));
const AdminPanelPage = lazy(() => import('pages/AdminPanel'));
const TemplatesPage = lazy(() => import('pages/Templates'));

export const MainRouter = () => {
  const { i18n, t } = useTranslation();
  const {
    isSearchAvailable,
    isTemplateProcessPageAvailable: hasTemplateProcessesAclAccess,
    isRegisterExportAvailable,
    isUserAdmin,
    isTemplatePageAvailable
  } = useUsersRole();
  const {
    requests,
    approvals,
    monitoring,
    task,
    process,
    analytics,
    search,
    settings,
    templateProcesses,
    templateProcessEdit,
    adminPanel,
    templates,
    templateEdit
  } = routesList(t);

  const location = useLocation();
  const {
    hasModuleAccess,
    hasTemplateProcessesAccess: hasTemplateProcessesSubscriptionAccess
  } = useBilling();

  if (!hasModuleAccess && location.pathname !== UPGRADE_REDIRECT_PATH) {
    return <Redirect to={UPGRADE_REDIRECT_PATH}/>;
  }

  return (
    <Suspense fallback={<Spinner/>}>
      <Switch>
        <Route
          exact
          path={UPGRADE_REDIRECT_PATH}
          component={(props) =>
            <Upgrade
              {...props}
              image={<img src={UpgradePageImages[i18n.language] || UpgradePageImageEn}/>}
              heading={t('upgradePage.heading')}
              description={t('upgradePage.description')}
            />}
        />

        <Route path="/" exact>
          <Redirect to={approvals.path}/>
        </Route>

        <Route path={task.url} exact>
          <Redirect to={requests.path}/>
        </Route>

        <Route path={process.url} exact>
          <Redirect to={requests.path}/>
        </Route>

        <Route path={approvals.path} exact>
          <Redirect to={`${approvals.path + approvals.submenu[0].path}`}/>
        </Route>

        <Route path={monitoring.path} exact>
          <Redirect to={`${monitoring.path + monitoring.submenu[0].path}`}/>
        </Route>

        <Route path={requests.path} component={RequestsPage}/>

        <Route path={approvals.path} component={ApprovalsPage}/>

        <Route path={monitoring.path} component={MonitoringPage}/>

        <Route path={settings.path} component={SettingsPage}/>

        <RestrictedRoute
          path={adminPanel.path}
          component={AdminPanelPage}
          hasAccess={isUserAdmin}
        />

        <RestrictedRoute
          path={templateEdit.path}
          component={TemplatesPage}
          hasAccess={isTemplatePageAvailable}
        />

        <RestrictedRoute
          path={templates.path}
          component={TemplatesPage}
          hasAccess={isTemplatePageAvailable}
        />

        <RestrictedRoute
          path={templateProcessEdit.path}
          component={TemplateProcessesPage}
          redirectPath={
            hasTemplateProcessesSubscriptionAccess
            ?'/approvals/active'
            : UPGRADE_REDIRECT_PATH
          }
          hasAccess={hasTemplateProcessesAclAccess && hasTemplateProcessesSubscriptionAccess}
        />

        <RestrictedRoute
          path={templateProcesses.path}
          component={TemplateProcessesPage}
          redirectPath={
            hasTemplateProcessesSubscriptionAccess
            ?'/approvals/active'
            : UPGRADE_REDIRECT_PATH
          }
          hasAccess={hasTemplateProcessesAclAccess && hasTemplateProcessesSubscriptionAccess}
        />

        {isSearchAvailable && (
          <Route path={search.path} component={SearchPage}/>
        )}

        <Route path={task.path} component={TaskPage}/>

        <Route path={process.path} component={ProcessRequestPage}/>

        {isRegisterExportAvailable && (
          <Route path={analytics.path} component={AnalyticsPage}/>
        )}

        <Redirect to={approvals.path}/>
      </Switch>
    </Suspense>
  );
};
