import React, { memo, ReactElement } from 'react';
import { Controller } from 'react-hook-form';
import { FormControl } from '@mui/material';

import { SelectLegalEntity } from 'components';

import useCompanySelect from './useCompanySelect';

type CompanySelectProps = {
  name: string;
  hint: string;
  innerFields: unknown[];
  rules: any;
}

export const CompanySelect = memo(({
  hint,
  name,
  innerFields,
  rules
}: CompanySelectProps): ReactElement => {
  const {
    value,
    control,
    error,
    handleChange,
  } = useCompanySelect(name, innerFields);

  return (
    <FormControl fullWidth>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={() => (
          <SelectLegalEntity
            fixSelectedValueWithoutId
            name={name}
            label={hint}
            value={value?.shortName}
            error={error}
            onSelect={handleChange}
          />
        )}
      />
    </FormControl>
  );
});
