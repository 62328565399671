import React, { useEffect } from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ErrorMessage, ErrorPage, Spinner, TaskDetails } from 'components';
import { routesList } from 'router';
import { ContentLayout } from 'layouts';

import {
  getTaskById,
  setInitialTab,
  useSubmitSnackbarState,
  useTaskState,
} from 'store/requests';
import { getProfileCompanyDataFromLocalStorage } from 'utils/user';
import { changeCurrentWorkspace } from 'utils/workspace';

import NoAccessIcon from 'assets/images/icons/error-page-no-access-icon.svg';
import { HISTORY_TAB_NAME } from 'components/TaskDetails/components/Tabs/FormTab/constants';

export const TaskPage = () => {
  const dispatch = useDispatch();
  const { id: paramId } = useParams<{ id?: string }>();
  const location = useLocation();
  const { t } = useTranslation();

  const { data: bpmTask, loading, error } = useTaskState();
  const { open: submitSnackbarOpen } = useSubmitSnackbarState();

  useEffect(() => {
    const { id: currentWorkspaceId } = getProfileCompanyDataFromLocalStorage();
    const taskWorkspaceId = queryString.parse(location.search)?.workspaceId || currentWorkspaceId;
    if (taskWorkspaceId !== currentWorkspaceId) {
      try {
        changeCurrentWorkspace(taskWorkspaceId);
      } catch(error) {
        console.log('Error changing workspace');
      }
    } else {
      if (paramId) {
        dispatch(setInitialTab(HISTORY_TAB_NAME));
        dispatch(getTaskById(paramId));
      }
    }
  }, [paramId]);

  if (loading) {
    return <Spinner />;
  }

  if (error?.errorCode === -1) {
    return <ErrorMessage text={t('errors.somethingIsWrong')} />;
  }

  if (error?.errorCode === 404){
    return <ErrorPage
    icon={NoAccessIcon}
    title={t('errorPage.noAccess.title')}
    message={""}
    notFullScreen
  />
  }

  if (!submitSnackbarOpen && bpmTask) {
    return (<ContentLayout title={''} zIndexContent={101}>
        <TaskDetails />
      </ContentLayout>);
  }

  if (submitSnackbarOpen) {
    return (
      <Redirect
        to={`${
          routesList(t).approvals.path + routesList(t).approvals.submenu[0].path
        }`}
      />
    );
  }

  return <Spinner />;
};

export default TaskPage;
