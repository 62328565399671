import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications'

import useStyles from './ConfirmationWithImageModal.useStyles';
import AlertIcon from 'assets/images/icons/alert_icon_orange.svg';

import { Spinner } from 'components/Spinner';
import { CloseButton } from 'components/CloseButton';

type Props = {
  close: () => void;
  title: string;
  description: string;
  action: () => Promise<void>
  onSuccessAction?: () => void
  okButtonText?: string;
  cancelButtonText?: string;
  additionalButtons?: {
    text: string;
    action: () => void
  }[]
};

export const ConfirmationWithImageModal = ({ close, title, action, onSuccessAction, description, okButtonText, cancelButtonText, additionalButtons = [] }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [loading, setLoading] = useState(false)

  const handleAction = async () => {
    setLoading(true)
    try {
      await action()
      onSuccessAction?.();
    } catch (error) {
      NotificationManager.error(t(error.message));
    } finally {
      setLoading(false)
    }

  }

  return (
    <Box className={classes.modalBody}>
      <div className={classes.modalHeader}>
       <CloseButton onClick={close} />
      </div>

      <div className={classes.modalContent}>
        {loading && <Spinner />}
        <img src={AlertIcon} alt={'Alert'}/>
        <Typography className={classes.title}>
          {title}
        </Typography>
        {description && <Typography className={classes.description}>
          {description}
        </Typography>}
      </div>

      <div className={classes.modalFooter}>
        <Button color="secondary" variant="contained" onClick={close} className={classes.modalButton}>
          {cancelButtonText ? cancelButtonText : t('comment_modal.button_cancel')}
        </Button>

        {additionalButtons.map(({ action, text }, index) => {
          return <Button key={index} color="secondary" variant="contained" onClick={action} className={classes.modalButton}>
          {text}
        </Button>
        })}

        <Button
          color="primary" variant="contained"
          className={classes.modalButton}
          onClick={handleAction}
        >
          {okButtonText ? okButtonText : t('buttons.button_submit')}
        </Button>
      </div>
    </Box>
  );
};


