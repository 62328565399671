import React from 'react';
import cn from 'classnames';
import {
  Button,
  DialogContent,
  Typography,
  DialogActions,
  Dialog,
  Box,
  ButtonBase,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import useStyles from './useStyles';
import CloseIcon from 'assets/images/icons/close-icon-new.svg';

type Props = {
  open: boolean;
  onClose: () => void;
  icon: string;
  title: string;
  message: string;
};

export const ErrorModal = ({
  open = false,
  onClose,
  icon,
  title,
  message
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handlePageRefresh = () => {
    window.location.reload();
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: 12
        }
      }}
    >
      <Box
        component="header"
        py={4}
        px={5}
        display="flex"
        justifyContent="flex-end"
        className={classes.header}
      >
        <ButtonBase onClick={onClose}>
          <img src={CloseIcon} alt="Close"/>
        </ButtonBase>
      </Box>
      <DialogContent>
        <Box className={classes.dialogContentWrapper}>
          <img src={icon} className={classes.icon} alt={""}/>
          <Typography className={classes.title}>{title}</Typography>
          <Typography className={classes.message}>{message}</Typography>
        </Box>

      </DialogContent>
      <DialogActions className={classes.footer}>
        <Button
          color="secondary"
          onClick={onClose}
          className={cn(classes.btn, classes.btnSecondary)}
        >
          {t('comment_modal.button_cancel')}
        </Button>
        <Button
          color="primary"
          onClick={handlePageRefresh}
          className={cn(classes.btn)}
        >
          {t('buttons.refresh_page')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
