import { getUserWorkspacesList, getWorkspaceToken } from 'api/requests';

const WORKSPACE_STORAGE_KEY = 'workspace';
const WORKSPACE_TOKEN_STORAGE_KEY = 'workspace_token';

export const changeCurrentWorkspace = async (workspaceId: string) => {
  const userWorkspaces = await getUserWorkspacesList();
  const workspaceData = userWorkspaces.find(workspace => workspace.id === workspaceId);
  const tokenResponse = await getWorkspaceToken(workspaceId);

  localStorage.setItem(WORKSPACE_TOKEN_STORAGE_KEY, tokenResponse?.token);
  localStorage.setItem(WORKSPACE_STORAGE_KEY, JSON.stringify(workspaceData));

  window.location.reload();
}
