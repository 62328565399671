import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  scrollbar: {
    width: 'calc(100% + 16px) !important',
    height: 'calc(100vh - 285px - 49px) !important',
    paddingRight: 12,
    marginTop: 4,
    marginLeft: -16,
  },
  tableFooterHint: {
    color: '#6D6E85',
  },
  list: {
    padding: 0,
  },
  emptyBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F7F8FC',
    flex: 1,
    alignSelf: 'center',
  },
  requestsBox: {
    flex: '1 0 auto',
    position: 'relative',
    display: 'flex',
    alignItems: 'end',
    flexDirection: 'column',
    padding: '0 24px 20px 24px',
  },
}));

export default useStyles;
