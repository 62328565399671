export const ERROR_MESSAGE_1C = "Couldn't send data to 1c. Please, try again";
export const ERROR_MESSAGE_DELETE_ACTION = "Couldn't delete your task. Please, try again";
export const ERROR_MESSAGE_URGENT_REQUEST =
  "Error! Couldn't create the process urgent. Please, try again";
export const ERROR_MESSAGE_LINKED_ENTITY =
  "Error! Couldn't create an instance of the linked entity. Please, try again";

export const URGENT_COMMENT = {
  STATE: 'Urgent',
  REASON: 'Reason',
  REASON_OFF: 'Reason of turn off urgent',
  USER: 'Changed by'
};

export const SAVE_ACTION = 'Save';

export const SAVE_TEMPLATE_ACTION = 'Save as template';
export const SAVE_TEMPLATE_AND_SUBMIT_FORM_ACTION = 'Save template and submit';
export const SAVE_TEMPLATE_ERROR_TEXT = 'Server error. Failed to save your template';

export const WITH_COMMENT_SUFFIX = 'with comment';
export const CLOSE_COMMENT_POPOVER_ACTION = 'Close action comment creation popover';
export const ADD_COMMENT_FROM_POPOVER_ACTION = 'Add comment to action';
export const ADD_COMMENT_POPOVER_ACTION_ERROR_TEXT = 'Server error. Failed to add your comment';

export const OPEN_STOP_COMMENT_POPOVER = 'Stop';
export const STOP_TASKS_ACTION = 'Stop - submit';
export const STOP_TASKS_ACTION_ERROR_TEXT =
  'Server error. Failed to stop tasks.';

export const JOB_OFFER_V2_SYS_NAME = 'JO-3_ver2';
export const JOB_OFFER_V2_PDF_FILE_CREATING_PAGE = 7;
export const JOB_OFFER_PDF_FILE_SYS_NAME = 'file';

export const PAYMENTS_V1_SYS_NAME = 'P1-payment-new-ver-1';
export const PROCESS_FORMATION_STEP = 1;

export const PROCUREMENT_SYS_NAME = 'procurement_v1';
export const PREBOARDING_SYS_NAME = 'preboarding';
export const ADAPTATION_SYS_NAME = 'onboarding_adaptation';

export const VPN_OUTSTAFF_VALUE = 'Outstaff (Ограниченный)';
export const AD_USER_FIELD_NAME = 'adUserSelect';
export const AD_USER_OUTSTAFF_VALUE = 'Outstaff';

export const TIME_OFF_SYS_NAME = 'time_off';

export const APPROVE_ASSIGN = 'Approve & Assign';
export const REASSIGN = 'Reassign';

export const TAKE_ON_A_TASK = 'Take on a task';
export const TAKE_ON_A_TASK_ERROR_TEXT =
  'Server error. Failed to take on this tasks.';

export const SELECT_EMPLOYEE_POPOVER_ACTION = 'Select employee to action';
export const CLOSE_SELECT_EMPLOYEE_POPOVER_ACTION = 'Close action select employee popover';

export const SUBMIT_AND_CREATE_ANOTHER_ACTION = 'Submit and create another';
export const GENERATE_PDF_ACTION = 'Generate task pdf';

export const CLOSE_MODAL_ACTION = 'Close modal';

export const SAVE_INSTANCE_ACTION = 'Save instance';

export const REMOVE_INSTANCE_ACTION = 'Remove instance';
export const INSTANCE_DELETION_ERROR = 'Server error. Failed to delete this instance.';
