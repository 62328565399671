import React, { memo, useContext, useState, useMemo, useCallback, useEffect } from 'react';
import { Box } from '@mui/material';
import { showDarkOverlay } from 'store/requests';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ProcessContext from 'contexts/ProcessContext';

import { Spinner, ErrorMessage } from 'components';
import { GroupsMenu } from './GroupsMenu';
import { Templates } from './Templates';
import { ProcessesList } from './ProcessesList';
import { ProcessStartErrorDialog } from './ProcessStartErrorDialog';
import useStyles from './useStyles';

import { ProcessContextType, ProcessGroupType, UserType } from 'types';
import { useUserProfile } from 'hooks';

import TemplatesIcon from 'assets/images/icons/templates-icon.svg';
import { useHoldingTranslationsLoadedState } from '../../../store/main';

export const NewRequest = memo(() => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { companyId } = useUserProfile();
  const holdingTranslationsLoaded = useHoldingTranslationsLoadedState();

  const { groups, loading, error }: Partial<ProcessContextType> = useContext(ProcessContext);

  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [isErrorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);
  const [errorDialogMessage, setErrorDialogMessage] = useState<string>('');
  const [errorDialogButtonVisibility, setErrorDialogButtonVisibility] = useState<boolean>(false);
  const [errorDialogUsers, setErrorDialogUsers] = useState<{user: UserType, title: string}[]>([]);

  const groupsWithProcesses = useMemo(() =>
      groups
        .filter(({ processes }: Partial<ProcessGroupType>): boolean => !!processes?.length)
        .map(group => ({...group, name: t(`groups.${companyId}_${group.sysName}.name`, {defaultValue: t(`groups.${group.sysName}.name`, {defaultValue: group.name })})}))
        .sort((a, b) => a.position > b.position ? 1 : -1)
    , [groups, holdingTranslationsLoaded]);

  const allGroups = useMemo(() =>
      groupsWithProcesses ?
        [
          ...groupsWithProcesses,
          {
            name: t('Processes.new_request_templates'),
            description: '',
            iconPath: TemplatesIcon,
            sysName: 'tpl',
            processes: []
          },
        ] :
        [{
          name: t('Processes.new_request_templates'),
          description: '',
          iconPath: TemplatesIcon,
          sysName: 'tpl',
          processes: []
        }],
    [groupsWithProcesses]);

  const openProcessStartErrorDialog = useCallback(({ message, isAdmin, admins}: {message: string, isAdmin: boolean, admins: {user: UserType, title: string}[]}): void => {
    dispatch(showDarkOverlay(true));
    setErrorDialogOpen(true);
    setErrorDialogMessage(message);
    setErrorDialogButtonVisibility(isAdmin);
    setErrorDialogUsers(admins);
  }, [setErrorDialogOpen]);

  const closeProcessStartErrorDialog = useCallback(() => {
    dispatch(showDarkOverlay(false));
    setErrorDialogOpen(false);
  }, [setErrorDialogOpen]);

  useEffect(() => {
    const maxIndex = allGroups?.length ? allGroups?.length - 1 : 0;
    if (selectedIndex > maxIndex) {
      setSelectedIndex(maxIndex);
    }
  }, [groupsWithProcesses, selectedIndex])

  if (error) {
    return <ErrorMessage text={'ERROR'}/>;
  }

  return (
    <Box component="section" className={classes.root}>
      {loading && <Spinner absolute/>}

      <Box component="aside" bgcolor="grey.200" py={9.25} className={classes.groupsWrapper}>
        {groups && (
          <GroupsMenu
            groups={allGroups}
            selected={selectedIndex}
            handleClick={setSelectedIndex}
          />
        )}
      </Box>

      <Box
        component="article"
        px={20}
        py={8}
        overflow="auto"
        position="relative"
      >
        {selectedIndex === groupsWithProcesses?.length ? (
          <Templates />
        ) : (
           <ProcessesList
             data={allGroups[Math.min(selectedIndex, allGroups?.length ? allGroups?.length - 1 : 0)]}
             openRequestStartErrorDialog={openProcessStartErrorDialog}
           />
         )}
      </Box>

      <ProcessStartErrorDialog
        open={isErrorDialogOpen}
        onClose={closeProcessStartErrorDialog}
        message={errorDialogMessage}
        showDirectManagerButton={errorDialogButtonVisibility}
        users={errorDialogUsers}
      />
    </Box>
  );
});
