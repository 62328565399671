import i18next from "i18next";

export function forceTranslate(key: string): string | null {
  const supportedLngs = i18next.options.supportedLngs || [];
  const {language: currentLanguage} = i18next;

  let forcedTranslatedValue = null;

  const languages = supportedLngs.filter(lang => currentLanguage !== lang && lang !== "cimode");

  for (const lng of languages) {
    const translatedValue = i18next.t(key, { lng });
    if(key !== translatedValue){
      forcedTranslatedValue = translatedValue
      break
    }
  }

  return forcedTranslatedValue
}
