import { useFiltersState } from 'store/search/search.selector';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { useLocation } from 'react-router-dom';

import { useRequestParams } from 'hooks';

import { ERROR_MESSAGES, DATE_VALIDATE } from '../constants';
import {
  getApprovalsAction,
  getWatchListAction,
  setReviewed,
  useApprovalsListState,
} from 'store/approvals';

type Props = {
  dateFrom: Date;
  dateTo: Date;
  reviewed?: boolean;
  previousReviewed?: boolean;
  completed: boolean;
  sendDateErrorCode: (val: string) => void;
};

const useApprovalsFilter = ({
  dateFrom,
  dateTo,
  reviewed = false,
  previousReviewed = false,
  completed,
  sendDateErrorCode,
}: Props) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const filtersState = useFiltersState();

  const {
    currentPage,
    pageSize,
    needRefresh,
    data
  } = useApprovalsListState();

  const { getRequestParameters, urlParams } = useRequestParams({
    completed: false,
    currentPage,
    pageSize,
    type: 'approvals',
  });

  const prevPage = useRef(currentPage);
  const prevPageSize = useRef(pageSize);
  const prevUrlParams = useRef(urlParams);

  useEffect(() => {
    if (currentPage !== prevPage.current) {
      prevPage.current = currentPage;
    }
  }, [currentPage]);

  useEffect(() => {
    if (pageSize !== prevPageSize.current) {
      prevPageSize.current = pageSize;
    }
  }, [pageSize]);

  useEffect(() => {
    if (!isEqual(prevUrlParams.current, urlParams)) {
      prevUrlParams.current = urlParams;
    }
  }, [urlParams]);

  const isDateValidationError = useCallback(() => {
    if (dateTo > DATE_VALIDATE) {
      sendDateErrorCode(ERROR_MESSAGES.DATE_TO_ERROR);
      return true;
    }
    if (dateFrom > DATE_VALIDATE) {
      sendDateErrorCode(ERROR_MESSAGES.DATE_FROM_ERROR);
      return true;
    }

    if (dateFrom > dateTo) {
      sendDateErrorCode(ERROR_MESSAGES.DATE_VALIDATION_ERROR);
      return true;
    }

    return false;
  }, [sendDateErrorCode, dateFrom, dateTo]);

  const getAllRequests = useCallback(
    async () => {
      if (isDateValidationError()) {
        return;
      }

      if (
        data &&
        reviewed === previousReviewed &&
        currentPage === prevPage.current &&
        pageSize === prevPageSize.current &&
        !isEqual(prevUrlParams.current, urlParams) &&
        !needRefresh
      ) {
        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [needRefresh, reviewed, urlParams]
  );

  useEffect(() => {
    dispatch(setReviewed(reviewed));
  }, [reviewed])

  useEffect(() => {
    const copyFilterState = { ...filtersState };

    if (reviewed) { delete copyFilterState.urgent };
    delete copyFilterState.pagination;
    const isMonitoringSection = () => (pathname.match(/\/monitoring/gm) ?? []).length > 0;
    const params = {params: {
      pagination: {
        size: pageSize,
        page: currentPage - 1,
        sort: 'taskStartDate,DESC'
      },
      ...copyFilterState,
      archive: false,
      completed, 
      taskCompleted: completed
    }};
    if (completed) params.params.pending = false; 
    if (isMonitoringSection()) {
      params['pending'] = !completed;
      dispatch(getWatchListAction(params));
    } else {
      dispatch(getApprovalsAction(params));
    }
  }, [dispatch, pageSize, currentPage, reviewed, filtersState, completed]);

  return {
    getAllRequests,
  };
};

export default useApprovalsFilter;
