import React, { useState, useMemo, ReactElement, useContext } from 'react';
import {
  FormControl,
  TextField,
  InputAdornment,
  Box,
  Button,
  ClickAwayListener
} from "@mui/material";
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import ProcessContext from 'contexts/ProcessContext';
import { ProcessContextType, ProcessGroupType } from 'types';

import {GroupSelectCard} from './GroupSelectCard';
import useGroupSelectStyles from './GroupSelect.useStyles';

import SearchIconThin from 'assets/images/icons/search-icon-thin.svg';
import ChevronDown from 'assets/images/icons/chevron_down_regular.svg'
import { useUserProfile } from '../../../../hooks';

const Icon = ({className}) => {
  return <img className={className} src={ChevronDown} alt=""/>
}

interface GroupSelectProps {
  label: string;
  selectedGroupSysName: string;
  handleGroupSelect: (groupSysName: string) => void;
  disabled?: boolean;
  onlyDefaultGroups?: boolean;
}

export const GroupSelect = ({
  label = '',
  selectedGroupSysName,
  handleGroupSelect,
  disabled,
  onlyDefaultGroups
}: GroupSelectProps): ReactElement => {
  const { t } = useTranslation();
  const { companyId } = useUserProfile();
  const classes = useGroupSelectStyles();
  const { groups: groupsList }: Partial<ProcessContextType> = useContext(ProcessContext);

  const groups = useMemo(() => {
    if (onlyDefaultGroups){
      return groupsList.filter(g => g.defaultGroup)
    }

    return groupsList
  }, [groupsList, onlyDefaultGroups])

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');

  const handleMenuClose = (): void => {
    setIsDropdownOpen(false);
  }
  const handleMenuOpen = (): void => {
    if (disabled) return;
    setIsDropdownOpen(!isDropdownOpen);
  }

  const handleSelectOption = (selectedOption): void => {
    handleGroupSelect(selectedOption.sysName);
    setSearchText('');
    handleMenuClose();
  }

  const localizedGroups = useMemo(() => {
    return groups?.map(group => ({
      ...group,
      localizedName: t(`groups.${group.sysName}.name`, { defaultValue: t(`groups.${companyId}_${group.sysName}.name`, { defaultValue: group.name }) }),
      localizedDescription: t(`groups.${group.sysName}.description`, { defaultValue: t(`groups.${companyId}_${group.sysName}.description`, { defaultValue: group.description }) }),
    })) || [];
  }, [groups, companyId]);

  const displayOptions = useMemo(() =>
      localizedGroups?.filter(group =>
        [group.name, group.localizedName, group.description, group.localizedDescription]
          .some(v => v?.includes(searchText))
      )
  , [localizedGroups, searchText]);

  const selectedGroup = useMemo(() =>
      localizedGroups?.find(group => group.sysName === selectedGroupSysName)
  , [selectedGroupSysName, localizedGroups])

  return (
    <ClickAwayListener onClickAway={handleMenuClose}>
      <FormControl fullWidth>
        <label className={classes.label}>{label}</label>

        <Button
          variant="contained"
          color="secondary"
          className={classes.groupsDropdown}
          onClick={handleMenuOpen}
          disabled={disabled}
        >
          <Box display="flex" alignItems="center">
            <Box ml={2}>
              <Box display="flex" alignItems="center">
                <GroupSelectCard group={selectedGroup}/>
              </Box>
            </Box>
          </Box>

          {!disabled && <Icon className={cn(classes.groupDropdownIcon, {[classes.groupDropdownActive]: isDropdownOpen})} />}
        </Button>

        <Box className={cn(classes.groupDropdownMenu, {[classes.groupDropdownMenuActive]: isDropdownOpen})}>
          <Box className={classes.groupDropdownMenuHeader}>
            <Box className={classes.groupDropdownMenuSearchWrapper}>
              <TextField
                autoFocus
                fullWidth
                size="small"
                value={searchText}
                placeholder={t('form_components.select.search_placeholder')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={SearchIconThin} className={classes.groupDropdownIcon} />
                    </InputAdornment>
                  )
                }}
                className={classes.groupDropdownMenuSearchTextField}
                onChange={(event) => {
                  setSearchText(event.target.value)
                }}
              />
            </Box>
          </Box>

          <Box className={classes.groupDropdownMenuContent}>
            {displayOptions.map((option: ProcessGroupType) => (
              <Box
                key={option.sysName}
                className={cn(classes.option, {
                  [classes.optionSelected]: option.sysName === selectedGroupSysName,
                })}
                onClick={() => handleSelectOption(option)}
              >
                <GroupSelectCard group={option}/>
              </Box>
            ))}
          </Box>
        </Box>
      </FormControl>
    </ClickAwayListener>
  )
}
