import React, { ReactElement, SyntheticEvent, useMemo, useState } from 'react';
import { Box, Tabs, Tab, Button, FormControl, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { TabPanel, TabContext } from '@mui/lab';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { AppTextField } from 'components';

import { GroupSelect } from '../GroupSelect';
import { TemplateIconSelectButton } from '../TemplateIconSelectButton';
import { FormProcess, Language } from '../../TemplateProcesses.types';
import { LANGUAGE_TRANSLATION, MAX_NAME_LENGTH, MAX_DESCRIPTION_LENGTH } from '../../TemplateProcesses.constants';

import useStyles from './TemplateProcessSettingsForm.useStyles';
import EngLanguageIcon from 'assets/images/icons/country-eng.svg';
import KazLanguageIcon from 'assets/images/icons/country-kaz.svg';
import RusLanguageIcon from 'assets/images/icons/country-rus.svg';
import IndLanguageIcon from 'assets/images/icons/country-ind.svg';
import GerLanguageIcon from 'assets/images/icons/country-ger.svg';
import InfoIcon from 'assets/images/icons/template-processes-preview-info.svg';
import WarningIcon from 'assets/images/icons/warning-sign.svg';
import { useRouteMatch } from 'react-router-dom';

interface TemplateProcessesSettingsFormProps {
  isReadonlyView: boolean;
  validateIcon?: boolean;
  onValuesChange: () => void;
  register: any; // TODO fix types
  fields: FormProcess[];
  update: any;
  append: any;
  remove: any;
  errors: any;
  formErrors?: { name: string[]; description: string[]; icon: boolean };
  processIcon: string;
  setProcessIcon: any;
  processGroup: string;
  setProcessGroup: any;
  languageTabsWithErrors: string[];
  availableLocales: string[];
  languageTabs: string[];
  currentLanguageTab: string;
  setCurrentLanguageTab: any;
  setLanguageTabs: any;
  selectedProcessLanguage: string;
  setCurrentLanguage?: any;
}

interface LanguageItem {
  icon: string;
  label: string;
  value: Language;
}

const LANGUAGES: LanguageItem[] = [
  {
    icon: RusLanguageIcon,
    value: Language.RUSSIAN,
    label: LANGUAGE_TRANSLATION[Language.RUSSIAN],
  },
  {
    icon: EngLanguageIcon,
    value: Language.ENGLISH,
    label: LANGUAGE_TRANSLATION[Language.ENGLISH],
  },
  {
    icon: KazLanguageIcon,
    value: Language.KAZAKH,
    label: LANGUAGE_TRANSLATION[Language.KAZAKH],
  },
  {
    icon: IndLanguageIcon,
    value: Language.BAHASA,
    label: LANGUAGE_TRANSLATION[Language.BAHASA],
  },
  {
    icon: GerLanguageIcon,
    value: Language.GERMAN,
    label: LANGUAGE_TRANSLATION[Language.GERMAN],
  },
];

const RequiredIcon = () => {
  const classes = useStyles();
  return <span className={classes.requiredIcon}>*</span>;
};

export const TemplateProcessSettingsForm = ({
  isReadonlyView = false,
  validateIcon = true,
  onValuesChange,
  register,
  fields,
  update,
  append,
  remove,
  errors,
  formErrors,
  processIcon,
  setProcessIcon,
  processGroup,
  setProcessGroup,
  languageTabs,
  availableLocales,
  setLanguageTabs,
  languageTabsWithErrors,
  currentLanguageTab,
  setCurrentLanguageTab,
  setCurrentLanguage,
  selectedProcessLanguage = Language.RUSSIAN,
}: TemplateProcessesSettingsFormProps): ReactElement => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const routeMatch = useRouteMatch();

  const isTemplatePage = useMemo(() => {
    return routeMatch.path === '/templates/:id';
  }, [routeMatch]);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const availableLanguages = useMemo(
    (): LanguageItem[] => LANGUAGES.filter(({ value }) => !languageTabs.includes(value) && availableLocales.includes(value)),
    [languageTabs, availableLocales]
  );

  const handleMenuClose = (): void => {
    setAnchorEl(null);
  };

  const handleLanguageSelect = (selectedLanguage: Language): void => {
    handleMenuClose();

    setTimeout(() => {
      append({
        language: selectedLanguage,
        processName: '',
        description: '',
      });

      setLanguageTabs((tabs) => [...tabs, selectedLanguage]);
      setCurrentLanguageTab(selectedLanguage);
    });
  };

  const isMenuOpen = useMemo((): boolean => Boolean(anchorEl), [anchorEl]);

  const handleTabChange = (event: SyntheticEvent, selectedLanguage: Language): void => {
    setCurrentLanguageTab(selectedLanguage);
  };

  const handleTabDelete = (): void => {
    const processFields = (fields as unknown) as FormProcess[];

    const currentLanguageIndex = languageTabs.findIndex((languageTab) => languageTab === currentLanguageTab);
    const currentFieldIndex = processFields.findIndex(({ language }: FormProcess) => language === currentLanguageTab);

    setCurrentLanguageTab(languageTabs[currentLanguageIndex - 1]);
    setLanguageTabs((tabs) => tabs.filter((tab) => tab !== currentLanguageTab));
    setCurrentLanguage(languageTabs[currentLanguageIndex - 1]);
    remove(currentFieldIndex);
  };

  const currentLanguageProcessInfo = useMemo(() => fields.find(({ language }) => language === selectedProcessLanguage), [
    fields,
    selectedProcessLanguage,
  ]);

  if (isReadonlyView) {
    return (
      <Box className={classes.readonlyProcessInfoWrapper}>
        <Box className={classes.readonlyProcessInfoTitleWrapper}>
          <img src={InfoIcon} className={classes.readonlyProcessInfoTitleIcon} />
          <h2 className={classes.readonlyProcessInfoTitle}>{t('customProcesses.readonlyView.processInfo')}</h2>
        </Box>

        <Box className={classes.readonlyProcessInfoRow}>
          <Box className={classes.readonlyProcessInfoTitleColumn}>{t('customProcesses.creationPage.fields.name')}</Box>
          <Box className={classes.readonlyProcessInfoContentColumn}>{currentLanguageProcessInfo?.processName}</Box>
        </Box>

        <Box className={classes.readonlyProcessInfoRow}>
          <Box className={classes.readonlyProcessInfoTitleColumn}>{t('customProcesses.creationPage.fields.description')}</Box>
          <Box className={classes.readonlyProcessInfoContentColumn}>{currentLanguageProcessInfo?.description}</Box>
        </Box>
      </Box>
    );
  }

  return (
    <TabContext value={currentLanguageTab}>
      <Box width="100%" pt={7.5}>
        <GroupSelect
          label={t('customProcesses.creationPage.fields.category')}
          selectedGroupSysName={processGroup}
          handleGroupSelect={(groupSysName) => {
            setProcessGroup(groupSysName);
            onValuesChange();
          }}
          onlyDefaultGroups={isTemplatePage}
        />
      </Box>

      <Box display="flex" alignItems="center" pt={7.5}>
        <Tabs
          variant="scrollable"
          scrollButtons={false}
          value={currentLanguageTab}
          className={classes.dialogContentFormTabs}
          onChange={handleTabChange}
        >
          {languageTabs.map((languageTab) => (
            <Tab
              key={languageTab}
              value={languageTab}
              label={LANGUAGE_TRANSLATION[languageTab]}
              className={cn(classes.dialogContentFormTabsTab, {
                [classes.dialogContentFormTabsTabWithError]: languageTabsWithErrors.includes(languageTab),
              })}
            />
          ))}
        </Tabs>

        {Boolean(availableLanguages?.length) && (
          <>
            <Button
              variant="text"
              startIcon={<AddIcon className={classes.dialogContentLanguageButtonIcon} />}
              className={cn({ [classes.dialogContentLanguageButton]: isMenuOpen })}
              onClick={handleMenuClick}
            >
              {t('customProcesses.creationPage.buttons.addLanguage')}
            </Button>

            <Menu
              anchorEl={anchorEl}
              disableAutoFocusItem
              open={Boolean(anchorEl)}
              className={classes.dialogContentLanguageMenu}
              MenuListProps={{ style: { padding: 0 } }}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {availableLanguages.map(({ icon, label, value }: LanguageItem) => (
                <MenuItem key={label} className={classes.dialogContentLanguageMenuItem} onClick={() => handleLanguageSelect(value)}>
                  <img src={icon} className={classes.dialogContentLanguageMenuIcon} />
                  <Box ml={2}>
                    <Typography>{label}</Typography>
                  </Box>
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
      </Box>

      {fields.map((field: any, index: number) => {
        const { processName, description } = errors.formProcess?.[index] || {};

        return (
          <TabPanel value={field.language} style={{ width: '100%', padding: '12px 0' }}>
            <Box display="flex" flexDirection="column" width="100%" pt={3}>
              <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                <TemplateIconSelectButton
                  activeIcon={processIcon}
                  handleIconSelect={(processIcon: string) => {
                    setProcessIcon(processIcon);
                    onValuesChange();
                  }}
                  error={!processIcon && validateIcon}
                />

                <Box ml={6} width="calc(100% - 82px)">
                  <FormControl fullWidth>
                    <AppTextField
                      className={classes.input}
                      {...register(`formProcess[${index}].processName`, {
                        required: t('form_components.required_errors.default') as string,
                        validate: (value) => !!value.trim() || t('form_components.required_errors.default'),
                      })}
                      rows={1}
                      size="medium"
                      label={
                        <>
                          {t('customProcesses.creationPage.fields.name')} <RequiredIcon />
                        </>
                      }
                      placeholder={t('customProcesses.creationPage.fields.namePlaceholder')}
                      value={field.processName}
                      defaultValue={field.processName}
                      error={
                        processName?.message ||
                        field?.processName?.length > MAX_NAME_LENGTH ||
                        (!processIcon && validateIcon) ||
                        formErrors?.name?.length > 0
                      }
                      helperText={
                        <div className={classes.processNameErrorsWrapper}>
                          <div>
                            {processName?.message || formErrors?.name?.length > 0 ? (
                              <div className={classes.helperTextError}>
                                <img src={WarningIcon} alt="warning" />
                                {processName?.message || t('form_components.required_errors.default')}
                              </div>
                            ) : null}

                            {field?.processName?.length > MAX_NAME_LENGTH ? (
                              <div className={classes.helperTextError}>
                                <img src={WarningIcon} alt="warning" />
                                {t('customProcesses.creationPage.processFormErrors.valueTooLong')}
                              </div>
                            ) : null}

                            {!processIcon && validateIcon ? (
                              <div className={classes.helperTextError}>
                                <img src={WarningIcon} alt="warning" />
                                {t('customProcesses.creationPage.processFormErrors.noIcon')}
                              </div>
                            ) : null}
                          </div>

                          <div className={classes.helperTextError}>
                            {field?.processName?.length > MAX_NAME_LENGTH ? `${field?.processName?.length}/${MAX_NAME_LENGTH}` : null}
                          </div>
                        </div>
                      }
                      onChange={(event) => {
                        update(index, {
                          ...field,
                          processName: event.target.value,
                        });
                      }}
                    />
                  </FormControl>
                </Box>
              </Box>

              <Box pt={4}>
                <FormControl fullWidth>
                  <AppTextField
                    className={classes.input}
                    {...register(`formProcess[${index}].description`, {
                      required: t('form_components.required_errors.default') as string,
                      validate: (value) => !!value.trim() || t('form_components.required_errors.default'),
                    })}
                    multiline
                    rows={4}
                    rowsMax={4}
                    size="medium"
                    label={
                      <>
                        {t('customProcesses.creationPage.fields.description')} <RequiredIcon />
                      </>
                    }
                    placeholder={t('customProcesses.creationPage.fields.descriptionPlaceholder')}
                    value={field?.description}
                    defaultValue={field?.description}
                    error={
                      description?.message || field?.description?.length > MAX_DESCRIPTION_LENGTH || formErrors?.description?.length > 0
                    }
                    helperText={
                      <div className={classes.processNameErrorsWrapper}>
                        <div>
                          {description?.message ||
                            (formErrors?.description?.length > 0 && (
                              <div className={classes.helperTextError}>
                                <img src={WarningIcon} alt="warning" />
                                {description?.message || t('form_components.required_errors.default')}
                              </div>
                            ))}

                          {field?.description?.length > MAX_DESCRIPTION_LENGTH ? (
                            <div className={classes.helperTextError}>
                              <img src={WarningIcon} alt="warning" />
                              {t('customProcesses.creationPage.processFormErrors.valueTooLong')}
                            </div>
                          ) : null}
                        </div>

                        <div className={classes.helperTextError}>
                          {field?.description?.length > MAX_DESCRIPTION_LENGTH
                            ? `${field?.description?.length}/${MAX_DESCRIPTION_LENGTH}`
                            : null}
                        </div>
                      </div>
                    }
                    onChange={(event) => {
                      update(index, {
                        ...field,
                        description: event.target.value,
                      });
                    }}
                  />
                </FormControl>
              </Box>
            </Box>
          </TabPanel>
        );
      })}

      {languageTabs?.length > 1 && currentLanguageTab !== (i18n.language as Language) && (
        <Box alignSelf="center" display="flex" justifyContent="center">
          <Button
            variant="text"
            startIcon={<DeleteOutlineIcon className={classes.dialogContentLanguageButtonIcon} />}
            onClick={handleTabDelete}
          >
            {t('customProcesses.creationPage.buttons.removeLanguage')}
          </Button>
        </Box>
      )}
    </TabContext>
  );
};
