import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => ({
  title: {
    fontSize: 16,
    fontWeight: 500,
    color: '#465564'
  },
  subtitle: {
    fontSize: 14,
    color: '#8A96A1'
  },
  imageWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    height: 120,
    width: 120
  },
  imageWrapperImage: {
    height: 82,
    width: 82
  }
}));
