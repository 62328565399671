import isArray from 'lodash/isArray';

const attributeTypes = [
  'arrayEntityAttributes',
  'booleanAttributes',
  'dateAttributes',
  'doubleAttributes',
  'enumAttributes',
  'fileAttributes',
  'integerAttributes',
  'linkAttributes',
  'stringAttributes',
];

export const getAttributes = (instance) => {
  if (!instance) return [];

  let attributes = [];

  attributeTypes.forEach((attrType) => {
    attributes = [...attributes, ...instance[attrType]];
  });

  return attributes;
};

export const getStateAttributes = (instance, stateName) => {
  if (!instance) return [];

  const state = instance.businessStateDTOList.find(
    (state) => state.sysName === stateName
  );
  if (!state) {
    return [];
  }

  const attributes = getAttributes(instance);
  const stateAttributes = state.bsnParameters.map((param) => {
    return {
      ...attributes.find((attr) => attr.typeAttributeId === param.attributeId),
      ...param,
    };
  });

  return stateAttributes.sort((a, b) => a.state_order - b.state_order);
};

export const getDefaultValues = (instance) => {
  if (!instance) return {};

  const values = {};
  const attributes = getAttributes(instance);

  attributes.forEach(({ name, value }) => {
    values[name] = value;

    if (isArray(value)) {
      values[name] = value.join(',');
    }

    if (name === 'benefits' && value?.length) {
      values[name] = value.join('\n');
    }
  });

  return values;
};
