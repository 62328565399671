import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ spacing, palette }) => ({
  linkWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  linkWrapperReverse: {
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end'
  },
  taskDetailsVariantWrapper: {
    marginTop: spacing(-2)
  },

  link: {
    wordWrap: 'break-word'
  },
  linkText: {
    wordWrap: 'break-word',
    color: palette.primary[200],
    cursor: 'pointer'
  },
  linkWithIconText: {
    color: palette.grey[700]
  },

  counter: {
    color: palette.grey[700],
    marginRight: spacing(4)
  },

  icon: {
    height: 16,
    width: 16
  },

  button: {
    padding: 0,
    minWidth: 'auto',
    marginLeft: spacing(1)
  },
  buttonNoCursor: {
    cursor: 'default'
  },
  buttonReverse: {
    marginLeft: spacing(0),
    marginRight: spacing(1)
  }
}));

export default useStyles;
