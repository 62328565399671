import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, FormControl, IconButton, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import cn from 'classnames';
import moment from 'moment';

import { AppTextField } from 'components';
import {
  ProcessStepTag,
} from '../index';

import {
  AllProcessFieldsListItem,
  ConditionStep, FormField,
  Language, StepValidationResult,
} from '../../TemplateProcesses.types';

import useConditionFormStyles from './ConditionForm.useStyles';

import WarningIcon from 'assets/images/icons/warning-sign.svg';
import PlusIcon from 'assets/images/icons/tertiary-plus-sign.svg';
import EditIcon from 'assets/images/icons/tertiary-edit-icon.svg';
import { CONDITION_TYPES } from '../StepConditionModal/StepConditionModal.constants';
import { useUsersState } from 'store/users';
import InfoIcon from 'assets/images/icons/template-processes-preview-info.svg';
import ConditionsIcon from 'assets/images/icons/template-processes-conditions-info.svg';
import { useFormatConditionValue } from 'pages/TemplateProcesses/useFormatConditionValue';
import { MAX_NAME_LENGTH } from '../../TemplateProcesses.constants';
import { limitStringLength } from 'utils/user';

const LOCALES = {
  'en': 'en-GB',
  'kz': 'kk',
  'ru': 'ru',
  'id': 'id',
};

interface ProcessStepFormProps {
  isReadonlyView?: boolean;
  isEditDisabled?: boolean;
  currentLanguage: Language;
  languages: Language[];
  condition?: ConditionStep;
  formErrors: StepValidationResult[];
  allProcessStepsFields: AllProcessFieldsListItem[];
  handleDisplayedVersionToggle: () => void;
  handleConditionValuesChange: (newValue) => void;
  handleConditionModalOpen: () => void;
  isTemplatePage?: boolean;
}

export const ConditionForm = ({
  isReadonlyView = false,
  isEditDisabled = false,
  currentLanguage,
  condition,
  formErrors,
  allProcessStepsFields,
  handleDisplayedVersionToggle,
  handleConditionValuesChange,
  handleConditionModalOpen,
  isTemplatePage
}: ProcessStepFormProps): ReactElement => {
  const { t, i18n } = useTranslation();
  moment.locale(LOCALES[i18n.language] || i18n.language);
  const classes = useConditionFormStyles();
  const { users } = useUsersState();
  const { formatConditionValue, conditionValueFormatted } = useFormatConditionValue()

  const conditionSentenceTemplateKey = useMemo(() => {
    const field = allProcessStepsFields?.find(item => item?.field.id === condition?.condition?.field)?.field;
    if (field) {
      return CONDITION_TYPES[field.component]?.find(item => item.key === condition?.condition?.type)?.template;
    }
    return '';
  }, [condition, allProcessStepsFields]);

  const conditionFieldName = useMemo(() => {
    const field = allProcessStepsFields.find(item => item?.field.id === condition?.condition?.field)?.field;
    return field?.hint[currentLanguage] || '';
  }, [condition, allProcessStepsFields, currentLanguage]);

  const getFormattedConditionValue = useCallback(async () => {
    const field = allProcessStepsFields.find(item => item?.field.id === condition?.condition?.field)?.field;

    if(!field || !condition) return

    formatConditionValue({
        fieldType: field?.component,
        value: condition?.condition?.value,
        fieldParams: field.params || {}
      })
  }, [condition])

  const handleConditionNameChange = (value) => {
    const newConditionValue = {
      ...condition,
      stepName: {
        ...condition?.stepName,
        [currentLanguage]: value,
      },
    };
    handleConditionValuesChange(newConditionValue);
  };

  useEffect(() => {
    getFormattedConditionValue()
  }, [getFormattedConditionValue])

  if (isReadonlyView) {
    return <Box className={classes.processStepForm}>
      <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="flex-start">
          <ProcessStepTag size="small" stepOrder={condition?.previousStepOrder} isConditionStep/>
        </Box>
      </Box>

      <Box className={classes.readonlyProcessInfoWrapper}>
        <Box className={classes.readonlyProcessInfoTitleWrapper}>
          <img src={InfoIcon} className={classes.readonlyProcessInfoTitleIcon}/>
          <h2 className={classes.readonlyProcessInfoTitle}>
            {t('customProcesses.readonlyView.stepInfo')}
          </h2>
        </Box>

        <Box className={classes.readonlyProcessInfoRow}>
          <Box className={classes.readonlyProcessInfoTitleColumn}>
            {t('customProcesses.creationPage.fields.name')}
          </Box>
          <Box className={classes.readonlyProcessInfoContentColumn}>
            {condition?.stepName[currentLanguage]}
          </Box>
        </Box>
      </Box>

      <Box className={classes.readonlyProcessInfoWrapper}>
          <Box className={classes.readonlyProcessInfoTitleWrapper}>
            <img src={ConditionsIcon} className={classes.readonlyProcessInfoTitleIcon}/>
            <h2 className={classes.readonlyProcessInfoTitle}>
              {t('customProcesses.creationPage.conditionForm.sections.conditions')}
            </h2>
          </Box>

            <Box className={classes.readonlyProcessInfoRow}>
              <Box
                className={cn(classes.readonlyProcessInfoTitleColumn)}>
                {t('task_data_view.history_tab.conditionNamePrefix')}
              </Box>
              <Box className={classes.readonlyProcessInfoContentColumn}>
                <Trans
                  i18nKey={conditionSentenceTemplateKey}
                  values={{ field: conditionFieldName, value: conditionValueFormatted }}
                >
                  If <span>conditionFieldName</span> is less than <span>conditionValueFormatted</span>
                </Trans>
              </Box>
            </Box>
        </Box>
    </Box>;
  }

  const limitedByLengthConditionValue = useMemo(() => limitStringLength(conditionValueFormatted, 25), [conditionValueFormatted]);

  return (
    <Box className={classes.processStepForm}>
      {isEditDisabled && (<Box className={classes.processStepFormEditingDisabledIndicator}>
          <Typography component="p">
            <Trans i18nKey="customProcesses.creationPage.processForm.editingDisabledMessage">
              Редактирование недоступно, потому что есть <span onClick={handleDisplayedVersionToggle}>версия</span> с
              неопубликованными изменениями
            </Trans>
          </Typography>
        </Box>
      )}

      <Box width="100%" display="flex" alignItems="center" justifyContent="space-between" pb={3}>
        <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="flex-start">
          <ProcessStepTag size="small" stepOrder={condition?.previousStepOrder} isConditionStep/>
        </Box>
      </Box>

      <Box className={classes.processStepFormSection}>
        <Typography className={classes.processStepFormSectionTitle}>
          {t('customProcesses.creationPage.conditionForm.sections.general')}
        </Typography>

        <Box display="flex" flexDirection="column">
          <FormControl fullWidth>
            <AppTextField
              disabled={isEditDisabled}
              size="medium"
              className={cn(classes.stepNameField)}
              label={<span className={classes.requiredFieldTitle}>
              {t('customProcesses.creationPage.conditionForm.fields.name')}
            </span>}
              error={formErrors[condition?.previousStepOrder - 1]?.scriptName.includes(currentLanguage)}
              helperText={
                <>
                {
                  formErrors[condition?.previousStepOrder - 1]?.scriptName.includes(currentLanguage)
                ? <div className={classes.processStepFormErrorMessage}>
                  <img src={WarningIcon} alt="warning"/>
                  <span>{t('customProcesses.creationPage.processFormErrors.requiredField')}</span>
                </div>
                : null
                }

                  {condition?.stepName[currentLanguage]?.length > MAX_NAME_LENGTH
                   ? <div
                     className={cn(classes.processStepFormErrorMessage, classes.processStepFormErrorMessageTwoSides)}>
                     <div>
                       <img src={WarningIcon} alt="warning"/>
                       <span>{t('customProcesses.creationPage.processFormErrors.valueTooLong')}</span>
                     </div>

                     <div>
                       {condition?.stepName[currentLanguage]?.length || 0}/{MAX_NAME_LENGTH}
                     </div>
                   </div>
                   : null
                  }
                </>
              }
              placeholder={t('customProcesses.creationPage.conditionForm.fields.namePlaceholder')}
              value={condition?.stepName[currentLanguage]}
              defaultValue={condition?.stepName[currentLanguage]}
              onChange={event => handleConditionNameChange(event.target.value)}
            />
          </FormControl>

        </Box>
      </Box>

      <Box className={classes.processStepFormSection}>
        <Typography className={classes.processStepFormSectionTitle}>
          {t('customProcesses.creationPage.conditionForm.sections.conditions')}
        </Typography>

        {isTemplatePage && (<Box className={classes.processStepFormEditingDisabledIndicator} mt={3} width="100% !important">
          <Typography component='p'>
            <Trans i18nKey="templates.warnings.addConditionScriptIsNotAvailable">
              Adding condition script is not available
            </Trans>
          </Typography>
        </Box>
      )}

        {!condition?.condition?.field && <Box display="flex" flexDirection="column">
          <Button
            className={classes.addConditionButton}
            variant="outlined"
            color="secondary"
            fullWidth
            onClick={handleConditionModalOpen}
            disabled={isTemplatePage || isEditDisabled}
          >
            <img src={PlusIcon} alt={'add'}/>
            <span>{t('customProcesses.creationPage.conditionForm.addConditionButton', { defaultValue: 'Condition' })}</span>
          </Button>

          {formErrors[condition?.previousStepOrder - 1]?.scriptCondition &&
            <div className={classes.processStepFormErrorMessage}>
              <img src={WarningIcon} alt="warning"/>
              <span>{t('customProcesses.creationPage.conditionFormErrors.requiredCondition')}</span>
            </div>
          }
        </Box>
        }

        {condition?.condition?.field &&
          <Box className={classes.conditionWrapper}>
            <Typography className={classes.conditionText}>
              <Trans
                i18nKey={conditionSentenceTemplateKey}
                values={{ field: conditionFieldName, value: limitedByLengthConditionValue }}
              >
                If <span>conditionFieldName</span> is less than <span>conditionValueFormatted</span>
              </Trans>
            </Typography>

            {!isEditDisabled &&
              <IconButton
                onClick={handleConditionModalOpen}
                className={classes.conditionEditButton}
                size="large">
                <img src={EditIcon} className={classes.conditionEditButtonIcon}/>
              </IconButton>
            }

          </Box>
        }
      </Box>
    </Box>
  );
};
