import * as Yup from 'yup';

import { safeCheckValidity } from 'utils/validation';
import { UserType, HcmsUserModel } from 'types';

const UserSchema = Yup.object()
  .shape({
    id: Yup.string().required(),
    firstName: Yup.string().nullable().notRequired(),
    lastName: Yup.string().nullable().notRequired(),
    photo: Yup.string().nullable().notRequired(),
    login: Yup.string().nullable().notRequired(),
    email: Yup.string().email().notRequired(),
    position: Yup.array().nullable().notRequired(),
    phoneNumber: Yup.string().nullable().notRequired(),
    division: Yup.array().notRequired()
  })
  .strict(true);

export class User {
  id: string;
  photo: string;
  email: string;
  firstName: string;
  lastName: string;
  firstNameRu: string;
  lastNameRu: string;
  logName: string;
  phoneNumber: string;
  login: string;
  position: string[];
  division: string[];
  companyId: string;
  jobTitle: string;
  department: string;
  reportsTo: string;
  reportsToId: string;
  reportsToEmail: string;
  status: string | null;
  role: string | null;

  constructor(data: HcmsUserModel) {
    this.id = data.id;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.firstNameRu = data.firstNameRu;
    this.lastNameRu = data.lastNameRu;
    this.photo = data.photoUrl;
    this.login = data.workEmail;
    this.email = data.workEmail;
    this.phoneNumber = '';
    this.division = data.division ?? [];
    this.position = data.jobTitle?.split(',') || [];
    this.companyId = data.companyId;
    this.department = data.department;
    this.reportsTo = data.reportsTo;
    this.reportsToId = data.reportsToId;
    this.reportsToEmail = data.reportsToEmail;
    this.status = data.status;
    this.role = data.role;
    this.jobTitle = data.jobTitle;
  }

  data(): UserType {
    return {
      id: this.id,
      fullName: `${this.firstName || ''} ${this.lastName || ''}`.trim() ?? " ",
      fullNameRu: `${this.firstNameRu || ''} ${this.lastNameRu || ''}`.trim() ?? " ",
      firstName: this.firstName,
      lastName: this.lastName,
      role: this.role,
      jobTitle: this.position?.join(', '),
      email: this.email,
      avatar: this.photo,
      logName: this.email?.substring(0, this.email?.lastIndexOf('@')).toLowerCase() || '',
      phoneNumber: this.phoneNumber,
      division: this.division,
      companyId: this.companyId,
      department: this.department,
      reportsTo: this.reportsTo,
      reportsToId: this.reportsToId,
      reportsToEmail: this.reportsToEmail,
      status: this.status,
    };
  }

  apiDataModel() {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      photo: this.photo,
      login: this.login,
      email: this.email,
      position: this.position,
      phoneNumber: this.phoneNumber,
      division: this.division,
    };
  }

  isValid(): boolean {
    return safeCheckValidity(UserSchema, this.apiDataModel());
  }

  static from(data: HcmsUserModel): User {
    return new User(data);
  }
}
