import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ spacing, palette }) => ({
  ParallelAssigneeToggle: {
    marginBottom: 16,
  },
  track: {
    backgroundColor: '#E8E9F0 !important',
    borderColor: '#E8E9F0 !important',
  },
  trackChecked: {
    backgroundColor: '#2DB77B !important',
    borderColor: '#2DB77B !important',
  },
  switchBase: {
    top: 0.8
  },
}));
