import makeStyles from '@mui/styles/makeStyles';
import { theme as darTheme } from '@dartech/dms-ui';

const useStyles = makeStyles(({ spacing, palette }) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: spacing(1, 0, 1, 0),
    transition: '0.3s background-color ease',
    // paddingLeft: 16,
  },
  wrapperDraft: {
    paddingLeft: 0,
  },
  wrapperBackground: {
    backgroundColor: '#CED7DF',
  },
  card: {
    display: 'flex',

    minHeight: 120,
    width: '100%',

    backgroundColor: '#FFFFFF',
    // border: `1px solid #F5F8FA`,
    borderRadius: '15px',
    overflow: 'hidden',

    margin: '1px',
    outline: `1px solid ${darTheme.palette.grey[300]}`,
    boxShadow: `0 0 0 1px ${darTheme.palette.grey[300]}`,

    transition:
      '0.2s background-color ease, ' +
      '0.2s border-color ease, ' +
      '0.2s outline ease, ' +
      '0.2s box-shadow ease',
    '&:hover': {
      backgroundColor: '#F5F8FA',
    },
  },
  cardActive: {
    backgroundColor: '#F5F8FA',
    // borderColor: darTheme.palette.primary[200],
    outline: `1px solid ${darTheme.palette.primary[200]}`,
    boxShadow: `0 0 0 1px ${darTheme.palette.primary[200]}`,
  },
  cardUrgent: {
    backgroundColor: '#FFF1F0',
  },
  cardDraft: {
    background: `
    repeating-linear-gradient(
      45deg,
      rgba(38, 40, 66, 0.04),
      #F7F8FC 2px 12px
    )
    `,
  },
  cardUnread: (props: { isMassApproveAvailable: boolean }) => {
    return {
      overflow: 'visible',
      position: 'relative',
      '&::before': {
        content: '""',
        width: 12,
        height: 12,
        position: 'absolute',
        top: -2,
        left: -2,
        zIndex: 1,
        borderRadius: '50%',
        backgroundColor: '#D6331F',
        border: '2px solid #F7F8FC',
      },
    };
  },
  cardApproveType: {
    paddingLeft: '0 !important',
  },
  cardWrapper: {
    position: 'relative',

    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    cursor: 'pointer',
  },
  cardValidationMessageWrapper: {
    position: 'relative',

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 12,
    width: '100%',

    height: 32,
    padding: '6px 16px',

    backgroundColor: '#FCF8EB',
    color: '#262842',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
  },
  validationErrorButton: {
    padding: '4px 8px',
    height: 24,
    color: '#262842',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 500,
    borderRadius: 6,
  },
  cardInnerWrapper: {
    position: 'relative',

    display: 'flex',
    alignItems: 'center',
    width: '100%',

    padding: spacing(4),
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 56px)',
  },
  cardContentHeaderIcon: {
    height: 20,
    width: 20,
  },
  cardContentHeaderTitle: {
    color: '#6D6E85',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',

    marginLeft: spacing(1.5),

    width: 'fit-content',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  cardBadge: {
    position: 'absolute',
    top: spacing(4),
    right: spacing(4),

    backgroundColor: '#FDE6E3',
    borderRadius: spacing(1),

    padding: spacing(0.5, 1, 0.5, 2),
  },
  cardBadgeTitle: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.6,
    color: darTheme.palette.error[600],
  },
  cardReworkIndicator: {
    position: 'absolute',
    right: spacing(8),
    top: spacing(5),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#262842',
    '& img': {
      height: 16,
      width: 16,
      marginRight: spacing(1),
    },
  },

  summaryHtmlWrapper: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '18px',
    color: darTheme.palette.grey[700],
  },

  cardTooltipPopper: {
    pointerEvents: 'all',
  },
  cardTooltip: {
    color: '#F7F8FC',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    maxWidth: 240,
    width: 'fit-content',
    padding: '8px 12px',
    borderRadius: 10,
  },
  cardTooltipLongText: {
    maxWidth: 320,
    width: 'fit-content',
  },

  cardContentUrgentTitle: {
    display: 'block',
    width: '100%',
    '& span': {
      color: '#262842'
    },
    // overflow: 'hidden',
    // whiteSpace: 'nowrap',
    // textOverflow: 'ellipsis',
  },
  cardContentFooterTitle: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '18px',
    color: '#6D6E85',
  },
  cardContentFooterTitleOverdue: {
    color: '#D6331F',
    fontWeight: 500,
  },
  cardContentFooterButton: {
    display: 'flex',
    alignItems: 'center',

    marginLeft: spacing(2),
  },
  cardContentFooterIcon: {
    height: spacing(5),
    width: spacing(5),

    marginRight: spacing(1),
    // marginTop: spacing(1)
  },
  cardContentDueDate: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 8,
    paddingLeft: 8,
    position: 'relative',
    '& :before': {
      content: '""',
      position: 'absolute',
      width: 1,
      height: 17,
      left: 0,
      top: 1.5,
      background: 'rgba(38, 40, 66, 0.12)',
    },
  },
  cardStatus: {
    // width: 40,
    // height: 40,

    marginLeft: spacing(4),
  },
  overdueIcon: {
    height: 10,
    width: 'fit-content',
    marginBottom: spacing(0.5),
  },
  overdueText: {
    fontSize: 16,
    color: '#D6331F',
  },
  checkbox: {
    marginRight: 12,
    '& path': {
      fill: '#8B8C9E !important',
    },
  },
  checkboxChecked: {
    '& path': {
      fill: '#007994 !important',
    },
  },
  checkboxDisabled: {
    '& path': {
      fill: '#2628421F !important',
    },
  },
  unreadIcon: {
    '&::before': {
      content: '""',
      width: 10,
      height: 10,
      position: 'absolute',
      top: 0,
      left: 13.5,
      borderRadius: '50%',
      backgroundColor: '#E94E3A',
      border: '2px solid #FFFFFF',
    },
  },
  statusIcon: {
    width: 8,
    height: 8,
    display: 'inline-block',
    borderRadius: '50%',
    marginRight: '4px',
  },
  priorityIcon: {
    width: 20,
    height: 20,
    marginLeft: -4,
  },
  summaryFieldsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: 4,
  },
  summaryField: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  summaryFieldHintWrapper: {
    maxWidth: 400,
    width: 'fit-content',
  },
  summaryFieldHint: {
    maxWidth: 400,
    width: 'fit-content',
    fontSize: 14,
    fontWeight: 400,
    fontStyle: 'normal',
    color: '#6D6E85',
    lineHeight: '18px',
  },
  summaryFieldValue: {
    maxWidth: 700,
    marginLeft: 4,
    fontSize: 14,
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '18px',
    color: '#262842',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    'line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  summaryFieldValueCount: {
    fontWeight: 500,
  },
  processDescription: {
    maxWidth: 700,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '18px',
    fontStyle: 'normal',
    color: '#262842',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    'line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  summaryFieldEmptyValue: {
    color: '#8B8C9E',
  },
  ticketChip: {
    position: 'absolute',
    top: 16,
    right: 16,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 4,
    padding: '4px 6px',
    borderRadius: 6,
    background: '#F0F0F7',
    '& img': {
      width: 16,
      height: 16,
    },
    '& span': {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '16px',
      color: '#262842',
    },
  },
}));

export default useStyles;
