import React from 'react';

import useProcessContext from './useProcessContext';
import BpmTaskContext from './index';

export const ProcessProvider = ({ children }) => {
  const {
    processes,
    groups,
    error,
    loading,
    getProcessByName,
  } = useProcessContext();

  return (
    <BpmTaskContext.Provider
      value={{
        processes,
        groups,
        error,
        loading,
        getProcessByName,
      }}>
      {children}
    </BpmTaskContext.Provider>
  );
};
