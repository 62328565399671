import React, { useEffect, useState } from 'react';
import { useUsersRole } from 'hooks';
import { Button, Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { useRequests } from 'pages/Requests/useRequests';

import useStyles from './NewButtonRequestHeader.useStyles'
import ProcessSettingsButtonIcon from 'assets/images/icons/process-settings-button-icon.svg';
import CreateCustomProcessIcon from 'assets/images/icons/new_custom_process.svg';
import CreateProcessIcon from 'assets/images/icons/tasks_app_regular.svg';
import { matomoScript, matomoTagManagerScript } from '../../utils/analytics';
import { sendCustomEvent } from 'utils/analytics'

export const NewButtonRequestHeader = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isGroupSettingPageAvailable, isTemplateProcessCreationAvailable } = useUsersRole();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const history = useHistory();

  const {
    openDialog,
    navigateToProcessSettings
  } = useRequests();

  useEffect(() => {
    // Инициализация Matomo
    eval(matomoScript);
    // Инициализация Tag Manager
    eval(matomoTagManagerScript);
  }, []);

  return <>
    {isGroupSettingPageAvailable && (
      <Button onClick={navigateToProcessSettings} size="medium" className={classes.settingsButton}
        color="secondary">
        <img src={ProcessSettingsButtonIcon} alt="settings" />
      </Button>
    )}

    {
      isTemplateProcessCreationAvailable ? <Button
        size="large"
        color="primary"
        variant="contained"
        startIcon={<AddIcon />}
        onClick={({ currentTarget }) => {
          setAnchorEl(currentTarget); // Устанавливаем текущую кнопку
          // sendCustomEvent(); // Отправляем событие
        }}
        className={classes.newRequestButton}
      >
        {t('Processes.newButton')}
      </Button> : <Button
        size="large"
        color="primary"
        variant="contained"
        startIcon={<AddIcon />}
        onClick={openDialog}
        className={classes.newRequestButton}
      >
        {t('Processes.Request')}
      </Button>
    }

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            sx: {
              width: 'fit-content',
              maxWidth: 'fit-content',
              border: '1px solid rgba(38, 40, 66, 0.08)',
              boxShadow: '0px 8px 16px rgba(38, 40, 66, 0.04)',
              borderRadius: '10px !important',
              marginTop: 8
            }
          }
        }
        }
      >
        <MenuItem className={classes.menuItem} onClick={() => {
          sendCustomEvent('Requests', 'Click_on_create_new_request', 'create_request_init');
          openDialog();
          setAnchorEl(null);
        }}>
          <img src={CreateProcessIcon} alt="" />
          {t('Processes.NewRequest')}
        </MenuItem>

        <MenuItem className={classes.menuItem} onClick={() => {
          setAnchorEl(null);
          sendCustomEvent('Requests_Process_Builder', 'Click_New_custom_Process_Builder_opened', 'Process_Builder_opened');
          history.push('/template-processes', {
            openCreateCustomProcessDialog: true
          });
        }}>
          <img src={CreateCustomProcessIcon} alt='' />
          {t('Processes.NewCustomProcess')}
        </MenuItem>
      </Menu>

  </>
}
