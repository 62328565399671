import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getGlossaryData } from 'api/requests';
import {
  sortArrayOfObjects,
  isObjectEmpty,
  isObjectAttributesEmpty,
} from 'utils/general';
import {
  setSkippedSteps,
  useSkippedTaskSteps,
  useTaskState,
  setTaskComponentDataLoadingStarted,
  setTaskComponentDataLoadingFinished,
} from 'store/requests';

const OPTIMIZED_ENDPOINTS = ['legalEntities', 'typeOfExpanses', 'names'];
const AGREEMENTS_PROCESS_NAME = 'Agreements';
const AGREEMENT_TYPE_FIELD_NAME = 'agreementType';
const AGREEMENT_TYPE_NDA = 'Соглашение о конфид-ти';
const AGREEMENT_TYPE_NDA_SKIPPED_STEP = 2;

const useGlossary = ({
  name,
  params,
  masterField,
  secondMasterField,
  innerFields,
  showSingleOptionReadOnly,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    control,
    formState: {
      errors,
    },
    setValue: setFormValue,
    watch,
    trigger,
    getValues,
  } = useFormContext();

  const formValue = watch(name);

  const { data: bpmTask } = useTaskState();
  const { skippedSteps } = useSkippedTaskSteps();

  const [isTree, setTree] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(
    params.multiSelect
    ? null
    : watch(name)
      ? { name: watch(name), id: watch(name) }
      : null);
  const [selectedValues, setSelectedValues] = useState<string[]>(
    params.multiSelect
    ? watch(name)
      ? watch(name).split(',')
      : []
    : []);
  const [inputValue, setInputValue] = useState(watch(name) || '');

  useEffect(() => {
    if (
      bpmTask.processName === AGREEMENTS_PROCESS_NAME &&
      name === AGREEMENT_TYPE_FIELD_NAME
    ) {
      if (value && value.name === AGREEMENT_TYPE_NDA) {
        dispatch(
          setSkippedSteps(
            skippedSteps.concat([AGREEMENT_TYPE_NDA_SKIPPED_STEP]),
          ),
        );
      } else {
        dispatch(
          setSkippedSteps(
            skippedSteps.filter((v) => v !== AGREEMENT_TYPE_NDA_SKIPPED_STEP),
          ),
        );
      }
    }
  }, [bpmTask.processName, name, value]);

  const clearFormValues = useCallback(() => {
    setFormValue(name, '');

    innerFields.length &&
    innerFields.forEach(field => setFormValue(field[0], ''));
  }, [innerFields, name, setFormValue]);

  const masterFieldValue = masterField?.fieldName ? watch(masterField.fieldName) : '';

  const secondMasterFieldValue = secondMasterField.fieldName ? watch(secondMasterField.fieldName) : '';

  const isAllParametersExists = useCallback(requestParameters =>
      Object.values(requestParameters).every(requestParameter => requestParameter),
    []);

  const getRequestParameters = useCallback(() => {
    const { source, endpoint } = params;
    let fullEndpoint = `${source}/${endpoint}`;
    const requestParameters: { [key: string]: string } = {};
    setTree(source.includes('/hcms'));
    if (source.includes('/hcms')) {
      fullEndpoint = `${source.replace('/hcms', '/hcms/v2')}/${endpoint}`;
    }

    if (masterField?.paramName && masterField?.fieldName) {
      requestParameters[masterField.paramName] = masterFieldValue;
    }

    if (secondMasterField?.paramName && secondMasterField?.fieldName) {
      requestParameters[secondMasterField.paramName] = secondMasterFieldValue;
    }

    if (OPTIMIZED_ENDPOINTS.includes(endpoint)) {
      requestParameters.process = bpmTask.processName;
    }

    return {
      endpoint: fullEndpoint,
      requestParameters,
    };
  }, [
    params,
    bpmTask.processName,
    masterField,
    masterFieldValue,
    secondMasterField,
    secondMasterFieldValue,
  ]);

  const getBpmGlossaryValues = useCallback(async () => {
    setLoading(true);

    const {
      endpoint,
      requestParameters,
    } = getRequestParameters();

    if (!endpoint.includes('/hcms')) {
      if (!isAllParametersExists(requestParameters)) {
        setLoading(false);
        setOptions([]);

        clearSelectedValue();
        return;
      }

      try {
        dispatch(setTaskComponentDataLoadingStarted(name));
        const bpmGlossaryData = await getGlossaryData(endpoint, requestParameters);

        if (!endpoint.includes('/hcms')) {
          const bpmGlossaryDataWithLabel = bpmGlossaryData.map(item => ({
            ...item,
            label: t(`glossary_1c.${item?.id}`, { defaultValue: item?.name }),
          }));
          const bpmGlossarySortedData = bpmGlossaryDataWithLabel.sort((a, b) =>
            sortArrayOfObjects(a, b, 'label'),
          );

          if (value?.name) {
            const selectedValue = bpmGlossarySortedData.find(({ name }) => name === value.name);
            // @ts-ignore
            if (!value?.label) {
              setValue(selectedValue);
            }
            !showSingleOptionReadOnly && !selectedValue && clearSelectedValue();
          }
          setOptions(bpmGlossarySortedData);
        }
        dispatch(setTaskComponentDataLoadingFinished(name));
      } catch (error) {
        isObjectEmpty(requestParameters) &&
        isObjectAttributesEmpty(requestParameters) &&
        clearSelectedValue();

        setOptions([]);
      } finally {
        setLoading(false);
      }
    }
  }, [value, getRequestParameters]);

  useEffect(() => {
    getBpmGlossaryValues();
  }, [getBpmGlossaryValues]);

  const clearSelectedValue = () => {
    setValue(null);
    setSelectedValues([]);
    setInputValue('');
  };

  const handleChange = (_, val) => {
    if (params.multiSelect) {
      setSelectedValues(val)
    } else {
      setValue(val);
    }

    if (val) {
      if (params.multiSelect) {
        setFormValue(name, val.join(','))
      } else {
        setFormValue(name, params?.isAcceptedValueTypeId ? val.id : val.name);

        innerFields.length &&
        innerFields.forEach(field => setFormValue(field[0], val.fields[field[1]]));
      }
    } else {
      clearFormValues();
    }

    errors[name] && trigger(name);
  };

  const handleInputChange = (_, val) => {
    setInputValue(val);
    const isSameValue = value?.['label'] === val;
    if (!isSameValue) {
      if (typeof value === 'string') {
        setFormValue(name, value);
      } else {
        if (typeof val === 'string') {
          setFormValue(name, params?.isAcceptedValueTypeId ? (val || value) : (val || value));
        } else {
          setFormValue(name, params?.isAcceptedValueTypeId ? (val.id || value) : (val?.name || value));
        }
      }
    }

    errors[name] && trigger(name);
  };

  useEffect(() => {
    if (showSingleOptionReadOnly && options.length === 1 && !formValue && formValue !== null) {
      handleChange(null, options[0]);
    }
  }, [options, showSingleOptionReadOnly, formValue]);

  const getOptionLabel = (option) => {
    if (option.name) {
      if (name === 'paymentCode' && option.fields) {
        return `${option.label || option.name} - (${option.fields.paymentCodeId})`;
      }

      return option.label || option.name;
    }

    return '';
  };

  const getOptionSelected = (option, value) => {
    if (value && value.name) {
      return option.name === value.name;
    }

    return false;
  };

  const isValueFromOptions = (value: string) =>
    !!options.find((option) => {
      const optionValue = typeof option === 'string' ? option : option?.name;
      const valueValue = typeof value === 'string' ? value : (value as { name: string })?.name;
      return optionValue?.toLowerCase() === valueValue?.toLowerCase();
    }) || t('form_components.validation_errors.listOption') as string;

  return {
    loading,
    errors,
    options,
    value,
    selectedValues,
    inputValue,
    control,
    handleChange,
    handleInputChange,
    getOptionLabel,
    getOptionSelected,
    isValueFromOptions,
    isTree,
  };
};

export default useGlossary;
