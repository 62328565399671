import React, { Suspense } from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { routesList } from 'router';
import { Spinner } from 'components';

export const ApprovalsRouter = () => {
  const { t } = useTranslation();
  const {
    approvals: { submenu },
  } = routesList(t);

  const { path } = useRouteMatch();
  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route path={path} exact>
          <Redirect to={`${path}${submenu[0].path}`} />
        </Route>

        {submenu.map(({ path: routePath, component: Component, props }) => (
          <Route path={`${path}${routePath}`}>
            <Component {...props} />
          </Route>
        ))}

        <Route path={`${path}(.*)`}>
          <Redirect to={routesList(t).approvals.path} />
        </Route>
      </Switch>
    </Suspense>
  );
};
