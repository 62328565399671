import React, {ReactElement} from "react";
import { Box, Button, IconButton, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

import useArchiveProcessDialogStyles from './ArchiveProcessDialog.useStyles';

interface ArchiveProcessDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onArchive: () => void;
}

export const ArchiveProcessDialog = ({ isOpen, onClose, onArchive }: ArchiveProcessDialogProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useArchiveProcessDialogStyles();

  const handleArchive = () => {
    onArchive();
    onClose();
  }

  return (
    <Modal open={isOpen}>
      <>
        <Box className={classes.modalBody}>
          <div className={classes.modalHeader}>
            <Typography className={classes.title}>
              {t('customProcesses.creationPage.archiveModal.title')}
            </Typography>

            <IconButton onClick={onClose} size="large">
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </div>

          <div className={classes.modalContent}>
            <Typography className={classes.description}>
              {t('customProcesses.creationPage.archiveModal.message')}
            </Typography>
          </div>

          <div className={classes.modalFooter}>
            <Button color="secondary" variant="contained" onClick={onClose} className={classes.modalButton}>
              {t('comment_modal.button_cancel')}
            </Button>

            <Button color="primary" onClick={handleArchive} className={classes.modalButton}>
              {t('customProcesses.creationPage.buttons.archive')}
            </Button>
          </div>
        </Box>
      </>
    </Modal>
  );
}
