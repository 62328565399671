import { useEffect, useRef } from 'react';

export const useInterval = (callback, delay) => {
  const savedCallback = useRef<any>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function handleCallback() {
      savedCallback?.current && savedCallback.current();
    }

    if (delay !== null) {
      const id = setInterval(handleCallback, delay);

      return () => {
        clearInterval(id);
      };
    }
  }, [callback, delay]);
}
