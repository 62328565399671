import React, {memo,ReactElement} from 'react';
import { Box, MenuItem, Select, Typography } from '@mui/material';
import { Pagination } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ChevronDown from 'assets/images/icons/chevron_down_regular.svg'
import useStyles from './useStyles';

type CustomPaginationProps = {
  pageSize: number;
  handlePageSizeChange: (event: any) => void;
  totalPages: number;
  currentPage: number;
  handlePageChange: (_: any, newPage: number) => void;
};

const Icon = () => {
  const classes = useStyles();

  return <img className={classes.chevron} src={ChevronDown} alt="" />
}

export const CustomPagination = memo(({
  pageSize,
  handlePageSizeChange,
  totalPages,
  currentPage,
  handlePageChange,
}: CustomPaginationProps): ReactElement => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <Box display="flex" my={3}>
      <Box display="flex" alignItems="center">
        <Typography className={classes.pageSizeLabel}>{t("Processes.pagination_page_size_label").replace(":", "")}</Typography>

        <Select value={pageSize} onChange={handlePageSizeChange} IconComponent={Icon} className={classes.select}>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={25}>25</MenuItem>
        </Select>
      </Box>

      <Pagination
        variant="outlined"
        shape="rounded"
        color="secondary"
        className={classes.pagination}
        siblingCount={1}
        boundaryCount={1}
        count={totalPages}
        page={currentPage}
        hideNextButton={totalPages <= 5}
        hidePrevButton={totalPages <= 5}
        onChange={handlePageChange}
      />
    </Box>
  );
});
