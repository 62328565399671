import makeStyles from '@mui/styles/makeStyles';

type Props = {
  isActionsVisible: boolean;
};

export default makeStyles(({ spacing }) => ({
  mainBox: ({ isActionsVisible }: Props) => ({
    height: `calc(100vh - ${isActionsVisible ? '360' : '304'}px)`, // 100vh - header, pagination etc heights
    overflowY: 'scroll',
  }),
  root: {
    width: '100%',
    border: 'none',
    boxShadow: 'none',
    minWidth: 1048,
  },
  container: {
    width: '100%',
    borderRadius: '15px',
    border: '1px solid #E0E3EA',
  },
  table: {
    borderCollapse: 'collapse',
    tableLayout: 'fixed',

    '& tr:has(span input[type=checkbox]) td': {
      paddingLeft: 12,
    },
    '& tr:has(span input[type=checkbox]) th': {
      paddingLeft: 12,
    },

    '& tr:has(span input:not(:checked)) span': {
      visibility: 'hidden',
    },
    '& tr:hover td:has(span input) span': {
      visibility: 'visible',
    },
    '& tr:hover th:has(span input) span': {
      visibility: 'visible',
    },
  },
  tableHeaderRow: {
    height: spacing(10),
    background: '#F5F8FA',
  },
  tableHeaderRowCell: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.3,
    color: '#465564',
    padding: 8,
    overflow: 'hidden',
    width: 'max-content',
    background: '#F5F8FA',
  },
  tableBodyActionMenuListItem: {
    padding: spacing(2.75, 4),
  },
  tableBodyActionMenuListItemText: {
    color: '#101F2B',
  },
}));
