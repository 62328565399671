import React, { ReactElement, useContext } from 'react';
import {
  Box,
  Link,
  TableCell,
  Typography, Tooltip, ButtonBase
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import moment from 'moment';
import { UserInfo } from 'components';

import { TableComponent } from './TableComponent'
import { useUsersState } from 'store/users';
import { TemplatesTableColumns } from './TemplatesTable.constants';
import { environment } from 'environments/environment';

import { TemplatesTableRow, TemplatesTableColumn } from './TemplatesTable.types';

import useStyles from './TemplatesTable.useStyles';
import ProcessContext from 'contexts/ProcessContext/context';
import { ProcessContextType } from 'types';
import { useUserProfile } from 'hooks';
import classNames from 'classnames';
import InfoIcon from '../../../../assets/images/icons/info_outline.svg';

type Props = {
  setCurrentRow: (row: TemplatesTableRow) => void
  setAnchorElement: (el: HTMLElement) => void
  requests: TemplatesTableRow[];
  anchorElement: HTMLElement;
  menuButtons: {
    title: string;
    visible: boolean;
    onClick: () => void
  }[];
  handleProcessNameClick: (row: TemplatesTableRow) => void
}

export const TemplatesTable = (props: Props) => {
  const { groups }: Partial<ProcessContextType> = useContext(ProcessContext);
  const { companyId } = useUserProfile();

  const {
    setCurrentRow,
    setAnchorElement,
    anchorElement,
    requests,
    menuButtons,
    handleProcessNameClick
  } = props;

  const classes = useStyles();

  const { t } = useTranslation();
  const { users = [] } = useUsersState();
  const currentRegion = environment.region === 'US' ? 'MM/DD/YYYY' : 'DD.MM.YYYY'

  const renderCell = (column: TemplatesTableColumn, row: TemplatesTableRow): ReactElement => {
    switch (column.id) {
      case 'processName': {
        return (
          <TableCell
            key={column.id}
            className={classes.tableCell}
            width={column.width}
            style={{ width: column.width }}
          >
            <Box className={classes.templateName} onClick={() => {
              handleProcessNameClick(row)
            }}>
              <Box
                className={classNames(classes.templateNameHeader)}>
                <img className={classes.templateNameHeaderImage} src={row.iconPath} alt=''/>
              </Box>

              <div className={classes.templateProcessNameInfo}>
                <Tooltip title={row[column.id]} placement="top-start">
                  <Link className={classes.templateNameTitle}>{row[column.id]}
                  </Link>
                </Tooltip>

                <Typography className={classes.templateNameSubtitle}>
                  {t(`groups.${row['category']}.name`, { defaultValue: groups.find(g => g?.sysName === row['category'])?.name })}
                </Typography>
              </div>
            </Box>
          </TableCell>
        );
      }
      case 'status': {
        const isShared = row.published;

        return (
          <TableCell key={column.id}
                     className={classes.tableCell}
                     width={column.width}
                     style={{ width: column.width }}
          >
            <Box display="flex" alignItems="center">
              <Box
                className={classNames(classes.status, { [classes.statusActive]: isShared })}></Box>
              <Box display="flex" flexDirection="column">
                <Typography className={classes.statusTitle}>
                  {isShared
                   ? t('templates.statusShared')
                   : t('templates.statusNotShared')}

                  {isShared && row?.draftIsChange &&
                    (<Typography className={classes.statusSubtitle}>
                      {t('templates.unsharedChanges')}
                    </Typography>)
                  }

                </Typography>
              </Box>
            </Box>
          </TableCell>
        );
      }
      case 'integration': {
        const integrationsList = row?.integration?.split(';') || [];

        if (integrationsList.length === 0) {
          return (
            <TableCell key={column.id}
                       className={classes.tableCell}
                       width={column.width}
                       style={{ width: column.width }}
            >
              <Typography>-</Typography>
            </TableCell>
          );
        }

        const localizedIntegrationsList = integrationsList.map(integrationName => t('templates.integrations.' + integrationName.toLowerCase(), {defaultValue: integrationName}));
        const displayedIntegration = localizedIntegrationsList[0];
        const extraIntegrations = localizedIntegrationsList.slice(1);

        const tooltipContent = <div className={classes.integrationsTooltipContent}>
          {extraIntegrations.map(value => <div className={classes.integrationChip}>{value}</div>)}
        </div>

        return (
          <TableCell key={column.id}
                     className={classes.tableCell}
                     width={column.width}
                     style={{ width: column.width }}
          >
            <Box className={classes.templateIntegrationsCell}>
              <div className={classes.integrationChip}>{displayedIntegration}</div>
              {extraIntegrations.length > 0 &&
                <Tooltip
                  placement="top"
                  title={tooltipContent}
                  enterDelay={300}
                  classes={{
                    tooltipArrow: classes.tooltip,
                    arrow: classes.tooltipArrow,
                    tooltip: classes.integrationTooltip,
                  }}>
                <div className={classes.integrationChip}>+{extraIntegrations.length}</div>
                </Tooltip>
              }
            </Box>
          </TableCell>
        );
      }
      case'allLanguages': {
        const availableLanguages = row[column.id]?.split(',')?.map(v => v.trim()) || [];
        const allProcessLanguages = availableLanguages?.join(', ') || '';

        return (
          <TableCell key={column.id}
                     className={classes.tableCell}
                     width={column.width}
                     style={{ width: column.width }}
          >
            <Typography>{allProcessLanguages?.toUpperCase()}</Typography>
          </TableCell>
        );
      }
      case 'creator': {
        const creator = users[row.creator];
        if (!creator) return <TableCell
          key={column.id}
          width={column.width}
          style={{ width: column.width }}
        />;

        return (
          <TableCell
            key={column.id}
            width={column.width}
            style={{ width: column.width }}
            className={classNames(classes.avatar, classes.tableCell)}
          >
            <Box display="flex" alignItems="center">
              <UserInfo
                user={creator}
                avatarSize={24}
                nameMarginLeft={12}
              />
            </Box>
          </TableCell>
        );
      }
      case'createdDate':
      case'lastUpdated': {
        const currentDate = moment(row[column.id]).format(currentRegion);

        return (
          <TableCell key={column.id}
                     className={classes.tableCell}
                     width={column.width}
                     style={{ width: column.width }}
          >
            <Typography>{currentDate}</Typography>
          </TableCell>
        );
      }
      case 'processMenu': {
        return (
          <TableCell
            key={column.id}
            className={classes.tableCell}
            style={{ width: column.width }}
            width={column.width}
          >
            <ButtonBase
              className={classes.actionButton}
              onClick={event => {
                setCurrentRow(row);
                setAnchorElement(event.currentTarget);
              }}>
              <MoreHorizIcon
                fontSize="small"
                color="secondary"
                className={classes.actionButtonIcon}
              />
            </ButtonBase>
          </TableCell>
        );
      }
      default: {
        return (
          <TableCell key={column.id}
                     className={classes.tableCell}
                     style={{ width: 48 }}
          >
            <Typography>{row[column.id]}</Typography>
          </TableCell>
        );
      }
    }
  };

  return <TableComponent
    columns={TemplatesTableColumns}
    rows={requests}
    renderCell={renderCell}
    menuButtons={menuButtons}
    anchorElement={anchorElement}
    onMenuClose={() => {
      setAnchorElement(null)
    }}
  />
}
