import React, { useMemo } from 'react';
import { useQRCode } from 'next-qrcode';
import { useTaskState } from 'store/requests';
import { useUserProfile } from 'hooks';
import { PROCESS_LINK_TEMPLATE, WORKSPACE_PARAM } from './TaskPdfGenerator.constants';

export const TaskPdfGenerator = () => {
  const { Canvas } = useQRCode();
  const { data: bpmTask } = useTaskState();
  const { companyId } = useUserProfile();
  const processLink = useMemo(() =>
      PROCESS_LINK_TEMPLATE + bpmTask?.processInstanceId + WORKSPACE_PARAM + companyId
    , [bpmTask]);

  return (
    <div id="qrCodeWrapper" style={{ display: 'none' }}>
      <Canvas
        text={processLink}
        options={{
          type: 'image/png',
          quality: 0.3,
          level: 'M',
          margin: 1,
          scale: 4,
          width: 256,
          color: {
            dark: '#000000',
            light: '#FFFFFF',
          },
        }}
      />
    </div>
  );
};
