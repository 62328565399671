import { getUserDataByIdOrLogName } from 'utils/user';

export const splitText = (value: string | null): string | string[] | null =>
  value?.includes(',') ? value.split(',') : value;

export async function filterUsers(usernames: string[], users, companyId = '') {
  const userCollect = [];

  if (Array.isArray(usernames) && usernames?.length) {
    for (const username of usernames) {
      const userId = Object.keys(users).find(
        (id) => users[id].logName === username || id === username
      );
      if (userId) {
        userCollect.push(users[userId]);
      } else {
        const userData = await getUserDataByIdOrLogName(username, companyId);
        if (userData) {
          userCollect.push(userData);
        }
      }
    }
  }

  return userCollect;
}
