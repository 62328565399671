import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ spacing, palette }) => ({
  card: {
    minHeight: 83,
    width: '100%',
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight: spacing(2),
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    backgroundColor: '#ffffff',
    borderRadius: 8,
    transitionProperty: 'background, border',
    transitionDuration: '300ms',

    '&:hover': {
      background: '#F7F8FC',
      '& $fieldActionsButtonHiddenWhenCardInactive': {
        opacity: 1,
        pointerEvents: 'all',
      },
      '& $cardActions': {
        display: 'block',
        position: 'absolute',
        top: '50%',
        right: 12,
        marginTop: -20,
      },
    },
  },
  cardDisabled: {
    '&:hover': {
      background: '#ffffff',
      '& $dragIndicator': {
        opacity: '0 !important',
      },
      '& $fieldActionsButtonHiddenWhenCardInactive': {
        opacity: '0 !important',
        pointerEvents: 'none !important',
      },
    },
  },
  cardIntegratedField: {
    backgroundColor: '#F7F8FC !important',
  },
  cardFocused: {
    background: '#ECF1FE',
    border: '2px solid #497CF6',
    '& $fieldActionsButtonHiddenWhenCardInactive': {
      opacity: 1,
      pointerEvents: 'all',
    },
  },
  cardDragged: {
    background: 'rgba(255, 255, 255, 0.8)',
    border: '2px solid #007994',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.12)',
    '& $fieldActionsButtonHiddenWhenCardInactive': {
      opacity: 1,
      pointerEvents: 'all',
    },
  },
  nameRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: spacing(1),
    cursor: 'default',
  },
  name: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    color: '#6D6E85',
    paddingRight: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
  },
  nameRequired: {
    '&::after': {
      display: 'inline-block',
      content: '"*"',
      color: palette.error[600],
      fontSize: 14,
      position: 'relative',
      right: -2,
      top: -2,
    },
  },
  cardContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflow: 'hidden',
  },
  fieldWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: spacing(2),
  },
  dragIndicator: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& img': {
      height: 20,
      width: 20,
      minWidth: 20,
      marginLeft: 0,
      marginRight: 0,
    },
  },
  cardActions: {
    display: 'none',
  },
  fieldActionsButtonHiddenWhenCardInactive: {
    opacity: 0,
    transitionProperty: 'opacity',
    transitionDuration: '300ms',
    pointerEvents: 'none',
  },
  fieldActionsButton: {
    width: 40,
    height: 40,
    backgroundColor: '#FFFFFF !important',
    // borderColor: '#FFFFFF !important',
    border: '1px solid #2628421F !important',
    borderRadius: '0 !important',
    '&:first-child': {
      borderTopLeftRadius: '10px !important',
      borderBottomLeftRadius: '10px !important',
    },
    '&:not(:first-child)': {
      borderLeftWidth: '0px !important',
    },
    '&:last-child': {
      borderTopRightRadius: '10px !important',
      borderBottomRightRadius: '10px !important',
    },
  },
  attachmentWrapper: {
    width: '100%',
    border: '1px dashed #b7c3cc',
    borderRadius: 16,
    '& .AttachmentsUploadLowHeight': {
      borderTop: 'none !important',
    },
  },
  infoIcon: {
    width: 16,
    height: 16,
    marginLeft: 4,
    verticalAlign: 'sub',
  },
  goToStepButton: {
    padding: 0,
    color: '#7CCCDE',
  },
  tooltip: {
    background: '#262842',
  },
  tooltipArrow: {
    color: '#262842',
  },
  fieldTooltip: {
    borderRadius: 8,
  },
  readonlyField: {
    paddingTop: 4,
    width: '100%',
  },
  templateFieldChip: {
    padding: '4px 6px',
    marginLeft: 4,
    borderRadius: 6,
    background: '#F0F0F7',
    color: '#262842',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
    whiteSpace: 'nowrap',
    '&:hover': {
      background: '#E8E8F0',
    },
  },
  signRequired: {
    backgroundColor: '#F0F0F7',
    padding: '4px 8px',
    borderRadius: 4,
    fontSize: 12,
    fontWeight: 600,
    margin: '4px 6px',
  },
}));
