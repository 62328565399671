import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import DOMPurify from 'dompurify';
import { useDispatch } from 'react-redux';

import { setTaskNewCommentsIndicator, useTaskState } from 'store/requests';
import { useInterval, useUserProfile } from 'hooks';
import { sendComment, getRequestComments, getRequestAssigneesList } from 'api/requests';
import { sortArrayOfObjects } from 'utils/general';
import { compareUsers } from 'utils/user';

import { User } from 'models/User.model';
import { HcmsUserModel, UserType } from 'types';

import { MENTION_REGEX, MENTION_SEARCH_REGEX, COMMENTS_TAB_NAME } from './constants';
import { purifyOptions } from '@dar-dms/utils';
import { readTaskComments } from '../../../../../../store/main';
import { useSelectedReviewedCurrentTask } from '../../../../../../store/approvals';
import { allUserPlug } from './utils';

const COMMENTS_REFRESH_INTERVAL = 30000;

type Props = {
  setTabCounter?: (tavName: string, value: string | number) => void;
  tabsList?: any[];
  mainScrollbarsRef: any;
  activeTab?: string;
};

const useTaskComments = ({ setTabCounter, tabsList, mainScrollbarsRef, activeTab }: Props) => {
  const { data: bpmTask } = useTaskState();
  const { id: profileId, companyId } = useUserProfile();
  const dispatch = useDispatch();
  const selectedReviewedApprovalCurrentStepId = useSelectedReviewedCurrentTask();

  const { t } = useTranslation();

  const taskCompanyId = bpmTask.values['companyId'] || companyId;

  const commentsScrollbarsRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState([]);
  const [dateGroupedComments, setDateGroupedComments] = useState({});
  const [error, setError] = useState('');
  const [comment, setComment] = useState('');
  const [isCommentEmpty, setCommentEmpty] = useState(true);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isUsersListShown, setUsersListShown] = useState(false);
  const [foundMention, setFoundMention] = useState('');
  const [fullMention, setFullMention] = useState('');
  const [mentionedUsers, setMentionedUsers] = useState<string[]>([]);
  const [users, setUsers] = useState({});

  const { handleSubmit } = useForm();

  const loadProfileCompanyUsers = async (companyId?: string): Promise<void> => {
    try {
      const taskAssigneesList = await getRequestAssigneesList(bpmTask.processInstanceId);
      const mappedUsersList = taskAssigneesList.map((data: HcmsUserModel) => User.from(data)).map((user: User) => user.data());
      const usersListObject = mappedUsersList.reduce((acc, user) => {
        return {
          ...acc,
          [user.id]: user,
        };
      }, {});
      setUsers(usersListObject);
    } catch (error) {
      setUsers({});
    }
  };

  useEffect(() => {
    loadProfileCompanyUsers(taskCompanyId);
  }, [taskCompanyId]);

  // setting comments count to data view comments tab label
  useEffect(() => {
    if (!!tabsList && !!setTabCounter) {
      const commentsTab = tabsList.find((tab) => tab.name === COMMENTS_TAB_NAME);

      if (commentsTab) {
        const commentsTabCount = commentsTab?.label ? +commentsTab.label.replace('(', '').replace(')', '') : 0;

        if ((commentsTab && !commentsTab?.label) || comments.length !== commentsTabCount) {
          setTabCounter(COMMENTS_TAB_NAME, comments.length);
        }
      }
    }
  }, [comments, setTabCounter, tabsList]);

  const showFilteredUsersList = (activeMentionTextPassed: string) => {
    setUsersListShown(true);
    const allUsers = Object.values(users).sort(compareUsers);
    if (activeMentionTextPassed?.length) {
      const filterString = activeMentionTextPassed.trim();
      const matchString = filterString.trim().substr(filterString.indexOf('@')).replace('@', '').replace('&nbsp;', ' ').toLowerCase();
      const filteredUsersList = Object.values({ all: allUserPlug(t), ...users })
        .filter((user: UserType) => !matchString || user.fullName.toLowerCase().includes(matchString))
        .sort(compareUsers);

      setFilteredUsers(filteredUsersList);
    } else {
      setFilteredUsers([allUserPlug(t), ...allUsers]);
    }
  };

  const handleUserSelect = (user: UserType) => {
    try {
      const mentionsSearchResult = [...comment.matchAll(MENTION_SEARCH_REGEX)];
      const mentionsResult = [...comment.matchAll(MENTION_REGEX)];
      // empty active mention is just @, or empty string if we mention user from toolbar's "@" button
      const isEmptyActiveMention =
        mentionsSearchResult.length - mentionsResult.length === 1 || (!mentionsResult?.length && !mentionsSearchResult?.length);
      const activeMentionText = isEmptyActiveMention
        ? mentionsResult?.length || mentionsSearchResult?.length
          ? '@'
          : ''
        : mentionsResult[mentionsResult.length - 1][0].replace('<', '').replace('>', '').trim();
      const mention = user.fullName + ' ';

      if (user?.id === 'All') {
        setMentionedUsers(filteredUsers.filter((user) => user.id !== 'All').map((user) => user.id));
      } else {
        setMentionedUsers((mentions) => [...mentions.filter((id) => id !== user.id), user.id]);
      }
      setFoundMention(activeMentionText);
      setFullMention(mention);
      setFilteredUsers([]);
      setUsersListShown(false);
      mainScrollbarsRef?.current?.scrollToTop();
    } catch (err) {
      console.error(err);
    }
  };

  const validate = () => {
    setError('');

    // TODO - подумать как сделать чтобы эта проверка отрабатывала правильно
    //  для комментария только из проблов
    if (!comment.trim()) {
      NotificationManager.error(t('task_data_view.comments_tab.empty_comment_error'));
      return false;
    }

    return true;
  };

  const groupCommentsByDate = (commentsList) => {
    return commentsList.reduce((acc, comm) => {
      const dateDay = comm.date.split('T')[0];
      const commentDate = moment(dateDay);

      const dateFormatTemplate = 'dddd, MMMM Do' + (moment().isSame(commentDate, 'year') ? '' : ' YYYY');
      let groupKey = commentDate.format(dateFormatTemplate);
      if (moment().isSame(commentDate, 'd')) {
        groupKey = t('task_data_view.comments_tab.today');
      }
      if (moment().subtract(1, 'd').isSame(commentDate, 'd')) {
        groupKey = t('task_data_view.comments_tab.yesterday');
      }

      if (Object.keys(acc).includes(groupKey)) {
        acc[groupKey].push(comm);
      } else {
        acc[groupKey] = [comm];
      }
      return acc;
    }, {});
  };

  const loadComments = (isUpdatingComments = false) => {
    setLoading(true);
    try {
      getRequestComments(bpmTask.processInstanceId)
        .then((response) => {
          if (response) {
            const commentsList = response.sort((a, b) => sortArrayOfObjects(a, b, 'date'));
            setComments(commentsList);
            setDateGroupedComments(groupCommentsByDate(commentsList));
            commentsScrollbarsRef?.current?.scrollToBottom();
          } else {
            setComments([]);
            setDateGroupedComments({});
          }
          setError('');
        })
        .catch((err) => {
          setError(err.message);
        });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadComments();
    // setLoading(true);
    // try {
    //   getRequestComments(bpmTask.processInstanceId)
    //     .then((response) => {
    //       if (response) {
    //         const commentsList = response.sort((a, b) =>
    //           sortArrayOfObjects(a, b, 'date')
    //         );
    //         setComments(commentsList);
    //         setDateGroupedComments(groupCommentsByDate(commentsList));
    //         commentsScrollbarsRef?.current?.scrollToBottom();
    //       } else {
    //         setComments([]);
    //         setDateGroupedComments({});
    //       }
    //       setError('');
    //     })
    //     .catch((err) => {
    //       setError(err.message);
    //     });
    // } catch (err) {
    //   setError(err.message);
    // } finally {
    //   setLoading(false);
    // }
  }, [bpmTask.processInstanceId]);

  const isActiveTab = useMemo(() => activeTab === 'comments', [activeTab]);

  const updateTaskComments = useCallback(() => {
    if (isActiveTab && comments.length > 0) {
      loadComments();
    }
  }, [isActiveTab, comments]);

  useEffect(() => {
    updateTaskComments();
  }, [isActiveTab]);

  useInterval(() => loadComments(true), COMMENTS_REFRESH_INTERVAL);

  const onSubmit = () => {
    // filter mentions deleted from comment text
    const mentionedUsersId = comment.includes(`<strong>${t('task_data_view.comments_tab.tag_all')}</strong>`)
      ? mentionedUsers
      : mentionedUsers.filter((userId, index) => {
          const user = users[userId];
          if (!user || !user?.fullName) {
            return false;
          }

          // remove duplicated ids
          if (mentionedUsers.indexOf(userId) !== index) {
            return false;
          }

          return comment.includes(user?.fullName);
        });

    const commentData = {
      processInstanceId: bpmTask.processInstanceId as string,
      author: profileId,
      text: DOMPurify.sanitize(comment, purifyOptions),
      date: new Date(),
      initiatorOfTask: bpmTask.initiator as string,
      taggedUsers: mentionedUsersId,
      approveStage: '',
      processStep: bpmTask.stepSysName,
    };

    if (validate()) {
      setLoading(true);

      try {
        sendComment(commentData)
          .then((newComment) => {
            const newCommentsList = [...comments, newComment];
            setComments(newCommentsList);
            setDateGroupedComments(groupCommentsByDate(newCommentsList));
            setComment(() => '');
            commentsScrollbarsRef?.current?.scrollToBottom();

            NotificationManager.success(t('notifications.commented_success'), `${t('success_messages.success')}!`);
          })
          .catch((err) => {
            setError(err.message);
          });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleFormSubmit = handleSubmit(onSubmit);

  const handleCommentInput = (commentText) => {
    if (!commentText) return;
    const mentionsSearchResult = [...commentText.matchAll(MENTION_SEARCH_REGEX)];
    const mentionsResult = [...commentText.matchAll(MENTION_REGEX)];
    const isActiveMention = !!mentionsSearchResult.length;
    // empty active mention is just @
    const isEmptyActiveMention = mentionsSearchResult.length - mentionsResult.length === 1;
    const activeMentionText =
      !isEmptyActiveMention && mentionsResult.length
        ? mentionsResult[mentionsResult.length - 1][0].trim().replace('<', '').replace('>', '')
        : '';

    if (isActiveMention) {
      showFilteredUsersList(activeMentionText);
    } else {
      setUsersListShown(false);
    }
    setComment(commentText);
  };

  const closeUsersList = useCallback(() => setUsersListShown(false), []);

  return {
    loading,
    error,
    dateGroupedComments,
    filteredUsers,
    isUsersListShown,
    isCommentEmpty,
    commentsScrollbarsRef,
    foundMention,
    fullMention,
    setFoundMention,
    setFullMention,
    setCommentEmpty,
    handleUserSelect,
    handleCommentInput,
    handleFormSubmit,
    closeUsersList,
    showFilteredUsersList,
  };
};

export default useTaskComments;
