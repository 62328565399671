import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';

import { Spinner, NoData, ContentMenu } from 'components';
import { TemplateItem } from './TemplateItem';
import { DeletionDialog } from './DeletionDialog';
import { OutdatedDialog } from './OutdatedDialog';
import { CreationDialog } from './CreationDialog';

import useTemplates from './useTemplates';

import useStyles from '../useStyles';
import { useTranslation } from 'react-i18next';

export const Templates = () => {
  const { t } = useTranslation();

  const {
    myTemplates,
    loading,
    creating,
    error,
    handleTemplateDeletion,
    startDialogOpen,
    createProcessFromTemplate,
    handleProcessStartClick,
    hideCreationDialog,
    openDeleteDialog,
    hideDeleteDialog,
    hideOutdatedDialog,
    deleteDialogOpen,
    deleting,
    deleteDialogTemplateId,
    outdateDialogOpen,
    template,
    tabsList,
    activeTab,
    setActiveTab
  } = useTemplates();

  const classes = useStyles();

  if (loading) return <Spinner />;

  if (myTemplates && myTemplates.length) {
    return (
      <>
        <Box component="header" mb={5}>
          <Box mb={3}>
            <Typography variant="h2" className={classes.sectionTitle}>
              {t('Processes.new_request_templates')}
            </Typography>
          </Box>
        </Box>

        {error && <Typography color="error">{error}</Typography>}

        {creating && <Spinner absolute />}

        <Box mb={3.5}>
          <ContentMenu
            menuList={tabsList}
            onClick={setActiveTab}
            activeTab={activeTab}
            templatesVariant
          />
        </Box>

        <Grid container spacing={2}>
          {myTemplates.map((template) => (
            <Grid item key={template.id} md={4} sm={5} xl={3}>
              <TemplateItem
                template={template}
                onDelete={openDeleteDialog}
                handleCreate={handleProcessStartClick}
              />
            </Grid>
          ))}
        </Grid>

        <DeletionDialog
          open={deleteDialogOpen}
          onCancel={hideDeleteDialog}
          onDelete={handleTemplateDeletion}
          deleting={deleting}
          templateId={deleteDialogTemplateId}
        />

        <CreationDialog
          open={startDialogOpen}
          onCancel={hideCreationDialog}
          onStart={createProcessFromTemplate}
          templateName={template?.title}
          processName={t(`constructor-${template?.processSysName}.name`, {defaultValue: template?.processDefinitionName})}
        />

        <OutdatedDialog
          open={outdateDialogOpen}
          onClose={hideOutdatedDialog}
          processName={t(`constructor-${template?.processSysName}.name`, {defaultValue: template?.processDefinitionName})}
        />
      </>
    );
  }

  return (
    <Box mt={8}>
      <NoData text={t('Processes.new_request_templates_empty')} Icon={InsertDriveFileOutlinedIcon} />
    </Box>
  );
}

export default Templates;
