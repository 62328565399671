import moment from 'moment';

import { getOrganizations } from 'api/requests/document-sign.api';
import { environment } from '../../../../../../environments/environment';

const getCleanCompanyName = company =>
  company
    .replace(/[^A-Za-z]/g, '')
    .trim()
    .toUpperCase();

const getSelectedOrganization = ({
  isInternalSignEmployeeInitiator,
  processCompanyName,
  companyBin,
  edsBin,
  organizations
}) => {
  if (isInternalSignEmployeeInitiator) {
    const companyNameBeatified = getCleanCompanyName(processCompanyName);

    const selectedOrganization = organizations.find(
      organization => companyNameBeatified === getCleanCompanyName(organization.Name)
    );

    if (selectedOrganization) {
      return selectedOrganization;
    }

    return organizations.find(organization => organization.Bin === companyBin);
  }

  if (processCompanyName) {
    const companyNameBeatified = getCleanCompanyName(processCompanyName);

    return organizations.find(
      ({ Name, Bin }) => companyNameBeatified === getCleanCompanyName(Name) && Bin === edsBin
    );
  }

  return organizations.find(organization => organization.Bin === edsBin);
};

export const getPointInstance = ({
  isInternalSignEmployeeInitiator,
  organizations,
  companyBin,
  edsBin,
  processCompanyName,
  handleBinError = () => {}
}) => {
  const selectedOrganization = getSelectedOrganization({
    isInternalSignEmployeeInitiator,
    processCompanyName,
    companyBin,
    edsBin,
    organizations
  });

  if (!selectedOrganization) {
    handleBinError();
  }

  const { Id, Name } = selectedOrganization;

  const modifiedName = Name.replace(/"/g, "'");
  const pointName = `Подписание ${modifiedName}`;
  const pointDescription = `Подписание ${modifiedName}`;

  return {
    organizationId: Id,
    pointName,
    pointDescription
  };
};

export const getPointSignatureInstance = () => {
  const currentDate = moment(new Date()).format('DD.MM.YYYY HH:mm:ss');
  const pointSignatureName = `Подписание ${currentDate}`;
  const pointSignatureDescription = `Подписание ${currentDate}`;

  return { pointSignatureName, pointSignatureDescription };
};

export const getSigningInstance = (summary?: string) => {
  const signingName = summary || 'Подписание';
  const signingDescription = summary || 'Подписание';

  return { signingName, signingDescription };
};

export const getOrganizationList = async ({
  userModelId,
  handleOrganizationsEmptyError = () => {},
  handleOrganizationsFetchError = () => {}
}) => {
  try {
    const response = await getOrganizations(userModelId);
    if (!Object.prototype.hasOwnProperty.call(response, 'Organizations')) {
      handleOrganizationsEmptyError();
    }

    const { Organizations } = response;
    if (Organizations.length === 0) {
      handleOrganizationsEmptyError();
    }

    return Organizations;
  } catch (e) {
    handleOrganizationsFetchError();
  }
};

export const getBinFromEDSSubject = ({
  isInternalSignEmployeeInitiator,
  individualNumber,
  subject,
  handleError = () => {}
}) => {
  if (isInternalSignEmployeeInitiator) {
    return { edsBin: individualNumber };
  }

  const { iin, bin } = subject;

  if (!bin && !iin) {
    handleError();
  }

  if (!bin && iin) {
    return { edsBin: iin };
  }

  return { edsBin: bin };
};
