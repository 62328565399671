import React, { useMemo, useCallback, useEffect, useState, Suspense } from 'react';
import cn from 'classnames';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ContentEditable from 'react-contenteditable';
import { useDispatch } from 'react-redux';
import { ErrorOutline } from '@mui/icons-material';
import { useFormContext } from 'react-hook-form';

import noAttachmentsImage from 'assets/images/icons/no-attachments.svg';
import NoFieldsIcon from 'assets/images/icons/no-fields.svg';
import DisabledInfoCard from 'assets/images/icons/disabled-info-card.svg';
import TakeOnTaskIcon from 'assets/images/icons/can-take-on-task.svg';

import Accordion from 'components/Accordion/Accordion';
import { DocflowDocumentInfo } from 'components/DocflowDocumentInfo/DocflowDocumentInfo';
import { Spinner } from 'components/Spinner';
import { useUserProfile } from 'hooks';
import { FormField } from 'pages/Task/TaskForm/FormFields';
import { JobOffer } from 'pages/Task/TaskForm/JobOffer';
import { UrgentRequest } from 'pages/Task/TaskForm/UrgentRequest';
import { WATCHERS_LIMIT } from 'pages/Task/constants';
import { UserList } from 'pages/Task/TaskForm/FormFields/Fields';
import { useSelectedTask, useSelectedTaskWatchers } from 'store/approvals';
import { setTaskComponentsDisabled, useTaskState } from 'store/requests';
import { Docflow, TaskParametersType, UserType } from 'types';
import { getAttributeNames } from 'utils/general';

import {
  ATTACHMENTS_TAB_NAME,
  ATTACHMENTS_COMPONENTS,
  INFORMATION_TAB_NAME,
  LINKS_TAB_NAME,
  LINK_FIELD_COMPONENT,
  HIDDEN_FIELD_COMPONENT,
} from './constants';
import useTaskDocuments from '../DocumentsTab/useTaskDocuments';
import useStyles from './useStyles';

const DocumentDetails = React.lazy(async () => ({
  default: (await import('@dar-dms/utils')).DocumentDetails,
}));

type Props = {
  className: string;
  isCurrentUserWatcher: boolean;
  isCurrentUserAssignee: boolean;
  handleWatchersSelect: (selectedWatchersIdList: string[], selectedWatcherObjectList: UserType[]) => void;
  tabName: string;
  setActiveTab: (tabName: string) => void;
  setButtonDisabled: (value: boolean) => void;
  setAlwaysActiveButtons: (value: string[]) => void;
  setTabCounter: (tabName: string, value: number | null) => void;
  handleAction?: any;
  stepDescription?: string;
  disableRenderInformationTab?: boolean;
};

export const FormTab = ({
  className,
  disableRenderInformationTab,
  handleAction,
  handleWatchersSelect,
  isCurrentUserWatcher = false,
  isCurrentUserAssignee = false,
  setActiveTab,
  setAlwaysActiveButtons,
  setButtonDisabled,
  setTabCounter,
  stepDescription,
  tabName,
}: Props) => {
  const selectedTaskGlobal = useSelectedTask();
  const classes = useStyles();
  const { t } = useTranslation();
  const { data: bpmTask, disabledComponentsList } = useTaskState();
  const { id: userId } = useUserProfile();
  const selectedTaskWatchers = useSelectedTaskWatchers();
  const dispatch = useDispatch();
  const { hasPublishedDocuments } = useTaskDocuments({
    fetchDocflowDocuments: false,
    formMethods: {},
    goToPreviousStep: () => {},
  });
  const {
    formState: { errors },
  } = useFormContext();

  const [attachmentsCounts, setAttachmentsCounts] = useState<{ [key: string]: number }>({});
  const [totalAttachmentsCount, setTotalAttachmentsCount] = useState<number>(0);
  const [onlyReadonlyAttachments, setOnlyReadonlyAttachments] = useState<boolean>(false);
  const [documentToPreview, setDocumentToPreview] = useState<number | null>(null);
  const userCanTakeOnATask = useMemo(() => bpmTask.candidateUsers && (!bpmTask.assignee || bpmTask.assignee.trim() === ''), [bpmTask]);

  const watcherIds = useMemo(() => {
    if (!selectedTaskWatchers) return '';
    const ids = selectedTaskWatchers.map((watcher) => watcher.id);
    return ids.join();
  }, [selectedTaskWatchers]);

  const setAttachmentsCountFormField = useCallback(
    (fieldName, value) => {
      const newAttachmentsCounts = {
        ...attachmentsCounts,
        [fieldName]: value,
      };

      setAttachmentsCounts(newAttachmentsCounts);
    },
    [setAttachmentsCounts]
  );

  const isInformationTab = useMemo(() => tabName === INFORMATION_TAB_NAME, [tabName]);
  const isAttachmentsTab = useMemo(() => tabName === ATTACHMENTS_TAB_NAME, [tabName]);
  const isJobOffer = useMemo(() => bpmTask.businessEntity.sysName === 'JO-3_ver2', [bpmTask]);
  const isComponentInAttachmentTab = useCallback((component) => ATTACHMENTS_COMPONENTS.includes(component), []);
  const isComponentALinkInLinksTab = (componentParams) => {
    const parsedComponentParams = componentParams ? JSON.parse(componentParams) : {};
    return !!parsedComponentParams?.showInLinksTab;
  };
  const isComponentInLinksTab = useCallback(({ component, componentParams }) => {
    if (component !== LINK_FIELD_COMPONENT) {
      return false;
    }
    return isComponentALinkInLinksTab(componentParams);
  }, []);
  const isComponentInTabInfo = useCallback((tabInfo, tabKey, { component, name, componentParams }) => {
    return (
      tabInfo &&
      !(
        (tabKey === INFORMATION_TAB_NAME && ATTACHMENTS_COMPONENTS.includes(component)) ||
        (component === LINK_FIELD_COMPONENT && isComponentALinkInLinksTab(componentParams))
      ) &&
      tabInfo.attributes.findIndex((attribute) => attribute.name === name) !== -1
    );
  }, []);
  const groupsByTabs = useMemo(() => {
    const tabsGroups = {};
    const formTabs = [
      ...bpmTask.taskDetailsTabs,
      { description: ATTACHMENTS_TAB_NAME, attributes: [] },
      { description: LINKS_TAB_NAME, attributes: [] },
    ];

    for (const tab of formTabs) {
      const tabKey = tab.description.toLowerCase();
      const filteredGroups = {};
      const groupsListKeys = Object.keys(bpmTask.currentStateGroups);

      groupsListKeys.forEach((groupListKey) => {
        filteredGroups[groupListKey] = bpmTask.currentStateGroups[groupListKey].filter(
          (field) =>
            isComponentInTabInfo(tab, tabKey, field) ||
            (tabKey === ATTACHMENTS_TAB_NAME && isComponentInAttachmentTab(field.component)) ||
            (tabKey === LINKS_TAB_NAME && isComponentInLinksTab(field))
        );
      });

      tabsGroups[tabKey] = filteredGroups;
    }

    return tabsGroups;
  }, [bpmTask, bpmTask.attributes]);

  const areAllFieldsHidden = useMemo(() => {
    const informationTabGroups = groupsByTabs[INFORMATION_TAB_NAME] || {};
    const informationTabGroupsAttributes = (Object.values(informationTabGroups) || []).flat();
    const allFieldsComponents = informationTabGroupsAttributes?.map((attribute) => (attribute as TaskParametersType)?.component) || [];
    return allFieldsComponents.every((component) => component === HIDDEN_FIELD_COMPONENT);
  }, [groupsByTabs]);

  const updateAttachmentsTabCounter = useCallback(
    (groupsListKeys) => {
      const attachmentsTabFilteredGroups = {};
      groupsListKeys.forEach((groupListKey) => {
        attachmentsTabFilteredGroups[groupListKey] = bpmTask.currentStateGroups[groupListKey].filter(({ component }) =>
          ATTACHMENTS_COMPONENTS.includes(component)
        );
      });
      const attachmentsComponents = Object.values(attachmentsTabFilteredGroups).flat() as { [key: string]: any };

      const areAllAttachmentsReadonly = attachmentsComponents.every(({ componentParams }) => {
        try {
          const parsedComponentParams = JSON.parse(componentParams);
          return !!parsedComponentParams?.readOnly;
        } catch (error) {
          return false;
        }
      });
      setOnlyReadonlyAttachments(areAllAttachmentsReadonly);

      const attachmentsCount = attachmentsComponents.reduce((counter: number, component: { value: string | any[]; component: string }) => {
        if (component.component !== 'file') {
          return counter;
        }

        const arrayValue = typeof component.value === 'string' ? component.value.split(',') : (component.value as any[]);
        const arrayValueWithoutDuplicates = arrayValue.reduce((arr, v) => (arr.includes(v) ? arr : [...arr, v]), []);

        return counter + arrayValueWithoutDuplicates.length;
      }, 0) as number;

      const linkedInstancesAttachmentsCount = Object.values(attachmentsCounts).reduce((acc: number, v: number) => acc + +v, 0) as number;

      setTotalAttachmentsCount(attachmentsCount + linkedInstancesAttachmentsCount);
      setTabCounter(ATTACHMENTS_TAB_NAME, attachmentsCount + linkedInstancesAttachmentsCount);
    },
    [setTabCounter]
  );

  const updateLinksTabCounter = useCallback(
    (groupsListKeys) => {
      const linksTabFilteredGroups = {};
      groupsListKeys.forEach((groupListKey) => {
        linksTabFilteredGroups[groupListKey] = bpmTask.currentStateGroups[groupListKey].filter(({ component, componentParams }) => {
          if (component !== LINK_FIELD_COMPONENT) {
            return false;
          }

          const parsedParams = componentParams ? JSON.parse(componentParams) : {};
          return !!parsedParams?.showInLinksTab;
        });
      });
      const linksComponentsCount = Object.values(linksTabFilteredGroups).flat().length;

      setTabCounter(LINKS_TAB_NAME, linksComponentsCount);
    },
    [setTabCounter]
  );

  useEffect(() => {
    const groupsListKeys = Object.keys(bpmTask.currentStateGroups).filter((groupKey) => !groupKey.includes('#hidden'));
    updateAttachmentsTabCounter(groupsListKeys);
  }, [attachmentsCounts]);

  const watcherNotAssignee = useMemo(() => (userCanTakeOnATask || isCurrentUserWatcher) && !isCurrentUserAssignee, [
    userCanTakeOnATask,
    isCurrentUserWatcher,
    isCurrentUserAssignee,
  ]);

  useEffect(() => {
    const groupsListKeys = Object.keys(bpmTask.currentStateGroups).filter((groupKey) => !groupKey.includes('#hidden'));
    updateLinksTabCounter(groupsListKeys);
    if ((userCanTakeOnATask || isCurrentUserWatcher) && !isCurrentUserAssignee) {
      const attributeNames = getAttributeNames(groupsByTabs);
      dispatch(setTaskComponentsDisabled(attributeNames));
    }
  }, [bpmTask, watcherNotAssignee, isCurrentUserAssignee]);

  const controlAttachmentFormFieldAccessibility = (attribute) => {
    try {
      const isAttachmentField = attribute.component === 'file';
      if (isAttachmentField && watcherNotAssignee) {
        const componentParams = JSON.parse(attribute.componentParams || {});
        componentParams.readOnly = true;
        attribute.componentParams = JSON.stringify(componentParams);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const showWatchers = useMemo(() => {
    const isInitialSteps = bpmTask.isFirstStep || bpmTask.isRework || bpmTask.isCancelled;
    const isNotTakeOnATask = bpmTask.assignee.trim() !== '';
    return bpmTask?.initiator === userId && isNotTakeOnATask && isInitialSteps;
  }, [bpmTask, userId]);

  const docflowDocumentsWithPayments: Docflow.ProcessDocument[] = useMemo(
    () =>
      bpmTask?.entity?.taskInstance?.docFlowDocuments?.filter((doc) => doc.payments?.contractValue || doc.payments?.paymentBalance) || [],
    [bpmTask?.entity?.taskInstance]
  );

  const renderTabForm = useCallback(
    (groupsList, tab) => {
      const groupNameOldTranslationKeyPrefix = `constructor-${bpmTask.processSysName}.states.${bpmTask.stepSysName}.fieldGroups.`;
      const groupNameTranslationKeyPrefix = `constructor-${bpmTask.processSysName}.fieldGroups.`;

      if (isInformationTab && areAllFieldsHidden && !showWatchers) {
        return (
          <Box key={'no-fields'} className={classes.formGroup}>
            <Box className={classes.noFieldsBlock}>
              <img src={NoFieldsIcon} alt="" />
              <Typography component="h3">{t('task_data_view.no_fields.title')}</Typography>
              <Typography component="p">{t('task_data_view.no_fields.description')}</Typography>
            </Box>
          </Box>
        );
      }

      if (!isInformationTab) {
        return (
          <>
            {Object.keys(groupsList).map((groupName) => (
              <Box
                key={groupName}
                className={cn(classes.formGroup, {
                  [classes.hiddenInTabField]: !groupsList[groupName]?.length || groupName.includes('#hidden') || tab !== tabName,
                })}
              >
                <Box flex="1">
                  {groupsList[groupName]
                    .sort((a, b) => a.state_order - b.state_order)
                    .map((attribute) => (
                      <div
                        className={cn({
                          [classes.hiddenInTabField]: !groupsList[groupName]?.find((field) => field.name === attribute.name),
                        })}
                      >
                        {controlAttachmentFormFieldAccessibility(attribute)}
                        <FormField
                          attribute={attribute}
                          handleAction={handleAction}
                          isTaskDetailsVariant
                          key={attribute.id}
                          setActiveTab={setActiveTab}
                          setAlwaysActiveButtons={setAlwaysActiveButtons}
                          setButtonDisabled={setButtonDisabled}
                          tabName={tabName}
                          updateAttachmentsCounter={setAttachmentsCountFormField}
                        />
                      </div>
                    ))}
                </Box>
              </Box>
            ))}
          </>
        );
      }

      return (
        <>
          {Object.keys(groupsList).map((groupName) => {
            let editableFields = [];
            let readonlyFields = [];
            let hiddenFields = [];
            groupsList[groupName]
              .sort((a, b) => a.state_order - b.state_order)
              .forEach((attribute) => {
                const isDisabled = disabledComponentsList.includes(attribute?.name);
                if (!attribute.component.includes('hidden') && !attribute.component.includes('readonly') && !isDisabled) {
                  editableFields.push(attribute);
                } else if (attribute.component.includes('readonly') || isDisabled) {
                  readonlyFields.push(attribute);
                } else {
                  hiddenFields.push(attribute);
                }
              });
            return (
              <Box
                key={groupName}
                className={cn(classes.formGroup, {
                  [classes.hiddenInTabField]: !groupsList[groupName]?.length || groupName.includes('#hidden') || tab !== tabName,
                })}
              >
                {editableFields.length ? (
                  <Accordion title={t("DocumentProcessFromDocflow.NeedAction")} isOpen>
                    {editableFields.map((attribute) => (
                      <div
                        className={cn({
                          [classes.hiddenInTabField]: !groupsList[groupName]?.find((field) => field.name === attribute.name),
                        })}
                      >
                        {controlAttachmentFormFieldAccessibility(attribute)}
                        <FormField
                          attribute={attribute}
                          handleAction={handleAction}
                          isTaskDetailsVariant
                          key={attribute.id}
                          setActiveTab={setActiveTab}
                          setAlwaysActiveButtons={setAlwaysActiveButtons}
                          setButtonDisabled={setButtonDisabled}
                          tabName={tabName}
                          updateAttachmentsCounter={setAttachmentsCountFormField}
                        />
                      </div>
                    ))}
                  </Accordion>
                ) : null}
                {readonlyFields?.length > 0 ? (
                  <Accordion
                    title={t(groupNameOldTranslationKeyPrefix + groupName.toLowerCase().replaceAll(' ', '-'), {
                      defaultValue: t(groupNameTranslationKeyPrefix + groupName.toLowerCase().replaceAll(' ', '-'), {
                        defaultValue: groupName,
                      }),
                    })}
                    isOpen
                  >
                    {isInformationTab && stepDescription && (
                      <Box display="flex" flexDirection="column" flex="1" alignItems="baseline" mb={1}>
                        <span className={classes.labelStepDescription}>
                          {t('customProcesses.creationPage.processForm.fields.stepDescription')}
                        </span>
                        <Box>
                          <Typography className={classes.comment}>
                            <ContentEditable disabled html={stepDescription} onChange={() => null} />
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    {readonlyFields.map((attribute) => (
                      <div
                        className={cn({
                          [classes.hiddenInTabField]: !groupsList[groupName]?.find((field) => field.name === attribute.name),
                        })}
                      >
                        {controlAttachmentFormFieldAccessibility(attribute)}
                        <FormField
                          attribute={attribute}
                          handleAction={handleAction}
                          isTaskDetailsVariant
                          key={attribute.id}
                          setActiveTab={setActiveTab}
                          setAlwaysActiveButtons={setAlwaysActiveButtons}
                          setButtonDisabled={setButtonDisabled}
                          tabName={tabName}
                          updateAttachmentsCounter={setAttachmentsCountFormField}
                        />
                      </div>
                    ))}
                  </Accordion>
                ) : null}
                {groupsList[groupName].find(({ name }) => name.includes('documentFromDocflow-')) && docflowDocumentsWithPayments.length > 0 ? (
                  <Accordion title={t('DocumentProcessFromDocflow.DocumentsInformation')} isOpen>
                    {docflowDocumentsWithPayments.map((doc: Docflow.ProcessDocument, i) => (
                      <DocflowDocumentInfo
                        document={doc}
                        key={doc.id}
                        noBorder={i === docflowDocumentsWithPayments.length - 1}
                        sum={groupsList[groupName]?.find(({ name }) => name.includes('sum-'))?.value}
                        currency={groupsList[groupName]?.find(({ name }) => name === 'sumCurrency')?.value}
                        // onOpen={() => setDocumentToPreview(doc.id)}
                      />
                    ))}
                  </Accordion>
                ) : null}
                {hiddenFields.map((attribute) => (
                  <div
                    className={cn({
                      [classes.hiddenInTabField]: !groupsList[groupName]?.find((field) => field.name === attribute.name),
                    })}
                  >
                    {controlAttachmentFormFieldAccessibility(attribute)}
                    <FormField
                      attribute={attribute}
                      handleAction={handleAction}
                      isTaskDetailsVariant
                      key={attribute.id}
                      setActiveTab={setActiveTab}
                      setAlwaysActiveButtons={setAlwaysActiveButtons}
                      setButtonDisabled={setButtonDisabled}
                      tabName={tabName}
                      updateAttachmentsCounter={setAttachmentsCountFormField}
                    />
                  </div>
                ))}
              </Box>
            );
          })}
        </>
      );
    },
    [tabName, stepDescription, watcherIds, errors]
  );

  const renderForm = useMemo(() => {
    if (isAttachmentsTab && totalAttachmentsCount === 0 && onlyReadonlyAttachments) {
      return (
        <Box className={classes.emptyAttachmentsMessageWrapper}>
          <img src={noAttachmentsImage} alt="" />

          <Typography component="h3">{t('task_data_view.attachments_tab.empty_tab_message')}</Typography>

          <Typography component="p">{t('task_data_view.attachments_tab.empty_tab_message_description')}</Typography>
        </Box>
      );
    }

    if (disableRenderInformationTab) {
      delete (groupsByTabs as any).information;
    }

    return Object.keys(groupsByTabs).map((formTabKey) => renderTabForm(groupsByTabs[formTabKey] || {}, formTabKey));
  }, [
    groupsByTabs,
    tabName,
    disableRenderInformationTab,
    totalAttachmentsCount,
    isAttachmentsTab,
    onlyReadonlyAttachments,
    bpmTask,
    stepDescription,
    watcherIds,
    errors,
  ]);

  const closeDocumentPreview = useCallback(() => setDocumentToPreview(null), []);

  return (
    <Box className={classes.FormTab + ' ' + className}>
      {isInformationTab && Object.entries(errors)?.length ? (
        <div className={classes.validation}>
          <ErrorOutline style={{ fontSize: 24, color: '#D6331F' }} />
          <div>
            <Typography className={classes.validationTitle}>{t('DocumentProcessFromDocflow.ApprovalActions')}</Typography>
            <Typography className={classes.validationSubTitle}>{t('DocumentProcessFromDocflow.FillRequiredFields')}</Typography>
          </div>
        </div>
      ) : null}
      {disabledComponentsList?.length > 0 && hasPublishedDocuments && isInformationTab && (
        <Box className={classes.disabledFieldsInfoCard}>
          <img src={DisabledInfoCard}/>
          <Box className={classes.disabledFieldsInfoCardContent}>
            <p>{t('task_data_view.disabled_documents_fields_message_title')}</p>
            <span>{t('task_data_view.disabled_documents_fields_message')}</span>
          </Box>
        </Box>
      )}

      {userCanTakeOnATask && (
        <Box className={classes.disabledFieldsInfoCard}>
          <img src={TakeOnTaskIcon}/>
          <Box className={classes.disabledFieldsInfoCardContent}>
            <p>{t('task_data_view.take_on_task_fields_message_title')}</p>
            <span>{t('task_data_view.take_on_task_fields_message')}</span>
          </Box>
        </Box>
      )}

      {isInformationTab && (
        <Box component="section">
          <UrgentRequest />
        </Box>
      )}

      {renderForm}

      {isInformationTab && showWatchers && !bpmTask?.isCompleted && (
        <div style={{ marginTop: 12, padding: '8px 20px' }}>
          <Typography variant="h5" className={classes.formGroupTitle}>
            {t('Watchers.processWatchers')}
          </Typography>
          <Typography variant="body1" style={{ marginBottom: 4 }}>
            {t('Watchers.watchers')} ({selectedTaskGlobal?.selectedUsersId.length}/{WATCHERS_LIMIT})
          </Typography>
          <UserList
            clearSelectedOnComponentUnmount
            handleUsersSelect={handleWatchersSelect}
            maxUsersCount={WATCHERS_LIMIT}
            name={'Name'}
            placeholder={t('Watchers.selectUsers')}
            selectedTask={selectedTaskGlobal}
            showWithoutDepartmentView
            value={''}
            withoutInitiator
          />
        </div>
      )}

      {isInformationTab && isJobOffer && bpmTask.currentAction.sysName === 'jo-jo_ver2' && <JobOffer loading={false} />}

      <Suspense fallback={<Spinner absolute />}>
        {documentToPreview && <DocumentDetails id={documentToPreview} isPreview onClose={closeDocumentPreview} />}
      </Suspense>
    </Box>
  );
};
