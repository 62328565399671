import { environment } from 'environments/environment';

const { env, baseUrl, appUrl } = environment;

export const PAPER_WIDTH = 595;
export const PAPER_HEIGHT = 842;
export const PADDING_X = 44;
export const PADDING_Y = 40;
export const FOOTER_HEIGHT = 60;
export const FOOTER_TOP_MARGIN = 25;
export const CARD_WIDTH = PAPER_WIDTH - PADDING_X * 2;
export const CARD_BORDER_RADIUS = 8;
export const CARD_PADDING = 12;
export const CARD_HEADER_HEIGHT = 32;
export const PARALLEL_BRANCHES_CARD_MARGIN_LEFT = 32;
export const PARALLEL_BRANCHES_CARD_MARGIN_RIGHT = 12;
export const PARALLEL_BRANCHES_CARD_PADDING = 8;
export const PARALLEL_BRANCHES_GAP = 16;
export const FIELD_GAP = 4;
export const FIELD_HINT_WIDTH = 120;
export const FIELD_VALUE_WIDTH = 340;
export const FIELD_HINT_VALUE_GAP = 12;
export const DOCUMENT_NAME_WIDTH = 460;
export const HISTORY_CONDITION_CARD_PADDING = 8;
export const COLORS = {
  TEXT_DARK_GRAY: '#101F2B',
  TEXT_LINK: '#007994',
  TEXT_LIGHT_GREY: '#6D6E85',
  TEXT_VERY_LIGHT_GRAY: '#8B8C9E',
  BORDER_DARK_GREY: '#262842',
  BORDER_LIGHT_GREY: '#BCBCCF',
  FLOW_TIMELINE: '#8B8C9E',
  WHITE: '#FFFFFF',
  LOGO_PLACEHOLDER_BACKGROUND: '#497CF6',
  USER_AVATAR_PLACEHOLDER_BACKGROUND: '#976AD1',
  PARALLEL_BRANCHES_BACKGROUND: '#F7F7F8',
  PARALLEL_BRANCHES_BORDER: '#BCBCCF',
}
export const FONTS = {
  400: 'Euclid Circular B Regular',
  500: 'Euclid Circular B Medium',
  600: 'Euclid Circular B SemiBold'
}

export const PROCESS_LINK_TEMPLATE = (env === 'PROD' ? appUrl : baseUrl)  + '/processes/process/';
export const DOCUMENT_LINK_TEMPLATE = (env === 'PROD' ? appUrl : baseUrl)  + '/docflow/documents/';
export const WORKSPACE_PARAM = '?workspaceId=';

export const HIDDEN_COMPONENTS = ['hidden-field', 'file', 'entities-list', 'check-status', 'edsFinalDocuments', 'modal', 'action-button', 'supplier-voting-results', 'supplier-voting-toggle', 'rejection-reason', 'template-viewer', 'company-select'];

export const APPROVE_STAGES_TYPES = {
  CURRENT_STEP: 'task_history.current_step',
  FUTURE_STEP: 'task_history.future_step',
  DEFAULT_STEP: 'task_history.approve',
  CANCELLED_STEP: 'task_statuses.stopped',
};
