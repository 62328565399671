import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  root: {
    border: '2px solid gray',
    width: '800px',
    backgroundColor: '#4F5457',
  },
  offerDocument: {
    margin: '15px 5px',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  logo: {
    width: 100,
    marginHorizontal: 'auto',
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4)
  },
  createDate: {
    textAlign: 'center',
    marginBottom: theme.spacing(4)
  },
  content: {
    width: '90%',
    padding: theme.spacing(1, 0),
    marginBottom: 8,
    borderTop: '2px solid #000',
    display: 'flex',
    flexDirection: 'column'
  },
  paragraph: {
    marginBottom: theme.spacing(2)
  },
  benefitList: {
    listStyle: 'none'
  },
  afterSectionParagraph: {
    paddingTop: theme.spacing(2),
    borderTop: '2px solid #000',
    marginBottom: theme.spacing(6)
  },
  sign: {
    paddingTop: theme.spacing(1),
    borderTop: '1px solid #ccc',
    width: '200px'
  },
  date: {
    paddingTop: theme.spacing(1),
    borderTop: '1px solid #ccc',
    width: '100px'
  },
  stamp: {
    width: '80px',
    alignSelf: 'flex-end',
    marginTop: theme.spacing(1)
  }
}));
