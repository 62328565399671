import { getHCMSUserById, getHCMSUserByLogName } from '../api/requests';
import { User } from '../models/User.model';

export const extractLetters = user => {
  const { firstName = '', lastName = '', fullName = '' } = user || {};
  let letters = [firstName?.[0], lastName?.[0]]
    .filter(Boolean)
    .join('')
    .toUpperCase();

  if (letters.length < 1) {
    letters = fullName
      .split(' ')
      .filter(Boolean)
      .map(name => name[0])
      .join('')
      .toUpperCase();
  }

  return letters;
};

export const compareUsers = (a, b) => {
  const userA = a.fullName.toUpperCase();
  const userB = b.fullName.toUpperCase();

  if (userA > userB) {
    return 1;
  }

  if (userA < userB) {
    return -1;
  }

  return 0;
};

const EMPTY_COMPANY = {
  id: '',
  holdingId: '',
  bin: '',
  organization: {
    name: '',
    shortName: ''
  }
}

// TODO: Подумать насчет связки хука useUserProfile и данной функции
export const getProfileCompanyDataFromLocalStorage = () => {
  try {
    const companyInfoJson = localStorage.getItem('workspace');
    return companyInfoJson ? JSON.parse(companyInfoJson) : EMPTY_COMPANY;
  } catch (error) {
    return EMPTY_COMPANY;
  }
};

// TODO: Придумать лучше решение
export const getUserDataByIdOrLogName = async (userKey: string, companyId = '') => {
  const isId = userKey.includes('-');

  if (isId) {
    const userData = await getHCMSUserById(userKey, companyId);
    return userData ? User.from(userData).data() : null;
  } else {
    const userData = await getHCMSUserByLogName(userKey);
    return userData ? User.from(userData).data() : null;
  }
};

export const limitStringLength = (str: string, limit: number) => str.length <= limit ? str : str.substring(0, limit) + '...';
