import { useEffect, useState } from "react"

import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';

import { StatusType } from 'types';
import { getAdminPanelRequests } from "api/requests";

import { AdminPanelRow } from '../components/AdminPanelTable/AdminPanelTable.types'
import { AdminPanelGetRequestsArgs, AdminPanelRequestsFilterValues } from '../AdminPanel.types'
import { statuses } from "../components/Filter";

// get users by old name

type Props = {
  filterValues: AdminPanelRequestsFilterValues,
  setFilterValues: (value: Partial<AdminPanelRequestsFilterValues>) => void
}

export const useAdminPanelRequest = ({filterValues, setFilterValues}: Props) => {
  const [requests, setRequests] = useState<AdminPanelRow[]>([]);
  const [loadingStatus, setLoadingStatus] = useState<StatusType>(StatusType.PENDING);
  const [totalElements, setTotalElements] = useState<number>(0)
  const [totalPages, setTotalPages] = useState<number>(0)
  const [abortController, setAbortController] = useState<AbortController>()

  const getRequests = async () => {
    setLoadingStatus(StatusType.PENDING)

    try {
      abortController?.abort()
      const controller = new AbortController()
      setAbortController(controller)

      let omittedEmptyFilterValues = omitBy(filterValues, (value, key) => {
        return isNull(value) || key === 'processes' || (Array.isArray(value) && value.length === 0) || (key === "searchText" && isEmpty(value))
      }) as AdminPanelGetRequestsArgs

      if(omittedEmptyFilterValues.status in statuses){
        omittedEmptyFilterValues = {
          ...omittedEmptyFilterValues,
          ...statuses[omittedEmptyFilterValues.status].value,
          status: undefined // api does not need this key
        }
      }

      if(filterValues.processes){
        omittedEmptyFilterValues.processDefinitionName = Object.values(filterValues.processes).flat().map((p: {name: string}) => p.name)
      }


      const data = await getAdminPanelRequests(omittedEmptyFilterValues, controller.signal);

      setRequests(data.content)

      if(data.totalElements !== totalElements){
        setTotalElements(data.totalElements)
      }

      if(data.totalPages !== totalPages){
        setTotalPages(data.totalPages)
      }

      setLoadingStatus(StatusType.RESOLVED)
    } catch (error) {
      if(abortController?.signal.aborted){
        return
      }

      setLoadingStatus(StatusType.REJECTED)
    }
  }

  useEffect(() => {
    getRequests();
  }, [filterValues])

  useEffect(() => {
    if(loadingStatus === StatusType.RESOLVED && requests.length === 0 && totalElements !== 0){
      setFilterValues({
        pagination: {
          ...filterValues.pagination,
          page: 0
        }
      })
    }
  }, [loadingStatus, requests, totalElements])

  return {
    requests,
    loadingStatus,
    totalElements,
    totalPages,
    getRequests
  }
}
