import { useEffect, useMemo, useState, useCallback, useRef, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ProcessContext from 'contexts/ProcessContext';
import { deleteTemplateById, startProcessById } from 'api/requests';
import {
  useTemplatesState,
  getTemplatesAction,
  removeTemplateAction,
  setCreateRequestAction as setCreateRequest,
  setTemplateAction as setTaskTemplate,
  useProcessesListState,
} from 'store/requests';
import { ProcessType } from 'types';

const START_PROCESS_ERROR =
  'Server error. Failed to start a process from your template.';

const useBpmMyTemplates = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getProcessByName } = useContext(ProcessContext);

  const { data: processesList } = useProcessesListState();
  const { error: loadTemplatesError, data, loading } = useTemplatesState();

  const [startDialogOpen, setStartDialogOpen] = useState(false);
  const [tabsList, setTabsList] = useState([]);
  const [activeTab, setActiveTab] = useState<number | string>('All');
  const [creating, setCreating] = useState(false);
  const [error, setError] = useState('');
  const [outdateDialogOpen, setOutdateDialogOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteDialogTemplateId, setDeleteDialogTemplateId] = useState('');
  const [template, setTemplate] = useState(null);
  const [myTemplates, setMyTemplates] = useState([]);
  const cachedTemplates = useRef({});

  const sortData = useMemo(
    () =>
      data
        ? (data as Array<any>).sort(
            (a: any, b: any) =>
              new Date(b.businessInstanceDTO.dataCreate).getTime() -
              new Date(a.businessInstanceDTO.dataCreate as string).getTime()
          )
        : [],
    [data]
  );

  useEffect(() => {
    if (!data && !loading && !loadTemplatesError) {
      dispatch(getTemplatesAction());
    }
  }, [dispatch, data, loading, loadTemplatesError]);

  useEffect(() => {
    if (sortData) {
      setMyTemplates(sortData);
    }
  }, [sortData]);

  useEffect(() => {
    if (data && data.length && !tabsList.length) {
      const tabs = data.map((el) => el.processDefinitionName).sort();
      const uniqueTabsNames = [...new Set(tabs)];
      const tabsWithIcons = uniqueTabsNames.map((name) => {
        const tabProcess = processesList
          ? processesList.find((process) => process.name === name)
          : null;

        const tabIcon = tabProcess ? tabProcess.processIconPath : '';
        const label = t(`constructor-${tabProcess?.processSysName}.name`, {defaultValue: name});
        return {
          name,
          label,
          tabIcon,
        };
      });
      setTabsList([{ name: 'All', label: t('Processes.new_request_templates_all'), tabIcon: '' }, ...tabsWithIcons]);
    }
  }, [processesList, data, tabsList]);

  useEffect(() => {
    if (activeTab === 'All') {
      setMyTemplates(data);
    } else if (cachedTemplates.current[activeTab]) {
      setMyTemplates(cachedTemplates.current[activeTab]);
    } else {
      const templates = data.filter(
        (el) => el.processDefinitionName === activeTab
      );
      setMyTemplates(templates);
      cachedTemplates.current[activeTab] = templates;
    }
  }, [activeTab, data, cachedTemplates]);

  const handleTemplateDeletion = useCallback(
    (id: string) => {
      setDeleting(true);
      deleteTemplateById(id)
        .then(() => {
          const deletedTemplate = data.find((t) => t.id === id);

          setMyTemplates((templates) => templates.filter((t) => t.id !== id));
          dispatch(removeTemplateAction(id));

          const oldCachedProcessTemplates =
            cachedTemplates.current[deletedTemplate.processDefinitionName];
          let processTemplatesCount = 0;
          if (oldCachedProcessTemplates) {
            const newCachedProcessTemplates = oldCachedProcessTemplates.filter(
              (t) => t.id !== id
            );
            cachedTemplates.current[
              deletedTemplate.processDefinitionName
            ] = newCachedProcessTemplates;

            processTemplatesCount = newCachedProcessTemplates.length;
          } else {
            const templates = data.filter(
              (el) => el.processDefinitionName === activeTab
            );
            processTemplatesCount = templates.length;
          }

          if (processTemplatesCount === 0) {
            setActiveTab('All');
            setTabsList((tabs) =>
              tabs.filter(
                (tab) => tab.name !== deletedTemplate.processDefinitionName
              )
            );
          }

          setDeleteDialogOpen(false);
        })
        .finally(() => {
          setDeleting(false);
        });
    },
    [
      data,
      cachedTemplates,
      dispatch,
      setDeleting,
      setMyTemplates,
      setDeleteDialogOpen,
    ]
  );

  const createProcessFromTemplate = useCallback(async (): Promise<void> => {
    const {processDefinitionId, processDefinitionName, processSysName} = template;
    setStartDialogOpen(false);
    setCreating(true);

    const { creator, isTemplateProcess, integration } = getProcessByName(processDefinitionName);

    const variables = {};
    if (integration) {
      variables['integration'] = {
        'type': 'String',
        'value': integration,
      };
    }

    try {
      const taskId = await startProcessById({
        params: {
          creator,
          id: processDefinitionId,
          ...(isTemplateProcess && {processTemplate: true})
        },
        data: {
          processId: processSysName,
          processDefinitionName: processDefinitionName,
          variables
        }
      });

      setCreating(false);

      dispatch(
        setCreateRequest({
          id: taskId,
          name: processDefinitionName,
          isNewRequest: true
        })
      );
    } catch {
      setError(START_PROCESS_ERROR);
      setCreating(false);
    }
  }, [dispatch, template]);

  const handleProcessStartClick = useCallback(template => {
    dispatch(setTaskTemplate(template));
    setTemplate(template);

    try {
      const { id, camundaId }: ProcessType = getProcessByName(template.processDefinitionName);
      const isTemplateOutdated = ![id, camundaId].includes(template.processDefinitionId);

      if (isTemplateOutdated) {
        setOutdateDialogOpen(true);
        return;
      }

      setStartDialogOpen(true);
    } catch (error) {
      setOutdateDialogOpen(true);
    }
  }, [getProcessByName, dispatch]);

  const openDeleteDialog = (id: string) => {
    setDeleteDialogOpen(true);
    setDeleteDialogTemplateId(id);
  };

  const hideCreationDialog = () => {
    setStartDialogOpen(false);
    dispatch(setTaskTemplate(null));
  };

  const hideDeleteDialog = () => {
    setDeleteDialogOpen(false);
    dispatch(setTaskTemplate(null));
  };

  const hideOutdatedDialog = () => {
    setOutdateDialogOpen(false);
    dispatch(setTaskTemplate(null));
  };

  return {
    myTemplates,
    loading,
    error,
    creating,
    handleTemplateDeletion,
    startDialogOpen,
    createProcessFromTemplate,
    hideCreationDialog,
    openDeleteDialog,
    hideDeleteDialog,
    hideOutdatedDialog,
    deleteDialogOpen,
    deleting,
    deleteDialogTemplateId,
    handleProcessStartClick,
    outdateDialogOpen,
    template,
    tabsList,
    activeTab,
    setActiveTab,
  };
};

export default useBpmMyTemplates;
