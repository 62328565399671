import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeView, TreeItem } from '@mui/x-tree-view';
import { Box, Button, ClickAwayListener, LinearProgress, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { useDebounce } from 'hooks';
import {
  getAllHierarchyV2,
  getDirectoriesV2,
  getDirectoriesV2Value,
  getDirectoryByIdV2,
  getDirectoryInfo,
  getNestedDirectories,
  getValuesByNameFragment,
} from 'api/requests';

import { useTreeItemStyles, useTreeStyles, useInputStyles } from './TreeGlossary.styles';
import Styled from './TreeGlossary.styles';

import dropdownIndicatorIcon from 'assets/images/icons/dropdown-indicator.svg';
import { ProjectChildSelectProps } from 'pages/Task/TaskForm/types';

type Props = {
  name?: string;
  hint?: string;
  params?: { [key: string]: unknown };
  control: any;
  value: any;
  valueId?: string;
  disabledItemsIds?: string[];
  errors: any[] | {};
  showErrorText?: boolean;
  onSelect: (value: any) => void;
  withoutFormController?: boolean;
  isBuilderVariant?: boolean;
  isConditionModalVariant?: boolean;
  forceEnglish?: boolean;
  placeholder?: string;
  rules?: { [key: string]: any }
  isDisabled?: boolean;
}

enum TreeType {
  DIRECTORY,
  DIRECTORY_ITEM,
}

enum FetchingState {
  SUCCESS,
  ERROR,
  LOADING,
  IDLE,
}

const ENDPOINTS_TO_NAMES_MAP = {
  'projects': 'project',
};

const InfoBox = ({ children, ...rest }) => <Box width="100%" height={40} pt={4} px={2}
                                                pb={1} {...rest}>{children}</Box>;

const ProjectChildSelect: FC<ProjectChildSelectProps> = (props) => {
  const classes = useTreeItemStyles();
  const treeClasses = useTreeStyles();
  const { t, i18n } = useTranslation();
  const [childNodes, setChildNodes] = useState(null);
  const [expanded, setExpanded] = React.useState([]);
  const [fetchingState, setFetchingState] = useState(FetchingState.IDLE);
  const childType = props.isBuilderVariant ? TreeType.DIRECTORY_ITEM : props.type === TreeType.DIRECTORY
                                                                       ? TreeType.DIRECTORY_ITEM
                                                                       : TreeType.DIRECTORY;
  const label = props?.localization[i18n.language] || props.directoryName || props.value;

  function fetchChildNodes(node) {
    if (props.type === TreeType.DIRECTORY) {
      return getDirectoryByIdV2(node, props?.valueParentId, 'PUBLISHED', props.forceEnglish);
    } else {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve) => {
        const res1 = await getNestedDirectories(node, 'PUBLISHED', props.forceEnglish);
        if (res1?.content?.length) {
          resolve(res1);
        } else {
          const nodes = await getDirectoriesV2Value(node, props.forceEnglish);
          resolve({ content: nodes });
        }
      });
    }
  }

  const handleChange = (event, nodes) => {
    const expandingNodes = nodes.filter(x => !expanded.includes(x));
    setExpanded(nodes);
    if (expandingNodes[0]) {
      const child = expandingNodes[0];
      getChildNodes(child);
    }
  };

  const getChildNodes = (id: string) => {
    setFetchingState(FetchingState.LOADING);
    fetchChildNodes(id)
      .then(
        result => {
          setChildNodes(
            result.content
              .filter(node => node.status === 'PUBLISHED')
              .map(
                node => <ProjectChildSelect
                  onSelect={props.onSelect}
                  type={childType}
                  key={node.id}
                  item={node}
                  disabledItemsIds={props.disabledItemsIds}
                  {...node}
                  valueParentId={id}
                  isBuilderVariant={props.isBuilderVariant}
                  forceEnglish={props.forceEnglish}
                />,
              ),
          );
          setFetchingState(FetchingState.SUCCESS);
        },
        () => setFetchingState(FetchingState.ERROR),
      );
  };

  const handleClick = (item, valuesCount) => {
    if (!props.disabledItemsIds.includes(item.id) && ((!props.isBuilderVariant && !valuesCount) || (props.isBuilderVariant && item?.valuesCount > 0))) {
      props.onSelect(item);
    }
  };

  const renderNodes = () => {
    switch (fetchingState) {
      case FetchingState.LOADING: {
        return props.valuesCount ? [<InfoBox><LinearProgress color="secondary"/></InfoBox>] : [];
      }
      case FetchingState.ERROR: {
        return props.valuesCount ? [
          <InfoBox display="flex" justifyContent="space-between" alignItems="center" pt={2} height={46}>
            <span>{t('form_components.tree_glossary.fetchingError')}</span>
            <Button onClick={() => getChildNodes(props?.id)}>{t('form_components.tree_glossary.tryAgain')}</Button>
          </InfoBox>,
        ] : [];
      }
      case FetchingState.SUCCESS:
      case FetchingState.IDLE:
      default: {
        return childNodes || [<div/>];
      }
    }
  };

  return (
    <TreeView
      className={treeClasses.root}
      defaultCollapseIcon={<ExpandMoreIcon/>}
      defaultExpandIcon={<ChevronRightIcon/>}
      expanded={expanded}
      onNodeToggle={handleChange}
    >
      <TreeItem
        nodeId={props.id}
        label={<div className={classes.labelRoot}>
          <Typography variant="body2"
                      className={cn(classes.labelText, { [classes.labelTextDisabled]: (props.isBuilderVariant && props?.item?.valuesCount === 0) || props?.disabledItemsIds?.includes(props?.item?.id) })}>
            {label}
          </Typography>
        </div>}
        onClick={() => handleClick(props.item, props.valuesCount)}
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          selected: classes.selected,
          group: classes.group,
          label: classes.label,
          iconContainer: props.valuesCount ? classes.iconContainer : classes.hideIconContainer,
        }}
      >
        {renderNodes()}
      </TreeItem>
    </TreeView>
  );
};

export const TreeGlossary = ({
  name,
  hint,
  value,
  valueId = '',
  disabledItemsIds = [],
  isDisabled = false,
  params,
  control,
  errors,
  showErrorText = true,
  onSelect,
  withoutFormController = false,
  isBuilderVariant = false,
  isConditionModalVariant = false,
  forceEnglish = false,
  placeholder = '',
  rules,
}: Props) => {
  const { t, i18n } = useTranslation();
  const selectContentRef = useRef();

  const treeClasses = useTreeStyles();
  const inputClasses = useInputStyles();
  const [selectedProject, setSelectedProject] = useState(null);
  const [fetchingState, setFetchingState] = useState(FetchingState.LOADING);
  const [childNodes, setChildNodes] = useState(null);
  const [expanded, setExpanded] = React.useState([]);
  const [open, setOpen] = useState(false);
  const [isClickAwayListenerActive, setIsClickAwayListenerActive] = useState(false);
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [popperWidth, setPopperWidth] = useState(300);
  const [inputValue, setInputValue] = useState('');
  const [projectDirectoryId, setProjectDirectoryId] = useState<string>();
  const debouncedInputValue = useDebounce(inputValue, 500);
  const [valueText, setValueText] = useState('');
  const [displayedValue, setDisplayedValue] = useState('');
  const [parentDirectoryId, setParentDirectoryId] = useState('');

  const getValueText = useCallback(async () => {
    if (valueId) {
      try {
        const v = await getDirectoryInfo(valueId, forceEnglish);
        setValueText(() => v.value);
        return v.value;
      } catch (error) {
        return '';
      }
    }

    if (value && isConditionModalVariant) {
      setValueText(() => value);
      return value;
    }

    return '';
  }, [isConditionModalVariant, value, valueId]);

  useEffect(() => {
    getValueText();
  }, [valueId, value, getValueText]);

  const handleSelect = (value) => {
    if (isBuilderVariant) {
      onSelect(value);
      setSelectedProject(value);
    } else {
      setOpen(false);
      setAnchorElement(null);
      setSelectedProject(value);
      const valueForForm = value ? {
        name: value?.value || value?.directoryName,
        fields: value?.additionalFields || {},
        id: params?.isAcceptedValueTypeId ? value?.id : undefined,
      } : null;
      onSelect(valueForForm);
    }
  };

  useEffect(() => {
    if (!open) {
      setInputValue('');
    }
    setTimeout(() => {
      setIsClickAwayListenerActive(open);
    }, 100);
  }, [open]);

  const init = async () => {
    if (params.showAllGlossaries) {
      getDirectoriesV2(forceEnglish).then((directories) => {
        if (directories) {
          setFetchingState(FetchingState.SUCCESS);
          setChildNodes(directories
            .filter(node => node.status === 'PUBLISHED' && node.firstLevel)
            .map(
              directory =>
                <ProjectChildSelect
                  key={directory.id}
                  onSelect={handleSelect}
                  disabledItemsIds={disabledItemsIds}
                  item={directory}
                  {...directory}
                  type={TreeType.DIRECTORY_ITEM}
                  label={directory.value}
                  isBuilderVariant={isBuilderVariant}
                  forceEnglish={forceEnglish}
                />,
            ));
        } else {
          setFetchingState(FetchingState.ERROR);
        }
      });
    } else {
      const directoriesPathList = params?.directoryPath ? (params.directoryPath as string).split('/') : [null];
      if (directoriesPathList.length === 1) {
        if (!projectDirectoryId) {
          getProjectId(directoriesPathList[0]).then(
            getChildNodes,
            () => setFetchingState(FetchingState.ERROR),
          );
        } else {
          getChildNodes(projectDirectoryId);
        }
      } else {
        try {
          const directoryId = directoriesPathList[directoriesPathList.length - 1];
          setParentDirectoryId(directoriesPathList[directoriesPathList.length - 2]);
          let nodes: any = [];
          const nodesResponse = await getNestedDirectories(directoryId, 'PUBLISHED', forceEnglish);
          if (nodesResponse?.content.length > 0) {
            nodes = nodesResponse?.content;
          } else {
            nodes = await getDirectoriesV2Value(directoryId, forceEnglish);
          }
          setFetchingState(FetchingState.SUCCESS);
          setChildNodes(nodes
            ?.filter(node => node.status === 'PUBLISHED')
            .map(
              project =>
                <ProjectChildSelect
                  key={project.id}
                  onSelect={handleSelect}
                  disabledItemsIds={disabledItemsIds}
                  item={project}
                  {...project}
                  type={TreeType.DIRECTORY_ITEM}
                  label={project.value}
                  isBuilderVariant={isBuilderVariant}
                  forceEnglish={forceEnglish}
                />,
            ));
        } catch (error) {
          console.log(error);
          setFetchingState(() => FetchingState.ERROR);
        }
      }
    }
  };

  useEffect(() => {
    init();
  }, []);

  const searchHandler = async () => {
    if (debouncedInputValue === '') {
      return init();
    }
    setFetchingState(FetchingState.LOADING);
    if (isBuilderVariant) {
      getValuesByNameFragment(debouncedInputValue, 'PUBLISHED', forceEnglish)
        .then(
          hierarchy => {
            setFetchingState(FetchingState.SUCCESS);
            setChildNodes([...(hierarchy || [])]
              .filter(node => node.status === 'PUBLISHED')
              .map(
                project =>
                  <ProjectChildSelect
                    key={project.id}
                    onSelect={handleSelect}
                    disabledItemsIds={disabledItemsIds}
                    {...project}
                    item={project}
                    type={isBuilderVariant
                          ? TreeType.DIRECTORY_ITEM
                          : project.value
                            ? TreeType.DIRECTORY_ITEM
                            : TreeType.DIRECTORY}
                    label={project.value}
                    isBuilderVariant={isBuilderVariant}
                    forceEnglish={forceEnglish}
                  />,
              ));
          },
          () => setFetchingState(FetchingState.ERROR),
        );
    } else {
      const directoriesPathList = params?.directoryPath ? (params.directoryPath as string).split('/') : [null];
      const glossaryDirectoryId = directoriesPathList[directoriesPathList.length - 1] || projectDirectoryId;
      const searchDirectoryId = directoriesPathList.length === 1 ? glossaryDirectoryId : null;
      const valueParentId = directoriesPathList.length === 1 ? null : parentDirectoryId;
      const glossaryId = directoriesPathList.length === 1 ? null : glossaryDirectoryId;
      getAllHierarchyV2(searchDirectoryId, debouncedInputValue, 'PUBLISHED', glossaryId, valueParentId, forceEnglish)
        .then(
          hierarchy => {
            setFetchingState(FetchingState.SUCCESS);
            setChildNodes([...(hierarchy?.info || []), ...(hierarchy?.value || [])]
              .filter(node => node.status === 'PUBLISHED')
              .map(
                project =>
                  <ProjectChildSelect
                    key={project.id}
                    onSelect={handleSelect}
                    disabledItemsIds={disabledItemsIds}
                    {...project}
                    item={project}
                    type={isBuilderVariant
                          ? TreeType.DIRECTORY_ITEM
                          : project.value
                            ? TreeType.DIRECTORY_ITEM
                            : TreeType.DIRECTORY}
                    label={project.value}
                    isBuilderVariant={isBuilderVariant}
                    forceEnglish={forceEnglish}
                  />,
              ));
          },
          () => setFetchingState(FetchingState.ERROR),
        );

    }
  };

  useEffect(() => {
    searchHandler();
  }, [debouncedInputValue]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const getChildNodes = async (id: string) => {
    fetchDirectories(id).then(
      projects => {
        if (projects) {
          setFetchingState(FetchingState.SUCCESS);
          setChildNodes(projects
            .filter(node => node.status === 'PUBLISHED')
            .map(
              project =>
                <ProjectChildSelect
                  key={project.id}
                  onSelect={handleSelect}
                  disabledItemsIds={disabledItemsIds}
                  item={project}
                  {...project}
                  type={TreeType.DIRECTORY_ITEM}
                  label={project.value}
                  isBuilderVariant={isBuilderVariant}
                  forceEnglish={forceEnglish}
                />,
            ));
        } else {
          setFetchingState(FetchingState.ERROR);
        }
      },
      () => setFetchingState(FetchingState.ERROR),
    );
  };

  const getProjectId = (id = null) => {
    if (id) {
      return new Promise(resolve => resolve(id));
    }

    return getDirectoriesV2(forceEnglish).then(data => {
        const directory = data.find(v =>
          v.directoryName.toLowerCase() === ((params?.directoryCode || ENDPOINTS_TO_NAMES_MAP[params.endpoint as string] || params.endpoint) as string).toLowerCase());
        setProjectDirectoryId(directory.id);
        return directory.id;
      },
      () => setFetchingState(FetchingState.ERROR),
    );
  };

  const fetchDirectories = (id?: string) => {
    setFetchingState(FetchingState.LOADING);
    return getDirectoryByIdV2(id || projectDirectoryId, undefined, 'PUBLISHED', forceEnglish).then(
      async data => {
        if (data?.content) {
          setFetchingState(FetchingState.SUCCESS);
          return data?.content;
        } else {
          const nodes = await getDirectoriesV2Value(id || projectDirectoryId, forceEnglish);
          setFetchingState(FetchingState.SUCCESS);
          return nodes;
        }
      },
      () => setFetchingState(FetchingState.ERROR),
    );
  };

  const handleRefetch = useCallback(() => {
    init();
  }, []);

  const handleChange = (event, nodes) => {
    const expandingNodes = nodes.filter(x => !expanded.includes(x));
    setExpanded(nodes);
    if (expandingNodes[0]) {
      if (!childNodes) {
        setFetchingState(FetchingState.LOADING);
        fetchDirectories().then(
          projects => {
            if (projects) {
              setFetchingState(FetchingState.SUCCESS);
              setChildNodes(projects
                .filter(node => node.status === 'PUBLISHED')
                .map(
                  project =>
                    <ProjectChildSelect
                      key={project.id}
                      onSelect={handleSelect}
                      disabledItemsIds={disabledItemsIds}
                      item={project}
                      {...project}
                      type={TreeType.DIRECTORY_ITEM}
                      label={project.value}
                      isBuilderVariant={isBuilderVariant}
                      forceEnglish={forceEnglish}
                    />,
                ));
            } else {
              setFetchingState(FetchingState.ERROR);
            }
          },
          () => setFetchingState(FetchingState.ERROR),
        );
      }
    }
  };

  const renderNodes = () => {
    switch (fetchingState) {
      case FetchingState.LOADING: {
        return <InfoBox><LinearProgress color="secondary"/></InfoBox>;
      }
      case FetchingState.ERROR: {
        return <InfoBox display="flex" justifyContent="space-between" alignItems="center" pt={2} height={46}>
          <span>Error while fetching</span>
          <Button onClick={handleRefetch}>Try again</Button>
        </InfoBox>;
      }
      case FetchingState.SUCCESS:
      case FetchingState.IDLE:
      default: {
        return <TreeView
          className={treeClasses.root}
          defaultCollapseIcon={<ExpandMoreIcon/>}
          defaultExpandIcon={<ChevronRightIcon/>}
          expanded={expanded}
          onNodeToggle={handleChange}
        >
          {childNodes}
        </TreeView>;
      }
    }
  };

  const validateValue = (value) => {
    if (value.length === 0 && rules?.required) {
      return t('form_components.validation_errors.listOption');
    }

    return true;
  };

  useEffect(() => {
    if (isBuilderVariant && params?.directoryCode) {
      getDirectoriesV2(forceEnglish).then((directories) => {
        const selectedDirectory = directories.find(dir => dir.directoryName === params.directoryCode);
        const displayedDirectoryName = selectedDirectory?.localization[i18n.language] || selectedDirectory?.localization['en'] || params.directoryCode;
        setDisplayedValue(displayedDirectoryName);
      })
    }
  }, [isBuilderVariant, params]);

  const textField = useMemo(() => {
    if (withoutFormController) {
      return <TextField
        label={hint}
        placeholder={placeholder}
        fullWidth={!isConditionModalVariant}
        value={displayedValue || ((valueId || (isConditionModalVariant && value)) ? valueText : value?.name) || ''}
        disabled={isDisabled}
        onChange={() => {
        }}
        className={cn({ [inputClasses.conditionFieldText]: isConditionModalVariant })}
        error={!!errors[name]}
        helperText={(!!errors[name] && showErrorText) && errors[name].message}
        InputProps={{ endAdornment: <img src={dropdownIndicatorIcon}/> }}
        onClick={(event) => {
          if (!isDisabled) {
            setOpen(v => !v);
            setAnchorElement(anchorElement ? null : event.currentTarget);
            setPopperWidth((event.currentTarget?.offsetWidth - 16) || 300);
          }
        }}
      />;
    }

    return <Controller
      name={name}
      control={control}
      rules={{
        validate: validateValue,
      }}
      render={() => (
        <>
          <label>{hint}</label>
          <TextField
            // label={hint}
            placeholder={placeholder}
            fullWidth
            disabled={isDisabled}
            value={valueId ? valueText : value?.name || ''}
            onChange={() => {
            }}
            inputProps={{ 'data-selenium': name }}
            error={!!errors[name]}
            helperText={(!!errors[name] && showErrorText) && errors[name].message}
            InputProps={{ endAdornment: <img src={dropdownIndicatorIcon}/> }}
            onClick={(event) => {
              if (!isDisabled) {
                setOpen(v => !v);
                setAnchorElement(anchorElement ? null : event.currentTarget);
                setPopperWidth((event.currentTarget?.offsetWidth - 16) || 300);
              }
            }}
          />
        </>
      )}
    />;

  }, [isConditionModalVariant, withoutFormController, name, control, validateValue, hint, placeholder, value, valueId, valueText, errors, showErrorText, anchorElement, isDisabled]);

  return (
    <>
      {textField}

      <ClickAwayListener onClickAway={() => {
        if (isClickAwayListenerActive) {
          setOpen(v => !v);
          setAnchorElement(null);
        }
      }}>
        <Styled.Popper
          open={open}
          style={{ width: popperWidth }}
          anchorEl={anchorElement}
          placement="bottom-start"
        >
          <Styled.ProjectSelectContainer
            ref={selectContentRef}
            visible={true}
            isSingleValueSelect={true}
          >
            <Styled.Input
              name="search"
              fullWidth
              value={inputValue}
              placeholder={t('form_components.select.search_placeholder')}
              onChange={handleInputChange}
              InputProps={{
                endAdornment: <SearchIcon htmlColor="#000"/>,
              }}
            />
            <Styled.TreeViewParent visible={true}>
              <Styled.TreeViewContainer>
                {renderNodes()}
              </Styled.TreeViewContainer>
            </Styled.TreeViewParent>
          </Styled.ProjectSelectContainer>
        </Styled.Popper>
      </ClickAwayListener>
    </>);
};
