import React from 'react';
import { Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

type Props = {
  text: string,
  Icon?: any | null
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  text: {
    marginTop: '8px'
  }
}));

export const NoData = ({ text, Icon = null }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {Icon && <Icon color="disabled" />}
      <Typography variant="h5" color="textSecondary" className={classes.text}>
        {text}
      </Typography>
    </div>
  );
}

