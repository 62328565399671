import React, { ReactElement, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Menu,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import classNames from 'classnames';

import {
  TemplatesTableRow,
  TemplatesTableColumn,
  TableMenuButton
} from "../TemplatesTable.types";

import useStyles from './TableComponent.useStyles';

interface TableProps {
  rows: Partial<TemplatesTableRow>[];
  menuButtons: Partial<TableMenuButton>[];
  anchorElement: Element | null;
  renderCell: (column: TemplatesTableColumn, row: Partial<TemplatesTableRow>) => ReactElement;
  onMenuClose: () => void;
  columns: TemplatesTableColumn[];
}

export const TableComponent = ({
  rows,
  columns,
  menuButtons = [],
  anchorElement,
  renderCell,
  onMenuClose,
}: TableProps): ReactElement => {
  const classes = useStyles();

  const { t } = useTranslation();

  const [currentButtonAction, setCurrentButtonAction] = useState<string | null>( null);

  const handleMenuItemClick = async (button: TableMenuButton): Promise<void> => {
    try {
      setCurrentButtonAction(button.title);
      await button.onClick();
      onMenuClose();
    } finally {
      setCurrentButtonAction(null);
    }
  }

  const renderHeaderRowsHelper = columns => columns.map((column: TemplatesTableColumn) => {
    return (
      <TableCell key={column.id} className={classes.tableHeaderRowCell} width={column.width} style={{width: column.width}}>
        <Box display='flex' alignItems="center" justifyContent={column.isCentered ? 'center' : ''}>
          <Box>{t(column.label)}</Box>
        </Box>
      </TableCell>
    )
  })

  return (
    <Box display="flex" flexDirection="column" className={classes.mainBox}>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table className={classNames(classes.table)} stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow className={classes.tableHeaderRow}>
                {renderHeaderRowsHelper(columns)}
              </TableRow>
            </TableHead>

            <TableBody>
              {rows.map((row) => (
                <TableRow hover role="checkbox" tabIndex={-1}>
                    {columns.map((column): ReactElement => renderCell(column, row))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Menu
        id="actions-menu"
        open={Boolean(anchorElement)}
        anchorEl={anchorElement}
        MenuListProps={{'aria-labelledby': 'basic-button', style: {
          padding: 0
        }}}
        PaperProps={{
          style: {
            borderRadius: 10
          },
        }}
        onClose={onMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {menuButtons.map((button: TableMenuButton) => (
          <MenuItem
            key={button.title}
            className={classes.tableBodyActionMenuListItem}
            onClick={() => handleMenuItemClick(button)}
          >
            <Box display="flex" alignItems="center">
              <img src={button.icon} className={classes.tableBodyActionMenuListItemIcon}/>

              <Typography className={classes.tableBodyActionMenuListItemText}>
                {button.title}
              </Typography>

              <Box ml={1}>
                <CircularProgress
                  size={8}
                  style={{ 'color': currentButtonAction === button.title ? 'inherit' : 'white'}}
                />
              </Box>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}
