import React, { 
  useCallback,
  useEffect,
  useMemo,
  createContext,
  useState,
  Dispatch,
  useContext,
  useRef
} from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Box, Fade } from '@mui/material';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  useCreateProcessState,
  useSavedDraftStatus,
  useProcessDialogState,
  openCreateDialogAction as openDialog,
  setCreateRequestAction as setCreate,
  clearTaskData,
  useTaskState,
  getTaskById,
  useRequestActiveStep,
} from 'store/requests';

import { NewRequest } from 'pages/Requests/NewRequest';
import { TaskForm } from 'pages/Task';

import DoneIcon from 'assets/images/icons/done.svg';
import useStyles from './useStyles';
import { CloseButton, ErrorMessage, Spinner } from 'components';
import { ProcessContextType } from 'types';
import ProcessContext from 'contexts/ProcessContext';

export const AbortControllerContext = createContext<{
  setAbortController: Dispatch<React.SetStateAction<AbortController>>
}>(null);

export const CreateProcessDialog = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [abortController, setAbortController] = useState<AbortController | null>(null);

  const { groups }: Partial<ProcessContextType> = useContext(ProcessContext);

  const dispatch = useDispatch();
  const createProcess = useCreateProcessState();
  const draftSaved = useSavedDraftStatus();
  const open = useProcessDialogState();
  const { data: bpmTask, loading: taskLoading, error: taskError } = useTaskState();
  const { isSummaryStep } = useRequestActiveStep();

  const [isSaveModalVisible, setIsSaveModalVisible] = useState(false);

  const [_propsForActionsBar, setPropsForActionsBar] = useState<{
    saveDraft: () => Promise<unknown>;
    disabled: boolean;
    buttonDisabled: boolean;
    alwaysActiveButtons: string[];
    onClick: ({ action }: { action: string; }) => Promise<void>;
    formMethods: any;
  }>(null);

  const handleDeleteRef = useRef(null);
  const hasDraftChanged = useRef(false);

  const onClose = useCallback(() => {
    if (!hasDraftChanged.current && createProcess?.isNewRequest) {
      handleDeleteRef.current?.(true);
    }

    if (hasDraftChanged.current && !isSaveModalVisible) {
      setIsSaveModalVisible(true);
      return;
    }

    if (abortController) {
      abortController.abort();
    }
    dispatch(openDialog(false));
    dispatch(clearTaskData());
    setPropsForActionsBar(null);
    setIsSaveModalVisible(false);
    hasDraftChanged.current = false;
    handleDeleteRef.current = null;

    setTimeout(() => {
      dispatch(setCreate(null));
    }, 300);
  }, [dispatch, abortController, isSaveModalVisible, createProcess]);

  useEffect(() => {
    if (hasDraftChanged.current) return;
    if (draftSaved && bpmTask) {
      hasDraftChanged.current = true;
    }
  }, [draftSaved]);

  useEffect(() => {
    if (createProcess?.id) {
      dispatch(getTaskById(createProcess?.id));
    }
  }, [createProcess?.id, dispatch]);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      setTimeout(() => {
        document.body.style.overflow = null;
      }, 300);
    }
  }, [open, dispatch]);

  const localizedProcessName = useMemo(() => {
    const processNameKey = `constructor-${createProcess?.sysName}.name`;
    return t(processNameKey, { defaultValue: createProcess?.name || '' });
  }, [createProcess]);

  const currentTaskGroup = useMemo(() => {
    if (!bpmTask) return null;
    return groups.find(g => g?.sysName === bpmTask?.businessTask?.groupSysName);
  }, [bpmTask, groups]);

  return (
    <Box component="dialog" className={cn(classes.dialog, { [classes.open]: open })}>
      {!createProcess && <Box
        component="header"
        className={classes.header}
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        <Box display="flex" justifyContent="center" flexDirection="column" gap={6}>
          {currentTaskGroup && <Box className={classes.groupName}>
            <img src={currentTaskGroup.iconPath} alt=""/>
            <span>{t(`groups.${currentTaskGroup.sysName}.name`, { defaultValue: currentTaskGroup?.name })}</span>
          </Box>}
          <Typography variant="h3" className={classes.title}>
            {isSummaryStep
             ? t('new_request.title_request_start_summary')
             : `${t('new_request.title_request_start_prefix')} ${localizedProcessName || ''} ${t('new_request.title_request_start_suffix')}`}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={12}>
          {createProcess && bpmTask && (
            <Fade in={draftSaved} timeout={300}>
              <Typography className={classes.draftStatus} variant="caption">
                <img src={DoneIcon} alt="Done"/> {t('customProcesses.creationPage.auto-saved')}
              </Typography>
            </Fade>
          )}

          <CloseButton onClick={onClose}/>
        </Box>
      </Box>
      }
      
      {createProcess ? (<>
          {taskLoading && <Spinner/>}
          {!taskLoading && taskError && <ErrorMessage text={t('errors.somethingIsWrong')}/>}

          {!taskError && bpmTask &&
            <TaskForm
              isNewRequest={createProcess.isNewRequest}
              isSaveModalVisible={isSaveModalVisible}
              setIsSaveModalVisible={setIsSaveModalVisible}
              setPropsForActionsBar={setPropsForActionsBar}
              handleClose={onClose}
              handleDeleteRef={handleDeleteRef}
              currentTaskGroup={currentTaskGroup}
              localizedProcessName={localizedProcessName}
              draftSaved={draftSaved}
              taskLoading={taskLoading}
              taskError={taskError}
              onDialogClose={onClose}
            />}
        </>
      ) : (
         <Box component="article" className={classes.content}>
           <AbortControllerContext.Provider value={{
             setAbortController,
           }}>
             <NewRequest/>
           </AbortControllerContext.Provider>
         </Box>
       )}
    </Box>
  );
};
