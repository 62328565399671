import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ palette, spacing }) => ({
  modalBody: {
    width: 500,
    background: '#fff',
    border: '1px solid rgba(38, 40, 66, 0.08)',
    boxShadow: '0px 16px 32px rgba(38, 40, 66, 0.08)',
    borderRadius: 12,

    overflow: 'hidden',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 99,
    outline: 'none',
  },
  modalHeader: {
    padding: 24,
    paddingBottom: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  modalContent: {
    padding: 24,
    paddingTop: 4,
    paddingBottom: 32,
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'end',
    gap: 12,
    padding: '12px 24px',
    background: '#F0F0F7',
    borderTop: '1px solid rgba(38, 40, 66, 0.08);',
  },
  modalButton: {
    borderRadius: 10,
    height: 40,
  },
  closeIcon: {
    '& > path': {
      fill: '#6D7C8B',
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    color: '#262842',
  },
  description: {
    marginBottom: 60,
    fontSize: 16,
    color: '#6D6E85',
  },
  infoCard: {
    padding: 12,
    borderRadius: 12,
    marginTop: 16,
    border: '1px solid rgba(38, 40, 66, 0.12)',
    background: '#EBF5FC',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,

    '& p': {
      margin: 0,
      marginBottom: 8,
    },

    '& ul': {
      margin: 0,
      padding: 0,
      paddingLeft: 20,
      marginBottom: 8,
    },

    '& p:last-child': {
      marginBottom: 0,
    }
  },
  nameInput: {
    marginBottom: 16,

    '& label': {
      fontWeight: 500,
      color: '#6D6E85',
      fontSize: 14,
      position: 'relative',
      display: 'block',
      width: 'fit-content',
      fontFamily: 'Euclid, sans-serif !important',
    },

    '& div': {
      marginTop: 0,
      borderRadius: 12,
      borderColor: 'rgba(38, 40, 66, 0.12)'
    },

    '& input': {
      fontSize: '14px !important',
      fontWeight: 400,
      color: '#262842',
    }
  },
  nameInputRequired: {
    '& label': {
      '&::after': {
        display: 'inline-block',
        content: '"*"',
        color: palette.error[600],
        fontSize: 14,
        position: 'relative',
        right: -2,
        top: -2,
      },
    },
  },
  dropdownPopper: {
    zIndex: 1400,
    marginLeft: -10,
    width: 450,
  },
  dropdownFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

    padding: spacing(2),
    borderTop: '1px solid #E0E3EA',
    color: '#007994',
  },
  workspaceDropdownField: {
    position: 'relative',
    maxWidth: 490,
    width: '100%',
    height: 40,
    fontSize: 14,
    fontWeight: 400,
    fontFamily: 'Euclid, sans-serif !important',

    backgroundColor: '#FFFFFF',
    border: '1px solid rgba(38, 40, 66, 0.12)',
    borderRadius: 10,
    padding: spacing(2.5, 2.5, 2.5, 2),

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  fieldLabel: {
    fontWeight: 500,
    color: '#6D6E85',
    fontSize: 14,
    position: 'relative',
    display: 'block',
    width: 'fit-content',
    paddingBottom: 4,

    '&::after': {
      display: 'inline-block',
      content: '"*"',
      color: palette.error[600],
      fontSize: 14,
      position: 'relative',
      right: -2,
      top: -2,
    },
  },
  workspacesDropdown: {
    display: 'none',
    position: 'absolute',
    top: 12,
    left: 0,

    width: '100%',
    backgroundColor: '#FFFFFF',
    border: '1px solid rgba(38, 40, 66, 0.12)',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.12)',
    borderRadius: spacing(2.5),
  },
  workspacesDropdownActive: {
    display: 'block',
    zIndex: 1300,
  },
  scrollArea: {
    maxHeight: 220,
    overflowY: 'auto',
  },
  loaderWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '14px 12px',

    '& span': {
      marginLeft: 8,
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 400,
      color: '#6D6E85'
    }
  },
  loaderSpinnerWrapper: {
    position: 'relative',
    width: 20,
    height: 20,
  },
  infoBox: {
    margin: '8px 12px',
    padding: 12,
    width: 'calc(100% - 24px)',
    borderRadius: 12,
    border: '1px solid rgba(38, 40, 66, 0.12)',
    background: '#EBF5FC',
    color: '#262842',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
  },
  dropdownList: {},
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    padding: spacing(0),
    cursor: 'pointer',
    minHeight: 40,
    height: 'fit-content',
    '& label': {
      width: '100%',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
    },
  },
  menuItemSelected: {
    background: '#EEEEF0',
  },
  listCheckbox: {
    marginLeft: spacing(1.5),
    marginRight: spacing(0.5),
    '&.MuiCheckbox-indeterminate': {
      color: '#1B74B4',
    },
  },

  checkbox: {
    '& svg path': {
      fill: '#8B8C9E !important',
    },
  },
  checkboxChecked: {
    '& svg path': {
      fill: '#007994 !important',
    },
  },
  checkboxDisabled: {
    '& svg path': {
      fill: palette.grey[300] + ' !important',
    },
  },
  workspaceItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight: 8,
  },
  logoWrapper: {
    marginLeft: 8,
    '& img': {
      width: 40,
      height: 40,
      borderRadius: 6,
    },
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginLeft: 8,
  },
  workspaceName: {
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    color: '#262842',
    maxWidth: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  workspaceUsers: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    color: '#8B8C9E',
  },
  workspaceNameTooltip: {
    maxWidth: 240,
    borderRadius: 8,
  },
  workspacesDropdownSearchWrapper: {
    padding: spacing(2, 3),
    lineHeight: 'initial',
    backgroundColor: '#F7F8FC',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  workspacesSearchTextField: {
    height: 32,
    fontSize: 14,
    fontWeight: 400,
    color: '#262842',
    '& > div': {
      borderRadius: spacing(2.5),
      paddingTop: 0,
      paddingBottom: 0,
      height: 32,
      '& input': {
        height: '100%',
      },
    },
  },
  workspacesDropdownIcon: {
    transition: '0.3s transform ease',
    pointerEvents: 'none',

    '& > path': {
      fill: '#6D7C8B !important',
    },
  },
  workspacesTooltipPopper: {
    pointerEvents: 'all',
  },
  workspacesTooltip: {
    maxHeight: 200,
    padding: 10,
    fontSize: 12,
    fontWeight: 500,
    color: palette.grey[50],
    borderRadius: 8,
  },
  tooltipContent: {
    width: 240,
    maxHeight: 180,
    maxWidth: 240,
    overflowY: 'auto',
    '& ul': {
      margin: 0,
      padding: 0,
      paddingLeft: 20,
    },
  },
  errorMessage: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: spacing(1),
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    color: '#D6331F',
    '& img': {
      marginRight: spacing(1.25),
      height: 13,
      width: 13,
    },
  },
  errorMessageTwoSides: {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
}));
