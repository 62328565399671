import React, { useCallback, Fragment } from 'react';
import { Box, Button, Switch, FormControlLabel, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useRequestActiveStep, useTaskSteps } from 'store/requests';

import { FormButtonList } from './FormButtonList';

import useActionBar from './useActionBar';
import useStyles from './useStyles';

import NextArrow from 'assets/images/icons/next-arrow.svg';
import PrevArrow from 'assets/images/icons/prev-arrow.svg';
import classNames from 'classnames';
import { Spinner } from 'components';

type Props = {
  disabled: boolean;
  buttonDisabled: boolean;
  alwaysActiveButtons?: string[];
  onClick: ({ action }: { action: string} ) => Promise<void>;
  formMethods: any;
  saveDraft: () => Promise<unknown>;
  skippedSteps?: number[];
};

export const ActionsBar = ({
  disabled,
  buttonDisabled,
  alwaysActiveButtons = [],
  onClick,
  formMethods,
  saveDraft,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const taskSteps = useTaskSteps();
  const { activeStep, isSummaryStep } = useRequestActiveStep();
  const { handleClickNext, handleClickPrev, isDocumentEditing } = useActionBar(
    formMethods,
    saveDraft
  );

  return (
    <Box component="footer" className={classes.root} gridArea="footer">
      {activeStep !== 0 && <Button
        variant="outlined"
        color="secondary"
        className={classes.footerButton}
        onClick={handleClickPrev}
        startIcon={
        <img
          src={PrevArrow}
          alt="Prev"
          className={classNames({
            [classes.opacityOn]: activeStep === 0
          })}
        />
      }
      >
        <span>{t('One_step_internal_memo.process_button4')}</span>
      </Button>}
      <Box marginLeft="auto" display="flex" alignItems="center" gap={3}>
        {isSummaryStep ? (
          <>
            <Button
              variant="outlined"
              color="secondary"
              className={classes.footerButton}
              onClick={()=> {
                onClick({
                  action: 'Open template creation popover',
                })
              }}>
                {t('One_step_internal_memo.process_button5')}
            </Button>

            <FormButtonList
              disabled={disabled}
              buttonDisabled={buttonDisabled}
              alwaysActiveButtons={alwaysActiveButtons}
              onClick={onClick}
              validateDocumentsSignatures
            />
          </>
        ) : (
          taskSteps && (
            <>
              <FormButtonList
                disabled={disabled}
                buttonDisabled={buttonDisabled}
                alwaysActiveButtons={alwaysActiveButtons}
                showOnlyAllStepsButtons
                onClick={onClick}
              />

            <Button
              variant="contained"
              color="primary"
              className={classes.footerButton}
              startIcon={isDocumentEditing ? <CircularProgress size={20} style={{ color: '#fff' }} /> : null}
              endIcon={<img src={NextArrow} alt="Next" />}
              disabled={disabled}
              onClick={handleClickNext}
            >
              <span>{t('One_step_internal_memo.process_button')}</span>
            </Button>
              </>
          )
        )}
      </Box>
    </Box>
  );
};
