import React, {memo, ReactElement} from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useUserProfile } from 'hooks';
import useStyles from './useStyles';

type Group = {
  name: string;
  iconPath: string;
  sysName: string;
}

interface GroupsMenuProps {
  groups: Group[],
  selected: number;
  handleClick: (index: number) => void;
}

export const GroupsMenu = memo(({
  groups,
  selected,
  handleClick
}: GroupsMenuProps): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { companyId } = useUserProfile();

  return (
    <List>
      {groups.map((group: Group, index: number) => (
        <ListItem
          button
          key={group.sysName}
          className={classes.item}
          selected={index === selected}
          onClick={() => handleClick(index)}>
          <ListItemIcon className={classes.icon}>
            <img src={group.iconPath} alt={group.name} />
          </ListItemIcon>

          <ListItemText
            className={classes.text}
            primary={t(`groups.${group.sysName}.name`, {defaultValue: t(`groups.${companyId}_${group.sysName}.name`, { defaultValue: group.name })})}
          />
        </ListItem>
      ))}
    </List>
  )
})
