import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { MoreHoriz } from '@mui/icons-material';

import {
  ConfirmDeleteParallelBranchesModalContext,
  FirstErrorStepIndexContext,
} from '../SetupTemplateProcessesDialog';
import { ProcessStepCard, ProcessStepTag } from '../index';
import {
  ConditionStep, DocumentTemplate,
  FormField,
  Language,
  ParallelBranchesGroup,
  ProcessStep,
  StepValidationResult,
} from '../../TemplateProcesses.types';
import useConditionStepCardStyles from './ParallelStepsGroupCard.useStyles';

import BranchesIcon from 'assets/images/icons/parallel-branches-icon.svg';
import PlusIcon from 'assets/images/icons/black-plus-sign.svg';
import { useFormatConditionValue } from 'pages/TemplateProcesses/useFormatConditionValue';
import { ProcessStepAddMenu } from '../ProcessStepAddMenu';
import { getDisplayedStepOrder } from '../../TemplateProcesses.utils';
import { BRANCH_STEPS_NUMBER_LIMIT, STEPS_NUMBER_LIMIT } from '../../TemplateProcesses.constants';
import WarningIcon from '../../../../assets/images/icons/warning-sign.svg';

interface ConditionStepCardProps {
  isAddConditionButtonAvailable?: boolean;
  isAddParallelBranchesButtonAvailable?: boolean;
  isTemplatePreview?: boolean;
  tagSize?: 'small' | 'medium';
  currentLanguage: Language;
  parallelBranchesGroup: ParallelBranchesGroup;
  parallelBranchesGroups: ParallelBranchesGroup[];
  conditions: ConditionStep[];
  parallelStepsList: ProcessStep[][];
  formErrors: StepValidationResult[];
  documents: DocumentTemplate[];
  departmentsList: any[];
  directManagerSteps: number[];
  performerSelectionSteps: number[];
  isReadonlyView: boolean;
  isEditDisabled?: boolean;
  isAddCardButtonDisabled?: boolean;
  isActive?: boolean;
  index?: number;
  currentStepIndex?: number;
  selectedItemType?: 'processStep' | 'condition' | 'parallelBranchesGroups';
  handleAddCard: () => void;
  handleAddCondition: () => void;
  handleAddParallelStepsGroup: () => void;
  handleParallelBranchStepAdd: (parallelBranchesGroup: ParallelBranchesGroup, branchIndex: number, stepPosition: number) => void;
  handleProcessCardClick: (processStep: ProcessStep) => void;
  handleParallelStepsGroupCardClick: () => void;
  isFlowPreview?: boolean;
}

export const ParallelStepsGroupsCard = ({
  isAddConditionButtonAvailable = true,
  isAddParallelBranchesButtonAvailable = true,
  tagSize = 'small',
  conditions,
  parallelBranchesGroups,
  currentLanguage,
  parallelBranchesGroup,
  parallelStepsList,
  documents,
  formErrors,
  departmentsList,
  directManagerSteps,
  performerSelectionSteps,
  isReadonlyView = false,
  isEditDisabled = false,
  isAddCardButtonDisabled = false,
  isActive = false,
  index,
  currentStepIndex,
  selectedItemType,
  handleAddCard,
  handleAddCondition,
  handleAddParallelStepsGroup,
  handleParallelBranchStepAdd,
  handleProcessCardClick,
  handleParallelStepsGroupCardClick,
  isTemplatePreview,
  isFlowPreview,
}: ConditionStepCardProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useConditionStepCardStyles();

  const { index: firstErrorStepIndex, setEl, ref: firstErrorStepEl } = useContext(FirstErrorStepIndexContext) || {};

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [addMenuAnchorEl, setAddMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [addBranchStepMenuAnchorEl, setAddBranchStepMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [branchIndex, setBranchIndex] = useState(0);

  const rootRef = useRef(null);
  const parallelBranchesCardRef = useRef(null);

  const {
    setIsOpenConfirmParallelBranchesDeleteModal,
    setDataForParallelBranchesDelete,
  } = useContext(ConfirmDeleteParallelBranchesModalContext);

  useEffect(() => {
    if (firstErrorStepIndex === index) {
      setEl?.(rootRef.current);
    }
  }, [firstErrorStepIndex]);

  return (
    <div
      ref={rootRef}
      className={cn(classes.wrapper, { [classes.wrapperPreview]: isTemplatePreview })}>

      {!isReadonlyView &&
        (<IconButton
        className={classes.processStepAddStep}
        onClick={({ currentTarget }) => {
          setAddMenuAnchorEl(currentTarget);
        }}
        disabled={isAddCardButtonDisabled}
        size="large">
          <img src={PlusIcon} className={classes.processStepAddStepIcon}/>
        </IconButton>)
      }

      <Box className={cn({ [classes.conditionCardWrapperWithError]: false })}>
        <div
          className={cn(classes.processStepCard, {
            [classes.processStepCardActive]: isActive,
            [classes.processStepCardError]: !isActive && (formErrors[parallelBranchesGroup?.previousStepOrder - 1]?.parallelBranchesName.length > 0 || formErrors[parallelBranchesGroup?.previousStepOrder - 1]?.lengthLimit.parallelBranchesName.length > 0),
          })}
          onClick={handleParallelStepsGroupCardClick}
          ref={parallelBranchesCardRef}
        >
          <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
            <ProcessStepTag
              size={tagSize}
              stepOrder={parallelBranchesGroup?.previousStepOrder}
              isParallelStepsGroup
            />

            {(!isReadonlyView && !isEditDisabled) && (
              <IconButton
                size="small"
                onClick={({ currentTarget }) => {
                  setAnchorEl(currentTarget);
                }}
              >
                <MoreHoriz fontSize="small" color="secondary"/>
              </IconButton>
            )}
          </div>

          <Box pt={3}>
            {parallelBranchesGroup?.stepName[currentLanguage]
             ? <Typography
               className={classes.processStepCardName}>{parallelBranchesGroup?.stepName[currentLanguage]}</Typography>
             : <Typography
               className={classes.processStepCardNamePlaceholder}>{t('customProcesses.processStep.conditionNamePlaceholder')}</Typography>
            }
          </Box>

          <Box className={classes.processStepCardBranches}>
            <img src={BranchesIcon} className={classes.processStepCardIcon}/>
            <Typography className={classes.processStepCardName}>
              {`${t('customProcesses.processStep.branches')}: ${parallelBranchesGroup?.steps.length}`}
            </Typography>
          </Box>
        </div>

        {(!isActive && (formErrors[parallelBranchesGroup?.previousStepOrder - 1]?.parallelBranchesName.length > 0 || formErrors[parallelBranchesGroup?.previousStepOrder - 1]?.lengthLimit.parallelBranchesName.length > 0)) &&
          <div className={classes.processStepCardErrorMessage}>
            <img src={WarningIcon} alt="warning"/>
            <span>{t('customProcesses.creationPage.processFormErrors.requiredFieldsNotFilled')}</span>
          </div>
        }
      </Box>

      <Box className={classes.branchesWrapper}>
        <div className={classes.topLine}/>

        <Box className={classes.branchCardsWrapper}>
          {parallelStepsList.map((branch, branchIndex) =>
            <div className={classes.branchWrapper}>
              {
                branch.map((step, stepIndex) => {
                    const documentSignaturesCount = documents?.filter(doc => doc.signatureSteps.includes(step?.stepOrder))?.length;
                    return <ProcessStepCard
                      isFlowPreview={isFlowPreview}
                      isTemplatePreview={isTemplatePreview}
                      tagStepOrder={getDisplayedStepOrder(step, conditions, parallelBranchesGroups)}
                      language={currentLanguage}
                      processStep={step as ProcessStep}
                      index={step?.stepOrder - 1}
                      documentSignaturesCount={documentSignaturesCount}
                      stepErrors={formErrors?.length ? formErrors[step?.stepOrder - 1] : {} as StepValidationResult}
                      departmentsList={departmentsList}
                      isActive={selectedItemType === 'processStep' && currentStepIndex === step?.stepOrder - 1}
                      hideLines
                      isMenuButtonShown
                      isAddConditionButtonAvailable={isAddConditionButtonAvailable}
                      isAddParallelBranchesButtonAvailable={isAddParallelBranchesButtonAvailable}
                      handleAddCard={() => handleParallelBranchStepAdd(parallelBranchesGroup, branchIndex, stepIndex)}
                      isDirectManagerStep={directManagerSteps?.includes(step?.stepOrder)}
                      isPerformerStep={performerSelectionSteps?.includes(step?.stepOrder - 1)}
                      isAutostartActive={false}
                      handleClick={() => handleProcessCardClick(step)}
                      isReadonlyView={isReadonlyView}
                    />;
                  },
                )}

              <div
                className={cn(classes.addBranchStepButtonWrapper, { [classes.addBranchStepButtonWrapperPreview]: isTemplatePreview })}>
                {
                  (!isFlowPreview && !isTemplatePreview && !isReadonlyView && !isEditDisabled) && <IconButton
                    className={classes.addBranchStepButton}
                    onClick={({ currentTarget }) => {
                      setBranchIndex(() => branchIndex);
                      setAddBranchStepMenuAnchorEl(currentTarget);
                    }}
                    size="large">
                    <img src={PlusIcon} className={classes.processStepAddStepIcon}/>
                  </IconButton>
                }
              </div>
            </div>)
          }
        </Box>

        <div className={classes.bottomLine}/>
      </Box>

      <ProcessStepAddMenu
        anchorElement={addMenuAnchorEl}
        handleAddCondition={handleAddCondition}
        handleAddParallelStepsGroup={handleAddParallelStepsGroup}
        handleAddStep={handleAddCard}
        hide
        isAddConditionButtonAvailable={true}
        isAddParallelBranchesButtonAvailable={true}
        open={Boolean(addMenuAnchorEl)}
        setAnchorElement={setAddMenuAnchorEl}
      />

      <ProcessStepAddMenu
        anchorElement={addBranchStepMenuAnchorEl}
        handleAddCondition={handleAddCondition}
        handleAddParallelStepsGroup={handleAddParallelStepsGroup}
        handleAddStep={() => handleParallelBranchStepAdd(parallelBranchesGroup, branchIndex, parallelBranchesGroup?.steps[branchIndex]?.length)}
        hide
        isAddConditionButtonAvailable={false}
        isAddParallelBranchesButtonAvailable={false}
        open={Boolean(addBranchStepMenuAnchorEl)}
        setAnchorElement={setAddBranchStepMenuAnchorEl}
      />

      <Menu
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        open={Boolean(anchorEl)}
        MenuListProps={{
          style: {
            display: 'flex',
            flexDirection: 'column',
          },
        }}
        PaperProps={{
          style: {
            border: '1px solid rgba(38, 40, 66, 0.08)',
            boxShadow: '0px 8px 16px rgba(38, 40, 66, 0.04)',
            borderRadius: 10,
            marginTop: 4,
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => {
          setIsOpenConfirmParallelBranchesDeleteModal(true);
          setDataForParallelBranchesDelete(parallelBranchesGroup);
          setAnchorEl(null);
        }}>
          <Box>
            {t('customProcesses.creationPage.buttons.deleteParallelBranches')}
          </Box>
        </MenuItem>
      </Menu>

    </div>
  );
};
