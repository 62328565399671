import makeStyles from '@mui/styles/makeStyles';

const baseValue = 31;
export default makeStyles(({ palette, spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: "wrap",
    zIndex: 1099,
    background: '#ffffff',
  },
  rootRequests: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: "wrap",
    zIndex: 1099,

    // "& $subpageSelect": {
    //   padding: "0 24px"
    // },
    //
    // "& > *:first-child": {
    //   marginLeft: 24
    // }
  },
  topPanel: {
    paddingLeft: 24,
    width: '100%',
    display: 'flex',
  },
  subpageSelect: {
    marginTop: "12px",
    flexBasis: "100%",
    display: "flex",
    gap: 8,
    paddingLeft: 24,
  },
  subpageSelectWithBackground: {
    paddingTop: "12px !important",
    background: "#F7F8FC",
    borderTop: "1px solid rgba(38, 40, 66, 0.08)"
  },
  contentActionTitle: {
    color: palette.grey[500],
  },
  baseButton: {
    border: '1px solid transparent',
    borderBottom: 'none',
    borderRadius: spacing(2),
    padding: spacing(3, 4, 2, 4),
    marginBottom: spacing(-0.25),
    zIndex: 99,
    gap: 10,

    fontWeight: 500,
    color: '#262842',

    '&:hover': {
      backgroundColor: 'rgba(38, 40, 66, 0.04)'
    }
  },
  baseButtonIcon: {
    height: spacing(5),
    width: spacing(5),
  },
  massSelectButton: {
    minWidth: 40,
    width: 'fit-content',
    height: 40,
    padding: 0,
    paddingLeft: 8,
    paddingRight: 8,
    marginRight: 12,
    borderRadius: 10,
    border: 'none',
    gap: 8,

    '& img': {
      width: 20,
      height: 20,
    },
    '& span': {
      color: '#262842',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 500,
    },
  },
  massSelectActiveButton: {
    backgroundColor: 'rgba(38, 40, 66, 0.08)'
  },
  popup: {
    zIndex: 100,
    position: 'fixed',
    backgroundColor: palette.background.default,
    top: 49,
    bottom: 0,
    right: 0,
    height: 'calc(100vh - 49px) !important',
    border: '1px solid #E0E3EA',
    width: 400,
    minHeight: 200,
    // padding: spacing(6, 6, 8),
    boxShadow: 'rgba(122, 122, 122, 0.12) 0 8px 48px',
    transition: '.6s',
    visibility: 'hidden',
    opacity: 0,
    transform: 'translate3d(30px, 0, 0)',
    '& input': {
      padding: '0 !important',
    },
  },
  popupSelect: {
    display: 'block',
    position: 'absolute',
    left: -59,
    top: baseValue + 15,
    width: 50,
    height: 42,
    borderRadius: '8px 0 0 8px',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#E0E3EA transparent #E0E3EA #E0E3EA',
    backgroundColor: palette.background.default,
    zIndex: 10,
    '&:before, &:after': {
      content: "''",
      display: 'block',
      borderWidth: 1,
      borderStyle: 'solid',
      position: 'absolute',
    },
    '&:before': {
      height: 16,
      width: 53,
      borderRadius: '0 0 8px 0',
      borderColor: 'transparent #E0E3EA #E0E3EA transparent',
      top: -16,
      right: -10,
    },
    '&:after': {
      height: 16,
      width: 53,
      borderRadius: '0 8px 0 0',
      borderColor: '#E0E3EA #E0E3EA transparent transparent',
      bottom: -16,
      right: -10,
    },
  },
  popupHigh: {
    '&:before': {
      top: '35px !important',
    },
    '&:after': {
      bottom: '35px !important',
    }
  },
  popupRight: {
    right: 0,
    left: 'auto',
    transform: 'translate3d(-30px, 0, 0)',
  },
  popupSelectRight: {
    left: 'auto',
    right: -59,
    borderRadius: '0 8px 8px 0',
    borderColor: '#E0E3EA #E0E3EA #E0E3EA transparent',
    zIndex: 10,
    '&:before': {
      borderRadius: '0 0 0 8px',
      borderColor: 'transparent transparent #E0E3EA #E0E3EA',
      left: -10,
    },
    '&:after': {
      borderRadius: '8px 0 0 0',
      borderColor: '#E0E3EA transparent transparent #E0E3EA',
      left: -10,
    },
  },
  popupOpen: {
    zIndex: 120,
    transform: 'translate3d(0, 0, 0)',
    visibility: 'visible',
    opacity: 1,
  },
  popupSelectHigh: {
    top: baseValue + 3,
    '&:before': {
      top: `${baseValue + 4} !important`,
    },
    '&:after': {
      top: `${baseValue - 5} !important`,
    },
  },
  filterButton: {
    display: 'flex',
    gap: 10,
    padding: '10px 16px',
    borderRadius: 10,
    height: 40,

    '&:hover': {
      backgroundColor: 'rgba(38, 40, 66, 0.04)'
    }
  },
  filterButtonActive: {
    background: '#F0F0F7',
    color: '#262842'
  },
  searchInput: {
    width: 260,
    height: '40px !important',
    marginRight: spacing(3),

    '& input': {
      padding: 0,
      height: 40,
    },
    '& > div': {
      borderRadius: 10,
      height: 40
    }
  },
  searchIconWrapper: {
    marginRight: 3,

    '& svg': {
      fill: '#8B8C9E',
      width: 17
    }
  },
  tab: {
    height: 40,
    padding: "11px 12px",
    borderRadius: 10,
    border: "1px solid rgba(38, 40, 66, 0.12)",
    background: "#F0F0F7",

    color: "#8B8C9E",
    fontSize: 14,
    fontWeight: 500,
  },
  activeTab: {
    background: "#DEF7FC",

    color: "#007994"
  },
  filtersBox: {
    position: "relative",
    display:"flex"
  }
}));
