import moment from 'moment';

export const MONTHS_EN = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

export const OPTIONS = {
  TODAY: 'Today',
  YESTERDAY: 'Yesterday',
  THIS_WEEK_MONDAY_TODAY: 'This week (Mon - Today)',
  LAST_7_DAYS: 'Last 7 days',
  LAST_WEEK: 'Last week (Mon - Sun)',
  LAST_14_DAYS: 'Last 14 days',
  LAST_30_DAYS: 'Last 30 days',
  ALL_TIME: 'All time',
};

export const OPTIONS_LABELS = {
  TODAY: 'form_components.date_period_picker.today',
  YESTERDAY: 'form_components.date_period_picker.yesterday',
  THIS_WEEK_MONDAY_TODAY: 'form_components.date_period_picker.this_week',
  LAST_7_DAYS: 'form_components.date_period_picker.last7',
  LAST_WEEK: 'form_components.date_period_picker.last_week',
  LAST_14_DAYS: 'form_components.date_period_picker.last14',
  LAST_30_DAYS: 'form_components.date_period_picker.last30',
  ALL_TIME: 'form_components.date_period_picker.all_time',
};

export const DEFAULT_OPTION = 'LAST_30_DAYS';

export const CUSTOM = 'CUSTOM';
export const N_DAYS_UP_TO_TODAY = 'N_DAYS_UP_TO_TODAY';
export const N_DAYS_UP_TO_TODAY_TEXT = 'days up to today';
export const N_DAYS_UP_TO_YESTERDAY = 'N_DAYS_UP_TO_YESTERDAY';
export const N_DAYS_UP_TO_YESTERDAY_TEXT = 'days up to yesterday';
export const DEFAULT_DAYS_INPUT_VALUE = '30';

export const STAY_OPEN_OPTIONS = [CUSTOM, N_DAYS_UP_TO_TODAY, N_DAYS_UP_TO_YESTERDAY];

const DATE_POINTS = {
  TODAY: moment(),
  YESTERDAY: moment().subtract(1, 'days'),
  DAYS_BEFORE_7: moment().subtract(7, 'days'),
  DAYS_BEFORE_14: moment().subtract(14, 'days'),
  DAYS_BEFORE_30: moment().subtract(30, 'days'),
  THIS_WEEK_MONDAY: moment().subtract(moment().isoWeekday() - 1, 'days'),
  LAST_WEEK_MONDAY: moment().subtract(moment().isoWeekday() + 6, 'days'),
  LAST_WEEK_SUNDAY: moment().subtract(moment().isoWeekday(), 'days'),
};

export const OPTIONS_DATES = {
  [OPTIONS.TODAY]: {
    from: DATE_POINTS.TODAY,
    to: DATE_POINTS.TODAY,
  },
  [OPTIONS.YESTERDAY]: {
    from: DATE_POINTS.YESTERDAY,
    to: DATE_POINTS.YESTERDAY,
  },
  [OPTIONS.THIS_WEEK_MONDAY_TODAY]: {
    from: DATE_POINTS.THIS_WEEK_MONDAY,
    to: DATE_POINTS.TODAY,
  },
  [OPTIONS.LAST_7_DAYS]: {
    from: DATE_POINTS.DAYS_BEFORE_7,
    to: DATE_POINTS.YESTERDAY,
  },
  [OPTIONS.LAST_14_DAYS]: {
    from: DATE_POINTS.DAYS_BEFORE_14,
    to: DATE_POINTS.YESTERDAY,
  },
  [OPTIONS.LAST_30_DAYS]: {
    from: DATE_POINTS.DAYS_BEFORE_30,
    to: DATE_POINTS.YESTERDAY,
  },
  [OPTIONS.LAST_WEEK]: {
    from: DATE_POINTS.LAST_WEEK_MONDAY,
    to: DATE_POINTS.LAST_WEEK_SUNDAY,
  },
  [OPTIONS.ALL_TIME]: {
    from: null,
    to: null,
  },
};

export const DEFAULT_DATE_FROM = OPTIONS_DATES[OPTIONS[DEFAULT_OPTION]].from.startOf('day');
export const DEFAULT_DATE_TO = OPTIONS_DATES[OPTIONS[DEFAULT_OPTION]].to.startOf('day');

export const DATE_FORMAT_TEMPLATE = 'MMM D, YYYY';

export const YEAR_MIN = 1940;
export const YEAR_MAX = 2040;
export const YEAR_DEFAULT = moment().year();
export const MONTH_DEFAULT = moment().month();

export const MONTHS_COUNT = 12;

export const DROPDOWN_HEIGHT = 506;
export const DROPDOWN_WIDTH = 533;
