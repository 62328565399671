import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = {
  name: string;
  rules: { [key: string]: string };
  params: {
    max?: number | string;
    isMultiline?: boolean;
    size?: 's' | 'm' | 'l';
  };
};

const DEFAULT_SIZE = 'm';
const DEFAULT_MAX_SYMBOL_LENGTH = 200;
const MAX_ROWS_FOR_SIZES = {
  s: 2,
  m: 4,
  l: 6,
};

const useTextarea = ({
  name,
  params: {
    max,
    isMultiline = false,
    size
  },
  rules
}: Props) => {
  const { t } = useTranslation();
  const {
    setValue,
    watch,
    trigger,
    formState: {
      errors
    }
  } = useFormContext();

  const [hasInput, setHasInput] = useState<boolean>(false);

  const maxSymbolLength = useMemo((): number =>
      max ?
        Number.parseInt(max as string) :
        DEFAULT_MAX_SYMBOL_LENGTH,
    [max]);

  const validate = useCallback(value => {
    if (!Object.keys(rules).includes('required')) {
      return true;
    }
     return value?.trim()?.length > 0 || t('form_components.required_errors.default') as string
  }, [rules]);

  const maxRows = useMemo((): number =>
    MAX_ROWS_FOR_SIZES[size] || MAX_ROWS_FOR_SIZES[DEFAULT_SIZE],
    [size]);

  const componentRules = useMemo(() => ({
      ...rules,
      maxLength: {
        value: maxSymbolLength,
        message: t('form_components.validation_errors.max200symbols').replace('200', maxSymbolLength.toString()),
      },
      validate,
    }), [rules, maxSymbolLength, validate]);

  const isValueEmpty = useCallback((value: string): boolean =>
    value.trim().length === 0,
    []);

  const checkInput = useCallback(
    (value: string): void => setHasInput(!isValueEmpty(value)),
    [isValueEmpty]
  );

  useEffect(() => {
    // to show clear field button if field is initially not empty (for example, on rework)
    const value = watch(name) || '';
    setHasInput(!isValueEmpty(value.toString()));
  }, [name])

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;

    setValue(name, newValue);
    checkInput(newValue);

    !!errors[name] && trigger(name);
  };

  const handleOnClear = (): void => {
    setValue(name, '');
    setHasInput(false);
  };

  return {
    hasInput,
    isMultiline,
    maxRows,
    componentRules,
    handleOnClear,
    handleOnChange,
  };
};

export default useTextarea;
