import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { removeSpacesFromNumber } from 'utils/general';

import {
  MAX_VALUE_LIMIT,
  PAYMENT__NAME,
  PAYMENT__POST_PAYMENT__VALUE,
  PREPAYMENT__NAME,
} from './constants';
import { useTranslation } from 'react-i18next';

type Props = {
  name: string;
  rules: { [key: string]: string };
  isActive: boolean;
  isDisabledParam: boolean;
}

const useNumber = ({ name, rules, isActive, isDisabledParam }: Props) => {
  const { t } = useTranslation();
  const { watch } = useFormContext();

  const [disabled, setDisabled] = useState(false);

  const isPrePayment = useMemo((): boolean =>
    name === PREPAYMENT__NAME,
    [name]);

  const isPaymentTypePostPayment = useMemo((): boolean =>
    watch(PAYMENT__NAME) === PAYMENT__POST_PAYMENT__VALUE,
    [watch]);

  const isDisabled = useMemo((): boolean =>
    (isPrePayment && isPaymentTypePostPayment) || !isActive,
    [isPrePayment, isPaymentTypePostPayment, isActive, isDisabledParam]);

  const validate = useCallback((value: number): string | boolean => {
    if (isPrePayment && value > 100) {
      return t('form_components.validation_errors.percentMax') as string;
    }

    if (removeSpacesFromNumber(value) > MAX_VALUE_LIMIT) {
      return t('form_components.validation_errors.numberMax') as string;
    }

    return true;
  }, [isPrePayment]);

  const validationRules = useMemo(() => ({
    ...rules,
    validate: validate
  }), [rules, validate]);

  useEffect(() => {
    setDisabled(isDisabled);
  }, [isDisabled]);

  return {
    disabled,
    validationRules
  }
}

export default useNumber;
