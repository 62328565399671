import React, { useMemo } from 'react';

import classNames from 'classnames';

import PlusIcon from 'assets/images/icons/plus.svg';

import useStyles from './index.useStyles';

interface StatusIconProps {
  condition?: boolean;
  parallel?: boolean;
  step?: boolean;
  disabled?: boolean;
  size?: number;
}

const StatusIcon = (props: StatusIconProps) => {
  const { condition = false, parallel = false, disabled = false, size = 24 } = props;
  const classes = useStyles({size});

  const iconClassName = useMemo(() => classNames(classes.iconStatus, {
    [classes.greenBackground]: parallel,
    [classes.purpleBackground]: condition,
    [classes.grayBackground]: disabled,
  }), [condition, parallel, disabled]);

  const stepIconClassName = useMemo(() => classNames({
    [classes.stepIcon]: true,
    [classes.stepIconDisabled]: disabled 
  }), [disabled]);

  const plusIconClassName = useMemo(() => classNames(classes.plusIcon, { [classes.revertPlusIcon]: parallel }), [parallel]);

  if (condition || parallel) {
    return (
      <div className={iconClassName}>
        <img src={PlusIcon} className={plusIconClassName}/>
      </div>
    );
  }

  return <div className={stepIconClassName} />

};

export default StatusIcon;
