import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, FormControl, IconButton, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import cn from 'classnames';
import moment from 'moment';

import { AppTextField } from 'components';
import {
  ProcessStepTag,
} from '../index';

import {
  AllProcessFieldsListItem,
  FormField,
  Language,
  ParallelBranchesGroup, ProcessStep,
  StepValidationResult,
} from '../../TemplateProcesses.types';

import useParallelStepsGroupFormStyles from './ParalleStepsGroupForm.useStyles';

import WarningIcon from 'assets/images/icons/warning-sign.svg';
import PlusIcon from 'assets/images/icons/tertiary-plus-sign.svg';
import DeleteIcon from 'assets/images/icons/tertiary-delete-icon.svg';
import { useUsersState } from 'store/users';
import InfoIcon from 'assets/images/icons/template-processes-preview-info.svg';
import ParallelBranchesIcon from 'assets/images/icons/template-processes-parallel-branches-info.svg';
import { useFormatConditionValue } from 'pages/TemplateProcesses/useFormatConditionValue';
import { MAX_NAME_LENGTH, MAX_PARALLEL_BRANCHES_COUNT, STEPS_NUMBER_LIMIT } from '../../TemplateProcesses.constants';
import { DeleteParallelBranchesConfirmationDialog } from '../DeleteParallelBranchesConfirmationDialog';
import { DeleteBranchConfirmationDialog } from '../DeleteBranchConfirmationDialog';

const LOCALES = {
  'en': 'en-GB',
  'kz': 'kk',
  'ru': 'ru',
  'id': 'id',
};

interface ProcessStepFormProps {
  isReadonlyView?: boolean;
  isEditDisabled?: boolean;
  currentLanguage: Language;
  languages: Language[];
  processSteps: ProcessStep[];
  parallelBranchesGroup?: ParallelBranchesGroup;
  formErrors: StepValidationResult[];
  allProcessStepsFields: AllProcessFieldsListItem[];
  handleDisplayedVersionToggle: () => void;
  handleParallelStepsGroupValuesChange: (newValue) => void;
  handleAddBranch: (parallelStepsGroup: ParallelBranchesGroup) => void;
  handleBranchDelete: (parallelStepsGroup: ParallelBranchesGroup, index: number) => void;
  openStepsLimitReachedDialog: () => void;
}

export const ParallelStepsGroupForm = ({
  isReadonlyView = false,
  isEditDisabled = false,
  currentLanguage,
  processSteps,
  parallelBranchesGroup,
  formErrors,
  handleDisplayedVersionToggle,
  handleParallelStepsGroupValuesChange,
  handleAddBranch,
  handleBranchDelete,
  openStepsLimitReachedDialog,
}: ProcessStepFormProps): ReactElement => {
  const { t, i18n } = useTranslation();
  moment.locale(LOCALES[i18n.language] || i18n.language);
  const classes = useParallelStepsGroupFormStyles();
  const { users } = useUsersState();
  const { formatConditionValue, conditionValueFormatted } = useFormatConditionValue();

  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);
  const [deletedBranchIndex, setDeletedBranchIndex] = useState(0);

  const handleNameChange = (value) => {
    const newValue = {
      ...parallelBranchesGroup,
      stepName: {
        ...parallelBranchesGroup?.stepName,
        [currentLanguage]: value,
      },
    };
    handleParallelStepsGroupValuesChange(newValue);
  };

  const handleAddParallelBranch = () => {
    if (processSteps.length < STEPS_NUMBER_LIMIT) {
      handleAddBranch(parallelBranchesGroup);
    } else {
      openStepsLimitReachedDialog();
    }
  };

  if (isReadonlyView) {
    return <Box className={classes.processStepForm}>
      <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="flex-start">
          <ProcessStepTag size="small" stepOrder={parallelBranchesGroup?.previousStepOrder} isParallelStepsGroup/>
        </Box>
      </Box>

      <Box className={classes.readonlyProcessInfoWrapper}>
        <Box className={classes.readonlyProcessInfoTitleWrapper}>
          <img src={InfoIcon} className={classes.readonlyProcessInfoTitleIcon}/>
          <h2 className={classes.readonlyProcessInfoTitle}>
            {t('customProcesses.readonlyView.stepInfo')}
          </h2>
        </Box>

        <Box className={classes.readonlyProcessInfoRow}>
          <Box className={classes.readonlyProcessInfoTitleColumn}>
            {t('customProcesses.creationPage.fields.name')}
          </Box>
          <Box className={classes.readonlyProcessInfoContentColumn}>
            {parallelBranchesGroup?.stepName[currentLanguage]}
          </Box>
        </Box>
      </Box>

      <Box className={classes.readonlyProcessInfoWrapper}>
        <Box className={classes.readonlyProcessInfoTitleWrapper}>
          <img src={ParallelBranchesIcon} className={classes.readonlyProcessInfoTitleIcon}/>
          <h2 className={classes.readonlyProcessInfoTitle}>
            {t('customProcesses.creationPage.processStepTag.parallelStepsGroup')}
          </h2>
        </Box>

        <Box className={classes.readonlyProcessInfoRow}>
          <Box className={classes.readonlyProcessInfoTitleColumn}>
            {t('customProcesses.creationPage.parallelStepsGroupForm.branches', { defaultValue: 'Branches' })}
          </Box>
          <Box className={classes.readonlyProcessInfoContentColumn}>
            {parallelBranchesGroup?.steps.length}
          </Box>
        </Box>
      </Box>
    </Box>;
  }

  return (
    <Box className={classes.processStepForm}>
      {isEditDisabled && (<Box className={classes.processStepFormEditingDisabledIndicator}>
          <p>
            <Trans i18nKey="customProcesses.creationPage.processForm.editingDisabledMessage">
              Редактирование недоступно, потому что есть <span onClick={handleDisplayedVersionToggle}>версия</span> с
              неопубликованными изменениями
            </Trans>
          </p>
        </Box>
      )}

      <Box width="100%" display="flex" alignItems="center" justifyContent="space-between" pb={3}>
        <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="flex-start">
          <ProcessStepTag size="small" stepOrder={parallelBranchesGroup?.previousStepOrder} isParallelStepsGroup/>
        </Box>
      </Box>

      <Box className={classes.processStepFormSection}>
        <Typography className={classes.processStepFormSectionTitle}>
          {t('customProcesses.creationPage.parallelStepsGroupForm.sections.general')}
        </Typography>

        <Typography className={classes.processStepFormSectionInfo}>
          {t('customProcesses.creationPage.parallelStepsGroupForm.sections.generalInfo')}
        </Typography>

        <Box display="flex" flexDirection="column">
          <FormControl fullWidth>
            <AppTextField
              disabled={isEditDisabled}
              // inputRef={nameFieldRef}
              size="medium"
              className={cn(classes.stepNameField)}
              label={<span className={classes.requiredFieldTitle}>
              {t('customProcesses.creationPage.parallelStepsGroupForm.fields.name')}
            </span>}
              error={formErrors[parallelBranchesGroup?.previousStepOrder - 1]?.parallelBranchesName.includes(currentLanguage)}
              helperText={
                <>
                  {formErrors[parallelBranchesGroup?.previousStepOrder - 1]?.parallelBranchesName.includes(currentLanguage)
                   ? <div className={classes.processStepFormErrorMessage}>
                     <img src={WarningIcon} alt="warning"/>
                     <span>{t('customProcesses.creationPage.processFormErrors.requiredField')}</span>
                   </div>
                   : null}

                  {parallelBranchesGroup?.stepName[currentLanguage]?.length > MAX_NAME_LENGTH
                   ? <div
                     className={cn(classes.processStepFormErrorMessage, classes.processStepFormErrorMessageTwoSides)}>
                     <div>
                       <img src={WarningIcon} alt="warning"/>
                       <span>{t('customProcesses.creationPage.processFormErrors.valueTooLong')}</span>
                     </div>

                     <div>
                       {parallelBranchesGroup?.stepName[currentLanguage]?.length || 0}/{MAX_NAME_LENGTH}
                     </div>
                   </div>
                   : null
                  }
                </>
              }
              placeholder={t('customProcesses.creationPage.parallelStepsGroupForm.fields.namePlaceholder')}
              value={parallelBranchesGroup?.stepName[currentLanguage]}
              defaultValue={parallelBranchesGroup?.stepName[currentLanguage]}
              onChange={event => handleNameChange(event.target.value)}
            />
          </FormControl>

        </Box>
      </Box>

      <Box className={classes.processStepFormSection}>
        <Typography className={classes.processStepFormSectionTitle}>
          {t('customProcesses.creationPage.parallelStepsGroupForm.sections.branches')}
        </Typography>

        {parallelBranchesGroup?.steps.map((_, index) =>
          <Box className={classes.branchCardWrapper}>
            <Typography className={classes.branchCardText}>
              {t('customProcesses.creationPage.parallelStepsGroupForm.branchCardText', { index: index + 1 })}
            </Typography>

            {!isEditDisabled &&
              <IconButton
                onClick={() => {
                  setDeletedBranchIndex(index);
                  setIsDeleteConfirmationModalOpen(true);
                }}
                size="large">
                <img src={DeleteIcon} className={classes.branchCardDeleteButton}/>
              </IconButton>
            }
          </Box>,
        )}

        {parallelBranchesGroup?.steps?.length < MAX_PARALLEL_BRANCHES_COUNT
         ?
         <Box display="flex" flexDirection="column">
           <Button
             className={classes.addBranchButton}
             variant="outlined"
             color="secondary"
             fullWidth
             onClick={handleAddParallelBranch}
             disabled={isEditDisabled}
           >
             <img src={PlusIcon} alt={'add'}/>
             <span>{t('customProcesses.creationPage.parallelStepsGroupForm.addBranchButton', { defaultValue: 'Branch' })}</span>
           </Button>
         </Box>
         :
         <Box className={classes.branchesLimitMessageWrapper}>
           <Typography className={classes.branchesLimitMessageText}>
             {t('customProcesses.creationPage.parallelStepsGroupForm.branchesLimitReached')}
           </Typography>
         </Box>
        }
      </Box>

      <DeleteBranchConfirmationDialog
        isOpen={isDeleteConfirmationModalOpen}
        onClose={() => setIsDeleteConfirmationModalOpen(false)}
        onConfirm={() => handleBranchDelete(parallelBranchesGroup, deletedBranchIndex)}
      />

    </Box>
  );
};
