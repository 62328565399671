export const LOCALIZED_TIME_UNITS = {
  'ru': {
    day: 'д',
    hour: 'ч',
  },
  'en': {
    day: 'd',
    hour: 'h',
  },
  'kz': {
    day: 'к',
    hour: 'c',
  },
  'de': {
    day: 's',
    hour: 't',
  },
  'id': {
    day: 'h',
    hour: 'j',
  },
  default: {
    day: 'd',
    hour: 'h',
  },
};

export const formatTimeFromMinutes = (minutes: number, locale = 'en') => {
  const hTotal = Math.floor(minutes / 60);
  const m = minutes % 60;
  const d = Math.floor(hTotal / 24);
  const h = hTotal % 24;
  if (d) {
    const localizedUnits = LOCALIZED_TIME_UNITS[locale] || LOCALIZED_TIME_UNITS['default'];
    return `${d}${localizedUnits.day}:${(h + '').padStart(2, '0')}${localizedUnits.hour}`
  }

  return `${h}:${(m + '').padStart(2, '0')}`
}

export const nearestFutureMinutes = (interval, someMoment) => {
  const roundedMinutes = Math.ceil(someMoment.minute() / interval) * interval;
  return someMoment.clone().minute(roundedMinutes).second(0);
}

export const getCorrectTimeWithISOFormat = () => {
  return new Date().toISOString();
};
