import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ spacing, palette }) => ({
  DirectManagerCheckbox: {

  },
  checkboxWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: spacing(2),
    marginRight: spacing(1),
  },
  checkbox: {
    marginLeft: spacing(-0.75),
    marginRight: spacing(1),
    '& svg path': {
      fill: '#039BE6 !important'
    }
  },
  root: {
    color: "#8B8C9E", // Цвет по умолчанию
    "&$disabled": {
      color: "#C0C1D1", // Цвет для отключенного состояния
    },
    "&$checked": {
      color: "#039BE6", // Задайте цвет для состояния checked
    },
  },
  checked: {},
  disabled: {},
  checkboxChecked: {
    '& svg path': {
      fill: '#039BE6 !important'
    }
  },
  checkboxDisabled: {
    '& path': {
      fill: '#2628421F !important'
    }
  },
  checkboxLabel: {
    marginLeft: 0,
  },
  defaultCursor: {
    cursor: 'default'
  },
  infoIcon: {
    height: 20,
    width: 20,
    marginLeft: 4
  },
  tooltipPopper: {
    pointerEvents: 'all',
  },
  helpTooltip: {
    width: 320,
    height: 'max-content',
  },
  helpTooltipContent: {
    width: 300,
    height: 'max-content',
    '& p': {
      margin: 0,
      marginBottom: spacing(5),
      '&:last-child': {
        marginBottom: 0
      }
    }
  },
  checkboxMessageWrapper: {},
  checkboxMessage: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    color: palette.grey[500]
  },
}));
