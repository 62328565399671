import React from 'react';
import { useTranslation } from 'react-i18next';

import { EdsButtonCallHook } from './EdsButtonCallHook';
import { EdsButtonCallModal } from './EdsButtonCallModal';

import useEdsButton from './useEdsButton';

import { EDS_FORM } from '../EdsConstants';

type Props = {
  type: 'button';
  variant: 'contained';
  color: 'primary';
  label: string;
  className?: string;
  wrapperClassName?: string;
  userModelId: string;
  attachmentName: string;
  attachmentStorage?: string;
  binAttribute: string;
  companyAttribute: string;
  individualNumberAttribute: string;
  emailAttribute: string;
  supplierEmailAttribute: string;
  existingSupplierAttribute: string;
  supplierBinAttribute: string;
  disabled: boolean;
  isInternalSign: boolean;
  isExternalSign: boolean;
  isInternalSignWithEmployee: boolean;
  isInternalSignEmployeeInitiator: boolean;
  onClick: () => void;
  icon?: string | React.ReactNode;
};

export const EdsButton = ({
  type,
  variant,
  label,
  color,
  className,
  wrapperClassName,
  disabled,
  userModelId,
  attachmentName,
  attachmentStorage,
  binAttribute,
  companyAttribute,
  individualNumberAttribute,
  emailAttribute,
  supplierEmailAttribute,
  existingSupplierAttribute,
  supplierBinAttribute,
  isInternalSign,
  isExternalSign,
  isInternalSignWithEmployee,
  isInternalSignEmployeeInitiator,
  onClick,
  icon,
}: Props) => {
  const { isModalOpen, handleButtonClick, handleModalState } = useEdsButton({
    onClick,
  });
  const { t } = useTranslation();

  if (isInternalSignEmployeeInitiator) {
    return (
      <EdsButtonCallHook
        type={type}
        variant={variant}
        color={color}
        className={className}
        wrapperClassName={wrapperClassName}
        label={label || t(EDS_FORM.EDS_OPEN_MODAL_LABEL)}
        disabled={disabled}
        isInternalSign={isInternalSign}
        isExternalSign={isExternalSign}
        isInternalSignWithEmployee={isInternalSignWithEmployee}
        isInternalSignEmployeeInitiator={isInternalSignEmployeeInitiator}
        attachmentName={attachmentName}
        attachmentStorage={attachmentStorage}
        binAttribute={binAttribute}
        companyAttribute={companyAttribute}
        individualNumberAttribute={individualNumberAttribute}
        emailAttribute={emailAttribute}
        supplierEmailAttribute={supplierEmailAttribute}
        existingSupplierAttribute={existingSupplierAttribute}
        supplierBinAttribute={supplierBinAttribute}
        userModelId={userModelId}
        onClick={handleButtonClick}
        icon={icon}
      />
    );
  }

  return (
    <EdsButtonCallModal
      type={type}
      label={label || t(EDS_FORM.EDS_OPEN_MODAL_LABEL)}
      variant={variant}
      color={color}
      className={className}
      isModalOpen={isModalOpen}
      disabled={disabled}
      attachmentName={attachmentName}
      attachmentStorage={attachmentStorage}
      binAttribute={binAttribute}
      companyAttribute={companyAttribute}
      individualNumberAttribute={individualNumberAttribute}
      emailAttribute={emailAttribute}
      supplierEmailAttribute={supplierEmailAttribute}
      existingSupplierAttribute={existingSupplierAttribute}
      supplierBinAttribute={supplierBinAttribute}
      isInternalSign={isInternalSign}
      isExternalSign={isExternalSign}
      isInternalSignWithEmployee={isInternalSignWithEmployee}
      userModelId={userModelId}
      onClick={handleButtonClick}
      handleModalState={handleModalState}
      icon={icon}
    />
  );
};
