import { environment } from 'environments/environment';
import { SocketClient } from '../../utils/websocket';
import { baseV1ApiClient } from '../clients';
import { commonExceptionHandler } from '../handlers';

export const completeResultWS = () => {
  const url = `${environment.NX_BASE_V1_WS_API}/camunda/complete`;

  return SocketClient.getInstance({ url });
}

export const getWSClientForEvents = () => {
  const url = `${environment.NX_BASE_V1_WS_API}/events`;

  return SocketClient.getInstance({ url });
}

export const sendTestEvent = async (userId, documentId) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: `/docflow/kafka-test/send`,
      params: {
        userId,
        documentId
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};
