
import React from 'react';

import { Box, Button } from '@mui/material';
import useUserDropdownStyles from './UserList.useStyles';
import { useTranslation } from 'react-i18next';

interface FinalActionButtonsProps {
  handleDropdownClear: () => void;
  handleDropdownSubmit: () => void;
  selectedUsersId: string[];
  hasChanges: boolean;
}

const FinalActionButtons = (props: FinalActionButtonsProps) => {
  const {
    handleDropdownClear,
    handleDropdownSubmit,
    selectedUsersId,
    hasChanges,
  } = props;
  const classes = useUserDropdownStyles({});
  const { t } = useTranslation();
  return (
    <Box className={classes.userDropdownMenuFooter}>
      <Button variant="text" onClick={handleDropdownClear} disabled={!selectedUsersId?.length}>
        {t('form_components.select.clear')}
      </Button>
      <Button variant="text" onClick={handleDropdownSubmit} disabled={!hasChanges || !selectedUsersId?.length}>
        {t('form_components.select.apply')}
      </Button>
    </Box>
  )
}

export default FinalActionButtons;
