import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '200px',
    display: 'flex',
    alignItems: 'stretch',
    position: 'relative',
    marginBottom: 5,
  },
  root: {
    width: '100%',
    fontSize: 16,
  },
  inputBase: {
    width: '100%',
    borderRadius: 8,
    height: '32px',
    border: '2px solid #007994',
    background: 'white',
    // flexDirection: 'row-reverse',
    padding: '0 12px',
    '& input': {
      padding: '10px 12px',
      // transition: theme.transitions.create(['border-color', 'box-shadow']),
      fontSize: 14,
      '&:active': {
        borderColor: 'red',
      },
      '&:focus': {
        borderColor: 'green',
      },
    },
  },
  outlinedButton: {
    width: '100%',
    height: '40px',
    textAlign: 'left',
    border: '1px solid rgba(38, 40, 66, 0.12)',
    borderRadius: '8px',
    alignItems: 'center',
    padding: '10px 8px',
    display: 'inline-flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
    // '&:hover': { border: `1px solid ${theme.palette.primary[200]}` },
    '&:active': { border: `2px solid #007994` },
  },
  outlinedErrorButton: {
    border: '1px solid rgb(214, 51, 31)',
  },
  documentPlaceholder: {
    color: 'rgb(139, 140, 158)',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
  },
  textButton: {
    width: '100%',
    height: '40px',
    textAlign: 'left',
    borderRadius: 4,
    alignItems: 'center',
    padding: '10px 8px',
    display: 'inline-flex',
    justifyContent: 'space-between',
  },
  checkbox: {
    '&.MuiCheckbox-root.Mui-checked path': {
      fill: '#007994 !important',
    },
    '& path': {
      fill: '#8B8C9E !important',
    },
  },
  checkboxDisabled: {
    '&.MuiCheckbox-root.Mui-checked path': {
      fill: '#A9A9B8 !important',
    },
    color: '#A9A9B8 !important',
  },
  accordionItem: {
    width: '100%',
    marginBottom: 2,
    cursor: 'pointer',

    '& svg': {
      '& path': {},
    },
  },
  accordionHeader: {
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: 'rgb(247, 248, 252)',
    padding: '0px 12px',

    '& > .MuiTypography-root': {
      color: 'rgb(38, 40, 66)',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '18px',
    },
  },
  accordionContent: {
    padding: '10px 12px',
    background: 'transparent !important',
  },
  resetFilterWrapper: {
    borderTop: '1px solid rgba(38, 40, 66, 0.12)',
    padding: 8,
  },
  resetFilterButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid rgba(38, 40, 66, 0.12)',
    borderRadius: '8px',
    background: 'rgb(247, 248, 252) !important',
    width: '100%',
    height: '100%',

    '& > .MuiTypography-root': {
      color: 'rgb(38, 40, 66)',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '18px',
    },
  },
  dateItem: {
    padding: '0px 12px',
    color: 'rgb(38, 40, 66)',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
    display: 'flex',
    alignItems: 'center',
    height: 32,

    '&:hover': {
      background: 'rgb(230, 245, 253)',
    },
  },
  dateItemSelected: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'rgb(230, 245, 253)',

    '& svg': {
      '& path': {
        fill: 'rgb(3, 155, 230)',
      },
    },
  },
  filterMenuListItem: {
    padding: '10px 12px',
    cursor: 'pointer',

    '&:hover': {
      background: 'rgb(230, 245, 253)',
    },
  },
  filterMenuListItemSelected: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'rgb(230, 245, 253)',

    '& svg': {
      '& path': {
        fill: 'rgb(3, 155, 230)',
      },
    },
  },
  filterItem: {
    padding: '0 !important',
    background: 'transparent !important',
  },
  customDate: {
    width: '100% !important',
    padding: '0px 10px',

    '& > .MuiInputBase-root': {
      width: '100% !important',
    },
  },
  tag: {
    border: '1px solid rgba(38, 40, 66, 0.12) !important',
    borderRadius: '6px !important',
    background: 'rgb(255, 255, 255) !important',
    height: '24px !important',
  },
  listbox: {
    '& .MuiAutocomplete-option': {
      '&:hover': {
        background: 'rgb(230, 245, 253) !important',
      },
    },
  },
  optionSelect: {
    '&:hover': {
      backgroundColor: 'rgb(230, 245, 253) !important', // Цвет при наведении на пункт
    },
  },
  rotateIcon: {
    transform: 'rotate(180deg)',
    transition: 'transform 0.3s ease-in-out',
  },
  rotateIconCollapsed: {
    transform: 'rotate(0deg)',
    transition: 'transform 0.3s ease-in-out',
  },
  docItem: {
    padding: '0px 12px',
    color: 'rgb(38, 40, 66)',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
    display: 'flex',
    alignItems: 'center',
    height: 50,

    '&:hover': {
      background: 'rgb(230, 245, 253)',
    },
  },
  docItemTitle: {
    color: 'rgb(38, 40, 66)',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
  },
  docItemDescription: {
    color: 'rgb(109, 110, 133)',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
  },
  docItemSelected: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'rgb(230, 245, 253)',

    '& svg': {
      '& path': {
        fill: 'rgb(3, 155, 230)',
      },
    },
  },
  menu: {
    width: 400,
  },
  autocompleteRoot: {
    '& .MuiOutlinedInput-root': {
      padding: '0px 10px',
    },

    '& .MuiAutocomplete-popupIndicator': {
      pointerEvents: 'none',
    },
  },
  labelStyle: {
    color: 'rgb(109, 110, 133)',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '18px',
  },
  labelError: {
    color: 'rgb(214, 51, 31)',
  },
  iconColor: {
    fill: 'rgb(139, 140, 158)',
    zIndex: 999,
  },
  iconIndex: {
    zIndex: 99,
  },
  backdrop: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
    zIndex: 20,
  },
}));
