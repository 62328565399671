import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
  summaryWrapper: {
    margin: '0 auto'
  },
  summaryStepWrapper: {
    width: 1240,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 40
  },
  group: {
    position: 'relative',
    width: 600,
  },
  groupTitle: {
    fontSize: 18,
    fontWeight: 600,
    color: '#262842',
    marginBottom: 8,
  },
  table: {
    width: '100%',
    borderSpacing: 0,
    '& td': {
      verticalAlign: 'top',
      padding: theme.spacing(2, 4, 2, 0)
    }
  },
  fieldName: {
    width: 240,
    fontSize: 14,
    fontWeight: 600,
    textAlign: 'left',
    color: '#6D6E85'
  },
  fieldValue: {
    fontSize: 14,
    fontWeight: 400,
    textAlign: 'left',
    color: '#262842'
  },
  documentsTable: {
    width: '100%',
    borderSpacing: 0,
    marginTop: theme.spacing(2),
    '& td': {
      verticalAlign: 'top',
      color: '#6D6E85',
      fontWeight: 500,
      padding: theme.spacing(2, 0),
      width: '100% !important'
    }
  },
  multipleSelectValue: {
    textAlign: 'left'
  },
  multipleSelectValueItem: {
    marginBottom: theme.spacing(2)
  },
  summaryUserWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  summaryUser: {
    '&:not(:first-child)': {
      marginTop: theme.spacing(1)
    }
  },
  textValueWrapper: {
    whiteSpace: 'pre-wrap',
  },
  emptyValueWrapper: {
    color: '#8B8C9E'
  },
  documentTableList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
}));
