import React, { memo } from 'react';
import { FormControl, Box, CircularProgress, TextField, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Autocomplete from '@mui/material/Autocomplete';
import { Controller, useFormContext } from 'react-hook-form';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { useTaskState } from 'store/requests';

import useGlossary from './useGlossary';
import useStyles from '../../useStyles';
import { TreeGlossary } from './TreeGlossary';
import { TreeGlossaryMultiSelect } from './TreeGlossaryMultiSelect';

type Props = {
  name?: string;
  hint?: string;
  params?: { [key: string]: unknown };
  masterField?: { [key: string]: unknown };
  secondMasterField?: { [key: string]: unknown };
  innerFields: unknown[];
  isActive?: boolean;
  isDisabled?: boolean;
  isTaskDetailsVariant?: boolean;
  showSingleOptionReadOnly?: boolean;
  rules?: {
    [key: string]: string;
  },
}

export const Glossary = memo(({
  name,
  hint,
  params,
  masterField,
  secondMasterField,
  innerFields,
  isActive,
  isDisabled,
  isTaskDetailsVariant = false,
  showSingleOptionReadOnly = false,
  rules,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data: bpmTask } = useTaskState();
  const { control } = useFormContext();
  const {
    loading,
    errors,
    options,
    value,
    selectedValues,
    inputValue,
    isValueFromOptions,
    handleChange,
    handleInputChange,
    getOptionLabel,
    getOptionSelected,
    isTree,
  } = useGlossary({
    name,
    params,
    masterField,
    secondMasterField,
    innerFields,
    showSingleOptionReadOnly,
  });

  const getIcon = () => {
    if (loading) {
      return (
        <Box mr={1}>
          <CircularProgress size={16}/>
        </Box>
      );
    }

    return <ArrowDropDownIcon/>;
  };

  const renderInput = (params) =>
    <Controller
      name={name}
      control={control}
      rules={
        rules?.required
        ? {
            required: t('form_components.validation_errors.listOption') as string,
            validate: isValueFromOptions,
          }
        : {
            validate: isValueFromOptions,
          }
      }
      render={() => (<>
          <TextField
            {...params}
            label={hint}
            inputProps={{ ...params.inputProps, 'data-selenium': name }}
            error={!!errors[name]}
            helperText={!!errors[name] && errors[name].message}
          />
        </>
      )}
    />;

  if (isTree || params.multiSelect) {
    return (
      <FormControl fullWidth>
        {params.multiSelect
         ?
         <TreeGlossaryMultiSelect
           params={params}
           onSelect={(value) => handleChange(null, value)}
           name={name}
           value={selectedValues}
           valueId={selectedValues}
           placeholder={params?.placeholder as string}
           hint={hint}
           errors={errors}
           control={control}
           forceEnglish={bpmTask && !bpmTask?.isTemplateProcessTask}
           rules={rules}
           isDisabled={isDisabled}
         />
         :
         <TreeGlossary
           params={params}
           onSelect={(value) => handleChange(null, value)}
           name={name}
           value={value}
           valueId={params?.isAcceptedValueTypeId ? value?.id || value?.name : null}
           placeholder={params?.placeholder as string}
           hint={hint}
           errors={errors}
           control={control}
           forceEnglish={bpmTask && !bpmTask?.isTemplateProcessTask}
           rules={rules}
           isDisabled={isDisabled}
         />
        }
      </FormControl>
    );
  }

  if (!!showSingleOptionReadOnly && options.length === 1) {
    return (
      <div>
        {!!hint && <Typography>{hint}</Typography>}
        <Typography
          color="primary"
          className={cn({
            [classes.taskDetailsReadOnly]: isTaskDetailsVariant,
          })}
        >
          <span>{value?.name}</span>
        </Typography>
      </div>
    );
  }

  return (
    <FormControl fullWidth>
      <Autocomplete
        fullWidth
        forcePopupIcon
        options={options}
        value={value}
        disabled={loading || !isActive || isDisabled}
        inputValue={inputValue}
        onChange={handleChange}
        onInputChange={handleInputChange}
        renderInput={renderInput}
        popupIcon={getIcon()}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={getOptionSelected}
      />
    </FormControl>
  );
});
