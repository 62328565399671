import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Box, IconButton } from '@mui/material';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { Editor, EditorState, ContentState, RichUtils, getDefaultKeyBinding, KeyBindingUtil, Modifier, convertFromHTML } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { useTranslation } from 'react-i18next';

import sendIconDisabled from 'assets/images/icons/send-disabled.svg';
import sendIconActive from 'assets/images/icons/send-active.svg';
import emailIcon from 'assets/images/icons/alternate_email.svg';

import useStyles from './useStyles';
import { getSelectedBlock, replaceString } from './utils';
import { CONTROL_BUTTONS, STYLE_MAP, STYLE_TYPES } from './constants';

type Props = {
  onChange?: (value: string) => void;
  onSubmit?: () => void;
  foundMentionValue?: string;
  fullMentionValue?: string;
  isUserMentionPopupOpen?: boolean;
  setFoundMentionValue?: (value: string) => void;
  setFullMentionValue?: (value: string) => void;
  setCommentEmpty?: (value: boolean) => void;
  showFilteredUsersList?: (value: string) => void;
  value?: string;
  inputId?: string;
};

export const CommentEditor = ({
  onChange,
  onSubmit,
  foundMentionValue,
  fullMentionValue,
  isUserMentionPopupOpen = false,
  setFoundMentionValue,
  setFullMentionValue,
  setCommentEmpty,
  showFilteredUsersList,
  value,
  inputId,
}: Props) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const editorRef = useRef<Editor>();

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [activeButtons, setActiveButtons] = useState([]);
  const [isSendButtonActive, setSendButtonActive] = useState(false);
  const [isHiddenPlaceHolder, setIsHiddenPlaceholder] = useState(false);

  const { hasCommandModifier } = KeyBindingUtil;

  const customKeyBinding = useCallback(
    (e) => {
      if (e.key === '@') showFilteredUsersList('');
      if (e.keyCode === 33 /* `PgUp` key */ || e.keyCode === 34 /* `PgDn` key */) {
        if (e.keyCode === 33) {
          // move focus to text start on PgUp
          editorRef?.current?.blur();
          editorRef?.current?.focus();
        }

        if (e.keyCode === 34) {
          // move focus to text end on PgDown
          const newEditorState = EditorState.moveFocusToEnd(editorState);
          setEditorState(newEditorState);
        }

        return 'handled';
      }

      if (e.keyCode === 13 && !hasCommandModifier(e) && !e.nativeEvent.shiftKey) {
        const textValue = editorState?.getCurrentContent()?.getPlainText()?.trim();

        if (!textValue) return;

        handleSubmit();
        return 'handled';
      }

      return getDefaultKeyBinding(e);
    },
    [editorRef, editorState, setEditorState]
  );

  const checkButtonActivity = useCallback(
    (styleKey) => {
      const block = getSelectedBlock(editorState);
      return editorState?.getCurrentInlineStyle()?.has(styleKey) || block?.getType() === styleKey;
    },
    [editorState]
  );

  const handleEditorFocus = useCallback(() => {
    const buttonsToEnable = CONTROL_BUTTONS.reduce((arr: string[], buttonGroup) => {
      buttonGroup.forEach((button) => {
        if (button?.activeInFocusedEditor) {
          arr.push(button.key);
        }
      });

      return arr;
    }, []);

    setActiveButtons(buttonsToEnable);
  }, [setActiveButtons]);

  const handleEditorBlur = useCallback(() => {
    const buttonsToDisable = CONTROL_BUTTONS.reduce((arr: string[], buttonGroup) => {
      buttonGroup.forEach((button) => {
        if (button?.activeInFocusedEditor) {
          arr.push(button.key);
        }
      });

      return arr;
    }, []);

    setActiveButtons((activeButtons) => activeButtons.filter((button) => !buttonsToDisable.includes(button)));
  }, [setActiveButtons]);

  const handleChange = useCallback(
    (newEditorState) => {
      setEditorState(newEditorState);
      const htmlContent = stateToHTML(newEditorState?.getCurrentContent());
      const textValue = newEditorState?.getCurrentContent()?.getPlainText()?.trim();

      onChange && onChange(textValue ? htmlContent : null);

      // fix not active button after style change
      if (!activeButtons.length) {
        handleEditorFocus();
      }
    },
    [editorState, onChange, activeButtons.length, handleEditorFocus]
  );

  const clearEditor = useCallback(() => {
    const newEditorState = EditorState.push(editorState, ContentState.createFromText(''), 'insert-fragment');
    const newEditorStateWithEndFocus = EditorState.moveFocusToEnd(newEditorState);
    setEditorState(newEditorStateWithEndFocus);
  }, [editorState, setEditorState]);

  const toggleInlineStyle = useCallback(
    (inlineStyle) => {
      const newEditorState = EditorState.forceSelection(editorState, editorState.getSelection());

      handleChange(RichUtils.toggleInlineStyle(newEditorState, inlineStyle));
    },
    [editorState, handleChange]
  );

  const toggleBlockType = useCallback(
    (blockType) => {
      const newEditorState = EditorState.forceSelection(editorState, editorState.getSelection());

      handleChange(RichUtils.toggleBlockType(newEditorState, blockType));
    },
    [editorState, handleChange]
  );

  useEffect(() => {
    const textValue = editorState?.getCurrentContent()?.getPlainText()?.trim();
    const htmlContent = stateToHTML(editorState?.getCurrentContent());

    setCommentEmpty?.(Boolean(textValue));
    setSendButtonActive(Boolean(textValue));

    if ((!textValue && htmlContent?.includes('ul')) || htmlContent?.includes('ol')) {
      setIsHiddenPlaceholder(true);
    } else {
      setIsHiddenPlaceholder(false);
    }
  }, [editorState, setSendButtonActive, setCommentEmpty]);

  useEffect(() => {
    if (!inputId) return;
    clearEditor();

    const blocksFromHTML = convertFromHTML(value || '<br />');
    const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

    const newEditorState = EditorState.createWithContent(state);

    setEditorState(newEditorState);
  }, [inputId]);

  useEffect(() => {
    // replace user mentions
    if (fullMentionValue) {
      const newEditorStateWithReplacedText = replaceString(editorState, foundMentionValue, fullMentionValue, 'BOLD');
      handleChange(newEditorStateWithReplacedText);
      if (onChange) {
        const htmlContent = stateToHTML(newEditorStateWithReplacedText?.getCurrentContent());
        onChange(htmlContent);
      }
      setFoundMentionValue('');
      setFullMentionValue('');
    }
  }, [editorRef, foundMentionValue, fullMentionValue, setFoundMentionValue, setFullMentionValue, editorState, handleChange]);

  const handleControlButtonClick = useCallback(
    ({ key, styleType }) => {
      if (styleType === STYLE_TYPES.INLINE) {
        toggleInlineStyle(key);
      }

      if (styleType === STYLE_TYPES.BLOCK) {
        toggleBlockType(key);
      }
    },
    [toggleInlineStyle]
  );

  const handleSubmit = useCallback(() => {
    if (onSubmit) {
      onSubmit();
      clearEditor();
    }
  }, [onSubmit, clearEditor]);

  return (
    <Box className={classes.commentEditorWrapper}>
      <Box className={classes.commentEditor}>
        <Editor
          ref={(r) => {
            if (r) {
              editorRef.current = r;
            }
          }}
          placeholder={!isHiddenPlaceHolder && t('task_data_view.comments_tab.new_line_info')}
          customStyleMap={STYLE_MAP}
          editorState={editorState}
          onChange={handleChange}
          onFocus={handleEditorFocus}
          onBlur={handleEditorBlur}
          keyBindingFn={customKeyBinding}
          // prevent new line when mentioning user
          {...(isUserMentionPopupOpen && { handleReturn: () => 'handled' })}
        />
      </Box>

      <Box className={classes.editorControls}>
        <Box className={classes.editorControlsLeft}>
          {CONTROL_BUTTONS.map((buttonGroup) => (
            <Box className={classes.controlButtonsGroup}>
              {buttonGroup.map(({ key, icon, iconActive, iconDisabled, styleType }) => {
                const buttonIcon = checkButtonActivity(key) ? iconActive : icon;
                return (
                  <IconButton
                    className={classes.editorControlsButton}
                    onClick={() => handleControlButtonClick({ key, styleType })}
                    data-selenium={key.toLowerCase()}
                  >
                    <img src={buttonIcon} alt={`${key}-button`} />
                  </IconButton>
                );
              })}
            </Box>
          ))}
          {showFilteredUsersList && (
            <IconButton className={classes.mentionButton} onClick={() => showFilteredUsersList('')}>
              <img src={emailIcon} alt="User mentions" />
            </IconButton>
          )}
        </Box>

        {typeof onSubmit === 'function' && (
          <Box className={classes.editorControlsRight}>
            <IconButton onClick={handleSubmit} disabled={!isSendButtonActive} data-selenium="send-comment-button">
              <img src={isSendButtonActive ? sendIconActive : sendIconDisabled} alt="Send" />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};
