import React, { ReactElement, useState } from 'react';
import { Box, FormControlLabel, Radio, Tooltip } from '@mui/material';
import cn from 'classnames';
import { Trans, useTranslation } from 'react-i18next';

import { useUsersState } from 'store/users';

import useDirectManagerCheckboxStyles from './InternalEmployee.useStyles';

import InfoIcon from 'assets/images/icons/info_outline.svg';

interface DirectManagerCheckboxProps {
  currentStep?: any;
  // processSteps: ProcessStep[];
  // conditions: ConditionStep[];
  // parallelBranchesGroups: ParallelBranchesGroup[];
  // isDirectManagerStep: boolean;
  //isAutostartActive: boolean;
  directManagerSteps?: number[];
  requestInitiatorSteps?: number[];
  disabled?: boolean;
  //setCurrentStepDMs: (value: string[]) => void;
  //handleDirectManagerToggle: (value: boolean) => void;
  isSelected?: boolean;
  onSelect?: (newState: string) => void;
}

export const InternalEmployee = ({
                                        currentStep,
                                        // processSteps = [],
                                        // conditions = [],
                                        // parallelBranchesGroups = [],
                                        // isDirectManagerStep = false,
                                        // isAutostartActive = false,
                                        directManagerSteps = [],
                                        disabled = false,
                                        //setCurrentStepDMs,
                                        //handleDirectManagerToggle,
                                        isSelected,
                                        onSelect,
                                        requestInitiatorSteps,
                                      }: DirectManagerCheckboxProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useDirectManagerCheckboxStyles();
  const { users } = useUsersState();

  const [assigneesDMs, setAssigneesDMs] = useState<string[]>([]);
  const [assigneesWithoutDMs, setAssigneesWithoutDMs] = useState<string[]>([]);

  const helpTooltipContent = <div className={classes.helpTooltipContent}>
    <p>{currentStep?.stepOrder === 1 ? t('customProcesses.internalEmployeeTooltip') : t('customProcesses.internalEmployeeNotFirst')}</p>
  </div>;


  return (
    <Box className={cn(classes.DirectManagerCheckbox)}>
          <>
            <Box mt={2} className={classes.checkboxWrapper}>
              <FormControlLabel
                //className={classes.checkbox}
                control={
                  <Radio
                    //color="primary"
                    disabled={false}
                    classes={{
                      root: classes.root,
                      checked: classes.checked,
                      disabled: classes.disabled
                    }}
                    checked={isSelected}
                    onChange={() => onSelect('internalEmployee')}
                  />
                }
                classes={{ label: classes.checkboxLabel }}
                label={currentStep?.stepOrder === 1 ? t('customProcesses.internalEmployeeAsInitiator') : t('customProcesses.internalEmployee')}
              />


              <Tooltip
                classes={{
                  popper: classes.tooltipPopper,
                  tooltip: classes.helpTooltip,
                }}
                arrow
                placement="bottom"
                title={helpTooltipContent}
                leaveDelay={500}
              >
                <img src={InfoIcon} className={classes.infoIcon} />
              </Tooltip>
            </Box>

          </>
    </Box>
  );
};

