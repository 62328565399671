import React, { memo, ReactElement } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import { FormControl, Box, CircularProgress, TextField } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import useEmployeeSelect from './useEmployeeSelect';

type Props = {
  name: string;
  hint: string;
}

export const EmployeeSelect = memo(({ hint, name }: Props): ReactElement => {
  const { t } = useTranslation();
  const {
    loading,
    options,
    value,
    inputValue,
    control,
    errors,
    handleChange,
    handleInputChange,
    getOptionLabel,
    getOptionSelected
  } = useEmployeeSelect(name);

  const getIcon = () => {
    if (loading) {
      return (
        <Box mr={1}>
          <CircularProgress size={16} />
        </Box>
      );
    }

    return <ArrowDropDownIcon />;
  };

  const renderInput = params => (
    <Controller
      name={name}
      control={control}
      rules={{ required: t('form_components.validation_errors.listOption') as string }}
      render={() => (
        <TextField
          {...params}
          label={hint}
          inputProps={{...params.inputProps, 'data-selenium': name}}
          error={!!errors[name]}
          helperText={!!errors[name] && errors[name].message}
        />
      )}
    />
  );

  return (
    <FormControl fullWidth>
      <Autocomplete
        fullWidth
        forcePopupIcon
        options={options}
        value={value}
        disabled={loading}
        inputValue={inputValue}
        onChange={handleChange}
        onInputChange={handleInputChange}
        renderInput={renderInput}
        popupIcon={getIcon()}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={getOptionSelected}
      />
    </FormControl>
  );
});
