import { createAction } from 'redux-actions';
import _get from 'lodash/get';

import store from 'store';
import BpmTask from 'models/BpmTask.model';

import {
  getGlossaryByCode as getGlossary,
  getMyRequests,
  updateDraftInstance,
  getMyTemplates,
  getHistoryTask,
  getProcessesGroupsByCompany,
  getEntity,
  getProcessDefinitions,
  getMyRequestsCount,
  getProcessIconsUrlList,
  getAllCompanyTemplateProcesses,
  getAvailableTemplateProcesses, getHistoryTaskByProcessInstanceId, getProcessDocuments,
} from 'api/requests';
import { environment } from '../../environments/environment';

export const REQUESTS_ACTIONS = {
  GET_MY_REQUESTS: 'GET_MY_REQUESTS',
  REFRESH_MY_REQUESTS: 'REFRESH_MY_REQUESTS',
  GET_TEMPLATES: 'GET_TEMPLATES',
  REMOVE_TEMPLATE: 'REMOVE_TEMPLATE',
  CLEAR_TEMPLATES: 'CLEAR_TEMPLATES',
  SET_TEMPLATE: 'SET_TEMPLATE',

  GET_PROCESS_ICONS_LIST: 'GET_PROCESS_ICONS_LIST',
  GET_PROCESSES_LIST: 'GET_PROCESSES_LIST',
  GET_TEMPLATE_PROCESSES_LIST: 'GET_TEMPLATE_PROCESSES_LIST',
  GET_AVAILABLE_TEMPLATE_PROCESSES_LIST: 'GET_AVAILABLE_TEMPLATE_PROCESSES_LIST',
  GET_PROCESSES_GROUPS: 'GET_PROCESSES_GROUPS',
  GET_GLOSSARY_BY_ID: 'GET_GLOSSARY_BY_ID',
  SET_GLOSSARY_ID: 'SET_GLOSSARY_ID',
  CREATE_REQUEST: 'CREATE_REQUEST',
  OPEN_CREATE_DIALOG: 'OPEN_CREATE_DIALOG',
  SET_ACTIVE_STEP: 'SET_ACTIVE_STEP',
  SET_SKIPPED_STEPS: 'SET_SKIPPED_STEPS',
  SET_TASK_STEPS: 'SET_TASK_STEPS',
  SAVE_DRAFT: 'SAVE_DRAFT',
  SET_SAVE_DRAFT_STATUS: 'SET_SAVE_DRAFT_STATUS',
  SET_DRAFTS_LIST_REFRESH_STATUS: 'SET_DRAFTS_LIST_REFRESH_STATUS',
  CREATE_AFTER_SUBMIT: 'CREATE_AFTER_SUBMIT',
  SET_REQUEST_LIST_COMPLETED: 'SET_REQUEST_LIST_COMPLETED',
  SET_REQUESTS_CURRENT_PAGE: 'SET_REQUESTS_CURRENT_PAGE',
  SET_REQUESTS_PAGE_SIZE: 'SET_REQUESTS_PAGE_SIZE',

  GET_TASK_BY_ID: 'GET_TASK_BY_ID',
  SET_TASK_LOADING: 'SET_TASK_LOADING',
  SET_TASK_COMPONENTS_DATA_LOADING_STARTED: 'SET_TASK_COMPONENTS_DATA_LOADING_STARTED',
  SET_TASK_COMPONENTS_DATA_LOADING_FINISHED: 'SET_TASK_COMPONENTS_DATA_LOADING_FINISHED',
  SET_TASK_COMPONENT_HIDDEN: 'SET_TASK_COMPONENT_HIDDEN',
  SET_TASK_COMPONENT_VISIBLE: 'SET_TASK_COMPONENT_VISIBLE',
  SET_TASK_COMPONENTS_DISABLED: 'SET_TASK_COMPONENTS_DISABLED',
  SET_TASK_NEW_COMMENTS_INDICATOR: 'SET_TASK_NEW_COMMENTS_INDICATOR',
  CLEAR_TASK_DATA: 'CLEAR_TASK_DATA',
  SET_TASK_OPEN_TIME: 'SET_TASK_OPEN_TIME',
  SET_TASK_DOCUMENTS_SIGNATURE_VALIDATION_ERROR_STATUS: 'SET_TASK_DOCUMENTS_SIGNATURE_VALIDATION_ERROR_STATUS',
  SET_TASK_DOCUMENTS: 'SET_TASK_DOCUMENTS',

  SET_SELECTED_REQUEST: 'SET_SELECTED_REQUEST',
  CLEAR_SELECTED_REQUEST: 'CLEAR_SELECTED_REQUEST',
  OPEN_SIDE_MODAL: 'OPEN_SIDE_MODAL',
  SHOW_DARK_OVERLAY: 'SHOW_DARK_OVERLAY',
  SET_SUBMIT_SNACKBAR_PARAMS: 'SET_SUBMIT_SNACKBAR_PARAMS',
  SET_INITIAL_TAB: 'SET_INITIAL_TAB',

  GET_ACTIVE_REQUESTS_COUNT: 'GET_ACTIVE_REQUESTS_COUNT',
  GET_COMPLETED_REQUESTS_COUNT: 'GET_COMPLETED_REQUESTS_COUNT',
  GET_DRAFTS_REQUESTS_COUNT: 'GET_DRAFTS_REQUESTS_COUNT',
  SET_ACTIVE_REQUESTS_COUNT: 'SET_ACTIVE_REQUESTS_COUNT',
  SET_COMPLETED_REQUESTS_COUNT: 'SET_COMPLETED_REQUESTS_COUNT',
  SET_DRAFTS_REQUESTS_COUNT: 'SET_DRAFTS_REQUESTS_COUNT',

  SET_IS_SIGN_REQUIRED: 'SET_IS_SIGN_REQUIRED', // used for docflow documents signing validation
  SET_HAS_DOCFLOW_DOCUMENTS_WITH_SIGNATURES: 'SET_HAS_DOCFLOW_DOCUMENTS_WITH_SIGNATURES', // used for hiding action buttons after docflow documents signing
  SET_IS_ATTACHMENT_REQUIRED: 'SET_IS_ATTACHMENT_REQUIRED', // used to show badge on AttachmentTab, to show user that attachment is required
};

export const getProcessIconsList = createAction(
  REQUESTS_ACTIONS.GET_PROCESS_ICONS_LIST,
  () => getProcessIconsUrlList()
)

export const getMyRequestsAction = createAction(
  REQUESTS_ACTIONS.GET_MY_REQUESTS,
  (params: { [key: string]: string }) => getMyRequests(params)
);

export const getActiveRequestsCountAction = createAction(
  REQUESTS_ACTIONS.GET_ACTIVE_REQUESTS_COUNT,
  () => {
    const params = {
      sort: 'taskStartDate,DESC',
      taskCompleted: false,
      completed: false,
      archive: false,
      pending: true,
      size: 10,
      page: 0,
    }
    try {
      return getMyRequestsCount(params)
    } catch (e) {
      return -1;
    }
  }
);

export const setActiveRequestsCountAction = createAction(
  REQUESTS_ACTIONS.SET_ACTIVE_REQUESTS_COUNT
);

export const getCompletedRequestsCountAction = createAction(
  REQUESTS_ACTIONS.GET_COMPLETED_REQUESTS_COUNT,
  () => {
    const params = {
      sort: 'taskStartDate,DESC',
      taskCompleted: true,
      completed: true,
      archive: false,
      pending: false,
      size: 10,
      page: 0,
    }
    try {
      return getMyRequestsCount(params)
    } catch (e) {
      return -1;
    }
  }
);

export const setCompletedRequestsCountAction = createAction(
  REQUESTS_ACTIONS.SET_COMPLETED_REQUESTS_COUNT
);

export const getDraftsRequestsCountAction = createAction(
  REQUESTS_ACTIONS.GET_DRAFTS_REQUESTS_COUNT,
  () => {
    const params = {
      sort: 'taskStartDate,DESC',
      taskCompleted: false,
      completed: false,
      archive: false,
      pending: false,
      size: 10,
      page: 0,
    }
    try {
      return getMyRequestsCount(params)
    } catch (e) {
      return -1;
    }
  }
);

export const setDraftsRequestsCountAction = createAction(
  REQUESTS_ACTIONS.SET_DRAFTS_REQUESTS_COUNT
);

export const refreshMyRequests = createAction(
  REQUESTS_ACTIONS.REFRESH_MY_REQUESTS
);

export const getProcessesListAction = createAction(
  REQUESTS_ACTIONS.GET_PROCESSES_LIST,
  () => getProcessDefinitions()
);

export const getTemplateProcessesListAction = createAction(
  REQUESTS_ACTIONS.GET_TEMPLATE_PROCESSES_LIST,
  () => getAllCompanyTemplateProcesses()
);

export const getAvailableTemplateProcessesListAction = createAction(
  REQUESTS_ACTIONS.GET_AVAILABLE_TEMPLATE_PROCESSES_LIST,
  () => getAvailableTemplateProcesses()
)

export const getProcessesGroupsAction = createAction(
  REQUESTS_ACTIONS.GET_PROCESSES_GROUPS,
  () => getProcessesGroupsByCompany()
);

export const getTemplatesAction = createAction(
  REQUESTS_ACTIONS.GET_TEMPLATES,
  () => getMyTemplates()
);

export const removeTemplateAction = createAction(
  REQUESTS_ACTIONS.REMOVE_TEMPLATE
);

export const clearTemplatesAction = createAction(
  REQUESTS_ACTIONS.CLEAR_TEMPLATES
);

export const setTemplateAction = createAction(
  REQUESTS_ACTIONS.SET_TEMPLATE
);

export const getBpmGlossaryAction = createAction(
  REQUESTS_ACTIONS.GET_GLOSSARY_BY_ID,
  (baseCode: string) => getGlossary(baseCode)
);

export const setBpmGlossaryId = createAction(
  REQUESTS_ACTIONS.SET_GLOSSARY_ID
);

export const setCreateRequestAction = createAction(
  REQUESTS_ACTIONS.CREATE_REQUEST
);

export const showDarkOverlay = createAction(
  REQUESTS_ACTIONS.SHOW_DARK_OVERLAY
);

export const openCreateDialogAction = createAction(
  REQUESTS_ACTIONS.OPEN_CREATE_DIALOG
);

export const setActiveStep = createAction(
  REQUESTS_ACTIONS.SET_ACTIVE_STEP
);

export const setSkippedSteps = createAction(
  REQUESTS_ACTIONS.SET_SKIPPED_STEPS
);

export const setTaskStepsAction = createAction(
  REQUESTS_ACTIONS.SET_TASK_STEPS
);

export const saveDraftAction = createAction(
  REQUESTS_ACTIONS.SAVE_DRAFT,
  (instanceId: string, data: { [key: string]: unknown }) =>
    updateDraftInstance(instanceId, data)
);

export const setDraftStatusAction = createAction(
  REQUESTS_ACTIONS.SET_SAVE_DRAFT_STATUS
);

export const setDraftsListRefreshStatus = createAction(
  REQUESTS_ACTIONS.SET_DRAFTS_LIST_REFRESH_STATUS
);

export const createAfterSubmitAction = createAction(
  REQUESTS_ACTIONS.CREATE_AFTER_SUBMIT
);

export const setRequestsCompletedAction = createAction(
  REQUESTS_ACTIONS.SET_REQUEST_LIST_COMPLETED
);

export const setRequestListPageAction = createAction(
  REQUESTS_ACTIONS.SET_REQUESTS_CURRENT_PAGE,
  ({ page, needRefresh= true }) => {
    return {
      page,
      needRefresh
    }
  }
);
export const setRequestListPageSizeAction = createAction(
  REQUESTS_ACTIONS.SET_REQUESTS_PAGE_SIZE
);

export const getTaskById = createAction(
  REQUESTS_ACTIONS.GET_TASK_BY_ID,
  async (id: string, idType: 'taskId' | 'processInstanceId' = 'taskId') => {
    const taskEntity = idType === 'taskId' ? await getHistoryTask(id) : await getHistoryTaskByProcessInstanceId(id);
    const businessEntity = await getEntity(taskEntity.taskInstance.bsnTypeId);
    const requestsState = store.getState().requests as { [key: string]: any };
    const { taskTemplate } = requestsState.templates;
    const processDefinitions = requestsState.processes.data;
    const processDefinitionId = processDefinitions?.find(
      (el) => el.processSysName === businessEntity.sysName
    )?.id;

    if (taskTemplate) {
      const { urgent, reason, businessInstanceDTO } = taskTemplate;

      if (urgent) {
        if (!Object.prototype.hasOwnProperty.call(taskEntity, 'urgent')) {
          taskEntity.urgent = {};
        }

        taskEntity.urgent.urgent = urgent;
        taskEntity.urgent.reason = reason;
      }

      for (const bDTOAttName of Object.keys(taskEntity.taskInstance)) {
        if (bDTOAttName.includes('Attributes')) {
          businessInstanceDTO[bDTOAttName].map((att) => {
            const attributeName = _get(att, 'name', '');
            const currentValue = _get(att, 'value', '');

            const taskInstanceAttributeIndex = taskEntity.taskInstance[bDTOAttName].findIndex(attr => attr.name === attributeName);
            if (taskInstanceAttributeIndex >= 0) {
              taskEntity.taskInstance[bDTOAttName][taskInstanceAttributeIndex].value = currentValue;
            }

            return att;
          });
        }
      }
    }

    const bpmTask =  new BpmTask(
      taskEntity,
      { ...businessEntity, processDefinitionId },
      taskEntity.businessActionDTOList,
      taskEntity.businessStateDTOList
    );

    let processDocuments = [];
    if (environment.isDocumentAdditionInTemplateProcessesAvailable) {
      processDocuments = await getProcessDocuments(taskEntity.businessTask.processInstanceId);
      processDocuments = processDocuments.map(doc => ({
        ...doc,
        isSigned: doc?.signings?.find(signing => signing.stepOrder === bpmTask.currentAction.stepperOrder)?.status,
        isPublished: doc.published || false,
      }));
    }

    // check if task is still selected
    const storeState = store.getState() as { [key: string]: any };
    const requestId = storeState.requests.selectedRequest;
    const approvalId = storeState.approvals.selectedApproval;
    const hasSelectedTaskId = requestId || approvalId;
    const hasSelectedTaskChanged = hasSelectedTaskId && id !== requestId && id !== approvalId;

    if (hasSelectedTaskChanged) {
      return {
        task: null,
        documents: []
      }
    }

    return {
      task: bpmTask,
      documents: processDocuments
    }
  }
);

export const setTaskDocuments = createAction(
  REQUESTS_ACTIONS.SET_TASK_DOCUMENTS
);

export const setTaskLoading = createAction(
  REQUESTS_ACTIONS.SET_TASK_LOADING
);

export const setTaskNewCommentsIndicator = createAction(
  REQUESTS_ACTIONS.SET_TASK_NEW_COMMENTS_INDICATOR
);

export const setTaskOpenTime = createAction(
  REQUESTS_ACTIONS.SET_TASK_OPEN_TIME
);

export const setDocumentSignatureErrorStatus = createAction(
  REQUESTS_ACTIONS.SET_TASK_DOCUMENTS_SIGNATURE_VALIDATION_ERROR_STATUS
);

export const setIsSignRequired = createAction(
  REQUESTS_ACTIONS.SET_IS_SIGN_REQUIRED
);

export const setIsAttachmentRequired = createAction(
  REQUESTS_ACTIONS.SET_IS_ATTACHMENT_REQUIRED
);

export const setHasDocFlowDocumentsWithSignatures = createAction(
  REQUESTS_ACTIONS.SET_HAS_DOCFLOW_DOCUMENTS_WITH_SIGNATURES
);

export const setTaskComponentDataLoadingStarted = createAction(
  REQUESTS_ACTIONS.SET_TASK_COMPONENTS_DATA_LOADING_STARTED
);

export const setTaskComponentDataLoadingFinished = createAction(
  REQUESTS_ACTIONS.SET_TASK_COMPONENTS_DATA_LOADING_FINISHED
);

export const setTaskComponentHidden = createAction(
  REQUESTS_ACTIONS.SET_TASK_COMPONENT_HIDDEN
);

export const setTaskComponentVisible = createAction(
  REQUESTS_ACTIONS.SET_TASK_COMPONENT_VISIBLE
);

export const setTaskComponentsDisabled = createAction(
  REQUESTS_ACTIONS.SET_TASK_COMPONENTS_DISABLED
);

export const clearTaskData = createAction(
  REQUESTS_ACTIONS.CLEAR_TASK_DATA
);

export const setSelectedRequest = createAction(
  REQUESTS_ACTIONS.SET_SELECTED_REQUEST
);

export const clearSelectedRequest = createAction(
  REQUESTS_ACTIONS.CLEAR_SELECTED_REQUEST
);

export const setSubmitSnackbarParams = createAction(
  REQUESTS_ACTIONS.SET_SUBMIT_SNACKBAR_PARAMS
);

export const setInitialTab = createAction(
  REQUESTS_ACTIONS.SET_INITIAL_TAB
);
