import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '28% auto',
    height: '100%',
    overflow: 'hidden',
  },
  groupsWrapper: {
    overflowY: 'auto'
  },
  sectionTitle: {
    fontSize: 28,
    fontWeight: 500
  },
  sectionSubtitle: {
    color: '#465564',
  },
}));
