import React, { memo, ReactElement, useCallback, useMemo, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  IconButton,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import useStyles from './useStyles';
import { toggleApprovalPanel } from 'store/approvals';

import { ReactComponent as CloseIcon } from 'assets/images/icons/close-icon-new.svg';
import { ActionCommentDialog } from '../../Task/TaskForm/ActionCommentDialog';
import { PromptModal } from '../../../components/Modal/PromptModal';

type Color = 'inherit' | 'primary' | 'secondary' | undefined;
type Action = 'Approve' | 'Rework' | 'Reject' | 'Delete' | '';

export type TButton = {
  action: Action;
  color: Color,
  icon: ReactElement | null,
  title?: string;
  requireComment?: boolean;
};

type Props = {
  type: 'approvals' | 'drafts',
  selectTickets: {
    all: () => void;
    one: (ticketData: string) => void;
  };
  availableTicketsCount: number;
  selectAllTickets: () => void;
  clearSelectedTickets: () => void;
  selected: {
    [key: string]: any
  };
  onClick: (action: Action, comment: string) => void;
  loading: Action;
  isDisabled: boolean;
  isAllSelected: boolean;
  isControlsShown: boolean;
  buttonList: TButton[];
};

export const ApprovalsControls = memo(({
  availableTicketsCount,
  buttonList,
  clearSelectedTickets,
  isAllSelected,
  isControlsShown,
  isDisabled,
  loading,
  onClick,
  selectAllTickets,
  selected,
  selectTickets,
  type,
}: Props): ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [comment, setComment] = useState('');
  const [isCommentModalOpen, setCommentModalOpen] = useState(false);
  const [isPromptModalOpen, setIsPromptModalOpen] = useState(false);
  const [buttonObject, setButtonObject] = useState<TButton>(null)

  const selectedRequestsNumber = useMemo((): number => Object.keys(selected).length, [selected]);

  const areActionButtonsDisabled = useMemo((): boolean =>
      !Boolean(selectedRequestsNumber) || Boolean(loading),
    [selectedRequestsNumber, loading]);

  const handleTogglePanel = useCallback((): void => {
    clearSelectedTickets();
    setCommentModalOpen(false);
    setButtonObject(null);
    setComment('');
    dispatch(toggleApprovalPanel());
  }, [clearSelectedTickets]);

  const handleActionButtonClick = useCallback((button: TButton): void => {
    setButtonObject(button);
    if (button.requireComment) {
      setComment('');
      setCommentModalOpen(true);
    } else {
      setIsPromptModalOpen(true);
    }
  }, []);

  const onClosePromptModal = useCallback(() => {
    setIsPromptModalOpen(false);
  }, []);

  const onCancelPromptModal = useCallback(() => {
    setIsPromptModalOpen(false);
  }, []);

  const onConfirmPromptModal = useCallback(() => {
    if (buttonObject) onClick(buttonObject?.action, '');
    setIsPromptModalOpen(false);
  }, [buttonObject]);

  return <>
    <Box className={classes.root}>
      <Box className={classes.contentWrapper}>
        <div className={classes.group}>
          {isAllSelected ?
           <Button
             className={classes.button}
             classes={{
               disabled: classes.buttonDisabled,
               focusVisible: classes.buttonFocused,
             }}
             key={'clearAll'}
             color={'secondary'}
             disabled={Boolean(loading)}
             onClick={clearSelectedTickets}>
             <Typography>{t('massActions.clearAll')}</Typography>
            </Button>
            :
            <Button
             className={classes.button}
             classes={{disabled: classes.buttonDisabled, focusVisible: classes.buttonFocused}}
             key={'selectAll'}
             color={'secondary'}
             disabled={availableTicketsCount === 0 || Boolean(loading)}
             onClick={selectAllTickets}>
             <Typography>{t('massActions.selectAll', { availableTicketsCount: availableTicketsCount })}</Typography>
           </Button>
          }
        </div>
        {
          selectedRequestsNumber >= 1 &&
          <div className={cn(classes.group, classes.groupWithDivider)}>
            <span className={classes.selectedCountText}>
              {isAllSelected
              ? t('massActions.selectedAll', { selectedRequestsNumber: selectedRequestsNumber })
              : t('massActions.selectedSome', { selectedRequestsNumber: selectedRequestsNumber })}
            </span>
          </div>
        }
        <div className={classes.group}>
          {buttonList.map(button => (
            <Button
              className={classes.button}
              classes={{disabled: classes.buttonDisabled, focusVisible: classes.buttonFocused}}
              key={button.action}
              color={button.color}
              startIcon={
                loading === button.action ? <CircularProgress style={{ 'color': 'white' }} size={16}/> : button.icon
              }
              disabled={areActionButtonsDisabled}
              onClick={() => handleActionButtonClick(button)}>
              <Typography>{button.title ?? button.action}</Typography>
            </Button>
          ))}
        </div>
      </Box>

      <IconButton
        className={cn(classes.button, classes.iconButton)}
        classes={{disabled: classes.buttonDisabled}}
        key={'close'}
        disabled={Boolean(loading)}
        onClick={handleTogglePanel}
        size="large">
        <CloseIcon/>
      </IconButton>
    </Box>

    <PromptModal
      cancelText={t('buttons.cancel')}
      confirmText={t('buttons.button_approve')}
      description={t('massActions.cannotUndoAction')}
      header={t('massActions.approveSelectedNumberRequest', {selectedRequestsNumber})}
      onCancel={onCancelPromptModal}
      onClose={onClosePromptModal}
      onConfirm={onConfirmPromptModal}
      open={isPromptModalOpen} />

    <ActionCommentDialog
      open={isCommentModalOpen}
      isCommentRequired={true}
      commentedAction={buttonObject?.action}
      actionOptions={{}}
      showInfoMessage
      requestsCount={selectedRequestsNumber}
      onClick={() => {
        setCommentModalOpen(false);
        onClick(buttonObject?.action as Action, comment);
      }}
      onClose={() => {
        setCommentModalOpen(false);
      }}
      callClickHandlerOnClose={false}
      submitAction={null}
      submitButtonText={t('comment_modal.comment.button_submit')}
      onCommentChange={(comment: string) => setComment(comment)}
      setAssigneeVariableName={() => {}}
      isTemplate={false} />
  </>;
});
