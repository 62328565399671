import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ spacing, palette }) => ({
  root: {
    color: "#8B8C9E",
    "&$disabled": {
      color: "#C0C1D1",
    },
    "&$checked": {
      color: "#039BE6",
    },
  },
  checked: {},
  disabled: {},
  DirectManagerCheckbox: {

  },
  checkboxWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: spacing(2),
    marginRight: spacing(1),
  },
  checkbox: {
    marginLeft: spacing(-1),
    marginRight: spacing(1),
    '& svg path': {
      fill: '#1976D2 !important'
    }
  },
  checkboxChecked: {
    '& svg path': {
      fill: '#007994 !important'
    }
  },
  checkboxDisabled: {
    '& svg path': {
      fill: '#2628421F !important'
    }
  },
  checkboxLabel: {
    marginLeft: 0,
    //margin: 0
  },
  defaultCursor: {
    cursor: 'default'
  },
  infoIcon: {
    height: 20,
    width: 20,
    marginLeft: -5
  },
  tooltipPopper: {
    pointerEvents: 'all',
  },
  helpTooltip: {
    width: 320,
    height: 'max-content',
    minHeight: 'max-content',
  },
  helpTooltipContent: {
    width: 300,
    height: 'max-content',
    minHeight: 'max-content',
    paddingRight: 8,
    '& p': {
      margin: 0,
      marginBottom: spacing(5),
      '&:last-child': {
        marginBottom: 0
      }
    }
  },
  checkboxMessageWrapper: {},
  checkboxMessage: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    color: palette.grey[500]
  },
  withoutDMs: {
    backgroundColor: '#FFE8C2',
    padding: '10px 20px',
    borderRadius: '9px',
    gap: '5px',
    display: 'flex'
  },
  usersListTooltip: {
    width: 240,
    height: 120,
    padding: 10,
    fontSize: 12,
    fontWeight: 400,
    color: palette.grey[50],
  },
  usersTooltipContent: {
    overflowY: 'auto',
    '& ul': {
      margin: 0,
      padding: 0,
      paddingLeft: 20,
    },
  }
}));
