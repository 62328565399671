import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ spacing }) => ({
  tabs: {
    height: 40,
    marginBottom: 12,
    borderBottom: '1px solid rgba(38, 40, 66, 0.08)',
    width: 'calc(100% + 24px)',
    marginLeft: -24,
    paddingLeft: 24,
  },
  tabsWide: {
    width: 'calc(100% + 48px) !important',
  },
  tabsRequestsPage: {
    width: 'calc(100% + 72px) !important',
  },
  tabIndicator: {
    display: 'block'
  },
  tab: {
    fontSize: 14,
    color: '#8B8C9E',
    textTransform: 'none',
    padding: spacing(2.5, 3),
    marginBottom: -1,
    minHeight: 'auto',
    borderBottom: 'none',
    minWidth: '100px',
    '&.Mui-selected': {
      border: 'none',
      color: '#007994',
    },
    '& span': {
      flexDirection: 'row',
      '& img': {
        marginBottom: '0 !important',
        marginRight: 8,
        height: 17.5,
        width: 'auto',
        minWidth: 17.5
      }
    }
  },
  templatesTab: {
    padding: spacing(2.25, 3) + '!important',
    '& span': {
      flexDirection: 'row',
      alignItems: 'center',
    }
  },
  tabIcon: {
    height: 20,
    maxHeight: 20,
    width: 'auto',
    marginBottom: '0 !important',
    marginRight: spacing(1),
  },
  tabBadgeBadge: {
    backgroundColor: '#E94E3A',
    color: '#F7F8FC',
    top: 9,
    right: 10,
    position: 'relative',
    marginLeft: spacing(1),
  },
  tabBadgeRoot: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
}));
