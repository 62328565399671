export const useDragAndDropActions = ({
  documents,
  focusedDocumentId,
  dragType,
  setDragActive,
  setDragType,
  setDocuments,
  handleStepFieldsAdd,
  handleStepFieldsMove,
  handleDocumentAdd,
  handleDocumentVisibilityChange,
}) => {
  const handleDragStart = (dragData) => {
    const { source: { droppableId } } = dragData;
    setDragActive(() => true);
    setDragType(() => ['DOCUMENT_TEMPLATES', 'PROCESS_DOCUMENTS_VISIBLE', 'PROCESS_DOCUMENTS_HIDDEN'].includes(droppableId) ? 'documents' : 'fields');
  };

  const handleDragEnd = (result) => {
    setDragActive(() => false);
    const { source, destination, draggableId } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (dragType === 'documents') {
      if (source.droppableId === 'DOCUMENT_TEMPLATES' && destination.droppableId === 'PROCESS_DOCUMENTS_VISIBLE') {
        handleDocumentAdd(+draggableId, destination.index + 1, true);
      }
      if (source.droppableId === 'PROCESS_DOCUMENTS_VISIBLE' && destination.droppableId === 'PROCESS_DOCUMENTS_HIDDEN') {
        handleDocumentVisibilityChange(draggableId, false);
      }
      if (source.droppableId === 'PROCESS_DOCUMENTS_HIDDEN' && destination.droppableId === 'PROCESS_DOCUMENTS_VISIBLE') {
        handleDocumentVisibilityChange(draggableId, true);
      }

      return;
    }

    switch (source.droppableId) {
      case destination.droppableId:
        handleStepFieldsMove(source.index, destination.index);
        break;
      case 'ITEMS':
        handleStepFieldsAdd([draggableId], destination.index + 1);
        break;
      case 'DOCUMENT_FIELDS':
        const currentDocumentIndex = documents.findIndex(doc => doc.uniqueId === focusedDocumentId);
        const currentDocument = documents[currentDocumentIndex];
        const documentFieldIndex = currentDocument.fields.findIndex(field => field.id === draggableId);
        const fieldData = currentDocument.fields[documentFieldIndex];

        const newFieldsIds = handleStepFieldsAdd([fieldData.type], destination.index + 1, [fieldData.hint], focusedDocumentId, false);
        currentDocument.fields[documentFieldIndex].processFieldId = newFieldsIds[0];
        setDocuments(docs => docs.map((doc, index) => index === currentDocumentIndex ? currentDocument : doc));
        break;
      default:
        break;
    }
  };

  return {
    handleDragStart,
    handleDragEnd,
    handleStepFieldsAdd,
  };
};
