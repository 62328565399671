import { useMemo, useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { useTaskState } from 'store/requests';
import { useUserProfile } from "hooks";

import { updateEntityInstance } from 'api/requests';

const SUPPLIER_VOTES_FIELD_TYPE = 'string';
const SUPPLIER_VOTES_FIELD = 'votes';

const useSuppliersVotingSwitch = ({
  instance,
  instancesList,
  setInstancesList,
  votingStatusAttribute,
  setMainFormValue,
}) => {
  const { setValue } = useFormContext();

  const { id: profileId } = useUserProfile();
  const { data: bpmTask } = useTaskState();

  const value = useMemo(() => {
    if (!instance) {
      return false;
    }

    const supplierVotesTypeAttributes =
      instance[SUPPLIER_VOTES_FIELD_TYPE + 'Attributes'];
    const votesAttribute = supplierVotesTypeAttributes.find(
      ({ name }) => name === SUPPLIER_VOTES_FIELD
    );
    const votesList =
      votesAttribute?.value
        ?.split(',')
        ?.filter((v) => !!v)
        .map((v) => v.trim()) || [];

    return votesList.includes(profileId);
  }, [instance, instancesList]);

  // set voting status (true if user has voted) when component is rendered
  // to fix incorrect value when modal is open 2nd time
  useEffect(() => {
    if (!setMainFormValue) {
      return;
    }
     const hasVoted = instancesList.reduce((result, instanceItem) => {
      const votesTypeAttributes =
        instanceItem[SUPPLIER_VOTES_FIELD_TYPE + 'Attributes'];
      const instanceItemVotesAttribute = votesTypeAttributes.find(
        ({ name }) => name === SUPPLIER_VOTES_FIELD
      );
      const instanceItemVotesList =
        instanceItemVotesAttribute?.value
          ?.split(',')
          ?.filter((v) => !!v)
          .map((v) => v.trim()) || [];

      return result || instanceItemVotesList.includes(profileId);
    }, false);

    const statusAttribute = bpmTask?.getAttributeBySysName(
      votingStatusAttribute
    );
    const votingStatusAttributeName = statusAttribute?.name;
    setMainFormValue(votingStatusAttributeName, hasVoted);
  }, [
    instancesList,
    profileId,
    setMainFormValue,
    votingStatusAttribute,
    bpmTask,
  ]);

  const handleChange = useCallback(
    async (newValue) => {
      if (!instance) {
        return;
      }

      const updatedInstancesIds = [instance.id];

      const supplierVotesTypeAttributes =
        instance[SUPPLIER_VOTES_FIELD_TYPE + 'Attributes'];
      const votesAttribute = supplierVotesTypeAttributes.find(
        ({ name }) => name === SUPPLIER_VOTES_FIELD
      );
      const votesList =
        votesAttribute?.value
          ?.split(',')
          ?.filter((v) => !!v)
          .map((v) => v.trim()) || [];

      if (newValue) {
        // if supplier is voted, remove current user
        // from other suppliers' votes lists
        instancesList.forEach((instanceItem) => {
          const votesTypeAttributes =
            instanceItem[SUPPLIER_VOTES_FIELD_TYPE + 'Attributes'];
          const instanceItemVotesAttribute = votesTypeAttributes.find(
            ({ name }) => name === SUPPLIER_VOTES_FIELD
          );
          const instanceItemVotesList =
            instanceItemVotesAttribute?.value
              ?.split(',')
              ?.filter((v) => !!v)
              .map((v) => v.trim()) || [];

          // save id of the current instance item if its votes list
          // is going to be changed because it includes current user's id
          if (instanceItemVotesList.includes(profileId)) {
            updatedInstancesIds.push(instanceItem.id);
          }

          const newInstanceItemVotesList = instanceItemVotesList.filter(
            (v) => v !== profileId
          );
          instanceItemVotesAttribute.value = newInstanceItemVotesList.join(',');
        });

        // and add vote to current supplier's votes list
        const newVotesList = votesList.includes(profileId)
          ? votesList
          : [...votesList, profileId];
        votesAttribute.value = newVotesList.join(',');
      } else {
        // else - remove vote from votes lists
        const newVotesList = votesList.filter((vote) => vote !== profileId);
        votesAttribute.value = newVotesList.join(',');
      }

      // then set voting status (true if a current user has voted)
      // if newValue is false, then all votes are false
      // and voting status is also false
      // since only one voting switch can be true at the same time
      const statusAttribute = bpmTask?.getAttributeBySysName(
        votingStatusAttribute
      );
      const votingStatusAttributeName = statusAttribute?.name;
      !!setMainFormValue &&
        setMainFormValue(votingStatusAttributeName, newValue);

      // and save instances changes
      const successfullyUpdatedInstances = [];
      try {
        updatedInstancesIds.forEach(async (updatedInstanceId) => {
          const updatedInstance = instancesList.find(
            (item) => item.id === updatedInstanceId
          );
          if (updatedInstance) {
            await updateEntityInstance(updatedInstance);
            successfullyUpdatedInstances.push(updatedInstanceId);
          }
        });
      } catch (error) {
        // if there was an error saving changes
        // restore state before vote change
        if (newValue) {
          // if supplier was selected
          updatedInstancesIds.forEach((updatedInstanceId) => {
            // do not change successfully saved instances
            if (successfullyUpdatedInstances.includes(updatedInstanceId)) {
              return;
            }

            const updatedInstance = instancesList.find(
              (item) => item.id === updatedInstanceId
            );
            const votesTypeAttributes =
              updatedInstance[SUPPLIER_VOTES_FIELD_TYPE + 'Attributes'];
            const instanceItemVotesAttribute = votesTypeAttributes.find(
              ({ name }) => name === SUPPLIER_VOTES_FIELD
            );

            const updatedInstanceVotesList =
              instanceItemVotesAttribute?.value
                ?.split(',')
                ?.filter((v) => !!v)
                .map((v) => v.trim()) || [];

            // remove current user from current supplier's votes list
            if (updatedInstanceId === instance.id) {
              const newUpdatedInstanceVotesList = updatedInstanceVotesList.filter(
                (vote) => vote !== profileId
              );
              instanceItemVotesAttribute.value = newUpdatedInstanceVotesList.join(
                ','
              );
            } else {
              // and add current user to other changed supplier votes list
              const newUpdatedInstanceVotesList = [
                ...updatedInstanceVotesList,
                profileId,
              ];
              instanceItemVotesAttribute.value = newUpdatedInstanceVotesList.join(
                ','
              );
            }
          });
        } else {
          // if supplier was unselected
          // add current user to current supplier's votes list
          const newVotesList = votesList.includes(profileId)
            ? votesList
            : [...votesList, profileId];
          votesAttribute.value = newVotesList.join(',');
        }
      }

      // finally save updated instances list
      const newInstancesList = [...instancesList];
      setInstancesList(newInstancesList);
    },
    [
      value,
      instance,
      instancesList,
      setInstancesList,
      bpmTask.getAttributeBySysName,
      votingStatusAttribute,
      setValue,
    ]
  );

  return { value, handleChange };
};

export default useSuppliersVotingSwitch;
