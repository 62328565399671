import { ProgressBarStepType } from '../../types';

export enum MASS_ACTIONS_VALIDATION_RESULTS {
  HAS_REQUIRED_FIELDS = 'HAS_REQUIRED_FIELDS',
  NEED_SIGNATURE = 'NEED_SIGNATURE',
  NEED_TAKE_ON_TASK = 'NEED_TAKE_ON_TASK',
  NEED_PERFORMER_SELECTION = 'NEED_PERFORMER_SELECTION',
  IS_REWORK_STEP = 'IS_REWORK_STEP',
  IS_CANCELED_STEP = 'IS_CANCELED_STEP',
  IS_REJECT_STEP = 'IS_REJECT_STEP',
  IS_OLD_REQUEST = 'IS_OLD_REQUEST'
}

export type TicketDataType = {
  id: string;
  applicationNumber: string;
  processInstanceId: string;
  initiator: string;
  assignee: string;
  assigneeList: string[];
  processSysName: string;
  processName: string;
  processDisplayName: string;
  completed: boolean;
  cancelled: boolean;
  massApproveAvailable: boolean;
  massApproveCheckboxDisabled?: boolean;
  massActivationValidationErrorType: MASS_ACTIONS_VALIDATION_RESULTS | null,
  stepName: string;
  stepDisplayName: string;
  stepNumber: number;
  stepsTotal: number;
  stepsList: ProgressBarStepType[];
  iconPath: string;
  commentsSize: number;
  attachmentCount: number;
  summaryType: 'html' | 'fields' | 'description',
  summary: string;
  processDescription: string;
  summaryFields: {
    fieldType: string;
    hint: string;
    value: string;
    valuesCount: number
  }[],
  urgent: boolean;
  rework: boolean;
  reject: boolean;
  reasonOfUrgent: string;
  isOverdue: boolean;
  companyId: string;
  formattedDeadline: string;
  isTimerOver: boolean;
  unread: boolean;
  unreadAttachments: boolean;
  unreadComments: boolean;
  hasCondition: boolean;
  parallelBranchesName: string | null;
  parallelBranchesStepperOrder: number;
  isSignatureStep?: boolean;
}
