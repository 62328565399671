import {HcmsUserModel} from './user.types';

export enum StatusType  {
  PENDING = 'pending',
  RESOLVED = 'resolved',
  REJECTED = 'rejected'
}

export type CompanyInfo = {
  bin: string;
  createdAt: string;
  id: string;
  isHolding: string;
  name: string;
  shortName: string;
}

export type CompanyPermission = {
  id: string;
  companyId: string;
  role: string;
  permissionSysName: string;
  permissionTitle: string;
  resourceUrl: string;
  description: string;
  descriptionRus: string;
  descriptionKaz: string;
  view: boolean;
  lastEdit: string;
  access: boolean;
  settingsActive: boolean;
}

export type CompanyPermissionInfo = {
  count: number;
  lastEdit: string;
  permissions: CompanyPermission[];
} | null;

export type CompanyProcess = {
  access: boolean;
  companyId: string;
  id: string;
  lastEdit: string;
  role: string;
  settingsActive: boolean;
  startProcessId: string;
};

export type CompanyProcessesInfo = {
  count: number;
  lastEdit: string;
  processes: CompanyProcess[];
} | null;

export type CompanyRoleInfo = {
  companyId: string;
  roleName: string;
  lastEdit: string | null;
  permissionFullInfo: CompanyPermissionInfo
  processesFullInfo: CompanyProcessesInfo
  usersCount: number;
}

export type CompanyUserInfo = {
  companyId: string,
  lastEdit: string;
  employeeInfo: HcmsUserModel;
  personalPermissionInfo: CompanyPermissionInfo;
  personalProcessesInfo: CompanyProcessesInfo;
}

export type UserPermission = {
  active: boolean;
  description: string;
  descriptionKaz: string;
  descriptionRus: string;
  permissionSysName: string;
  permissionTitle: string;
  resourceUrl: string;
  view: boolean;
}
