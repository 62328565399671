import React, { useEffect, useState } from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ErrorMessage, ErrorPage, Spinner, TaskDetails } from 'components';
import { routesList } from 'router';
import { ContentLayout } from 'layouts';

import {
  getTaskById,
  setInitialTab,
  useSubmitSnackbarState,
  useTaskState,
} from 'store/requests';
import { getProfileCompanyDataFromLocalStorage } from 'utils/user';
import { changeCurrentWorkspace } from 'utils/workspace';
import { useUserProfile } from 'hooks';

import NoAccessIcon from 'assets/images/icons/error-page-no-access-icon.svg';
import { HISTORY_TAB_NAME } from 'components/TaskDetails/components/Tabs/FormTab/constants';

export const RequestPage = () => {
  const dispatch = useDispatch();
  const { id: paramId } = useParams<{ id?: string }>();
  const { id: currentUserId, nickname } = useUserProfile();
  const location = useLocation();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const { data: bpmTask, loading: taskLoading, error } = useTaskState();
  const { open: submitSnackbarOpen } = useSubmitSnackbarState();

  const openCorrectProcessStep = async (processInstanceId) => {
    const { id: currentWorkspaceId } = getProfileCompanyDataFromLocalStorage();
    const taskWorkspaceId =
      queryString.parse(location.search)?.workspaceId || currentWorkspaceId;
    try {
      if (taskWorkspaceId !== currentWorkspaceId) {
        await changeCurrentWorkspace(taskWorkspaceId);
      } else {
        dispatch(setInitialTab(HISTORY_TAB_NAME));
        dispatch(getTaskById(processInstanceId, 'processInstanceId'));
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    openCorrectProcessStep(paramId);
  }, [paramId, currentUserId]);

  if (loading || taskLoading) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorMessage text={t('errors.somethingIsWrong')} />;
  }

  if (!submitSnackbarOpen && bpmTask) {
    return (
      <ContentLayout title={''} zIndexContent={101}>
        <TaskDetails />
      </ContentLayout>
    );
  }

  if (submitSnackbarOpen) {
    return (
      <Redirect
        to={`${
          routesList(t).approvals.path + routesList(t).approvals.submenu[0].path
        }`}
      />
    );
  }

  return (
    <ErrorPage
      icon={NoAccessIcon}
      title={t('errorPage.noAccess.title')}
      message={''}
    />
  );
};

export default RequestPage;
