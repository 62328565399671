import React, { useState, useEffect, memo } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { theme } from '@dartech/dms-ui';

import { useExchangeRate } from './useExchangeRate';

type Props = {
  name: string;
  hint?: string;
  currencyFieldName?: string;
}

const CR_DEFAULT_FIELD_NAME = 'currency';
const CR_DEFAULT_VALUE = 1;

export const CurrencyRate = memo(({
  name,
  hint = 'Currency Rate',
  currencyFieldName
}: Props) => {
  const {
    watch,
    setValue,
    control
  } = useFormContext();

  const {
    loading,
    error,
    currencyRates
  } = useExchangeRate({
    name
  });

  const [inputValue, setInputValue] = useState(watch(name) || CR_DEFAULT_VALUE);

  const currency = watch(currencyFieldName || CR_DEFAULT_FIELD_NAME);

  useEffect(() => {
    if (!currency) {
      return;
    }

    const currentRate = currencyRates.find(({ name }) => name === currency);
    const currentValue = currentRate?.fields?.currencyRate || CR_DEFAULT_VALUE;

    setValue(name, currentValue);
    setInputValue(currentValue);
  }, [currency, currencyRates, name, setValue]);

  return (
    <>
      <Typography>{hint}</Typography>
      {loading ? (
        <CircularProgress size={20} />
      ) : (
        <Typography style={{ color: theme.palette.grey[700] }}>
          {inputValue}
        </Typography>
      )}

      {error && (
        <Typography color="secondary">{error}</Typography>
      )}

      <Controller
        control={control}
        name={name}
        render={() => (
          <input type="hidden" data-selenium={name} />
        )}
      />
    </>
  );
});
