import { useCallback, useState } from "react";

import debounce from 'lodash/debounce';

export const useDebouncedSearchText = ({ doWithNewValue }: {
  doWithNewValue: (value: string) => void
}) => {
  const [searchText, setSearchText] = useState('');
  const [searchFieldText, setSearchFieldText] = useState('');

  const debouncedSetSearchText = useCallback(
    debounce((value: string) => {
      setSearchText(value)
      doWithNewValue(value)
    }, 400),
    [setSearchText]);

  const handleSearchTextChange = (value: string) => {
    setSearchFieldText(value);
    if(value.trim() === searchText.trim()) return
    debouncedSetSearchText(value.trim());
  }

  return {
    handleSearchTextChange,
    searchText,
    searchFieldText
  }
}
