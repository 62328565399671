import React from 'react';
import { Button, DialogContent, Typography, DialogActions, Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useStyles from './useStyles';

export const OutdatedDialog = ({ open = false, onClose, processName }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent className={classes.content}>
        <Typography className={classes.message}>
          {t('template_outdated_modal.text1')}‘{processName}’{t('template_outdated_modal.text2')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {t('template_outdated_modal.button_close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
