import React, { useMemo } from 'react';

import classNames from 'classnames';

import { Styled } from './index.styles';
import { UserAvatar } from 'components/User/UserAvatar';
import { UserType } from 'types';
import { useTranslation } from 'react-i18next';

interface UserListProps {
  headerText?: string;
  users: UserType[];
  readOnly?: boolean;
}

const UserList = ({ users = [], headerText, readOnly = false }: UserListProps) => {
  const { t } = useTranslation();
  const userListClassNames = useMemo(() => classNames({
    userList: true,
    readonly: readOnly
  }), [readOnly]);

  if (users.length === 0) {
    return (
      <Styled.UserList>
        <div className={userListClassNames}>{t('Watchers.noWatchers')}</div>  
      </Styled.UserList>
    )
  }

  return (
    <Styled.UserList>
      <div className={userListClassNames}>
        {headerText && <div className="userList__header">{headerText}</div>}
        {
          users.map((user: UserType, index: number) => (
            <div className="userList__item" key={index}>
              <UserAvatar user={user} avatarSize={24} />
              <span>{`${user.firstName} ${user.lastName}`}</span>
            </div>
          ))
        }
      </div>
    </Styled.UserList>
  )
};

export default UserList;
