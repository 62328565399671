import React from 'react'
import { Typography, Box } from '@mui/material';

interface Props {
  text: string
}

export const ErrorMessage = ({ text }: Props) =>
  <Box mt={16}>
    <Typography align="center" color="error">{text}</Typography>
  </Box>
