import { createAction } from 'redux-actions';

import { getMonitoringListRequest } from 'api/requests';

export const MONITORING_ACTIONS = {
  GET_MONITORING_LIST: 'GET_MONITORING_LIST',
  SET_ACTIVE_MONITORING_COUNT: 'SET_ACTIVE_MONITORING_COUNT',
  GET_ACTIVE_MONITORING_COUNT: 'GET_ACTIVE_MONITORING_COUNT',
  SET_ACTIVE_APPROVALS_COUNT: 'SET_ACTIVE_MONITORING_COUNT',
  CLEAR_SELECTED_MONITORING: 'CLEAR_SELECTED_MONITORING',
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
  SET_CURRENT_PAGE_SIZE: 'SET_CURRENT_PAGE_SIZE',
  TOGGLE_MONITORING_PANEL: 'TOGGLE_MONITORING_PANEL',
  SET_SELECTED_APPROVAL: 'SET_SELECTED_APPROVAL',
  SET_APPROVALS_ERROR: 'SET_APPROVALS_ERROR'
}

export const getListAction = createAction(
  MONITORING_ACTIONS.GET_MONITORING_LIST, async ({ params }) => {
    return await getMonitoringListRequest({params});
  });

export const setActiveCountAction = createAction(
  MONITORING_ACTIONS.SET_ACTIVE_MONITORING_COUNT
);

export const clearSelected = createAction(
  MONITORING_ACTIONS.CLEAR_SELECTED_MONITORING
);

export const setCurrentPage = createAction(MONITORING_ACTIONS.SET_CURRENT_PAGE);

export const setCurrentPageSize = createAction(
  MONITORING_ACTIONS.SET_CURRENT_PAGE_SIZE
);

export const toggleMonitoringPanel = createAction(
  MONITORING_ACTIONS.TOGGLE_MONITORING_PANEL
);

export const setSelectedMonitoring = createAction(
  MONITORING_ACTIONS.SET_SELECTED_APPROVAL
);

export const setMonitoringError = createAction(
  MONITORING_ACTIONS.SET_APPROVALS_ERROR
);


