import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  InputAdornment,
  Popper,
  TextField, Tooltip, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import useCopyProcessDialogStyles from './CopyProcessDialog.useStyles';
import cn from 'classnames';
import { getWorkspacesListForProcessCopy } from 'api/requests';
import { UserSelectDepartmentData, WorkspaceInfo } from '../../TemplateProcesses.types';
import { WorkspaceSelectItem } from './WorkspaceSelectItem';
import SearchIcon from 'assets/images/icons/search-icon-thin.svg';
import DropdownIndicator from 'assets/images/icons/dropdown-indicator.svg';
import PersonIcon from '../../../../assets/images/icons/new-user-icon.svg';
import { Spinner, UserInfo } from '../../../../components';
import ChevronDown from '../../../../assets/images/icons/chevron_down_regular.svg';

interface WorkspaceSelectProps {
  onSelect: (values: any[]) => void;
  label: string;
  value: string[];
  disabled?: boolean;
  selectedValuesLimit?: number;
}

export const WorkspaceSelect = ({
  onSelect,
  label,
  value,
  disabled = false,
  selectedValuesLimit = -1,
}: WorkspaceSelectProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useCopyProcessDialogStyles();
  const [loading, setLoading] = useState<boolean>(true);
  const [workspacesList, setWorkspacesList] = useState<WorkspaceInfo[]>([]);
  const [selectedWorkspaces, setSelectedWorkspaces] = useState<string[]>([]);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');

  const searchInputRef = useRef(null);
  const dropdownAnchor = useRef<HTMLElement>(null);

  const initWorkspacesList = async () => {
    try {
      const workspaces = await getWorkspacesListForProcessCopy() as WorkspaceInfo[];
      setWorkspacesList(workspaces);
    } catch (error) {
      console.log('Error loading workspaces list:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initWorkspacesList();
  }, []);

  const handleDropdownClear = () => {
    setHasChanges(true);
    setSelectedWorkspaces([]);
    onSelect([]);
  };

  const handleDropdownSubmit = () => {
    onSelect(selectedWorkspaces
      .map(workspaceId => workspacesList.find(w => w.id === workspaceId))
      .filter(Boolean)
    );
    setOpen(false);
  };

  const handleSelectOption = (value: WorkspaceInfo) => {
    if (selectedValuesLimit >= 0 && selectedWorkspaces.length >= selectedValuesLimit && !selectedWorkspaces.includes(value.id)) {
      return;
    }
    setHasChanges(true);
    if (selectedWorkspaces.includes(value.id)) {
      setSelectedWorkspaces(list => list.filter(v => v !== value.id));
    } else {
      setSelectedWorkspaces(list => [...list, value?.id]);
    }
  };

  const filteredWorkspacesList = useMemo(() => {
    return workspacesList.filter(item => (item.organization.name + ' ' + item.organization.shortName).includes(searchText));
  }, [workspacesList, searchText]);

  const textFieldValue = useMemo(() => {
    if (value.length === 0) {
      return '';
    }
    const firstWorkspace = workspacesList.find(w => w.id === value[0]);
    return firstWorkspace?.organization?.shortName || firstWorkspace?.organization?.name;
  }, [value, workspacesList]);

  const workspacesTooltip = useMemo(() => {
    if (value?.length <= 1) {
      return null;
    }

    const tooltipWorkspaces = value
      ?.map(workspaceId => workspacesList.find(w => w.id === workspaceId))
      .filter(Boolean);

    const tooltipContent =
      <Box className={classes.tooltipContent}>
        <ul>
          {tooltipWorkspaces?.map(workspace =>
            <li>{workspace.organization?.shortName || workspace.organization.name}</li>,
          )}
        </ul>
      </Box>;

    return (
      <Tooltip
        arrow
        placement="top"
        title={tooltipContent}
        leaveDelay={500}
        classes={{
          popper: classes.workspacesTooltipPopper,
          tooltip: classes.workspacesTooltip,
        }}>
        <span>(+{tooltipWorkspaces?.length - 1})</span>
      </Tooltip>);
  }, [value, workspacesList]);


  useEffect(() => {
    setSearchText('');
  }, [isOpen]);

  return (
    <>
      <label className={classes.fieldLabel}>{label}</label>

      <Button
        variant="contained"
        color="secondary"
        className={cn(classes.workspaceDropdownField)}
        disabled={disabled}
        onClick={(event) => {
          setOpen(v => !v);
          dropdownAnchor.current = event.target as HTMLElement;
        }}>
        <Box display="flex" alignItems="center">
          {(value?.length && !!value[0]) ? (
            <Box display="flex" alignItems="center">
              {textFieldValue}
              {value?.length > 1 && (
                <Box ml={1}>
                  {workspacesTooltip}
                </Box>
              )}
            </Box>
          ) : (
             <Typography>
               {t('form_components.select.placeholder')}
             </Typography>
           )}
        </Box>

        <img
          src={DropdownIndicator}
          className={cn(classes.workspacesDropdownIcon)}
          alt=""
        />
      </Button>

      {/*<ClickAwayListener onClickAway={() => isOpen && setOpen(false)}>*/}
      <Popper
        id="process-copy-workspace-select-dropdown"
        open={isOpen}
        anchorEl={dropdownAnchor.current}
        className={classes.dropdownPopper}
        placement="bottom-start"
        keepMounted
      >
        <Box className={cn(classes.workspacesDropdown, { [classes.workspacesDropdownActive]: isOpen })}>
          <Box className={classes.workspacesDropdownSearchWrapper}>
            <TextField
              autoFocus
              fullWidth
              size="small"
              value={searchText}
              inputRef={searchInputRef}
              onClick={(event) => {
                searchInputRef?.current?.focus();
              }}
              placeholder={t('form_components.select.search_placeholder')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={SearchIcon} className={classes.workspacesDropdownIcon}/>
                  </InputAdornment>
                ),
              }}
              className={classes.workspacesSearchTextField}
              onChange={(event) => {
                setSearchText(event.target.value);
              }}
            />
          </Box>

          <Box className={classes.scrollArea}>
            {loading
             ?
             <Box className={classes.loaderWrapper}>
               <div className={classes.loaderSpinnerWrapper}>
                 <Spinner size={20} absolute/>
               </div>
               <span>{t('form_components.loaderText')}</span>
             </Box>
             :
             <>
               <Box className={classes.infoBox}>
                 {t('customProcesses.copyProcessDialog.workspaceSelect.infoText')}
               </Box>

               <Box className={classes.dropdownList}>
                 {filteredWorkspacesList.filter(v => selectedWorkspaces.includes(v.id))
                   ?.map((option: WorkspaceInfo) => (
                     <Box
                       key={option.id}
                       className={cn(classes.menuItem, classes.menuItemSelected)}
                       onClick={() => handleSelectOption(option)}
                     >
                       <Checkbox
                         color="default"
                         checked={selectedWorkspaces?.includes(option.id)}
                         disabled={selectedValuesLimit >= 0 && selectedWorkspaces?.length >= selectedValuesLimit}
                         className={cn(classes.listCheckbox, classes.checkbox,
                           {
                             [classes.checkboxChecked]: selectedWorkspaces?.includes(option.id),
                             [classes.checkboxDisabled]: !selectedWorkspaces.includes(option.id) && selectedValuesLimit >= 0 && selectedWorkspaces?.length >= selectedValuesLimit,
                           })}
                       />
                       <WorkspaceSelectItem workspace={option}/>
                     </Box>
                   ))}

                 {filteredWorkspacesList.filter(v => !selectedWorkspaces.includes(v.id))
                   ?.map((option: WorkspaceInfo) => (
                     <Box
                       key={option.id}
                       className={classes.menuItem}
                       onClick={() => handleSelectOption(option)}
                     >
                       <Checkbox
                         color="default"
                         checked={selectedWorkspaces.includes(option.id)}
                         disabled={selectedValuesLimit >= 0 && selectedWorkspaces?.length >= selectedValuesLimit}
                         className={cn(classes.listCheckbox, classes.checkbox,
                           {
                             [classes.checkboxChecked]: selectedWorkspaces.includes(option.id),
                             [classes.checkboxDisabled]: !selectedWorkspaces.includes(option.id) && selectedValuesLimit >= 0 && selectedWorkspaces?.length >= selectedValuesLimit,
                           })}
                       />
                       <WorkspaceSelectItem workspace={option}/>
                     </Box>
                   ))}
               </Box>
             </>
            }
          </Box>

          {!loading &&
            <Box className={classes.dropdownFooter}>
            <Button variant="text" onClick={handleDropdownClear} disabled={!selectedWorkspaces?.length}>
              {t('form_components.select.clear')}
            </Button>
            <Button variant="text" onClick={handleDropdownSubmit}
                    disabled={!hasChanges || !selectedWorkspaces?.length}>
              {t('form_components.select.apply')}
            </Button>
          </Box>
          }
        </Box>
      </Popper>
      {/*</ClickAwayListener>*/}
    </>
  );
};
