import React, { ReactElement } from 'react';
import { Box, Button, IconButton, Modal, Typography } from '@mui/material';
import CloseIcon from 'assets/images/icons/close.svg';
import { useTranslation } from 'react-i18next';

import useDocumentSignatureStepRemovalDialogStyles from './DocumentSignatureStepRemovalDialog.useStyles';

interface DocumentSignatureStepRemovalDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const DocumentSignatureStepRemovalDialog = ({
  isOpen,
  onClose,
}: DocumentSignatureStepRemovalDialogProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useDocumentSignatureStepRemovalDialogStyles();

  return (
    <Modal open={isOpen}>
      <Box className={classes.modalBody}>
        <div className={classes.modalHeader}>
          <Typography className={classes.title}>
            {t('customProcesses.creationPage.processForm.documentSignatureStepRemovedTitle')}
          </Typography>

          <IconButton onClick={onClose} size="large">
            <img src={CloseIcon} className={classes.closeIcon}/>
          </IconButton>
        </div>

        <div className={classes.modalContent}>
          <Typography className={classes.description}>
            {t('customProcesses.creationPage.processForm.documentSignatureStepRemovedMessage')}
          </Typography>
        </div>

        <div className={classes.modalFooter}>
          <Button variant="contained" color="secondary" className={classes.modalButton} onClick={onClose}>
            {t('template_outdated_modal.button_close')}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
