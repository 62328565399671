import React, { useEffect } from 'react';
import cn from 'classnames';

import { ErrorMessage, Spinner } from 'components';

import useTaskHistory from './useTaskHistory';

import { TaskStateType, HistoryNode } from './history.types';
import { HistoryTabNode } from './HistoryTabNode';
import useStyles from './useStyles';

type Props = {
  className?: string;
  activeTab?: string;
  companyId?: string;
  setSteps?: (steps: any[]) => void;
};

export const HistoryTab = ({ className, activeTab, companyId, setSteps }: Props) => {
  const {
    steps,
    error,
    loading,
  }: TaskStateType = useTaskHistory({ isActiveTab: activeTab === 'history' });

  const classes = useStyles({isStepCompleted: false, isLastStep: false, isFutureStep: false, isBranchStep: false, isOpenedStep: false, isParallelStep: false});

  useEffect(() => {
    setSteps?.(steps);
  }, [setSteps, steps]);

  if (loading && activeTab === 'history') {
    return <Spinner/>;
  }

  return (
    <div className={cn(className, classes.historyTabContentWrapper)}>
      {
      error
      ? <ErrorMessage text={error}/>
      : steps.map((node: HistoryNode, nodeIndex: number) => {
          return (
            <HistoryTabNode
              node={node}
              nodeIndex={nodeIndex}
              nodeCount={steps.length}
              companyId={companyId}
            />
          );
        },
      )}
    </div>
  );
};
