import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  groupTitle: {
    marginBottom: theme.spacing(2)
  },
  groupLink: {
    marginRight: theme.spacing(2),
    '&:last-child': {
      marginRight: 0
    },
    '&:hover': {
      textDecoration: 'none'
    }
  }
}));

export default useStyles;
