import { useState, useMemo, useEffect } from 'react';
import axios  from 'axios';
import { useTranslation } from 'react-i18next';

import { AttachmentItemType } from '../attachments-component';

import {
  getInstance,
  downloadAttachmentFromPS,
  uploadAttachmentToPS
} from 'api/requests';

import { useTaskState } from 'store/requests';
import { useUsersState } from 'store/users';
import { getProfileCompanyDataFromLocalStorage, getUserDataByIdOrLogName } from 'utils/user';
import { createFileInstance, getErrorMessage } from '../utils';
import { useUserProfile } from "hooks";

import { INITIAL_LOADING_PROGRESS, ERROR_MESSAGES } from '../constants';
import { environment } from 'environments/environment';
import { getCorrectTimeWithISOFormat } from 'utils/time';
const { env } = environment;

export const useAttachments = ({
  value,
  formErrors,
  updateValue,
  clearFormErrors,
  isReadOnly,
  storage
}) => {
  const { t } = useTranslation();
  const { data: bpmTask } = useTaskState();
  const { companyId } = useUserProfile();

  const { users } = useUsersState();

  const [attachments, setAttachments] = useState<AttachmentItemType[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const fieldValidationErrorMessage = useMemo((): string =>
    getErrorMessage(formErrors),
    [formErrors]);

  useEffect(() => {
    // fix empty attachments value on first step form after going
    // to previous steps from the summary step
    if (value.length === 0 && attachments.length > 0 && bpmTask.isFirstStep) {
      const uploadedAttachmentsIds = attachments
        .filter(
          ({ isLoading, isDeleted, error }) =>
            !isLoading && !isDeleted && !error
        )
        .map(({ id }) => id);
      if (uploadedAttachmentsIds.length > 0) {
        const newValue = uploadedAttachmentsIds.join(',');
        updateValue(newValue);
      }
    }
  }, [value, attachments, bpmTask.isFirstStep, updateValue]);

  useEffect(() => {
    if (attachments.length > 0 && !isReadOnly) {
      const uploadedAttachmentsIds = attachments
      .filter(({ isLoading, isDeleted, error }) => !isLoading && !isDeleted && !error)
      .map(({ id }) => id);

      const uniqueAttachmentsIds = uploadedAttachmentsIds
        .reduce((idsArray, id) => idsArray.includes(id) ? idsArray : [...idsArray, id], []);

      const attachmentNewValue = uniqueAttachmentsIds.join(',');
      const attachmentOldValue = value.join(',');

      if (attachmentNewValue !== attachmentOldValue) {
        updateValue(attachmentNewValue);
      }
      // if value or updateValue is in dependency array
      // component is lagging in modal window because of
      // infinity running of this useEffect
    }
  }, [attachments, isReadOnly]);

  useEffect(() => {
    if (attachments.length === 0 && value.length > 0) {
      const fetchAttachments = () => {
        value.forEach(async (attachmentId) => {
          const instance = await getInstance(attachmentId);
          const { stringAttributes, integerAttributes } = instance;

          const attachmentItem: AttachmentItemType = {
            cancelToken: axios.CancelToken.source(),
            filename: stringAttributes.find(({ name }) => name === 'Название').value,
            id: attachmentId,
            isLoading: false,
            randomId: Math.random(),
            type: stringAttributes.find(({ name }) => name === 'Тип').value
          };

          const isNewVersion = !!stringAttributes.find(({ name }) => name === 'step');
          if (isNewVersion) {
            const attachmentsStepSysName = stringAttributes.find(({ name }) => name === 'step').value
            const attachmentStep = bpmTask.actions.find(({ sysName }) => sysName === attachmentsStepSysName);

            const attachmentAssigneeKey = stringAttributes.find(({ name }) => name === 'assignee')?.value;
            const attachmentAssigneeUser = users[attachmentAssigneeKey];
            if (attachmentAssigneeUser) {
              attachmentItem.assignee = attachmentAssigneeUser?.fullName;
            } else {
              try {
                const userInfo = await getUserDataByIdOrLogName(attachmentAssigneeKey, companyId);
                attachmentItem.assignee = userInfo?.fullName || t('task_data_view.employee_not_found');
              } catch (e) {
                attachmentItem.assignee = t('task_data_view.employee_not_found');
              }
            }

            const attachmentsStepLocalizationKey = `constructor-${bpmTask.processSysName}.actions.${attachmentsStepSysName}.name`;
            attachmentItem.step = t(attachmentsStepLocalizationKey, {defaultValue: attachmentStep?.name});
            attachmentItem.creationDate = stringAttributes.find(({ name }) => name === 'creationDate')?.value || '';
            attachmentItem.size = integerAttributes.find(({ name }) => name === 'size')?.value || 0;
          }

          setAttachments((attachmentsList) => {
            const isDuplicateAttachment = attachmentsList.find(({ id }) => id === attachmentItem.id);
            if (isDuplicateAttachment) {
              return attachmentsList;
            }

            return [...attachmentsList, attachmentItem]
              .sort((a, b) => +b.id - +a.id);
          });
        });
      };

      fetchAttachments();
    }
  }, [value, setAttachments]);

  useEffect(() => {
    setErrorMessage(fieldValidationErrorMessage);
  }, [fieldValidationErrorMessage]);

  const updateAttachment = (attachmentData: AttachmentItemType) => {
    const { randomId } = attachmentData;
    setAttachments((atts) => {
      const attachmentIndex = atts.indexOf(
        atts.find((att) => att.randomId === randomId)
      );
      if (attachmentIndex >= 0) {
        return [
          ...atts.slice(0, attachmentIndex),
          attachmentData,
          ...atts.slice(attachmentIndex + 1),
        ];
      }
      return atts;
    });
  };

  const resetAllErrors = () => {
    setErrorMessage('');
    clearFormErrors();
  };

  const handleAttachmentClick = async (attachment: AttachmentItemType) => {
    const { id, filename, type } = attachment;

    if (!filename) {
      return;
    }

    updateAttachment({
      ...attachment,
      isLoading: true,
      loadingProgress: 0.1
    });

    try {
      await downloadAttachmentFromPS(id, filename, type);
    } catch (error) {
      setErrorMessage(t(ERROR_MESSAGES.ATTACHMENT_DOWNLOAD));
    } finally {
      updateAttachment({
        ...attachment,
        isLoading: false,
        loadingProgress: 1
      });
    }
  };

  async function initPSUploading(file: File, attachmentItem: AttachmentItemType) {
    const fileId = await createFileInstance({
      payload: {
        file: {
          id: '',
          name: attachmentItem.filename,
          mimeType: attachmentItem.type,
          size: attachmentItem.size
        },
        taskId: bpmTask.taskId,
        applicationNumber: bpmTask.applicationNumber,
        step: bpmTask.currentAction.sysName,
        assignee: bpmTask.assignee,
        creationDate: getCorrectTimeWithISOFormat()
      },
      cancelToken: attachmentItem.cancelToken.token
    });

    updateAttachment({
      ...attachmentItem,
      id: fileId,
      isLoading: true,
      loadingProgress: 0.2,
    });

    const { id: profileCompanyId } = getProfileCompanyDataFromLocalStorage();
    const companyId = bpmTask.values['companyId'] || profileCompanyId;

    const formData = new FormData();
    formData.append('file', file);

    try {
      const handleUploadProgress = (progress: number) => {
        updateAttachment({
          ...attachmentItem,
          id: fileId,
          isLoading: true,
          loadingProgress: 0.2 + 0.8 * progress,
        });
      }

      await uploadAttachmentToPS({
        params: {
          companyId,
          fileId
        },
        data: formData,
        config: {
          handleUploadProgress,
          cancelToken: attachmentItem.cancelToken.token
        }
      });

      updateAttachment({
        ...attachmentItem,
        id: fileId,
        isLoading: false,
        loadingProgress: 1,
      });
    } catch (error) {
      if (error?.message === t(ERROR_MESSAGES.ATTACHMENT_UPLOAD_CANCELED)) {
        return;
      }

      const isFileLarge = error.response?.status === 413;
      updateAttachment({
        ...attachmentItem,
        id: fileId,
        isLoading: false,
        error: isFileLarge ? t(ERROR_MESSAGES.PS_FILE_TOO_LARGE) : t(ERROR_MESSAGES.ATTACHMENT_UPLOAD),
      });

      if (isFileLarge) {
        setErrorMessage(t(ERROR_MESSAGES.PS_FILE_TOO_LARGE));
      } else {
        setErrorMessage(t(ERROR_MESSAGES.PS_DEFAULT));
      }
    }
  }

  const handleAttachmentUpload = async (attachmentFile: File) => {
    resetAllErrors();
    const attachmentsStepLocalizationKey = `constructor-${bpmTask.processSysName}.actions.${bpmTask.stepSysName}.name`;

    const attachmentData = {
      randomId: Math.random(),
      filename: attachmentFile.name,
      size: attachmentFile.size,
      type: attachmentFile.type,
      step: t(attachmentsStepLocalizationKey, {defaultValue: bpmTask.currentAction.name}),
      assignee: users[bpmTask.assignee]?.fullName || t('task_data_view.employee_not_found'),
      creationDate: getCorrectTimeWithISOFormat(),
      isLoading: true,
      loadingProgress: INITIAL_LOADING_PROGRESS,
      cancelToken: axios.CancelToken.source()
    };

    setAttachments((attachments) => [attachmentData, ...attachments]);

    initPSUploading(attachmentFile, attachmentData);
  };

  const handleAttachmentRemoval = (attachment: AttachmentItemType): void => {
    if (attachment.isLoading) {
      attachment.cancelToken.cancel(t(ERROR_MESSAGES.ATTACHMENT_UPLOAD_CANCELED));
    }

    attachment.isDeleted = true;

    const existingAttachments = attachments
      .filter(({ randomId, isDeleted }: AttachmentItemType) => randomId !== attachment.randomId && !isDeleted);
    if (!existingAttachments.length) {
      setErrorMessage('');
    }

    const attachmentIndex = attachments.findIndex(({randomId}) => randomId === attachment.randomId);
    const newAttachments = [...attachments];
    newAttachments[attachmentIndex] = attachment;

    setAttachments(newAttachments);
  };

  const handleAttachmentRemovalUndoing = (attachment: AttachmentItemType): void => {
    if (!attachment.isLoading) {
      attachment.isDeleted = false;
      const attachmentIndex = attachments.findIndex(({ randomId }) => randomId === attachment.randomId);
      const newAttachments = [...attachments];
      newAttachments[attachmentIndex] = attachment;

      setAttachments(newAttachments);
    }
  };

  return {
    attachments,
    handleAttachmentUpload,
    handleAttachmentRemoval,
    handleAttachmentRemovalUndoing,
    handleAttachmentClick,
    errorMessage,
    setErrorMessage,
  };
};
