import React, { useMemo } from 'react';
import cn from 'classnames';
import { List, ListItem, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Spinner } from 'components';

import useStyles from './useStyles';
import { useRequestActiveStep, useSkippedTaskSteps, useTaskState, useTaskSteps } from 'store/requests';

export const Steps = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data: bpmTask } = useTaskState();
  const { activeStep } = useRequestActiveStep();
  const { skippedSteps } = useSkippedTaskSteps();
  const steps = useTaskSteps();

  const stepLocalizationKeyPrefix = useMemo(() =>
    `constructor-${bpmTask.processSysName}.form-steps.`,
  [bpmTask]);

  return steps ? (
    <Box className={classes.root}>
      <List disablePadding>
        {steps.map((step, i) => (
          <ListItem
            key={step.order}
            selected={activeStep === i}
            className={cn(classes.listItem, {
              [classes.completed]: activeStep > i,
              [classes.skipped]: skippedSteps.includes(step.order),
            })}
          >
            <Typography variant="body2">{t('new_request.step')} {step.order}</Typography>
            <div className={classes.listText}>
              <i />
              {t(stepLocalizationKeyPrefix + i, {defaultValue: step.description})}
            </div>
          </ListItem>
        ))}
      </List>
    </Box>
  ) : (
    <Spinner />
  );
}
