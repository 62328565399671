import styled from 'styled-components';
import { theme } from '@dartech/dms-ui';
import { ButtonBase } from '@mui/material';
import { PropsWithChildren } from 'react';

const defaultSideLength = 32;

const AttachmentItem = styled.div<PropsWithChildren<any>>`
  .AttachmentItem {
    position: relative;
    margin-left: 16px;
    margin-bottom: 24px;
    cursor: pointer;
  }

  .ContentWrapper {
    max-width: 178px;
    width: 178px;
    height: 112px;
    display: flex;
    flex-direction: column;
    background: ${theme.palette.grey[100]};
  }

  .AttachmentItemIconWrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .AttachmentIcon,
  &__icon {
    height: 30px;
    width: auto;
  }

  .AttachmentItemTitleWrapper {
    width: 100%;
    height: 32px;
    background: ${theme.palette.grey[200]};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .AttachmentItemTitle {
    font-size: 12px;
    font-weight: 400;
  }

  .AttachmentItemSize {
    color: ${theme.palette.primary[200]};
  }

  .CloseIcon {
    width: 20px;
    height: 20px;
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 8;
  }

  .AttachmentError {
    margin-top: 4px;
    margin-bottom: -8px;
    font-size: 12px;
    color: ${theme.palette.error[600]};
    text-align: left;
    max-width: 170px;
  }

  .LoadingIndicator {
    height: 4px;
    padding: 0;
    margin: 0;
    position: absolute;
    bottom: 32px;
    background: #6787e3;
  }

  .AttachmentItemCompact {
    width: 608px;
    min-height: 24px;
    padding: 8px 12px;
    padding-right: 12px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition: 0.3s background ease;

    &.AttachmentItemDisabled,
    &:hover {
      background: ${theme.palette.grey[200]};
    }

    &__loader {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .Spinner {
      color: ${theme.palette.grey[500]};
    }

    .ContentWrapper {
      height: 100%;
      width: auto;
      max-width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      background: none;

      .AttachmentIcon {
        height: 26px;
        width: auto;
      }

      .AttachmentItemInfo {
        display: flex;
        flex-direction: column;
        margin-left: 12px;
        width: 100%;

        .AttachmentItemInfoTop {
          display: flex;
          flex-direction: column;
          margin-bottom: 8px;
        }
        .AttachmentItemInfoTopOldAttachment {
          margin-bottom: 0;
          margin-top: 4px;
        }

        .AttachmentItemInfoTitle {
          width: fit-content;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #007994;
        }

        .AttachmentItemInfoDateSize {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #8a96a1;
        }

        .AttachmentItemInfoText {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #465564;

          & b {
            font-weight: 500;
          }
        }
      }
    }

    .ControlsWrapperOldAttachment {
      margin-top: 4px;
    }

    .DeletedFileContentWrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      .UndoButton {
        color: #007994;
      }
    }
  }

  .AttachmentItemCompactDashed {
    display: block;
    align-items: center;
    width: auto;
    max-width: 100%;
    border-radius: 4px;
    background: #fff;
    border-radius: 12px;
    border: 1px solid rgba(38, 40, 66, 0.12);
    padding: 8px 12px;
    margin: 6px 0;

    .ContentWrapper {
      width: 100%;
      .AttachmentItemTitle {
        color: ${theme.palette.primary[200]};
      }
      .AttachmentItemSize {
        color: ${theme.palette.grey[500]};
      }
    }
  }
  .AttachmentItemCompactDashedReadonly {
    margin: 0 0 8px;
  }

  .FileActions {
    display: flex;
    align-items: flex-end;
    margin-right: 16px;

    .DownloadButton,
    .ViewButton {
      border-radius: 8px;
    }

    .DownloadButton,
    .fileViewLoader {
      margin-right: 4px;
      width: ${defaultSideLength}px;
      max-width: ${defaultSideLength}px;
      min-width: ${defaultSideLength}px;
      padding: 0;
    }
  }
`;

const DeleteIcon = styled(ButtonBase)`
  height: 32px;
  width: 32px;
  border: 2px transparent;
  border-radius: 8px;

  &:hover {
    background: rgba(38, 40, 66, 0.04);
  }
  &:focus {
    border: 2px solid #007994;
  }
`;

const ControlsWrapper = styled.div<PropsWithChildren<any>>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: 8px;
`;

export const Styled = {
  AttachmentItem,
  DeleteIcon,
  ControlsWrapper,
};
