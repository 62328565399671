import docIcon from 'assets/images/icons/format/doc.svg';
import docxIcon from 'assets/images/icons/format/docx.svg';
import gifIcon from 'assets/images/icons/format/gif.svg';
import jpegIcon from 'assets/images/icons/format/jpeg.svg';
import jpgIcon from 'assets/images/icons/format/jpg.svg';
import movIcon from 'assets/images/icons/format/mov.svg';
import mp3Icon from 'assets/images/icons/format/mp3.svg';
import mp4Icon from 'assets/images/icons/format/mp4.svg';
import pdfIcon from 'assets/images/icons/format/pdf.svg';
import pngIcon from 'assets/images/icons/format/png.svg';
import pptIcon from 'assets/images/icons/format/ppt.svg';
import pptxIcon from 'assets/images/icons/format/pptx.svg';
import unknown from 'assets/images/icons/format/unknown.svg';
import wavIcon from 'assets/images/icons/format/wav.svg';
import xlsIcon from 'assets/images/icons/format/xls.svg';
import xlsxIcon from 'assets/images/icons/format/xlsx.svg';
import xmlIcon from 'assets/images/icons/format/xml.svg';
import zipIcon from 'assets/images/icons/format/zip.svg';

export const MIME_TYPES = {
  csv: 'text/csv',
  doc: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword',
  epub: 'application/epub+zip',
  html: 'text/html',
  jpeg: 'image/jpeg',
  jpg: 'image/jpg',
  json: 'application/vnd.google-apps.script+json',
  mov: 'video/quicktime',
  mp3: 'audio/mpeg',
  mp4: 'video/mp4',
  odp: 'application/vnd.oasis.opendocument.presentation',
  ods: 'application/x-vnd.oasis.opendocument.spreadsheet',
  odt: 'application/vnd.oasis.opendocument.text',
  pdf: 'application/pdf',
  png: 'image/png',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  rtf: 'application/rtf',
  svg: 'image/svg+xml',
  txt: 'text/plain',
  wav: 'audio/wav',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  zip: 'application/zip',
};

export const INITIAL_LOADING_PROGRESS = 0.1;

export const ERROR_MESSAGES = {
  PS_DEFAULT: 'form_components.attachment.errors.ps_upload_error',
  CAMUNDA_DEFAULT: 'form_components.attachment.errors.camunda_error',
  GOOGLE_DRIVE_DEFAULT: 'form_components.attachment.errors.google_drive_error',
  PS_FILE_TOO_LARGE: 'form_components.attachment.errors.file_too_big_200_mb',
  ATTACHMENT_DOWNLOAD: 'form_components.attachment.errors.download_error',
  ATTACHMENT_UPLOAD: 'form_components.attachment.errors.upload_error',
  ATTACHMENT_UPLOAD_CANCELED: 'form_components.attachment.errors.cancelled'
}

export const FILENAME_DISPLAY_LENGTH = 20;

export const iconsMap = {
  default: unknown,
  doc: docIcon,
  docx: docxIcon,
  jpeg: jpegIcon,
  jpg: jpgIcon,
  mp3: mp3Icon,
  mp4: mp4Icon,
  pdf: pdfIcon,
  png: pngIcon,
  ppt: pptIcon,
  pptx: pptxIcon,
  xls: xlsIcon,
  xlsx: xlsxIcon,
  xml: xmlIcon,
  gif: gifIcon,
  movIcon: movIcon,
  wav: wavIcon,
  zip: zipIcon,
};
