import React, { memo, ReactNode, ReactElement } from 'react';
import { Box } from '@mui/material';

import { useVisiblePanel } from 'store/approvals';
import useStyles from './useStyles';

type RequestsListLayoutProps = {
  children?: ReactNode;
  footerChild?: ReactNode;
  hasMenuButtons?: boolean
};

export const RequestsListLayout = memo(({
  children,
  footerChild,
  hasMenuButtons = true
}: RequestsListLayoutProps): ReactElement => {
  const visiblePanel = useVisiblePanel();
  const classes = useStyles({visiblePanel, hasMenuButtons});

  return (
    <>
      <Box className={classes.requestsListLayout}>
        <Box className={classes.requestsListWrapper}>
          {children}
        </Box>

        <Box className={classes.footerWrapper}>
          {footerChild}
        </Box>
      </Box>
    </>
  );
});
