import makeStyles from '@mui/styles/makeStyles';

type Props = {
  isModalVariant: boolean;
}

export default makeStyles(({ spacing, palette }) => ({
  documentsListFilters: {
    width: '100%',
    marginBottom: 16,
  },
  searchField: {
    '& > div': {
      borderRadius: 8,
      height: '32px !important',
      background: '#FFFFFF !important'
    },
  },
  selectsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginTop: 16,
  },
  selectLabel: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '18px',
    textAlign: 'left',
    color: '#6D6E85',
    marginBottom: 4,
  },
  typeSelectWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '50%',
    position: 'relative',
  },
  subtypeSelectWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '50%',
    position: 'relative',
  },
  selectTitle: {
    marginBottom: 4,
    color: '#6D6E85',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '18px',
  },
  typeSelect: {
    width: '100%',
    borderRadius: 8,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: 'none',
    height: 32,
  },
  subtypeSelect: {
    width: '100%',
    borderRadius: 8,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    height: 32,
  },
}));
