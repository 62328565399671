import React, { useMemo, useEffect, memo } from 'react';
import { Typography } from '@mui/material';
import NumberFormat from 'react-number-format';
import { useFormContext } from 'react-hook-form';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import isNil from 'lodash/isNil';

import useStyles from '../../useStyles';

import {
  BPM_READONLY_NUMBER__DECIMAL_SCALE__DEFAULT,
  AMOUNT__NAME,
  AMOUNT_IN_KZT__NAME,
} from './constants';
import { removeSpacesFromNumber } from 'utils/general';
import { useTaskState } from 'store/requests';

type Props = {
  name: string;
  hint: string;
  type: string;
  isTaskDetailsVariant?: boolean;
  systemVariableName?: string;
};

export const ReadOnlyNumber = memo(({
  hint,
  name,
  type,
  isTaskDetailsVariant = false,
  systemVariableName = '',
}: Props) => {
  const {t} = useTranslation();
  const { register, watch, setValue } = useFormContext();

  const { data: bpmTask } = useTaskState();

  useEffect(() => {
    if (systemVariableName) {
      const systemVariableValue = bpmTask.businessTask[systemVariableName];
      setValue(name, systemVariableValue);
    }
  }, [systemVariableName, bpmTask.businessTask, name, setValue]);

  const value = useMemo(
    () => {
      return watch(name) || watch(name) === 0 || watch(name) === '0' || watch.name === '0.00'
      ? Number(removeSpacesFromNumber(watch(name)))
      : null
    },
    [watch, name]
  );

  const decimalScale = useMemo(
    () =>
      name === AMOUNT__NAME ||
      name === AMOUNT_IN_KZT__NAME ||
      type === 'DOUBLE' ? undefined : BPM_READONLY_NUMBER__DECIMAL_SCALE__DEFAULT,
    [name]
  );

  const classes = useStyles();

  if (isNil(value)) {
    return <div>
      <Typography>{hint}</Typography>
      <Typography className={classes.emptyValueMessage}>
        {t('form_components.readOnly.emptyValue')}
      </Typography>
    </div>;
  }

  return (
    <div>
      <Typography>{hint}</Typography>
      <Typography
        color="primary"
        className={cn({
          [classes.taskDetailsReadOnly]: isTaskDetailsVariant,
        })}
      >
        <NumberFormat
          value={value}
          displayType="text"
          thousandSeparator=" "
          decimalSeparator="."
          fixedDecimalScale={!!decimalScale}
          decimalScale={decimalScale}
        />
      </Typography>
      <input type="hidden" data-selenium={name} {...register(name)} />
    </div>
  );
}, (prevProps, nextProps) =>
  prevProps.name === nextProps.name &&
  prevProps.hint === nextProps.hint &&
  prevProps.type === nextProps.type &&
  prevProps.isTaskDetailsVariant === nextProps.isTaskDetailsVariant &&
  prevProps.systemVariableName === nextProps.systemVariableName);
