import i18n from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { environment } from '../environments/environment';

export const supportedLngs = ['en', 'ru', 'kz', 'id', 'de'];
const namespace = 'processesV2';
const loadPath = `${environment.NX_BASE_V1_API}/dictionary-api/localization/all?lang={{lng}}&module={{ns}}`;

i18n
  .use(detector)
  .use(ChainedBackend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    defaultNS: namespace,
    preload: ['ru', 'kz'], // необходимо для работы локализации glossary
    backend: {
      backends: [Backend], // много динамических данных (переводы процессов и тп), кэширование в LocalStorage может вызвать проблемы
      backendOptions: [
        {
          loadPath,
        },
      ],
    },
    supportedLngs,
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default i18n;
