import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { setActiveStep, useRequestActiveStep, useSkippedTaskSteps, useTaskState } from 'store/requests';

import { useUserProfile } from 'hooks';
import { Docflow, UserType } from 'types';
import DocumentCard from '../../../../components/DocumentsView/DocumentCard';
import FormattedValues from './FormattedValues';
import UserList from '../../../../components/UserList';
import useStyles from './useStyles';
import useTaskDocuments from '../../../../components/TaskDetails/components/Tabs/DocumentsTab/useTaskDocuments';

const DocumentDetails = React.lazy(async () => ({
  default: (await import('@dar-dms/utils')).DocumentDetails,
}));

interface SummaryStepProps {
  selectedWatchers: UserType[];
  values: { [key: string]: string };
  formMethods: any;
}

export const SummaryStep = ({ values, selectedWatchers, formMethods }: SummaryStepProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useUserProfile();
  const { activeStep } = useRequestActiveStep();
  const { skippedSteps } = useSkippedTaskSteps();

  const { data: bpmTask, documents } = useTaskState();

  const goToPreviousStep = useCallback(() => {
    let previousStep = activeStep - 1;
    while (skippedSteps.includes(previousStep + 1)) {
      previousStep -= 1;
    }
    dispatch(setActiveStep(previousStep));
  }, [dispatch, skippedSteps, activeStep]);

  const docflowDocuments: Docflow.ProcessDocument[] = useMemo(
    () => ((values?.docFlowDocuments as unknown) as Array<Docflow.ProcessDocument>) || [],
    [values]
  );

  const {
    visibleDocuments,
    groupedDocuments,
    currentLoadingDocumentId,
    currentDocumentId,
    documentViewMode,
    documentsForSignature,
    signedDocuments,
    changeCurrentDocument,
    openDocumentForSign,
    previewDocument,
    downloadDocument,
    onSignComplete,
    beforeDocumentSign,
    setGoBackToFormAfterClose,
    handleDocumentClose,
    docflowDocumentsForSignature,
    docflowDocumentsForView,
    isSignatureAvailable,
    selectedDocflowDocument,
  } = useTaskDocuments({ formMethods, goToPreviousStep, docflowDocuments, fetchDocflowDocuments: true, showDeletedDocumentsError: true });

  useEffect(() => {
    if (
      groupedDocuments?.sign?.length > 0 &&
      groupedDocuments.sign.some((doc) => !doc.isSigned) &&
      currentDocumentId < 0 &&
      !selectedDocflowDocument
    ) {
      openDocumentForSign(groupedDocuments.sign[0].docflowDocumentId);
      setGoBackToFormAfterClose(true);
    } else if (
      docflowDocumentsForSignature?.length &&
      docflowDocumentsForSignature?.some((doc) => doc?.signatories?.find((user) => user.userId === id && !user.isSigned)) &&
      !selectedDocflowDocument &&
      currentDocumentId < 0
    ) {
      openDocumentForSign(docflowDocumentsForSignature?.[0]?.id, true);
      setGoBackToFormAfterClose(true);
    }
  }, [groupedDocuments, currentDocumentId, selectedDocflowDocument, docflowDocumentsForSignature]);

  const groupNameOldTranslationKeyPrefix = `constructor-${bpmTask.processSysName}.states.${bpmTask.stepSysName}.fieldGroups.`;
  const groupNameTranslationKeyPrefix = `constructor-${bpmTask.processSysName}.fieldGroups.`;
  const currentStateGroupsKeys = useMemo(() => Object.keys(bpmTask.currentStateGroups), [bpmTask]);
  const filteredCurrentStateGroypsKeys = useMemo(() => currentStateGroupsKeys.filter((groupListKey) => !groupListKey.includes('#hidden')), [
    currentStateGroupsKeys,
  ]);

  return (
    <Box component="div" className={classes.summaryWrapper}>
      <Box
        component="article"
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="flex-start"
        className={classes.summaryStepWrapper}
      >
        {filteredCurrentStateGroypsKeys.map((el, index) => (
          <>
            <Box component="section" className={classes.group} key={el}>
              <Typography variant="h5" className={classes.groupTitle}>
                {t(groupNameOldTranslationKeyPrefix + el.toLowerCase().replaceAll(' ', '-'), {
                  defaultValue: t(groupNameTranslationKeyPrefix + el.toLowerCase().replaceAll(' ', '-'), {
                    defaultValue: el,
                  }),
                })}
              </Typography>
              <Box component="table" className={classes.table}>
                {bpmTask.currentStateGroups[el].map((value) => {
                  const { name, sysName, hint, component, componentParams } = value;
                  if (component === 'documentFromDocflow') return null; // DocumentsFromDocflow are shown below
                  const params = componentParams ? JSON.parse(componentParams) : {};
                  const hintTranslationKey = `constructor-${bpmTask.processSysName}.states.${
                    bpmTask.stepSysName
                  }.attributes.${sysName.replaceAll('::', '-')}.hint`;
                  const hintTranslationNewFormatKey = `constructor-${bpmTask.processSysName}.attributes.${sysName.replaceAll(
                    '::',
                    '-'
                  )}.hint`;

                  return (
                    (!!hint || component === 'file') &&
                    component !== 'hidden-field' && (
                      <>
                        {values && component === 'file' && (
                          <tr>
                            <td className={classes.fieldName}>
                              {t(hintTranslationKey, { defaultValue: t(hintTranslationNewFormatKey, { defaultValue: hint }) })}
                            </td>
                          </tr>
                        )}
                        <tr>
                          {values && (
                            <>
                              {!!hint && component !== 'file' && (
                                <td className={classes.fieldName}>
                                  {t(hintTranslationKey, { defaultValue: t(hintTranslationNewFormatKey, { defaultValue: hint }) })}
                                </td>
                              )}
                              {!params?.groupParams ? (
                                <td className={classes.fieldValue}>
                                  <FormattedValues
                                    value={values[name]}
                                    values={values}
                                    name={name}
                                    hint={hint}
                                    component={component}
                                    params={params}
                                    sysName={sysName}
                                  />
                                </td>
                              ) : (
                                <FormattedValues
                                  value={values[name]}
                                  values={values}
                                  name={name}
                                  hint={hint}
                                  component={component}
                                  params={params}
                                  sysName={sysName}
                                />
                              )}
                            </>
                          )}
                        </tr>
                      </>
                    )
                  );
                })}
              </Box>
            </Box>

            {index === filteredCurrentStateGroypsKeys.length - 1 && (
              <Box component="section" className={classes.group}>
                <Typography variant="h5" className={classes.groupTitle}>
                  {t('Watchers.watchers')}
                </Typography>
                <Box component="table" className={classes.table}>
                  <tr>
                    <td className={classes.fieldName}>{t('Watchers.processWatchers')}</td>
                    <td className={classes.fieldValue}>
                      <UserList readOnly users={selectedWatchers} />
                    </td>
                  </tr>
                </Box>
              </Box>
            )}
          </>
        ))}

        <Box component="section" className={classes.group} key={'documents'}>
          {visibleDocuments.length > 0 || docflowDocumentsForSignature.length || docflowDocumentsForView?.length ? (
            <Typography variant="h5" className={classes.groupTitle}>
              {t('new_request.summary.documents.title')}
            </Typography>
          ) : null}
          <table className={classes.documentsTable}>
            {groupedDocuments?.sign.length > 0 || docflowDocumentsForSignature.length ? (
              <>
                <tr>
                  <td>{t('new_request.summary.documents.signingRequired')}</td>
                </tr>
                <tr className={classes.documentTableList}>
                  {groupedDocuments?.sign?.map((document) => (
                    <td>
                      <DocumentCard
                        documentId={document.docflowDocumentId}
                        documentName={document.name}
                        fileType={'pdf'}
                        size={document.size}
                        creationDate={document.dataCreate}
                        stepName={t(`constructor-${bpmTask.processSysName}.actions.${bpmTask.currentAction.sysName}.name`, {
                          defaultValue: bpmTask.currentAction.name,
                        })}
                        needSignature={true}
                        hasSignatures={document.isSigned || document.signings.some((v) => v.status)}
                        isSigned={document?.isSigned}
                        isFullySigned={document.signings.every(
                          (signing) => signing.status || (signing.stepOrder === bpmTask.currentAction.stepperOrder && document.isSigned)
                        )}
                        isPublished={document?.isPublished}
                        isLoading={document.docflowDocumentId === currentLoadingDocumentId}
                        onSign={openDocumentForSign}
                        onPreview={previewDocument}
                        onDownload={downloadDocument}
                      />
                    </td>
                  ))}
                  {docflowDocumentsForSignature.map((doc) => (
                    <td>
                      <DocumentCard
                        documentId={doc.id}
                        documentName={doc.title}
                        fileType={'pdf'}
                        size={0}
                        creationDate={doc.createdAt}
                        stepName={t(`constructor-${bpmTask.processSysName}.actions.${bpmTask.currentAction.sysName}.name`, {
                          defaultValue: bpmTask.currentAction.name,
                        })}
                        isSigned={doc.status === 'SIGNED' || doc.signatories.find((user) => user.userId === id)?.isSigned}
                        isFullySigned={doc.signatories.every((item) => item.isSigned)}
                        isPublished={doc.status !== 'DRAFT'}
                        isLoading={doc.id === currentLoadingDocumentId}
                        hasSignatures={doc.status === 'SIGNED' || doc.signatories.some((user) => user.isSigned)}
                        needSignature={
                          bpmTask.isAssignee(id, false) &&
                          isSignatureAvailable &&
                          !doc.signatories.find((user) => user.userId === id)?.isSigned
                        }
                        onSign={openDocumentForSign}
                        onDownload={downloadDocument}
                        onPreview={previewDocument}
                        isDocflowDocument
                      />
                    </td>
                  ))}
                </tr>
              </>
            ) : null}

            {groupedDocuments?.view.length > 0 || docflowDocumentsForView.length ? (
              <>
                <tr>
                  <td>{t('new_request.summary.documents.noSignature')}</td>
                </tr>
                <tr className={classes.documentTableList}>
                  {groupedDocuments?.view?.map((document) => (
                    <td>
                      <DocumentCard
                        documentId={document.docflowDocumentId}
                        documentName={document.name}
                        fileType={'pdf'}
                        size={document.size}
                        creationDate={document.dataCreate}
                        stepName={t(`constructor-${bpmTask.processSysName}.actions.${bpmTask.currentAction.sysName}.name`, {
                          defaultValue: bpmTask.currentAction.name,
                        })}
                        needSignature={false}
                        hasSignatures={document.isSigned || document.signings.some((v) => v.status)}
                        isSigned={false}
                        isFullySigned={document.signings.every(
                          (signing) => signing.status || (signing.stepOrder === bpmTask.currentAction.stepperOrder && document.isSigned)
                        )}
                        isLoading={document.docflowDocumentId === currentLoadingDocumentId}
                        isPublished={document?.isPublished}
                        onPreview={previewDocument}
                        onDownload={downloadDocument}
                      />
                    </td>
                  ))}
                  {docflowDocumentsForView.map((doc) => (
                    <td>
                      <DocumentCard
                        documentId={doc.id}
                        documentName={doc.title}
                        fileType={'pdf'}
                        size={0}
                        creationDate={doc.createdAt}
                        stepName={t(`constructor-${bpmTask.processSysName}.actions.${bpmTask.currentAction.sysName}.name`, {
                          defaultValue: bpmTask.currentAction.name,
                        })}
                        isSigned={doc.status === 'SIGNED' || doc.signatories.find((user) => user.userId === id)?.isSigned}
                        isPublished={doc.status !== 'DRAFT'}
                        isFullySigned={doc.signatories.every((item) => item.isSigned)}
                        hasSignatures
                        needSignature={false}
                        isLoading={doc.id === currentLoadingDocumentId}
                        onPreview={previewDocument}
                        onDownload={downloadDocument}
                        isDocflowDocument
                      />
                    </td>
                  ))}
                </tr>
              </>
            ) : null}
          </table>
          {(selectedDocflowDocument || currentDocumentId >= 0) && (
            <DocumentDetails
              id={currentDocumentId >= 0 ? currentDocumentId : selectedDocflowDocument}
              isPreview={documentViewMode === 'view'}
              documentsList={[...documentsForSignature, ...docflowDocumentsForSignature.map((doc) => doc.id)]}
              onClose={handleDocumentClose}
              onSignSuccess={documentViewMode === 'view' ? null : onSignComplete}
              signedDocumentsList={[
                ...signedDocuments,
                ...docflowDocumentsForSignature
                  .filter((doc) => doc.signatories.find((signatory) => signatory.userId === id && signatory.isSigned))
                  .map((doc) => doc.id),
                ...docflowDocumentsForView
                  .filter((doc) => doc.signatories.find((signatory) => signatory.userId === id && signatory.isSigned))
                  .map((doc) => doc.id),
              ]}
              handleCurrentDocumentChange={changeCurrentDocument}
              beforeSign={beforeDocumentSign}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
