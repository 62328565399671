import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ spacing, palette }) => ({
  title: {
    fontWeight: 600,
    color: palette.grey[600],
    marginTop: spacing(1),
    marginBottom: spacing(3)
  },
  reason: {
    color: palette.grey[700],
    marginBottom: spacing(4)
  }
}));
