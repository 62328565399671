import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ spacing, palette }) => ({
  processStepForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: "24px",
    overflow: 'auto',
    height: '100%',
    maxHeight: '100%',
  },
  processStepFormSection: {
    marginBottom: 40,
    width: "100%",
    maxWidth: 460,
  },
  fieldGroupDocument: {
    overflow: 'hidden',
    borderRadius: 12,
    border: '1px solid rgba(38, 40, 66, 0.08)',
  },
  fieldGroupHeaderDocument: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: "12px 16px",
    borderBottom: "1px solid rgba(38, 40, 66, 0.08)",
    background: "#F7F8FC",
  },
  processStepFormSectionTitle: {
    marginTop: 0,
    marginBottom: 12,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 20,
    color: '#262842',
  },
  processStepFormSectionTitleWithDescription: {
    marginBottom: 8,
  },
  processStepFormSectionDescription: {
    marginTop: 0,
    marginBottom: 12,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#262842',
  },
  processStepFormEditingDisabledIndicator: {
    height: 48,
    width: 'fit-content',
    maxWidth: '100%',
    marginBottom: spacing(7),
    marginTop: spacing(-3),
    padding: spacing(3, 5),
    background: '#FFE8C2',
    borderRadius: 10,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    color: '#262842',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& span': {
      color: '#007994',
      cursor: 'pointer',
    },
  },
  processStepFormTitle: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 1.3,
    color: '#101F2B',
    marginBottom: spacing(2.75)
  },
  processStepFormIcon: {
    height: spacing(6),
    width: spacing(6),
  },
  processStepFormFileTitle: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.4,
    color: '#465564',
  },
  processStepFormFileText: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 1.3,
    color: '#8A96A1',
  },
  textFieldDisabled: {
    '& div': {
      '&, &:hover': {
        cursor: 'auto !important',
        backgroundColor: '#F0F0F7 !important',
        color: '#6D6E85 !important',
        border: '1px solid rgba(38, 40, 66, 0.12) !important',
      },
    },
  },
  directManagerCheckbox: {
    marginLeft: -10,
  },
  processStepFormErrorMessage: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: spacing(1),
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    color: '#D6331F',
    '& img': {
      marginRight: spacing(1.25),
      height: 13,
      width: 13,
    },
  },
  processStepFormErrorMessageTwoSides: {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  endingAfterInputsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  readonlyProcessInfoWrapper: {
    width: '100%',
    maxWidth: 800,
    padding: spacing(6),
    paddingBottom: spacing(12),
    marginTop: spacing(3),
    borderRadius: 12,
    border: '1px solid rgba(38, 40, 66, 0.08)',
  },
  readonlyProcessInfoTitleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: spacing(5),
  },
  readonlyProcessInfoTitleIcon: {
    width: 32,
    height: 32,
    marginRight: spacing(3),
  },
  readonlyProcessInfoTitle: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 20,
    color: '#262842',
    margin: 0,
  },
  readonlyProcessInfoRow: {
    paddingTop: 18,
    paddingBottom: 18,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'top',
    borderBottom: '1px solid rgba(38, 40, 66, 0.12)',
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  readonlyProcessInfoTitleColumn: {
    width: 160,
    minWidth: 160,
    marginRight: 12,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    color: '#8B8C9E',
  },
  requiredFieldTitle: {
    '&::after': {
      display: 'inline-block',
      content: '"*"',
      color: palette.error[600],
      fontSize: 16,
      position: 'relative',
      right: -2,
      top: -2,
    },
  },
  readonlyProcessInfoContentColumn: {
    position: 'relative',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    color: '#262842',
    width: '100%',
  },
  readonlyUsersListScrollbars: {
    transitionProperty: 'height',
    transitionDuration: '500ms',
    maxHeight: 296,
    minHeight: 24,
  },
  readonlyUsersList: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '24px',
    color: '#262842',
    '& b': {
      paddingLeft: spacing(1),
      fontWeight: 600,
    },
  },
  readonlyUsersListCompact: {
    '& li:not(:first-child)': {
      visibility: 'hidden',
      display: 'none',
    },
  },
  readonlyUsersInfoMessage: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
    color: '#6D6E85',
    display: 'block',
    marginTop: 4,
  },
  readonlyDescription: {
    marginTop: 16,
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
    '& p, & li': {
      margin: 0,
      marginTop: spacing(-4),
    },
    '& ul, ol': {
      marginTop: spacing(-2),
      marginBottom: spacing(-2),
      paddingLeft: spacing(6)
    }
  },
  usersListToggleButton: {
    position: 'absolute',
    top: 0,
    right: 12,
    padding: 0,
    margin: 0,
    height: 'fit-content',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    color: '#007994',
    zIndex: 1,
  },
  descriptionToggleButton: {
    padding: 0,
    margin: 0,
    height: 'fit-content',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    color: '#007994',
    zIndex: 1,
    minWidth: 'fit-content',
    marginTop: 4,
  },
  readonlyDescriptionCompactWrapper: {
    maxHeight: 120,
    height: 150,
    overflow: 'hidden'
  },
  processStepAssigneeWrapper: {
    display: 'flex',
  },
  processStepAssigneeCount: {
    color: '#101F2B',
    fontSize: 12,
    fontWeight: 500,
    marginLeft: spacing(2),
  },
  processStepAssigneesTooltipPopper: {
    pointerEvents: 'all',
  },
  processStepAssigneesTooltip: {
    maxHeight: 180,
    padding: 10,
    fontSize: 12,
    fontWeight: 500,
    color: palette.grey[50],
  },
  tooltipContent: {
    height: 160,
    width: 160,
    maxHeight: 160,
    maxWidth: 160,
    overflowY: 'auto',
    '& ul': {
      margin: 0,
      padding: 0,
      paddingLeft: 20,
    },
  },
  executionTimeWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  stepNameField: {
    '& > div': {
      borderRadius: 10,
    }
  },
  fieldsListWrapper: {
    minHeight: 8,
    padding: '12px 4px',
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    backgroundColor: 'transparent',
    transitionProperty: 'background',
    transitionDuration: '200ms',
  },
  fieldsListWrapperDocument: {
    minHeight: 8,
    padding: '12px 4px',
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    backgroundColor: 'transparent',
    transitionProperty: 'background',
    transitionDuration: '200ms',
  },
  fieldsListDragZoneVisible: {
    backgroundColor: '#DEF7FC',
  },
  processStepFormFieldsInfoMessage: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#8B8C9E',
  },
  fieldGroupHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: "12px 16px",
    borderBottom: "1px solid rgba(38, 40, 66, 0.08)",
    background: "#F7F8FC",
  },
  processStepFormFieldsGroupTitle: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    color: '#262842',
  },
  processStepFormFieldsGroupIcon: {
    width: 20,
    height: 20,
    marginRight: 12,
  },
  fieldGroup: {
    overflow: "hidden",
    borderRadius: 12,
    border: "1px solid rgba(38, 40, 66, 0.08)",
  },
  readonlyProcessInfoReadonlyFieldComponentSuffix: {
    color: '#8B8C9E',
  },
  signatureChip: {
    marginLeft: 8,
    padding: '4px 6px',
    borderRadius: 6,
    background: '#F0F0F7',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px'
},
}));
