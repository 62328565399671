import React, { ReactElement, useMemo } from 'react';
import { Typography } from '@mui/material';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import useProcessStepTagStyles from './ProcessStepTag.useStyles';

interface ProcessStepTagProps {
  stepOrder: number | string;
  size?: 'small' | 'medium';
  isConditionStep?: boolean;
  isParallelStepsGroup?: boolean;
  isIntegratedStep?: boolean;
}

export const ProcessStepTag = ({
  stepOrder,
  size = 'medium',
  isConditionStep = false,
  isParallelStepsGroup = false,
  isIntegratedStep = false,
}: ProcessStepTagProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useProcessStepTagStyles();

  const isFirstStep = stepOrder === 1;

  const processStepTagTitle = useMemo(() => {
    if (isIntegratedStep) {
      return t('customProcesses.creationPage.processStepTag.integratedStep', {defaultValue: 'Integrated'});
    }

    if (isConditionStep) {
      return t('customProcesses.creationPage.processStepTag.conditionStep');
    }

    if (isParallelStepsGroup) {
      return t('customProcesses.creationPage.processStepTag.parallelStepsGroup');
    }

    if (isFirstStep) {
      return t('customProcesses.creationPage.processStepTag.start');
    }

    return `${t('customProcesses.creationPage.processStepTag.step')} ${stepOrder}`;
  }, [isConditionStep, isParallelStepsGroup, stepOrder]);

  return (
    <Typography
      component="h6"
      className={cn(classes.processStepTag, {
        [classes.processStepActive]: isFirstStep,
        [classes.processStepCondition]: isConditionStep,
        [classes.processStepParallelStepsGroup]: isParallelStepsGroup,
        [classes.processStepIntegrated]: isIntegratedStep,
        [classes.processStepSizeSmall]: size === 'small',
      })}>
      {processStepTagTitle}
    </Typography>
  );
};
