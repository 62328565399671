import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ spacing, palette }) => ({
  popover: {
    position: 'absolute',
    top: '50vh',
    left: '50vw',
    zIndex: 9999
  },
  label: {
    fontWeight: 500,
    color: "#6D6E85",
    fontSize: 14,
    marginBottom: 4,
    width: "fit-content",

    "&::after": {
      display: 'inline-block',
      content: '"*"',
      color: palette.error[600],
      fontSize: 14,
      position: 'relative',
      right: -2,
      top: -2,
    }
  },
  commentInput: {
    marginTop: spacing(2),

    '& label': {
      fontWeight: 500,
      color: "#6D6E85",
      fontSize: 14,
      position: 'relative',
      display: 'block',
      width: 'fit-content',
    },

    '& div': {
      marginTop: 0,
      borderRadius: 12,
    },
  },
  commentInputRequired: {
    '& label': {
      '&::after': {
        display: 'inline-block',
        content: '"*"',
        color: palette.error[600],
        fontSize: 14,
        position: 'relative',
        right: -2,
        top: -2,
      },
    },
  },
  dialogButton: {
    height: 40,
  },
  redButton: {
    backgroundColor: palette.error[400],
    borderColor: palette.error[400],
  },
  title: {
    color: '#262842',
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: 500,
  },
  header:{
    // border: "1px solid rgba(38, 40, 66, 0.08)"
  },
  footer: {
    background: "#F7F8FC",
    borderTop: 0,
  },
  select: {
    width: "100%",
    borderRadius: 10,
  },
  menuItem: {
    display: "block",
    color: "#8B8C9E",
    fontSize: 12,

    "& > span": {
      display: "block",
      color: "#262842",
      fontWeight: 400,
      fontSize: 14,
      maxWidth: '100%',
      overflow: "hidden",
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    }
  },
  btn: {
    borderRadius: 10,
    padding: '11px 16px',
    marginLeft: '12px !important',
    height: 40,

    "&:disabled": {
      background: "#F0F0F7",
      color: "#A9A9B8 !important",
      borderColor: "#F0F0F7"
    }
  },
  btnSecondary: {
    border: "1px solid rgba(38, 40, 66, 0.12)",
    color: '#262842 !important',
  },
  reworkStepLabelTooltip: {
    maxWidth: 240,
    borderRadius: 8
  },
  infoMessage: {
    color: '#8B8C9E',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    marginBottom: 32,
    marginTop: 4,
  },
}));

export default useStyles;
