import { useSelector } from 'react-redux';

import type { RootState } from 'store';
import {CompanyRoleInfo} from "types";

export const useSettingsACLUserPermission = () =>
  useSelector((state: RootState) => state.settings.ACLUserPermissions);

export const useSettingsTemplateProcessesPermissions = () =>
  useSelector((state: RootState) => state.settings.templateProcessesAccesses);

export const useSettingsACLUserProcesses = () =>
  useSelector((state: RootState) => state.settings.ACLUserProcesses);

export const useSettingsCompanyRoles = () =>
  useSelector((state: RootState) => state.settings.companyRoles);

export const useSettingsCompanyRoleNames = () =>
  useSelector((state: RootState) =>
    state.settings.companyRoles.data.map(({ roleName }: CompanyRoleInfo): string => roleName));

export const useSettingsCompanyUsers = () =>
  useSelector((state: RootState) => state.settings.companyUsers);

export const useSettingsAllPermission = () =>
  useSelector((state: RootState) => state.settings.usersAllPermission);

export const useSettingsCompanyProcesses = () =>
  useSelector((state: RootState) => state.settings.companyProcesses);

export const useSettingsCompanyTemplateProcesses = () =>
  useSelector((state: RootState) => state.settings.companyTemplateProcesses);

export const useSettingsDefaultCompanyProcesses = () =>
  useSelector((state: RootState) => state.settings.defaultCompanyProcesses);

export const useSettingsCheckUserSettingsAccess = () =>
  useSelector((state: RootState) => state.settings.isUserHasAccessToSettings);

export const useSettingsGetChildrenCompanies = () =>
  useSelector((state: RootState) => state.settings.childrenCompanies);
