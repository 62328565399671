import { ResponseGetTemplates, TemplatesRequestFilterValues } from "pages/Templates/Templates.types";
import {baseV1ApiClient} from "../clients";
import {commonExceptionHandler} from "../handlers";
import {TemplateProcess} from "pages/TemplateProcesses/TemplateProcesses.types";
import { getProfileCompanyDataFromLocalStorage } from 'utils/user';
import { TemplatesTableRow } from "pages/Templates/components/TemplatesTable/TemplatesTable.types";

const TIMEOUT = 2 * 60 * 1000
const EXTENDED_TIMEOUT = 5 * 60 * 1000

export const saveTemplateProcessRequest = async (payload: TemplateProcess): Promise<TemplateProcess> => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'PUT',
      url: '/dms-bpm-process-template/settings/process/autoSave',
      data: payload
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
}

export const makeTemplateProcessRequest = async (payload: TemplateProcess): Promise<string> => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'POST',
      url: '/dms-bpm-process-template/settings/process',
      data: payload,
      timeout: TIMEOUT
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const deleteTemplateProcessRequest = async (processSysId: string): Promise<string> => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'DELETE',
      url: '/dms-bpm-process-template/settings/processV2',
      params: {
        processSysId
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getSettingsProcessCompanyRequest = async (params: any): Promise<{content: Partial<TemplateProcess>[], totalPages: number, totalElements: number}> => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'POST',
      url: `/dms-bpm-process-template/settings/process/company/v2/pageAll`,
      data: params
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const activateTemplateProcessRequest = async (processSysId : string): Promise<string> => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'PUT',
      url: '/dms-bpm-process-template/settings/process/activationV2',
      params: {
        processSysId
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
}

export const deactivateTemplateProcessRequest = async (processSysId : string): Promise<string> => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'PUT',
      url: '/dms-bpm-process-template/settings/process/deactivationV2',
      params: {
        processSysId
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
}

export const getAllCompanyTemplateProcesses = async (config?) => {
  try {
    const { id: companyId } = getProfileCompanyDataFromLocalStorage();

    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/dms-bpm-process-template/process-definition/templates/company',
      headers: {
        companyId,
      },
      cancelToken: config?.cancelToken,
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getAvailableTemplateProcesses = async (config?) => {
  try {
    const { id: companyId } = getProfileCompanyDataFromLocalStorage();

    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/dms-bpm-process-template/process-definition/templates/access',
      headers: {
        companyId,
      },
      cancelToken: config?.cancelToken,
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};


export const saveProcessAutostartSettings = async (processName: string, data: {[key: string]: string | number | boolean}) => {
  try {
    const { id: companyId } = getProfileCompanyDataFromLocalStorage();

    const response = await baseV1ApiClient.request({
      method: 'POST',
      url: '/dms-bpm-process-template/settings/process/autoStart/save',
      params: {
        companyId,
        processName
      },
      data
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const activateTemplateProcessAutostart = async (processName: string) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'PUT',
      url: '/dms-bpm-process-template/settings/process/autostart/activation',
      params: {
        processName
      },
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const deactivateTemplateProcessAutostart = async (processName: string) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'PUT',
      url: '/dms-bpm-process-template/settings/process/autostart/deactivation',
      params: {
        processName
      },
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getTemplateProcessDraftBySysName = async (processSysId: string) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/dms-bpm-process-template/settings/process/getById',
      params: {
        processSysId
      },
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getTemplateProcessBySysName = async (processSysId: string, signal?: AbortSignal) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/dms-bpm-process-template/settings/process/getPublicOrDraftById',
      params: {
        processSysId
      },
      signal
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const deleteAutoSaveTemplate = async (processSysId: string) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'DELETE',
      url: '/dms-bpm-process-template/settings/process/autoSave',
      params: {
        processSysId
      },
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getPublishedProcessesCount = async () => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/dms-bpm-process-template/settings/process/published/count',
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getWorkspacesListForProcessCopy = async () => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/dms-bpm-process-template/settings/process/workspaces',
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const duplicateProcess = async (processTemplateDTO, recipientsCompanyIds) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'POST',
      url: '/dms-bpm-process-template/settings/process/copy',
      data: {
        deactivateOriginalTemplate: false,
        processTemplateDTO,
        recipientsCompanyIds
      },
      timeout: EXTENDED_TIMEOUT,
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getTemplates = async (filter: TemplatesRequestFilterValues, signal: AbortSignal): Promise<ResponseGetTemplates> => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'post',
      url: '/dms-bpm-process-template/template/byName',
      signal,
      data: filter
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const createTemplateFromProcess = async (process): Promise<TemplatesTableRow> => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'post',
      url: '/dms-bpm-process-template/template',
      data: process
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const createProcessFromTemplate = async (template): Promise<any> => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'post',
      url: '/dms-bpm-process-template/template/process',
      data: template,
      timeout: TIMEOUT
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};


export const getTemplateById = async (processSysId: string): Promise<any> => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'get',
      url: '/dms-bpm-process-template/template',
      params: {
        processSysId
      },
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
}

export const getAllTemplates = async (): Promise<any> => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'get',
      url: '/dms-bpm-process-template/template/all',

    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
}

export const deleteTemplateBySysId = async (processSysId: string): Promise<any> => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'delete',
      url: '/dms-bpm-process-template/template/?',
      params: {
        processSysId
      },
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
}

export const archieveTemplateBySysId = async (processSysId: string): Promise<any> => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'PUT',
      url: '/dms-bpm-process-template/template/unshare',
      params: {
        processSysId
      },
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
}

export const getTemplateBySysName = async (processSysId: string, signal?: AbortSignal) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/dms-bpm-process-template/template/getPublicOrDraftById',
      params: {
        processSysId
      },
      signal
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};


export const getTemplateDraftBySysName = async (processSysId: string, signal?: AbortSignal) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/dms-bpm-process-template/template/getById',
      params: {
        processSysId
      },
      signal
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};


export const saveTemplateRequest = async (payload: TemplateProcess): Promise<TemplateProcess> => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'PUT',
      url: '/dms-bpm-process-template/template/autoSave',
      data: payload
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
}

export const deleteAutoSaveTemplateRequest = async (processSysId: string): Promise<TemplateProcess> => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'DELETE',
      url: '/dms-bpm-process-template/template/autoSave',
      params: {
        processSysId
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
}

export const makeTemplateRequest = async (payload: TemplateProcess): Promise<TemplateProcess> => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'PUT',
      url: '/dms-bpm-process-template/template',
      data: payload,
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
}

export const getDocumentTemplatesList = async (searchValue: string, department: string, type: string) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/docflow/templates',
      params: {
        departmentIds: department ? department : null,
        typeIds: type ? type : null,
        query: searchValue,
        statuses: 'PUBLISHED',
        destinationTypes: 'PROCESSES',
        pageable: {
          "page": 0,
          "size": 1000,
        }
      }
    });

    return response?.data?.content || [];
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};


export const getDocumentTemplateById = async (id: number) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: `/docflow/templates/${id}`,
    });
    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getDocflowDocumentById = async (id: number) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: `/docflow/bpm/documents/${id}`,
    });
    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};
