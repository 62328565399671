import React from 'react';

import { HistoryTabStep } from './HistoryTabStep';

import { StepType, HistoryNode } from './history.types';
import { HistoryTabConditionItem } from './HistoryTabConditionItem';
import { HistoryTabParallelBranches } from './HistoryTabParallelBranches';
import { HistoryTabParallelAssigneesStep } from './HistoryTabParallelAssigneesStep';

type Props = {
  node: HistoryNode;
  nodeIndex: number;
  nodeCount: number;
  companyId: string;
};

export const HistoryTabNode = ({ node, nodeIndex, nodeCount, companyId }: Props) => {
  if (node?.type === 'step') {
    return <HistoryTabStep
      step={node.data as StepType}
      isFirstStep={nodeIndex === nodeCount - 1}
      isLastStep={nodeIndex === 0}
      companyId={companyId}
    />;
  }

  if (node?.type === 'condition') {
    return <HistoryTabConditionItem
      condition={node.data}
      nodeIndex={nodeIndex}
      nodeCount={nodeCount}
      companyId={companyId}
    />;
  }

  if (node?.type === 'parallelBranches') {
    return <HistoryTabParallelBranches
      parallelBranches={node.data}
      nodeIndex={nodeIndex}
      nodeCount={nodeCount}
      companyId={companyId}
    />;
  }

  if (node?.type === 'parallelAssigneesStep') {
    return <HistoryTabParallelAssigneesStep
      step={node.data}
      isFirstStep={nodeIndex === nodeCount - 1}
      isLastStep={nodeIndex === 0}
      companyId={companyId}
    />;
  }

  return null;
};
