import React, { memo, useMemo, useCallback } from 'react';
import { Box, ButtonBase, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useProcessesListState } from 'store/requests';

import deleteIcon from 'assets/images/icons/delete-icon.svg';
import useStyles from './useStyles';

const DELETE_ICON_CLASS = 'delete-icon';

type Props = {
  template: any;
  onDelete: (id: string) => void;
  handleCreate: (tpl: any) => void;
};

export const TemplateItem = memo(({ template, onDelete, handleCreate }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { data } = useProcessesListState();

  const templateProcess = useMemo(() =>
    data ? data.find((process) => process.key === template.processDefinitionKey) : null,
    [data, template.processDefinitionKey]);

  const templateIcon = useMemo(() =>
    templateProcess ? templateProcess.processIconPath : '',
    [templateProcess]);

  const handleClick = useCallback(event => {
      if (!event.target.classList.contains(DELETE_ICON_CLASS)) {
        // click target is NOT a delete button
        handleCreate(template);
      }
    },
    [template, handleCreate]
  );

  return (
    <Box component="figure" className={classes.card} onClick={handleClick}>
      <Box component="header" className={classes.header}>
        <Box className={classes.img}>
          <img src={templateIcon} alt="" />
        </Box>

        <Typography variant="body1">
          {t(`constructor-${template.processSysName}.name`, {defaultValue: template.processDefinitionName})}
        </Typography>

        <ButtonBase
          className={classes.deleteIcon + ' ' + DELETE_ICON_CLASS}
          onClick={() => onDelete(template?.id)}
        >
          <img
            src={deleteIcon}
            className={DELETE_ICON_CLASS}
            alt="delete template"
          />
        </ButtonBase>
      </Box>

      <Box component="figcaption" className={classes.caption}>
        <Typography variant="body1">{template.title}</Typography>
      </Box>
    </Box>
  );
});
