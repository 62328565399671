import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Tooltip,
} from '@mui/material';

import { FormField } from 'pages/Task/TaskForm/FormFields';

import { getAttributes, getDefaultValues, getStateAttributes } from './utils';
import { TaskParametersType } from 'types';

import openIcon from 'assets/images/icons/bottom-arrow.svg';
import closedIcon from 'assets/images/icons/right-arrow.svg';
import useStyles from './useStyles';

type Props = {
  instance: any;
  instancesList: any;
  setInstancesList: (value: any[]) => void;
  labelAttribute: string;
  displayState?: string;
  formFieldName?: string;
  showOnlyAttachments?: boolean;
  designVariant?: string;
  expanded: boolean;
  onChange: (e: any, isExpanded: boolean) => void;
  headerComponents: TaskParametersType[];
  handleAction: (params: {
    action: string;
    tplName?: string;
    componentParams?: { [key: string]: any };
    instanceFormFieldName?: string;
    instance?: any;
  }) => void;
  setMainFormValue: (field: string, value: any) => void;
};

export const EntityDisplay = ({
  instance,
  instancesList,
  setInstancesList,
  labelAttribute,
  displayState = 'SuppliersTab',
  formFieldName = '',
  showOnlyAttachments = false,
  designVariant = 'accordion',
  expanded,
  onChange,
  headerComponents,
  handleAction,
  setMainFormValue,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [label, setLabel] = useState('');
  const [attachmentsExpanded, setAttachmentsExpanded] = useState(false);
  const [displayedAttributes, setDisplayedAttributes] = useState([]);
  const [entitySysName, setEntitySysName] = useState('');

  const isAccordionVariant = useMemo(() => designVariant === 'accordion', [
    designVariant,
  ]);
  const isListVariant = useMemo(() => designVariant === 'list', [
    designVariant,
  ]);
  const isListWithGroupedAttachmentsVariant = useMemo(
    () => designVariant === 'list-with-grouped-attachments',
    [designVariant]
  );

  const attachmentsFields = useMemo(
    () =>
      displayedAttributes.filter((attribute) => attribute.component === 'file'),
    [displayedAttributes]
  );

  const formMethods = useForm({ defaultValues: getDefaultValues(instance) });

  // useEffect(() => {
  //   if (instance) {
  //     const instanceKeys = Object.keys(instance);
  //
  //     const formValues = formMethods.getValues();
  //     const formFields = Object.keys(formValues);
  //
  //     instanceKeys.forEach((instanceKey) => {
  //       if (!instanceKey.includes('Attributes')) {
  //         return;
  //       }
  //
  //       const group = instance[instanceKey];
  //       group.forEach(({ name, value }) => {
  //         if (formFields.includes(name)) {
  //           const currentValue = isArray(value) ? "" : value;
  //           formMethods.setValue(name, currentValue);
  //         }
  //       });
  //     });
  //   }
  // }, [instance]);

  useEffect(() => {
    if (instance) {
      setEntitySysName(instance.name.replace('Экземпляр сущности', '').trim());

      const attributes = getAttributes(instance);

      const entityDisplayLabel =
        attributes.find(({ name }) => name === labelAttribute)?.value || '';
      setLabel(entityDisplayLabel);

      const displayAttributes = getStateAttributes(instance, displayState);
      setDisplayedAttributes(displayAttributes);
    }
  }, [instance, setDisplayedAttributes, setEntitySysName, setLabel, displayState]);

  const icon = useMemo(() => (expanded ? openIcon : closedIcon), [expanded]);
  const attachmentsIcon = useMemo(
    () => (attachmentsExpanded ? openIcon : closedIcon),
    [attachmentsExpanded]
  );

  const handleHeaderButtonAction = useCallback(
    (params) => {
      const extendedParams = {
        ...params,
        instanceFormFieldName: formFieldName,
        instance,
      };
      handleAction(extendedParams);
    },
    [instance, formFieldName]
  );

  const onAttachmentsChange = useCallback(
    () => setAttachmentsExpanded((v) => !v),
    [setAttachmentsExpanded]
  );

  if (!instance) {
    return (
      <>
        {isAccordionVariant ? (
          <Accordion className={classes.root} expanded={false}>
            <Tooltip title={label} placement="top" arrow>
              <AccordionSummary className={classes.header}>
                <div className={classes.headerInfo}>
                  <img src={icon} className={classes.icon} />
                  <Typography className={classes.title} color="error">
                    Malformed entity
                  </Typography>
                </div>
                <div className={classes.headerControls}></div>
              </AccordionSummary>
            </Tooltip>
          </Accordion>
        ) : (
          <div className={classes.listVariantWrapper}>
            <Typography className={classes.listVariantTitleMalformed}>
              Malformed entity
            </Typography>
          </div>
        )}
      </>
    );
  }

  return (
    <>
      {isAccordionVariant ? (
        <Accordion
          className={classes.root}
          expanded={expanded}
          onChange={onChange}
        >
          <Tooltip title={label} placement="top" arrow>
            <div className={classes.headerWrapper}>
              <AccordionSummary className={classes.header}>
                <div className={classes.headerInfo}>
                  <img src={icon} className={classes.icon} />
                  <Typography className={classes.title}>{label}</Typography>
                </div>
              </AccordionSummary>
              <div className={classes.headerControls}>
                {headerComponents.map((attribute) => (
                  <FormField
                    key={attribute.attributeId}
                    attribute={attribute}
                    handleAction={handleHeaderButtonAction}
                    setMainFormValue={setMainFormValue}
                    entitySysName={entitySysName}
                    stepSysName={displayState}
                  />
                ))}
              </div>
            </div>
          </Tooltip>
          <AccordionDetails className={classes.body}>
            <FormProvider {...formMethods}>
              {displayedAttributes.map((attribute) => (
                <FormField
                  attribute={attribute}
                  key={attribute.id}
                  isTaskDetailsVariant={true}
                  isEntityCardVariant={true}
                  setMainFormValue={setMainFormValue}
                  entitySysName={entitySysName}
                  stepSysName={displayState}
                />
              ))}
            </FormProvider>
          </AccordionDetails>
        </Accordion>
      ) : (
        <div
          className={cn({
            [classes.listVariantWrapper]: isListVariant,
            [classes.listWithGroupedAttachmentsWrapper]: isListWithGroupedAttachmentsVariant,
          })}
        >
          <div
            className={cn({
              [classes.listVariantHeader]:
                !showOnlyAttachments && !isListWithGroupedAttachmentsVariant,
            })}
          >
            <Typography
              className={cn({
                [classes.listVariantTitle]: isListVariant,
                [classes.listWithGroupedAttachmentsTitle]: isListWithGroupedAttachmentsVariant,
                [classes.listVariantTitleOnlyAttachments]: showOnlyAttachments,
              })}
            >
              {label}
            </Typography>
            {!isListWithGroupedAttachmentsVariant && (
              <div
                className={cn([
                  classes.headerControls,
                  classes.headerControlsListVariant,
                ])}
              >
                {!showOnlyAttachments &&
                  headerComponents.map((attribute) => (
                    <FormField
                      key={attribute.attributeId}
                      attribute={attribute}
                      handleAction={handleHeaderButtonAction}
                      setMainFormValue={setMainFormValue}
                      entitySysName={entitySysName}
                      stepSysName={displayState}
                    />
                  ))}
              </div>
            )}
          </div>
          <div
            className={cn({
              [classes.listVariantBody]: isListVariant && !showOnlyAttachments,
              [classes.listWithGroupedAttachmentsVariantBody]:
                isListWithGroupedAttachmentsVariant && !showOnlyAttachments,
            })}
          >
            <FormProvider {...formMethods}>
              <div
                className={cn({
                  [classes.listWithGroupedAttachmentsVariantForm]:
                    isListWithGroupedAttachmentsVariant && !showOnlyAttachments,
                })}
              >
                <>
                  <div
                    className={cn({
                      [classes.listWithGroupedAttachmentsVariantFormContent]:
                        isListWithGroupedAttachmentsVariant &&
                        !showOnlyAttachments,
                    })}
                  >
                    {displayedAttributes.map((attribute) => {
                      if (
                        !(
                          (showOnlyAttachments &&
                            attribute.component !== 'file') ||
                          (isListWithGroupedAttachmentsVariant &&
                            attribute.component === 'file')
                        )
                      ) {
                        return (
                          <div
                            className={cn({
                              [classes.hiddenField]:
                                attribute.component === 'hidden-field',
                            })}
                          >
                            <FormField
                              attribute={attribute}
                              key={attribute.id}
                              isTaskDetailsVariant={true}
                              isEntityCardVariant={true}
                              setMainFormValue={setMainFormValue}
                              entitySysName={entitySysName}
                              stepSysName={displayState}
                            />
                          </div>
                        );
                      }
                    })}
                  </div>

                  {isListWithGroupedAttachmentsVariant && (
                    <div
                      className={cn([
                        classes.headerControlsListWithGroupedAttachmentsVariant,
                      ])}
                    >
                      {headerComponents.map((attribute) => (
                        <FormField
                          key={attribute.attributeId}
                          attribute={attribute}
                          handleAction={handleHeaderButtonAction}
                          instance={instance}
                          instancesList={instancesList}
                          setInstancesList={setInstancesList}
                          setMainFormValue={setMainFormValue}
                          entitySysName={entitySysName}
                          stepSysName={displayState}
                        />
                      ))}
                    </div>
                  )}
                </>
              </div>

              {attachmentsFields.length > 0 &&
                isListWithGroupedAttachmentsVariant &&
                !showOnlyAttachments && (
                  <Accordion
                    className={classes.attachmentsRoot}
                    expanded={attachmentsExpanded}
                    onChange={onAttachmentsChange}
                  >
                    <AccordionSummary className={classes.attachmentsHeader}>
                      <img
                        src={attachmentsIcon}
                        className={classes.attachmentsIcon}
                      />
                      <Typography className={classes.attachmentsTitle}>
                        {t('form_components.attachment.title')}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.attachmentsBody}>
                      {attachmentsFields.map((attribute) => (
                        <FormField
                          attribute={attribute}
                          key={attribute.id}
                          isTaskDetailsVariant={true}
                          isEntityCardVariant={true}
                          setMainFormValue={setMainFormValue}
                          entitySysName={entitySysName}
                          stepSysName={displayState}
                        />
                      ))}
                    </AccordionDetails>
                  </Accordion>
                )}
            </FormProvider>
          </div>
        </div>
      )}
    </>
  );
};
export default EntityDisplay;
