import React, { memo, ReactElement, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { ContentLayout } from 'layouts';
import { ContentActions } from 'components/Content';
import { ApprovalsRouter } from 'router/ApprovalsRouter';
import { NewButtonRequestHeader } from 'components';

import { routesList } from 'router';
import { PageType, SubmenuItem } from 'types';
import { SearchValueContext } from 'pages/Requests';

const ApprovalsPage = memo(
  (): ReactElement => {
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const {
      approvals: { path: requestPath, submenu: routesMenuList },
    } = routesList(t);
    const [searchValue, setSearchValue] = useState("");

    const { isMassSelectAvailable, isFiltersAvailable } = useMemo((): {
      isMassSelectAvailable: boolean;
      isFiltersAvailable: boolean;
    } => {
      const currentRoute = routesMenuList.find(
        ({ path }: SubmenuItem): boolean =>
          pathname.includes(requestPath + path)
      );

      const {
        isMassSelectAvailable = false,
        isFiltersAvailable = false,
      } = currentRoute?.props || {};

      return {
        isMassSelectAvailable,
        isFiltersAvailable,
      };
    }, [pathname]);

    return (
      <ContentLayout
        headerChild={
          <Box display="flex" alignItems="center">
            <NewButtonRequestHeader />
          </Box>
        }>
        <SearchValueContext.Provider value={{ value: searchValue, setValue: setSearchValue }}>
          <ContentActions
            menuList={routesMenuList}
            basePageUrl={requestPath}
            type={PageType.APPROVALS}
            isFiltersAvailable={isFiltersAvailable}
            isMassSelectAvailable={isMassSelectAvailable} />
          <ApprovalsRouter />
        </SearchValueContext.Provider>
      </ContentLayout>
    );
  }
);

export default ApprovalsPage;
