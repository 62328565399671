import React, { ReactElement } from 'react';
import {
  Box, Tooltip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import useCopyProcessDialogStyles from './CopyProcessDialog.useStyles';
import { WorkspaceInfo } from '../../TemplateProcesses.types';
import Avatar from '@mui/material/Avatar';
import { UserAvatar } from '../../../../components';


interface WorkspaceSelectProps {
  workspace: WorkspaceInfo;
}

export const WorkspaceSelectItem = ({
  workspace,
}: WorkspaceSelectProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useCopyProcessDialogStyles();

  return <Box className={classes.workspaceItem}>
    <Box className={classes.logoWrapper}>
      <UserAvatar
        user={{
          id: '',
          avatar: workspace.organization.logo,
          email: '',
          firstName: '',
          fullName: workspace.organization.shortName || workspace.organization.name,
          fullNameRu: '',
          lastName: '',
          phoneNumber: '',
          role: [''],
          logName: workspace.organization.shortName || workspace.organization.name,
          jobTitle: '',
        }}
        avatarSize={40}
        roundedSquare
      />
    </Box>
    <Box className={classes.infoWrapper}>
      <Tooltip
        title={workspace.organization.shortName || workspace.organization.name}
        arrow
        enterDelay={300}
        placement="bottom"
        classes={{
          tooltip: classes.workspaceNameTooltip,
        }}
      >
      <span className={classes.workspaceName}>
        {workspace.organization.shortName || workspace.organization.name}
      </span>
      </Tooltip>
      <span className={classes.workspaceUsers}>{t('customProcesses.copyProcessDialog.workspaceSelect.usersCount', {
        defaultValue: `${workspace.numberOfEmployees} workers`,
        count: workspace.numberOfEmployees,
      })}</span>
    </Box>
  </Box>;
};
