import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import {
  Button,
  TextField,
  DialogContent,
  Typography,
  DialogActions,
  Dialog,
  Box,
  ButtonBase,
  Select,
  MenuItem,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';

import useStyles from './useStyles';
import { ADD_COMMENT_FROM_POPOVER_ACTION } from '../constants';

import { showDarkOverlay, useTaskState } from 'store/requests';
import { getHistoryStepsForRework } from 'api/requests';

import { ReworkStepsSelectItem } from './ReworkStepsSelectItem';

import CloseIcon from 'assets/images/icons/close-icon-new.svg';
import ChevronDown from 'assets/images/icons/chevron_down_regular.svg';

const Icon = () => {
  return <img style={{ position: 'absolute', right: 10, top: 9, pointerEvents: 'none' }} src={ChevronDown} alt=""/>;
};

const CLOSE_ACTION = 'Close action comment creation popover';

export type ReworkStepsListItem = {
  actionSysName: string;
  approveStage: string;
  assignee: string;
  assigneeVariableName: string;
  cancelledStep: boolean;
  completed: boolean;
  parallel: boolean;
  parallelBranch: boolean
  parallelBranchName: string | null;
  parallelJoin: boolean;
  parallelStepsRange: string | null;
  possibleSteps: number[];
  stepperLabel: string;
  stepperOrder: number;
  stepsRange: string;
  displayedStepOrder: string;
  taskCompleted: boolean;
  taskEndDate: string;
  taskId: string;
  taskName: string;
  taskStartDate: string;
  label?: string;
}

type Props = {
  open: boolean;
  commentedAction?: string;
  isCommentRequired?: boolean;
  actionOptions?: Record<string, unknown>;
  onClick: ({ action, componentParams }: { action: string, componentParams: Record<string, unknown> }) => void;
  onCommentChange: (msg: string) => void;
  submitAction?: string;
  submitButtonText?: string;
  setAssigneeVariableName?: (name: string) => void;
  onClose?: () => void;
  callClickHandlerOnClose?: boolean;
  isTemplate: boolean;
  showInfoMessage?: boolean;
  requestsCount?: number;
};

export const ActionCommentDialog = ({
  open = false,
  commentedAction = '',
  isCommentRequired = true,
  actionOptions = {},
  showInfoMessage = false,
  requestsCount = 0,
  onClick,
  onClose = () => {},
  callClickHandlerOnClose = true,
  onCommentChange,
  submitAction = ADD_COMMENT_FROM_POPOVER_ACTION,
  submitButtonText = '',
  setAssigneeVariableName,
  isTemplate,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { data: bpmTask } = useTaskState();
  const [steps, setSteps] = useState<ReworkStepsListItem[]>([]);
  const [loading, setLoading] = useState(false);

  const [comment, setComment] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');
  const [infoMessage, setInfoMessage] = useState('');
  const [submitButtonLabel, setSubmitButtonLabel] = useState('');
  const [commentInputLabel, setCommentInputLabel] = useState('');
  const [commentInputPlaceholder, setCommentInputPlaceholder] = useState('');
  const [stepLabel, setStepLabel] = useState('');

  const initSteps = useCallback(async () => {
    if (bpmTask && bpmTask?.isTemplateProcessTask) {
      try {
        setLoading(() => true);
        const stepsList = await getHistoryStepsForRework(bpmTask?.processInstanceId, bpmTask?.currentAction?.stepperOrder);
        setSteps(stepsList);
      } catch (error) {
        console.log('error loading rework steps');
      } finally {
        setLoading(() => false);
      }
    }
  }, [bpmTask?.processInstanceId, bpmTask?.currentAction]);

  useEffect(() => {
    initSteps();
  }, [initSteps]);

  const dialogDetailsMap = useMemo(
    () => ({
      Rework: {
        dialogTitle: t('buttons.button_rework'),
        commentInputLabel: t('comment_modal.rework.comment_label'),
        submitButtonLabel: t('comment_modal.rework.button_submit'),
        commentInputPlaceholder: t('comment_modal.rework.comment_placeholder'),
      },
      Reject: {
        dialogTitle: t('comment_modal.reject.button_submit'),
        commentInputLabel: t('comment_modal.reject.comment_label'),
        submitButtonLabel: t('comment_modal.reject.button_submit'),
        commentInputPlaceholder: t('comment_modal.reject.comment_placeholder'),
        infoMessageOne: t('comment_modal.reject.info_message_one', {selectedRequestsCount: requestsCount}),
        infoMessageMany: t('comment_modal.reject.info_message_many', {selectedRequestsCount: requestsCount}),
      },
      default: {
        dialogTitle: t('comment_modal.comment.title'),
        commentInputLabel: t('comment_modal.comment.comment_label'),
        commentInputPlaceholder: t('comment_modal.comment.comment_placeholder'),
        submitButtonLabel: t('comment_modal.comment.button_submit'),
        infoMessageOne: '',
        infoMessageMany: ''
      },
    }),
    [requestsCount],
  );

  useEffect(() => {
    dispatch(showDarkOverlay(open));
  }, [open]);

  useEffect(() => {
    setSubmitButtonLabel(
      submitButtonText || dialogDetailsMap[commentedAction]?.submitButtonLabel ||
      dialogDetailsMap.default.submitButtonLabel,
    );
    setCommentInputLabel(
      dialogDetailsMap[commentedAction]?.commentInputLabel ||
      dialogDetailsMap.default.commentInputLabel,
    );
    setCommentInputPlaceholder(
      dialogDetailsMap[commentedAction]?.commentInputPlaceholder ||
      dialogDetailsMap.default.commentInputPlaceholder,
    );
    setDialogTitle(
      dialogDetailsMap[commentedAction]?.dialogTitle ||
      dialogDetailsMap.default.dialogTitle,
    );
    setInfoMessage(
      (
        requestsCount === 1
       ? dialogDetailsMap[commentedAction]?.infoMessageOne
       : dialogDetailsMap[commentedAction]?.infoMessageMany
      ) || dialogDetailsMap.default.dialogTitle,
    );
  }, [commentedAction, dialogDetailsMap, requestsCount, submitButtonText]);

  const delayedOnCommentChange = useCallback(
    debounce(message => onCommentChange(message), 150),
    []);

  const handleCommentChange = (message: string) => {
    setComment(message);
    delayedOnCommentChange(message);
  };

  const handleClose = () => {
    setComment('');
    dispatch(showDarkOverlay(false));
    if (callClickHandlerOnClose) {
      onClick({ action: CLOSE_ACTION, componentParams: actionOptions });
    }
    if (onClose) {
      onClose();
    }
  };

  const handleCancel = () => {
    if (callClickHandlerOnClose) {
      onClick({ action: CLOSE_ACTION, componentParams: actionOptions });
    }
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    setComment('');
  }, [open])

  const isSubmitButtonDisabled = useMemo(
    () =>
      !(!isCommentRequired || (isCommentRequired && !!comment.trim().length)) || (isTemplate && actionOptions?.commentedAction === 'Rework' && !stepLabel),
    [isCommentRequired, comment, actionOptions?.commentedAction, stepLabel],
  );

  const stepsWithLabels = useMemo(() =>
      steps.map((step) =>
        ({
          ...step,
          label: step?.parallelBranch
                 ? `${t('customProcesses.creationPage.processStepTag.step')} ${step?.stepsRange}: ${t(`constructor-${bpmTask.processSysName}.parallelBranches.${step?.stepperOrder}.name`, { defaultValue: t(`constructor-${bpmTask.processSysName}.parallelBranches.${step?.stepperOrder + 1}.name`, { defaultValue: step?.parallelBranchName }) })}`
                 : `${t('customProcesses.creationPage.processStepTag.step')} ${step?.displayedStepOrder}: ${t(`constructor-${bpmTask.processSysName}.actions.${step.actionSysName}.name`, { defaultValue: step.taskName })}`,
        }))
    , [steps]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      hideBackdrop={true}
      PaperProps={{
        style: {
          borderRadius: 12,
        },
      }}
    >
      <Box
        component="header"
        py={4}
        px={6}
        display="flex"
        justifyContent="space-between"
        className={classes.header}
      >
        <Typography variant="h4" className={classes.title}>{dialogTitle}</Typography>
        <ButtonBase onClick={handleClose}>
          <img src={CloseIcon} alt="Close"/>
        </ButtonBase>
      </Box>

      <DialogContent>
        {actionOptions.commentedAction === 'Rework' && isTemplate &&
          <Box mt={3} mb={5}>
            <Typography className={classes.label}>{t('customProcesses.creationPage.processStepTag.step')}</Typography>

            <Select
              IconComponent={Icon}
              onChange={({ target: { value } }) => {
                setAssigneeVariableName(value as string);
              }}
              className={classes.select}
              disabled={loading}
              MenuProps={{
                PaperProps: {
                  style: {
                    borderRadius: 10,
                  },
                },
                MenuListProps: { 'aria-labelledby': 'basic-button' },
                style: {
                  marginTop: 10,
                  marginLeft: -8,
                  border: '1px solid rgba(38, 40, 66, 0.08)',
                  boxShadow: '0px 8px 16px rgba(38, 40, 66, 0.04)',
                },
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}
              renderValue={() => stepLabel}
            >
              {stepsWithLabels.map(step => {
                const { assigneeVariableName, label } = step as any;

                return <MenuItem
                  onClick={() => setStepLabel(label)}
                  value={assigneeVariableName}
                  className={classes.menuItem}
                >
                  <ReworkStepsSelectItem step={step}/>
                </MenuItem>;
              })}
            </Select>
          </Box>
        }

        {(showInfoMessage && commentedAction === 'Reject') &&
          <p className={classes.infoMessage}>
            {infoMessage}
          </p>}

        <Box mt={3} mb={5}>
          <TextField
            multiline
            rows={4}
            maxRows={4}
            label={commentInputLabel}
            placeholder={commentInputPlaceholder}
            className={cn(classes.commentInput, {
              [classes.commentInputRequired]: isCommentRequired,
            })}
            fullWidth
            onChange={(e) => handleCommentChange(e.target.value)}
          />
        </Box>
      </DialogContent>

      <DialogActions className={classes.footer}>
        <Button
          color="secondary"
          onClick={handleCancel}
          className={cn(classes.btn, classes.btnSecondary)}
        >
          {t('comment_modal.button_cancel')}
        </Button>

        <Button
          color="primary"
          onClick={() =>
            onClick({ action: submitAction, componentParams: actionOptions })
          }
          disabled={isSubmitButtonDisabled}
          className={cn(classes.btn)}
        >
          {submitButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
