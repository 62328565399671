import React, { ReactElement, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import useDocumentsListStyles from './DocumentsList.useStyles';
import { DocumentsCard } from '../DocumentCard/DocumentCard';

import NoDocumentsSearchResultIcon from 'assets/images/icons/document_search_regular.svg';
import NoDocumentsIcon from 'assets/images/icons/document_regular.svg';
import { Box } from '@mui/material';

interface DocumentsListProps {
  groupedDocumentsList: any;
  selectedDocument: string | number;
  modalVariant?: boolean;
  isFiltered?: boolean;
  searchText?: string;
  onDocumentClick: (documentId: number) => void;
}

export const DocumentsList = ({
  groupedDocumentsList,
  selectedDocument,
  modalVariant = false,
  isFiltered = false,
  searchText,
  onDocumentClick,
}: DocumentsListProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useDocumentsListStyles({ isModalVariant: modalVariant });

  if (groupedDocumentsList.length === 0) {
    return <div className={classes.documentsList}>
      <div className={classes.emptyMessageWrapper}>
        <div className={classes.emptyMessageIconWrapper}>
          <img src={isFiltered ? NoDocumentsSearchResultIcon : NoDocumentsIcon} className={classes.emptyMessageIcon}/>
        </div>
        <span className={classes.emptyMessageTitle}>
          {isFiltered
           ? t('customProcesses.documentsList.noResults')
           : t('customProcesses.documentsList.noTemplatesYet')}
        </span>
        <span className={classes.emptyMessageText}>
          {isFiltered
           ? <>
             <Trans i18nKey="customProcesses.documentsList.noResultForSearchText" values={{ searchText: searchText }}>
               No results found for «<span>searchText</span>».
             </Trans>
             <br/>
             {t('customProcesses.documentsList.tryAgain')}
           </>
           : t('customProcesses.documentsList.noPublishedTemplatesYet')}
        </span>
      </div>
    </div>;
  }

  return (
    <Droppable droppableId="DOCUMENT_TEMPLATES" isDropDisabled={true}>
      {(provided, snapshot) =>
        <div className={classes.documentsList} ref={provided.innerRef}>
          {
            groupedDocumentsList?.map(department =>
              <div className={classes.departmentDocumentsWrapper} key={department}>
                <h4 className={classes.departmentName}>{department.departmentName}</h4>
                {
                  department.types.map(type =>
                    <div className={classes.typeDocumentsWrapper} key={type}>
                      <h5 className={classes.typeName}>{type.typeName}</h5>
                      {
                        type.documents.map((document, index) =>
                          <Draggable
                            key={document.id.toString()}
                            draggableId={document.id.toString()}
                            // isDragDisabled={modalVariant}
                            index={index}>
                            {(provided, snapshot) => (
                              <>
                                <React.Fragment>
                                  <Box
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    isDragging={snapshot.isDragging}
                                    className={classes.documentsListDraggableItem}
                                    style={
                                      provided.draggableProps
                                        .style
                                    }>
                                    <DocumentsCard
                                      key={document.id}
                                      name={document.title}
                                      description={document.description}
                                      author={document.author.userId}
                                      authorWorkspace={document.author.workspaceId}
                                      publicationDate={document.updatedAt}
                                      isSelected={document.id === selectedDocument || document.uniqueId === selectedDocument}
                                      isDraggable={!modalVariant}
                                      dragHandleProps={provided.dragHandleProps}
                                      onClick={() => onDocumentClick(document.id)}
                                    />
                                  </Box>
                                </React.Fragment>

                                {snapshot.isDragging &&
                                  <DocumentsCard
                                    key={document.id}
                                    name={document.title}
                                    description={document.description}
                                    author={document.author.userId}
                                    authorWorkspace={document.author.workspaceId}
                                    publicationDate={document.updatedAt}
                                    isSelected={document.id === selectedDocument || document.uniqueId === selectedDocument}
                                    isDraggable={!modalVariant}
                                    dragHandleProps={provided.dragHandleProps}
                                    onClick={() => onDocumentClick(document.id)}
                                  />
                                }
                              </>
                            )}
                          </Draggable>,
                        )}
                    </div>,
                  )}
              </div>,
            )}
        </div>
      }
    </Droppable>
  );
};
