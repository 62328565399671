import { makeStyles } from '@mui/styles';

export default makeStyles({
  accordionContainer: {
    borderBottom: '1px solid #2628421F',
    // margin: '10px 0',
    overflow: 'hidden',
    padding: '0 20px',
  },
  accordionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 0',
    cursor: 'pointer',
    userSelect: 'none',
  },
  accordionTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#2b2d42',
  },
  accordionArrow: {
    fontSize: '12px',
    color: '#6c757d',
    transition: 'transform 0.3s ease-in-out',
  },
  accordionArrowOpen: {
    transform: 'rotate(180deg)',
  },
  userDropdownIcon: {
    transition: '0.3s transform ease',

    '& > path': {
      fill: '#6D7C8B !important',
    },
  },
  accordionContent: {
    overflow: 'hidden',
    transition: 'height 0.3s ease-in-out',
  },
  accordionContentInner: {
    // padding: '10px 0',
    fontSize: '14px',
    color: '#495057',
  },
});
