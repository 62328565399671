import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ spacing, palette }) => ({
  FormTab: {
    // padding: spacing(4, 4, 10, 0),
    minHeight: '100%',
    position: 'relative',
  },
  group: {},
  table: {},
  formGroup: {},
  formGroupTitle: {
    marginTop: spacing(0),
    marginBottom: spacing(4),
    color: '#262842',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 500,
  },
  legalEntititesWrapper: {
    width: '100%',
    marginBottom: spacing(3),
    padding: spacing(2),
    overflowX: 'auto',
    '& div .MuiTableContainer-root': {
      width: 'auto',
    },
  },
  hiddenInTabField: {
    visibility: 'hidden',
    padding: '0 !important',
    margin: '0 !important',
    height: '0 !important',
    opacity: 0,
    float: 'left',
    fontSize: '0px !important',
    '& *, & *:before, &:after': {
      padding: '0 !important',
      margin: '0 !important',
      height: '0 !important',
      minHeight: '0 !important',
      maxHeight: '0 !important',
      width: '0 !important',
      opacity: 0,
      visibility: 'hidden',
      fontSize: '0px !important',
    },
  },
  emptyAttachmentsMessageWrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: '#6D6E85',

    '& h3': {
      marginTop: 16,
      marginBottom: 4,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '20px',
    },

    '& p': {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '18px',
    },
  },
  emptyAttachmentsMessageText: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 500,
    marginTop: 8,
  },
  labelStepDescription: {
    color: '#6D6E85',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 500,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  comment: {
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
    color: '#1D2B36 !important',
    maxWidth: 800,
    marginRight: spacing(4),
    marginBottom: spacing(2.5),
    '& p, & li': {
      margin: 0,
    },
    '& ul, ol': {
      marginTop: spacing(-2),
      marginBottom: spacing(-2),
      paddingLeft: spacing(6),
    },
  },
  noFieldsBlock: {
    position: 'absolute',
    width: 'calc(100% - 24px)',
    height: '100%',
    marginTop: -70,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: '#6D6E85',

    '& h3': {
      marginTop: 16,
      marginBottom: 4,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '20px',
    },

    '& p': {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '18px',
    },
  },
  disabledFieldsInfoCard: {
    width: 'calc(100% - 40px)',
    height: 'fit-content',
    margin: '16px 20px',
    padding: 12,
    background: '#059BEB1A',
    borderRadius: 12,
    display: 'flex',
    gap: 12,
    alignItems: 'flex-start',
  },
  disabledFieldsInfoCardContent: {

    '& p': {
      color: '#0072B8',
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '20px',
      marginBottom: 10,
    },

    '& span': {
      color: '#262842',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '18px',
    }
  },
  validation: {
    backgroundColor: '#FFE2DE',
    width: 'calc(100% - 40px)',
    padding: '8px 12px',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    margin: '8px 20px',
  },
  validationTitle: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '18px',
    marginBottom: 6,
    color: '#D6331F',
  },
  validationSubTitle: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
  },
}));
