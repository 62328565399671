import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, IconButton, Modal, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';

import CloseIcon from '@mui/icons-material/Close';
import { UserAvatar, UserInfo } from 'components/User';
import { useOldUsersListState, useUsersState } from 'store/users';
import { GroupSelect } from 'components/GroupSelect';
import { ConfirmationModal } from '../ConfirmationModal';
import { Spinner } from 'components/Spinner';
import { updateAssignee, massUpdateAssignee } from 'api/requests';
import { StatusType } from 'types';
import useStyles from './ChangeAssignee.useStyles';

type Props = {
  close: () => void
  data: {
    assigneeId: string;
    taskId: string | string[];
    stepName: string;
  }
  onSuccess: () => void;
};

export const ChangeAssignee = ({ close, data, onSuccess }: Props) => {
  const { t } = useTranslation();
  const { users: usersList = [] } = useUsersState();
  const oldUsersList = useOldUsersListState();
  const [newAssignee, setNewAssignee] = useState(null);
  const [status, setStatus] = useState<StatusType>(null);
  const [currentAssignee, setCurrentAssignee] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [newAssigneeFullname, setNewAssigneeFullname] = useState("")

  const classes = useStyles({isModalHidden: isConfirmModalOpen});

  const changeAssignee = async () => {
    if (typeof data.taskId !== "string") return

    setStatus(StatusType.PENDING)
    try {
      await updateAssignee({
        taskId: data.taskId,
        assigneeId: newAssignee
      })

      setStatus(StatusType.RESOLVED)
      onSuccess()
    } catch (error) {
      setStatus(StatusType.REJECTED)
      NotificationManager.error(t(error.message));
    }
  }

  const massChangeAssignee = async () => {
    if (!Array.isArray(data.taskId)) {
      throw new Error("data.taskId should be list")
    }

    return massUpdateAssignee(
      data.taskId?.map(taskId => ({
        taskId,
        userId: newAssignee
      }))
    )
  }

  const users = useMemo(() => {
    return Object.values(usersList).map(({ fullName, avatar, id }: any) => ({
      label: fullName,
      value: id,
      icon: <UserAvatar user={{
        avatar,
        id,
        fullName,
        firstName: '',
        fullNameRu: '',
        lastName: '',
        logName: '',
        phoneNumber: '',
        jobTitle: '',
        email: '',
        role: '',
      }}
        avatarSize={24}
      />
    }));
  }, [usersList]);

  useEffect(() => {
    if (typeof data.assigneeId !== "string") return
    if (currentAssignee) return;
    setCurrentAssignee(usersList[data.assigneeId] || oldUsersList?.find(user => user.id === data.assigneeId || user.userId === data.assigneeId))
  }, [usersList, oldUsersList, data, currentAssignee])

  return (
    <Box className={classes.modalBody}>
      <div className={classes.modalHeader}>
        <Typography className={classes.title}>
          {t('AdminPanel.actions.changeAssignee')}
        </Typography>

        <IconButton onClick={close} size="large">
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </div>

      <div className={classes.modalContent}>
        {status === StatusType.PENDING && <Spinner />}

        {currentAssignee && <div className={classes.currentAssigneeInfo}>
          <Typography className={classes.stepName}>
            {t("new_request.step")}: <span>{data.stepName}</span>
          </Typography>

          <UserInfo
            user={currentAssignee}
            avatarSize={24}
            nameMarginLeft={5}
          />
        </div>
        }

        <GroupSelect
          title={t('AdminPanel.inputs.newAssignee')}
          options={users}
          onChange={values => {
            setNewAssigneeFullname(values[0]?.label)
            setNewAssignee(values[0]?.value);
          }}
          value={[newAssignee].filter(Boolean)}
          isShownSearch
          className={classes.userSelect}
        />
      </div>

      <div className={classes.modalFooter}>
        <Button color="secondary" variant="contained" onClick={close} className={classes.modalButton}>
          {t('comment_modal.button_cancel')}
        </Button>

        <Button
          color="primary"
          variant="contained"
          className={classes.modalButton}
          disabled={!newAssignee}
          onClick={() => {
            if (Array.isArray(data.taskId)){
              setIsConfirmModalOpen(true)
            } else {
              changeAssignee()
            }
          }}
        >
          {t('buttons.change')}
        </Button>
      </div>

      <Modal open={isConfirmModalOpen}>
      <ConfirmationModal
          description={t('AdminPanel.massChangeAssigneeConfirmation', {
            assigneeName: newAssigneeFullname
          })}
          title={t("general.Confirmation")}
          okButtonText={t('buttons.confirm')}
          close={() => {
            setIsConfirmModalOpen(false)
          }}
          onSuccessAction={() => {
            setIsConfirmModalOpen(false)
            onSuccess()
          }}
          action={massChangeAssignee}
        />
    </Modal>
    </Box>
  );
};


