import { sortArrayOfObjects, removeSpacesFromNumber } from 'utils/general';
import {
  TaskValuesType,
  TaskEntityType,
  BusinessEntityType,
  TaskActionType,
  TaskStateType,
  TaskParametersType,
} from 'types';

const attributeTypes = [
  'arrayEntityAttributes',
  'booleanAttributes',
  'dateAttributes',
  'doubleAttributes',
  'enumAttributes',
  'fileAttributes',
  'integerAttributes',
  'linkAttributes',
  'stringAttributes',
];

class BpmTask {
  public _entity: TaskEntityType;
  private _businessEntity: BusinessEntityType;
  private _allActions: TaskActionType[];
  private _states: TaskStateType[];
  private _attributes: any[];
  private _showReadonlyAttributes: boolean;

  constructor(
    entity: TaskEntityType,
    businessEntity: BusinessEntityType,
    actions: TaskActionType[],
    states?: TaskStateType[]
  ) {
    this._entity = entity;
    this._businessEntity = businessEntity;
    this._allActions = actions;
    this._states = states;
    this._attributes = [];
    this._showReadonlyAttributes = false;
  }

  get entity() {
    return (this._entity || {}) as TaskEntityType;
  }

  set entity(entity) {
    this._entity = entity;
  }

  get businessEntity() {
    return (this._businessEntity || {}) as BusinessEntityType;
  }

  set businessEntity(businessEntity) {
    this._businessEntity = businessEntity;
  }

  get allActions() {
    return this._allActions || [];
  }

  get states() {
    return this._states || [];
  }

  set states(states) {
    this._states = states;
  }

  get instance() {
    return this.entity.taskInstance;
  }

  get businessTask() {
    return this.entity.businessTask;
  }

  get applicationNumber(): string {
    return this.businessTask.applicationNumber;
  }

  get processName(): string {
    return this.businessTask.processDefinitionName;
  }

  get isTemplateProcessTask(): boolean {
    return this._businessEntity.template;
  }

  get assignee(): string {
    return this.businessTask.assignee
      ? this.businessTask.assignee.toLowerCase()
      : '';
  }

  get candidateUsers(): string | string[] {
    return this.businessTask.candidateUsers
      ? this.businessTask.candidateUsers.map((candidateUser: string) =>
          candidateUser.toLowerCase()
        )
      : '';
  }

  get initiator(): string {
    return this.businessTask.initiator
      ? this.businessTask.initiator.toLowerCase()
      : '';
  }

  get processInstanceId(): string {
    return this.businessTask.processInstanceId;
  }

  get taskId(): string {
    return this.businessTask.taskId;
  }

  get createdDate(): string {
    return this.businessTask.processStartDate;
  }

  get completedDate(): string {
    return this.businessTask.taskEndDate;
  }

  get processSysName(): string {
    return this.businessEntity.sysName;
  }

  get isStepCompleted(): boolean {
    return this.businessTask.completed;
  }

  get isCompleted(): boolean {
    return this.businessTask.taskCompleted;
  }

  get isCancelled(): boolean {
    return this.isCompleted && !!this.businessTask.errorStatus || this._entity.businessTask.taskStatus === 'Canceled';
  }

  get isRejected(): boolean {
    return this._entity.businessTask.taskStatus === 'Reject';
  }

  get attachments() {
    return this.instance.attachments;
  }

  get actions(): TaskActionType[] {
    return this.allActions
      .filter((action) => action.active)
      .sort((a, b) => sortArrayOfObjects(a, b, 'stepperOrder'));
  }

  get currentAction(): TaskActionType {
    const currentAction = this.actions.find(
      ({ sysName }) => sysName === this.businessTask.actionSysName
    );

    return (currentAction || {}) as TaskActionType;
  }

  get steps(): TaskActionType[] {
    return this.actions.filter(
      (action) =>
        !action.stepperLabel.includes('##hidden') && action.stepperOrder > 0
    );
  }

  get currentSteps(): TaskActionType[] {
    const currentSteps = [...this.steps];

    if (['Reject', 'Rework'].includes(this.currentAction.name)) {
      currentSteps.unshift(this.currentAction);
    }

    return currentSteps;
  }

  get isRework(): boolean {
    return this.currentAction.name === 'Rework';
  }

  get isFirstStep(): boolean {
    return this.currentAction.stepperOrder === 1;
  }

  get isLastStep(): boolean {
    return (
      this.actions.length &&
      this.currentAction.stepperOrder ===
        this.actions[this.actions.length - 1].stepperOrder
    );
  }

  get stepNumber(): number | string {
    return this.currentAction ? this.currentAction.stepperOrder : '-';
  }

  get stepName(): string {
    return this.currentAction ? this.currentAction.name : '';
  }

  get stepSysName(): string {
    return this.currentAction ? this.currentAction.sysName : '';
  }

  get values(): Partial<TaskValuesType> {
    const values = {};

    attributeTypes.forEach((attrType) => {
      this.instance[attrType].forEach((attr) => {
        if (attrType === 'arrayEntityAttributes') {
          values[attr.name] = (attr.value as string[]).join();
        } else {
          values[attr.name] = attr.value;
        }
      });
    });

    return values;
  }

  isInitiator(user: string, hasAccessToPromoteRequests: boolean): boolean {
    return this.initiator === user.toLowerCase() || hasAccessToPromoteRequests;
  }

  isAssignee(user: string, hasAccessToPromoteRequests: boolean): boolean {
    return this.assignee === user.toLowerCase() || hasAccessToPromoteRequests;
  }

  isAssigneeCandidate(
    user: string,
    hasAccessToPromoteRequests: boolean
  ): boolean {
    return (
      this.candidateUsers.includes(user.toLowerCase()) ||
      hasAccessToPromoteRequests
    );
  }

  get taskDetailsTabs() {
    const tabs = [];
    const attrsWithGroup = [];

    this.businessAttributes.forEach((attr) => {
      const index = this.stateAttributes.findIndex(
        (el) => el.attributeId === attr.id
      );
      if (index !== -1) {
        attrsWithGroup.push({
          ...attr,
          groupName: this.stateAttributes[index].groupName,
        });
      }
    });
    for (const currentAttr of attrsWithGroup) {
      const index = tabs.findIndex(
        (el) => el.index === (currentAttr.tabNumber || 0)
      );
      if (index === -1) {
        tabs.push({
          index: currentAttr.tabNumber || 0,
          description: currentAttr.tabDescription || 'Information',
          attributes: [currentAttr],
        });
      } else {
        const currentTab = tabs[index];
        tabs[index] = {
          ...currentTab,
          attributes: [...currentTab.attributes, currentAttr],
        };
      }
    }
    return tabs;
  }

  get formationRequestSteps() {
    const steps = [];
    const attrsWithGroup = [];

    this.businessAttributes.forEach((attr) => {
      const index = this.stateAttributes.findIndex(
        (el) => el.attributeId === attr.id
      );
      if (index !== -1) {
        attrsWithGroup.push({
          ...attr,
          groupName: this.stateAttributes[index].groupName,
        });
      }
    });
    for (const currentAttr of attrsWithGroup) {
      const index = steps.findIndex(
        (el) => el.order === currentAttr.stepNumber
      );
      if (index === -1) {
        steps.push({
          order: currentAttr.stepNumber,
          description: currentAttr.stepDescription,
          attrGroups: {
            [currentAttr.groupName]: [this.getAttributeById(currentAttr.id)],
          },
        });
      } else {
        const currentStep = steps[index];
        steps[index] = {
          ...currentStep,
          attrGroups: {
            ...currentStep.attrGroups,
            [currentAttr.groupName]: currentStep.attrGroups[
              currentAttr.groupName
            ]
              ? [
                  ...currentStep.attrGroups[currentAttr.groupName],
                  this.getAttributeById(currentAttr.id),
                ]
              : [this.getAttributeById(currentAttr.id)],
          },
        };
      }
    }
    const filtredSteps =
      steps.length > 1 ? steps.filter((el) => el.order) : steps;
    return filtredSteps.length > 1
      ? filtredSteps.sort((a, b) => a.order - b.order)
      : filtredSteps;
  }

  get currentState(): TaskStateType {
    return this.states.find(
      ({ sysName }) => sysName === this.businessTask.actionSysName
    );
  }

  get stateAttributes(): TaskParametersType[] {
    if (this.currentState) {
      return this.currentState.bsnParameters
        .filter((param) => param.groupName)
        .sort((a, b) => sortArrayOfObjects(a, b, 'state_order'));
    }

    return [];
  }

  get currentStateGroups() {
    const groups = {};

    this.stateAttributes.forEach((param) => {
      if (this.businessEntity.sysName === 'Procurement' && param.groupName === 'Contractors') {
        return;
      }

      if (!groups[param.groupName]) {
        groups[param.groupName] = [];
      }

      groups[param.groupName] = [
        ...groups[param.groupName],
        this.getAttributeById(param.attributeId),
      ];
    });

    return groups;
  }

  get currentStateButtons() {
    if (this.currentState) {
      const buttonsList = this.currentState.bsnParameters
        .filter((param) => !param.groupName)
        .sort((a, b) => sortArrayOfObjects(a, b, 'state_order'));

      return buttonsList.map((button) => {
        const buttonAttribute = this.attributes?.find(
          (attribute) => attribute?.typeAttributeId === button?.attributeId
        );
        return {
          ...button,
          sysName: buttonAttribute?.sysName,
        };
      });
    }

    return [];
  }

  get businessAttributes() {
    return this.businessEntity.attributes;
  }

  get instanceAttributes() {
    let attributes = [];

    attributeTypes.forEach((attrType) => {
      attributes = [...attributes, ...this.instance[attrType]];
    });

    return attributes;
  }

  get defaultFormValues() {
    const values = {};
    const attributes = this.stateAttributes.map((attr) =>
      this.getAttributeById(attr.attributeId)
    );

    attributes.forEach((attr) => {
      if (!(attr && Object.prototype.hasOwnProperty.call(attr, 'name'))) {
        return;
      }

      let value = this.values[attr.name];

      values[attr.name] = value;

      if (attr.name === 'benefits' && value?.length) {
        values[attr.name] = value.join('\n');
      }
      if (attr.name === 'amount' && value === 0) {
        values[attr.name] = undefined;
      }
    });

    return values;
  }

  get attributes() {
    if (this._attributes.length > 0) {
      return this._attributes;
    }

    this._attributes = this.businessAttributes.map((attr) => {
      const stateData = this.stateAttributes.find(
        (stateAttr) => stateAttr.attributeId === attr.id
      );
      const instanceData = this.instanceAttributes.find(
        (instanceAttr) => instanceAttr.typeAttributeId === attr.id
      );

      if (stateData) {
        // Logic for readonly fields in last steps or if current user can't promote request
        if (
          this.isCompleted ||
          this.isStepCompleted ||
          this._showReadonlyAttributes
        ) {
          const componentExceptionArray = [
            'hidden-field',
            'readonly-field',
            'readonly-date',
            'readonly-user',
            'readonly-number',
            'readonly-amount-and-currency',
            'entities-list',
            'link-field',
            'supplier-voting-results',
            'modal',
            'rejection-reason',
            'readonly-user-list',
            'action-button',
            'readonly-multi-select-glossary',
          ];
          const componentReadOnlyArray = [
            'checkbox',
            'contractors-table',
            'datetime',
            'file',
            'edsFinalDocuments',
          ];
          const componentReadOnlyNumberNameArray = [
            'amountInKzt',
            'prepayment',
            'currencyRate',
          ];
          const componentReadOnlyNumberComponentExceptions = ['hidden-field'];
          const componentReadOnlyLocalizedGlossaryValueArray = [
            'glossary',
            'selectGlossary',
          ];
          const componentReadOnlyMultiSelectGlossary = ['glossary'];
          const componentReadOnlyLocalizedValueListArray = [
            'radio-button-group',
            'select',
          ];

          const componentParams = stateData.componentParams;
          const componentParamsObject =
            (componentParams && JSON.parse(componentParams)) || {};

          if (componentReadOnlyArray.includes(stateData.component)) {
            if (
              !Object.prototype.hasOwnProperty.call(
                componentParamsObject,
                'readOnly'
              ) ||
              !componentParamsObject.readOnly
            ) {
              componentParamsObject.readOnly = true;
            }

            stateData.componentParams = JSON.stringify(componentParamsObject);
          } else if (
            componentReadOnlyNumberNameArray.includes(instanceData.name) &&
            !componentReadOnlyNumberComponentExceptions.includes(
              stateData.component
            )
          ) {
            stateData.component = 'readonly-number';
          } else if (
            componentReadOnlyMultiSelectGlossary.includes(
              stateData.component
            ) &&
            componentParamsObject.multiSelect
          ) {
            stateData.component = 'readonly-multi-select-glossary';
          } else if (!componentExceptionArray.includes(stateData.component)) {
            const componentParams = stateData.componentParams;
            const componentParamsObject =
              (componentParams && JSON.parse(componentParams)) || {};

            if (
              componentReadOnlyLocalizedGlossaryValueArray.includes(
                stateData.component
              )
            ) {
              componentParamsObject.isGlossaryValue = true;
            }
            if (
              componentReadOnlyLocalizedValueListArray.includes(
                stateData.component
              )
            ) {
              componentParamsObject.isValueFromValuesList = true;
            }

            stateData.componentParams = JSON.stringify(componentParamsObject);

            if (stateData.component === 'user-select') {
              stateData.component = 'readonly-user';
            } else if (stateData.component === 'user-list') {
              stateData.component = 'readonly-user-list';
            } else {
              stateData.component = 'readonly-field';
            }
          }
        }
      }

      return { ...instanceData, ...attr, ...stateData };
    });

    return this._attributes;
  }

  get getObservers() {
    return this._entity.businessTask.observer;
  }

  forceRefreshAttributes(showReadonlyAttributes) {
    this._showReadonlyAttributes = showReadonlyAttributes;
    this._attributes = [];
  }

  getAttributeBySysName(attributeSysName = '') {
    const [attributeProcess, attributeName] = attributeSysName.split('::');

    const selectedAttribute = this.attributes.find(({ sysName }) => {
      const [attributeProcessBySysName, attributeNameBySysName] = sysName.split(
        '::'
      );
      return (
        attributeProcess === attributeProcessBySysName &&
        attributeName === attributeNameBySysName
      );
    });

    if (selectedAttribute) return selectedAttribute;

    return this.attributes.find(({ sysName }) =>
      sysName.startsWith(attributeSysName)
    );
  }

  getAttributeById = (id) =>
    this.attributes.find(({ attributeId }) => attributeId === id);

  getAttributeByName = (attributeName) =>
    this.attributes.find(({ name }) => name === attributeName);

  setInstanceAttributes({ values, observerUsers }) {
    const getModifiedInstanceAttributeValueByType = (
      attributeValue,
      attributeType
    ) => {
      if (!attributeType) {
        return;
      }

      switch (attributeType) {
        case 'arrayEntityAttributes':
          return attributeValue
            ? attributeValue
                .toString()
                ?.split(',')
                .map((attachmentId) => +attachmentId)
            : [];
        case 'doubleAttributes':
          return removeSpacesFromNumber(attributeValue);
        case 'integerAttributes':
          return removeSpacesFromNumber(attributeValue);
        case 'booleanAttributes':
          /* Условие для readonly компонентов, которые делают force update значения при типе boolean,
           * перед ее инициализацией. Нужно было вернуть все обратно чтобы запрос не ломался */
          if (
            typeof attributeValue === 'string' &&
            (attributeValue === 'true' || attributeValue === 'false')
          ) {
            return attributeValue === 'true';
          }

          return !!attributeValue;
        case 'stringAttributes':
          /* Условие для checkbox-ов (boolean), которые мы отправляем в запрос как тип string */
          if (attributeValue === 'false' || attributeValue === false) {
            return '';
          }
          if (typeof attributeValue === 'boolean') {
            return attributeValue.toString();
          }
          return attributeValue?.toString() || '';
        case 'enumAttributes': {
          return attributeValue;
        }
        case 'dateAttributes':
          return new Date(attributeValue);
        default:
          return attributeValue;
      }
    };

    const instance = this.instance;
    instance.observer = observerUsers || [];

    attributeTypes.forEach((attributeType) => {
      instance[attributeType] = instance[attributeType].map(
        (instanceAttribute) => {
          const instanceAttributeValue = values[instanceAttribute.name];
          if (
            (typeof instanceAttributeValue === 'undefined' &&
              // fix accesses selects not saved after unchecking
              !instanceAttribute?.sysName.includes('accesses')) ||
            instanceAttributeValue === null ||
            !Object.keys(values).includes(instanceAttribute.name)
          ) {
            return instanceAttribute;
          }

          if (
            !Object.prototype.hasOwnProperty.call(instanceAttribute, 'value')
          ) {
            instanceAttribute.value = null;
          }

          instanceAttribute.value = getModifiedInstanceAttributeValueByType(
            instanceAttributeValue,
            attributeType
          );

          return instanceAttribute;
        }
      );
    });

    return instance;
  }

  setInstanceAttribute(name, value) {
    const newInstance = this.instance;

    attributeTypes.forEach((type) => {
      newInstance[type] = newInstance[type].map((attr) => {
        if (attr.name === name) return { ...attr, value };
        return attr;
      });
    });

    return newInstance;
  }
}

export default BpmTask;
