import { createAction } from 'redux-actions';

export const MAIN_ACTIONS = {
  SET_CUSTOM_MODAL_PARAMS: 'SET_CUSTOM_MODAL_PARAMS',
  CLOSE_CUSTOM_MODAL: 'CLOSE_CUSTOM_MODAL',
  REFRESH_ENTITIES: 'REFRESH_ENTITIES',
  SET_SIDE_MENU_OPEN: 'SET_SIDE_MENU_OPEN',
  TOGGLE_SIDE_MENU_OPEN: 'TOGGLE_SIDE_MENU_OPEN',
  RESTORE_SIDE_MENU_OPEN_STATE: 'RESTORE_SIDE_MENU_OPEN_STATE',
  READ_TASK_ATTACHMENTS: 'READ_TASK_ATTACHMENTS',
  READ_TASK_COMMENTS: 'READ_TASK_COMMENTS',
  READ_TASK: 'READ_TASK',
  SET_HOLDING_TRANSLATIONS_LOADED: 'SET_HOLDING_TRANSLATIONS_LOADED'
};

export const setCustomModalParams = createAction(
  MAIN_ACTIONS.SET_CUSTOM_MODAL_PARAMS
);

export const closeCustomModal = createAction(
  MAIN_ACTIONS.CLOSE_CUSTOM_MODAL
);

export const refreshEntitiesAction = createAction(
  MAIN_ACTIONS.REFRESH_ENTITIES
);

export const setSideMenuOpen = createAction(
  MAIN_ACTIONS.SET_SIDE_MENU_OPEN
);

export const toggleSideMenuOpen = createAction(
  MAIN_ACTIONS.TOGGLE_SIDE_MENU_OPEN
);

export const restoreSideMenuStateFromLocalStorage = createAction(
  MAIN_ACTIONS.RESTORE_SIDE_MENU_OPEN_STATE, () => {
    const localStorageValue = localStorage.getItem('isSidebarOpen');
    return localStorageValue === null || localStorageValue === 'true';
  }
);

export const readTask = createAction(
  MAIN_ACTIONS.READ_TASK
);

export const readTaskComments = createAction(
  MAIN_ACTIONS.READ_TASK_COMMENTS
);

export const readTaskAttachments = createAction(
  MAIN_ACTIONS.READ_TASK_ATTACHMENTS
);

export const setHoldingTranslationsLoaded = createAction(
  MAIN_ACTIONS.SET_HOLDING_TRANSLATIONS_LOADED
);
