import { lazy } from 'react';

import { RouterListType } from 'types';

const DraftsList = lazy(() => import('pages/Requests/DraftsList'));
const RequestsList = lazy(() => import('pages/Requests/RequestsList'));
const ApprovalsResults = lazy(() => import('pages/Approvals/ApprovalsResults'));
// const MonitoringResults = lazy(() => import('pages/Monitoring/List'));
const RolesAndUsersSettingsPage = lazy(() => import('pages/Settings/RolesAndUsersSettingsPage/RolesAndUsersSettingsPage'));
const GroupsSettingsPage = lazy(() => import('pages/Settings/GroupsSettingsPage/GroupsSettingsPage'));

export const routesList = (t: any): RouterListType => ({
  requests: {
    path: '/requests',
    name: t('Processes.module_requests'),
    submenu: [
      {
        name: t('Processes.Requests_tab1'),
        path: '/active',
        component: RequestsList,
        props: {
          completed: false,
          isMassSelectAvailable: false,
          isDataViewAvailable: true,
          isFiltersAvailable: true,
        },
      },
      {
        name: t('Processes.Requests_tab2'),
        path: '/completed',
        component: RequestsList,
        props: {
          completed: true,
          isMassSelectAvailable: false,
          isDataViewAvailable: true,
          isFiltersAvailable: true,
        },
      },
      {
        name: t('Processes.Requests_tab3'),
        path: '/drafts',
        component: DraftsList,
        props: {
          isMassSelectAvailable: true,
          isDataViewAvailable: false,
          isFiltersAvailable: false,
        },
      },
    ],
    special: {
      register: {
        name: 'Register',
        path: '/register',
      },
    },
  },
  approvals: {
    path: '/approvals',
    name: t('Processes.module_approvals'),
    submenu: [
      {
        name: t('Processes.Approvals_tab1'),
        path: '/active',
        component: ApprovalsResults,
        props: {
          reviewed: false,
          isMassSelectAvailable: true,
          isDataViewAvailable: true,
          isFiltersAvailable: true,
        },
      },
      {
        name: t('Processes.Approvals_tab2'),
        path: '/reviewed',
        component: ApprovalsResults,
        props: {
          reviewed: true,
          isMassSelectAvailable: false,
          isDataViewAvailable: true,
          isFiltersAvailable: true,
        },
      },
    ],
  },
  monitoring: {
    path: '/monitoring',
    name: t('Processes.Approvals_tab3'),
    submenu: [
      {
        name: t('Processes.Requests_tab1'),
        path: '/active',
        component: ApprovalsResults,
        props: {
          completed: false,
          isMassSelectAvailable: false,
          isDataViewAvailable: true,
          isFiltersAvailable: true,
        },
      },
      {
        name: t('Processes.Requests_tab2'),
        path: '/completed',
        component: ApprovalsResults,
        props: {
          completed: true,
          isMassSelectAvailable: false,
          isDataViewAvailable: true,
          isFiltersAvailable: true,
        },
      },
    ]
  },
  templateProcesses: {
    path: '/template-processes',
    name: t('customProcesses.title'),
  },
  templateProcessEdit: {
    path: '/template-processes/:id',
    url: '/template-processes',
    name: t('customProcesses.title'),
  },
  adminPanel: {
    path: '/admin-panel',
    name: t('AdminPanel.title'),
  },
  templates: {
    path: '/templates',
    name: t('templates.title'),
  },
  templateEdit: {
    path: '/templates/:id',
    url: '/templates',
    name: t('templates.title'),
  },
  search: {
    path: '/search',
    name: 'Search',
  },
  task: {
    path: '/task/:id',
    url: '/task',
  },
  process: {
    path: '/process/:id',
    url: '/process',
  },
  analytics: {
    path: '/analytics',
    name: t('Processes.module_analytics'),
  },
  settings: {
    path: '/settings',
    name: t('settings.sidebar.title'),
    submenu: [
      {
        name: t('settings.sidebar.menu.rolesAndPermissions'),
        path: '/roles',
        component: RolesAndUsersSettingsPage
      },
      {
        name: t('settings.sidebar.menu.groups'),
        path: '/groups',
        component: GroupsSettingsPage
      }
    ]
  },
});
