import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, FormControlLabel, Grid, Switch, Drawer, Tab, Tooltip, Typography, Radio, RadioGroup, Checkbox } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Trans, useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useDraggable as useDraggableScroll } from 'react-use-draggable-scroll';
import { useRouteMatch } from 'react-router-dom';

import { AppTextField, NumberInputWithArrows, PromptModal } from 'components';

import {
  ConditionStep,
  FormField,
  Language,
  ParallelBranchesGroup,
  ProcessStep,
  StepValidationResult,
  AllProcessFieldsListItem,
  DepartmentInfo,
  DocumentTemplate,
  DocumentTemplateField,
  TemplateProcess,
} from '../../TemplateProcesses.types';
import useProcessSetupSidePanelStyles from './ProcessSetupSidePanel.useStyles';
import { ProcessStepList } from '../ProcessStepList';
import { FieldsListCard } from '../FieldsListCard';
import {
  ATTACHMENT_FIELD_KEY,
  DOCUMENT_DOCFLOW_FIELD_IS_REQUIRED_PARAM,
  DOCUMENT_DOCFLOW_FIELD_KEY,
  DOCUMENT_DOCFLOW_FIELD_MULTIPLE_SELECTION_PARAM,
  FIELD_TYPES,
  FIELDS,
  GLOSSARY_FIELD_DIRECTORY_CODE_PARAM,
  GLOSSARY_FIELD_DIRECTORY_PARAM,
  GLOSSARY_FIELD_KEY,
  GLOSSARY_MULTI_SELECT_FIELD_KEY,
  MAX_FIELDS_IN_SUMMARY,
  SUM_FIELD_KEY,
  SYSTEM_GLOSSARIES,
  SYSTEM_GLOSSARIES_DEFAULT_NAMES,
  SYSTEM_GLOSSARY_FIELD_KEY,
  SYSTEM_GLOSSARY_INDEX_NAME_PARAM,
} from './ProcessSetupSidePanel.constants';

import InfoIcon from 'assets/images/icons/info_outline.svg';
import FlowTabIcon from 'assets/images/icons/template-process-flow-tab-icon.svg';
import FlowTabActiveIcon from 'assets/images/icons/template-process-flow-tab-icon-active.svg';
import FieldsTabIcon from 'assets/images/icons/template-processes-fields-tab-icon.svg';
import FieldsTabActiveIcon from 'assets/images/icons/template-processes-fields-tab-icon-active.svg';
import FieldsTabDisabledIcon from 'assets/images/icons/template-processes-fields-tab-icon-disabled.svg';
import WarningIcon from 'assets/images/icons/warning-sign.svg';
import { TreeGlossary } from '../../../Task/TaskForm/FormFields/Fields/Glossary/TreeGlossary';
import CloseIcon from 'assets/images/icons/close-icon.svg';
import StatusIcon from '../StatusIcon';
import WarningDarkGray from 'assets/images/icons/warning_dark_gray.svg';
import DocumentsTabIcon from 'assets/images/icons/template-processes-documents-tab-icon.svg';
import DocumentsTabActiveIcon from 'assets/images/icons/template-processes-documents-tab-icon-active.svg';
import DocumentsTabDisabledIcon from 'assets/images/icons/template-processes-documents-tab-icon-disabled.svg';

import { limitStringLength } from 'utils/user';
import { DocumentsTab } from './tabs/documents/DocumentsTab';
import dropdownIndicatorIcon from '../../../../assets/images/icons/dropdown-indicator.svg';

import { environment } from 'environments/environment';
import CheckboxCheckedIcon from '../../../../assets/images/icons/checkbox-checked-disabled.svg';

interface ProcessStepListProps {
  processIntegrations?: string[];
  formErrors?: StepValidationResult[];
  isReadonlyView?: boolean;
  isAutostartActive?: boolean;
  processSteps: ProcessStep[];
  currentLanguage: Language;
  currentStepIndex: number;
  currentConditionId: string;
  currentParallelBranchesGroupId: string;
  selectedItemType: 'processStep' | 'condition' | 'parallelBranchesGroups';
  departmentsList: DepartmentInfo[];
  allUsedGlossaries: string[];
  directManagerSteps: number[];
  performerSelectionSteps: number[];
  gridPathRef: any;
  focusedStepFieldId: string;
  summaryFields: string[];
  assigneeTypeChanged: boolean;
  isEditDisabled?: boolean;
  allProcessStepsFields: AllProcessFieldsListItem[];
  conditions: ConditionStep[];
  parallelBranchesGroups: ParallelBranchesGroup[];
  documents: DocumentTemplate[];
  focusedDocumentId: string;
  setFocusedDocumentId: (value: string) => void;
  handleParallelStepsGroupSelect: (parallelStepsGroup: ParallelBranchesGroup) => void;
  handleAddProcessConditionStep: (processStep: ProcessStep) => void;
  handleAddParallelStepsGroup: (processStep: ProcessStep) => void;
  handleAddProcessStep: () => void;
  handleAddPreviousStep: (
    processStep: ProcessStep,
    isConditionBranchStep?: boolean,
    branch?: 'positive' | 'negative',
    condition?: ConditionStep
  ) => void;
  handleParallelBranchStepAdd: (parallelBranchesGroup: ParallelBranchesGroup, branchIndex: number, stepPosition: number) => void;
  handleConditionBranchStepAdd: (condition: ConditionStep, branchType: 'positive' | 'negative', stepPosition: number) => void;
  handleProcessSelect: (processStep: ProcessStep) => void;
  handleConditionSelect: (condition: ConditionStep) => void;
  handleStepFieldValuesChange: (fieldDate: FormField, propagateToAllSteps?: boolean) => void;
  clearFocusedField: () => void;
  currentStep: ProcessStep;
  openStepsLimitReachedDialog: () => void;
  openBranchStepsLimitReachedDialog: () => void;
  handleDocumentFieldsAdd: (fields: DocumentTemplateField[]) => void;
  handleDocumentParamsChange: (document: DocumentTemplate) => void;
  handleDocumentChangeUndo: (addedFields: string[], linkedFields: string[], hasSignatureStateChanged: boolean) => void;
  handleDocumentPreviewOpen: (documentId: number) => void;
  handleDocumentSignatureStepRemovalDialogOpen: () => void;
  handleStepFieldDeleteConfirmation: (fieldId: string[]) => void;
  isNewField: boolean;
}

const TABS = {
  processPath: {
    key: 'processPath',
    title: 'customProcesses.tabs.processPath',
    disabledTooltipText: 'customProcesses.tabs.fieldSettingsDisabledTooltipText',
    disabledTooltipTextConditionBranches: 'customProcesses.tabs.fieldSettingsDisabledTooltipTextConditionBranches',
    icon: FlowTabIcon,
    iconActive: FlowTabActiveIcon,
    iconDisabled: FlowTabIcon,
    availableForTemplates: true,
    disabledWhenEditDisabled: false,
    disabledOnAutostartStep: false,
    disabledOnNotStepSelected: false,
    disabledOnConditionBranchSteps: false,
  },
  fieldsSettings: {
    key: 'fieldsSettings',
    title: 'customProcesses.tabs.fieldsSettings',
    disabledTooltipText: 'customProcesses.tabs.fieldSettingsDisabledTooltipText',
    disabledTooltipTextConditionBranches: 'customProcesses.tabs.fieldSettingsDisabledTooltipTextConditionBranches',
    icon: FieldsTabIcon,
    iconActive: FieldsTabActiveIcon,
    iconDisabled: FieldsTabDisabledIcon,
    availableForTemplates: true,
    disabledWhenEditDisabled: true,
    disabledOnAutostartStep: true,
    disabledOnNotStepSelected: true,
    disabledOnConditionBranchSteps: true,
  },
  documents: {
    key: 'documents',
    title: 'customProcesses.tabs.documents',
    disabledTooltipText: 'customProcesses.tabs.documentsDisabledTooltipText',
    disabledTooltipTextConditionBranches: 'customProcesses.tabs.documentsDisabledTooltipTextConditionBranches',
    icon: DocumentsTabIcon,
    iconActive: DocumentsTabActiveIcon,
    iconDisabled: DocumentsTabDisabledIcon,
    availableForTemplates: false,
    disabledWhenEditDisabled: true,
    disabledOnAutostartStep: true,
    disabledOnNotStepSelected: true,
    disabledOnConditionBranchSteps: true,
  },
};

export const getParallelAndConditionStepsCount = (parallelBranchesGroups, conditions) => {
  let parallelCount = 0;
  let conditionCount = 0;
  parallelCount = parallelBranchesGroups.length;
  conditionCount = conditions.length;
  return { parallelCount, conditionCount };
};

export const DESRIPTION_TEXT_LIMIT = 35;
const BRANCH_STATUSES_TEXT_LIMIT = 15;
const SIDEPANEL_TEXT_LIMIT = 25;
export const CONDITION_STEPS_LIMIT = 3;
export const PARALLEL_STEPS_LIMIT = 2;

export const ProcessSetupSidePanel = ({
  processIntegrations = [],
  isReadonlyView = false,
  isAutostartActive = false,
  processSteps,
  formErrors,
  currentLanguage,
  currentStepIndex,
  currentConditionId,
  currentParallelBranchesGroupId,
  selectedItemType,
  departmentsList,
  allUsedGlossaries = [],
  directManagerSteps = [],
  performerSelectionSteps = [],
  focusedStepFieldId,
  gridPathRef,
  summaryFields,
  assigneeTypeChanged,
  isEditDisabled,
  allProcessStepsFields,
  conditions,
  parallelBranchesGroups,
  documents,
  focusedDocumentId,
  setFocusedDocumentId,
  currentStep,
  handleDocumentFieldsAdd,
  handleDocumentParamsChange,
  handleDocumentChangeUndo,
  handleDocumentPreviewOpen,
  handleParallelStepsGroupSelect,
  handleAddProcessConditionStep,
  handleAddParallelStepsGroup,
  handleParallelBranchStepAdd,
  handleConditionBranchStepAdd,
  handleDocumentSignatureStepRemovalDialogOpen,
  handleAddPreviousStep,
  handleAddProcessStep,
  handleProcessSelect,
  handleConditionSelect,
  handleStepFieldValuesChange,
  clearFocusedField,
  openStepsLimitReachedDialog,
  openBranchStepsLimitReachedDialog,
  handleStepFieldDeleteConfirmation,
  isNewField,
}: ProcessStepListProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useProcessSetupSidePanelStyles({
    hasConditions: conditions?.length > 0,
    hasParallelStepsGroups: parallelBranchesGroups?.length > 0,
  });

  const routeMatch = useRouteMatch();

  const isTemplatePage = useMemo(() => {
    return routeMatch.path === '/templates/:id';
  }, [routeMatch]);

  const { events: draggableScrollEvents } = useDraggableScroll(gridPathRef); // Now we pass the reference to the useDraggable hook:

  const [currentSidePanelTab, setCurrentSidePanelTab] = useState(TABS.processPath.key);
  const [initialFieldData, setInitialFieldData] = useState<FormField | null>(null);
  const [nameFieldError, setNameFieldError] = useState<boolean>(false);
  const [directoryPathError, setDirectoryPathError] = useState<boolean>(false);
  // const [howItWorksSidePanelOpen, setHowItWorksSidePanelOpen] = useState<boolean>(false);
  // const [showProcessInstruction, setShowProcessInstruction] = useState(false);
  // const [processInstructionOpened, setProcessInstructionOpened] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState(false);

  // TODO - найти лучший способ поправить сброс названия и описания поля при выборе справочника
  const savedParams = useRef({ hint: {}, placeholder: {}, isRequired: false, isEditable: false });
  const currentFieldId = useRef('');
  const prevFieldId = useRef('');

  useEffect(() => {
    if (!isSaving && prevFieldId.current && prevFieldId.current !== focusedStepFieldId && isNewField) {
      handleStepFieldDeleteConfirmation([prevFieldId.current]);
    }

    prevFieldId.current = focusedStepFieldId;
  }, [focusedStepFieldId, isNewField]);

  const focusedField = useMemo(() => {
    if (!focusedStepFieldId) {
      setInitialFieldData(null);
      currentFieldId.current = null;
      return;
    }
    const currentStepFields = processSteps[currentStepIndex].fields || [];
    const activeField = currentStepFields.find(({ id }) => id === focusedStepFieldId);
    if (!activeField) {
      return null;
    }

    if (!initialFieldData || currentFieldId.current !== focusedStepFieldId) {
      setInitialFieldData(activeField);
      savedParams.current = {
        hint: activeField?.hint || {},
        placeholder: activeField?.placeholder || {},
        isRequired: !!activeField?.isRequired,
        isEditable: !!activeField?.isEditable,
      };
      if (currentSidePanelTab !== 'fieldsSettings') {
        setCurrentSidePanelTab('fieldsSettings');
      }
    }

    currentFieldId.current = focusedStepFieldId;

    const fieldComponent = FIELDS.find(({ key }) => key === activeField.component);

    return { field: activeField, fieldComponent: fieldComponent };
  }, [currentStepIndex, processSteps, focusedStepFieldId, currentLanguage, currentSidePanelTab]);

  useEffect(() => {
    if (focusedDocumentId) {
      if (currentSidePanelTab !== 'documents') {
        setCurrentSidePanelTab('documents');
      }
    }
  }, [focusedDocumentId]);

  useEffect(() => {
    // GLOSSARY_FIELD_DIRECTORY_PARAM is used for glossaries in processes
    // GLOSSARY_FIELD_DIRECTORY_CODE_PARAM is used for glossaries in process templates
    setNameFieldError(() => false);
    setDirectoryPathError(() => false);
    if (
      focusedField &&
      (Object.values(focusedField?.field?.hint)?.some((v) => v.trim().length === 0) ||
        (!isTemplatePage &&
          formErrors?.[currentStepIndex]?.paramsWithErrors?.[focusedField?.field?.id]?.includes(GLOSSARY_FIELD_DIRECTORY_PARAM)) ||
        (isTemplatePage &&
          formErrors?.[currentStepIndex]?.paramsWithErrors?.[focusedField?.field?.id]?.includes(GLOSSARY_FIELD_DIRECTORY_CODE_PARAM)))
    ) {
      if (
        formErrors &&
        formErrors[currentStepIndex]?.errorFieldsIds?.includes(focusedField?.field?.id) &&
        Object.values(focusedField?.field?.hint).some((v) => v.trim().length === 0)
      ) {
        setNameFieldError(() => true);
      }
      if (
        formErrors &&
        ((!isTemplatePage &&
          (formErrors[currentStepIndex]?.paramsWithErrors[focusedField?.field?.id]?.includes(GLOSSARY_FIELD_DIRECTORY_PARAM) ||
            formErrors[currentStepIndex]?.paramsWithErrors[focusedField?.field?.id]?.includes(GLOSSARY_FIELD_DIRECTORY_CODE_PARAM))) ||
          (isTemplatePage &&
            formErrors[currentStepIndex]?.paramsWithErrors[focusedField?.field?.id]?.includes(GLOSSARY_FIELD_DIRECTORY_CODE_PARAM)))
      ) {
        setDirectoryPathError(() => true);
      }
    }
  }, [focusedField, formErrors]);

  const isFieldAdditionStep = useMemo(
    () => allProcessStepsFields.find((item) => item?.field?.id === focusedStepFieldId)?.firstAppearanceStep === currentStepIndex + 1,
    [allProcessStepsFields, focusedStepFieldId, currentStepIndex]
  );

  // disabled and active on the 1st step
  const isEditableToggleDisabled = useMemo(() => currentStepIndex === 0 || isFieldAdditionStep, [isFieldAdditionStep, currentStepIndex]);

  const isRequiredToggleDisabled = useMemo(() => !focusedField?.field?.isEditable, [focusedField]);

  const onStepFieldValuesChange = useCallback(
    (name: string, newValue: string | boolean | number[]) => {
      if (!focusedField) {
        return;
      }

      if (['hint', 'placeholder'].includes(name)) {
        savedParams.current = {
          ...savedParams.current,
          [name]: {
            ...savedParams.current?.[name],
            [currentLanguage]: newValue,
          },
        };
        handleStepFieldValuesChange(
          {
            ...focusedField.field,
            [name]: {
              ...focusedField.field[name],
              [currentLanguage]: newValue,
            },
          },
          true
        );
      } else if (name.startsWith('params')) {
        const paramName = name.replace('params.', '');
        handleStepFieldValuesChange(
          {
            ...focusedField.field,
            ...savedParams.current,
            params: {
              ...focusedField.field.params,
              [paramName]: newValue,
            },
          },
          true
        );
        // second function call is not an error, it fixes unnecessary change of isRequired value after params value change
        handleStepFieldValuesChange({
          ...focusedField.field,
          ...savedParams.current,
          params: {
            ...focusedField.field.params,
            [paramName]: newValue,
          },
        });
      } else {
        savedParams.current = {
          ...savedParams.current,
          [name]: newValue,
        };
        handleStepFieldValuesChange({
          ...focusedField.field,
          [name]: newValue,
        });
      }
    },
    [focusedField, allUsedGlossaries, handleStepFieldValuesChange, initialFieldData, currentLanguage, savedParams]
  );

  const handleCancel = () => {
    if (isNewField) {
      handleStepFieldDeleteConfirmation([focusedStepFieldId]);
    } else {
      handleStepFieldValuesChange(initialFieldData, true);
    }
    setInitialFieldData(null);
    setNameFieldError(() => false);
    setDirectoryPathError(() => false);
    clearFocusedField();
  };

  const hintPlaceholderLength = useMemo(() => focusedField?.field?.placeholder[currentLanguage]?.length, [
    focusedField?.field?.placeholder[currentLanguage],
  ]);
  const hintPlaceholderIsOverLimit = useMemo(() => hintPlaceholderLength > DESRIPTION_TEXT_LIMIT, [hintPlaceholderLength]);
  const hintPlaceholderErrorTemplate = useMemo(
    () => (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>{hintPlaceholderIsOverLimit ? `${t('errors.maximumCharacters')} ${DESRIPTION_TEXT_LIMIT}` : ''}</div>
        <div>{`${hintPlaceholderLength}/${DESRIPTION_TEXT_LIMIT}`}</div>
      </div>
    ),
    [hintPlaceholderLength, hintPlaceholderIsOverLimit]
  );

  const handleSave = () => {
    setNameFieldError(() => false);
    setDirectoryPathError(() => false);
    let hasErrors = false;
    setIsSaving(true);

    if (Object.values(focusedField?.field.hint).some((v) => v.trim().length === 0)) {
      setNameFieldError(() => true);
      hasErrors = true;
    }

    if (
      [GLOSSARY_FIELD_KEY, GLOSSARY_MULTI_SELECT_FIELD_KEY].includes(focusedField?.fieldComponent.key) &&
      !(focusedField?.field.params[GLOSSARY_FIELD_DIRECTORY_PARAM] || focusedField?.field.params[GLOSSARY_FIELD_DIRECTORY_CODE_PARAM])
    ) {
      setDirectoryPathError(() => true);
      hasErrors = true;
    }

    if (hintPlaceholderIsOverLimit) {
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    clearFocusedField();
    setTimeout(() => setIsSaving(false), 0);
  };

  useEffect(() => {
    if (isAutostartActive && currentStepIndex === 0) {
      setCurrentSidePanelTab(TABS.processPath.key);
      clearFocusedField();
      setFocusedDocumentId('');
    }
  }, [isAutostartActive, currentStepIndex]);

  // const [parallelCountState, setParallelCountState] = useState(0);
  // const [conditionCountState, setConditionCountState] = useState(0);

  // useEffect(() => {
  //   const { parallelCount, conditionCount } = getParallelAndConditionStepsCount(parallelBranchesGroups, conditions);
  //   setParallelCountState(parallelCount);
  //   setConditionCountState(conditionCount);
  // }, [parallelBranchesGroups, conditions]);

  // useEffect(() => {
  //   if (!processInstructionOpened) {
  //     if (conditionCountState >= CONDITION_STEPS_LIMIT || parallelCountState >= PARALLEL_STEPS_LIMIT) {
  //       setShowProcessInstruction(true);
  //       setProcessInstructionOpened(true);
  //     }
  //   }
  // }, [processInstructionOpened, conditionCountState, parallelCountState]);

  useEffect(() => {
    if (!environment.isDocumentAdditionInTemplateProcessesAvailable) {
      delete TABS.documents;
    }
  }, []);

  useEffect(() => {
    if (currentStep?.parallel && focusedField) {
      const isRequiredParam = focusedField.field.params[DOCUMENT_DOCFLOW_FIELD_IS_REQUIRED_PARAM];

      if (isRequiredParam !== false) {
        handleStepFieldValuesChange(
          {
            ...focusedField.field,
            params: {
              ...focusedField.field.params,
              [DOCUMENT_DOCFLOW_FIELD_IS_REQUIRED_PARAM]: false,
            },
          },
          true
        );
      }
    }
  }, [currentStep?.parallel, focusedField, handleStepFieldValuesChange]);

  // const onSidePanelAction = () => setHowItWorksSidePanelOpen(!howItWorksSidePanelOpen);

  if (isReadonlyView) {
    return (
      <Grid item className={classes.dialogContentSidePanel}>
        <div className={cn(classes.tabContent, classes.tabContentReadonlyView)} {...draggableScrollEvents} ref={gridPathRef}>
          <ProcessStepList
            isReadonlyView={isReadonlyView}
            processIntegrations={processIntegrations}
            currentLanguage={currentLanguage}
            currentStepIndex={currentStepIndex}
            currentConditionId={currentConditionId}
            currentParallelBranchesGroupId={currentParallelBranchesGroupId}
            selectedItemType={selectedItemType}
            processSteps={processSteps}
            formErrors={formErrors}
            conditions={conditions}
            parallelBranchesGroups={parallelBranchesGroups}
            documents={documents}
            handleParallelStepsGroupSelect={handleParallelStepsGroupSelect}
            handleAddProcessConditionStep={handleAddProcessConditionStep}
            handleAddParallelStepsGroup={handleAddParallelStepsGroup}
            departmentsList={departmentsList}
            directManagerSteps={directManagerSteps}
            performerSelectionSteps={performerSelectionSteps}
            isAutostartActive={isAutostartActive}
            allProcessStepsFields={allProcessStepsFields}
            handleAddPreviousStep={handleAddPreviousStep}
            handleAddProcessStep={handleAddProcessStep}
            handleParallelBranchStepAdd={handleParallelBranchStepAdd}
            handleConditionBranchStepAdd={handleConditionBranchStepAdd}
            handleProcessSelect={handleProcessSelect}
            handleConditionSelect={handleConditionSelect}
            isEditDisabled={isEditDisabled}
            openStepsLimitReachedDialog={openStepsLimitReachedDialog}
            openBranchStepsLimitReachedDialog={openBranchStepsLimitReachedDialog}
          />
        </div>
      </Grid>
    );
  }

  // const conditionLimitTextClassNames = useMemo(() => cn({
  //   [classes.statusData]: conditionCountState < CONDITION_STEPS_LIMIT,
  //   [classes.statusDataVermilion]: conditionCountState > CONDITION_STEPS_LIMIT,
  // }), [conditionCountState]);

  // const parallelLimitTextClassNames = useMemo(() => cn({
  //   [classes.statusData]: parallelCountState < PARALLEL_STEPS_LIMIT,
  //   [classes.statusDataVermilion]: parallelCountState > PARALLEL_STEPS_LIMIT,
  // }), [parallelCountState]);

  return (
    <>
      <Grid item className={classes.dialogContentSidePanel}>
        <TabContext value={currentSidePanelTab}>
          <TabList
            onChange={(event, value) => {
              setCurrentSidePanelTab(value);
            }}
            className={classes.tabsList}
          >
            {Object.values(TABS).map((tab) => {
              const processStep = processSteps?.[currentStepIndex];
              const isConditionOrParallelBranch =
                tab.key === 'documents' && (processStep?.isConditionBranchStep || processStep?.isParallelBranchesGroupStep);

              let isTabDisabled, shouldShowTooltip, tooltipText;

              switch (tab.key) {
                case 'fieldsSettings':
                case 'documents':
                  isTabDisabled =
                    (isAutostartActive && currentStepIndex === 0 && tab.disabledOnAutostartStep) ||
                    (isEditDisabled && tab.disabledWhenEditDisabled) ||
                    (selectedItemType !== 'processStep' && tab.disabledOnNotStepSelected) ||
                    (isConditionOrParallelBranch && tab.disabledOnConditionBranchSteps) ||
                    (isTemplatePage && !tab.availableForTemplates);
                  shouldShowTooltip =
                    (isAutostartActive && currentStepIndex === 0 && tab.disabledOnAutostartStep) ||
                    (isConditionOrParallelBranch && tab.disabledOnConditionBranchSteps);
                  tooltipText =
                    isConditionOrParallelBranch && tab.disabledOnConditionBranchSteps
                      ? tab.disabledTooltipTextConditionBranches
                      : tab.disabledTooltipText;
                  break;
                case 'processPath':
                default:
                  break;
              }

              return (
                <Tab
                  style={{ pointerEvents: 'auto' }}
                  disabled={isTabDisabled}
                  label={
                    <Tooltip
                      arrow
                      placement="bottom"
                      disableHoverListener={!shouldShowTooltip}
                      disableTouchListener={!shouldShowTooltip}
                      disableFocusListener={!shouldShowTooltip}
                      title={t(tooltipText)}
                      classes={{
                        tooltipArrow: classes.tooltip,
                        arrow: classes.tooltipArrow,
                        tooltip: classes.fieldTooltip,
                      }}
                      leaveDelay={200}
                    >
                      <span>{t(tab.title)}</span>
                    </Tooltip>
                  }
                  value={tab.key}
                  icon={
                    <img
                      src={isTabDisabled ? tab.iconDisabled : currentSidePanelTab === tab.key ? tab.iconActive : tab.icon}
                      className={classes.tabIcon}
                    />
                  }
                  className={classes.tab}
                />
              );
            })}
          </TabList>

          <TabPanel value={TABS.processPath.key} className={classes.tabContent} {...draggableScrollEvents} ref={gridPathRef}>
            <ProcessStepList
              isAddConditionButtonAvailable={true} // conditionCountState < CONDITION_STEPS_LIMIT
              isAddParallelBranchesButtonAvailable={true} // parallelCountState < PARALLEL_STEPS_LIMIT
              isReadonlyView={isReadonlyView}
              processIntegrations={processIntegrations}
              currentLanguage={currentLanguage}
              currentStepIndex={currentStepIndex}
              currentConditionId={currentConditionId}
              currentParallelBranchesGroupId={currentParallelBranchesGroupId}
              selectedItemType={selectedItemType}
              processSteps={processSteps}
              formErrors={formErrors}
              conditions={conditions}
              parallelBranchesGroups={parallelBranchesGroups}
              documents={documents}
              handleParallelStepsGroupSelect={handleParallelStepsGroupSelect}
              handleAddProcessConditionStep={handleAddProcessConditionStep}
              handleAddParallelStepsGroup={handleAddParallelStepsGroup}
              departmentsList={departmentsList}
              directManagerSteps={directManagerSteps}
              performerSelectionSteps={performerSelectionSteps}
              isAutostartActive={isAutostartActive}
              allProcessStepsFields={allProcessStepsFields}
              handleAddPreviousStep={handleAddPreviousStep}
              handleAddProcessStep={handleAddProcessStep}
              handleParallelBranchStepAdd={handleParallelBranchStepAdd}
              handleConditionBranchStepAdd={handleConditionBranchStepAdd}
              handleProcessSelect={handleProcessSelect}
              handleConditionSelect={handleConditionSelect}
              isEditDisabled={isEditDisabled}
              openStepsLimitReachedDialog={openStepsLimitReachedDialog}
              openBranchStepsLimitReachedDialog={openBranchStepsLimitReachedDialog}
            />
          </TabPanel>

          <TabPanel value={TABS.fieldsSettings.key} className={cn(classes.tabContent, classes.tabContentLimitedHeight)}>
            {!focusedStepFieldId && (
              <>
                <Typography className={classes.fieldsTypesTitle}>{t('customProcesses.sidePanel.titles.fieldsTypes')}</Typography>

                <Typography className={classes.fieldsTypesDescription}>{t('customProcesses.fields.dragFields')}</Typography>

                <Droppable droppableId="ITEMS" isDropDisabled={true}>
                  {(provided, snapshot) => (
                    <div
                      className={classes.fieldsListWrapper}
                      ref={provided.innerRef}
                      // isDraggingOver={snapshot.isDraggingOver}
                    >
                      {FIELDS.filter((field) => !field.hiddenFromFieldsList).map((field, index) => {
                        const isFieldAdded =
                          field.isDisabledToAdd &&
                          processSteps.some((step) => step.fields.some((addedField) => addedField.component === field.key));

                        const isDragDisabled =
                          isFieldAdded ||
                          (field.isDisabledToAdd &&
                            (processSteps?.[currentStepIndex]?.isConditionBranchStep ||
                              processSteps?.[currentStepIndex]?.isParallelBranchesGroupStep));
                        return (
                          <Draggable key={field.key} draggableId={field.key} index={index} isDragDisabled={isDragDisabled}>
                            {(provided, snapshot) => (
                              <React.Fragment>
                                <Box
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  isDragging={snapshot.isDragging}
                                  style={provided.draggableProps.style}
                                >
                                  <FieldsListCard
                                    field={field}
                                    isDragged={snapshot.isDragging}
                                    disabled={isDragDisabled}
                                    selectedItemType={selectedItemType}
                                    isConditionStep={currentStep.isConditionBranchStep}
                                    isParallelStep={currentStep.isParallelBranchesGroupStep}
                                  />
                                </Box>

                                {snapshot.isDragging && (
                                  <FieldsListCard
                                    field={field}
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    className={classes.clone}
                                    selectedItemType={selectedItemType}
                                    isConditionStep={currentStep.isConditionBranchStep}
                                    isParallelStep={currentStep.isParallelBranchesGroupStep}
                                  />
                                )}
                              </React.Fragment>
                            )}
                          </Draggable>
                        );
                      })}
                    </div>
                  )}
                </Droppable>
              </>
            )}

            {!!focusedStepFieldId && (
              <>
                <Typography className={classes.fieldsTypesTitle}>{t('customProcesses.sidePanel.titles.fieldSettings')}</Typography>

                <Box className={classes.processParamsBlock}>
                  <Typography className={classes.fieldsParamsSubTitle}>{t('customProcesses.sidePanel.titles.general')}</Typography>

                  {[FIELD_TYPES.INTEGRATED, FIELD_TYPES.SYSTEM].includes(focusedField?.field?.type as FIELD_TYPES) && (
                    <>
                      {focusedField?.field?.type === FIELD_TYPES.SYSTEM && (
                        <div className={classes.fieldEditingInfo}>
                          <Trans i18nKey="customProcesses.fields.infoMessages.system">
                            Fields <span>from template</span> are required in a template process. They can be renamed or rearranged, but can
                            not deleted.
                          </Trans>
                        </div>
                      )}

                      {focusedField?.field?.type === FIELD_TYPES.INTEGRATED && (
                        <>
                          <div className={classes.fieldEditingInfo}>
                            <Trans i18nKey="customProcesses.fields.infoMessages.integrated_line1">
                              Fields <span>from template</span> are required in a template process. They can be renamed or rearranged, but
                              can not deleted.
                            </Trans>
                            <br />
                            <Trans i18nKey="customProcesses.fields.infoMessages.integrated_line2">
                              <span>Integrated fields</span> will be automatically added to the linked module once completed.
                            </Trans>
                          </div>

                          <Typography className={classes.optionLabel}>{t('customProcesses.sidePanel.form.integrationType')}</Typography>

                          <div className={classes.fieldIntegrations}>
                            {focusedField?.field?.integration?.split(';')?.map((integrationName) => {
                              const displayedValue = t('templates.integrations.' + integrationName.toLowerCase(), {
                                defaultValue: integrationName,
                              });
                              return <div className={classes.integrationChip}>{displayedValue}</div>;
                            })}
                          </div>
                        </>
                      )}
                    </>
                  )}
                  <Typography className={classes.optionLabel}>{t('customProcesses.sidePanel.form.component')}</Typography>

                  <Typography className={classes.fieldType}>
                    {t(focusedField?.fieldComponent.title)}
                    <Tooltip
                      arrow
                      classes={{
                        tooltip: classes.tooltipContent,
                      }}
                      placement="bottom"
                      title={t(focusedField?.fieldComponent.description)}
                      leaveDelay={200}
                    >
                      <img src={InfoIcon} className={classes.fieldTypeInfo} />
                    </Tooltip>
                  </Typography>
                </Box>

                {focusedField?.fieldComponent.key !== SUM_FIELD_KEY && focusedField?.fieldComponent.key !== DOCUMENT_DOCFLOW_FIELD_KEY && (
                  <>
                    <Box className={cn(classes.fieldWrapper)}>
                      <Typography className={cn(classes.sidePanelInfoText)}>
                        {t('customProcesses.sidePanel.form.valuesChangeInfoMessage')}
                      </Typography>
                    </Box>

                    <Box className={cn(classes.fieldWrapper, classes.limitedWidthFieldWrapper)}>
                      <Typography className={cn(classes.optionLabel, classes.optionLabelRequired)}>
                        {t('customProcesses.sidePanel.form.fieldName')}
                      </Typography>

                      <AppTextField
                        placeholder={t('customProcesses.sidePanel.form.fieldName')}
                        fullWidth
                        error={nameFieldError}
                        className={classes.textField}
                        helperText={
                          nameFieldError ? (
                            <div className={classes.errorMessage}>
                              <img src={WarningIcon} alt="warning" />
                              {Object.keys(focusedField?.field?.hint).length > 1
                                ? t('customProcesses.creationPage.processFormErrors.requiredFieldAllLanguages')
                                : t('customProcesses.creationPage.processFormErrors.requiredField')}
                            </div>
                          ) : null
                        }
                        value={focusedField?.field.hint[currentLanguage] || ''}
                        onChange={(event) => onStepFieldValuesChange('hint', event.target.value)}
                      />
                    </Box>
                  </>
                )}

                {focusedField?.fieldComponent.key !== ATTACHMENT_FIELD_KEY &&
                  focusedField?.fieldComponent.key !== SUM_FIELD_KEY && (
                  <Box className={cn(classes.fieldWrapper, classes.limitedWidthFieldWrapper)}>
                    <Typography className={cn(classes.optionLabel)}>{t('customProcesses.sidePanel.form.fieldPlaceholder')}</Typography>

                    <AppTextField
                      className={classes.textField}
                      error={hintPlaceholderIsOverLimit}
                      fullWidth
                      helperText={hintPlaceholderErrorTemplate}
                      onChange={(event) => onStepFieldValuesChange('placeholder', event.target.value)}
                      placeholder={t('customProcesses.sidePanel.form.fieldPlaceholderPlaceholder')}
                      value={focusedField?.field.placeholder[currentLanguage] || ''}
                    />
                  </Box>
                )}

                {focusedField?.fieldComponent.key === SYSTEM_GLOSSARY_FIELD_KEY && (
                  <Box className={cn(classes.fieldWrapper, classes.limitedWidthFieldWrapper)}>
                    <Typography className={cn(classes.optionLabel, classes.optionLabelRequired)}>
                      {t('customProcesses.sidePanel.form.systemGlossaryValue')}
                    </Typography>

                    <AppTextField
                      placeholder={t('customProcesses.sidePanel.form.systemGlossaryValue')}
                      fullWidth
                      disabled
                      InputProps={{ endAdornment: <img src={dropdownIndicatorIcon} /> }}
                      className={classes.textField}
                      value={t(SYSTEM_GLOSSARIES[(focusedField?.field?.params[SYSTEM_GLOSSARY_INDEX_NAME_PARAM] as string) || ''], {
                        defaultValue:
                          SYSTEM_GLOSSARIES_DEFAULT_NAMES[(focusedField?.field?.params[SYSTEM_GLOSSARY_INDEX_NAME_PARAM] as string) || ''],
                      })}
                    />
                  </Box>
                )}

                {focusedField?.fieldComponent.key === DOCUMENT_DOCFLOW_FIELD_KEY && (
                  <Box mb={8}>
                    {/* Disabled multiple select, docflowDocuments can only be singleSelectType for now, we will enable this feature later */}
                    {/* <Box mb={8}>
                    <Typography className={classes.fieldsParamsSubTitle}>
                      {t('customProcesses.fields.documentFromDocflow.selectionType')}
                    </Typography>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={Boolean(focusedField?.field.params[DOCUMENT_DOCFLOW_FIELD_MULTIPLE_SELECTION_PARAM])}
                      onChange={(_, value) => {
                        onStepFieldValuesChange('params.multipleSelection', value === 'true');
                      }}
                    >
                      <FormControlLabel
                        value={false}
                        control={<Radio
                          className={cn(classes.radioButton, {
                            [classes.radioButtonChecked]: !focusedField?.field.params[DOCUMENT_DOCFLOW_FIELD_MULTIPLE_SELECTION_PARAM],
                          })}
                        />}
                        label={t('customProcesses.fields.documentFromDocflow.selectionTypeSingle')}
                      />

                      <FormControlLabel
                        value={true}
                        control={<Radio
                          className={cn(classes.radioButton, {
                            [classes.radioButtonChecked]: focusedField?.field.params[DOCUMENT_DOCFLOW_FIELD_MULTIPLE_SELECTION_PARAM],
                          })}
                        />}
                        label={t('customProcesses.fields.documentFromDocflow.selectionTypeMulti')}
                      />
                    </RadioGroup>
                  </Box> */}
                    {!currentStep?.isParallelBranchesGroupStep && !currentStep?.isConditionBranchStep && (
                      <>
                        <Typography className={classes.fieldsParamsSubTitle}>
                          {t('customProcesses.fields.documentFromDocflow.signature')}
                        </Typography>
                        <FormControlLabel
                          className={classes.toggle}
                          control={
                            <Switch
                              color="default"
                              classes={{
                                track: Boolean(
                                  ((focusedField.field.params.signRequiredSteps as unknown) as number[])?.includes(currentStepIndex + 1)
                                )
                                  ? classes.switchTrackChecked
                                  : classes.switchTrackUnchecked,
                              }}
                              checked={Boolean(
                                ((focusedField.field.params.signRequiredSteps as unknown) as number[])?.includes(currentStepIndex + 1)
                                  ? true
                                  : false
                              )}
                              onChange={(event) => {
                                const currentStepOrder = currentStepIndex + 1;
                                let signRequiredSteps = Array.isArray(focusedField?.field?.params?.signRequiredSteps)
                                  ? focusedField.field.params.signRequiredSteps
                                  : [];
                                if (event.target.checked) {
                                  signRequiredSteps = [...signRequiredSteps, currentStepOrder];
                                } else {
                                  signRequiredSteps = [...signRequiredSteps.filter((i) => i !== currentStepOrder)];
                                }
                                onStepFieldValuesChange('params.signRequiredSteps', signRequiredSteps);
                              }}
                              disabled={
                                !!currentStep?.parallel ||
                                focusedField?.field?.isHidden ||
                                directManagerSteps?.length > 0 ||
                                currentStep?.assigneeId?.length > 1 ||
                                performerSelectionSteps.includes(currentStep?.stepOrder - 1)
                              }
                            />
                          }
                          label={t('customProcesses.fields.documentFromDocflow.signatureRequired')}
                        />
                      </>
                    )}
                  </Box>
                )}

                {focusedField?.fieldComponent.key === SUM_FIELD_KEY && (
                  <>
                    <Box className={cn(classes.fieldWrapper, classes.limitedWidthFieldWrapper)}>
                      <Typography className={cn(classes.optionLabel, classes.optionLabelRequired)}>
                        {t('customProcesses.sidePanel.form.fieldName')}
                      </Typography>

                      <AppTextField
                        placeholder={t('customProcesses.sidePanel.form.fieldName')}
                        fullWidth
                        error={nameFieldError}
                        className={classes.textField}
                        helperText={
                          nameFieldError ? (
                            <div className={classes.errorMessage}>
                              <img src={WarningIcon} alt="warning" />
                              {Object.keys(focusedField?.field?.hint).length > 1
                                ? t('customProcesses.creationPage.processFormErrors.requiredFieldAllLanguages')
                                : t('customProcesses.creationPage.processFormErrors.requiredField')}
                            </div>
                          ) : null
                        }
                        value={focusedField?.field.hint[currentLanguage] || ''}
                        onChange={(event) => onStepFieldValuesChange('hint', event.target.value)}
                      />
                    </Box>
                  </>
                )}

                {isTemplatePage && [GLOSSARY_MULTI_SELECT_FIELD_KEY, GLOSSARY_FIELD_KEY].includes(focusedField?.fieldComponent.key) && (
                  <Box className={cn(classes.fieldWrapper, classes.limitedWidthFieldWrapper)}>
                    <Typography className={cn(classes.optionLabel, classes.optionLabelRequired)}>
                      {t('customProcesses.sidePanel.form.directoryValue')}
                    </Typography>

                    <AppTextField
                      placeholder={t('customProcesses.sidePanel.form.directoryValue')}
                      fullWidth
                      className={classes.textField}
                      helperText={
                        directoryPathError ? (
                          <div className={classes.errorMessage}>
                            <img src={WarningIcon} alt="warning" />
                            {t('customProcesses.creationPage.processFormErrors.requiredField')}
                          </div>
                        ) : null
                      }
                      error={directoryPathError && !focusedField?.field?.params[GLOSSARY_FIELD_DIRECTORY_CODE_PARAM]}
                      value={focusedField?.field?.params[GLOSSARY_FIELD_DIRECTORY_CODE_PARAM] || ''}
                      onChange={(event) => onStepFieldValuesChange('params.' + GLOSSARY_FIELD_DIRECTORY_CODE_PARAM, event.target.value)}
                    />
                  </Box>
                )}
                {!isTemplatePage && [GLOSSARY_MULTI_SELECT_FIELD_KEY, GLOSSARY_FIELD_KEY].includes(focusedField?.fieldComponent.key) && (
                  <Box className={cn(classes.fieldWrapper, classes.limitedWidthFieldWrapper)}>
                    <Typography
                      className={cn(classes.optionLabel, {
                        [classes.optionLabelRequired]: true,
                      })}
                    >
                      {t('customProcesses.sidePanel.form.directoryValue')}
                    </Typography>

                    <TreeGlossary
                      params={{
                        showAllGlossaries: true,
                        directoryCode: focusedField?.field?.params[GLOSSARY_FIELD_DIRECTORY_CODE_PARAM],
                      }}
                      isDisabled={[FIELD_TYPES.INTEGRATED, FIELD_TYPES.SYSTEM].includes(focusedField?.field?.type as FIELD_TYPES)}
                      onSelect={(selectedItem) => {
                        const parentDirectoryId = selectedItem?.dictionaryValueIds?.length
                          ? selectedItem?.dictionaryValueIds[0]
                          : selectedItem?.directoryId
                          ? selectedItem.directoryId
                          : 'parent';
                        onStepFieldValuesChange(
                          'params.directoryPath',
                          selectedItem.firstLevel ? selectedItem.id : `${parentDirectoryId}/${selectedItem.id}`
                        );
                      }}
                      name={GLOSSARY_FIELD_DIRECTORY_PARAM}
                      placeholder={t('form_components.select.placeholder')}
                      value={focusedField?.field?.params[GLOSSARY_FIELD_DIRECTORY_PARAM]}
                      valueId={
                        (focusedField?.field?.params[GLOSSARY_FIELD_DIRECTORY_PARAM] as string)?.includes('/')
                          ? (focusedField?.field?.params[GLOSSARY_FIELD_DIRECTORY_PARAM] as string)?.split('/').pop()
                          : (focusedField?.field?.params[GLOSSARY_FIELD_DIRECTORY_PARAM] as string)
                      }
                      errors={
                        directoryPathError && !focusedField?.field?.params[GLOSSARY_FIELD_DIRECTORY_PARAM]
                          ? { [GLOSSARY_FIELD_DIRECTORY_PARAM]: true }
                          : {}
                      }
                      disabledItemsIds={allUsedGlossaries}
                      showErrorText={false}
                      control={null}
                      withoutFormController
                      isBuilderVariant
                    />
                    {directoryPathError && !focusedField?.field?.params[GLOSSARY_FIELD_DIRECTORY_PARAM] ? (
                      <Box className={classes.errorMessage} mt={0.5}>
                        <img src={WarningIcon} alt="warning" />
                        {t('customProcesses.creationPage.processFormErrors.requiredField')}
                      </Box>
                    ) : null}
                  </Box>
                )}

                <Box className={classes.buttonsWrapper}>
                  <Button className={classes.button} color="secondary" variant="contained" onClick={handleCancel}>
                    {t('comment_modal.button_cancel')}
                  </Button>
                  <Button className={classes.button} color="primary" variant="contained" onClick={handleSave}>
                    {t('buttons.button_save')}
                  </Button>
                </Box>
              </>
            )}
          </TabPanel>

          {environment.isDocumentAdditionInTemplateProcessesAvailable && !isTemplatePage && (
            <TabPanel value={TABS.documents.key} className={classes.tabContent}>
              <DocumentsTab
                processDocuments={documents}
                focusedDocumentId={focusedDocumentId}
                setFocusedDocumentId={setFocusedDocumentId}
                parallelBranchesGroups={parallelBranchesGroups}
                handleDocumentFieldsAdd={handleDocumentFieldsAdd}
                handleDocumentParamsChange={handleDocumentParamsChange}
                handleDocumentChangeUndo={handleDocumentChangeUndo}
                handleDocumentPreviewOpen={handleDocumentPreviewOpen}
                handleDocumentSignatureStepRemovalDialogOpen={handleDocumentSignatureStepRemovalDialogOpen}
                allProcessStepsFields={allProcessStepsFields}
                currentLanguage={currentLanguage}
                currentProcessStepOrder={currentStepIndex + 1}
                currentStep={processSteps[currentStepIndex]}
                formErrors={formErrors}
              />
            </TabPanel>
          )}
        </TabContext>
      </Grid>
    </>
  );
};
