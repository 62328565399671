import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  content: {
    '& .MuiBox-root': {
      margin: theme.spacing(1, 0)
    },
    padding: theme.spacing(4, 0, 2),
  }
}));

export default useStyles;
