export const CONDITION_TYPES = {
  'singleLineText': [
    {
      key: 'contains',
      label: 'customProcesses.creationForm.conditionForm.conditions.contains',
      template: 'customProcesses.creationPage.conditionTemplates.contains',
      templatePdf: 'customProcesses.creationPage.conditionTemplatesNoTags.contains',
    },
    {
      key: 'notContains',
      label: 'customProcesses.creationForm.conditionForm.conditions.notContains',
      template: 'customProcesses.creationPage.conditionTemplates.notContains',
      templatePdf: 'customProcesses.creationPage.conditionTemplatesNoTags.notContains',
    },
    {
      key: 'equalTo',
      label: 'customProcesses.creationForm.conditionForm.conditions.equalTo',
      template: 'customProcesses.creationPage.conditionTemplates.equalTo',
      templatePdf: 'customProcesses.creationPage.conditionTemplatesNoTags.equalTo',
    },
    {
      key: 'notEqualTo',
      label: 'customProcesses.creationForm.conditionForm.conditions.notEqualTo',
      template: 'customProcesses.creationPage.conditionTemplates.notEqualTo',
      templatePdf: 'customProcesses.creationPage.conditionTemplatesNoTags.notEqualTo',
    },
  ],
  'multiLineText': [
    {
      key: 'contains',
      label: 'customProcesses.creationForm.conditionForm.conditions.contains',
      template: 'customProcesses.creationPage.conditionTemplates.contains',
      templatePdf: 'customProcesses.creationPage.conditionTemplatesNoTags.contains',
    },
    {
      key: 'notContains',
      label: 'customProcesses.creationForm.conditionForm.conditions.notContains',
      template: 'customProcesses.creationPage.conditionTemplates.notContains',
      templatePdf: 'customProcesses.creationPage.conditionTemplatesNoTags.notContains',
    },
    {
      key: 'equalTo',
      label: 'customProcesses.creationForm.conditionForm.conditions.equalTo',
      template: 'customProcesses.creationPage.conditionTemplates.equalTo',
      templatePdf: 'customProcesses.creationPage.conditionTemplatesNoTags.equalTo',
    },
    {
      key: 'notEqualTo',
      label: 'customProcesses.creationForm.conditionForm.conditions.notEqualTo',
      template: 'customProcesses.creationPage.conditionTemplates.notEqualTo',
      templatePdf: 'customProcesses.creationPage.conditionTemplatesNoTags.notEqualTo',
    },
  ],
  'glossaryMultiSelect': [
    {
      key: 'contains',
      label: 'customProcesses.creationForm.conditionForm.conditions.contains',
      template: 'customProcesses.creationPage.conditionTemplates.contains',
      templatePdf: 'customProcesses.creationPage.conditionTemplatesNoTags.contains',
    },
    {
      key: 'notContains',
      label: 'customProcesses.creationForm.conditionForm.conditions.notContains',
      template: 'customProcesses.creationPage.conditionTemplates.notContains',
      templatePdf: 'customProcesses.creationPage.conditionTemplatesNoTags.notContains',
    },
    {
      key: 'equalTo',
      label: 'customProcesses.creationForm.conditionForm.conditions.equalTo',
      template: 'customProcesses.creationPage.conditionTemplates.equalTo',
      templatePdf: 'customProcesses.creationPage.conditionTemplatesNoTags.equalTo',
    },
    {
      key: 'notEqualTo',
      label: 'customProcesses.creationForm.conditionForm.conditions.notEqualTo',
      template: 'customProcesses.creationPage.conditionTemplates.notEqualTo',
      templatePdf: 'customProcesses.creationPage.conditionTemplatesNoTags.notEqualTo',
    },
  ],
  'currency': [
    {
      key: 'equalTo',
      label: 'customProcesses.creationForm.conditionForm.conditions.equalTo',
      template: 'customProcesses.creationPage.conditionTemplates.equalTo',
      templatePdf: 'customProcesses.creationPage.conditionTemplatesNoTags.equalTo',
    },
    {
      key: 'notEqualTo',
      label: 'customProcesses.creationForm.conditionForm.conditions.notEqualTo',
      template: 'customProcesses.creationPage.conditionTemplates.notEqualTo',
      templatePdf: 'customProcesses.creationPage.conditionTemplatesNoTags.notEqualTo',
    },
    {
      key: 'greaterThan',
      label: 'customProcesses.creationForm.conditionForm.conditions.greaterThan',
      template: 'customProcesses.creationPage.conditionTemplates.greaterThan',
      templatePdf: 'customProcesses.creationPage.conditionTemplatesNoTags.greaterThan',
    },
    {
      key: 'lessThan',
      label: 'customProcesses.creationForm.conditionForm.conditions.lessThan',
      template: 'customProcesses.creationPage.conditionTemplates.lessThan',
      templatePdf: 'customProcesses.creationPage.conditionTemplatesNoTags.lessThan',
    },
  ],
  'dateLineArea': [
    {
      key: 'equalTo',
      label: 'customProcesses.creationForm.conditionForm.conditions.isExactDate',
      template: 'customProcesses.creationPage.conditionTemplates.exactDate',
      templatePdf: 'customProcesses.creationPage.conditionTemplatesNoTags.exactDate',
    },
    {
      key: 'notEqualTo',
      label: 'customProcesses.creationForm.conditionForm.conditions.isNotExactDate',
      template: 'customProcesses.creationPage.conditionTemplates.notExactDate',
      templatePdf: 'customProcesses.creationPage.conditionTemplatesNoTags.notExactDate',
    },
    {
      key: 'greaterThan',
      label: 'customProcesses.creationForm.conditionForm.conditions.isAfterExactDate',
      template: 'customProcesses.creationPage.conditionTemplates.afterExactDate',
      templatePdf: 'customProcesses.creationPage.conditionTemplatesNoTags.afterExactDate',
    },
    {
      key: 'lessThan',
      label: 'customProcesses.creationForm.conditionForm.conditions.isBeforeExactDate',
      template: 'customProcesses.creationPage.conditionTemplates.beforeExactDate',
      templatePdf: 'customProcesses.creationPage.conditionTemplatesNoTags.beforeExactDate',
    },
  ],
  'glossary': [
    {
      key: 'equalTo',
      label: 'customProcesses.creationForm.conditionForm.conditions.equalTo',
      template: 'customProcesses.creationPage.conditionTemplates.equalTo',
      templatePdf: 'customProcesses.creationPage.conditionTemplatesNoTags.equalTo',
    },
    {
      key: 'notEqualTo',
      label: 'customProcesses.creationForm.conditionForm.conditions.notEqualTo',
      template: 'customProcesses.creationPage.conditionTemplates.notEqualTo',
      templatePdf: 'customProcesses.creationPage.conditionTemplatesNoTags.notEqualTo',
    },
  ],
  'userList': [
    {
      key: 'contains',
      label: 'customProcesses.creationForm.conditionForm.conditions.contains',
      template: 'customProcesses.creationPage.conditionTemplates.contains',
      templatePdf: 'customProcesses.creationPage.conditionTemplatesNoTags.contains',
    },
    {
      key: 'notContains',
      label: 'customProcesses.creationForm.conditionForm.conditions.notContains',
      template: 'customProcesses.creationPage.conditionTemplates.notContains',
      templatePdf: 'customProcesses.creationPage.conditionTemplatesNoTags.notContains',
    },
    {
      key: 'equalTo',
      label: 'customProcesses.creationForm.conditionForm.conditions.equalTo',
      template: 'customProcesses.creationPage.conditionTemplates.equalTo',
      templatePdf: 'customProcesses.creationPage.conditionTemplatesNoTags.equalTo',
    },
    {
      key: 'notEqualTo',
      label: 'customProcesses.creationForm.conditionForm.conditions.notEqualTo',
      template: 'customProcesses.creationPage.conditionTemplates.notEqualTo',
      templatePdf: 'customProcesses.creationPage.conditionTemplatesNoTags.notEqualTo',
    },
  ],
  'systemGlossary': [
    {
      key: 'equalTo',
      label: 'customProcesses.creationForm.conditionForm.conditions.equalTo',
      template: 'customProcesses.creationPage.conditionTemplates.equalTo',
      templatePdf: 'customProcesses.creationPage.conditionTemplatesNoTags.equalTo',
    },
    {
      key: 'notEqualTo',
      label: 'customProcesses.creationForm.conditionForm.conditions.notEqualTo',
      template: 'customProcesses.creationPage.conditionTemplates.notEqualTo',
      templatePdf: 'customProcesses.creationPage.conditionTemplatesNoTags.notEqualTo',
    },
  ],
};
