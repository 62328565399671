export enum PageType {
  REQUESTS = 'requests',
  APPROVALS = 'approvals',
  MONITORING = 'monitoring',
  SEARCH = 'search'
}

export type LegalEntity = {
  id: string;
  bin: string;
  name: string;
  shortName: string;
}
