import styled from 'styled-components';

const userListClassName = 'userList';

const UserList = styled.div`
  .${userListClassName} {
    align-items: flex-start;
    background-color: #FFFFFF;
    border-radius: 10px;
    border: 1px solid rgba(38, 40, 66, 0.08);
    top: 50px;
    box-shadow: 0px 8px 16px 0px rgba(38, 40, 66, 0.04);
    display: flex;
    flex-direction: column;
    font-size: 14px;
    justify-content: flex-start;
    position: absolute;
    right: 0;
    z-index: 2001;

    &__item, &__header {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: 4;
      justify-content: flex-start;
      padding: 12px 8px;
      width: 280px;
      img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }
      span {
        margin-left: 8px;
      }
    }

    &__header {
      color: rgba(109, 110, 133, 1);
    }

    &.readonly {
      box-shadow: none;
      border-radius: 0;
      border: none;
      position: relative;
      top: 0;
      z-index: 1;

      .${userListClassName}__item:first-child {
        padding-top: 0;
      }
    }

  }
`;

export const Styled = {
  UserList
};

