import React, { useMemo, useState, useEffect } from 'react';
import path from 'path';
import { Button, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { OfferDocument } from './OfferDocument';

import { useUsersState } from 'store/users';
import { useTaskState } from 'store/requests';
import { generateOfferFields, savePDFAsFile } from './generateOfferPDF';

type Props = {
  loading: boolean
}

export const JobOffer = ({ loading }: Props) => {
  const { t } = useTranslation();
  const { data: bpmTask } = useTaskState();
  const { users } = useUsersState();

  const [PDFImg, setPDFImg] = useState({ logoImg: null, stampImg: null });
  const [isLoading, setIsLoading] = useState(false);

  const [fields, setFields] = useState({});

  const getFields = async () => {
    if (bpmTask) {
      const generatedFields = await generateOfferFields(
        bpmTask.attributes,
        bpmTask.createdDate,
        users,
        bpmTask.values.serviceContract,
        bpmTask.values.companyId
      );
      setFields(generatedFields);
    }
  };

  useEffect(() => {
    getFields();
  }, [bpmTask, users]);

  useEffect(() => {
    const logoImg = new Image();
    logoImg.src = path.resolve('/assets/images/logo.jpg');

    const stampImg = new Image();
    stampImg.src = path.resolve('/assets/images/stamp.jpg');

    setPDFImg({ logoImg, stampImg });
  }, []);

  const generateOfferPDF = () => {
    setIsLoading(true);
    setTimeout(() => {
      savePDFAsFile(fields).then(() => {
        setIsLoading(false);
      });
    }, 1000);
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return (
    <Box mb={2}>
      <Box mb={2}>
        <Button
          type="button"
          variant="contained"
          onClick={generateOfferPDF}
          disabled={isLoading}
          color="primary"
        >
          {isLoading ? t('form_components.jobOffer.loading') : t('form_components.jobOffer.downloadPdf')}
        </Button>
      </Box>

      <OfferDocument fields={fields} />
    </Box>
  );
};
