import { handleActions } from 'redux-actions';

import { USER_ACTIONS } from './users.actions';
import { FULFILLED } from 'store/constants';

import { User } from 'models/User.model';
import { HcmsUserModel, UserType, UserTypeKeyValue } from 'types';

type StateType = {
  users: UserTypeKeyValue,
  oldUsersList: any[],
  positions: {
    [key: string]: any;
  }
}

export const initState: StateType = {
  users: {},
  oldUsersList: [],
  positions: {},
};

const propagateUsersList = (payload) => {
  return Array.isArray(payload) ?
    payload
      .map((data: HcmsUserModel) => User.from(data))
      .filter((user: User) => user.isValid())
      .map((user: User) => user.data())
      .reduce((acc, user: UserType) => ({ ...acc, [user.id]: { ...user } }), {}) :
    {}
};

export default handleActions({
  [USER_ACTIONS.HCMS_USERS_LIST + FULFILLED]: (state: StateType, { payload }) => ({
    ...state, users: propagateUsersList(payload)
  }),
  [USER_ACTIONS.OLD_ID_USERS_LIST + FULFILLED]: (state: StateType, { payload }) => {
    return {
      ...state,
      oldUsersList: Array.isArray(payload) ? payload.map(user => {
        return {
          ...user,
          fullName: user.displayName,
          avatar: user.photoUrl
        }
      }) : []
    }
  },
  [USER_ACTIONS.USERS_POSITIONS_LIST + FULFILLED]: (state: StateType, { payload }) => ({
    ...state,
    positions: Array.isArray(payload) ?
    payload
      .reduce((acc, position: any) => ({ ...acc, [position.id]: { ...position } }), {}) :
    {}
  }),
}, initState);

