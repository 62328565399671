import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { AppTextField } from 'components';

import useStepExecutionTimeFieldStyles from './StepExecutionTimeField.useStyles';
import { ProcessStep } from '../../TemplateProcesses.types';

import WarningIcon from 'assets/images/icons/warning-sign.svg';

interface StepExecutionTimeFieldProps {
  currentStep: ProcessStep;
  register: any;
  trigger: any;
  errors: any;
  clearErrors: any;
  hasWorkCalendar: boolean;
  isEditDisabled?: boolean;
  handleStepExecutionTimeChange: (value: string) => void;
}

export const StepExecutionTimeField = ({
  currentStep,
  register,
  trigger,
  errors,
  clearErrors,
  hasWorkCalendar,
  isEditDisabled = false,
  handleStepExecutionTimeChange,
}: StepExecutionTimeFieldProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useStepExecutionTimeFieldStyles();

  return (
    <AppTextField
      {...register(`processSteps[${currentStep?.stepOrder - 1}].timer`, {
        max: {
          value: 999,
          message: t('customProcesses.processForm.timeMaxValueError'),
        },
      })}
      size="medium"
      label={<span className={classes.label}>
        {hasWorkCalendar
         ? t('customProcesses.processForm.fields.workingHours')
         : t('customProcesses.processForm.fields.hours')
        }
      </span>
      }
      value={currentStep?.timer}
      placeholder={t('customProcesses.enterHours')}
      disabled={isEditDisabled}
      defaultValue={currentStep?.timer}
      className={classes.executionTimeInput}
      onChange={event => {
        const { value } = event.target;

        if (value.length === 4) {
          handleStepExecutionTimeChange(value.slice(0, value.length - 1));
        } else {
          handleStepExecutionTimeChange(value);
        }
      }}
      error={!!errors?.[currentStep?.stepOrder - 1]?.timer}
      helperText={
        !!errors?.[currentStep?.stepOrder - 1]?.timer
        ? <div className={classes.processStepFormErrorMessage}>
          <img src={WarningIcon} alt="warning"/>
          <span>{t('customProcesses.creationPage.processFormErrors.requiredField')}</span>
        </div>
        : null
      }
    />
  );
};
