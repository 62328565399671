import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    // flexWrap: 'wrap',
    gap: 12,
  },
  taskDetailsVariant: {
    width: '100%',
  },
  bothSidesButtonsVariant: {
    width: '100%',
  },
  leftSide: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 12,
  },
  rightSide: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 12,
  },
  button: {
    height: 40,
    padding: spacing(1, 4),
  },
  buttonWhite: {
    backgroundColor: palette.grey[50],
    borderColor: '#CED7DF',
    color: palette.grey[700],
    '&:hover': {
      backgroundColor: palette.grey[100],
      borderColor: '#CED7DF',
    },
  },
  icon: {
    height: 20,
    width: 20,
  },
  iconTextVariant: {
    marginLeft: 3.5,
  },
  iconButton: {
    width: 40,
    minWidth: 40,
    '& $icon': {
      width: 20,
      minWidth: 20,
      height: 20,
      minHeight: 20,
      margin: 0,
      marginRight: 0,
    },
  },
  tooltip: {
    padding: '8px 12px',
    borderRadius: 8,
  },
  normalButtonWithIcon: {
    padding: '0px 12px 0px 8px',
  },
  labelMargin: {
    marginLeft: 6,
  },
  blackIcon: {
    fontSize: 20,
    '& > path': {
      fill: '#262842',
    },
  },
  whiteIcon: {
    fontSize: 20,
    '& > path': {
      fill: '#fff',
    },
  },
}));

export default useStyles;
