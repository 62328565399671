import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import { useDarkOverlayState } from 'store/requests';

import useStyles from './useStyles';
import { Box } from '@mui/material';

export const DarkOverlay = () => {
  const classes = useStyles();
  const [highPosition, setHighPosition] = useState(false);

  const visible = useDarkOverlayState();

  useEffect(() => {
    if (visible) {
      //move higher when overlay is set to visible
      setHighPosition(true);
    } else {
      //move lower after animation is finished
      setTimeout(() => {
        setHighPosition(false);
      }, 400);
    }
  }, [visible, setHighPosition]);

  return (
    <Box
      className={cn(classes.darkOverlay, {
        [classes.darkOverlayShown]: visible,
        [classes.darkOverlayHidden]: !visible,
        [classes.darkOverlayHigh]: highPosition,
      })}
    />
  );
};
