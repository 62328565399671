import React, { memo, useMemo, useRef, useEffect, ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ApprovalsList, ApprovalsControls, TButton } from 'pages/Approvals';
import { Spinner, ErrorMessage, NoContentMessage } from 'components';
import { useSelectedApproval, useVisiblePanel } from 'store/approvals';
import MonitoringList from './MonitoringList';
import useApprovals from './hooks/useApprovals';

import { ReactComponent as RejectIcon } from 'assets/images/icons/mass-action-reject.svg';
import { ReactComponent as ApproveIcon } from 'assets/images/icons/mass-action-approve.svg';

import useStyles from './Appovals.useStyles';

interface ApprovalsResultsProps {
  reviewed: boolean;
  completed: boolean;
}

const ApprovalsResults = memo(
  ({ reviewed, completed }: ApprovalsResultsProps): ReactElement => {
    const { t } = useTranslation();
    const visiblePanel = useVisiblePanel();
    const prevReviewed = useRef(reviewed);
    const { pathname } = useLocation();
    const isMonitoringSection = useMemo(
      () => (pathname.match(/\/monitoring/gm) ?? []).length > 0,
      [pathname]
    );
    const {
      allAvailableTickets,
      clearSelectedTickets,
      currentPage,
      error,
      handlePageChange,
      handlePageSizeChange,
      handleTaskSelection,
      isAllSelected,
      loading,
      massLoading,
      onClick,
      pageSize,
      requests,
      selectAllTickets,
      selectedTickets,
      selectTickets,
      setMassApprove,
      showControls,
      totalElements,
      totalPages,
    } = useApprovals({
      reviewed,
      previousReviewed: prevReviewed.current,
      completed,
    });

    useEffect(() => {
      if (reviewed !== prevReviewed.current) {
        prevReviewed.current = reviewed;
      }
    }, [reviewed]);

    const selectedApprovalId = useSelectedApproval();
    const classes = useStyles();

    const buttonsList: TButton[] = useMemo(
      () => [
        {
          title: t('buttons.button_reject'),
          action: 'Reject',
          color: 'secondary',
          icon: <RejectIcon />,
          requireComment: true,
        },
        {
          title: t('buttons.button_approve'),
          action: 'Approve',
          color: 'secondary',
          icon: <ApproveIcon />,
        },
      ],
      []
    );

    if (loading) {
      return <Spinner />;
    }

    if (error) {
      return <ErrorMessage text={t(error)} />;
    }

    if (requests?.length) {
      if (isMonitoringSection) {
        return (
          <MonitoringList
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            handlePageSizeChange={handlePageSizeChange}
            handleTaskSelection={handleTaskSelection}
            list={requests}
            pageSize={pageSize}
            totalElements={totalElements}
            totalPages={totalPages}
          />
        );
      }

      return (
        <>
          {visiblePanel && (
            <div className={classes.massActionsPanelWrapper}>
              <ApprovalsControls
                availableTicketsCount={allAvailableTickets?.length}
                buttonList={buttonsList}
                clearSelectedTickets={clearSelectedTickets}
                isAllSelected={isAllSelected}
                isControlsShown={showControls}
                isDisabled={loading}
                loading={massLoading}
                onClick={(action, comment) => onClick({ action, comment })}
                selectAllTickets={selectAllTickets}
                selected={selectedTickets}
                selectTickets={selectTickets}
                type="approvals"
              />
            </div>
          )}
          <ApprovalsList
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            handlePageSizeChange={handlePageSizeChange}
            handleTaskSelection={handleTaskSelection}
            list={requests}
            pageSize={pageSize}
            reviewed={reviewed}
            selected={selectedTickets}
            selectedTaskId={selectedApprovalId}
            selectTickets={selectTickets}
            setMassApprove={setMassApprove}
            totalElements={totalElements}
            totalPages={totalPages}
          />
        </>
      );
    }

    if (isMonitoringSection) {
      return (
        <div className={classes.emptyBox}>
          <NoContentMessage
            message={t('Watchers.no_processes_to_monitor')}
            additionalMessage={t(
              'Watchers.no_processes_to_monitor_description'
            )}
          />
        </div>
      );
    }

    return (
      <div className={classes.emptyBox}>
        <NoContentMessage
          message={t('Processes.no_approvals')}
          additionalMessage={t('Processes.no_approvals_description')}
        />
      </div>
    );
  }
);

export default ApprovalsResults;
