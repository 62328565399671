import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ palette, spacing }) => ({
  button: {
    height: 40,
    padding: '0px 12px 0px 8px',
    flexShrink: 0,
    borderRadius: 10,
    '& img': {
      marginRight: 6,
    },
  },
}));
