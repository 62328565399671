import { useMemo } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';

import { useTaskState } from 'store/requests';
import { useHoldingTranslationsLoadedState } from 'store/main';

import { parseJSON } from 'utils/general';

import { TaskParametersType } from 'types';

const PARAMS_LOCALIZATION_EXCEPTIONS = ['values', 'possibleResults'];
const PLACEHOLDER_PARAM = 'placeholder';

export const useField = (attribute: TaskParametersType, entitySysName?: string, stepSysName?: string) => {
  const { t, i18n } = useTranslation();
  const { data: bpmTask } = useTaskState();
  const holdingTranslationsLoaded = useHoldingTranslationsLoadedState();

  const translationEntitySysName = useMemo(() =>
    entitySysName || bpmTask.processSysName,
    [entitySysName, bpmTask]);

  const translationStepSysName = useMemo(() =>
    stepSysName || bpmTask.stepSysName,
    [stepSysName, bpmTask]);

  const hint = useMemo(() => {
    const hintTranslationKey = `constructor-${translationEntitySysName}.states.${translationStepSysName}.attributes.${attribute.sysName.replaceAll('::', '-')}.hint`;
    const hintTranslationNewFormatKey = `constructor-${translationEntitySysName}.attributes.${attribute.sysName.replaceAll('::', '-')}.hint`;
    return t(hintTranslationKey, {defaultValue: t(hintTranslationNewFormatKey, {defaultValue: attribute.hint})});
  }, [attribute, translationEntitySysName, holdingTranslationsLoaded])

  const params = useMemo(() => {
      const paramsObject = parseJSON(attribute.componentParams);
      const paramsTranslationKeyPrefix = `states.${translationStepSysName}.attributes.${attribute.sysName.replaceAll('::', '-')}.params.`;
      const placeholderParamOldKey = `states.${translationStepSysName}.attributes.${attribute.sysName.replaceAll('::', '-')}.params.${PLACEHOLDER_PARAM}`;
      const placeholderParamKey = `attributes.${attribute.sysName.replaceAll('::', '-')}.params.${PLACEHOLDER_PARAM}`;
      const placeholderParamKey2 = `attributes.${attribute.sysName.replaceAll('::', '-')}.${PLACEHOLDER_PARAM}`;
      const translations = (i18n.store?.data[i18n.language]?.processesV2 || {}) as {[key: string]: string};
      const translationsDefault = (i18n.store?.data['en']?.processesV2 || {}) as {[key: string]: string};

      const combinedTranslations = {
        ...translationsDefault,
        ...translations
      }

      if (!combinedTranslations) {
        return paramsObject;
      }

      const processTranslations = combinedTranslations[`constructor-${translationEntitySysName}`];
      if (processTranslations) {
        const translatedParams = Object.keys(processTranslations)?.reduce((acc, key) => {
          if ((key.startsWith(paramsTranslationKeyPrefix) || key === placeholderParamKey || key === placeholderParamKey2) && !PARAMS_LOCALIZATION_EXCEPTIONS.includes(key.replace(paramsTranslationKeyPrefix, ''))) {
            if (key.endsWith(PLACEHOLDER_PARAM)) {
              return {
                ...acc,
                [PLACEHOLDER_PARAM]: processTranslations[placeholderParamOldKey] || processTranslations[placeholderParamKey] || processTranslations[placeholderParamKey2] || paramsObject?.placeholder || '',
              }
            } else {
              return {
                ...acc,
                [key.replace(paramsTranslationKeyPrefix, '')]: processTranslations[key],
              };
            }
          }
          return acc;
        }, {});

        return {
          ...paramsObject,
          ...translatedParams,
        };
      }

      return paramsObject;
    },
    [attribute.componentParams, translationEntitySysName, holdingTranslationsLoaded]);

  const options = useMemo(() =>
    params.values ?
      params.values.split(',').sort() :
      [],
    [params.values]);

  const localizedOptions = useMemo(() => {
    const valuesLocalizationKey = `constructor-${translationEntitySysName}.states.${translationStepSysName}.attributes.${attribute.sysName.replaceAll('::', '-')}.params.values`;
    const localizedValues = t(valuesLocalizationKey, {defaultValue: params.values});
    if (!params.values || !localizedValues) {
      return {};
    }

    const valuesArray = params.values.split(',');
    const localizedValuesArray = localizedValues.split(',');

    return valuesArray.map((value, index) => ({
      value,
      label: localizedValuesArray[index]
    })).sort((a,b) => a.label > b.label ? 1 : -1);
  }, [params.values]);

  const localizedPossibleResults = useMemo(() => {
    const optionsLocalizationKey = `constructor-${translationEntitySysName}.states.${translationStepSysName}.attributes.${attribute.sysName.replaceAll('::', '-')}.params.possibleResults`;
    return t(optionsLocalizationKey, {defaultValue: ''});
  }, [params.possibleResults]);

  const rules = useMemo(() => {
    if (Array.isArray(attribute.bsnRules)) {
      const rules: {[key: string]: string} = {};

      attribute.bsnRules.forEach(({ type }) => {
        if (type === 'REQUIRED') {
          rules.required =
            attribute.component === 'file' ?
              t("form_components.required_errors.file") :
              t("form_components.required_errors.default")
        }
      });

      return rules;
    }

    return {};
  }, [attribute.component, attribute.bsnRules, translationEntitySysName]);

  const masterField = useMemo(() => {
    if (bpmTask && params.parameterName && params.parameterAttribute) {
      const attribute = bpmTask.getAttributeBySysName(params.parameterAttribute);

      return {
        paramName: params.parameterName,
        fieldName: attribute.name
      };
    }

    return {};
  }, [bpmTask, params.parameterName, params.parameterAttribute]);

  const secondMasterField = useMemo(() => {
    if (
      bpmTask &&
      params.secondParameterName &&
      params.secondParameterAttribute
    ) {
      const attribute = bpmTask.getAttributeBySysName(params.secondParameterAttribute);

      return {
        paramName: params.secondParameterName,
        fieldName: attribute.name,
      };
    }

    return {};
  }, [bpmTask, params]);

  const innerFields = useMemo(() =>
      params.innerValue
        ? params.innerValue
          .split(';')
          .filter(val => !!val.length)
          .map(val => val.split(','))
        : [],
    [params.innerValue]
  );

  return {
    hint,
    params,
    rules,
    options,
    localizedOptions,
    localizedPossibleResults,
    masterField,
    secondMasterField,
    innerFields,
  };
};
