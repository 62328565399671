import React from 'react';
import { Typography } from '@mui/material';

import useStyles from './useStyles';
import { useTranslation } from 'react-i18next';

import {EDS_FORM, INVALID_PARAM_P12_ERROR, ERROR_MESSAGES} from '../../EdsConstants';

type Props = {
  loading: boolean;
  error: string;
}

export const EdsFormFooter = ({ loading = false, error }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (loading) {
    return <Typography>{t(EDS_FORM.LOADING_TEXT)}</Typography>;
  }

  if (error) {
    return <Typography className={classes.errorText}>
      {error === INVALID_PARAM_P12_ERROR ? t(ERROR_MESSAGES.INVALID_PARAM_P12) : t(error)}
    </Typography>;
  }

  return null;
};
