import React, { memo, ReactElement, useCallback, useMemo} from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Tabs, Tab } from '@mui/material';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { clearSelectedRequest, clearTaskData } from 'store/requests';
import { clearSelectedApproval, clearSelectedReviewedCurrentTask } from 'store/approvals';

import { SubmenuItem } from "types";

import useStyles from './useStyles';
type ContentMenuProps = {
  menuList: SubmenuItem[];
  leftTaskOpenTabs?: string[];
  activeTab?: number | string;
  templatesVariant?: boolean;
  onClick?: (tabId: number | string) => void;
};

export const ContentMenu = memo(({
  menuList,
  leftTaskOpenTabs = [],
  activeTab,
  templatesVariant = false,
  onClick
}: ContentMenuProps): ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { pathname } = useLocation();

  const activeValue = useMemo((): string | number =>
    activeTab ?? pathname,
    [activeTab, pathname]);

  // to close opened task when tab is changed
  const handleLinkTabClick = useCallback((tabName: string): void => {
    if (!leftTaskOpenTabs.includes(tabName)) {
      dispatch(clearSelectedRequest());
      dispatch(clearSelectedApproval());
      dispatch(clearSelectedReviewedCurrentTask());
      dispatch(clearTaskData());
    }
  }, [leftTaskOpenTabs, dispatch]);

  const getTabLabel = useCallback((element) => {
    return element?.label || element.name;
  }, []);

  return (
    <Tabs
      variant="scrollable"
      scrollButtons="auto"
      value={activeValue}
      className={classes.tabs}
      classes={{
        indicator: classes.tabIndicator
      }}
    >
      {menuList.map((el) =>
        onClick ? (
          <Tab
            className={cn(classes.tab, {
              [classes.templatesTab]: templatesVariant,
            })}
            key={el.name}
            label={getTabLabel(el)}
            icon={<img src={el.tabIcon} className={classes.tabIcon} />}
            value={el.name}
            onClick={() => onClick(el.name)}
          />
        ) : (
          <Tab
            className={cn(classes.tab, {
              [classes.templatesTab]: templatesVariant,
            })}
            value={`${url}${el.path}`}
            key={el.name}
            label={getTabLabel(el)}
            component={Link}
            onClick={() => handleLinkTabClick(el.name)}
            to={`${url}${el.path}`}
          />
        )
      )}
    </Tabs>
  );
});
