import React, { memo, useEffect, useState, useCallback, useRef, createRef, RefObject, ReactElement } from 'react';
import cx from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import allUsersIcon from 'assets/images/icons/people-all.svg';
import { UserInfo } from 'components';
import { UserType } from 'types';

import useStyles from './useStyles';

type Props = {
  users: UserType[];
  onUserSelected: (user: UserType) => void;
};

export const UserMentionsList = memo(
  ({ users, onUserSelected }: Props): ReactElement => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [selectedItemIndex, setSelectedItemIndex] = useState(null);

    const refs = useRef<RefObject<HTMLDivElement>[]>(users.map(() => createRef()));

    const handleUserKeyPress = useCallback(
      (event) => {
        const { key } = event;
        if (key === 'ArrowDown') {
          if (selectedItemIndex === null) {
            setSelectedItemIndex(() => 0);
          } else {
            const newIndex = (users.length + selectedItemIndex + 1) % users.length;
            setSelectedItemIndex(() => newIndex);
            refs.current[newIndex].current.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          }
          event.preventDefault();
        }

        if (key === 'ArrowUp') {
          if (selectedItemIndex === null) {
            setSelectedItemIndex(() => 0);
          } else {
            const newIndex = (users.length + selectedItemIndex - 1) % users.length;
            setSelectedItemIndex(() => newIndex);
            refs.current[newIndex].current.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
            });
          }
          event.preventDefault();
        }

        if (key === 'Enter') {
          event.preventDefault();
          if (selectedItemIndex !== null) {
            setSelectedItemIndex(null);
            onUserSelected(users[selectedItemIndex]);
          }
        }
        // eslint-disable-next-line
      },
      [selectedItemIndex, onUserSelected, users, refs]
    );

    useEffect(() => {
      window.addEventListener('keydown', handleUserKeyPress);

      return () => {
        window.removeEventListener('keydown', handleUserKeyPress);
      };
    }, [handleUserKeyPress]);

    return (
      <Box className={classes.usersList}>
        <Scrollbars
          style={{ height: users.length * 40 + 16, maxHeight: 296, width: '300px' }}
          renderView={(props) => <div {...props} style={{ padding: '8px 0', ...props.style }} />}
        >
          {users.map((user, index) => {
            return (
              <div
                key={index}
                className={cx(classes.usersListItem, { selectedListItem: index === selectedItemIndex })}
                ref={refs.current[index]}
                onClick={() => onUserSelected(user)}
              >
                <UserInfo user={user} inputRef={refs[index]} avatarSize={24} truncateName maxWidth={260} maxNameLength={25} />
              </div>
            );
          })}
        </Scrollbars>
      </Box>
    );
  }
);
