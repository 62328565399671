import React, { useState, useRef, useEffect } from 'react';

import ArrowUp from 'assets/images/icons/arrow-up-accordion.svg';
import useStyles from './Accordion.style';

interface AccordionProps {
  title: string;
  children: React.ReactNode;
  isOpen?: boolean;
}

export const Accordion: React.FC<AccordionProps> = ({ title, children, isOpen = false }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(isOpen);
  const [height, setHeight] = useState<string | number>(0);
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleAccordion = () => {
    setOpen(!open);
  };


  useEffect(() => {
    if (contentRef.current) {
      requestAnimationFrame(() => {
        setHeight(open ? contentRef?.current?.scrollHeight : 0);
      });
    }
  }, [open, children]);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (contentRef.current) {
        setHeight(open ? contentRef?.current?.scrollHeight : 0);
      }
    });

    if (contentRef.current) {
      observer.observe(contentRef.current, { childList: true, subtree: true });
    }

    return () => {
      if (contentRef.current) {
        observer.disconnect();
      }
    };
  }, [open]);

  return (
    <div className={classes.accordionContainer}>
      <div className={classes.accordionHeader} onClick={toggleAccordion}>
        <span className={classes.accordionTitle}>{title}</span>
        <div className={`${classes.accordionArrow} ${open ? classes.accordionArrowOpen : ''}`}>
          <img src={ArrowUp} alt={'arrow-up'} />
        </div>
      </div>
      <div className={classes.accordionContent} style={{ height: `${height}px` }} ref={contentRef}>
        <div className={classes.accordionContentInner}>{children}</div>
      </div>
    </div>
  );
};

export default Accordion;
