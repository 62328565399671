import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { toggleApprovalPanel } from 'store/approvals';
import { useRequestListState } from 'store/requests';
import { useRequestParams } from 'hooks';

import {
  ContentActionsHookProps,
  ContentActionsHookResponse
} from './ContentActions.types';
import { useHistory, useLocation } from 'react-router-dom';

export const useContentActions = ({
  type,
  badgeCountDelta = 0,
  basePageUrl = ''
}: ContentActionsHookProps): ContentActionsHookResponse => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const { completed, currentPage } = useRequestListState();
  const { urlParams } = useRequestParams({
    completed,
    currentPage
  });

  const [isFiltersOpen, setOpenFilters] = useState<boolean>(false);
  const [selectedSubpage, setSelectedSubpage] = useState<string>('');

  useEffect(() => {
    const currentSubpageValue = location.pathname.replace(basePageUrl, '');
    setSelectedSubpage(currentSubpageValue);
  }, [location.pathname, basePageUrl]);

  const contentActionBadgeContent = useMemo(
    (): number =>
      !isFiltersOpen
        ? Math.max(0, Object.keys(urlParams).length + badgeCountDelta)
        : 0,
    [isFiltersOpen, badgeCountDelta, urlParams]
  );

  const handleTogglePanel = (): void => {
    dispatch(toggleApprovalPanel());
  };

  const handleFiltersClick = (): void => {
    setOpenFilters(!isFiltersOpen);
  };

  const handleFiltersClose = (): void => {
    setOpenFilters(false);
  };

  const handleSubpageSelect = (value: string): void => {
    setSelectedSubpage(value);
    const newPageAddress = basePageUrl + value;
    history.push(newPageAddress);
  };

  return {
    selectedSubpage,
    isFiltersOpen,
    contentActionBadgeContent,
    handleTogglePanel,
    handleFiltersClick,
    handleFiltersClose,
    handleSubpageSelect
  };
};
