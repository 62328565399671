export const containsText = (text: null | string, searchText: string): boolean =>
  text?.toLowerCase()?.indexOf(searchText.toLowerCase()) > -1;

export const containsFilters = (option: string | string[], filters: string[]): boolean => {
  if (!filters.length) {
    return true;
  }

  if (Array.isArray(option)) {
    return option.some(optionItem => filters.includes(optionItem));
  }

  return filters.includes(option);
}
