import React, { memo, useEffect, useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import cn from 'classnames';

import useStyles from './useStyles';

import checkIcon from 'assets/images/icons/green-check.svg';
import crossIcon from 'assets/images/icons/red-cross.svg';
import circularArrowIcon from 'assets/images/icons/orange-circular-arrow.svg';

const EMPTY_VALUE_LABEL = 'n/a';

type Props = {
  name?: string;
  hint?: string;
  options?: string;
  localizedOptions?: string;
};

export const CheckResult = memo(({
  hint,
  name,
  options = '',
  localizedOptions = ''
}: Props) => {
  const { register, watch } = useFormContext();

  const [icon, setIcon] = useState(null);
  const [label, setLabel] = useState(null);

  const value = watch(name);

  const parsedOptions = useMemo(() => {
    const optionsList = options.split(',');
    const localizedOptionsList = localizedOptions.split(',')
    return optionsList.map((optionItem, index) => {
      const [symbol, value] = optionItem.split(':').map((v) => v.trim());
      const localizedOption = index >=0 && index < localizedOptionsList.length ? localizedOptionsList[index] : optionItem;
      const [localizedOptionSymbol, label] = localizedOption.split(':').map((v) => v.trim());
      return { symbol, value, label };
    });
  }, [options]);

  const iconMap = useMemo(
    () => ({
      '+': checkIcon,
      '-': crossIcon,
      '?': circularArrowIcon,
    }),
    []
  );

  useEffect(() => {
    const selectedOption = parsedOptions.find(
      (option) => option.value === value
    );
    if (!selectedOption || !value.trim()) {
      setLabel(EMPTY_VALUE_LABEL);
      setIcon(null);
    } else {
      const selectedOptionIcon = iconMap[selectedOption.symbol];
      setIcon(selectedOptionIcon);
      setLabel(selectedOption?.label || value);
    }
  }, [value, parsedOptions]);

  const classes = useStyles();

  return (
    <div>
      <Typography>{hint}</Typography>
      <div className={classes.wrapper}>
        {icon && <img className={classes.icon} src={icon} />}
        <Typography
          className={cn(classes.label, {
            [classes.emptyValueLabel]: label === EMPTY_VALUE_LABEL,
          })}
        >
          {label}
        </Typography>
      </div>
      <input type="hidden" data-selenium={name} {...register(name)} />
    </div>
  );
}, (prevProps, nextProps) =>
  prevProps.name === nextProps.name &&
  prevProps.hint === nextProps.hint &&
  prevProps.options === nextProps.options);
