import makeStyles from '@mui/styles/makeStyles';

type stylesProps = {
  x: number;
  y: number;
  maxDropdownHeight: number;
};

const useStyles = makeStyles(({ palette, spacing }) => ({
  textField: {
    width: '100%',
  },
  dropdownWrapper: {
    width: 533,
    height: ({ maxDropdownHeight }: stylesProps) => Math.min(maxDropdownHeight, 530),
    position: 'absolute',
    zIndex: 1400,
    backgroundColor: palette.common.white,
    borderRadius: spacing(1),
    marginTop: spacing(1),
    border: `1px solid ${palette.grey[300]}`,
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.12)',
    top: ({ y }: stylesProps) => y,
    left: ({ x }: stylesProps) => x,
  },
  searchWrapper: {
    width: '100%',
    height: 64,
    backgroundColor: palette.grey[100],
    padding: 12,
    borderRadius: spacing(1, 1, 0, 0),
  },
  searchInput: {
    width: '100%',
    height: '100%',
  },
  optionsWrapper: {
    width: '100%',
    height: ({ maxDropdownHeight }: stylesProps) => 413 - 530 + Math.min(maxDropdownHeight, 530),
    '& > div > div': {
      paddingLeft: '12px',
    },
  },
  selectAllWrapper: {
    width: '100%',
    height: 40,
    borderBottom: `1px solid ${palette.grey[300]}`,
  },
  option: {
    display: 'block',
    height: 40,
  },
  clearWrapper: {
    width: '100%',
    height: 52,
    borderTop: `1px solid ${palette.grey[300]}`,
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
  clearButton: {},
  analyticsFiltersVariant: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 'fit-content',
    paddingLeft: spacing(2),

    '& label': {
      position: 'relative',
      fontSize: 14,
      fontWeight: 400,
      height: 40,
      display: 'flex',
      alignItems: 'center',
      paddingTop: 6,
      background: 'none',
    },

    '& > div': {
      border: '1px solid transparent',
      width: 'fit-content',
      padding: '9px 7px',
      margin: 0,
      marginLeft: 4,
      fontSize: 14,
      color: '#1B74B4',
      background: 'none',
    },
  },
  valueChanged: {
    backgroundColor: '#E4F6FE',
  },
  dropdownWrapperCompact: {
    width: 300,
  },

  checkbox: {
    padding: '7px !important',
    '& svg path': {
      fill: '#8B8C9E !important',
    },
  },
  checkboxChecked: {
    '& svg path': {
      fill: '#007994 !important',
    },
  },
  checkboxDisabled: {
    '& svg path': {
      fill: palette.grey[300] + ' !important',
    },
  },
}));

export default useStyles;
