import {TemplatesTableColumn} from './TemplatesTable.types'

export const TemplatesTableColumns: TemplatesTableColumn[] = [
  {
    id: "processName",
    label: "customProcesses.creationPage.fields.name",
    width: "auto",
  },
  {
    id: "status",
    label: "customProcesses.table.status",
    width: 150
  },
  {
    id: "integration",
    label: "customProcesses.table.integration",
    width: 160
  },
  {
    id: "allLanguages",
    label: "customProcesses.table.languages",
    width: 120
  },
  {
    id: "creator",
    label: "filters.author",
    width: 190
  },
  {
    id: "createdDate",
    label: "customProcesses.table.creationDate",
    width: 120
  },
  {
    id: "lastUpdated",
    label: "customProcesses.table.updateDate",
    width: 120
  },
  {
    id: "processMenu",
    label: "",
    width: 48
  },
];

