import React, { ReactElement, createContext, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box, Typography,
  ButtonBase,
  TextField,
  InputAdornment,
  Modal,
  Collapse,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import cn from 'classnames';
import Search from '@mui/icons-material/Search';
import { useDispatch } from 'react-redux';
import { NotificationManager } from 'react-notifications';

import {
  CustomPagination,
  ErrorMessage,
  MainTabs,
  Spinner,
  NewButtonRequestHeader,
  NoContentMessage,
} from 'components';

import { StatusType } from 'types';
import FilterIcon from 'assets/images/icons/new-filter-icon.svg';

import { Filter } from './components/Filter/Filter';
import { AdminPanelTable } from './components/AdminPanelTable';
import { ChangeAssignee } from 'components/Modal/ChangeAssignee';
import { Details } from './components/Details';

import { useAdminPanelRequest } from './hooks/useAdminPanelRequest';
import { useDebouncedSearchText } from './hooks/useDebouncedSearchText';
import { useFilter } from './hooks/useFilter';

import { AdminPanelRow, SelectedAdminPanelRow } from './components/AdminPanelTable/AdminPanelTable.types';
import { clearTaskData, getTaskById, useTaskState } from 'store/requests';
import { ConfirmationModal } from 'components/Modal/ConfirmationModal';
import { deleteAdminPanelRequest, massDeleteAdminPanelRequest } from 'api/requests';
import { RequestStatusesEnum } from './AdminPanel.types';
import useStyles from './AdminPanel.useStyles';
import { ActionsWithSelection } from './components/ActionsWithSelections';

export type ChangeAssigneeData = {
  assigneeId: string;
  taskId: string | string[];
  stepName: string;
}

export const ChangeAssigneeCallbackContext = createContext<{
  action: (changeAssigneeData: ChangeAssigneeData) => void;
  isAvailiable: boolean;
}>(null);

export const AdminPanel = (): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: bpmTask } = useTaskState();

  const {
    usedFilterCountNumber,
    isFilterVisible,
    setIsFilterVisible,
    currentFilterValues,
    setCurrentFilterValues,
  } = useFilter();

  const [currentRow, setCurrentRow] = useState<AdminPanelRow>();
  const [anchorElement, setAnchorElement] = useState<HTMLElement>(null);
  const [isDetailsWindowOpen, setIsDetailsWindowOpen] = useState<boolean>(false);
  const [isDeleteWindowOpen, setIsDeleteWindowOpen] = useState<boolean>(false);
  const [isMassDeleteWindowOpen, setIsMassDeleteWindowOpen] = useState<boolean>(false);

  const [changeAssigneeData, setChangeAssigneeData] = useState<ChangeAssigneeData>(null);
  const [selectedRows, setSelectedRows] = useState<SelectedAdminPanelRow[]>([]);

  const { requests, loadingStatus, totalElements, totalPages, getRequests } = useAdminPanelRequest({
    filterValues: currentFilterValues,
    setFilterValues: setCurrentFilterValues,
  });

  const { handleSearchTextChange, searchFieldText } = useDebouncedSearchText({
    doWithNewValue: (searchText) => setCurrentFilterValues({ searchText }),
  });

  const openDetailsWindow = useCallback((row?: AdminPanelRow) => {
    if (row) {
      if (row.businessTask.taskId !== currentRow?.businessTask.taskId) {
        setCurrentRow(row);
      }

      setIsDetailsWindowOpen(true);
      dispatch(getTaskById(row.businessTask.taskId));
    }
  }, [currentRow, dispatch]);

  const handleMassChangeAssignee = () => {
    if (selectedRows.length === 1) {
      setChangeAssigneeData({
        assigneeId: selectedRows[0].businessTask.assignee,
        taskId: selectedRows[0].businessTask.taskId,
        stepName: t(`constructor-${selectedRows[0].businessTask.processSysName}.actions.${selectedRows[0].currentAction.sysName}.name`, { defaultValue: selectedRows[0].currentAction.name }),
      });
      return;
    }

    setChangeAssigneeData({
      assigneeId: null,
      taskId: selectedRows.map(r => r.businessTask.taskId),
      stepName: null,
    });
  };

  const handleDeleteRequest = async () => {
    const id = currentRow?.businessTask?.processInstanceId || bpmTask?.processInstanceId;

    if (!id) return;
    return deleteAdminPanelRequest(id);
  };

  const handleMassDeleteRequest = async () => {
    if (selectedRows.length < 1) return;
    return massDeleteAdminPanelRequest(selectedRows.map(r => r.businessTask.processInstanceId));
  };

  const onSuccessMassDeleteRequest = () => {
    getRequests();

    setIsMassDeleteWindowOpen(false);
    setSelectedRows([]);

    dispatch(clearTaskData());
    setIsDetailsWindowOpen(false);
    NotificationManager.success(t('customProcesses.notifications.deletion_success'));
  };

  const onSuccessDeleteRequest = () => {
    getRequests();

    setIsDeleteWindowOpen(false);
    dispatch(clearTaskData());
    setCurrentRow(null);
    setSelectedRows([]);

    setIsDetailsWindowOpen(false);
    NotificationManager.success(t('customProcesses.notifications.deletion_success'));
  };

  const onSuccessChangeAssignee = () => {
    setChangeAssigneeData(null);
    setSelectedRows([]);

    NotificationManager.success(t('notifications.AdminPanel.assigneeChanged'));

    if (bpmTask) {
      dispatch(getTaskById(bpmTask.businessTask.taskId));
    }

    getRequests();
  };

  const menuButtons = useMemo(() => {
    const menu = [
      {
        title: t('customProcesses.table.actions.delete'),
        visible: currentRow?.businessTask?.status !== RequestStatusesEnum.Completed,
        onClick: () => {
          setIsDeleteWindowOpen(true);
        },
      },
      {
        title: t('buttons.showDetails'),
        visible: true,
        onClick: () => openDetailsWindow(currentRow),
      },
    ];

    if (!currentRow?.businessTask.parallel && currentRow?.businessTask.status === RequestStatusesEnum.InProgress && currentRow?.businessTask.assignee) {
      menu.push({
        title: t('AdminPanel.actions.changeAssignee'),
        visible: true,
        onClick: () => {
          setChangeAssigneeData({
            assigneeId: currentRow.businessTask.assignee,
            taskId: currentRow.businessTask.taskId,
            stepName: t(`constructor-${currentRow.businessTask.processSysName}.actions.${currentRow.currentAction.sysName}.name`, { defaultValue: currentRow.currentAction.name }),
          });
        },
      });
    }
    return menu;
  }, [openDetailsWindow, t, currentRow]);

  useEffect(() => {
    return () => {
      dispatch(clearTaskData());
    };
  }, [dispatch]);

  useEffect(() => {
    setSelectedRows([]);
  }, [requests]);

  const renderRequestsList = useMemo((): ReactElement => {
    switch (loadingStatus) {
      case StatusType.RESOLVED:
        // eslint-disable-next-line no-case-declarations
        let content = null;

        if (requests.length > 0) {
          content = (
            <Box pt={4}>
              <Collapse in={selectedRows.length > 0}>
                <ActionsWithSelection
                  selectedRowsCount={selectedRows.length}
                  openDeleteModal={() => setIsMassDeleteWindowOpen(true)}
                  openChangeAssigneeModal={handleMassChangeAssignee}
                />
              </Collapse>

              <AdminPanelTable
                requests={requests}
                setCurrentRow={setCurrentRow}
                setAnchorElement={setAnchorElement}
                anchorElement={anchorElement}
                menuButtons={menuButtons}
                openDetailsWindow={openDetailsWindow}
                selectRows={setSelectedRows}
                selectedRows={selectedRows}
              />

              <Box display="flex" justifyContent="end" alignItems="center" gap={6}>

                <Typography className={classes.paginationText}>
                  <Trans
                    i18nKey="customProcesses.table.pagination"
                    values={{
                      from: currentFilterValues.pagination.size * currentFilterValues.pagination.page + 1,
                      to: Math.min(currentFilterValues.pagination.size * (currentFilterValues.pagination.page + 1), totalElements),
                      total: totalElements,
                    }}/>
                </Typography>

                {totalElements > 10 &&
                  <CustomPagination
                    pageSize={currentFilterValues.pagination.size}
                    currentPage={currentFilterValues.pagination.page + 1}
                    handlePageSizeChange={(e) => {
                      setCurrentFilterValues({
                        pagination: {
                          ...currentFilterValues.pagination,
                          size: Number(e.target.value),
                          page: 0,
                        },
                      });
                    }}
                    handlePageChange={(_, page) => {
                      setCurrentFilterValues({
                        pagination: {
                          ...currentFilterValues.pagination,
                          page: page - 1,
                        },
                      });
                    }}
                    totalPages={totalPages}
                  />
                }
              </Box>
            </Box>
          );
        } else if (currentFilterValues.searchText?.trim() || usedFilterCountNumber > 0) {
          content = <NoContentMessage noResultsWithFilters/>;
        } else {
          content = <NoContentMessage message={t('Processes.no_requests')}
                                      additionalMessage={t('AdminPanel.no_requests_description')}/>;
        }

        if (requests.length === 0 && totalElements !== 0) {
          return <Spinner absolute={false}/>;
        }

        return <Box display="flex" flexGrow={1} alignItems="center" justifyContent="center">
          {content}
        </Box>;
      case StatusType.REJECTED: {
        return (
          <ErrorMessage text={t('errors.somethingIsWrong')}/>
        );
      }
      case StatusType.PENDING:
      default: {
        return (
          <Spinner absolute={false}/>
        );
      }
    }
  }, [loadingStatus, anchorElement, selectedRows]);

  return (
    <Box className={classes.root}>
      <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.headerWrapper}>
        <Typography className={classes.mainTitle}>
          {t('Processes.module')}
        </Typography>

        <Box>
          <NewButtonRequestHeader/>
        </Box>
      </Box>

      <MainTabs/>

      <Box display="flex" alignItems="center" gap={3}>
        <TextField
          size="medium"
          className={classes.searchInput}
          value={searchFieldText}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" className={classes.searchIconWrapper}>
                <Search/>
              </InputAdornment>
            ),
          }}
          onChange={event => handleSearchTextChange(event.target.value)}
        />

        <ButtonBase
          className={cn(classes.filterButton, {
            [classes.filterButtonActive]: Boolean(isFilterVisible) || Boolean(usedFilterCountNumber),
          })}
          onClick={() => {
            setIsFilterVisible(!isFilterVisible);
          }}
        >
          <img
            src={FilterIcon}
            alt={t('filters.title')}
          />
          {t('filters.title')} {usedFilterCountNumber ? `(${usedFilterCountNumber})` : null}
        </ButtonBase>
      </Box>

      <Box
        className={cn(classes.popup,
          {
            [classes.popupClosed]: !isFilterVisible,
            [classes.popupOpened]: isFilterVisible,
          },
        )}>
        <Filter
          value={currentFilterValues}
          setValue={(value, close) => {
            setCurrentFilterValues({
              ...value,
            });

            if (close) return;

            setIsFilterVisible(false);
          }}
          close={() => setIsFilterVisible(false)}
        />
      </Box>

      {renderRequestsList}

      <Box
        className={cn(classes.popup,
          {
            [classes.popupClosed]: !isDetailsWindowOpen,
            [classes.popupOpened]: currentRow && isDetailsWindowOpen,
          })}
      >
        <ChangeAssigneeCallbackContext.Provider value={
          {
            action: ({ assigneeId, taskId, stepName }) => {
              setChangeAssigneeData({
                assigneeId,
                taskId,
                stepName,
              });
            },
            isAvailiable: true,
          }
        }>
          <Details
            close={() => {
              setCurrentRow(null);
              setIsDetailsWindowOpen(false);
            }}
            currentRow={currentRow}
            openDeleteWindow={() => setIsDeleteWindowOpen(true)}
          />
        </ChangeAssigneeCallbackContext.Provider>
      </Box>

      <Modal open={Boolean(changeAssigneeData)}>
        <ChangeAssignee
          data={changeAssigneeData ? {
            assigneeId: changeAssigneeData?.assigneeId,
            taskId: changeAssigneeData?.taskId,
            stepName: changeAssigneeData?.stepName,
          } : null}
          close={() => setChangeAssigneeData(null)}
          onSuccess={onSuccessChangeAssignee}
        />
      </Modal>

      <Modal open={isDeleteWindowOpen}>
        <ConfirmationModal
          description={t('customProcesses.creationPage.deleteModal.description')}
          title={t('AdminPanel.actions.deleteRequest')}
          okButtonText={t('customProcesses.creationPage.buttons.delete')}
          close={() => {
            setIsDeleteWindowOpen(false);
            setCurrentRow(null);
          }}
          onSuccessAction={onSuccessDeleteRequest}
          action={handleDeleteRequest}
        />
      </Modal>

      <Modal open={isMassDeleteWindowOpen}>
        <ConfirmationModal
          description={t('customProcesses.creationPage.deleteModal.description')}
          title={t('AdminPanel.actions.deleteRequest')}
          okButtonText={t('customProcesses.creationPage.buttons.delete')}
          close={() => {
            setIsMassDeleteWindowOpen(false);
          }}
          onSuccessAction={onSuccessMassDeleteRequest}
          action={handleMassDeleteRequest}
        />
      </Modal>
    </Box>
  );
};

export default AdminPanel;
