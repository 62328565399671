import { baseV1ApiClient } from '../clients';
import { commonExceptionHandler } from '../handlers';
import { getProfileCompanyDataFromLocalStorage } from "utils/user";


export const getOldIdUsersListByCompany = async (): Promise<{data: {userId: string; id: string}[]}> => {
  try {
    const { id: companyId } = getProfileCompanyDataFromLocalStorage();

    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/camunda/bpm-user-api/hcms/employees/okta',
      headers: {
        companyId
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};
