import { handleActions } from 'redux-actions';

import { CompanyInfo, CompanyPermission, CompanyRoleInfo, CompanyUserInfo, StatusType, UserPermission } from 'types';
import { SETTINGS_ACTIONS } from './settings.actions';
import { FULFILLED, PENDING, REJECTED } from 'store/constants';

export interface SettingsState {
  isUserHasAccessToSettings: boolean,
  templateProcessesAccesses: {
    data: {
      template_process_create: boolean,
      template_process_edit: boolean,
      template_process_copy: boolean,
      template_process_archive: boolean,
    },
    status: StatusType
  },
  // TODO: Подумать над созданием общего хранение child компании
  childrenCompanies: {
    data: CompanyInfo[];
    status: StatusType;
    errorMessage: null | string;
  },
  ACLUserPermissions: {
    data: CompanyPermission[];
    status: StatusType;
    errorMessage: null | string;
  },
  ACLUserProcesses: {
    data: any[]; //todo - fix type
    status: StatusType;
    errorMessage: null | string;
  },
  companyRoles: {
    data: CompanyRoleInfo[];
    status: StatusType;
    errorMessage: null | string;
  },
  companyUsers: {
    data: CompanyUserInfo[];
    status: StatusType;
    errorMessage: null | string;
  },
  defaultCompanyProcesses: {
    data: {
      id: null | string;
      publicProcesses: string[];
      notAvailableCompanies: string[];
    }
    status: StatusType.PENDING;
    errorMessage: null;
  },
  // TODO: Исправить тип any
  companyProcesses: {
    data: any[];
    status: StatusType;
    errorMessage: null | string;
  },
  companyTemplateProcesses: {
    data: any[];
    status: StatusType;
    errorMessage: null | string;
  },
  usersAllPermission: {
    data: UserPermission[];
    status: StatusType;
    errorMessage: null | string;
  },
}

export const settingsInitialState: SettingsState = {
  isUserHasAccessToSettings: false,
  templateProcessesAccesses: {
    status: StatusType.PENDING,
    data: {
      template_process_create: false,
      template_process_edit: false,
      template_process_copy: false,
      template_process_archive: false,
    }
  },
  childrenCompanies: {
    data: [],
    status: StatusType.PENDING,
    errorMessage: null
  },
  ACLUserPermissions: {
    data: [],
    status: StatusType.PENDING,
    errorMessage: null
  },
  ACLUserProcesses: {
    data: [],
    status: StatusType.PENDING,
    errorMessage: null
  },
  companyRoles: {
    data: [],
    status: StatusType.PENDING,
    errorMessage: null
  },
  companyProcesses: {
    data: [],
    status: StatusType.PENDING,
    errorMessage: null
  },
  companyTemplateProcesses: {
    data: [],
    status: StatusType.PENDING,
    errorMessage: null
  },
  defaultCompanyProcesses: {
    data: {
      id: null,
      publicProcesses: [],
      notAvailableCompanies: []
    },
    status: StatusType.PENDING,
    errorMessage: null
  },
  companyUsers: {
    data: [],
    status: StatusType.PENDING,
    errorMessage: null
  },
  usersAllPermission: {
    data: [],
    status: StatusType.PENDING,
    errorMessage: null
  }
}

const DEFAULT_ERROR = 'Some error occurred! Please try again!';
const NOT_SHOWN_PROCESSES = ['preboarding'];

export default handleActions({
  [SETTINGS_ACTIONS.GET_CHILD_COMPANIES + PENDING]: (state: SettingsState) => ({
    ...state,
    childrenCompanies: {
      ...state.childrenCompanies,
      status: StatusType.PENDING,
      errorMessage: null
    }
  }),
  [SETTINGS_ACTIONS.GET_CHILD_COMPANIES + FULFILLED]: (state: SettingsState, { payload }: {
    payload: CompanyInfo[]
  }) => ({
    ...state,
    childrenCompanies: {
      ...state.childrenCompanies,
      status: StatusType.RESOLVED,
      data: payload ?? []
    }
  }),
  [SETTINGS_ACTIONS.GET_CHILD_COMPANIES + REJECTED]: (state: SettingsState, { payload }) => ({
    ...state,
    childrenCompanies: {
      ...state.childrenCompanies,
      status: StatusType.REJECTED,
      errorMessage: payload?.message || DEFAULT_ERROR
    }
  }),

  [SETTINGS_ACTIONS.CHECK_USER_ACCESS_TO_ADMIN_SETTINGS + FULFILLED]: (state: SettingsState, { payload }: {
    payload: boolean
  }) => ({
    ...state,
    isUserHasAccessToSettings: payload ?? false
  }),

  [SETTINGS_ACTIONS.CHECK_TEMPLATE_PROCESSES_ACCESSES + FULFILLED]: (state: SettingsState, { payload }: {
    payload: boolean
  }) => ({
    ...state,
    templateProcessesAccesses: {
      data: payload,
      status: StatusType.RESOLVED
    }
  }),
  [SETTINGS_ACTIONS.CHECK_TEMPLATE_PROCESSES_ACCESSES + REJECTED]: (state: SettingsState, { payload }: {
    payload: boolean
  }) => ({
    ...state,
    templateProcessesAccesses: {
      status: StatusType.REJECTED
    }
  }),

  [SETTINGS_ACTIONS.GET_DEFAULT_COMPANY_PROCESSES + PENDING]: (state: SettingsState) => ({
    ...state,
    defaultCompanyProcesses: {
      ...state.defaultCompanyProcesses,
      status: StatusType.PENDING,
      errorMessage: null
    }
  }),
  [SETTINGS_ACTIONS.GET_DEFAULT_COMPANY_PROCESSES + FULFILLED]: (state: SettingsState, { payload }: {
    payload: {
      id: string;
      publicProcesses: string[];
      notAvailableCompanies: string[];
    }
  }) => ({
    ...state,
    defaultCompanyProcesses: {
      ...state.defaultCompanyProcesses,
      status: StatusType.RESOLVED,
      data: {
        id: payload.id,
        publicProcesses: payload.publicProcesses,
        notAvailableCompanies: payload.notAvailableCompanies
      }
    }
  }),
  [SETTINGS_ACTIONS.GET_DEFAULT_COMPANY_PROCESSES + REJECTED]: (state: SettingsState, { payload }) => ({
    ...state,
    defaultCompanyProcesses: {
      ...state.defaultCompanyProcesses,
      status: StatusType.REJECTED,
      errorMessage: payload?.message || DEFAULT_ERROR
    }
  }),

  [SETTINGS_ACTIONS.GET_DEFAULT_COMPANY_PROCESSES + PENDING]: (state: SettingsState) => ({
    ...state,
    companyProcesses: {
      ...state.companyProcesses,
      status: StatusType.PENDING,
      errorMessage: null
    }
  }),
  [SETTINGS_ACTIONS.GET_ALL_COMPANY_PROCESSES + FULFILLED]: (state: SettingsState, { payload }) => ({
    ...state,
    companyProcesses: {
      ...state.companyProcesses,
      status: StatusType.RESOLVED,
      // TODO: Убрать хардкод после добавление значка с бека по видимости / невидимости процесса
      data: payload?.filter(({ processSysName }) => processSysName && !NOT_SHOWN_PROCESSES.includes(processSysName)) || []
    }
  }),
  [SETTINGS_ACTIONS.GET_ALL_COMPANY_PROCESSES + REJECTED]: (state: SettingsState, { payload }) => ({
    ...state,
    companyProcesses: {
      ...state.companyProcesses,
      status: StatusType.REJECTED,
      errorMessage: payload?.message || DEFAULT_ERROR
    }
  }),

  [SETTINGS_ACTIONS.GET_ALL_COMPANY_TEMPLATE_PROCESSES + PENDING]: (state: SettingsState) => ({
    ...state,
    companyTemplateProcesses: {
      ...state.companyTemplateProcesses,
      status: StatusType.PENDING,
      errorMessage: null
    }
  }),
  [SETTINGS_ACTIONS.GET_ALL_COMPANY_TEMPLATE_PROCESSES + FULFILLED]: (state: SettingsState, { payload }) => ({
    ...state,
    companyTemplateProcesses: {
      ...state.companyTemplateProcesses,
      status: StatusType.RESOLVED,
      data: payload || []
    }
  }),
  [SETTINGS_ACTIONS.GET_ALL_COMPANY_TEMPLATE_PROCESSES + REJECTED]: (state: SettingsState, { payload }) => ({
    ...state,
    companyTemplateProcesses: {
      ...state.companyTemplateProcesses,
      status: StatusType.REJECTED,
      errorMessage: payload?.message || DEFAULT_ERROR
    }
  }),

  [SETTINGS_ACTIONS.GET_ACL_CURRENT_USER_ACCESSES + PENDING]: (state: SettingsState) => ({
    ...state,
    ACLUserPermissions: {
      ...state.ACLUserPermissions,
      status: StatusType.PENDING,
      errorMessage: null
    },
    ACLUserProcesses: {
      ...state.ACLUserProcesses,
      status: StatusType.PENDING,
      errorMessage: null
    }
  }),
  [SETTINGS_ACTIONS.GET_ACL_CURRENT_USER_ACCESSES+ FULFILLED]: (state: SettingsState, { payload }: {payload: CompanyUserInfo}) => ({
    ...state,
    ACLUserPermissions: {
      ...state.ACLUserPermissions,
      status: StatusType.RESOLVED,
      data: payload?.personalPermissionInfo?.permissions || []
    },
    ACLUserProcesses: {
      ...state.ACLUserProcesses,
      status: StatusType.RESOLVED,
      data: payload?.personalProcessesInfo?.processes || []
    }
  }),
  [SETTINGS_ACTIONS.GET_ACL_CURRENT_USER_ACCESSES + REJECTED]: (state: SettingsState, { payload }) => ({
    ...state,
    ACLUserPermissions: {
      ...state.ACLUserPermissions,
      status: StatusType.REJECTED,
      errorMessage: payload?.message || DEFAULT_ERROR
    },
    ACLUserProcesses: {
      ...state.ACLUserProcesses,
      status: StatusType.REJECTED,
      errorMessage: payload?.message || DEFAULT_ERROR
    }
  }),

  [SETTINGS_ACTIONS.GET_COMPANY_ROLES + PENDING]: (state: SettingsState) => ({
    ...state,
    companyRoles: {
      ...state.companyRoles,
      status: StatusType.PENDING,
      errorMessage: null
    }
  }),
  [SETTINGS_ACTIONS.GET_COMPANY_ROLES + FULFILLED]: (state: SettingsState, { payload }: {payload: CompanyRoleInfo[]}) => ({
    ...state,
    companyRoles: {
      ...state.companyRoles,
      status: StatusType.RESOLVED,
      data: payload || []
    }
  }),
  [SETTINGS_ACTIONS.GET_COMPANY_ROLES + REJECTED]: (state: SettingsState, { payload }) => ({
    ...state,
    companyRoles: {
      ...state.companyRoles,
      status: StatusType.REJECTED,
      errorMessage: payload?.message || DEFAULT_ERROR
    }
  }),

  [SETTINGS_ACTIONS.GET_COMPANY_USERS + PENDING]: (state: SettingsState) => ({
    ...state,
    companyUsers: {
      ...state.companyUsers,
      status: StatusType.PENDING,
      errorMessage: null
    }
  }),
  [SETTINGS_ACTIONS.GET_COMPANY_USERS + FULFILLED]: (state: SettingsState, { payload }: {payload: CompanyUserInfo}) => ({
    ...state,
    companyUsers: {
      ...state.companyUsers,
      status: StatusType.RESOLVED,
      data: payload || []
    }
  }),
  [SETTINGS_ACTIONS.GET_COMPANY_USERS + REJECTED]: (state: SettingsState, { payload }) => ({
    ...state,
    companyUsers: {
      ...state.companyUsers,
      status: StatusType.REJECTED,
      errorMessage: payload?.message || DEFAULT_ERROR
    }
  }),

  [SETTINGS_ACTIONS.GET_ALL_USERS_PERMISSION + PENDING]: (state: SettingsState) => ({
  ...state,
    usersAllPermission: {
    ...state.usersAllPermission,
    status: StatusType.PENDING,
    errorMessage: null
  }
}),
  [SETTINGS_ACTIONS.GET_ALL_USERS_PERMISSION + FULFILLED]: (state: SettingsState, { payload }: {payload: UserPermission[]}) => ({
  ...state,
  usersAllPermission: {
    ...state.usersAllPermission,
    status: StatusType.RESOLVED,
    data: payload?.filter(({ view }: UserPermission): boolean => view) || []
  }
}),
  [SETTINGS_ACTIONS.GET_ALL_USERS_PERMISSION + REJECTED]: (state: SettingsState, { payload }) => ({
  ...state,
  usersAllPermission: {
    ...state.usersAllPermission,
    status: StatusType.REJECTED,
    errorMessage: payload?.message || DEFAULT_ERROR
  }
}),
}, settingsInitialState);
