import {AdminPanelTableColumn} from './AdminPanelTable.types'

export const AdminPanelTableColumns: AdminPanelTableColumn[] = [
  {
    id: "checkbox",
    label: "",
    width: 48
  },
  {
    id: "name",
    label: "customProcesses.creationPage.fields.name",
    width: "auto"
  },
  {
    id: "status",
    label: "customProcesses.table.status",
    width: 120
  },
  {
    id: "startDate",
    label: "Processes.request.filter.startDate",
    width: 120
  },
  {
    id: "initiator",
    label: "customProcesses.creationPage.processForm.initiator",
    width: 100,
    isCentered: true
  },
  {
    id: "currentAssignee",
    label: "filters.current_step_assignee",
    width: 190,
    isCentered: true
  },
  {
    id: "taskType",
    label: "AdminPanel.table.taskType",
    width: 160
  },
  {
    id: "step",
    label: "new_request.step",
    width: 80
  },
  {
    id: "processMenu",
    label: "",
    width: 48
  },
];

