import { useFormContext } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';

import { useUsersState } from 'store/users';
import { useTaskState } from 'store/requests';

import { filterUsers, splitText } from './utils';
import { parseJSON } from 'utils/general';
import { getUserDataByIdOrLogName } from 'utils/user';
import { getGlossaries } from 'api/requests';
import { useUsersRole } from 'hooks';

type UseReadOnlyUserProps = {
  name: string;
  params: {
    systemVariable: string;
    showInitiatorValue?: boolean;
    childrenAttributesMapping: string; // JSON
  };
};

type MappingAttribute = {
  inProperty: string;
  outAttrSysName: string;
};

export const useReadOnlyUser = ({
  name,
  params: { childrenAttributesMapping: childrenAttributesMappingJSON, systemVariable = '', showInitiatorValue = false },
}: UseReadOnlyUserProps) => {
  const { watch, getValues, setValue } = useFormContext();
  const value = watch(name);

  const { users: storedUsers } = useUsersState();
  const { data: bpmTask } = useTaskState();
  const { showReadonlyAttributes } = useUsersRole();

  useEffect(() => {
    // Исправление пустого значения при изменении отображаемого поля из редактируемого в readonly
    // До изменения в readonly UserSelect успевает его сбросить, но не восстановить
    if (showReadonlyAttributes && !value && bpmTask?.defaultFormValues[name] && !showInitiatorValue && !systemVariable) {
      setValue(name, bpmTask?.defaultFormValues[name]);
    }
  }, [showReadonlyAttributes, bpmTask?.defaultFormValues, name, showInitiatorValue, systemVariable]);

  const taskCompanyId = useMemo(() => (bpmTask ? bpmTask.businessTask.companyId : ''), [bpmTask]);

  const isEmptyValue = useMemo(() => !value.toString().trim(), [value]);

  const [user, setUser] = useState(null);

  const getUserData = async (value) => {
    if (!value) {
      return null;
    }

    const userId = Object.keys(storedUsers).find((id) => {
      return storedUsers[id].logName === value || id === value;
    });

    if (userId) {
      const currentUser = storedUsers[userId];
      setUser(currentUser);
    } else {
      try {
        const currentUser = await getUserDataByIdOrLogName(value, taskCompanyId);
        setUser(currentUser);
      } catch (error) {
        setUser(null);
      }
    }
  };

  useEffect(() => {
    getUserData(value);
  }, [value, storedUsers]);

  const [users, setUsers] = useState([]);

  const getUsersData = async (usersValue: string) => {
    const usernames = splitText(usersValue);
    const usernamesArray = Array.isArray(usernames) ? usernames : [usernames];

    const usersList = await filterUsers(usernamesArray, storedUsers, taskCompanyId);
    setUsers(usersList);
  };

  useEffect(() => {
    getUsersData(value);
  }, [value, storedUsers]);

  useEffect(() => {
    if (systemVariable) {
      const systemVariableValue = bpmTask.businessTask[systemVariable];
      setValue(name, systemVariableValue);
    }
  }, [systemVariable, bpmTask.businessTask, name, setValue]);

  // Функционал по автоматическому проставлению данных инициатора (logName)
  useEffect(() => {
    if (showInitiatorValue) {
      setValue(name, bpmTask.initiator);
    }
  }, [showInitiatorValue, bpmTask.initiator, name, setValue]);

  // Функционал по проставлению дополнительных данных из users в другие аттрибуты
  useEffect(() => {
    const mapChildrenAttributes = async () => {
      if (childrenAttributesMappingJSON) {
        const childrenAttributesMapping: MappingAttribute[] = parseJSON(childrenAttributesMappingJSON);

        // Обязательная проверка на массив childrenAttributesMapping
        if (Array.isArray(childrenAttributesMapping)) {
          for (const { inProperty, outAttrSysName } of childrenAttributesMapping) {
            const attribute = bpmTask.getAttributeBySysName(outAttrSysName);

            // Проверка наличия значения главных переменных от которых зависит вся логика
            if (!attribute || !user) {
              continue;
            }

            const attributeValue = getValues(attribute.name);
            let userParameterValue = user[inProperty];

            if (inProperty === 'jobTitle') {
              try {
                // get default value here, not ru localization
                const jobTitleGlossary = await getGlossaries(userParameterValue);
                const name = jobTitleGlossary?.[0]?.value;
                if (name) {
                  userParameterValue = name;
                }
              } catch (error) {
                console.error(error);
              }
            }

            // Предотвращения бесконечного loop-a
            if (userParameterValue && attributeValue !== userParameterValue) {
              setValue(attribute.name, userParameterValue);
            }
          }
        }
      }
    };

    mapChildrenAttributes();
  }, [childrenAttributesMappingJSON, bpmTask, setValue, getValues, user]);

  return {
    value,
    isEmptyValue,
    user,
    users,
  };
};
