import React from 'react';
import useStyles from './TemplateInfoCard.useStyles';
import { IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import RightIcon from 'assets/images/icons/chevron_right_regular.svg';

type Props = {
  onClick: () => void
  title: string;
  categoryName: string;
  stepsCount: number;
  description: string;
  integrations?: string[];
  iconPath: string;
}

export const TemplateInfoCard = ({
  onClick,
  title,
  stepsCount,
  categoryName,
  description,
  integrations,
  iconPath,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.templateCard} onClick={onClick}>
      <div className={classes.templateCardInfo}>

        <div className={classes.templateIcon}>
          <img src={iconPath} alt="process icon"/>
        </div>
        <div className={classes.templateTitleWrap}>
          <Tooltip
            title={title}
            placement="top"
            arrow
            enterDelay={250}
            classes={{
              tooltip: classes.cardTooltip,
            }}>
            <div className={classes.templateTitle}>{title}</div>
          </Tooltip>

          <Tooltip
            title={categoryName}
            placement="top"
            arrow
            enterDelay={250}
            classes={{
              tooltip: classes.cardTooltip,
            }}>
            <div className={classes.templateCategoryTitle}>{categoryName}</div>
          </Tooltip>
        </div>
      </div>

      <Tooltip
        title={description}
        placement="top"
        arrow
        enterDelay={250}
        classes={{
          tooltip: classes.cardTooltip,
        }}>
        <div className={classes.templateDescription}>
          {description}
        </div>
      </Tooltip>

      {integrations?.length > 0 &&
        <div className={classes.templateIntegrations}>
          {
            integrations.map(integrationName => {
              const displayedValue = t('templates.integrations.' + integrationName.toLowerCase(), { defaultValue: integrationName });
              return <div className={classes.integrationChip}>{displayedValue}</div>;
            })
          }
        </div>
      }

      <div className={classes.templateFooter}>
        {t('customProcesses.templatesList.steps', { stepsCount })}

        <IconButton className={classes.goBtn} onClick={onClick} size="large">
          <img src={RightIcon} alt="go"/>
        </IconButton>
      </div>
    </div>
  );
};
