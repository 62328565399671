import { useEffect, useState } from "react"

import { StatusType } from 'types';
import { getTemplates as getTemplatesRequest } from "api/requests";

import { TemplatesTableRow } from '../components/TemplatesTable/TemplatesTable.types'
import { TemplatesRequestFilterValues } from '../Templates.types'

type Props = {
  filterValues: TemplatesRequestFilterValues,
  setFilterValues: (value: Partial<TemplatesRequestFilterValues>) => void
}

export const useTemplatesRequest = ({filterValues, setFilterValues}: Props) => {
  const [requests, setRequests] = useState<TemplatesTableRow[]>([]);
  const [loadingStatus, setLoadingStatus] = useState<StatusType>(StatusType.PENDING);
  const [totalElements, setTotalElements] = useState<number>(0)
  const [totalPages, setTotalPages] = useState<number>(0)
  const [abortController, setAbortController] = useState<AbortController>()

  const getTemplates = async () => {
    setLoadingStatus(StatusType.PENDING)

      try {
        abortController?.abort()
        const controller = new AbortController()
        setAbortController(controller)

        const data = await getTemplatesRequest({
          ...filterValues,
        }, controller.signal);
        setRequests(data.content)

        if(data.totalElements !== totalElements){
          setTotalElements(data.totalElements)
        }

        if(data.totalPages !== totalPages){
          setTotalPages(data.totalPages)
        }

        setLoadingStatus(StatusType.RESOLVED)
      } catch (error) {
        if(abortController?.signal.aborted){
          return
        }

        setLoadingStatus(StatusType.REJECTED)
      }
  }

  useEffect(() => {
    getTemplates();
  }, [filterValues])

  useEffect(() => {
    if(loadingStatus === StatusType.RESOLVED && requests.length === 0 && totalElements !== 0){
      setFilterValues({
        page: 0
      })
    }
  }, [loadingStatus, requests, totalElements])

  return {
    requests,
    loadingStatus,
    totalElements,
    totalPages,
    getTemplates,
  }
}
