import React, { memo, ReactElement, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useTaskState } from 'store/requests';

type Props = {
  name: string;
  type: string;
  rules: {
    [key: string]: string;
  };
  params: {
    systemVariable: string;
  }
  validateAttributeNum: (attributeValue) => boolean | string;
};

export const HiddenField = memo(({
  name,
  type,
  rules,
  validateAttributeNum,
  params: {
    systemVariable = '',
  }
}: Props): ReactElement => {
  const { control, register, setValue } = useFormContext();
  const { data: bpmTask } = useTaskState();

  useEffect(() => {
    if (systemVariable) {
      const systemVariableValue = bpmTask.businessTask[systemVariable];
      setValue(name, systemVariableValue);
    }
  }, [systemVariable, bpmTask.businessTask, name, setValue]);

  if (type === 'ENUM') {
    return (
      <input
        type="hidden"
        {...register(name, {
          validate: validateAttributeNum,
        })}
      />
    )
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={() => (
        <input type="hidden" data-selenium={name} />
      )}
    />
  );
});
