import React, { ReactElement, useState } from 'react';
import {Box, FormControlLabel, Modal, Switch} from '@mui/material';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { DocumentTemplate, ProcessStep } from '../../TemplateProcesses.types';

import useParallelAssigneeToggleStyles from './ParallelAssigneeToggle.useStyles';
import { ParallelAssigneeActivationConfirmationModal } from '../ParallelAssigneeActivationConfirmationModal';
import { DOCUMENT_DOCFLOW_FIELD_KEY } from "../ProcessSetupSidePanel/ProcessSetupSidePanel.constants";
import {ConfirmationWithImageModal} from "../../../../components/Modal/ConfirmationWithImageModal";

interface PerformerSelectCheckboxProps {
  currentStep: ProcessStep;
  handleChange: (value: boolean) => void;
  disabled: boolean;
  documents: DocumentTemplate[];
  setSelectedRadioByStep?: any;
}

export const ParallelAssigneeToggle = ({
  currentStep,
  handleChange,
  disabled,
  documents,
                                         setSelectedRadioByStep
}: PerformerSelectCheckboxProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useParallelAssigneeToggleStyles();

  const [isConfirmationModalOpen, setConfirmationModalClose] = useState(false);

  const handleToggleValueChange = (newValue) => {
    if (!newValue) {
      setSelectedRadioByStep((prevState) => ({
        ...prevState,
        [currentStep?.stepOrder]: "", // Сбрасываем для текущего шага
      }));
      handleChange(false);
    } else {
      const hasSignatureStep = documents?.some(doc => doc.signatureSteps.includes(currentStep?.stepOrder));
      const isDocFlowRequired = currentStep.fields.some(step => step.component === DOCUMENT_DOCFLOW_FIELD_KEY && step.params.isRequired);

      if (hasSignatureStep || isDocFlowRequired) {
        setConfirmationModalClose(true);
      } else {
        handleChange(true);
      }
    }
  }

  return (
    <Box className={cn(classes.ParallelAssigneeToggle)}>
      <FormControlLabel
        disabled={disabled}
        control={<Switch
          checked={currentStep?.parallel}
          classes={{
            track: currentStep?.parallel ? classes.trackChecked : classes.track,
            switchBase: classes.switchBase
          }}
          onChange={e => handleToggleValueChange(e?.target?.checked)}
          //onChange={e => setConfirmationModalClose(true)}
        />}
        label={t('customProcesses.creationPage.processForm.fields.parallelAssigneeToggle')}
      />

      <ParallelAssigneeActivationConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={() => setConfirmationModalClose(false)}
        onConfirm={() => handleChange(true)}
      />

      <Modal open={isConfirmationModalOpen}>
        <Box>
          <ConfirmationWithImageModal
            description={'Enabling parallel or multiple assignees will temporarily prevent users from signing any documents at this step.'}
            title='Enable several assignees'
            okButtonText={t('customProcesses.creationPage.buttons.confirm')}
            close={() => {
              setConfirmationModalClose(false);
            }}
            action={async () => {
              setConfirmationModalClose(false);
              //handleToggleValueChange(false);
            }}
          />
        </Box>
      </Modal>
    </Box>
  );
};
