import { baseV1ApiClient, baseV1ApiFormDataClient } from '../clients';
import { commonExceptionHandler } from '../handlers';

export const createSignature = async ({ formData, params }) => {
  try {
    const response = await baseV1ApiFormDataClient.request({
      method: 'POST',
      url: `/document-sign-api/user/document/V2/signature`,
      data: formData,
      params,
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getOrganizations = async userModelId => {
  try {
    const response = await baseV1ApiFormDataClient.request({
      method: 'GET',
      url: `/document-sign-api/organizations?userModelId=${userModelId}`,
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getFinalDocuments = async ({
  taskId,
  params
}: {
  taskId: string;
  params: {
    userModelId: string;
    processSysId?: string;
    order?: number;
  }
}): Promise<{
  cms: string;
  original: string;
  qr: string;
}> => {
  try {
    const url =
      params.processSysId ?
        `/document-sign-api/user/document/V2/links/${taskId}` :
        `/document-sign-api/user/document/links/${taskId}`;

    const response = await baseV1ApiFormDataClient.request({
      method: 'GET',
      url,
      params,
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getEDSKeyInfo = async data => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'POST',
      url: '/nca-node-new/pkcs12/info',
      data,
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const signCms = async data => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'POST',
      url: '/nca-node-new/cms/sign',
      data,
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};
