import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  dialog: {
    padding: 0,
    background: '#fff',
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    border: 'none',
    zIndex: 1200,
    display: 'flex',
    flexDirection: 'column',
    willChange: 'transform',
    transition: 'transform .4s ease-in-out',
    transform: 'translate3d(0, -100%, 0)'
  },
  open: {
    transform: 'translate3d(0, 0, 0)'
  },
  header: {
    position: 'relative',
    zIndex: 1,
    borderBottom: "1px solid rgba(38, 40, 66, 0.08)",
    padding: theme.spacing(6, 7),
    height: 80
  },
  title: {
    fontSize: 20,
    fontWeight: 400
  },
  content: {
    overflowY: 'auto',
    flex: 1
  },
  draftStatus: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& img': {
      marginRight: theme.spacing(3),
      verticalAlign: 'middle'
    },
    '& span': {
      color: theme.palette.grey[600],
      fontSize: 14,
    }
  },
  groupName: {
    display: "flex",
    alignItems: "center",

    fontSize: 14,
    fontWeight: 400,
    color: "#6D6E85",

    "& img": {
      width: 16,
      height: 16,
      borderRadius: 4,
      marginRight: 8
    }
  }
}));
