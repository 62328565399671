import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
  statusIcon: {
    height: 20,
    width: 20,
  },
  statusText: {
    fontSize: 14,
    fontWeight: 400,
    color: '#465564',
  },
  votesPercent: {
    marginRight: '0 !important',
    width: 'fit-content !important',
    minWidth: 'fit-content !important'
  },
  detailsLink: {
    cursor: 'pointer'
  },
  supplierCard: {
    '&:not(:first-child)': {
      marginTop: theme.spacing(3),
    },
  },
  supplierCardTitle: {
    width: '100% !important',
  },
  linearProgressBar: {
    width: '100%',
  }
}));
