import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ spacing }) => ({
  checkbox: {
    '& svg path': {
      fill: '#8B8C9E !important',
    },
  },
  checkboxChecked: {
    '& svg path': {
      fill: '#007994 !important',
    },
  },
  filtersForm: {
    height: '100%',
  },
  filtersHeader: {
    width: '100%',
    height: 48,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid rgba(38, 40, 66, 0.08)',
    paddingLeft: 16,
    paddingRight: 20,
  },
  filtersTitle: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    color: '#262842',
  },
  filtersCloseButton: {
    height: 15.5,
    width: 15.5,
  },
  filtersContent: {
    width: '100%',
    height: 'calc(100% - 48px - 48px)',
    padding: 16,
  },
  filtersFooter: {
    width: '100%',
    height: 48,
    padding: spacing(3, 4),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: spacing(2),
    background: '#F7F8FC',
    borderTop: '1px solid rgba(38, 40, 66, 0.08)',
  },
  footerButton: {
    height: 32,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    borderRadius: 8,
  },
  datePicker: {
    '& > div': {
      marginTop: 0,
    }
  },
  label: {
    color: '#6D6E85',
    fontSize: 14,
    fontWeight: 600,
    margin: 0
  }
}));
