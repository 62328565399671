import React, { ReactElement, useMemo } from 'react';
import { Box, Checkbox, FormControlLabel, Tooltip, Typography } from '@mui/material';
import cn from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import Scrollbars from 'react-custom-scrollbars';

import { useUsersState } from 'store/users';
import { UserType } from 'types';

import usePerformerSelectCheckboxStyles from './PerformerSelectCheckbox.useStyles';
import { ProcessStep } from '../../TemplateProcesses.types';

import InfoIcon from 'assets/images/icons/info_outline.svg';
import { ReactComponent as CheckboxUncheckedIcon } from 'assets/images/icons/checkbox-unchecked.svg';
import { ReactComponent as CheckboxCheckedIcon } from 'assets/images/icons/checkbox-checked.svg';

interface PerformerSelectCheckboxProps {
  currentStep: ProcessStep;
  processSteps: ProcessStep[];
  currentStepDMs: string[];
  disabled?: boolean;
  isDirectManagerStep: boolean;
  isPerformerSelectionStep: boolean;
  handlePerformerSelectionToggle: (value: boolean) => void;
}

export const PerformerSelectCheckbox = ({
  currentStep,
  processSteps = [],
  currentStepDMs = [],
  disabled = false,
  isDirectManagerStep = false,
  isPerformerSelectionStep = false,
  handlePerformerSelectionToggle,
}: PerformerSelectCheckboxProps): ReactElement => {
  const { t } = useTranslation();
  const classes = usePerformerSelectCheckboxStyles();
  const { users } = useUsersState();

  const helpTooltipContent = <div className={classes.helpTooltipContent}>
    <p>
      <Trans i18nKey="customProcesses.creationPage.processForm.performerCheckboxHelp">
        <b>Внимание:</b> Выбирать исполнителя можно в департаментах, где 2 и более сотрудников. LALAL
      </Trans>
    </p>
  </div>;

  const assignees = useMemo(() => {
    if (isDirectManagerStep) {
      return currentStepDMs
        .map(userId => users[userId])
        .filter(Boolean);
    }

    return currentStep?.assigneeId
      ?.map(userId => users[userId])
      .filter(Boolean);
  }, [currentStep, currentStepDMs, isDirectManagerStep, users]);

  const assigneesWithoutDirectPerformers = useMemo(() => {
    return assignees?.filter(assignee => {
      const directSubordinates = (Object.values(users) as UserType[])
        .filter(user => user.reportsToId === assignee.id);
      const usersFromSameDepartment = (Object.values(users) as UserType[])
        .filter(user => user.department === assignee.department && user.id !== assignee.id);
      return !usersFromSameDepartment.length && !directSubordinates.length;
    })
  }, [assignees]);

  const disabledReason = useMemo(() => {
    if (processSteps.length >= 26 && !isPerformerSelectionStep) {
      return <Typography className={cn(classes.checkboxMessage, classes.defaultCursor)}>
        {t('customProcesses.creationPage.processForm.performerCheckbox.maxSteps')}
      </Typography>;
    }

    // if (assigneesWithoutDirectPerformers?.length) {
    //   const usersNamesList = assigneesWithoutDirectPerformers.map(user => user.fullName);
    //   return  <Typography className={cn(classes.checkboxMessage, classes.defaultCursor)}>
    //     {t('customProcesses.creationPage.processForm.directManagerCheckbox.noPerformerMessagePrefix')}{' '}
    //     {usersNamesList[0]}
    //     {assigneesWithoutDirectPerformers.length > 1
    //      ? <Tooltip
    //        classes={{
    //          popper: classes.tooltipPopper,
    //          tooltip: classes.usersListTooltip,
    //        }}
    //        arrow
    //        placement="bottom"
    //        title={assigneesWithoutDirectPerformersContent}
    //        leaveDelay={500}
    //        interactive
    //      >
    //        <span>{` (+${usersNamesList.length})`}</span>
    //      </Tooltip>
    //      : ''}{' '}
    //     {t('customProcesses.creationPage.processForm.directManagerCheckbox.noPerformerMessageSuffix')}
    //   </Typography>
    // }

    return null;
  }, [processSteps, assigneesWithoutDirectPerformers]);

  const assigneesTooltipContent = useMemo(() => (
    <Scrollbars className={classes.usersTooltipContent}>{
      <ul>
        {assignees?.map(user => <li>{user?.fullName}</li>)}
      </ul>
    }</Scrollbars>), [assignees]);

  const assigneesWithoutDirectPerformersContent = useMemo(() => (
    <Scrollbars className={classes.usersTooltipContent}>{
      <ul>
        {assigneesWithoutDirectPerformers?.map(user => <li>{user?.fullName}</li>)}
      </ul>
    }</Scrollbars>), [assigneesWithoutDirectPerformers]);

  return (
    <Box
      className={cn(classes.DirectManagerCheckbox)}>
      {(currentStep?.stepOrder > 1 && !currentStep?.parallel) && (
        <>
          <Box mt={1} className={classes.checkboxWrapper}>
            <FormControlLabel
              className={cn(classes.checkbox, {
                [classes.checkboxDisabled]: processSteps.length >= 26 || assignees?.length === 0 || !!disabledReason,
                [classes.checkboxChecked]: isPerformerSelectionStep
              })}
              control={<Checkbox
                color="default"
                checked={isPerformerSelectionStep}
                icon={<CheckboxUncheckedIcon/>}
                checkedIcon={<CheckboxCheckedIcon/>}
                classes={{
                  disabled: classes.checkboxDisabled,
                  checked: classes.checkboxChecked,
                }}
                onChange={e => handlePerformerSelectionToggle(e?.target?.checked)}
                disabled={processSteps.length >= 26 || assignees?.length === 0 || !!disabledReason || disabled}
              />}
              classes={{label: classes.checkboxLabel}}
              label={t('customProcesses.creationPage.processForm.fields.performerCheckbox')}
            />

            {/*{(assignees?.length > 1 || (isDirectManagerStep && assignees?.length > 0)) &&*/}
            {/*  <Tooltip*/}
            {/*    classes={{*/}
            {/*      popper: classes.tooltipPopper,*/}
            {/*      tooltip: classes.usersListTooltip,*/}
            {/*    }}*/}
            {/*    arrow*/}
            {/*    placement="bottom"*/}
            {/*    title={assigneesTooltipContent}*/}
            {/*    leaveDelay={500}*/}
            {/*    interactive*/}
            {/*  >*/}
            {/*    <Typography className={classes.defaultCursor}> ({assignees?.length})</Typography>*/}
            {/*  </Tooltip>*/}
            {/*}*/}

            {/*<Tooltip*/}
            {/*  classes={{*/}
            {/*    popper: classes.tooltipPopper,*/}
            {/*    tooltip: classes.helpTooltip,*/}
            {/*  }}*/}
            {/*  arrow*/}
            {/*  placement="bottom"*/}
            {/*  title={helpTooltipContent}*/}
            {/*  leaveDelay={500}*/}
            {/*  interactive*/}
            {/*>*/}
            {/*  <img src={InfoIcon} className={classes.infoIcon}/>*/}
            {/*</Tooltip>*/}
          </Box>

          {!!disabledReason && (disabledReason)}
        </>
      )}
    </Box>
  );
};
