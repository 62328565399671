import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { MaskedField } from '../MaskedField';

type Props = {
  name?: string;
  hint?: string;
  params?: {[key: string]: any};
  isActive: boolean;
};

const DEFAULT_MASK = '+* (***) *** - ****';

export const PhoneNumber = memo(({ name, hint, params, isActive }: Props) => {
  const { watch } = useFormContext();

  const [mask, setMask] = useState(DEFAULT_MASK);
  const previousValueRef = useRef('');

  const value = watch(name);

  useEffect(() => {
    const hasNumbers = /\d/.test(value);
    // fix constantly changing mask for Kyrgyzstan
    const currentValueNumbers = value
      .split('')
      .filter((char) => /\d/.test(char))
      .join('');
    const previousValueNumbers = previousValueRef.current
      .split('')
      .filter((char) => /\d/.test(char))
      .join('');
    if (hasNumbers && currentValueNumbers !== previousValueNumbers) {
      const brecketPosition = value.indexOf('(');
      const hasNumberBeforeBrecket = /\d/.test(value[brecketPosition - 1]);
      const newValue =
        value.substr(0, brecketPosition) +
        (hasNumberBeforeBrecket ? ' ' : '') +
        value.substr(brecketPosition);
      const symbolsBeforeBrecket = newValue.indexOf('(') - 2;

      const newMask =
        '+' +
        '*'.repeat(Math.max(1, symbolsBeforeBrecket)) +
        ' (***) *** - ***' +
        (symbolsBeforeBrecket === 1 ? '*' : '');
      setMask(newMask);
      previousValueRef.current = value;
    } else if (!hasNumbers) {
      setMask(DEFAULT_MASK);
    }
  }, [value]);

  const parsedMask = useMemo(
    () => mask.split('').map((elem) => (elem === '*' ? /\d/ : elem)),
    [mask]
  );

  return (
    <MaskedField
      name={name}
      hint={hint}
      mask={parsedMask}
      params={params}
      isActive={isActive}
    />
  );
});
