import React from 'react';
import { Route } from 'react-router-dom';

import { usePageAccess } from 'hooks';

type RestrictedRouteProps = {
  path: string;
  exact?: boolean;
  component?: any;
  hasAccess: boolean;
  redirectPath?: string;
  isExternalRedirect?: boolean;
}

export const RestrictedRoute = ({
  path,
  exact = false,
  component,
  hasAccess,
  redirectPath = '/requests/active',
  isExternalRedirect = false,
}: RestrictedRouteProps) => {
  usePageAccess({ hasAccess, redirectPath, isExternalRedirect })

  return <Route path={path} exact={exact} component={component} />
}
