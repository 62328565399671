import React, { useMemo, useState } from 'react'
import { Box, Button, ButtonBase } from '@mui/material';
import {useTranslation} from 'react-i18next';

import { useUsersState } from 'store/users';
import usebpmProcess from 'contexts/ProcessContext/useProcessContext';
import { DatePeriodPicker, GroupSelect } from 'components';
import { UserAvatar } from 'components';

import useStyle from './Filter.useStyle';

import { TypeFilterValues } from 'pages/TemplateProcesses/TemplateProcesses';
import Close from '@mui/icons-material/Close';
import moment from 'moment';

type TypeModalFilterValues = Omit<TypeFilterValues, 'searchText'>;

type TypeFilterProps = {
  setValue?: (value: TypeModalFilterValues, closeFilterStatus?: boolean) => void;
  value: TypeModalFilterValues,
  close?: () => void;
}

const Filter = (props: TypeFilterProps) => {
  const { value, setValue, close } = props;
  const classes = useStyle();
  const { t } = useTranslation();

  const { groups } = usebpmProcess();
  const { users: usersList } = useUsersState();
  const [groupsState, setGroupsState] = useState(value.group || []);
  const [status, setStatus] = useState(value.published);
  const [autoStart, setAutostart] = useState(value.autoStart);
  const [author, setAuthor] = useState(value.author || []);

  const [initiator, setInitiator] = useState(value.initiator || []);

  const [creationDateFrom, setCreationDateFrom] = useState(null);
  const [creationDateTo, setCreationDateTo] = useState(null);
  const [creationDatePeriod, setCreationDatePeriod] = useState('All time');
  const [updateDateFrom, setUpdateDateFrom] = useState(null);
  const [updateDateTo, setUpdateDateTo] = useState(null);
  const [updateDatePeriod, setUpdateDatePeriod] = useState('All time');

  const STATUSES = useMemo(() => {
    return [
      {
        label: t('customProcesses.table.statusPublished'),
        value: true,
        icon: <div className={classes.published}></div>
      },
      {
        label: t('customProcesses.table.statusNotPublished'),
        value: false,
        icon: <div className={classes.not_published}></div>
      },
    ]
  }, [])

  const AUTOSTART = useMemo(() => {
    return [
      {
        label: t('customProcesses.filters.withAutostart'),
        value: true,
        icon: <div className={classes.published}></div>
      },
      {
        label: t('customProcesses.filters.withoutAutostart'),
        value: false,
        icon: <div className={classes.not_published}></div>
      },
    ]
  }, [])

  const initialStatus = useMemo(() => {
    if ((typeof status !== 'boolean')) return;

    return status ? [STATUSES[0]] : [STATUSES[1]]
  }, [status])

  const initialAutostart = useMemo(() => {
    if ((typeof autoStart !== 'boolean')) return;

    return autoStart ? [AUTOSTART[0]] : [AUTOSTART[1]]
  }, [autoStart]);

  const handleClear = () => {
    setGroupsState([]);
    setStatus(undefined);
    setAuthor([]);
    setCreationDatePeriod('');
    setCreationDateFrom(null);
    setCreationDateTo(null);
    setUpdateDatePeriod('')
    setUpdateDateFrom(null);
    setUpdateDateTo(null);
  }

  const handleApply = () => {
    const createdDate = creationDateFrom ? `${moment(creationDateFrom).format('YYYY-MM-DD')},${moment(creationDateTo).format('YYYY-MM-DD')}` : '';
    const lastUpdated = updateDateFrom ? `${moment(updateDateFrom).format('YYYY-MM-DD')},${moment(updateDateTo).format('YYYY-MM-DD')}` : '';

    setValue({
      group: groupsState,
      published: status,
      initiator,
      author,
      autoStart,
      createdDate,
      lastUpdated
    })
  }

  const users = useMemo(() => {
    return Object.values(usersList).map(({ fullName, avatar, id }) => ({
      label: fullName,
      value: id,
      icon: <UserAvatar user={{
          avatar,
          id,
          fullName,
          firstName: '',
          fullNameRu: '',
          lastName: '',
          logName: '',
          phoneNumber: '',
          jobTitle: '',
          email: '',
          role: '',
        }}
        avatarSize={24}
        />
    }));
  }, [usersList]);

  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <h3>{t('filters.title')}</h3>
        {typeof close === 'function' && <ButtonBase onClick={() => close()}>
          <Close />
        </ButtonBase>}
      </header>

      <Box padding="0 16px" className={classes.content}>
        <GroupSelect
          title={t('groupsSettings.deleteModal.fieldLabel')}
          options={groups.map(g => ({
            label: g.name,
            value: g.sysName,
            icon: <img src={g.iconPath} alt={g.description} />
          }))}
          onChange={values => {
            setGroupsState(values);
          }}
          value={groupsState}
          isShownSearch
          multiple
          className={classes.select}
        />

        <GroupSelect
          title={t('customProcesses.table.status')}
          options={STATUSES}
          onChange={value => {
            if (value.length) {
              setStatus(value[0].value);
            } else {
              setStatus(undefined);
            }
          }}
          value={initialStatus}
          className={classes.select}
        />

          <GroupSelect
            title={t('filters.author')}
            options={users}
            onChange={values => {
              setAuthor(values);
            }}
            className={classes.select}
            value={author}
            multiple
            isShownSearch
          />

        <GroupSelect
          title={t('customProcesses.creationPage.processForm.initiator')}
          options={users}
          onChange={values => {
            setInitiator(values);
          }}
          className={classes.select}
          value={initiator}
          multiple
          isShownSearch
        />


          <GroupSelect
            title={t('customProcesses.table.autostart')}
            options={AUTOSTART}
            onChange={value => {
              if (value.length) {
                setAutostart(value[0].value);
              } else {
                setAutostart(undefined);
              }
            }}
            value={initialAutostart}
            className={classes.select}
          />

          <DatePeriodPicker
            className={classes.datePicker}
            label={t('customProcesses.table.creationDate')}
            dateFromValue={creationDateFrom}
            dateToValue={creationDateTo}
            onDateFromChange={setCreationDateFrom}
            onDateToChange={setCreationDateTo}
            selectedDatePeriod={creationDatePeriod}
            onSelectedDatePeriodChange={setCreationDatePeriod}
            hideCustomDaysTo={true}
            mainFiltersVariant
            preventInitialValuesSet
          />

          <DatePeriodPicker
            className={classes.datePicker}
            label={t('customProcesses.table.updateDate')}
            dateFromValue={updateDateFrom}
            dateToValue={updateDateTo}
            onDateFromChange={setUpdateDateFrom}
            onDateToChange={setUpdateDateTo}
            selectedDatePeriod={updateDatePeriod}
            onSelectedDatePeriodChange={setUpdateDatePeriod}
            hideCustomDaysTo={true}
            mainFiltersVariant
            preventInitialValuesSet
          />

        </Box>

      <footer className={classes.footer}>
          <Box display='flex' justifyContent='end' gap={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClear}
            >
              {t('customProcesses.filters.clearAll')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleApply}
            >
              {t('filters.filter_button_apply')}
            </Button>
          </Box>
      </footer>
    </div>
  );
}

export default Filter;
