import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
  root: {
    width: 500,
    height: "100%",
  },
  header: {
    background: "#007994",
    padding: "14px 16px"
  },
  modalTitle: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "20px",
    color: "white"
  },
  subHeader: {
    background: "#F7F8FC",
  },
  content: {
    flexGrow: 1,
    maxHeight: "100vh",
    overflow: "auto",

    background: "white"
  },
  footer: {
    padding: "12px 24px",
    background: "#F7F8FC",
  },
  tabs: {
    display: "flex",
    flexShrink: 0,
    padding: "0 16px",
    background: "white"
  },
  tab: {
    padding: 10,
    borderBottom: "2px solid transparent",
    color: "#007994",
    cursor: "pointer",
    fontWeight: 500,
  },
  activeTab: {
    borderBottom: "2px solid #007994",
  },
  btn: {
    borderRadius: 8
  }
}));

