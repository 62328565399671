import React, {ReactElement} from "react";
import {Box, Typography} from "@mui/material";

import { UserAvatar } from 'components';
import { ProcessGroupType, UserType } from 'types';

import useGroupSelectStyles from "./GroupSelect.useStyles";

interface GroupSelectCardProps {
  group: ProcessGroupType;
}

export const GroupSelectCard = ({ group }: GroupSelectCardProps): ReactElement => {
  const classes = useGroupSelectStyles();

  return (
    <Box className={classes.groupCard}>
      <img className={classes.groupCardIcon} src={group?.iconPath}/>
      <Box className={classes.groupInfoWrapper}>
        <Typography className={classes.groupCardName}>{group?.localizedName || group?.name}</Typography>
        <Typography className={classes.groupCardDescription}>{group?.localizedDescription || group?.description}</Typography>
      </Box>
    </Box>
  )
}
