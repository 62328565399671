import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import useApprovalsHistory from './useApprovalsHistory';
import useApprovalsFilter from './useApprovalsFilter';
import useApprovalsControl from './useApprovalsControl';
import {
  clearSelectedApproval,
  setActiveApprovalsCountAction,
  setApprovalsError,
  setReviewedApprovalsCountAction,
  setSelectedApproval,
  setSelectedReviewedCurrentTask,
  useApprovalsListState,
} from 'store/approvals';

const useApprovals = ({ reviewed, previousReviewed, completed }) => {
  const [requests, setRequests] = useState([]);
  const dispatch = useDispatch();
  const { data, loading, error, currentPage, pageSize } = useApprovalsListState();

  useEffect(() => {
    if (!data) return;
    setRequests(data?.content);
    if (reviewed) {
      dispatch(setReviewedApprovalsCountAction(data?.totalElements));
    } else {
      dispatch(setActiveApprovalsCountAction(data?.totalElements));
    }
  }, [data]);

  const {
    dateFrom,
    dateTo,
    handleDateFromChange,
    handleDateToChange,
    handleInitiatorChange,
    handleLegalEntityChange,
    handleProcessChange,
    handleSearchQueryChange,
    initiatorRequest,
    legalEntityRequest,
    processRequest,
    searchQuery,
  } = useApprovalsHistory();

  const {
    allAvailableTickets,
    clearSelectedTickets,
    closeSuccessModal,
    handlePageChange,
    handlePageSizeChange,
    isAllSelected,
    massLoading,
    modalInfo,
    onClick,
    selectAllTickets,
    selectedTickets,
    selectTickets,
    setMassApprove,
    showControls,
  } = useApprovalsControl({ requests, type: 'approvals' });

  const handleTaskSelection = (taskId, reviewedCurrentTaskId = '') => {
    dispatch(setSelectedApproval(taskId));
    if (reviewedCurrentTaskId) {
      dispatch(setSelectedReviewedCurrentTask(reviewedCurrentTaskId));
    }
  };

  const sendDateErrorCode = (errorMessage) => {
    dispatch(setApprovalsError(errorMessage));
  };

  const { getAllRequests } = useApprovalsFilter({
    dateFrom,
    dateTo,
    reviewed,
    previousReviewed,
    completed,
    sendDateErrorCode,
  });

  useEffect(() => {
    getAllRequests();
  }, [getAllRequests, reviewed]);

  useEffect(() => {
    return () => {
      dispatch(clearSelectedApproval());
    };
  }, []);

  return {
    allAvailableTickets,
    clearSelectedTickets,
    closeSuccessModal,
    currentPage,
    dateFrom,
    dateTo,
    error,
    handleDateFromChange,
    handleDateToChange,
    handleInitiatorChange,
    handleLegalEntityChange,
    handlePageChange,
    handlePageSizeChange,
    handleProcessChange,
    handleSearchQueryChange,
    handleTaskSelection,
    initiatorRequest,
    isAllSelected,
    legalEntityRequest,
    loading,
    massLoading,
    modalInfo,
    onClick,
    pageSize,
    processRequest,
    requests,
    searchQuery,
    selectAllTickets,
    selectedTickets,
    selectTickets,
    setMassApprove,
    showControls,
    totalElements: data?.totalElements,
    totalPages: data ? data.totalPages : 0,
  };
};

export default useApprovals;
