import React, { useContext, useMemo, useState } from 'react';
import useStyles from './Details.useStyles';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Box, Button, ButtonBase, Menu, MenuItem, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useTaskState } from 'store/requests';
import { Spinner } from 'components';
import { TaskInfo } from './TaskInfo';
import { DetailsHeader } from './Header';
import { ChangeAssigneeCallbackContext } from 'pages/AdminPanel/AdminPanel';
import { AdminPanelRow } from '../AdminPanelTable/AdminPanelTable.types';
import { RequestStatusesEnum } from 'pages/AdminPanel/AdminPanel.types';

type Props = {
  close: () => void
  openDeleteWindow: () => void
  currentRow: AdminPanelRow
}

export const Details = ({ close, openDeleteWindow, currentRow }: Props) => {
  const classes = useStyles();
  const { data: bpmTask, loading } = useTaskState();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const changeAssigneeContext = useContext(ChangeAssigneeCallbackContext);

  const hideDeleteButton = useMemo(() =>
      bpmTask?.businessTask?.taskStatus === RequestStatusesEnum.Completed || loading
    , [bpmTask, loading]);

  return <Box display="flex" flexDirection="column" className={classNames(
    classes.root,
  )}>

    {loading && <Spinner/>}

    <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.header}>
      <Typography className={classes.modalTitle}>{t('taskPdf.details')}</Typography>
      <ButtonBase onClick={close}>
        <Close htmlColor="#fff"/>
      </ButtonBase>
    </Box>

    <Box className={classes.subHeader}>
      {bpmTask && <DetailsHeader/>}
    </Box>

    <Box className={classes.tabs}>
      <Typography className={classNames(classes.tab, classes.activeTab)}>
        {t('detail.flow')}
      </Typography>
    </Box>

    <Box className={classes.content}>
      {bpmTask && <TaskInfo/>}
    </Box>

    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
        style: {
          width: 200,
          border: '1px solid rgba(38, 40, 66, 0.08)',
          boxShadow: '0px 8px 16px rgba(38, 40, 66, 0.04)',
          background: 'white',
          borderRadius: 10,
        },
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      PaperProps={{
        style: {
          paddingBottom: 8,
          border: 'none',
          boxShadow: 'none',
          background: 'transparent',
        },
      }}
    >
      {
        !currentRow?.businessTask.parallel && currentRow?.businessTask.status === RequestStatusesEnum.InProgress && currentRow?.businessTask.assignee
        &&
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            changeAssigneeContext.action({
              assigneeId: currentRow.businessTask.assignee,
              taskId: currentRow.businessTask.taskId,
              stepName: t(`constructor-${currentRow.businessTask.processSysName}.actions.${currentRow.currentAction.sysName}.name`, { defaultValue: currentRow.currentAction.name }),
            });
          }}
        >
          <Typography>
            {t('AdminPanel.actions.changeAssignee')}
          </Typography>
        </MenuItem>
      }

      {!hideDeleteButton &&
        <MenuItem
          onClick={() => {
            openDeleteWindow();
            setAnchorEl(null);
          }}
        >
          <Typography
          >
            {t('customProcesses.table.actions.delete')}
          </Typography>
        </MenuItem>
      }
    </Menu>

    <Box className={classes.footer} display="flex" justifyContent="space-between">
      {!hideDeleteButton &&
        <Button
          className={classes.btn}
          variant="contained"
          color="secondary"
          onClick={e => setAnchorEl(e.currentTarget)}
        >
          {t('buttons.manageRequest')}
        </Button>
      }

      {hideDeleteButton && <div></div>}

      <Button
        className={classes.btn}
        variant="contained"
        color="secondary"
        onClick={close}
      >
        {t('filters.filter_button_close')}
      </Button>
    </Box>
  </Box>;
};
