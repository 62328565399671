import React, { useCallback, useEffect, useState, useMemo, memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import isArray from 'lodash/isArray';

import EntityDisplay from 'components/EntityDisplay/EntityDisplay';
import { Spinner } from 'components';

import {useUserProfile, useUsersRole} from "hooks";
import { getEntityInstanceById } from 'api/requests';
import { useTaskState } from 'store/requests';
import { refreshEntitiesAction, useCustomModalState } from 'store/main';
import { getStateAttributes } from 'components/EntityDisplay/utils';

import { TaskParametersType } from 'types';

import { ATTACHMENTS_TAB_NAME } from 'components/TaskDetails/components/Tabs/FormTab/constants';

import useStyles from './useStyles';

const LOADING_ERROR = 'Error loading entities.';
const DEFAULT_MINIMUM_VALIDATION_ERROR =
  'The minimum number of elements is {M}';

type Attribute = {
  attributeSysName: string;
};

type Props = {
  name: string;
  titleAttribute: string;
  displayState: string;
  designVariant: string;
  suppliersListAttribute?: string;
  tabName?: string;
  updateAttachmentsCounter?: any;
  buttonList?: any[];
  hasMinimumNumberOfElements?: boolean;
  minimum?: number;
  errorText?: string;
  validateByInstancesFieldsEmptiness?: boolean;
  instanceFieldName?: string;
  isValidationResultSaved?: boolean;
  validationResultAttribute?: any;
  handleAction: (params: {
    action: string;
    tplName?: string;
    componentParams?: { [key: string]: any };
    instanceFormFieldName?: string;
    instance?: any;
  }) => void;
  setMainFormValue: (field: string, value: any) => void;
};

export const EntitiesListDisplay = memo(
  ({
    name,
    titleAttribute,
    displayState,
    designVariant = 'accordion',
    suppliersListAttribute = '',
    tabName = '',
    updateAttachmentsCounter,
    buttonList = [],
    hasMinimumNumberOfElements,
    minimum,
    errorText,
    validateByInstancesFieldsEmptiness,
    instanceFieldName,
    isValidationResultSaved,
    validationResultAttribute,
    handleAction,
    setMainFormValue,
  }: Props) => {
    const dispatch = useDispatch();

    const { data: bpmTask } = useTaskState();
    const { id: profileId, nickname: profileNickName } = useUserProfile();
    const { hasAccessToPromoteRequests } = useUsersRole();

    const { refreshEntities } = useCustomModalState();
    const {
      watch,
      setValue,
      control
    } = useFormContext();

    const isAssignee: boolean = useMemo((): boolean =>
      bpmTask?.isAssignee(profileId, hasAccessToPromoteRequests) ||
      bpmTask?.isAssignee(profileNickName, hasAccessToPromoteRequests),
      [bpmTask, profileId, profileNickName, hasAccessToPromoteRequests]);

    const [expandedList, setExpandedList] = useState([]);
    const [instancesList, setInstancesList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [validationError, setValidationError] = useState('');

    const getHeaderAttributesByList = useCallback(
      (attributes: Attribute[]): TaskParametersType[] =>
        bpmTask && attributes.length
          ? attributes.map(({ attributeSysName }) =>
              bpmTask.getAttributeBySysName(attributeSysName)
            )
          : [],
      [bpmTask]
    );

    const suppliersListAtrributeName = useMemo(() => {
      if (!bpmTask || !suppliersListAttribute) {
        return name;
      }

      const listAttribute = bpmTask.getAttributeBySysName(
        suppliersListAttribute
      );

      return listAttribute?.name;
    }, [suppliersListAttribute, bpmTask, name]);

    const value = watch(suppliersListAtrributeName);

    const getAllInstances = useCallback(async () => {
      const parsedValue = value?.split(',')?.filter(value => !!value) || [];
      const isLengthSame = parsedValue?.length !== instancesList?.length;

      if ((isLengthSame || !!refreshEntities) && !loading && !error) {
        setError('');
        setLoading(true);

        try {
          const loadingInstances = parsedValue.map(async id =>
            await getEntityInstanceById(id)
          );

          const loadedInstances = await Promise.all(loadingInstances);
          setInstancesList(loadedInstances);
        } catch (error) {
          setError(LOADING_ERROR);
        } finally {
          setLoading(false);
          dispatch(refreshEntitiesAction(false));
        }
      }
    }, [value, loading, refreshEntities, setInstancesList]);

    useEffect(() => {
      getAllInstances()
    }, [getAllInstances]);

    useEffect(() => {
      const displayedFileAttributes = instancesList
        .map((instance) =>
          getStateAttributes(instance, displayState).filter(
            (attr) => attr.component === 'file'
          )
        )
        .flat();

      const instancesAttachmentsCount = displayedFileAttributes.reduce(
        (
          counter: number,
          component: { value: string | any[]; component: string }
        ) => {
          if (component.component !== 'file') {
            return counter;
          }

          const arrayValue =
            typeof component.value === 'string'
              ? component.value.split(',')
              : (component.value as any[]);

          const arrayValueWithoutDuplicatesAndNulls = arrayValue.reduce(
            (arr, v) => (!v || arr.includes(v) ? arr : [...arr, v]),
            []
          );

          return counter + arrayValueWithoutDuplicatesAndNulls.length;
        },
        0
      ) as number;

      updateAttachmentsCounter &&
        updateAttachmentsCounter(name, instancesAttachmentsCount);
    }, [instancesList, displayState, updateAttachmentsCounter]);

    useEffect(() => {
      // validation by required minimum number of elements
      if (hasMinimumNumberOfElements) {
        const parsedValue = value?.split(',')?.filter((v) => !!v) || [];
        const numberOfElements = parsedValue.length;

        if (numberOfElements < minimum) {
          const errorTemplate = errorText || DEFAULT_MINIMUM_VALIDATION_ERROR;
          const formattedErrorText = errorTemplate.replace('{M}', '' + minimum);
          setValidationError(formattedErrorText);
        } else {
          setValidationError('');
        }

        if (isValidationResultSaved) {
          const resultAttribute = bpmTask.getAttributeBySysName(
            validationResultAttribute
          );
          const validationResultAttributeName = resultAttribute?.name;
          setValue(validationResultAttributeName, numberOfElements < minimum);
        }
      }

      // validation by not emptyness of specified attributes of the displayed instances
      // true - specified attribute of at least one instance if empty or false
      // false - specified attribute of all instances is not empty and not false
      if (validateByInstancesFieldsEmptiness) {
        let result = false;

        instancesList.forEach((instance) => {
          if (!instance) {
            return;
          }

          const instanceKeys = Object.keys(instance);
          instanceKeys.forEach((instanceKey) => {
            if (!instanceKey.includes('Attributes')) {
              return;
            }

            const instancePart = instance[instanceKey];
            instancePart.forEach(({ name, value }) => {
              if (name === instanceFieldName) {
                const isEmpty = isArray(value)
                  ? !value.filter((v) => v !== null && v !== undefined)
                  : !value;

                result = result || isEmpty;
              }
            });
          });
        });

        if (isValidationResultSaved) {
          const resultAttribute = bpmTask.getAttributeBySysName(
            validationResultAttribute
          );
          setValue(resultAttribute?.name, result);
        }
      }
    }, [value, instancesList, setValue]);

    const handleEntityDisplayChange = useCallback(
      (entityId) => (e, isExpanded) => {
        if (isExpanded) {
          setExpandedList((v) => v.concat([entityId]));
        } else {
          setExpandedList((v) => v.filter((id) => id !== entityId));
        }
      },
      [setExpandedList]
    );

    const headerComponentsList = useMemo(
      () => (isAssignee ? getHeaderAttributesByList(buttonList) : []),
      [isAssignee, buttonList]
    );

    const classes = useStyles();

    if (error) {
      return <Typography color="error">{error}</Typography>;
    }

    return (
      <div className={classes.root}>
        <Controller
          control={control}
          name={name}
          render={() => (
            <input type="hidden"data-selenium={name} />
          )}
        />

        {loading && (
          <Spinner/>
        )}

        {!loading && instancesList.map((instance, instanceKey) => (
          <EntityDisplay
            key={instanceKey}
            instance={instance}
            instancesList={instancesList}
            setInstancesList={setInstancesList}
            labelAttribute={titleAttribute}
            displayState={displayState}
            headerComponents={headerComponentsList}
            formFieldName={name}
            showOnlyAttachments={tabName === ATTACHMENTS_TAB_NAME}
            designVariant={
              tabName === ATTACHMENTS_TAB_NAME ? 'list' : designVariant
            }
            expanded={expandedList.includes(instance?.id)}
            onChange={handleEntityDisplayChange(instance?.id)}
            handleAction={handleAction}
            setMainFormValue={setMainFormValue}
          />
        ))}
        {!!validationError && (
          <Typography color="error">{validationError}</Typography>
        )}
      </div>
    );
  },
  (prevProps, nextProps) =>
    prevProps.name === nextProps.name &&
    prevProps.titleAttribute === nextProps.titleAttribute &&
    prevProps.displayState === nextProps.displayState &&
    prevProps.designVariant === nextProps.designVariant &&
    prevProps.tabName === nextProps.tabName &&
    prevProps.buttonList?.length === nextProps.buttonList?.length &&
    prevProps.hasMinimumNumberOfElements ===
      nextProps.hasMinimumNumberOfElements &&
    prevProps.minimum === nextProps.minimum &&
    prevProps.errorText === nextProps.errorText &&
    prevProps.validateByInstancesFieldsEmptiness ===
      nextProps.validateByInstancesFieldsEmptiness &&
    prevProps.instanceFieldName === nextProps.instanceFieldName &&
    prevProps.isValidationResultSaved === nextProps.isValidationResultSaved
);
