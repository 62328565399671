import { createAction } from 'redux-actions';

import { searchRequests as searchRequestsApi } from 'api/requests';

export const SEARCH_ACTIONS = {
  SEARCH_REQUESTS: 'SEARCH_REQUESTS',
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
  SET_CURRENT_PAGE_SIZE: 'SET_CURRENT_PAGE_SIZE',
  SET_FILTERS: 'SET_FILTERS'
}

export const searchRequests = createAction(
  SEARCH_ACTIONS.SEARCH_REQUESTS,
  params => searchRequestsApi(params)
);

export const setSearchCurrentPage = createAction(
  SEARCH_ACTIONS.SET_CURRENT_PAGE,
  (page, refresh=true) => ({ page, refresh })
);

export const setSearchCurrentPageSize = createAction(
  SEARCH_ACTIONS.SET_CURRENT_PAGE_SIZE
);

export const setFilters = createAction(
  SEARCH_ACTIONS.SET_FILTERS
);
