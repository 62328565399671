import { useEffect, useMemo } from 'react';
import { useUsersRole } from './useUsersRole';
import { useHistory } from 'react-router-dom';
import { environment } from 'environments/environment';

type PageAccessProps = {
  requiredPermissions?: string[];
  redirectPath?: string;
  hasAccess?: boolean;
  isExternalRedirect?: boolean;
};

export const usePageAccess = ({
  requiredPermissions = [],
  hasAccess = false,
  redirectPath = '/requests/active',
  isExternalRedirect = false
}: PageAccessProps) => {
  const {
    areACLUserAccessesLoading,
    areTemplateProcessesAccessesLoading,
    currentUserPermissions
  } = useUsersRole();

  const history = useHistory();

  const hasAccessToPage = useMemo(() => {
    if (areACLUserAccessesLoading || areTemplateProcessesAccessesLoading) {
      return true;
    }

    if (requiredPermissions.length) {
      return requiredPermissions.some((permission) =>
        currentUserPermissions.includes(permission)
      );
    }

    return hasAccess;
  }, [
    areACLUserAccessesLoading,
    areTemplateProcessesAccessesLoading,
    currentUserPermissions,
    requiredPermissions,
    hasAccess
  ]);

  useEffect(() => {
    if (!hasAccessToPage) {
      if (isExternalRedirect) {
        window.location.href = environment.baseUrl + redirectPath;
      } else {
        history.push(redirectPath);
      }
    }
  }, [hasAccessToPage, isExternalRedirect, history]);
};
