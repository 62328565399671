import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { Box, ButtonBase, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { useTaskState } from 'store/requests';
import { useUserProfile } from 'hooks';

import { Assignee } from '../../Assignee';
import { CombinedStepType, StepType } from './history.types';

import ArrowTertiary from 'assets/images/icons/history-arrow-tertiary.svg';
import ArrowGrey from 'assets/images/icons/history-arrow-grey.svg';
import CircleTertiary from 'assets/images/icons/history-circle-tertiary.svg';
import CircleGrey from 'assets/images/icons/history-circle-grey.svg';
import EditRegular from 'assets/images/icons/edit_regular.svg';

import useStyles from './useStyles';
import { ChangeAssigneeCallbackContext } from 'pages/AdminPanel/AdminPanel';

// TODO: refactor, move change assignee in HistoryTab

type Props = {
  step: StepType | CombinedStepType
  isFirstStep: boolean;
  isLastStep: boolean;
  companyId: string;
  hideTimeline?: boolean;
  branchStepItem?: boolean;
  topBranchStepItem?: boolean;
};

export const HistoryTabStep = ({
  step,
  isFirstStep = false,
  isLastStep = false,
  companyId,
  hideTimeline = false,
  branchStepItem = false,
  topBranchStepItem = false,
}: Props) => {
  const classes = useStyles({
    isStepCompleted: step.completed,
    isLastStep,
    isFutureStep: step.isFutureStep,
    isParallelStep: step.parallel,
    isOpenedStep: step.isOpenedStep,
    isBranchStep: branchStepItem,
  });

  const changeAssigneeContext = useContext(ChangeAssigneeCallbackContext);

  const { data: bpmTask } = useTaskState();
  const { t } = useTranslation();
  const { companyName } = useUserProfile();

  const getAssigneeList = useCallback((assignee: string) => {
    if (assignee?.includes(',') || assignee?.includes(';')) {
      const separator = assignee?.includes(',') ? ',' : ';';
      return assignee?.split(separator).map((assignee) => assignee.trim());
    }

    return [];
  }, []);

  const handleChangeAssignee = (assigneeId: string, taskId?: string) => {
    changeAssigneeContext.action({
      assigneeId,
      taskId: taskId || step.taskId,
      stepName: t(`constructor-${bpmTask.processSysName}.actions.${step.actionSysName}.name`, { defaultValue: step.taskName }),
    });
  };

  const signRequiredSteps: number[] = useMemo(() => {
    if(bpmTask) {
      const docflowField = bpmTask?.attributes?.find((attr) => attr.name.includes('documentFromDocflow-'));
      if (docflowField) {
        const params = JSON.parse(docflowField?.componentParams || '{}');
        return params?.signRequiredSteps || [];
      }
    }
    return [];
  }, [bpmTask]);
  
  const AssigneeInformation = useMemo(() => {
    const { candidateUsers, assignee } = step;

    const isAssigneeArrayExists = !!assignee && (assignee?.includes(',') || assignee?.includes(';'));
    const isCandidateUsersExists = Array.isArray(candidateUsers) && candidateUsers?.length;

    if (isAssigneeArrayExists || isCandidateUsersExists) {
      const assigneeArray = getAssigneeList(assignee);

      const candidateUsersArray =
        isCandidateUsersExists ?
        candidateUsers :
          [];

      const possibleUsersArray = [
        ...assigneeArray,
        ...candidateUsersArray,
      ];

      return (
        <>
          <Typography className={classes.multipleAssigneesLabel}>
            {step.parallel
             ? <>{t('task_history.parallelAssigneesLabel')}{' '}({possibleUsersArray.length})</>
             : t('task_history.possible_assignees')}
          </Typography>

          {possibleUsersArray.map(possibleUser => (
            <div className={classes.multipleAssigneesUser}>
              <Assignee assignee={possibleUser} companyId={companyId}/>
            </div>
          ))}
        </>
      );
    }

    // eslint-disable-next-line no-template-curly-in-string
    if (assignee === 'company' || assignee === '${company}') {
      return <Typography className={classes.assigneeText}>{companyName}</Typography>;
    }

    // eslint-disable-next-line no-template-curly-in-string
    if (assignee === 'HeadCheck' || assignee === '${HeadCheck}') {
      return <Typography
        className={classes.assigneeText}>{t('customProcesses.creationPage.processForm.assigneeDirectManager')}</Typography>;
    }

    // eslint-disable-next-line no-template-curly-in-string
    if (assignee === '${performer}' || assignee === 'performer') {
      return <Typography
        className={classes.assigneeText}>{t('customProcesses.creationPage.processForm.assigneePerformer')}</Typography>;
    }

    if (assignee) {
      return (
        <Box display="flex" gap={6}>
          <Assignee assignee={assignee} companyId={companyId}/>
          {
            changeAssigneeContext?.isAvailiable &&
            changeAssigneeContext?.action &&
            step.isCurrentStep &&
            <ButtonBase
              onClick={() => handleChangeAssignee(assignee)}
              className={classes.editBtn}
              disabled={signRequiredSteps?.includes(step.stepperOrder)}
            >
              <img src={EditRegular} alt="edit"/>
            </ButtonBase>
          }
        </Box>
      );
    }

    return (
      <Typography className={classes.assigneeText}>{t('task_history.no_assignee')}</Typography>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const timelineIcon = useMemo(() => {
    if (isFirstStep) {
      return CircleTertiary;
    }

    if (isLastStep) {
      return step.isFutureStep || branchStepItem ? CircleGrey : CircleTertiary;
    }

    return step.isFutureStep || branchStepItem ? ArrowGrey : ArrowTertiary;
  }, [isFirstStep, isLastStep, step.isFutureStep]);

  const stepInfo = useMemo(() => {
    if (step.isCombinedStep) {
      const hasRejectOrReworkTasks = (step as CombinedStepType).approveStageTypesList.some(status => ['reject', 'rework'].includes(status));
      return <>
        <span className={classes.multipleAssigneesLabel}>
          {t('task_history.parallelAssigneesLabel')}{' '}({(step as CombinedStepType).assigneeList.length})
        </span>
        {(step as CombinedStepType).assigneeList.map((assigneeId, index) => (
          <div className={classes.parallelStepInfoWrapper}>
            <div
              className={cn({ [classes.historyItemDataFutureStep]: hasRejectOrReworkTasks && !(step as CombinedStepType).completedStatuses[index] })}>

              <Box display="flex" gap={6}>
                <Assignee assignee={assigneeId} companyId={companyId}/>
                {
                  changeAssigneeContext?.isAvailiable &&
                  changeAssigneeContext?.action &&
                  step.isCurrentStep &&
                  !(step as CombinedStepType).completedStatuses[index] &&
                  <ButtonBase
                    onClick={() => {
                      handleChangeAssignee(assigneeId, (step as any).taskIdList[index]);
                    }}
                    className={classes.editBtn}
                    disabled={signRequiredSteps?.includes(step.stepperOrder)}
                  >
                    <img src={EditRegular} alt="edit"/>
                  </ButtonBase>
                }
              </Box>

            </div>
            {!step.isFutureStep &&
              <Typography className={classes.taskStatus}>
                {(step as CombinedStepType).completedStatuses[index]
                 ?
                 <>
             <span className={cn({
               [classes.taskStatusApprove]: (step as CombinedStepType).approveStageTypesList[index] === 'approve',
               [classes.taskStatusRework]: (step as CombinedStepType).approveStageTypesList[index] === 'rework',
               [classes.taskStatusReject]: (step as CombinedStepType).approveStageTypesList[index] === 'reject',
             })}>{(step as CombinedStepType).approveStageList[index]}</span>
                   {'  '}{(step as CombinedStepType).completionDatesList[index]}
                 </>
                 : (!hasRejectOrReworkTasks &&
                    <span className={classes.taskStatusWaitingApproval}>{t('task_history.waitingApproval')}</span>)
                }
              </Typography>
            }
          </div>
        ))}
      </>;
    }

    return <>
      {AssigneeInformation}
      <Typography className={classes.taskStatus}>
        {step.approveStage !== '[Cancel]' && (
          step.isCurrentStep && step.approveStage !== '[Reject]' ? (
            <span className={classes.taskStatusWaitingApproval}>
              {t('task_history.waitingApproval')}
            </span>
          ) : step.rejectedStep && step.nextStep?.approveStage !== '[Reject]' ? (
            <span className={classes.taskStatusWaitingApproval}>
              {t('task_history.interrupted')}
          </span>
          ) : (
            <>
              <span
                className={cn({
                  [classes.taskStatusApprove]: step.displayApproveStageType === 'approve',
                  [classes.taskStatusRework]: step.displayApproveStageType === 'rework',
                  [classes.taskStatusReject]: step.displayApproveStageType === 'reject',
                })}
              >
                {step.displayApproveStage}
              </span>
              {'  '}{step.completionDateString}
            </>
          ))}
      </Typography>
    </>;
  }, [step]);

  return (
    <Box className={cn(classes.historyItemWrapper, {[classes.historyItemWrapperNoTimeline]: hideTimeline, [classes.historyItemWrapperBranchStep]: branchStepItem, [classes.historyItemWrapperTopBranchStep]: topBranchStepItem})}>
      {!hideTimeline &&
        <div className={classes.timelinePart}>
          <div className={classes.timelinePoint}>
            <img src={timelineIcon} alt=""/>
          </div>
          {!isFirstStep && <div className={classes.timelineLine}></div>}
        </div>
      }

      <div className={cn(classes.historyItemData, { [classes.historyItemDataNoTimeline]: hideTimeline, [classes.historyItemDataFutureStep]: step.approveStage === '[Cancel]' || step.isFutureStep })}>
        <Typography className={classes.taskName}>
          {t(`constructor-${bpmTask.processSysName}.actions.${step.actionSysName}.name`, { defaultValue: step.taskName })}
        </Typography>
        {stepInfo}
      </div>
    </Box>
  );
};
