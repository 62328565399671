import React, { ReactElement, useEffect, useMemo, useState, useRef } from 'react';
import { Fade, Typography } from '@mui/material';
import { NotificationManager } from 'react-notifications';
import { useTranslation } from 'react-i18next';
import { debounce, DebouncedFunc } from 'lodash';

import { isArraysEqual } from './ProcessAutoSave.utils';
import { ConditionStep, DocumentTemplate, ParallelBranchesGroup, ProcessStep } from '../../TemplateProcesses.types';

import useProcessAutoSaveStyles from './ProcessAutoSave.useStyles';
import DoneIcon from 'assets/images/icons/done.svg';

interface ProcessAutoSaveProps {
  processSteps: ProcessStep[],
  conditions: ConditionStep[],
  documents: DocumentTemplate[],
  parallelBranchesGroups: ParallelBranchesGroup[],
  fieldsSettingsForm: any,
  allProcessStepsFields: any,
  directManagerSteps: number[],
  performerSelectionSteps: number[],
  isAutostartActive: boolean,
  autostartSettings: any,
  summaryFields: string[],
  onAutoSave: (currentForm: ProcessStep[]) => Promise<void>,
  isActive?: boolean,
}

export const ProcessAutoSave = ({
  processSteps,
  fieldsSettingsForm,
  allProcessStepsFields,
  conditions,
  documents,
  parallelBranchesGroups,
  directManagerSteps,
  performerSelectionSteps,
  isAutostartActive,
  autostartSettings,
  summaryFields,
  onAutoSave,
  isActive = true,
}: ProcessAutoSaveProps): ReactElement => {
  const classes = useProcessAutoSaveStyles();
  const initialAutoSave = useRef(true);

  const { t } = useTranslation();

  const [isDraftSaved, setIsDraftSaved] = useState<boolean>(false);
  const [formOldVersion, setFormOldVersion] = useState<ProcessStep[]>([]);
  const [conditionsOldVersion, setConditionsOldVersion] = useState<ConditionStep[]>([]);
  const [parallelBranchesGroupsOldVersion, setParallelBranchesGroupsOldVersion] = useState<ParallelBranchesGroup[]>([]);
  const [documentsOldVersion, setDocumentsOldVersion] = useState<DocumentTemplate[]>([]);

  const handleDraftSavedSuccess = (): void => {
    setIsDraftSaved(true);

    setTimeout((): void => {
      setIsDraftSaved(false);
    }, 1000);
  };

  const saveProcess = async (currentForm: ProcessStep[]) => {
    if (!isActive || (isArraysEqual(currentForm, formOldVersion) && isArraysEqual(conditions, conditionsOldVersion)
      && isArraysEqual(parallelBranchesGroups, parallelBranchesGroupsOldVersion) && isArraysEqual(documents, documentsOldVersion))) {
      return;
    }

    setFormOldVersion(currentForm);
    setConditionsOldVersion(conditions);
    setParallelBranchesGroupsOldVersion(parallelBranchesGroups);
    setDocumentsOldVersion(documents);

    try {
      await onAutoSave(currentForm);
      handleDraftSavedSuccess();
    } catch (error) {
      NotificationManager.error(t(error?.message || 'errors.somethingIsWrong'), `${t('customProcesses.notifications.error')}!`);
    }
  }

  useEffect(() => {
    if (processSteps && initialAutoSave.current) {
      setTimeout(() => processSteps && saveProcess(processSteps), 400);
    }
  }, [processSteps])

  const debouncedProcessStepsHandler = useMemo((): DebouncedFunc<(values: ProcessStep[]) => Promise<void>> =>
      debounce(async (currentForm: ProcessStep[]): Promise<void> => {
        if (!isActive || (isArraysEqual(currentForm, formOldVersion) && isArraysEqual(conditions, conditionsOldVersion)
          && isArraysEqual(parallelBranchesGroups, parallelBranchesGroupsOldVersion) && isArraysEqual(documents, documentsOldVersion))) {
          return;
        }

        setFormOldVersion(currentForm);
        setConditionsOldVersion(conditions);
        setParallelBranchesGroupsOldVersion(parallelBranchesGroups);
        setDocumentsOldVersion(documents);

        try {
          await onAutoSave(currentForm);
          handleDraftSavedSuccess();
        } catch (error) {
          NotificationManager.error(t(error?.message || 'errors.somethingIsWrong'), `${t('customProcesses.notifications.error')}!`);
        }
      }, 3000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      processSteps,
      conditions,
      parallelBranchesGroups,
      documents,
      fieldsSettingsForm,
      allProcessStepsFields,
      directManagerSteps,
      performerSelectionSteps,
      isAutostartActive,
      autostartSettings,
      summaryFields,
      onAutoSave,
    ]);

  useEffect((): () => void => {
    if (processSteps) {
      debouncedProcessStepsHandler(processSteps);
    }

    return () => {
      debouncedProcessStepsHandler.cancel();
      initialAutoSave.current = false
    };
  }, [
    debouncedProcessStepsHandler,
  ]);

  return (
    <Fade in={isDraftSaved} timeout={300}>
      <Typography className={classes.draftStatus} variant="caption">
        <img src={DoneIcon} alt="Done"/> {t('customProcesses.creationPage.auto-saved')}
      </Typography>
    </Fade>
  );
};
