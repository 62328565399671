const ERROR_MESSAGE_IN_TEXT_PREFIX = '[Message:';

const ERROR_TYPES = {
  NETWORK: 'Network Error',
};

const ERROR_MESSAGES = {
  NETWORK:
    'The network connection is lost. Check the Internet, disable VPN or third-party Browser programs.',
};

const getErrorMessage = (currentErrorType) => {
  // TODO - localize
  for (const errorType of Object.keys(ERROR_TYPES)) {
    if (currentErrorType === ERROR_TYPES[errorType]) {
      return ERROR_MESSAGES[errorType];
    }
  }

  // parse error message from error text
  const isErrorWithMessageInText = currentErrorType?.includes(
    ERROR_MESSAGE_IN_TEXT_PREFIX
  );
  if (isErrorWithMessageInText) {
    const errorMessageLine = currentErrorType
      .split('\n')
      .filter((v) => v.includes(ERROR_MESSAGE_IN_TEXT_PREFIX))[0] as string;
    const errorMessageIndex =
      errorMessageLine.indexOf(ERROR_MESSAGE_IN_TEXT_PREFIX) +
      ERROR_MESSAGE_IN_TEXT_PREFIX.length;
    return errorMessageLine.substring(errorMessageIndex).trim();
  }

  return currentErrorType;
};

export default getErrorMessage;
