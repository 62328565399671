import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { useTaskState } from 'store/requests';

type Props = {
  name: string;
  params: {
    childrenComponentSysName?: string;
  }
};

const useRadioButtonGroup = ({
  name,
  params: {
    childrenComponentSysName = ''
  }
}: Props) => {
  const { watch, setValue } = useFormContext();
  const currentValue = watch(name);

  const { data: bpmTask } = useTaskState();

  const [inputValue, setInputValue] = useState(watch(name) || null);

  const isChildrenAttributeExists = useMemo((): boolean =>
      !!childrenComponentSysName,
    [childrenComponentSysName]);

  const childrenAttribute = useMemo(() =>
      bpmTask && isChildrenAttributeExists ?
        bpmTask.getAttributeBySysName(childrenComponentSysName) : null,
    [bpmTask, isChildrenAttributeExists, childrenComponentSysName]);

  const childrenAttributeHandleChange = useCallback((newValue: string): void =>
    childrenAttribute && setValue(childrenAttribute.name, newValue),
    [childrenAttribute, setValue]);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = (event.target as HTMLInputElement).value;

    setValue(name, newValue);
    setInputValue(newValue);

    childrenAttributeHandleChange(newValue);
  }, [setValue, name, childrenAttributeHandleChange]);

  /* Функция которая ожидает изменении значения компонента radio-button-group
  за пределами этого компонента

  Пример:
  useEffect действие касательно массива changeValueOnFields* который может
  поменять значение любого компонента за пределами этого компонента

  * changeValueOnFields находится в файле useField.ts (pages/Task/TaskForm/FormFields) */

  useEffect(() => {
    if (currentValue && inputValue !== currentValue) {
      setInputValue(currentValue);
      childrenAttributeHandleChange(currentValue);
    }
  }, [currentValue, inputValue, childrenAttributeHandleChange]);

  return {
    inputValue,
    handleChange,
  }
};

export default useRadioButtonGroup;
