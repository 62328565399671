import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => {
  return {
    root: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,

      background: "rgba(38, 40, 66, 0.40)",
      zIndex: 1
    },
    content: {
      display: "flex",
      flexDirection: "column",
      width: 900,
      height: "100%",
      backgroundColor: "#fff",
      marginLeft: "auto"
    },
    header: {
      padding: "12px 24px",
      borderBottom: "1px solid rgba(38, 40, 66, 0.08)",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    title: {
      fontSize: 20,
      fontWeight: 400,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    },
    groupName: {
      display: "flex",
      alignItems: "center",

      fontSize: 14,
      fontWeight: 400,
      color: "#6D6E85",

      "& img": {
        width: 16,
        height: 16,
        borderRadius: 4,
        marginRight: 8
      }
    },
    flowWrapper: {
      background: "#F7F8FC",
      height: "calc(100% - 75px)",
    },
    flow: {
      height: "100%",
      overflow: "auto",
      padding: "8px 0",
    },
    errorBox: {
      position: "sticky",
      top: 0,
      zIndex: 22,

      maxWidth: 360,
      height: 40,
      margin: "0 auto",
      marginTop: 8,

      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",

      padding: "0 16px",
      borderRadius: 12,
      background: "#FFA530",
      boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.08)",

    },
    controlBtn: {
      borderRadius: "100%"
    },
    startBtn: {
      height: 40,
      marginRight: 12
    }
  }
})
