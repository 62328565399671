import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => ({
  massActionsPanelWrapper: {
    marginLeft: 24,
    marginRight: 24,
    marginTop: 12,
  },
  emptyBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F7F8FC',
    flex: 1,
    alignSelf: 'center',
  },
}));
