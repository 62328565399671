import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import {
  TextField as MuiTextField,
  Popper as MuiPopper
} from '@mui/material';
import { theme } from '@dartech/dms-ui';

export const useTreeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      color: theme.palette.text.secondary,
      '&:hover > $content, &:hover > $content $label': {
        backgroundColor: '#E6ECF1',
      },
      '&:focus > $content, &:focus > $content $label': {
        backgroundColor: '#E6ECF1',
      },
      '&$selected > $content, &$selected > $content $label': {
        backgroundColor: '#fff'
      },
      '&$selected:hover > $content label, &$selected:hover > $content': {
        backgroundColor: '#E6ECF1',
      },
      '&$selected:focus > $content $label, &$selected:focus > $content': {
        backgroundColor: '#E6ECF1',
      },
      '&$expanded > $content, &$expanded > $content $label': {
        backgroundColor: '#fff'
      },
      '&$expanded:hover > $content, &$expanded:hover > $content $label': {
        backgroundColor: '#fff'
      },
      '&$expanded > $content:hover, &$expanded > $content:hover $label': {
        backgroundColor: '#E6ECF1',
      },
      '&$expanded > $content > label:hover': {
        backgroundColor: '#E6ECF1',
      }
    },
    content: {
      height: 40,
      width: '100%',
      color: theme.palette.text.secondary,
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      '&:hover, &:hover > $label': {
        backgroundColor: '#E6ECF1',
      }
    },
    group: {
      paddingLeft: 26,
      marginLeft: 0,
      '& $content': {
        paddingLeft: theme.spacing(2),
      },
    },
    expanded: {
      '& > $content': {
        backgroundColor: '#fff',
      },
      '& > $content $label': {
        backgroundColor: '#fff',
      },
    },
    selected: {
    },
    iconContainer: {},
    hideIconContainer: {
      visibility: 'hidden',
    },
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      '&:hover': {
        backgroundColor: '#E6ECF1 !important',
      }
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
    },
    labelIcon: {},
    labelText: {
      flexGrow: 1,
      color: '#262842',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      marginLeft: 6,
    },
    labelTextDisabled: {
      color: '#A9A9B8 !important'
    }
  }),
);

export const useTreeStyles = makeStyles(
  createStyles({
    root: {
      flexGrow: 1,

    },
  }),
);

export const useInputStyles = makeStyles(
  createStyles({
    conditionFieldText: {
      '& div': {
        width: 240,
        height: 40,
        marginLeft: 12,
        marginTop: 12,
        border: '1px solid rgba(38, 40, 66, 0.12)',
        borderRadius: 10,
      }
    }
  }),
);

const Input = styled(MuiTextField)`
  border-radius: 8px
`;
// width: 100%;
// .${HCMS_PREFIX}-MuiCircularProgress-colorPrimary {
//   color: ${theme.palette.primary[200]};
// }
// .${HCMS_PREFIX}-MuiSvgIcon-root {
//   color: ${theme.palette.success[500]};
// }
// .${HCMS_PREFIX}-MuiInputBase-input.Mui-disabled {
//   color: ${theme.palette.grey[700]};
// }
// `;

const Popper = styled(MuiPopper)`
  z-index: 1350;
  width: 100%;
  min-width: 300px;
  max-height: 360px;
  height: fit-content;
  margin-top: 12px;
  margin-left: 8px;
  margin-bottom: 12px;
`;

const ProjectSelectContainer = styled.div<{ visible?: boolean, isSingleValueSelect?: boolean } & PropsWithChildren<any>>`
  padding: 0px;
  box-shadow: none;
  border: 1px solid transparent;
  transition: box-shadow 0.3s, border 0.3s;

  ${props => props.visible && `
    background: #F5F8FA;
    margin: -8px;
    padding: 8px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
    border: 1px solid #E0E3EA;
    border-radius: 12px;
  `}

  ${props => props.isSingleValueSelect && `
    padding-bottom: 0;
    overflow: hidden;
  `}

  &:hover {
    background: #F5F8FA;
    margin: -8px;
    padding: 8px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
    border: 1px solid #E0E3EA;
    border-radius: 12px;

    ${props => props.isSingleValueSelect && `
    padding-bottom: 0;
    overflow: hidden;
  `}
  }
`

const TreeViewParent = styled.div<{ visible: boolean } & PropsWithChildren<any>>`
  display: ${props => props.visible ? 'block' : 'none'};
  height: auto;
  max-height: 280px;
  min-height: fit-content;
  margin-top: 8px;
  position: relative;
  width: 100%;
  background-color: #fff;

  & > div {
    opacity: ${props => props.visible ? '1' : '0'}
  }
`

const TreeViewContainer = styled.div`
  position: relative;
  top: 0;
  left: -9px;
  width: calc(100% + 18px);
  height: auto;
  max-height: 280px;
  overflow-y: scroll;
  z-index: 200;
  background-color: #fff;
  border-left: 1px solid #E0E3EA;
  border-right: 1px solid #E0E3EA;
  border-bottom: 1px solid #E0E3EA;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  //box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
  transition: opacity 0.3s ease-in-out;
`
const TreeViewContainerCompact = styled.div`
  position: absolute;
  top: 8px;
  left: -9px;
  width: calc(100% + 18px);
  height: auto;
  max-height: 150px;
  overflow-y: scroll;
  z-index: 200;
  background-color: #fff;
  border-left: 1px solid #E0E3EA;
  border-right: 1px solid #E0E3EA;
  border-bottom: 1px solid #E0E3EA;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
  transition: opacity 0.3s ease-in-out;
`
const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  left: -9px;
  padding-top: 8px;
  color: #007994;
`

export default {
  ProjectSelectContainer,
  TreeViewParent,
  TreeViewContainer,
  TreeViewContainerCompact,
  Input,
  Popper,
  Footer
}
