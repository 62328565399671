import { AdminPanelRow } from "./components/AdminPanelTable/AdminPanelTable.types";

export enum RequestStatusesEnum {
  InProgress = "InProgress",
  Completed = "Completed",
  Deleted = "Deleted",
}

export type Pagination = {
  page: number;
  size: number;
  sort: string;
}

export type SortValue = [string, string]

export type AdminPanelRequestsFilterValues = {
  searchText: string;
  initiator: string[];
  assignee: string[];
  status: RequestStatusesEnum;
  fromDate: string;
  tillDate: string;
  processes: {
    [key: string]: unknown[]
  },
  pagination: Pagination,
}

export type AdminPanelGetRequestsArgs =  AdminPanelRequestsFilterValues & {
  processDefinitionName: string[]
}

export type ResponseGetAdminPanelRequests = {
  content: AdminPanelRow[],
  totalPages: number,
  totalElements: number,
  numberOfElements: number,
}


