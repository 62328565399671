import { NotificationManager } from 'react-notifications';
import {
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import isArray from 'lodash/isArray';


import ProcessContext from 'contexts/ProcessContext';
import { useTranslation } from 'react-i18next';

import { useUsersState } from 'store/users';
import {
  getBpmGlossaryAction as getGlossary,
  useTaskSteps,
  setTaskStepsAction as setTaskSteps,
  useProcessDialogState,
  useRequestActiveStep,
  saveDraftAction,
  setDraftStatusAction as setDraftStatus,
  useSavedDraftStatus,
  useGlossaryState,
  clearTemplatesAction as clearTemplates,
  setCreateRequestAction as setCreateRequest,
  useTaskState,
  setSubmitSnackbarParams,
  createAfterSubmitAction,
  setTaskComponentDataLoadingStarted,
  setTaskComponentDataLoadingFinished,
  openCreateDialogAction as openDialog,
  clearTaskData,
  setCreateRequestAction as setCreate,
} from 'store/requests';
import {
  setCustomModalParams,
  closeCustomModal,
  refreshEntitiesAction,
} from 'store/main';

import {
  sendComment,
  completeTask,
  createTemplate,
  makeProcessUrgent,
  cancelProcess,
  uploadNewEmployeeData,
  createInstance,
  getBusinessEntity,
  updateInstance,
  startProcessById,
  deleteTask,
  createEntityInstance,
  updateEntityInstance,
  getEntityInstanceById,
  deleteEntityInstance,
  takeOnATask,
  setTaskAttachmentsUnreadStatus,
  editDocument,
  getTaskHistoryV2,
  getDocflowDocumentById
} from 'api/requests';

import {
  savePDFAsBase64,
  generateOfferFields,
} from 'pages/Task/TaskForm/JobOffer/generateOfferPDF';
import {useUserProfile} from "hooks";
import getErrorMessage from './handleError';

import { ProcessType, UserType } from 'types';
import {
  ERROR_MESSAGE_1C,
  ERROR_MESSAGE_DELETE_ACTION,
  ERROR_MESSAGE_URGENT_REQUEST,
  URGENT_COMMENT,
  SAVE_TEMPLATE_ACTION,
  SAVE_TEMPLATE_ERROR_TEXT,
  SAVE_TEMPLATE_AND_SUBMIT_FORM_ACTION,
  WITH_COMMENT_SUFFIX,
  OPEN_STOP_COMMENT_POPOVER,
  STOP_TASKS_ACTION,
  STOP_TASKS_ACTION_ERROR_TEXT,
  CLOSE_COMMENT_POPOVER_ACTION,
  ADD_COMMENT_FROM_POPOVER_ACTION,
  ADD_COMMENT_POPOVER_ACTION_ERROR_TEXT,
  JOB_OFFER_V2_SYS_NAME,
  JOB_OFFER_V2_PDF_FILE_CREATING_PAGE,
  JOB_OFFER_PDF_FILE_SYS_NAME,
  PAYMENTS_V1_SYS_NAME,
  TIME_OFF_SYS_NAME,
  PROCUREMENT_SYS_NAME,
  PROCESS_FORMATION_STEP,
  VPN_OUTSTAFF_VALUE,
  AD_USER_FIELD_NAME,
  AD_USER_OUTSTAFF_VALUE,
  PREBOARDING_SYS_NAME,
  ADAPTATION_SYS_NAME,
  APPROVE_ASSIGN,
  REASSIGN,
  SELECT_EMPLOYEE_POPOVER_ACTION,
  CLOSE_SELECT_EMPLOYEE_POPOVER_ACTION,
  TAKE_ON_A_TASK_ERROR_TEXT,
  TAKE_ON_A_TASK,
  SAVE_ACTION,
  ERROR_MESSAGE_LINKED_ENTITY,
  SUBMIT_AND_CREATE_ANOTHER_ACTION,
  REMOVE_INSTANCE_ACTION,
  INSTANCE_DELETION_ERROR,
  CLOSE_MODAL_ACTION,
  SAVE_INSTANCE_ACTION, GENERATE_PDF_ACTION,
} from './constants';
import { removeSpacesFromNumber } from 'utils/general';
import { useTaskPdfGenerator } from './TaskPdfGenerator/useTaskPdfGenerator';
import { useHistory } from 'react-router-dom';
import { OnFormFieldHandleActionProps } from './types';

const getAttributeInstanceValue = (
  bDTOAttName: string,
  currentValue: string
) => {
  switch (bDTOAttName) {
    case 'stringAttributes':
      return typeof currentValue === 'boolean'
             ? (currentValue as boolean)?.toString()
             : currentValue?.toString();
    case 'dateAttributes':
      if (!currentValue) return null;
      return new Date(currentValue);
    case 'booleanAttributes':
      return !!currentValue;
    case 'doubleAttributes':
      return currentValue !== null && currentValue !== undefined ? removeSpacesFromNumber(currentValue) : null;
    case 'integerAttributes':
      return currentValue !== null && currentValue !== undefined ? typeof currentValue === 'number' ? currentValue : parseInt(currentValue) : null;
    case 'enumAttributes': {
      const currentValueString = (currentValue ?? '').toString();
      const currentValueDelimeter = currentValueString.includes('\n') ? '\n' : ',';
      return [...new Set(currentValueString.split(currentValueDelimeter))].filter(Boolean);
    }
    case 'arrayEntityAttributes':
      return currentValue
        .toString()
        .split(',')
        .map((el: string) => +el)
        .filter(Boolean);
    default:
      return currentValue;
  }
};

export type FormInputs = {
  prepayment: string;
  paymentType: string;
  urgentSwitch: string;
  urgentReason: string;
  vpnSelect: string;
  benefits: string | string[];
  accessType: string | string[];
  selectUserOkta: string | string[];
  [AD_USER_FIELD_NAME]: string;
};

interface UseTaskFormProps {
  selectedWatchers?: UserType[];
}

const useTaskForm = ({selectedWatchers}: UseTaskFormProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const history = useHistory();

  const { users } = useUsersState();
  const { data: bpmTask } = useTaskState();
  const { activeStep, isSummaryStep } = useRequestActiveStep();
  const { getProcessByName } = useContext(ProcessContext);

  const { id: profileId, nickname: profileNickName, companyId } = useUserProfile();
  const formSteps = useTaskSteps();
  const createDialogOpen = useProcessDialogState();
  const draftSaved = useSavedDraftStatus();
  const glossaryState = useGlossaryState();

  const { generatePdf } = useTaskPdfGenerator();

  const defaultValues = useMemo(() =>
    bpmTask?.defaultFormValues, [bpmTask?.defaultFormValues]);

  const formMethods = useForm<FormInputs>({ defaultValues });

  const isProcurement = useMemo((): boolean =>
      bpmTask?.businessEntity.sysName === PROCUREMENT_SYS_NAME,
    [bpmTask?.businessEntity.sysName]);

  const isTimeOff = useMemo((): boolean =>
      bpmTask?.businessEntity.sysName === TIME_OFF_SYS_NAME,
    [bpmTask?.businessEntity.sysName]);

  const isPayments = useMemo((): boolean =>
      bpmTask?.businessEntity.sysName === PAYMENTS_V1_SYS_NAME,
    [bpmTask?.businessEntity.sysName]);

  const isAdaptation = useMemo((): boolean =>
      bpmTask?.businessEntity.sysName === ADAPTATION_SYS_NAME,
    [bpmTask?.businessEntity.sysName]);

  const isJobOfferPDFFormationStep = useMemo((): boolean =>
      bpmTask?.businessEntity.sysName === JOB_OFFER_V2_SYS_NAME &&
      bpmTask?.currentAction.stepperOrder === JOB_OFFER_V2_PDF_FILE_CREATING_PAGE,
    [bpmTask?.businessEntity.sysName, bpmTask?.currentAction.stepperOrder]);

  const isJobOfferLastStep = useMemo((): boolean =>
      bpmTask?.businessEntity.sysName === JOB_OFFER_V2_SYS_NAME &&
      bpmTask?.isLastStep,
    [bpmTask?.businessEntity.sysName, bpmTask?.isLastStep]);

  const isPreboardingLastStep = useMemo((): boolean =>
      bpmTask?.businessEntity.sysName === PREBOARDING_SYS_NAME &&
      bpmTask?.isLastStep,
    [bpmTask?.businessEntity.sysName, bpmTask?.isLastStep]);

  const hasSubmitError = useRef(false);

  const [loading, setLoading] = useState(false);
  const [selectedAction, setSelectedAction] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [alwaysActiveButtons, setAlwaysActiveButtons] = useState([]);
  const [error, setError] = useState('');
  const [formErrorsRefresh, setFormErrorsRefresh] = useState(false);
  const [templatePopoverInfo, setTemplatePopoverInfo] = useState({ open: false });
  const [commentPopoverInfo, setCommentPopoverInfo] = useState({
    commentedAction: '',
    open: false,
    useWithoutRequiredFieldsFilled: false,
    isCommentRequired: true,
  });
  const [commentPopoverSubmitAction, setCommentPopoverSubmitAction] = useState(ADD_COMMENT_FROM_POPOVER_ACTION);
  const [selectEmployeePopoverInfo, setSelectEmployeePopoverInfo] = useState({
    open: false,
    isCommentRequired: true,
    showOnlyPossiblePerformers: false,
  });
  const [actionEmployee, setActionEmployee] = useState('');
  const [actionComment, setActionComment] = useState('');
  const [assigneeVariableName, setAssigneeVariableName] = useState('');
  const [commentedAction, setCommentedAction] = useState('');

  const isDraftsAutoSaveEnabled = useRef(true);

  const watchedFields = formMethods.watch();

  const [clickedButtonComponentParams, setClickedButtonComponentParams] = useState<{[key: string]: any;}>({});

  useEffect(() => {
    if (draftSaved) {
      setTimeout(() => {
        dispatch(setDraftStatus(false));
      }, 2000);
    }
  }, [draftSaved, dispatch]);

  useEffect(() => {
    if (bpmTask && !formSteps && createDialogOpen) {
      dispatch(setTaskSteps(bpmTask.formationRequestSteps));
    }
  }, [formSteps, dispatch, bpmTask, createDialogOpen]);

  useEffect( () => {
    const { loading, error, ids, mapList } = glossaryState;

    if (!loading && !error && ids.length && ids.length !== Object.keys(mapList).length) {
      // Ожидаем загрузки всех данных Glossary компонента
      Promise.all(
        [...ids.map(async id => {
          await dispatch(setTaskComponentDataLoadingStarted(id));
          await dispatch(getGlossary(id))
        })]
      ).then(() => {
        // Сбрасываем ожидание
        ids.map(id => dispatch(setTaskComponentDataLoadingFinished(id)));
      });
    }
  }, [dispatch, glossaryState]);

  useEffect(() => {
    if (watchedFields.paymentType && watchedFields.paymentType === 'Postpayment') {
      formMethods.setValue('prepayment', '0');
      formMethods.clearErrors('prepayment');
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedFields.paymentType, formMethods.setValue, formMethods.clearErrors]);

  useEffect(() => {
    if (watchedFields.vpnSelect && watchedFields.vpnSelect === VPN_OUTSTAFF_VALUE) {
      formMethods.setValue(AD_USER_FIELD_NAME, AD_USER_OUTSTAFF_VALUE);
      formMethods.clearErrors(AD_USER_FIELD_NAME);
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedFields.vpnSelect, formMethods.setValue, formMethods.clearErrors]);

  const startNewProcess = useCallback(async () => {
    if (!bpmTask) return;

    setLoading(true);
    try {
      const { creator, processSysName, isTemplateProcess, camundaId, integration } = getProcessByName(bpmTask.processName);
      const variables = {};
      if (integration) {
        variables['integration'] = {
          'type': 'String',
          'value': integration,
        };
      }
      const taskId = await startProcessById({
        params: {
          creator: creator,
          id: bpmTask.businessEntity.processDefinitionId || camundaId,
          ...(isTemplateProcess && {processTemplate: true})
        },
        data: {
          processId: processSysName,
          processDefinitionName: bpmTask.processName
        }
      });

      dispatch(
        setCreateRequest({
          id: taskId,
          name: bpmTask.businessEntity.name,
          sysName: bpmTask.businessEntity.sysName,
          isNewRequest: true
        })
      );
      // eslint-disable-next-line no-useless-catch
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, [dispatch, bpmTask]);

  const getOKTAEmailByLogName = (logName) => {
    const userIdArray = Object.keys(users);
    const userId = userIdArray.find(
      (userId) => users[userId].logName === logName
    );

    const userSelected = users[userId];
    return userSelected?.email;
  };

  const submitTask = async (values) => {
    isDraftsAutoSaveEnabled.current = false;

    const taskValues = { ...values };
    const urgentMark = bpmTask.currentAction.urgentMark;
    const isNotFormationStep = bpmTask.currentAction.stepperOrder > PROCESS_FORMATION_STEP;

    if (!taskValues.urgent) {
      taskValues.urgent = 'false';
    }

    if ((isTimeOff || isPayments || isProcurement) && urgentMark) {
      try {
        const { urgentSwitch, urgentReason, urgentDeclineReason } = taskValues;
        const { urgent, reason } = bpmTask.entity.urgent;

        if (!urgentSwitch) {
          bpmTask.entity.urgent.reason = '';
          bpmTask.entity.urgent.urgent = false;
        }

        await saveUrgentState({
          urgentSwitch: urgentSwitch,
          urgentReason: urgentSwitch ? urgentReason : '',
        });

        if (isNotFormationStep && urgentSwitch !== urgent) {
          const getUrgentComment = () => {
            const urgentSwitchBoolean =
              typeof urgentSwitch === 'string'
              ? urgentSwitch.toLowerCase() === 'true'
              : urgentSwitch;

            const urgentStateComment = `${URGENT_COMMENT.STATE}: ${urgentSwitchBoolean}`;
            const urgentReasonComment = urgentSwitchBoolean
                                        ? `${URGENT_COMMENT.REASON}: ${urgentReason || reason}`
                                        : `${URGENT_COMMENT.REASON_OFF}: ${urgentDeclineReason}`;
            const urgentUserComment = `${URGENT_COMMENT.USER}: ${profileNickName}`;

            return [
              urgentStateComment,
              urgentReasonComment,
              urgentUserComment,
            ].join('/n');
          };

          const { approveStage } = taskValues;
          setCommentedAction(approveStage);

          const urgentComment = getUrgentComment();
          await addComment(urgentComment);
        }
      } catch (e) {
        setLoading(false);
        setError(ERROR_MESSAGE_URGENT_REQUEST);
        hasSubmitError.current = true;
        throw e;
      }
    }

    if (isAdaptation && bpmTask.isFirstStep) {
      const { employeeOkta } = taskValues;

      taskValues.email = getOKTAEmailByLogName(employeeOkta);
    }

    if (isJobOfferPDFFormationStep) {
      try {
        const fileBusinessEntityResponse = await getBusinessEntity({
          params: {
            sysName: JOB_OFFER_PDF_FILE_SYS_NAME
          }
        });
        const fileInstanceCreateResponse = await createInstance({
          params: {
            bsnTypeId: fileBusinessEntityResponse.id,
            taskId: bpmTask.taskId,
            applicationNumber: bpmTask.applicationNumber
          }
        });

        const jobOfferFields = await generateOfferFields(
          bpmTask.attributes,
          bpmTask.createdDate,
          users,
          bpmTask.values.serviceContract,
          bpmTask.values.companyId
        );
        const jobOfferPDFBase64 = await savePDFAsBase64(jobOfferFields);
        const jobOfferFileBytes = fileInstanceCreateResponse.fileAttributes.find(
          (attr) => attr.name === 'Данные'
        );
        const fileAttribute = {
          ...jobOfferFileBytes,
          value: jobOfferPDFBase64,
        };
        const fileEntityInstance = {
          ...fileInstanceCreateResponse,
          fileAttributes: [fileAttribute],
        };

        taskValues.pdfFile = fileEntityInstance.id.toString();

        await updateInstance({
          params: {
            taskId: bpmTask.taskId
          },
          data: fileEntityInstance
        });
      } catch (error) {
        setLoading(false);
        setError(getErrorMessage(error.message));
      }
    }

    if (isJobOfferLastStep) {
      try {
        const { company, probation } = bpmTask.values;
        const employeeData = {
          ...bpmTask.values,
          legalEntity: company,
          probationEndDate: probation,
        };

        await uploadNewEmployeeData(employeeData);
      } catch (e) {
        setLoading(false);
        setError(getErrorMessage(e.message));
      }
    }

    if (isPreboardingLastStep) {
      const employeeData = bpmTask.values;
      uploadNewEmployeeData(employeeData).catch((e) => {
        setLoading(false);
        setError(getErrorMessage(e.message));
      });
    }

    if (
      taskValues.approveStage?.length &&
      [SELECT_EMPLOYEE_POPOVER_ACTION, APPROVE_ASSIGN, REASSIGN].includes(
        taskValues.approveStage[0]
      ) &&
      clickedButtonComponentParams?.assignBefore &&
      Object.prototype.hasOwnProperty.call(
        taskValues,
        clickedButtonComponentParams.assignBefore
      )
    ) {
      taskValues[clickedButtonComponentParams.assignBefore] = actionEmployee;
    }

    if(assigneeVariableName){
      taskValues['rework_step'] = assigneeVariableName
    }

    if(selectedAction === "Approve & Assign" || selectedAction === "Reassign"){
      taskValues['performer'] = actionEmployee

      taskValues.approveStage = [selectedAction]
    }

    try {
      const data = bpmTask.setInstanceAttributes({
        values: taskValues
      });
      const params = {
        taskId: bpmTask.taskId,
      };

      let hasChangedAttachments = false;
      for (const attribute of data['arrayEntityAttributes']){
        const initialValueTemp = defaultValues[attribute.name];
        const initialValue = initialValueTemp
                             ? (typeof initialValueTemp === 'string'
                                ? initialValueTemp?.split(',')?.map(value => +value)?.sort((a, b) => a - b)
                                : initialValueTemp?.sort((a, b) => a - b))
                             : [];
        const finalValue = attribute?.value?.sort((a,b) => a - b);
        if (initialValue?.toString() !== finalValue?.toString()) {
          hasChangedAttachments = true;
        }
      }

      if (hasChangedAttachments) {
        setTaskAttachmentsUnreadStatus(bpmTask?.taskId)
      }
      if(data?.stringAttributes.find(attr => attr.name.includes('documentFromDocflow-'))){
        data.docFlowDocuments = taskValues.docFlowDocuments || bpmTask?.entity?.taskInstance?.docFlowDocuments; // setting docflow documents

        const docflowField = bpmTask?.attributes?.find(attr =>  attr.name.includes('documentFromDocflow-'));
        const params = JSON.parse(docflowField?.componentParams || '{}');
        const signRequiredSteps = params?.signRequiredSteps || []; // checking if documents need to be signed

        if(data.docFlowDocuments.length) {
          const document = await getDocflowDocumentById(data.docFlowDocuments[0].id);
          if(data.docFlowDocuments[0].status === 'DRAFT' && signRequiredSteps?.length) {
            try {
              const history = await getTaskHistoryV2(bpmTask?.processInstanceId); // getting all process' assignees from task history
              const steps = [...history.pastTasks, ...history.futureTasks];
              const signatureRequiredAssignees = [];
              steps.forEach((step) => {
                // checking which assignees need to sign document and on which step
                if (signRequiredSteps.includes(step.stepperOrder) && !signatureRequiredAssignees.includes(step.assignee)) {
                  signatureRequiredAssignees.push(step.assignee);
                }
              });
              if (!document.signatories.length) {
                await editDocument({
                  ...document,
                  signatories: signatureRequiredAssignees.map((assignee) => ({
                    // finally overriding document's signatories
                    userId: assignee,
                    workspaceId: companyId,
                    isSigned: false,
                    groupId: '',
                  })),
                });
              }
            } catch (err) {
              console.log(err);
            }
          }
          if (document && document?.signatories.every((signatory) => signatory.isSigned)) {
            data.docFlowDocuments = [{
              ...data.docFlowDocuments[0],
              signed: true
            }]
          }
        }
      }

      const isInitialSteps =
        bpmTask.isFirstStep ||
        bpmTask.isRework ||
        bpmTask.isCancelled ||
        bpmTask.isRejected;

      const currentObservers = bpmTask.getObservers;

      if (bpmTask.isCancelled && selectedWatchers.length === 0 && currentObservers.length > 0) {
        data.observer = currentObservers;
      } else if (isInitialSteps) {
        const ids = selectedWatchers.map(watcher => watcher.id);
        data.observer = ids;
      } else {
        data.observer = currentObservers;
      }

      await completeTask(params, data);

      if (createDialogOpen) {
        history.push("/requests/active");
      }

      setError('');
      hasSubmitError.current = false;

      dispatch(
        setSubmitSnackbarParams({
          open: true,
          action: taskValues.approveStage[0],
        })
      );
    } catch (error) {
      setError(getErrorMessage(error.response?.data?.message || error.message));
      NotificationManager.error(t(error.response?.data?.message || error.message))
      hasSubmitError.current = true;
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (hideSnackbar?: boolean) => {
    isDraftsAutoSaveEnabled.current = false;
    try {
      await deleteTask({
        processInstanceId: bpmTask.businessTask.rootProcessInstanceId
      });
      setError('');
      hasSubmitError.current = false;

      if (hideSnackbar) return;
      dispatch(setSubmitSnackbarParams({ open: true, action: 'default' }));
      dispatch(openDialog(false));
      dispatch(clearTaskData());
      setTimeout(() => {
        dispatch(setCreate(null));
      }, 300);
    } catch (e) {
      setError(ERROR_MESSAGE_DELETE_ACTION);
      hasSubmitError.current = true;
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = (action: string) => async (values: FormInputs) => {
    setLoading(true);

    if ('accessType' in values) {
      const { accessType } = values;

      if (typeof accessType === 'string') {
        values.accessType = accessType.split(',');
      } else if (Array.isArray(accessType)) {
        values.accessType = accessType;
      } else {
        values.accessType = [];
      }
    }

    if ('benefits' in values) {
      values.benefits = values.benefits
                        ? isArray(values.benefits)
                          ? values.benefits
                          : (values.benefits as string).split('\n')
                        : [];
    }

    if ('selectUserOkta' in values) {
      const { selectUserOkta } = values;

      if (typeof selectUserOkta === 'string') {
        values.selectUserOkta = selectUserOkta.split(',');
      } else if (Array.isArray(selectUserOkta)) {
        values.selectUserOkta = selectUserOkta;
      } else {
        values.selectUserOkta = [];
      }
    }

    try {
      await submitTask({ ...values, approveStage: [action] });
    } catch (error) {
      setError(ERROR_MESSAGE_1C);
      hasSubmitError.current = true;
      setLoading(false);
    }
  };

  const handleSaveTemplateError = () => {
    setTemplatePopoverInfo({ ...templatePopoverInfo, open: false });
    setError(SAVE_TEMPLATE_ERROR_TEXT);
    setLoading(false);
  };

  const getCurrentInstance = useCallback(
    (overwriteValues?: { [key: string]: string }) => {
      const formValues = overwriteValues || formMethods.getValues();
      const formValuesKeys = Object.keys(formValues);

      const taskInstance = bpmTask.instance;
      const taskInstanceKeys = Object.keys(taskInstance);

      const taskInstanceEntries = taskInstanceKeys.map((taskInstanceKey) => {

        const getTaskInstanceModifiedValues = (taskInstanceArray) => {
          return taskInstanceArray.map((taskInstanceAttribute) => {
            const { name } = taskInstanceAttribute;

            if (!formValuesKeys.includes(name)) {
              return taskInstanceAttribute;
            }

            const currentValue = get(formValues, name, '');
            const value = getAttributeInstanceValue(
              taskInstanceKey,
              currentValue as string
            );
            return {
              ...taskInstanceAttribute,
              value,
            };
          });
        };

        if(taskInstanceKey === 'docFlowDocuments') {
          return [taskInstanceKey, formValues['docFlowDocuments']];
        }

        if (!taskInstanceKey.includes('Attributes')) {
          return [taskInstanceKey, taskInstance[taskInstanceKey]];
        }

        const taskInstanceModifiedValues = getTaskInstanceModifiedValues(
          taskInstance[taskInstanceKey]
        );
        return [taskInstanceKey, taskInstanceModifiedValues];
      });

      return Object.fromEntries(taskInstanceEntries);
    },
    [formMethods, bpmTask]
  );

  const saveUrgentState = useCallback(
    async ({ urgentSwitch, urgentReason }) => {
      try {
        const { reason } = bpmTask.entity.urgent;

        const urgentSwitchBoolean =
          typeof urgentSwitch === 'string'
          ? urgentSwitch.toLowerCase() === 'true'
          : urgentSwitch;

        const urgentUserReason = urgentReason ? urgentReason : reason || '';
        const urgentUserReasonParameter = urgentSwitchBoolean
                                          ? urgentUserReason
                                          : '';

        await makeProcessUrgent({
          processInstanceId: bpmTask.processInstanceId,
          urgent: urgentSwitchBoolean ?? false,
          reason: urgentUserReasonParameter,
        });
      } catch (e) {
        console.error(e);
      }
    },
    [bpmTask]
  );

  const saveUserInput = useCallback(
    async (overwriteValues) => {
      if (bpmTask) {
        if (!isDraftsAutoSaveEnabled.current) {
          return;
        }
        try {
          if (isPayments || isProcurement) {
            const { urgentSwitch, urgentReason } = overwriteValues;
            await saveUrgentState({
              urgentSwitch,
              urgentReason,
            });
          }

          const newTaskInstance = getCurrentInstance(overwriteValues);
          newTaskInstance.docFlowDocuments = bpmTask?.entity?.taskInstance?.docFlowDocuments

          dispatch(
            saveDraftAction(
              bpmTask.businessTask.processInstanceId,
              newTaskInstance
            )
          );
          // await updateDraftInstance(bpmTask.businessTask.processInstanceId, newTaskInstance);
        } catch (e) {
          console.error(e);
        }
      }
    },
    [dispatch, saveUrgentState, getCurrentInstance, isPayments, bpmTask]
  );

  const addComment = async (comment: string) => {
    const commentData = {
      processInstanceId: bpmTask.processInstanceId as string,
      author: profileId,
      text: comment,
      date: new Date(),
      initiatorOfTask: bpmTask.initiator as string,
      approveStage: commentedAction,
      action: commentedAction,
      processStep: bpmTask.stepSysName,
    };

    setLoading(true);

    try {
      await sendComment(commentData);
    } catch (error) {
      setError(error?.message);
      setLoading(false);
    }
  };

  const handleSaveTemplateAction = async (templateName: string) => {
    setLoading(true);

    try {
      const businessInstanceDTO = getCurrentInstance();
      const {
        id: processDefinitionId,
        camundaId: customProcessId,
        key: processDefinitionKey,
        name: processDefinitionName,
        processSysName
      }: ProcessType = getProcessByName(bpmTask.businessTask.processDefinitionName);

      const { urgentReason = false, urgentSwitch = '' } = formMethods.getValues();

      const urgentParameters = {
        urgent: urgentSwitch,
        reason: urgentSwitch ? urgentReason : '',
      };

      await createTemplate({
        title: templateName,
        processSysName,
        businessInstanceDTO,
        processDefinitionId: processDefinitionId || customProcessId,
        processDefinitionKey,
        processDefinitionName,
        urgentParameters,
      });

      dispatch(clearTemplates());

      setTemplatePopoverInfo({ ...templatePopoverInfo, open: false });
      setLoading(false);
    } catch (error) {
      console.error(error, 'error');
      handleSaveTemplateError();
    }
  };

  const handleLinkedEntityCreationAndEditing = async ({
    clearFormFields = false,
    instanceId = null,
    prefix = '',
    componentParamsLinkedEntitySysName = null,
    componentParamsLinkedEntityPrefix = null,
    componentParamsLinkedEntityIdField = null,
  }) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const {
        linkedEntitySysName,
        linkedEntityFieldsPrefix,
        linkedEntityIdField,
      } = bpmTask.currentState;

      if (
        !instanceId &&
        !prefix &&
        (!(linkedEntitySysName || componentParamsLinkedEntitySysName) ||
          !(linkedEntityFieldsPrefix || componentParamsLinkedEntityPrefix) ||
          !(linkedEntityIdField || componentParamsLinkedEntityIdField))
      ) {
        throw new Error(ERROR_MESSAGE_LINKED_ENTITY);
      }

      const fieldsPrefix = componentParamsLinkedEntityPrefix || prefix || linkedEntityFieldsPrefix;

      // get entity fields values from form fields
      const values = formMethods.getValues();
      const entityFormFields = Object.keys(values).filter((field) => field.startsWith(fieldsPrefix));
      const entityFormFieldsWithoutPrefix = entityFormFields.map((v) => v.replace(fieldsPrefix, ''));

      const entityFormFieldsValues = entityFormFields.reduce(
        (fieldsValues, field) => {
          fieldsValues[field.replace(fieldsPrefix, '')] = values[field];
          return fieldsValues;
        }, {}
      );

      // create an empty instance or load existing one by id
      let linkedEntityInstance = null;
      const idField = componentParamsLinkedEntityIdField || linkedEntityIdField;
      let linkedEntityIdFieldNewValue = '';

      if (instanceId) {
        linkedEntityInstance = await getEntityInstanceById(instanceId);
      } else {
        const processInstanceId = bpmTask.processInstanceId;
        const creationParams = {
          processInstanceId,
          sysName: componentParamsLinkedEntitySysName || linkedEntitySysName
        };
        linkedEntityInstance = await createEntityInstance(creationParams);

        linkedEntityIdFieldNewValue = values[idField]
                                      ? values[idField] + ',' + linkedEntityInstance.id
                                      : linkedEntityInstance.id.toString();
      }

      // fill it with corresponding form fields values
      const linkedEntityInstanceKeys = Object.keys(linkedEntityInstance);
      const linkedEntityInstanceWithConvertedValues = linkedEntityInstanceKeys.reduce(
        (resultInstance, linkedEntityInstanceKey) => {
          const currentLinkedEntityInstance =
            linkedEntityInstance[linkedEntityInstanceKey];

          if (!linkedEntityInstanceKey.includes('Attributes')) {
            resultInstance[
              linkedEntityInstanceKey
              ] = currentLinkedEntityInstance;
            return resultInstance;
          }

          resultInstance[
            linkedEntityInstanceKey
            ] = currentLinkedEntityInstance.map((attribute) => {
            if (!entityFormFieldsWithoutPrefix.includes(attribute.name)) {
              return attribute;
            }

            const currentEntityValue = entityFormFieldsValues[attribute.name];
            if (
              linkedEntityInstanceKey.includes('double') ||
              linkedEntityInstanceKey.includes('integer')
            ) {
              return {
                ...attribute,
                value: currentEntityValue !== null && currentEntityValue !== undefined
                  ? Number(removeSpacesFromNumber(currentEntityValue))
                  : null,
              };
            }

            if (
              linkedEntityInstanceKey.includes('array') &&
              typeof currentEntityValue === 'string'
            ) {
              return {
                ...attribute,
                value: currentEntityValue.split(','),
              };
            }

            return {
              ...attribute,
              value: currentEntityValue,
            };
          });
          return resultInstance;
        },
        {}
      );

      await updateEntityInstance(linkedEntityInstanceWithConvertedValues);

      if (!instanceId) {
        formMethods.setValue(idField, linkedEntityIdFieldNewValue);
        await saveUserInput(formMethods.getValues());
      }

      if (clearFormFields) {
        dispatch(
          setCustomModalParams({
            clearFields: true,
            loading: false,
            instanceFieldsPrefix: fieldsPrefix,
          })
        );
      }
    } catch (error) {
      throw error;
    }
  };

  const handleSubmitAndCreateAnother = () => {
    dispatch(createAfterSubmitAction(true));

    handleAction({
      action: 'Submit',
      tplName: null,
      componentParams: {},
    });
  };

  // TODO: Отрефакторить код ниже
  const handleAction = async ({
    action,
    tplName,
    componentParams,
    instanceFormFieldName = '',
    instanceFieldsPrefix = '',
    focusedLinkedInstanceId = '',
    instance = null,
    validationAttributeList = [],
  }: OnFormFieldHandleActionProps) => {
    setLoading(true);
    setSelectedAction(action);
    dispatch(setCustomModalParams({ loading: true }));
    formMethods.clearErrors();
    hasSubmitError.current = false;

    const trimmedAction = action.trim();

    const isModalFormValidationExists = !!componentParams?.modalFormValidation;
    /* Добавление проверки валидации внутри модальных окнах
    - validationAttributeList -- список аттрибутов, которые берутся из модальных окон,
    которые нужно проверить прежде чем делать какое либо действие. */
    if (isModalFormValidationExists && validationAttributeList?.length) {
      const attributeNameList = validationAttributeList.map(({ name }) => name);
      await formMethods.trigger(attributeNameList);
      /* Проверка на ошибки после trigger-a с фильрацией по атрибутам из validationAttributeList.
      Если ошибки есть -- останавливаем функцию */
      const errorsKeys = Object.keys(
        formMethods.formState.errors
      ).filter((key) => attributeNameList.includes(key));
      const isErrorsExists = !!errorsKeys.length;

      if (isErrorsExists) {
        dispatch(setCustomModalParams({ loading: false }));
        setLoading(false);
        return;
      }
    }

    // Функционал по сохранению данных внутри модального окна
    const isModalDataStoring = !!componentParams?.isModalDataStoring || false;
    dispatch(setCustomModalParams({ saveDataToMainForm: isModalDataStoring }));

    if (isModalDataStoring && validationAttributeList.length) {
      validationAttributeList.forEach(({ name, dependentAttributeName }) => {
        const dependentAttributeValue = formMethods.getValues(name);
        formMethods.setValue(dependentAttributeName, dependentAttributeValue);
      });

      await saveUserInput(formMethods.getValues());
    }

    // Функционал по закрытию модального окна
    const closeModal = !!componentParams?.closeModal || false;
    if (closeModal) {
      if (trimmedAction !== SAVE_INSTANCE_ACTION) {
        dispatch(setCustomModalParams({ open: false }));

        // условие необходимо для возмонжости закрыть модалку вместе с выполнением
        // какого-то действия, к примеру, удалением связанной сущности
        if (trimmedAction === CLOSE_MODAL_ACTION) {
          dispatch(setCustomModalParams({ loading: false }))
          setLoading(false);
          return;
        }
      }
    }

    const useWithoutRequiredFieldsFilled =
      (componentParams && !!componentParams.useWithoutRequiredFieldsFilled) ||
      false;

    const createLinkedEntity =
      (componentParams && !!componentParams.createLinkedEntity) || false;

    const stopAfterLinkedEntityCreation =
      (componentParams && !!componentParams.stopAfterLinkedEntityCreation) ||
      false;

    const clearFormFields =
      (componentParams &&
        !!componentParams.clearFormFieldsAfterLinkedEntityCreation) ||
      false;

    if (createLinkedEntity) {
      try {
        const {
          linkedEntitySysName: componentParamsLinkedEntitySysName,
          linkedEntityPrefix: componentParamsLinkedEntityPrefix,
          linkedEntityIdField: componentParamsLinkedEntityIdField,
          createOnlyIfIdFieldEmpty,
        } = componentParams;

        // если настроено создание сущности только если она еще не создана
        if (!!createOnlyIfIdFieldEmpty && componentParamsLinkedEntityIdField) {
          const idFieldValue = formMethods.getValues(
            componentParamsLinkedEntityIdField
          );
          const instanceId = idFieldValue
                             ? idFieldValue.toString()?.split(',')[0]
                             : null;

          if (instanceId) {
            // если сущность уже создана - редактировать созданную сущность
            await handleLinkedEntityCreationAndEditing({
              clearFormFields,
              instanceId: instanceId,
              prefix: componentParamsLinkedEntityPrefix,
            });
          } else {
            // иначе - создать новую сущность
            await handleLinkedEntityCreationAndEditing({
              clearFormFields,
              componentParamsLinkedEntitySysName,
              componentParamsLinkedEntityPrefix,
              componentParamsLinkedEntityIdField,
            });
          }
        } else {
          await handleLinkedEntityCreationAndEditing({
            clearFormFields,
            componentParamsLinkedEntitySysName,
            componentParamsLinkedEntityPrefix,
            componentParamsLinkedEntityIdField,
          });
        }

        if (stopAfterLinkedEntityCreation && !closeModal) {
          setLoading(false);
          return;
        }
      } catch (error) {
        setError(error.message || ERROR_MESSAGE_LINKED_ENTITY);
        setLoading(false);
        return;
      }
    }

    const openCustomModal =
      (componentParams && !!componentParams.isModalConnected) || false;

    if (openCustomModal) {
      const { dependentModalAttributeSysName } = componentParams;

      if (dependentModalAttributeSysName) {
        const {
          fillModalWithInstanceValues = false,
          clearModalFieldsAfterOpening = false,
          modalFieldsPrefix = '',
        } = componentParams;

        const customModalParameters = {
          open: true,
          loading: false,
          sysName: dependentModalAttributeSysName,
          focusedLinkedInstance: instance,
          focusedLinkedInstanceFieldName: instanceFormFieldName,
          instanceFieldsPrefix: modalFieldsPrefix,
          fillWithInstanceValues: !!fillModalWithInstanceValues,
          clearFields: !!clearModalFieldsAfterOpening,
        };

        setLoading(false);
        dispatch(setCustomModalParams(customModalParameters));
        return;
      }
    }

    if (componentParams) {
      setClickedButtonComponentParams(componentParams);
    }

    if (!useWithoutRequiredFieldsFilled) {
      await formMethods.trigger();
    }

    if (
      trimmedAction &&
      trimmedAction !== SUBMIT_AND_CREATE_ANOTHER_ACTION &&
      trimmedAction !== 'Delete' &&
      trimmedAction !== 'Reject' &&
      trimmedAction !== 'Rework' &&
      trimmedAction !== APPROVE_ASSIGN &&
      trimmedAction !== REASSIGN &&
      trimmedAction !== CLOSE_SELECT_EMPLOYEE_POPOVER_ACTION &&
      trimmedAction !== SELECT_EMPLOYEE_POPOVER_ACTION &&
      trimmedAction !== 'Open template creation popover' &&
      trimmedAction !== SAVE_ACTION &&
      trimmedAction !== SAVE_TEMPLATE_ACTION &&
      trimmedAction !== SAVE_TEMPLATE_AND_SUBMIT_FORM_ACTION &&
      trimmedAction !== 'Close template creation popover' &&
      trimmedAction !== OPEN_STOP_COMMENT_POPOVER &&
      trimmedAction !== STOP_TASKS_ACTION &&
      trimmedAction !== TAKE_ON_A_TASK &&
      trimmedAction !== CLOSE_COMMENT_POPOVER_ACTION &&
      trimmedAction !== ADD_COMMENT_FROM_POPOVER_ACTION &&
      trimmedAction !== REMOVE_INSTANCE_ACTION &&
      trimmedAction !== SAVE_INSTANCE_ACTION &&
      trimmedAction !== GENERATE_PDF_ACTION &&
      !trimmedAction.includes(WITH_COMMENT_SUFFIX)
    ) {
      setFormErrorsRefresh(() => false);
      if (useWithoutRequiredFieldsFilled) {
        onSubmit(trimmedAction)(formMethods.getValues());
      } else {
        const onValidationError = async (errors) => {
          // fix not always working formMethods errors
          // that cause not displayed validation error message
          setFormErrorsRefresh(() => true);
          // handle validation error due to empty fields in modal
          const modalFields = bpmTask.attributes
            .filter((attr) => attr?.groupName?.includes('#hidden'))
            .map((attr) => attr?.name);
          const hasModalFields = !!modalFields.length;
          const hasNotInModalErrors = !!Object.keys(errors).filter(
            (fieldName) => !modalFields.includes(fieldName)
          ).length;

          // submit if errors are only in modal
          if (hasModalFields && !hasNotInModalErrors) {
            setLoading(true);
            await onSubmit(trimmedAction)(formMethods.getValues());
          }
        };

        formMethods
          .handleSubmit(onSubmit(trimmedAction), onValidationError)()
          .finally(() => {
            setLoading(false);
          });
      }
    } else if (trimmedAction === SUBMIT_AND_CREATE_ANOTHER_ACTION) {
      handleSubmitAndCreateAnother();
    } else if (trimmedAction === 'Delete' || trimmedAction === '') {
      await handleDelete();
    } else if (
      trimmedAction === 'Reject' ||
      trimmedAction === 'Rework' ||
      trimmedAction.includes(WITH_COMMENT_SUFFIX)
    ) {
      setFormErrorsRefresh(() => false);
      const actionName = trimmedAction.replace(WITH_COMMENT_SUFFIX, '').trim();
      setCommentPopoverSubmitAction(ADD_COMMENT_FROM_POPOVER_ACTION);
      if (!useWithoutRequiredFieldsFilled) {
        await formMethods.trigger();
        if (Object.keys(formMethods.formState.errors).length) {
          setFormErrorsRefresh(() => true);
        }
      }

      if (!Object.keys(formMethods.formState.errors).length || useWithoutRequiredFieldsFilled) {
        const isCommentRequired = !componentParams?.commentNotRequired ?? true;
        setCommentedAction(actionName);
        setCommentPopoverInfo({
          ...commentPopoverInfo,
          open: true,
          commentedAction: actionName,
          useWithoutRequiredFieldsFilled,
          isCommentRequired
        });
      }
      setLoading(false);
    } else if (trimmedAction === SAVE_ACTION) {
      if (useWithoutRequiredFieldsFilled) {
        await saveUserInput(formMethods.getValues());
        setTimeout(() => {
          setLoading(false);
        }, 700);
      } else {
        await formMethods
          .handleSubmit(saveUserInput)()
          .finally(() => {
            setLoading(false);
          });
      }
    } else if ([APPROVE_ASSIGN, REASSIGN].includes(trimmedAction)) {
      const isCommentRequired = !componentParams?.commentNotRequired ?? true;
      const showOnlyPossiblePerformers = !!componentParams?.showOnlyPossiblePerformers ?? false;

      const { errors } = formMethods.formState
      setLoading(false);

      if(Object.values(errors).length !== 0) {
        NotificationManager.error(t("customProcesses.notifications.fillAllFields"))
        return
      }

      setSelectEmployeePopoverInfo({
        ...selectEmployeePopoverInfo,
        open: true,
        isCommentRequired,
        showOnlyPossiblePerformers
      });

    } else if (trimmedAction === CLOSE_SELECT_EMPLOYEE_POPOVER_ACTION) {
      setSelectEmployeePopoverInfo({
        ...selectEmployeePopoverInfo,
        open: false,
      });
      setActionComment('');
      setLoading(false);
    } else if (trimmedAction === SELECT_EMPLOYEE_POPOVER_ACTION) {
      try {
        setSelectEmployeePopoverInfo({
          ...selectEmployeePopoverInfo,
          open: false,
        });
        if (useWithoutRequiredFieldsFilled) {
          await onSubmit(clickedButtonComponentParams?.value || trimmedAction)(
            formMethods.getValues()
          );
        } else {
          await formMethods.handleSubmit(
            onSubmit(clickedButtonComponentParams?.value || trimmedAction)
          )();
        }
        if (actionComment?.length && !hasSubmitError?.current) {
          await addComment(actionComment);
        }
        setActionComment('');
        setLoading(false);
      } catch (error) {
        setError(ADD_COMMENT_POPOVER_ACTION_ERROR_TEXT);
        setLoading(false);
      }
    } else if (trimmedAction === 'Open template creation popover') {
      setTemplatePopoverInfo({ ...templatePopoverInfo, open: true });
      setLoading(false);
    } else if (trimmedAction === 'Close template creation popover') {
      setTemplatePopoverInfo({ ...templatePopoverInfo, open: false });
      setLoading(false);
    } else if (trimmedAction === CLOSE_COMMENT_POPOVER_ACTION) {
      setCommentPopoverInfo({
        ...commentPopoverInfo,
        open: false,
        useWithoutRequiredFieldsFilled: false,
      });
      setLoading(false);
    } else if (trimmedAction === ADD_COMMENT_FROM_POPOVER_ACTION) {
      setCommentPopoverInfo({
        ...commentPopoverInfo,
        useWithoutRequiredFieldsFilled: false,
        open: false,
      });
      try {
        const handleError = () => {
          setLoading(false);
        };
        const handleSubmit = async (values) => {
          try {
            await onSubmit(commentedAction)(values);
            if (actionComment?.length && !hasSubmitError?.current) {
              await addComment(actionComment);
            }
            setLoading(false);
          } catch (e) {
            setLoading(false);
          }
        };
        if (useWithoutRequiredFieldsFilled) {
          handleSubmit(formMethods.getValues());
        } else {
          formMethods.handleSubmit(handleSubmit, handleError)();
        }
      } catch (e) {
        setError(ADD_COMMENT_POPOVER_ACTION_ERROR_TEXT);
        setLoading(false);
      }
    } else if (trimmedAction === TAKE_ON_A_TASK) {
      try {
        const { taskId } = bpmTask;
        const { assigneeVariableName } = bpmTask.currentAction;
        await takeOnATask({
          taskId,
          variable: assigneeVariableName
        });
        setLoading(false);
        dispatch(
          setSubmitSnackbarParams({ open: true, action: TAKE_ON_A_TASK })
        );
      } catch (e) {
        setError(TAKE_ON_A_TASK_ERROR_TEXT);
        hasSubmitError.current = true;
        setLoading(false);
      }
      setLoading(false);
    } else if (trimmedAction === OPEN_STOP_COMMENT_POPOVER) {
      setCommentPopoverSubmitAction(STOP_TASKS_ACTION);
      setCommentPopoverInfo({ ...commentPopoverInfo, open: true });
      setLoading(false);
    } else if (trimmedAction === STOP_TASKS_ACTION) {
      setCommentPopoverInfo({ ...commentPopoverInfo, open: false });
      try {
        const { processInstanceId, taskId } = bpmTask;
        await cancelProcess({
          taskId,
          processInstanceId,
          cancelReason: actionComment,
        });
        setLoading(false);
        dispatch(setSubmitSnackbarParams({ open: true, action: 'Stop' }));
      } catch (e) {
        setError(STOP_TASKS_ACTION_ERROR_TEXT);
        hasSubmitError.current = true;
        setLoading(false);
      }
      setActionComment('');
      setLoading(false);
    } else if (trimmedAction === SAVE_TEMPLATE_ACTION) {
      setError('');
      hasSubmitError.current = false;
      await handleSaveTemplateAction(tplName);
    } else if (trimmedAction === SAVE_TEMPLATE_AND_SUBMIT_FORM_ACTION) {
      setError('');
      hasSubmitError.current = false;
      await handleSaveTemplateAction(tplName);
      if (useWithoutRequiredFieldsFilled) {
        onSubmit('Submit')(formMethods.getValues());
      } else {
        formMethods.handleSubmit(onSubmit('Submit'))();
      }
    } else if (trimmedAction === REMOVE_INSTANCE_ACTION) {
      try {
        const currentInstanceId = instance
                                  ? instance?.id
                                  : focusedLinkedInstanceId;
        await deleteEntityInstance(currentInstanceId);

        const fieldName = instanceFormFieldName || instanceFormFieldName;
        const instanceFormFieldValue = formMethods.watch(fieldName as any);
        const valueAsArray =
          typeof instanceFormFieldValue === 'string'
          ? instanceFormFieldValue.split(',')
          : (instanceFormFieldValue as string[]);
        const newFieldValue = valueAsArray
          .filter((v) => +v !== currentInstanceId)
          .join(',');
        formMethods.setValue(fieldName as any, newFieldValue);

        await saveUserInput(formMethods.getValues());
      } catch (error) {
        setError(INSTANCE_DELETION_ERROR);
        hasSubmitError.current = true;
      } finally {
        setLoading(false);
      }
    } else if (trimmedAction === SAVE_INSTANCE_ACTION) {
      if (focusedLinkedInstanceId && instanceFieldsPrefix) {
        await handleLinkedEntityCreationAndEditing({
          clearFormFields,
          instanceId: focusedLinkedInstanceId,
          prefix: instanceFieldsPrefix,
        });
        dispatch(refreshEntitiesAction(true));
      }
      if (closeCustomModal) {
        dispatch(setCustomModalParams({ open: false, loading: false }));
      }
      setLoading(false);
    } else if (trimmedAction === GENERATE_PDF_ACTION) {
      await generatePdf();
      setLoading(false);
    } else {
      await submitTask({
        approveStage: [
          !trimmedAction || trimmedAction === 'Delete' ? '' : trimmedAction,
        ],
      });
    }
  };

  return {
    loading,
    setLoading,
    error,
    buttonDisabled,
    setButtonDisabled,
    alwaysActiveButtons,
    setAlwaysActiveButtons,
    formMethods,
    templatePopoverInfo,
    commentPopoverInfo,
    commentPopoverSubmitAction,
    isDraftsAutoSaveEnabled,
    setActionComment,
    saveUserInput,
    handleAction,
    formSteps,
    activeStep,
    isSummaryStep,
    startNewProcess,
    selectEmployeePopoverInfo,
    setActionEmployee,
    formErrorsRefresh,
    setAssigneeVariableName,
    handleDelete
  };
};

export default useTaskForm;
