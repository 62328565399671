import { useEffect, useMemo, useState, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { useTranslation } from 'react-i18next';

import { useUserProfile } from 'hooks';
import { useTaskState, setTaskComponentsDisabled, setTaskDocuments, setIsSignRequired } from 'store/requests';
import {
  fillDocumentWithFormData,
  getDocument,
  getDocflowDocumentById,
  getDocumentAttachments,
  getWSClientForEvents,
  saveTaskState,
  publishDocument,
  editDocument,
  getTaskHistoryV2,
} from 'api/requests';
import { downloadAttachmentFromUrl } from 'utils/files';
import { SOCKET_ERROR_MESSAGE, SOCKET_EVENT_MESSAGE } from '../../../../../utils/websocket';
import { Docflow } from 'types';
import useTaskForm from 'pages/Task/TaskForm/useTaskForm';

type Props = {
  fetchDocflowDocuments?: boolean; // some hook usage do not use docflowDocuments
  showDeletedDocumentsError?: boolean; // to prevent multiple hooks to spam error messages
  formMethods?: any;
  checkValidationErrors?: () => boolean;
  setErrorMessageLines?: (value: ('fields' | 'documents')[]) => void;
  goToPreviousStep?: () => void;
  docflowDocuments?: Docflow.ProcessDocument[];
};

const useTaskDocuments = ({
  fetchDocflowDocuments = false,
  showDeletedDocumentsError = false,
  formMethods,
  checkValidationErrors,
  setErrorMessageLines,
  goToPreviousStep,
  docflowDocuments,
}: Props) => {
  const { t } = useTranslation();
  const { data: bpmTask, documents } = useTaskState();
  const { saveUserInput } = useTaskForm({});

  const { getValues } = useFormContext();
  const dispatch = useDispatch();
  const { id, companyId } = useUserProfile();

  const [currentDocumentId, setCurrentDocumentId] = useState(-1);
  const [selectedDocflowDocument, setSelectedDocflowDocument] = useState<number>();
  const [documentsForSignature, setDocumentsForSignature] = useState([]);
  const [signedDocuments, setSignedDocuments] = useState([]);
  const [publishedDocuments, setPublishedDocuments] = useState([]);
  const [docflowDocumentsForSignature, setDocflowDocumentsForSignature] = useState<Docflow.FullDocflowDocument[]>([]);
  const [docflowDocumentsForView, setDocflowDocumentsForView] = useState<Docflow.FullDocflowDocument[]>([]);
  const [currentLoadingDocumentId, setCurrentLoadingDocumentId] = useState(-1);
  const [documentViewMode, setDocumentViewMode] = useState<'view' | 'sign'>('view');
  const [goBackToFormAfterClose, setGoBackToFormAfterClose] = useState(false);
  const documentIdToOpen = useRef<number | null>(null);
  const viewModeToOpen = useRef<'view' | 'sign'>('view');
  const socketTrigger = useRef<number>(0);
  const [lastPublishedDocument, setLastPublishedDocument] = useState<number>();

  useEffect(() => {
    const socket = getWSClientForEvents();
    const token = JSON.parse(localStorage.getItem('dms-auth'))?.id_token;

    const handleWebSocketMessage = (message) => {
      if (message.type === 'close_qr' && message.payload === socketTrigger.current) {
        onSignComplete();
      }
    };

    const handleError = () => {
      socket.close();
    };

    socket.eventEmitter.on(SOCKET_EVENT_MESSAGE, handleWebSocketMessage);
    socket.eventEmitter.on(SOCKET_ERROR_MESSAGE, handleError);

    socket.open(token);

    return () => {
      socket.close();
      socket.eventEmitter.off(SOCKET_EVENT_MESSAGE, handleWebSocketMessage);
      socket.eventEmitter.off(SOCKET_ERROR_MESSAGE, handleError);
    };
  }, [socketTrigger]);

  const handleGetDocflowDocuments = async (signedDocs?: Docflow.ProcessDocument[]) => {
    setDocflowDocumentsForSignature([]);
    setDocflowDocumentsForView([]);

    const docflowField = bpmTask?.attributes?.find((attr) => attr.name.includes('documentFromDocflow-'));
    if (docflowField && docflowField?.component !== 'hidden-field') {
      const params = JSON.parse(docflowField?.componentParams || '{}');
      const signRequiredSteps = params?.signRequiredSteps || [];

      const allDocflowDocuments = signedDocs?.length
        ? signedDocs
        : docflowDocuments?.length
        ? docflowDocuments
        : bpmTask?.entity?.taskInstance?.docFlowDocuments || [];

      try {
        if (allDocflowDocuments?.length) {
          const promises = [];
          allDocflowDocuments.forEach((doc) => promises.push(getDocument(doc.id)));
          const result = await Promise.all(promises);

          if (signRequiredSteps?.includes(bpmTask?.currentAction?.stepperOrder)) {
            setDocflowDocumentsForSignature(result);
          } else {
            setDocflowDocumentsForView(result);
          }
          if (
            // if we need to sign documents, but documents do not have any signatories or its not signed => validation error
            signRequiredSteps?.includes(bpmTask?.currentAction?.stepperOrder) &&
            (!result?.[0]?.signatories.length ||
              result?.[0]?.signatories.some((signatory) => signatory.userId === id && !signatory.isSigned))
          ) {
            dispatch(setIsSignRequired(true));
          } else {
            dispatch(setIsSignRequired(false));
          }
        }
      } catch (err) {
        if ((err?.status === 404 && showDeletedDocumentsError, showDeletedDocumentsError)) {
          NotificationManager.error(t('DocumentProcessFromDocflow.RequestError'));
        }
      } finally {
        if (signRequiredSteps?.includes(bpmTask?.currentAction?.stepperOrder) && !allDocflowDocuments.length) {
          // if we need to sign docs, but no documents selected => validation error
          dispatch(setIsSignRequired(true));
        } else {
          dispatch(setIsSignRequired(false)); // else validation success
        }
      }
    }
  };

  useEffect(() => {
    if (fetchDocflowDocuments) {
      handleGetDocflowDocuments();
    }
  }, [docflowDocuments]);

  useEffect(() => {
    if (selectedDocflowDocument) {
      socketTrigger.current = selectedDocflowDocument;
      setCurrentDocumentId(-1);
    }
  }, [selectedDocflowDocument]);

  useEffect(() => {
    if (currentDocumentId >= 0) {
      socketTrigger.current = currentDocumentId;
      setSelectedDocflowDocument(null);
    }
  }, [currentDocumentId]);

  const hasPartiallyOrFullySignedDocflowDocuments = useMemo(() => {
    const docs = [...docflowDocumentsForSignature, ...docflowDocumentsForView];
    return docs.some((doc) => doc.signatories.some((signatory) => signatory.isSigned) || signedDocuments.includes(doc.id));
  }, [docflowDocumentsForSignature, docflowDocumentsForView, signedDocuments]);

  const openDocumentForSignWithValidation = async (documentId) => {
    documentIdToOpen.current = documentId;
    viewModeToOpen.current = 'sign';
    await formMethods?.trigger();
  };

  const openDocumentForPreviewWithValidation = async (documentId) => {
    documentIdToOpen.current = documentId;
    viewModeToOpen.current = 'view';
    await formMethods?.trigger();
  };

  const isSignatureAvailable = useMemo(() => !['Reject', 'Cancel', 'Canceled', 'Completed'].includes(bpmTask.businessTask?.taskStatus), [
    bpmTask,
  ]);

  const hasPartiallyOrFullySignedDocuments = useMemo(
    () =>
      documents.some((doc) =>
        doc.signings.some(
          (signing) =>
            // document signature was already created
            signing.status ||
            // or was created on the current step
            (signing.stepOrder === bpmTask?.currentAction?.stepperOrder && doc.isSigned)
        )
      ),
    [documents, bpmTask?.currentAction?.stepperOrder]
  );

  const hasPublishedDocuments = useMemo(() => documents.some((doc) => doc.isPublished), [documents, bpmTask?.currentAction?.stepperOrder]);

  const hasFullySignedDocuments = useMemo(
    () =>
      documents.some((doc) =>
        doc.signings.every(
          (signing) =>
            // document signature was already created
            signing.status ||
            // or was created on the current step
            (signing.stepOrder === bpmTask?.currentAction?.stepperOrder && doc.isSigned)
        )
      ),
    [documents, bpmTask?.currentAction?.stepperOrder]
  );

  useEffect(() => {
    if (!formMethods?.formState?.isValidating && documentIdToOpen?.current) {
      const valid = checkValidationErrors();

      if (valid) {
        if (viewModeToOpen.current === 'sign') {
          openDocumentForSign(documentIdToOpen.current);
        } else {
          previewDocument(documentIdToOpen.current);
        }
      } else {
        setErrorMessageLines(['fields']);
      }

      documentIdToOpen.current = null;
    }
  }, [formMethods?.formState?.isValidating]);

  const beforeDocumentSign = async () => {
    if (currentDocumentId >= 0) {
      const document = documents.find((doc) => doc.docflowDocumentId === currentDocumentId);
      if (!(publishedDocuments.includes(currentDocumentId) || document.isPublished)) {
        // save current form values
        const formValues = getValues();
        const data = bpmTask?.setInstanceAttributes({
          values: formValues,
          observerUsers: [],
        });
        await saveTaskState(bpmTask.taskId, data);

        // save published status
        const modifiedDocuments = documents.map((doc) => {
          if (doc.docflowDocumentId === currentDocumentId) {
            doc.isPublished = true;
          }

          return doc;
        });
        dispatch(setTaskDocuments(modifiedDocuments));
        setPublishedDocuments((docs) => [...docs, currentDocumentId]);
      }
    } else if (selectedDocflowDocument) {
      const draftDocuments = docflowDocumentsForSignature.filter((doc) => doc.status === 'DRAFT').map((doc) => doc.id);
      if (draftDocuments?.length) {
        const promises = [];
        draftDocuments.forEach((documentId) => promises.push(publishDocument(documentId)));
        await Promise.all(promises);
        setDocflowDocumentsForSignature((prev) =>
          prev.map((doc) => {
            if (draftDocuments.includes(doc.id)) {
              return {
                ...doc,
                status: 'PENDING_YOUR_SIGNATURE',
              };
            }
            return doc;
          })
        );
      }
    }
  };

  const openDocumentForSign = async (documentId, isDocflowDocument?: boolean) => {
    setLastPublishedDocument(documentId);
    setCurrentLoadingDocumentId(() => documentId);
    setDocumentViewMode(() => 'sign');
    if (!isDocflowDocument) {
      const docflowDocument = await getDocument(documentId);
      const isDocumentPublished = docflowDocument?.status !== 'DRAFT';
      if (!isDocumentPublished) {
        // fill document with form fields data
        const formValues = getValues();
        const data = bpmTask?.setInstanceAttributes({
          values: formValues,
          observerUsers: [],
        });
        if (lastPublishedDocument !== documentId) {
          await fillDocumentWithFormData(documentId, bpmTask.processInstanceId, data);
        }
      }
      // hide loader and open document modal
      setCurrentDocumentId(() => documentId);
      setCurrentLoadingDocumentId(() => -1);
    } else {
      const document = await getDocflowDocumentById(documentId);
      if (!document.signatories.length) {
        const docflowField = bpmTask?.attributes?.find((attr) => attr.name.includes('documentFromDocflow-'));
        const params = JSON.parse(docflowField?.componentParams || '{}');
        const signRequiredSteps = params?.signRequiredSteps || [];

        const history = await getTaskHistoryV2(bpmTask?.processInstanceId); // getting all process' assignees from task history
        const steps = [...history.pastTasks, ...history.futureTasks];
        const signatureRequiredAssignees = [];
        steps.forEach((step) => {
          // checking which assignees need to sign document and on which step
          if (signRequiredSteps.includes(step.stepperOrder) && !signatureRequiredAssignees.includes(step.assignee)) {
            signatureRequiredAssignees.push(step.assignee);
          }
        });
        await editDocument({
          ...document,
          signatories: signatureRequiredAssignees.map((assignee) => ({
            // finally overriding document's signatories
            userId: assignee,
            workspaceId: companyId,
            isSigned: false,
            groupId: '',
          })),
        });
      }
      setSelectedDocflowDocument(documentId);
      setTimeout(() => setCurrentLoadingDocumentId(() => -1), 1000);
    }
  };

  const previewDocument = async (documentId: number, isDocflowDocument?: boolean) => {
    setDocumentViewMode(() => 'view');
    if (!isDocflowDocument) {
      const document = documents.find((doc) => doc.docflowDocumentId === documentId);
      if (!document?.isPublished) {
        setCurrentLoadingDocumentId(() => documentId);
        const formValues = getValues();
        const data = bpmTask?.setInstanceAttributes({
          values: formValues,
          observerUsers: [],
        });
        await fillDocumentWithFormData(documentId, bpmTask.processInstanceId, data);
        setCurrentLoadingDocumentId(() => -1);
      }
      setCurrentDocumentId(() => documentId);
    } else {
      setSelectedDocflowDocument(documentId);
    }
  };

  const downloadDocument = async (documentId) => {
    setCurrentLoadingDocumentId(() => documentId);
    const documentData = await getDocument(documentId);
    const attachment = await getDocumentAttachments(documentData?.attachmentId);
    await downloadAttachmentFromUrl(attachment.signedLink, documentData.title + '.pdf');
    await downloadAttachmentFromUrl(attachment.link, documentData.title + ' (QR).pdf');
    setCurrentLoadingDocumentId(() => -1);
  };

  useEffect(() => {
    setSignedDocuments((docs) => [...docs, ...documents.filter((doc) => doc.isSigned).map((doc) => doc.docflowDocumentId)]);
  }, [documents]);

  const visibleDocuments = useMemo(
    () =>
      documents.filter(
        (document) =>
          document.firstAppearanceStep <= bpmTask.currentAction.stepperOrder &&
          !document.documentHiddenSteps.includes(bpmTask.currentAction.stepperOrder)
      ),
    [documents, bpmTask.currentAction.stepperOrder]
  );

  const groupedDocuments = useMemo(() => {
    const documentsForSign = visibleDocuments.filter((document) => document.signRequiredSteps.includes(bpmTask.currentAction.stepperOrder));
    const documentsForView = visibleDocuments.filter(
      (document) => !document.signRequiredSteps.includes(bpmTask.currentAction.stepperOrder)
    );

    setDocumentsForSignature(() => [...documentsForSign.map((doc) => doc.docflowDocumentId)]);
    return {
      sign: documentsForSign,
      view: documentsForView,
    };
  }, [documents, bpmTask.currentAction.stepperOrder, visibleDocuments]);

  useEffect(() => {
    const publishedDocumentsList = documents.filter((doc) => publishedDocuments.includes(doc.docflowDocumentId) || doc?.isPublished);
    const allPublishedDocumentsFields = publishedDocumentsList.flatMap((doc) => doc.flowTabFields.map((field) => field.templateFieldId));
    dispatch(setTaskComponentsDisabled(allPublishedDocumentsFields));
  }, [documents, publishedDocuments]);

  const onSignComplete = async () => {
    try {
      if (currentDocumentId > 0) {
        await setSignedDocuments((docs) => [...docs, currentDocumentId]);
        const modifiedDocuments = documents.map((doc) => {
          if (doc.docflowDocumentId === currentDocumentId) {
            doc.isSigned = true;
          }
          return doc;
        });
        dispatch(setTaskDocuments(modifiedDocuments));
      } else {
        const formValues = formMethods.getValues();
        await saveUserInput(formValues); // save process on document sign
        await handleGetDocflowDocuments(formValues?.docFlowDocuments); // and refetch signed documents
      }
    } catch (err) {
      console.error('onSignComplete error; useTaskDocuments file;', err);
    } finally {
      setSignedDocuments((docs) => {
        const updatedSignedDocuments = [...docs, currentDocumentId];
        const remainingDocuments = docflowDocumentsForSignature.filter((doc) => !updatedSignedDocuments.includes(doc.id));

        if (remainingDocuments.length > 0) {
          setSelectedDocflowDocument(null);
        } else {
          // Закрываем все окна, если все документы подписаны
          setCurrentDocumentId(-1);
          setSelectedDocflowDocument(null);
          setCurrentLoadingDocumentId(() => -1);
        }

        return updatedSignedDocuments;
      });
    }
  };

  const handleDocumentClose = () => {
    if (
      goBackToFormAfterClose &&
      ((currentDocumentId >= 0 && !signedDocuments.includes(currentDocumentId)) ||
        (selectedDocflowDocument &&
          docflowDocumentsForSignature?.some((doc) => doc?.signatories?.find((user) => user.userId === id && !user.isSigned))))
    ) {
      goToPreviousStep();
      setGoBackToFormAfterClose(() => false);
    }
    setCurrentDocumentId(-1);
    setSelectedDocflowDocument(null);
    setCurrentLoadingDocumentId(() => -1);
  };

  const changeCurrentDocument = (documentId) => {
    const docflowDocument = docflowDocumentsForSignature.find((doc) => doc.id === documentId);
    if (documentsForSignature.includes(documentId) || docflowDocument) {
      openDocumentForSign(documentId, Boolean(docflowDocument));
    } else {
      previewDocument(documentId);
    }
  };

  return {
    visibleDocuments,
    groupedDocuments,
    currentLoadingDocumentId,
    currentDocumentId,
    documentViewMode,
    isSignatureAvailable,
    hasPartiallyOrFullySignedDocuments,
    hasFullySignedDocuments,
    hasPartiallyOrFullySignedDocflowDocuments,
    hasPublishedDocuments,
    documentsForSignature,
    signedDocuments,
    beforeDocumentSign,
    openDocumentForSign,
    openDocumentForSignWithValidation,
    openDocumentForPreviewWithValidation,
    previewDocument,
    downloadDocument,
    changeCurrentDocument,
    onSignComplete,
    setSignedDocuments,
    setCurrentDocumentId,
    setCurrentLoadingDocumentId,
    setGoBackToFormAfterClose,
    handleDocumentClose,
    selectedDocflowDocument,
    setSelectedDocflowDocument,
    docflowDocumentsForSignature,
    docflowDocumentsForView,
    setDocumentViewMode,
  };
};

export default useTaskDocuments;
