import { createStore, applyMiddleware, compose, StoreEnhancer } from 'redux';
import promiseMiddleware from 'redux-promise-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';

import { defaultState, rootReducer as reducer } from './rootReducer';

const middlewares= [promiseMiddleware];

function generateCompose(...middlewares: Array<StoreEnhancer<any>>): StoreEnhancer {
  if (process.env.NODE_ENV === 'development') {
    return composeWithDevTools({
      trace: true,
    })(...middlewares)
  }

  return compose(...middlewares);
}

const store = createStore(reducer, defaultState, generateCompose(applyMiddleware(...middlewares)));

export type RootState = typeof defaultState;
export default store;
