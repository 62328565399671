import React from 'react';
import { Box, CircularProgress, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { EdsModal } from '../EdsModal';

type Props = {
  type: 'button';
  variant: 'contained';
  color: 'primary';
  className?: string;
  label: string;
  userModelId: string;
  attachmentName: string;
  attachmentStorage?: string;
  binAttribute: string;
  companyAttribute: string;
  individualNumberAttribute: string;
  emailAttribute: string;
  supplierEmailAttribute: string;
  existingSupplierAttribute: string;
  supplierBinAttribute: string;
  disabled: boolean;
  isModalOpen: boolean;
  isInternalSign: boolean;
  isExternalSign: boolean;
  isInternalSignWithEmployee: boolean;
  onClick: () => void;
  handleModalState: (state: boolean) => void;
  icon?: string | React.ReactNode;
};

export const EdsButtonCallModal = ({
  type,
  variant,
  label,
  color,
  className,
  isModalOpen,
  disabled,
  userModelId,
  attachmentName,
  attachmentStorage,
  binAttribute,
  companyAttribute,
  individualNumberAttribute,
  emailAttribute,
  supplierEmailAttribute,
  existingSupplierAttribute,
  supplierBinAttribute,
  isInternalSign,
  isExternalSign,
  isInternalSignWithEmployee,
  onClick,
  handleModalState,
  icon,
}: Props) => {
  const { t } = useTranslation();
  const { trigger } = useFormContext();

  const handleClick = async () => {
    const isFormValid = await trigger();
    if (!isFormValid) {
      return;
    }
    handleModalState(true);
  };

  return (
    <>
      <Button type={type} variant={variant} className={className} color={color} disabled={disabled || isModalOpen} onClick={handleClick}>
        {icon ? (
          typeof icon === 'string' ? (
            <img src={icon} style={{ width: 20, height: 20, marginRight: 6 }} alt="Icon" />
          ) : (
            <div style={{ marginRight: 6 }}>{icon}</div>
          )
        ) : null}
        <span>{t(label)}</span>
        {isModalOpen && (
          <Box display="flex" alignItems="center" ml={1}>
            <CircularProgress size={24} />
          </Box>
        )}
      </Button>

      <EdsModal
        isModalOpen={isModalOpen}
        isInternalSign={isInternalSign}
        isExternalSign={isExternalSign}
        isInternalSignWithEmployee={isInternalSignWithEmployee}
        attachmentName={attachmentName}
        attachmentStorage={attachmentStorage}
        binAttribute={binAttribute}
        companyAttribute={companyAttribute}
        individualNumberAttribute={individualNumberAttribute}
        emailAttribute={emailAttribute}
        supplierEmailAttribute={supplierEmailAttribute}
        existingSupplierAttribute={existingSupplierAttribute}
        supplierBinAttribute={supplierBinAttribute}
        labelValue={label}
        userModelId={userModelId}
        onClick={onClick}
        handleModalState={handleModalState}
      />
    </>
  );
};
