import React from 'react';
import { Box } from '@mui/material';

import useEdsForm from './useEdsForm';
import { EdsFormBody } from './EdsFormBody';
import { EdsFormFooter } from './EdsFormFooter';

import useStyles from './useStyles';
import { EdsFormHeader } from './EdsFormHeader';

type Props = {
  isInternalSign: boolean;
  isExternalSign: boolean;
  isInternalSignWithEmployee: boolean;
  isInternalSignEmployeeInitiator?: boolean;
  labelValue: string;
  attachmentName: string;
  attachmentStorage: string;
  binAttribute: string;
  companyAttribute: string;
  individualNumberAttribute: string;
  emailAttribute: string;
  supplierEmailAttribute: string;
  existingSupplierAttribute: string;
  supplierBinAttribute: string;
  userModelId: string;
  onClick: () => void;
  handleModalState: (value: boolean) => void;
};

export const EdsForm = ({
  userModelId,
  attachmentName,
  attachmentStorage,
  binAttribute,
  companyAttribute,
  individualNumberAttribute,
  emailAttribute,
  supplierEmailAttribute,
  existingSupplierAttribute,
  supplierBinAttribute,
  labelValue,
  isInternalSign,
  isExternalSign,
  isInternalSignWithEmployee,
  isInternalSignEmployeeInitiator,
  onClick,
  handleModalState,
}: Props) => {
  const classes = useStyles();

  const {
    loading,
    error,
    buttonLabel,
    clientEdsFileName,
    isButtonDisabled,
    handleClientSignature,
    handleClientEdsPassword,
    handleClientEdsFile,
  } = useEdsForm({
    userModelId,
    attachmentName,
    attachmentStorage,
    binAttribute,
    companyAttribute,
    individualNumberAttribute,
    emailAttribute,
    supplierEmailAttribute,
    existingSupplierAttribute,
    supplierBinAttribute,
    labelValue,
    isInternalSign,
    isExternalSign,
    isInternalSignWithEmployee,
    isInternalSignEmployeeInitiator,
    onClick,
  });

  return (
    <>
      <EdsFormHeader loading={loading} handleModalState={handleModalState} />
      <Box className={classes.content}>
        <EdsFormBody
          loading={loading}
          buttonLabel={buttonLabel}
          clientEdsFileName={clientEdsFileName}
          isButtonDisabled={isButtonDisabled}
          handleClientSignature={handleClientSignature}
          handleClientEdsPassword={handleClientEdsPassword}
          handleClientEdsFile={handleClientEdsFile}
        />
        <EdsFormFooter loading={loading} error={error} />
      </Box>
    </>
  );
};
