import { Docflow } from 'types';

export const prepareDocumentForBpm = (doc: Docflow.FullDocflowDocument): Docflow.ProcessDocument => ({
  id: doc.id,
  title: doc.title,
  description: doc.description,
  initiator: doc?.initiator,
  status: doc.status,
  signatories: doc.signatories,
  payments: {
    contractValue: doc?.payments?.contractValue,
    paymentBalance: doc?.payments?.paymentBalance,
    currency: doc?.payments?.currency,
    isVat: doc?.payments?.isVAT,
  },
  counterParties: doc.counterparties?.map((item) => ({
    id: item.id,
    name: item.name,
    companyCode: item.companyCode,
    signatoryName: item.signatoryName,
  })),
  documentHiddenSteps: [],
  signRequiredSteps: [],
  documentOrder: 0,
  firstAppearanceStep: 0,
  processSysId: doc.processId,
  attachmentId: doc.attachmentId,
});
