import { combineReducers } from 'redux';

import main, { initState as mainState } from './main';
import requests, { initState as requestsState } from './requests';
import approvals, { initState as approvalsState } from './approvals';
import monitoring, { initState as monitoringState } from './monitoring';
import search, { initState as searchState } from './search';
import users, { initState as usersState } from './users';
import settings, { settingsInitialState } from './settings';

export const defaultState = {
  main: {...mainState},
  requests: { ...requestsState },
  approvals: { ...approvalsState },
  monitoring: { ...monitoringState },
  search: { ...searchState},
  users: { ...usersState },
  settings: { ...settingsInitialState},
};

const appReducer = combineReducers({
  main,
  requests,
  approvals,
  monitoring,
  search,
  users,
  settings
});

export const rootReducer = (state, action) => {
  if (action.type === 'RESET_STATE') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action)
}
