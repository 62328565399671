import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ palette, spacing }) => ({
  textFieldDisabled: {
    '& > div': {
      backgroundColor: '#E6ECF1 !important',
    }
  },
}));

export default useStyles;
