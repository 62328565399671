import { useEffect, useMemo, useState } from 'react';

import {
  useSettingsACLUserPermission,
  useSettingsACLUserProcesses,
  useSettingsCheckUserSettingsAccess,
  useSettingsTemplateProcessesPermissions,
} from 'store/settings';
import { useTaskState } from 'store/requests';
import { CompanyPermission, StatusType } from 'types';

import { useUserProfile } from './useUserProfile';

export const ACL_PERMISSIONS = {
  BPM_GLOBAL_SEARCH: 'bpm_global_search',
  DMS_BPM_GLOBAL_SEARCH: 'dms_bpm_search_all',
  REGISTER_EXPORT: 'bpm_register_export',
  TASK_READER: 'reader',
  TASK_COMPLETE: 'bpm_completed',
  TEMPLATE_PROCESS_CREATE: 'template_process_create',
  TEMPLATE_PROCESS_EDIT: 'template_process_edit',
  TEMPLATE_PROCESS_COPY: 'template_process_copy',
  TEMPLATE_PROCESS_ARCHIVE: 'template_process_archive',
  GROUP_SETTINGS: 'groupSetting',
  BPM_ADMIN: "bpm_admin_access",
  BPM_CREATE_TEMPLATE_ACCESS: "bpm_create_template_access",
  MASS_ACTIONS: 'dms_bpm_mass_approve'
};

export const TEMPLATE_PROCESSES_PERMISSIONS = [
  ACL_PERMISSIONS.TEMPLATE_PROCESS_CREATE,
  ACL_PERMISSIONS.TEMPLATE_PROCESS_EDIT,
  ACL_PERMISSIONS.TEMPLATE_PROCESS_COPY,
  ACL_PERMISSIONS.TEMPLATE_PROCESS_ARCHIVE,
];

export const ADMIN_PANEL_PERMISSIONS = [
  ACL_PERMISSIONS.BPM_ADMIN
]

type UsersRoleHookResponse = {
  hasAccessToPromoteRequests: boolean;
  hasAccessToAllRequests: boolean;
  isGroupSettingPageAvailable: boolean;
  isSearchAvailable: boolean;
  isRegisterExportAvailable: boolean;
  isACLUserHasAccessToSettings: boolean;
  isTemplateProcessPageAvailable: boolean;
  isTemplateProcessCreationAvailable: boolean;
  isTemplateProcessEditingAvailable: boolean;
  isTemplateProcessCopyingAvailable: boolean;
  isTemplateProcessArchivingAvailable: boolean;
  areACLUserAccessesLoading: boolean;
  areTemplateProcessesAccessesLoading: boolean;
  currentUserPermissions: any;
  currentUserProcesses: any; //todo - fix any
  ACLUserPermissionStatus: StatusType
  showReadonlyAttributes: boolean;
  isUserAdmin: boolean;
  isTemplatePageAvailable: boolean
  hasAccessToMassActions: boolean
}

export const useUsersRole = (): UsersRoleHookResponse => {
  const { data: bpmTask } = useTaskState();
  const { id } = useUserProfile();

  const [showReadonlyAttributes, setShowReadonlyAttributes] = useState(false);

  const {
    data: ACLUserPermissions,
    status: ACLUserPermissionStatus,
  } = useSettingsACLUserPermission();

  const {
    data: ACLUserProcesses,
    status: ACLUserProcessesStatus,
  } = useSettingsACLUserProcesses();

  const {
    status: templateProcessesAccessesStatus,
    data: templateProcessesAccesses,
  } = useSettingsTemplateProcessesPermissions();

  const isACLUserHasAccessToSettings = useSettingsCheckUserSettingsAccess();

  const areACLUserAccessesLoading = useMemo(() => ACLUserPermissionStatus === StatusType.PENDING, [ACLUserPermissionStatus]);

  const areTemplateProcessesAccessesLoading = useMemo(() => templateProcessesAccessesStatus === StatusType.PENDING, [templateProcessesAccessesStatus]);

  const currentUserPermissions = useMemo((): string[] => {
    if ([StatusType.REJECTED, StatusType.PENDING].includes(ACLUserPermissionStatus)) {
      return [];
    }

    return ACLUserPermissions
      .filter(({ access }: CompanyPermission): boolean => access)
      .map(({ permissionSysName }: CompanyPermission): string => permissionSysName);
  }, [ACLUserPermissionStatus, ACLUserPermissions]);

  const currentUserProcesses = useMemo((): string[] => {
    if ([StatusType.REJECTED, StatusType.PENDING].includes(ACLUserProcessesStatus)) {
      return [];
    }

    return ACLUserProcesses
      .filter(({ access }): boolean => access)
      .map(({ startProcessId }): string => startProcessId);
  }, [ACLUserProcessesStatus, ACLUserProcesses]);

  const hasAccessToPromoteRequests = useMemo((): boolean =>
      currentUserPermissions.includes(ACL_PERMISSIONS.TASK_COMPLETE),
    [currentUserPermissions]);

  const hasAccessToMassActions = useMemo((): boolean =>
      currentUserPermissions.includes(ACL_PERMISSIONS.MASS_ACTIONS),
    [currentUserPermissions]);

  const hasAccessToAllRequests = useMemo((): boolean =>
      currentUserPermissions.includes(ACL_PERMISSIONS.TASK_READER),
    [currentUserPermissions]);

  useEffect(() => {
    if (bpmTask) {
      const showReadonlyAttributes = bpmTask.currentAction.stepperOrder > 1
        && !(bpmTask.isAssignee(id, hasAccessToPromoteRequests) || bpmTask.isAssigneeCandidate(id, hasAccessToPromoteRequests));
      bpmTask.forceRefreshAttributes(showReadonlyAttributes);
      setShowReadonlyAttributes(() => showReadonlyAttributes);
    }
  }, [hasAccessToPromoteRequests, bpmTask]);

  const isGroupSettingPageAvailable = useMemo((): boolean =>
          currentUserPermissions.includes(ACL_PERMISSIONS.GROUP_SETTINGS),
    [currentUserPermissions]);

  const isSearchAvailable = useMemo((): boolean =>
      currentUserPermissions.some((permission: string): boolean =>
        [ACL_PERMISSIONS.DMS_BPM_GLOBAL_SEARCH, ACL_PERMISSIONS.BPM_GLOBAL_SEARCH].includes(permission)),
    [currentUserPermissions]);

  const isRegisterExportAvailable = useMemo((): boolean =>
      currentUserPermissions.includes(ACL_PERMISSIONS.REGISTER_EXPORT),
    [currentUserPermissions]);

  const isTemplateProcessCreationAvailable = useMemo(() =>
      templateProcessesAccesses[ACL_PERMISSIONS.TEMPLATE_PROCESS_CREATE],
    [templateProcessesAccesses]);

  const isTemplateProcessEditingAvailable = useMemo((): boolean =>
      templateProcessesAccesses[ACL_PERMISSIONS.TEMPLATE_PROCESS_EDIT],
    [templateProcessesAccesses]);

  const isTemplateProcessArchivingAvailable = useMemo((): boolean =>
      templateProcessesAccesses[ACL_PERMISSIONS.TEMPLATE_PROCESS_ARCHIVE],
    [templateProcessesAccesses]);

  const isTemplateProcessCopyingAvailable = useMemo((): boolean =>
      templateProcessesAccesses[ACL_PERMISSIONS.TEMPLATE_PROCESS_COPY],
    [templateProcessesAccesses]);

  const isTemplateProcessPageAvailable = useMemo((): boolean =>
      isTemplateProcessCreationAvailable || isTemplateProcessEditingAvailable || isTemplateProcessCopyingAvailable || isTemplateProcessArchivingAvailable,
    [isTemplateProcessCreationAvailable, isTemplateProcessEditingAvailable, isTemplateProcessCopyingAvailable, isTemplateProcessArchivingAvailable]);

  const isUserAdmin = useMemo(() => currentUserPermissions.includes(ACL_PERMISSIONS.BPM_ADMIN), [currentUserPermissions])

  const isTemplatePageAvailable = useMemo(() => currentUserPermissions.includes(ACL_PERMISSIONS.BPM_CREATE_TEMPLATE_ACCESS), [currentUserPermissions])

  return {
    hasAccessToPromoteRequests,
    hasAccessToAllRequests,
    isGroupSettingPageAvailable,
    isSearchAvailable,
    isRegisterExportAvailable,
    isACLUserHasAccessToSettings,
    isTemplateProcessPageAvailable,
    isTemplateProcessCreationAvailable,
    isTemplateProcessEditingAvailable,
    isTemplateProcessCopyingAvailable,
    isTemplateProcessArchivingAvailable,
    areTemplateProcessesAccessesLoading,
    areACLUserAccessesLoading,
    currentUserPermissions,
    currentUserProcesses,
    ACLUserPermissionStatus,
    showReadonlyAttributes,
    isUserAdmin,
    isTemplatePageAvailable,
    hasAccessToMassActions
  };
};
