import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
  Wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  InnerWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  InnerErrorWrapper: {
    border: '1px solid rgb(214, 51, 31)',
    borderRadius: '8px',
  },
  label: {
    position: 'relative',
    width: 'fit-content',
    color: 'rgb(109, 110, 133) ',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '18px',
  },
  labelError: {
    color: 'rgb(214, 51, 31)',
  },
  amountInput: {
    width: 291,
    '& div': {
      borderTopRightRadius: '0 !important',
      borderBottomRightRadius: '0 !important',
      marginRight: -1,
      width: '100%',
      '&:hover': {
        zIndex: 20,
      },
      '&:focused': {
        zIndex: 20,
      },
    },
  },
  currencyAutocomplete: {
    width: 109,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    '& .MuiSelect-icon': {
      color: 'rgb(139, 140, 158)',
    },
    '& fieldset': {
      borderLeft: 'none !important',
    },
  },
  currencyInputRoot: {
    borderTopLeftRadius: '0 !important',
    borderBottomLeftRadius: '0 !important',
    width: 109,
    backgroundColor: theme.palette.grey[100],
    color: 'rgb(38, 40, 66)',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
  },
  currencyInput: {
    padding: '0 !important',
  },
  currencyItemSelected: {
    background: 'rgb(230, 245, 253) !important',
    width: '100%',
    height: '100%',

    '& svg': {
      '& path': {
        fill: 'rgb(3, 155, 230)',
      },
    },
  },
  errorMessage: {
    marginLeft: 14,
  },
}));
