import { createAction } from 'redux-actions';

import store from 'store';

import {
  getApprovalsRequests,
  getReviewedApprovalsRequests,
  getReviewedApprovalsCount,
  getMonitoringListRequest,
} from 'api/requests';

export const APPROVALS_ACTIONS = {
  CLEAR_SELECTED_APPROVAL: 'CLEAR_SELECTED_APPROVAL',
  CLEAR_SELECTED_REVIEWED_CURRENT_TASK: 'CLEAR_SELECTED_REVIEWED_CURRENT_TASK',
  GET_ACTIVE_APPROVALS_COUNT: 'GET_ACTIVE_APPROVALS_COUNT',
  GET_APPROVALS: 'GET_APPROVALS',
  GET_REVIEWED_APPROVALS_COUNT: 'GET_REVIEWED_APPROVALS_COUNT',
  GET_WATCH_LIST: 'GET_WATCH_LIST',
  REFRESH_APPROVALS: 'REFRESH_APPROVALS',
  SET_ACTIVE_APPROVALS_COUNT: 'SET_ACTIVE_APPROVALS_COUNT',
  SET_APPROVALS_ERROR: 'SET_APPROVALS_ERROR',
  SET_APROVALS_LIST_REVIEWED: 'SET_APROVALS_LIST_REVIEWED',
  SET_CURRENT_PAGE_SIZE: 'SET_CURRENT_PAGE_SIZE',
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
  SET_MASS_APPROVE_ACTION_STATUS: 'SET_MASS_APPROVE_ACTION_STATUS',
  SET_REVIEWED_APPROVALS_COUNT: 'SET_REVIEWED_APPROVALS_COUNT',
  SET_SELECTED_APPROVAL: 'SET_SELECTED_APPROVAL',
  SET_SELECTED_REVIEWED_CURRENT_TASK: 'SET_SELECTED_REVIEWED_CURRENT_TASK',
  TOGGLE_APPROVAL_PANEL: 'TOGGLE_APPROVAL_PANEL',
  SET_SELECTED_TASK: 'SET_SELECTED_TASK',
  SET_SELECTED_TASK_WATCHERS: 'SET_SELECTED_TASK_WATCHERS'
};

export const getWatchListAction = createAction(
  APPROVALS_ACTIONS.GET_APPROVALS, async ({ params }) => {
    return await getMonitoringListRequest({params});
  });

export const getApprovalsAction = createAction(
  APPROVALS_ACTIONS.GET_APPROVALS,
  async ({ params }) => {
    const approvalsState = store.getState()?.approvals as { [key: string]: any };
    const reviewed = approvalsState.approvalsList.reviewed;

    if (reviewed) {
      return await getReviewedApprovalsRequests({ params: {...params, taskCompleted: false} });
    } else {
      return await getApprovalsRequests({ params: {...params, taskCompleted: false} });
    }
  }
);

export const setApprovalsError = createAction(
  APPROVALS_ACTIONS.SET_APPROVALS_ERROR
);

export const getActiveApprovalsCountAction = createAction(
  APPROVALS_ACTIONS.GET_ACTIVE_APPROVALS_COUNT,
  async () => {
    const params = {
      sort: 'taskStartDate,DESC',
      taskCompleted: false,
      size: 10,
      page: 0
    }
    const { totalElements } = await getApprovalsRequests({params});
    return totalElements || -1;
  }
);

export const setActiveApprovalsCountAction = createAction(
  APPROVALS_ACTIONS.SET_ACTIVE_APPROVALS_COUNT
);

export const getReviewedApprovalsCountAction = createAction(
  APPROVALS_ACTIONS.GET_REVIEWED_APPROVALS_COUNT,
  () => {
    const params = {
      sort: 'taskStartDate,DESC',
      taskCompleted: false,
      size: 10,
      page: 0
    }
    return getReviewedApprovalsCount(params)
  }
);

export const setReviewedApprovalsCountAction = createAction(
  APPROVALS_ACTIONS.SET_REVIEWED_APPROVALS_COUNT
);

export const setCurrentPage = createAction(APPROVALS_ACTIONS.SET_CURRENT_PAGE);

export const setCurrentPageSize = createAction(
  APPROVALS_ACTIONS.SET_CURRENT_PAGE_SIZE
);

export const setReviewed = createAction(APPROVALS_ACTIONS.SET_APROVALS_LIST_REVIEWED);

export const refreshApprovals = createAction(
  APPROVALS_ACTIONS.REFRESH_APPROVALS
);

export const setSelectedApproval = createAction(
  APPROVALS_ACTIONS.SET_SELECTED_APPROVAL
);

export const setSelectedReviewedCurrentTask = createAction(
  APPROVALS_ACTIONS.SET_SELECTED_REVIEWED_CURRENT_TASK
);

export const clearSelectedApproval = createAction(
  APPROVALS_ACTIONS.CLEAR_SELECTED_APPROVAL
);
export const clearSelectedReviewedCurrentTask = createAction(
  APPROVALS_ACTIONS.CLEAR_SELECTED_REVIEWED_CURRENT_TASK
);

export const toggleApprovalPanel = createAction(
  APPROVALS_ACTIONS.TOGGLE_APPROVAL_PANEL
);

export const setMassApproveActionStatus = createAction(
  APPROVALS_ACTIONS.SET_MASS_APPROVE_ACTION_STATUS
);

export const setSelectedTask = createAction(
  APPROVALS_ACTIONS.SET_SELECTED_TASK
);

export const setSelectedTaskWatchers = createAction(
  APPROVALS_ACTIONS.SET_SELECTED_TASK_WATCHERS
);

