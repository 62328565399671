import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { useTaskState } from 'store/requests';
import { parseJSON } from 'utils/general';

import { TaskParametersType } from 'types';
import { fieldBehaviour } from './types';

export const useFieldBehaviour = (attribute?: TaskParametersType): fieldBehaviour => {
  const { data: bpmTask, disabledComponentsList } = useTaskState();
  const { watch } = useFormContext();

  const behaviour = useMemo(() => parseJSON(attribute?.behaviour || ''), [attribute?.behaviour]);

  /* FYI isRuleGroupWorks PROPS:
  Функция isRuleGroupWorks получает значение правил fields

  Пример правил:
  1) Группа правил: [{ attribute*, value* }, { attribute*, value* }, ...]
  2) Одиночное правило: [{ attribute*, value* }]
  *attribute -> аттрибут, за которым мы наблюдаем
  *value -> значение, которое мы ожидаем получить от аттрибута

  Итого:
  fields = [Группа правил, Одиночное правило, Группа правил, ...]
  */
  const isRuleGroupWorks = useCallback(
    (fields): boolean => {
      // FYI: fieldsResponses ожидает массив со значениями [true, false]
      // Пример: fieldsResponses = [true, false, false, true, false];
      const fieldsResponses = fields.map((fieldsRule) =>
        // Возвращаем true только в случае если все value в группе правил сработали
        fieldsRule.every(({ attribute, value }) => {
          const { type, name } = attribute;
          const fieldValue = watch(name);

          // TODO: Проверить в процессах есть ли такие случаи
          if (type === 'BOOLEAN' && typeof fieldValue === 'string') {
            return fieldValue !== 'false' && fieldValue === value;
          }

          if (typeof fieldValue === 'string' && typeof value === 'string') {
            return fieldValue.trim() === value.trim();
          }

          return fieldValue === value;
        })
      );

      // FYI: Если хотя бы одно из правил выполнено, то возвращаем значение true
      return fieldsResponses.some((fieldResponse) => fieldResponse);
    },
    [watch]
  );

  /* FYI getFieldsByListOfRules props
  Функция getFieldsByListOfRules получает список объектов ( правил )

  Пример объекта: {attributeSysName*: value*}
  * attributeSysName - системное название аттрибута
  * value - значение которое мы ожидаем получить от аттрибута
   */
  const getFieldsByListOfRules = useCallback(
    (listOfRules) => {
      if (bpmTask && Array.isArray(listOfRules) && listOfRules.length) {
        return listOfRules.map((listField) =>
          listField.map((listFieldRule: { [key: string]: string }) => {
            const [[attributeSysName, value]] = Object.entries(listFieldRule);

            const attribute = bpmTask.getAttributeBySysName(attributeSysName);

            return {
              attribute,
              value,
            };
          })
        );
      }

      return [];
    },
    [bpmTask]
  );

  const isVisible = useCallback((): boolean => {
    const visibilityFields = getFieldsByListOfRules(behaviour?.visibleOn);

    if (visibilityFields.length) {
      return isRuleGroupWorks(visibilityFields);
    }

    return true;
  }, [behaviour?.visibleOn, getFieldsByListOfRules, isRuleGroupWorks]);

  const isInvisible = useCallback((): boolean => {
    const invisibilityFields = getFieldsByListOfRules(behaviour?.invisibleOn);

    if (invisibilityFields.length) {
      return isRuleGroupWorks(invisibilityFields);
    }

    return false;
  }, [behaviour?.invisibleOn, getFieldsByListOfRules, isRuleGroupWorks]);

  const isActive = useCallback((): boolean => {
    const activityFields = getFieldsByListOfRules(behaviour?.activeOn);

    if (activityFields.length) {
      return isRuleGroupWorks(activityFields);
    }

    return true;
  }, [behaviour?.activeOn, getFieldsByListOfRules, isRuleGroupWorks]);

  const isInactive = useCallback((): boolean => {
    const inactivityFields = getFieldsByListOfRules(behaviour?.inactiveOn);

    if (inactivityFields.length) {
      return isRuleGroupWorks(inactivityFields);
    }

    return false;
  }, [behaviour?.inactiveOn, getFieldsByListOfRules, isRuleGroupWorks]);

  const isDisabled = useCallback(() => disabledComponentsList.includes(attribute?.name), [disabledComponentsList, attribute]);

  return {
    isActive: isActive() && !isInactive() && !isDisabled(),
    isVisible: isVisible() && !isInvisible(),
    isDisabled: isDisabled(),
    isRuleGroupWorks,
    getFieldsByListOfRules,
  };
};
