import React, { useCallback, useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import ContentEditable from 'react-contenteditable';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import moment from 'moment';

import { Spinner, UserInfo } from 'components';

import { useUsersState } from 'store/users';
import { useTaskState } from 'store/requests';
import { getUserDataByIdOrLogName } from 'utils/user';
import { getTranslationKeyForTaskHistory } from 'utils/translations';
import { UserType } from 'types';

import {
  MENTION_REGEX,
  MENTION_REPLACE_TEMPLATE,
  ACTION_LABEL_COLORS,
} from './constants';
import useStyles from './useStyles';

import ApproveIcon from 'assets/images/icons/action-approve.svg';
import MassApproveIcon from 'assets/images/icons/action-mass-approve.svg';
import RejectIcon from 'assets/images/icons/action-reject.svg';
import ReworkIcon from 'assets/images/icons/action-rework.svg';
import NoCommentsIcon from 'assets/images/icons/no-comments.svg';

type Comment = {
  id: string;
  author: string;
  date: Date;
  text: string;
  action?: string | null;
  processStep?: string | null;
};

type Props = {
  comments: { [key: string]: Comment[] };
  loading: boolean;
  companyId: string;
};

export const CommentsList = ({ comments, loading, companyId }: Props) => {
  const classes = useStyles();
  const {data: bpmTask} = useTaskState();

  const { t } = useTranslation();

  const { users } = useUsersState();
  const [extraLoadedUsers, setExtraLoadedUsers] = useState({});

  const renderCommentText = (commentText: string): string =>
    commentText
      .replace(MENTION_REGEX, MENTION_REPLACE_TEMPLATE)
      .replaceAll('/n', '<br/>');

  const loadNotFoundUser = async (userKey: string) => {
    const userData = await getUserDataByIdOrLogName(userKey, companyId);
    if (userData) {
      setExtraLoadedUsers(value => {
        return { ...value, [userKey]: userData };
      });
    } else {
      setExtraLoadedUsers(value => {
        return {
          ...value, [userKey]: {
            id: '',
            avatar: '',
            email: '',
            firstName: '',
            fullName: '',
            fullNameRu: '',
            lastName: '',
            phoneNumber: '',
            role: [''],
            logName: t('task_data_view.employee_not_found'),
            jobTitle: '',
          },
        };
      });
    }
  };

  const getUser = useCallback(
    ({ author }: Comment): UserType => {
      // временно: в старых заявках: author -- был ID
      const hcmsUser = users[author];

      if (hcmsUser) {
        return hcmsUser;
      }

      // логика нахождения пользователя по logName-у в HCMS объекте
      const hcmsUserKeys = Object.keys(users);
      const hcmsUserID = hcmsUserKeys.find((hcmsID) => users[hcmsID].logName === author);

      if (hcmsUserID) {
        return users[hcmsUserID];
      }

      if (Object.keys(extraLoadedUsers).includes(author)) {
        return extraLoadedUsers[author];
      } else {
        loadNotFoundUser(author);
      }

      return {
        id: '',
        avatar: '',
        email: '',
        firstName: '',
        fullName: '',
        fullNameRu: '',
        lastName: '',
        phoneNumber: '',
        role: [''],
        logName: t('task_data_view.employee_not_found'),
        jobTitle: ''
      };
    },
    [users, extraLoadedUsers],
  );

  const actionData = useMemo(
    () => ({
      Reject: {
        label: t('task_history_past.reject'),
        icon: RejectIcon,
        color: ACTION_LABEL_COLORS.red,
      },
      'Mass Reject': {
        label: t('task_history_past.reject'),
        icon: RejectIcon,
        color: ACTION_LABEL_COLORS.red,
      },
      Rework: {
        label: t('task_history.rework'),
        icon: ReworkIcon,
        color: ACTION_LABEL_COLORS.blue,
      },
      'Mass Rework': {
        label: t('task_history_past.rework'),
        icon: ReworkIcon,
        color: ACTION_LABEL_COLORS.blue,
      },
      'Mass Approve': {
        label: t('task_history_past.approve'),
        icon: MassApproveIcon,
        color: ACTION_LABEL_COLORS.green,
      },
      default: {
        label: '',
        icon: ApproveIcon,
        color: ACTION_LABEL_COLORS.green,
      },
    }),
    [],
  );

  const getActionData = useCallback((action) => {
    return actionData[action] || ({ ...actionData.default, label: t(getTranslationKeyForTaskHistory(action)) });
  }, [actionData]);

  return (
    <Box my={3} mr={1} className={classes.commentsList}>

      {Object.keys(comments).length === 0 && <Box className={classes.noCommentsBlock}>
        <img src={NoCommentsIcon} alt='' />

        <Typography component="h3">
          {t("task_data_view.no_comments.title")}
        </Typography>

        <Typography component="p">{t("task_data_view.no_comments.description")}</Typography>
      </Box>}

      {Object.keys(comments).map((commentDateKey) => <>
          <Typography className={classes.dateGroupsDelimiter}>
            {commentDateKey}
          </Typography>

          {comments[commentDateKey].map((comment) => {
            return (
              <Box key={comment.id} mt={4} className={classes.commentItem}>
                <Box display="flex" alignItems="flex-top">
                  <Box mr={2}>
                    <UserInfo
                      user={getUser(comment)}
                      nameOnTop={true}
                      avatarSize={40}
                      nameMarginLeft={12}
                    />
                  </Box>

                  <Typography className={classes.commentDate} color="textSecondary">
                    {moment(comment.date).format('h:mm A')}
                  </Typography>
                </Box>

                <Box ml={13.5} mt={-4.5}>
                  <Typography className={classes.comment}>
                    <ContentEditable
                      disabled
                      html={renderCommentText(comment.text)}
                      onChange={() => null}
                    />
                  </Typography>
                </Box>

                {!!comment.processStep && (
                  <Box ml={13} mt={1.5} mb={1} className={classes.commentInfoWrapper}>
                    <Typography className={classes.processStep}>
                      {t(`constructor-${bpmTask.processSysName}.actions.${comment.processStep}.name`, {defaultValue: bpmTask?.actions?.find(action => action.sysName === comment.processStep)?.name || comment.processStep})}
                    </Typography>

                    {!!comment.action && (
                      <div
                        className={cn(classes.commentedAction, {
                          [classes.commentedActionRed]:
                          getActionData(comment.action)?.color ===
                          ACTION_LABEL_COLORS.red,
                          [classes.commentedActionBlue]:
                          getActionData(comment.action)?.color ===
                          ACTION_LABEL_COLORS.blue,
                          [classes.commentedActionGreen]:
                          getActionData(comment.action)?.color ===
                          ACTION_LABEL_COLORS.green,
                        })}
                      >
                        <img src={getActionData(comment.action)?.icon}/>
                        <span>{getActionData(comment.action)?.label}</span>
                      </div>
                    )}
                  </Box>
                )}
              </Box>
            )
          })
          }
        </>,
      )}

      {loading && <Spinner/>}
    </Box>
  );
};
