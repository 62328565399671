import { useState } from 'react';

type Props = {
  onClick: () => void;
};

const useEdsButton = ({ onClick }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleButtonClick = () => {
    setIsModalOpen(false);
    onClick();
  };

  const handleModalState = modalState => setIsModalOpen(modalState);

  return {
    isModalOpen,
    handleButtonClick,
    handleModalState
  };
};

export default useEdsButton;
