import { useCallback, useMemo } from 'react';
import jsPDF from 'jspdf';
import isNil from 'lodash/isNil';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';

import { useGlossaryState, useHiddenTaskComponentsList, useTaskState } from 'store/requests';
import { useUsersState } from 'store/users';
import { useUserProfile } from 'hooks';
import {
  getDocument,
  getDirectoryInfo,
  getGlossaryAsTree,
  getProcessDocuments,
  getTaskHistoryStructured
} from 'api/requests';
import { environment } from 'environments/environment';

import {
  StepType,
  StructuredHistoryParallelAssigneesStep,
  StructuredHistoryStep,
} from 'components/TaskDetails/components/Tabs/HistoryTab/history.types';

import { COMPONENTS_DATA_TYPES, DATA_TYPES } from '../SummaryStep/constants';
import {
  CARD_BORDER_RADIUS,
  CARD_HEADER_HEIGHT,
  CARD_PADDING,
  CARD_WIDTH,
  COLORS, DOCUMENT_LINK_TEMPLATE, DOCUMENT_NAME_WIDTH,
  FIELD_GAP,
  FIELD_HINT_VALUE_GAP,
  FIELD_HINT_WIDTH,
  FIELD_VALUE_WIDTH,
  FONTS,
  FOOTER_HEIGHT,
  FOOTER_TOP_MARGIN,
  HIDDEN_COMPONENTS,
  HISTORY_CONDITION_CARD_PADDING,
  PADDING_X,
  PADDING_Y,
  PAPER_HEIGHT,
  PAPER_WIDTH,
  PARALLEL_BRANCHES_CARD_MARGIN_LEFT,
  PARALLEL_BRANCHES_CARD_MARGIN_RIGHT,
  PARALLEL_BRANCHES_CARD_PADDING,
  PROCESS_LINK_TEMPLATE,
  WORKSPACE_PARAM,
} from './TaskPdfGenerator.constants';

import './fonts/Euclid Circular B Regular-normal';
import './fonts/Euclid Circular B Medium-normal';
import './fonts/Euclid Circular B SemiBold-normal';
import TaskDetailsIcon from 'assets/images/icons/task-pdf-details-icon.png';
import TaskDocumentsIcon from 'assets/images/icons/task-pdf-documents-icon.png';
import TaskFlowIcon from 'assets/images/icons/task-pdf-flow-icon.png';
import FlowArrow from 'assets/images/icons/task-pdf-flow-arrow-indicator.png';
import FlowCircle from 'assets/images/icons/task-pdf-flow-circle-indicator.png';
import FlowConditionIcon from 'assets/images/icons/task-pdf-flow-condition-indicator.png';
import {
  formatFieldValue,
  getImageDimensionsFromBase64,
  getImageFromUrlAsBase64,
  getInitials,
  getUserById,
} from './utils';
import { CONDITION_TYPES } from '../../../TemplateProcesses/components/StepConditionModal/StepConditionModal.constants';
import { useFormatConditionValue } from 'pages/TemplateProcesses/useFormatConditionValue';
import useTaskHistory from '../../../../components/TaskDetails/components/Tabs/HistoryTab/useTaskHistory';
import { initializeSelectedStates } from '../FormFields/Fields/Glossary/utils';
import { TreeStructureNode } from '../FormFields/Fields/Glossary/TreeGlossaryMultiSelect';
import sortBy from 'lodash/sortBy';

const LOCALES = {
  en: environment.region === 'US'? 'en' : 'en-GB',
  'kz': 'kk',
  'ru': 'ru',
  'id': 'id',
};

export const useTaskPdfGenerator = () => {
  const { t, i18n } = useTranslation();
  const { data: bpmTask } = useTaskState();
  const { users } = useUsersState();
  const { mapList: glossaryMap } = useGlossaryState();
  const hiddenTaskFields = useHiddenTaskComponentsList();
  const { companyName, companyLogo, companyId } = useUserProfile();

  const { parseStructuredHistory } = useTaskHistory({ isActiveTab: false });

  const { formatConditionValue } = useFormatConditionValue();

  moment.locale(LOCALES[i18n.language] || i18n.language);

  const processLink = useMemo(() =>
      PROCESS_LINK_TEMPLATE + bpmTask?.processInstanceId + WORKSPACE_PARAM + companyId
    , [bpmTask]);

  let currentPos = PADDING_Y;
  let currentPage = 1;
  let cardStartPos = 0;

  const getTaskHistoryNodes = useCallback(async () => {
    try {
      const taskHistory: StructuredHistoryStep = await getTaskHistoryStructured(
        bpmTask?.processInstanceId,
      );

      return parseStructuredHistory(taskHistory);
    } catch (error) {
      return [];
    }
  }, [bpmTask?.processInstanceId]);

  const drawCompanyLogo = async (doc, x, y, size) => {
    if (companyLogo) {
      const imageData = await getImageFromUrlAsBase64(companyLogo);
      const imageDimensions = await getImageDimensionsFromBase64(imageData);
      if (imageDimensions.h < imageDimensions.w) {
        const aspectRatio = imageDimensions.h / imageDimensions.w;
        const logoHeight = size * aspectRatio;
        const logoMarginTop = (size - logoHeight) / 2;
        await doc.addImage(imageData, x, y + logoMarginTop, size, logoHeight);
      } else {
        const aspectRatio = imageDimensions.w / imageDimensions.h;
        const logoWidth = size * aspectRatio;
        const logoMarginLeft = (size - logoWidth) / 2;
        await doc.addImage(imageData, x + logoMarginLeft, y, logoWidth, size);
      }
    } else {
      // add logo with company name initials
      const companyNameInitials = getInitials(companyName);
      doc.setFillColor(COLORS.LOGO_PLACEHOLDER_BACKGROUND);
      await doc.roundedRect(x, y, size, size, 4, 4, 'F');
      doc.setTextColor(COLORS.WHITE);
      doc.setFont(FONTS[400]);
      doc.setFontSize(size >= 20 ? 12 : 10);
      const companyNameInitialsWidth = doc.getTextWidth(companyNameInitials);
      await doc.text(companyNameInitials, x + ((size - companyNameInitialsWidth) / 2), y + size * (13 / 20));
    }
  };

  const addHeader = async (doc: jsPDF, isTaskCompleted: boolean) => {
    // company info
    await drawCompanyLogo(doc, PADDING_X, currentPos, 20);
    doc.setTextColor(COLORS.TEXT_DARK_GRAY);
    doc.setFont(FONTS[500]);
    doc.setFontSize(12);

    const companyNameSplit = doc.splitTextToSize(companyName, 435);
    const companyNameTextHeight = companyNameSplit.length * 12;
    doc.text(companyNameSplit, PADDING_X + 20 + 8, currentPos + 1 + 12, { lineHeightFactor: 18 / 12 });
    currentPos += companyNameTextHeight + 2 + 16;

    // process name
    doc.setFontSize(16);
    const localizedProcessName = t(`constructor-${bpmTask.processSysName}.name`, { defaultValue: bpmTask.processName });
    const localizedProcessNameSplit = doc.splitTextToSize(localizedProcessName, 400);
    const localizedProcessNameTextHeight = localizedProcessNameSplit.length * 16;
    const processNameWidth = Math.min(doc.getTextWidth(localizedProcessName), 400);
    doc.text(localizedProcessNameSplit, PADDING_X, currentPos + 16, { lineHeightFactor: 20 / 16 });

    // process link
    doc.setFont(FONTS[400]);
    doc.setFontSize(10);
    doc.setTextColor(COLORS.TEXT_LINK);
    doc.textWithLink(t('taskPdf.open'), PADDING_X + processNameWidth + 4, currentPos + 14, {
      lineHeightFactor: 16 / 10,
      url: processLink,
    });
    doc.setDrawColor(COLORS.TEXT_LINK);
    doc.setLineWidth(0.6);
    doc.line(PADDING_X + processNameWidth + 4, currentPos + 16, PADDING_X + processNameWidth + 4 + doc.getTextWidth(t('taskPdf.open')), currentPos + 16);
    currentPos += localizedProcessNameTextHeight + 4;

    // process start and end dates
    doc.setFontSize(10);
    doc.setTextColor(COLORS.TEXT_LIGHT_GREY);
    const isUSRegion = environment.region === 'US';
    const dateFormat = isUSRegion ? 'MM/DD/YYYY, hh:mm A' : 'D MMMM Y, HH:mm';
    let startEndDatesText = `${t(`taskPdf.started`, { date: moment(bpmTask.createdDate).format(dateFormat) })}`;
    if (isTaskCompleted) {
      startEndDatesText += ` - ${t('taskPdf.finished', { date: moment(bpmTask.completedDate).format(dateFormat) })}`;
    }
    doc.text(startEndDatesText, PADDING_X, currentPos + 10, { lineHeightFactor: 16 / 10 });
    currentPos += 10 + 20;

    // qr code
    const qrCodeElement = (document.getElementById('qrCodeWrapper').firstChild as HTMLCanvasElement);
    doc.addImage(
      qrCodeElement?.toDataURL('image/png'),
      'PNG',
      PAPER_WIDTH - 44 - 56,
      PADDING_Y,
      56,
      56);
    doc.setDrawColor(COLORS.BORDER_DARK_GREY);
    doc.setLineWidth(2);
    doc.roundedRect(
      PAPER_WIDTH - PADDING_X - 56 - 2,
      PADDING_Y - 2,
      60,
      60,
      4,
      4,
      'S',
    );
  };

  const drawCard = async ({
    doc,
    startY,
    height,
    hasHeader = true,
    headerIcon = '',
    iconFormat = 'PNG',
    headerText = '',
  }) => {
    await doc.setPage(currentPage);

    doc.setDrawColor(COLORS.BORDER_LIGHT_GREY);
    doc.setLineWidth(1);
    doc.roundedRect(PADDING_X, startY, CARD_WIDTH, height, CARD_BORDER_RADIUS, CARD_BORDER_RADIUS, 'S');
    if (hasHeader) {
      doc.setDrawColor(COLORS.BORDER_LIGHT_GREY);
      doc.setLineWidth(1);
      doc.line(PADDING_X, startY + CARD_HEADER_HEIGHT, PAPER_WIDTH - PADDING_X, startY + CARD_HEADER_HEIGHT);

      doc.setFontSize(12);
      doc.setFont(FONTS[400]);
      doc.setTextColor(COLORS.TEXT_DARK_GRAY);
      doc.text(headerText, PADDING_X + CARD_PADDING + 16 + 8, startY + 8 + 12, { lineHeightFactor: 16 / 12 });

      await doc.addImage(
        headerIcon,
        iconFormat,
        PADDING_X + CARD_PADDING,
        startY + 8,
        16,
        16,
      );
    }
  };

  const checkFieldVisibility = (field) =>
    !(hiddenTaskFields.includes(field.name) || HIDDEN_COMPONENTS.includes(field.component) || field.name.startsWith('documentFromDocflow-'));

  const drawFieldValue = async (doc, hint, initialValue, value, dataType, params) => {
    doc.setPage(currentPage);
    const isValueEmpty =
      (dataType === DATA_TYPES.DATE && !initialValue) ||
      isNil(value) ||
      (Array.isArray(value) && (value?.length === 0 || (value.length === 1 && !value[0])));

    doc.setFontSize(10);
    doc.setFont(FONTS[400]);
    doc.setTextColor(COLORS.TEXT_LIGHT_GREY);

    if (typeof params.directoryPath === 'string' && !params.multiSelect && !isValueEmpty && dataType !== DATA_TYPES.GLOSSARY_MULTIPLE_VALUES) {
      let glossaryValue = value;

      if (Array.isArray(value)) {
        glossaryValue = value[0];
      }

      try {
        const info = await getDirectoryInfo(glossaryValue);
        const valueText = info?.value;
        value = doc.splitTextToSize(valueText, FIELD_VALUE_WIDTH);
      } catch (error) {
        //
      }
    }

    doc.text(hint, PADDING_X + CARD_PADDING, currentPos + 10, { lineHeightFactor: 16 / 10 });

    if (isValueEmpty) {
      // empty value placeholder
      doc.setTextColor(COLORS.TEXT_VERY_LIGHT_GRAY);
      doc.text(t('form_components.readOnly.emptyValue'), PADDING_X + CARD_PADDING + FIELD_HINT_WIDTH + FIELD_HINT_VALUE_GAP, currentPos + 10, { lineHeightFactor: 16 / 10 });
      currentPos += hint.length * 10 * (16 / 10) * 0.75 + FIELD_GAP;
    } else if (dataType === DATA_TYPES.USER || dataType === DATA_TYPES.USER_LIST) {
      // for readonly-user-list
      if (dataType === DATA_TYPES.USER_LIST) {
        const usersId = Array.isArray(value)
                        ? value
                        : value?.split(',') || [];

        currentPos -= 10;

        const remainingSpace = PAPER_HEIGHT - FOOTER_HEIGHT - FOOTER_TOP_MARGIN - CARD_PADDING * 2 - currentPos;
        const remainingSpaceInUsers = Math.floor((remainingSpace - 12) / 20);

        const usersInPage = Math.floor((PAPER_HEIGHT - PADDING_Y - FOOTER_HEIGHT - FOOTER_TOP_MARGIN - CARD_PADDING * 2) / 20);
        const usersDividedInGroups = [];
        usersDividedInGroups.push(usersId.slice(0, remainingSpaceInUsers + 1));

        for (let i = remainingSpaceInUsers + 1; i < usersId.length; i += usersInPage) {
          const chunk = usersId.slice(i, i + usersInPage);
          usersDividedInGroups.push(chunk);
        }

        let index = 0;
        for (const userGroup of usersDividedInGroups) {
          if (index > 0) {
            await addNewPage(doc, true, currentPage === 1, t('taskPdf.details'), TaskDetailsIcon);
          }

          for (let j = 0; j < userGroup.length; j++) {
            await drawUserData(doc, userGroup[j], PADDING_X + CARD_PADDING + FIELD_HINT_WIDTH + FIELD_HINT_VALUE_GAP, currentPos + 20 * (j + 1) - 6);
          }

          index++;
        }
        currentPos += Math.max((hint.length + 1) * 12, (usersDividedInGroups[index - 1].length + 1) * 20 - 6);
        return;
      }

      if (params?.multiple) {
        const remainingSpace = PAPER_HEIGHT - FOOTER_HEIGHT - FOOTER_TOP_MARGIN - CARD_PADDING * 2 - currentPos;
        const remainingSpaceInUsers = Math.floor((remainingSpace - 12) / 20);

        const usersInPage = Math.floor((PAPER_HEIGHT - PADDING_Y - FOOTER_HEIGHT - FOOTER_TOP_MARGIN - CARD_PADDING * 2) / 20);
        const usersDividedInGroups = [];
        usersDividedInGroups.push(value.slice(0, remainingSpaceInUsers + 1));
        for (let i = remainingSpaceInUsers + 1; i < value.length; i += usersInPage) {
          const chunk = value.slice(i, i + usersInPage);
          usersDividedInGroups.push(chunk);
        }

        let index = 0;
        for (const userGroup of usersDividedInGroups) {
          if (index > 0) {
            await addNewPage(doc, true, currentPage === 1, t('taskPdf.details'), TaskDetailsIcon);
          }

          for (let j = 0; j < userGroup.length; j++) {
            await drawUserData(doc, userGroup[j], PADDING_X + CARD_PADDING + FIELD_HINT_WIDTH + FIELD_HINT_VALUE_GAP, currentPos + 20 * (j + 1) - 6);
          }

          index++;
        }
        currentPos += Math.max((hint.length + 1) * 12, (usersDividedInGroups[index - 1].length + 1) * 20 - 6);
      } else {
        await drawUserData(doc, value, PADDING_X + CARD_PADDING + FIELD_HINT_WIDTH + FIELD_HINT_VALUE_GAP, currentPos);
        currentPos += 20 + FIELD_GAP;
      }
    } else if (dataType === DATA_TYPES.GLOSSARY_MULTIPLE_VALUES) {
      const valuesList = value?.split(',') || [];
      const directoriesPathList = params?.directoryPath ? (params.directoryPath as string).split('/') : [null];
      const directoryId = directoriesPathList[directoriesPathList.length - 1];
      const directoryCode = params?.directoryCode;
      const glossariesTree = await getGlossaryAsTree(null, true);
      const nodesList = initializeSelectedStates(glossariesTree.filter(node => node.data.status === 'PUBLISHED'), valuesList, true);
      const nodesListValues = Object.values(nodesList);
      const directoryNode = nodesListValues.find(node => {
        const n = node as TreeStructureNode;
        if (!directoryCode) {
          return n?.data?.id === directoryId
        } else {
          return n?.data.directoryName === directoryCode;
        }
      }) as TreeStructureNode;
      const tempNodes = {};
      const baseLabel = directoryNode?.data?.localization[i18n.language] || directoryNode?.data?.localization['en'] || directoryNode?.data?.directoryName || directoryNode?.data?.value;
      const parseNode = (childNode: TreeStructureNode, path: string[]) => {
        const node = Object.values(nodesList).find(listNode => (listNode as TreeStructureNode)?.data?.id === childNode.data.id) as TreeStructureNode;
        if (!node) {
          return;
        }
        if (node.children.length > 0 && node.selectedChildCount > 0) {
          const nextPathNodeLabel = node.data.localization[i18n.language] || node.data.localization['en'] || node.data.directoryName || node.data.value;
          node.children.forEach(childNode => {
            parseNode(childNode, [...path, nextPathNodeLabel]);
          });
        } else {
          if (valuesList.includes(node.data.id)) {
            const nodeLabel = node.data.localization[i18n.language] || node.data.localization['en'] || node.data.directoryName || node.data.value;
            const nodePathKey = path.join(';');
            if (!Object.keys(tempNodes).includes(nodePathKey)) {
              tempNodes[nodePathKey] = {
                path: path,
                values: [],
                order: Object.keys(tempNodes).length,
              };
            }
            tempNodes[nodePathKey].values.push(nodeLabel);
          }
        }
      };

      directoryNode?.children?.forEach(node => {
        parseNode(node, [baseLabel]);
      });

      const groupedValues = sortBy(Object.values(tempNodes), 'order');
      const lines = groupedValues.map(group => `${group['path']?.join(' > ')} > ${group['values'].join(', ')}`);
      const pathLines = groupedValues.map(group => `${group['path']?.join(' > ')} >`);
      const splitLines = lines.map(line => doc.splitTextToSize(line, FIELD_VALUE_WIDTH - 20));
      const splitPathLines = pathLines.map(line => doc.splitTextToSize(line, FIELD_VALUE_WIDTH - 20));
      const allLinesHeight = splitLines.map(v => v.length).reduce((a, v) => a + v, 0) * 10 * (16 / 10) * 0.75 + splitLines.length * FIELD_GAP;
      const hintHeight = hint.length * 10 * (16 / 10) * 0.75 + FIELD_GAP;

      for (let i = 0; i < splitLines.length; i++) {
        const glossaryValuesGroupLines = splitLines[i];
        const valueLines = glossaryValuesGroupLines.length;
        const remainingSpace = PAPER_HEIGHT - FOOTER_HEIGHT - FOOTER_TOP_MARGIN - CARD_PADDING * 2 - currentPos;
        const remainingSpaceInLines = Math.floor(remainingSpace / 12);

        const pathLines = splitPathLines[i];
        const lineValueLines = glossaryValuesGroupLines.slice(pathLines.length - 1);
        lineValueLines[0] = lineValueLines[0].replace(pathLines[pathLines.length - 1], '');

        if (valueLines > remainingSpaceInLines) {
          await addNewPage(doc, true, currentPage === 1, t('taskPdf.details'), TaskDetailsIcon);
          doc.setFontSize(10);
        }

        doc.setTextColor(COLORS.TEXT_LIGHT_GREY);
        doc.text('•', PADDING_X + CARD_PADDING + FIELD_HINT_WIDTH + FIELD_HINT_VALUE_GAP + 10, currentPos + 10, { lineHeightFactor: 16 / 10 });
        doc.text(pathLines, PADDING_X + CARD_PADDING + FIELD_HINT_WIDTH + FIELD_HINT_VALUE_GAP + 20, currentPos + 10, { lineHeightFactor: 16 / 10 });
        const pathLinesHeight = (pathLines.length - 1) * 10 * (16 / 10) * 0.75;
        currentPos += pathLinesHeight;
        const lastPathLineWidth = doc.getTextWidth(pathLines[pathLines.length - 1]);
        doc.setTextColor(COLORS.BORDER_DARK_GREY);
        doc.text(lineValueLines[0], PADDING_X + CARD_PADDING + FIELD_HINT_WIDTH + FIELD_HINT_VALUE_GAP + 20 + lastPathLineWidth,
          currentPos + 10 + (pathLines.length > 1 ? 12 : 0), { lineHeightFactor: 16 / 10 });
        let linesPosDelta = 0;
        if (lineValueLines.length > 1) {
          linesPosDelta = 10 * (16 / 10) * 0.75;
          currentPos += linesPosDelta;
          doc.text(lineValueLines.splice(1), PADDING_X + CARD_PADDING + FIELD_HINT_WIDTH + FIELD_HINT_VALUE_GAP + 20, currentPos + 10, { lineHeightFactor: 16 / 10 });
        }
        const fieldValueHeight = glossaryValuesGroupLines.length * 10 * (16 / 10) * 0.75;
        currentPos += fieldValueHeight - pathLinesHeight - linesPosDelta + FIELD_GAP;
      }

      if (allLinesHeight < hintHeight) {
        currentPos += hintHeight - allLinesHeight;
      }
    } else {
      const valueLines = value.length;
      const remainingSpace = PAPER_HEIGHT - FOOTER_HEIGHT - FOOTER_TOP_MARGIN - CARD_PADDING * 2 - currentPos;
      const remainingSpaceInLines = Math.floor(remainingSpace / 12);

      if (valueLines <= remainingSpaceInLines) {
        doc.setTextColor(COLORS.BORDER_DARK_GREY);
        doc.text(value.map((v) => v.replace('\t', '')), PADDING_X + CARD_PADDING + FIELD_HINT_WIDTH + FIELD_HINT_VALUE_GAP, currentPos + 10, { lineHeightFactor: 16 / 10 });
        const maxNumberOfLines = Math.max(hint.length, value.length);
        const fieldValueHeight = maxNumberOfLines * 12;
        currentPos += fieldValueHeight + FIELD_GAP;

      } else {
        const linesInPage = Math.floor((PAPER_HEIGHT - PADDING_Y - FOOTER_HEIGHT - FOOTER_TOP_MARGIN - CARD_PADDING * 2) / 12);
        const valueDividedInGroups = [];
        valueDividedInGroups.push(value.slice(0, remainingSpaceInLines + 1));
        for (let i = remainingSpaceInLines + 1; i < value.length; i += linesInPage) {
          const chunk = value.slice(i, i + linesInPage);
          valueDividedInGroups.push(chunk);
        }

        let index = 0;
        for (const valueGroup of valueDividedInGroups) {
          index += 1;
          doc.setFontSize(10);
          doc.setFont(FONTS[400]);
          doc.setTextColor(COLORS.BORDER_DARK_GREY);
          await doc.text(valueGroup, PADDING_X + CARD_PADDING + FIELD_HINT_WIDTH + FIELD_HINT_VALUE_GAP, currentPos + 10, { lineHeightFactor: 16 / 10 });

          if (index < valueDividedInGroups.length) {
            await addNewPage(doc, true, currentPage === 1, t('taskPdf.details'), TaskDetailsIcon);
          } else {
            currentPos += valueGroup.length * 12 + FIELD_GAP;
          }
        }
      }
    }
  };

  const drawGroupName = async (doc, groupName) => {
    doc.setPage(currentPage);

    const splitGroupName = doc.splitTextToSize(groupName, PAPER_WIDTH - PADDING_X * 2 - CARD_PADDING * 2);
    const fieldValueHeight = splitGroupName.length * 10;

    if (currentPos + fieldValueHeight > PAPER_HEIGHT - FOOTER_HEIGHT - FOOTER_TOP_MARGIN - CARD_PADDING - FIELD_GAP - 10) {
      await addNewPage(doc, true, currentPage === 1, t('taskPdf.details'), TaskDetailsIcon);
    }

    doc.setFontSize(10);
    doc.setFont(FONTS[600]);
    doc.setTextColor(COLORS.TEXT_LIGHT_GREY);
    doc.text(splitGroupName, PADDING_X + CARD_PADDING, currentPos + splitGroupName.length * 10, { lineHeightFactor: 16 / 10 });

    currentPos += splitGroupName.length * 10 + FIELD_GAP;
  };

  const addNewPage = async (doc, shouldDrawCard = true, cardWithHeader = true, cardTitle = '', cardIcon = null) => {
    if (shouldDrawCard) {
      await drawCard({
        doc,
        startY: cardStartPos,
        height: PAPER_HEIGHT - cardStartPos - FOOTER_HEIGHT - FOOTER_TOP_MARGIN,
        hasHeader: cardWithHeader,
        headerIcon: cardIcon,
        headerText: cardTitle,
      });
    }
    // add new page
    await doc.addPage();
    currentPage += 1;
    currentPos = PADDING_Y + CARD_PADDING;
    cardStartPos = PADDING_Y;
  };

  const addTaskDetails = async (doc) => {
    cardStartPos = currentPos;
    currentPos += CARD_HEADER_HEIGHT + CARD_PADDING;

    let hasDisplayedFields = false;

    const filteredTaskGroups = {};
    Object.keys(bpmTask.currentStateGroups).forEach(groupKey => {
      if (!groupKey.includes('#hidden')) {
        if (bpmTask.currentStateGroups[groupKey].some(field => checkFieldVisibility(field))) {
          filteredTaskGroups[groupKey] = bpmTask.currentStateGroups[groupKey].filter(field => checkFieldVisibility(field));
        }
      }
    });
    const filteredTaskGroupsName = Object.keys(filteredTaskGroups);
    const unhiddenFilteredTaskGroupsName = filteredTaskGroupsName.filter((groupKey) => !groupKey.includes('#hidden'));
    for (const groupListKey of unhiddenFilteredTaskGroupsName) {
      hasDisplayedFields = true;
      const groupNameOldTranslationKey = `constructor-${bpmTask.processSysName}.states.${bpmTask.stepSysName}.fieldGroups.` + groupListKey.toLowerCase().replaceAll(' ', '-');
      const groupNameTranslationKey = `constructor-${bpmTask.processSysName}.fieldGroups.` + groupListKey.toLowerCase().replaceAll(' ', '-');
      await drawGroupName(doc, t(groupNameOldTranslationKey, { defaultValue: t(groupNameTranslationKey, { defaultValue: groupListKey }) }));

      for (const field of filteredTaskGroups[groupListKey]) {
        const hintTranslationKey = `constructor-${bpmTask.processSysName}.states.${bpmTask.stepSysName}.attributes.${field.sysName.replaceAll('::', '-')}.hint`;
        const hintTranslationNewFormatKey = `constructor-${bpmTask.processSysName}.attributes.${field.sysName.replaceAll('::', '-')}.hint`;
        const fieldHint = t(hintTranslationKey, { defaultValue: t(hintTranslationNewFormatKey, { defaultValue: field.hint }) });
        let fieldValue = bpmTask.values[field.name];

        // to get rid of blank space in details
        if (typeof fieldValue === 'string') {
          fieldValue = fieldValue.trim();
        }

        let fieldValueDataType = COMPONENTS_DATA_TYPES[field.component] || COMPONENTS_DATA_TYPES['default'];
        const componentParams = JSON.parse(field.componentParams || '{}')
        if (field.component === 'glossary' && componentParams.multiSelect) {
          fieldValueDataType = DATA_TYPES.GLOSSARY_MULTIPLE_VALUES;
        }
        const formattedFieldValue = formatFieldValue(fieldValue || '', field.component, JSON.parse(field.componentParams || '{}'), bpmTask.values, bpmTask, t, i18n, glossaryMap);

        const splitHint = doc.splitTextToSize(fieldHint, FIELD_HINT_WIDTH);
        const splitValue = doc.splitTextToSize(formattedFieldValue, FIELD_VALUE_WIDTH);

        if (currentPos + 32 > PAPER_HEIGHT - FOOTER_HEIGHT - FOOTER_TOP_MARGIN - CARD_PADDING) {
          await addNewPage(doc, true, currentPage === 1, t('taskPdf.details'), TaskDetailsIcon);
        }

        await drawFieldValue(
          doc,
          splitHint,
          fieldValue || '',
          [DATA_TYPES.USER, DATA_TYPES.USER_LIST, DATA_TYPES.GLOSSARY_MULTIPLE_VALUES].includes(fieldValueDataType)
          ? fieldValue
          : splitValue,
          fieldValueDataType,
          JSON.parse(field.componentParams || '{}'),
        );
      }
    }

    if (!hasDisplayedFields) {
      const emptyFieldsPlaceholderTitle = t('task_data_view.no_fields.title');
      const emptyFieldsPlaceholderMessage = t('task_data_view.no_fields.description');
      const splitEmptyFieldsPlaceholderTitle = doc.splitTextToSize(emptyFieldsPlaceholderTitle, DOCUMENT_NAME_WIDTH);
      const splitEmptyFieldsPlaceholderMessage = doc.splitTextToSize(emptyFieldsPlaceholderMessage, DOCUMENT_NAME_WIDTH);

      doc.setTextColor(COLORS.TEXT_LIGHT_GREY);
      doc.setFont(FONTS[600]);
      doc.setFontSize(10);
      doc.text(splitEmptyFieldsPlaceholderTitle, PADDING_X + CARD_PADDING, currentPos + 10, { lineHeightFactor: 16 / 10 });
      currentPos += splitEmptyFieldsPlaceholderTitle.length * 10 * (16 / 10) * 0.75 + 2;
      doc.setFont(FONTS[400]);
      doc.text(splitEmptyFieldsPlaceholderMessage, PADDING_X + CARD_PADDING, currentPos + 10, { lineHeightFactor: 16 / 10 });
      currentPos += splitEmptyFieldsPlaceholderMessage.length * 10 * (16 / 10) * 0.75 + 2;
    }

    await drawCard({
      doc,
      startY: cardStartPos,
      height: currentPos + CARD_PADDING - cardStartPos,
      hasHeader: currentPage === 1,
      headerIcon: TaskDetailsIcon,
      headerText: t('taskPdf.details'),
    });
  };

  const addTaskDocuments = async (doc, visibleDocuments) => {
    cardStartPos = currentPos;
    currentPos += CARD_HEADER_HEIGHT + CARD_PADDING;
    const startPage = currentPage;

    for (let i = 0; i < visibleDocuments.length; i++) {
      const document = visibleDocuments[i];

      if (i>0) {
        currentPos += 4;
      }

      const documentName = document.name;
      const leftPos = PADDING_X + CARD_PADDING + 7;

      // document name
      const splitDocumentName = doc.splitTextToSize(documentName, DOCUMENT_NAME_WIDTH);
      doc.setTextColor(COLORS.BORDER_DARK_GREY);
      doc.setFont(FONTS[400]);
      doc.setFontSize(11);
      doc.text('•', leftPos, currentPos + 11, { lineHeightFactor: 16 / 11 });
      doc.text(splitDocumentName, leftPos + 8, currentPos + 11, { lineHeightFactor: 16 / 11 });

      const lastDocumentNameLineWidth = doc.getTextWidth(splitDocumentName[splitDocumentName.length - 1]);

      // document link
      const documentUrl = DOCUMENT_LINK_TEMPLATE + document.docflowDocumentId;
      doc.setFont(FONTS[400]);
      doc.setFontSize(11);
      doc.setTextColor(COLORS.TEXT_LINK);
      doc.textWithLink(t('taskPdf.open'), leftPos + 8 + lastDocumentNameLineWidth + 4, currentPos + (splitDocumentName.length - 1) * 14 + 11, {
        lineHeightFactor: 16 / 11,
        url: documentUrl,
      });
      doc.setDrawColor(COLORS.TEXT_LINK);
      doc.setLineWidth(0.6);
      doc.line(leftPos + 8 + lastDocumentNameLineWidth + 4, currentPos + (splitDocumentName.length - 1) * 14 + 13, leftPos + 8 + lastDocumentNameLineWidth + 4 + doc.getTextWidth(t('taskPdf.open')), currentPos + (splitDocumentName.length - 1) * 14 + 13);
      currentPos += splitDocumentName.length * 11 + 4;

      const completedSignings = document.signings.filter(signing => signing.status || (signing.stepOrder === bpmTask.currentAction.stepperOrder && document.isSigned));

      for (const signing of completedSignings) {
        const user = await getUserById(users, signing.userId, bpmTask?.businessTask?.companyId);
        const userFullName = user?.fullName || t('task_data_view.employee_not_found');
        const isUSRegion = environment.region === 'US';
        const signDateFormat = isUSRegion ? 'MM/DD/YYYY' : 'DD.MM.YYYY';
        const formattedSignDate = moment(signing.signDate).format(signDateFormat);
        const signingText = `${t('taskPdf.documentsSignedBy')} ${userFullName} · ${t('taskPdf.documentsDigitalSignature')} · ${formattedSignDate}`;
        const splitSigningText = doc.splitTextToSize(signingText, DOCUMENT_NAME_WIDTH - 8);

        const signingHeight = splitSigningText.length * 10 * (16 / 10) * 0.75 + FIELD_GAP;
        if (currentPos + signingHeight > PAPER_HEIGHT - FOOTER_HEIGHT - FOOTER_TOP_MARGIN - CARD_PADDING) {
          await addNewPage(doc, true, currentPage === 1, t('taskPdf.documents'), TaskDocumentsIcon);
        }

        doc.setTextColor(COLORS.TEXT_LIGHT_GREY);
        doc.setFont(FONTS[400]);
        doc.setFontSize(10);
        doc.text(splitSigningText, leftPos + 8 + 4, currentPos + 11, { lineHeightFactor: 16 / 10 });
        currentPos += signingHeight;
      }

      if (currentPos + 32 > PAPER_HEIGHT - FOOTER_HEIGHT - FOOTER_TOP_MARGIN - CARD_PADDING) {
        await addNewPage(doc, true, currentPage === 1, t('taskPdf.documents'), TaskDocumentsIcon);
      }
    }

    await drawCard({
      doc,
      startY: cardStartPos,
      height: currentPos + CARD_PADDING - cardStartPos,
      hasHeader: currentPage === startPage,
      headerIcon: TaskDocumentsIcon,
      headerText: t('taskPdf.documents'),
    });
  };

  const drawUserAvatarPlaceholder = (doc, user, x, y) => {
    const userInitials = getInitials(user.fullName);
    doc.setFillColor(COLORS.USER_AVATAR_PLACEHOLDER_BACKGROUND);
    doc.ellipse(x, y, 8, 8, 'F');
    doc.setTextColor(COLORS.WHITE);
    doc.setFont(FONTS[500]);
    doc.setFontSize(8);
    const userInitialsWidth = doc.getTextWidth(userInitials);
    doc.text(userInitials, x - userInitialsWidth / 2, y + 2);
  };

  const drawUserData = async (doc, userId, x, y) => {
    const user = await getUserById(users, userId, bpmTask?.businessTask?.companyId);
    if (user) {
      if (user.avatar) {
        try {
          const imageData = await getImageFromUrlAsBase64(user.avatar);
          await doc.addImage(imageData, x, y, 16, 16);
          doc.setLineWidth(4);
          doc.setDrawColor(COLORS.WHITE);
          await doc.ellipse(x + 8, y + 8, 8 + 2, 8 + 2, 'S');
        } catch (error) {
          await drawUserAvatarPlaceholder(doc, user, x + 8, y + 8);
        }
      } else {
        await drawUserAvatarPlaceholder(doc, user, x + 8, y + 8);
      }

      doc.setFontSize(10);
      doc.setFont(FONTS[400]);
      doc.setTextColor(COLORS.BORDER_DARK_GREY);
      const maxNameWidth = PAPER_WIDTH - x - PADDING_X * 2 - 20;
      const splitFullName = doc.splitTextToSize(user.fullName, maxNameWidth);
      const displayedFullName = splitFullName.length === 1 ? user.fullName : splitFullName[0] + '...';
      await doc.text(displayedFullName, x + 16 + 4, y + 10, { lineHeightFactor: 16 / 10 });
    } else {
      await doc.text(t('task_data_view.employee_not_found'), x, currentPos + 10, { lineHeightFactor: 16 / 10 });
    }
  };

  const drawHistoryStep = async (
    doc,
    isCompletedProcess,
    stepData,
    isFirstStep = false,
    isLastStep = false,
    isFirstOnPage = false,
    addTimeline = true,
    leftMargin = 0,
    rightMargin = 0,
    isParallelBranchStep = false,
    drawParallelBranchesTimeline = true,
    drawParallelBranchesTimelineArrow = false,
    isFirstBranchStep = false,
  ) => {
    const lineStartPos = isFirstOnPage ? currentPos + 2 : currentPos + 6;
    const stepName = t(`constructor-${bpmTask.processSysName}.actions.${stepData.actionSysName}.name`, { defaultValue: stepData.taskName });

    doc.setPage(currentPage);

    const maxTextSize = PAPER_WIDTH - (PADDING_X + 36) * 2 - leftMargin - rightMargin;
    const splitStepName = doc.splitTextToSize(stepName, maxTextSize);

    const assigneesList = [...(stepData.assigneeList || []), ...(stepData.candidateUsers || [])];

    if (isParallelBranchStep) {
      let assigneesBlockHeight = 0;
      if (stepData.assignee === '${company}') {
        assigneesBlockHeight += 16 + 4;
      } else {
        if (stepData.parallel) {
          assigneesBlockHeight += 10 + 8;
        }
        for (const assigneeId of assigneesList) {
          assigneesBlockHeight += 16 + 4;
        }
      }

      const backgroundStartPos = currentPos - 2;
      const backgroundHeight = splitStepName.length * 10 + 8 // step name
        + assigneesBlockHeight // assignees
        + 10 + 12 // date and approve status
        + 4; // extra visual margin to prevent empty lines

      await drawParallelBranchesBackground(doc, backgroundStartPos, backgroundHeight, true, drawParallelBranchesTimeline);
      if (drawParallelBranchesTimelineArrow) {
        const outerCardLeftPos = PADDING_X + PARALLEL_BRANCHES_CARD_MARGIN_LEFT - PARALLEL_BRANCHES_CARD_PADDING + 4 - 1;
        if (isFirstBranchStep) {
          doc.setFillColor(COLORS.PARALLEL_BRANCHES_BACKGROUND);
          doc.setDrawColor(COLORS.FLOW_TIMELINE);
          await doc.rect(outerCardLeftPos + PARALLEL_BRANCHES_CARD_PADDING - 4, currentPos - 6, 8, 6 + 7, 'F');
        }
        const arrowLineStartPos = outerCardLeftPos + PARALLEL_BRANCHES_CARD_PADDING - 0.5;
        const arrowLineEndPos = outerCardLeftPos + PARALLEL_BRANCHES_CARD_PADDING + 16;
        const arrowLineVerticalPos = currentPos + 7;
        await doc.line(arrowLineStartPos, arrowLineVerticalPos, arrowLineEndPos, arrowLineVerticalPos);
        await doc.lines([[3, 3], [-3, 3]], arrowLineEndPos - 3, arrowLineVerticalPos - 3, [1, 1], 'S', false);
      }
    }

    // step name
    doc.setFontSize(10);
    doc.setFont(FONTS[500]);
    doc.setTextColor(COLORS.BORDER_DARK_GREY);
    await doc.text(splitStepName, PADDING_X + 36 + leftMargin, currentPos + 10, { lineHeightFactor: 16 / 10 });
    currentPos += splitStepName.length * 10 + 8;
    const lineIndicatorPosition = currentPos - 8 - ((splitStepName.length - 1) * 10) - 5 - 4;

    // assignee
    doc.setFont(FONTS[400]);
    // eslint-disable-next-line no-template-curly-in-string
    if (stepData.assignee === '${company}') {
      await drawCompanyLogo(doc, PADDING_X + 36 + leftMargin, currentPos, 16);
      doc.setFontSize(10);
      doc.setFont(FONTS[400]);
      doc.setTextColor(COLORS.BORDER_DARK_GREY);
      await doc.text(companyName, PADDING_X + 36 + leftMargin + 16 + 4, currentPos + 10, { lineHeightFactor: 16 / 10 });
      currentPos += 16 + 4;
    } else {
      if (stepData.parallel) {
        doc.setFontSize(10);
        doc.setFont(FONTS[400]);
        doc.setTextColor(COLORS.TEXT_LIGHT_GREY);
        await doc.text(`${t('task_history.parallelAssigneesLabel')} (${stepData.assigneeList.length})`, PADDING_X + 36 + leftMargin, currentPos + 7, { lineHeightFactor: 16 / 10 });
        currentPos += 10 + 8;
      }
      if (stepData?.candidateUsers?.length > 0) {
        doc.setFontSize(10);
        doc.setFont(FONTS[400]);
        doc.setTextColor(COLORS.TEXT_LIGHT_GREY);
        await doc.text(t('task_history.possible_assignees'), PADDING_X + 36 + leftMargin, currentPos + 7, { lineHeightFactor: 16 / 10 });
        currentPos += 10 + 8;
      }
      for (const assigneeId of assigneesList) {
        await drawUserData(doc, assigneeId, PADDING_X + 36 + leftMargin, currentPos);
        currentPos += 16 + 4;
      }
    }

    // date and approve status
    doc.setTextColor(COLORS.TEXT_LIGHT_GREY);
    if (stepData.isCurrentStep && isLastStep) {
      await doc.text(t('task_history.waitingApproval'), PADDING_X + 36 + leftMargin, currentPos + 10, { lineHeightFactor: 16 / 10 });
    } else {
      await doc.text(`${stepData.displayApproveStage} ${stepData.completionDateString}`, PADDING_X + 36 + leftMargin, currentPos + 10, { lineHeightFactor: 16 / 10 });
    }
    currentPos += 10 + 12;

    // timeline and indicator
    if (addTimeline) {
      if (!isLastStep) {
        doc.setLineWidth(1);
        doc.setDrawColor(COLORS.FLOW_TIMELINE);
        await doc.line(PADDING_X + 18 + leftMargin, lineStartPos, PADDING_X + 18 + leftMargin, currentPos);
      }
      const blockHeight = 12;
      doc.setFillColor(COLORS.WHITE);
      await doc.rect(PADDING_X + 12 + leftMargin, lineIndicatorPosition, 12, blockHeight, 'F');
      await doc.addImage(
        ((isFirstStep && isCompletedProcess) || isLastStep) ? FlowCircle : FlowArrow,
        'PNG',
        PADDING_X + CARD_PADDING + leftMargin,
        lineIndicatorPosition,
        12,
        12,
      );
    }
  };

  const drawHistoryFutureStepsPlaceholder = async (doc, isFirstOnPage, leftMargin = 0, addBranchesBackground = false, drawBranchesTimeline = true) => {
    const lineStartPos = isFirstOnPage ? currentPos + 2 : currentPos + 6;

    const label = t('taskPdf.futureStepsPlaceholderLabel');
    const message = t('taskPdf.futureStepsPlaceholderMessage');
    const maxTextSize = PAPER_WIDTH - (PADDING_X + 36) * 2 - leftMargin;
    const splitLabel = doc.splitTextToSize(label, maxTextSize);
    const splitMessage = doc.splitTextToSize(message, maxTextSize);

    doc.setPage(currentPage);

    if (addBranchesBackground) {
      const placeholderHeight = splitLabel.length * 10 + 8 + splitMessage.length * 10 + 12;
      await drawParallelBranchesBackground(doc, currentPos, placeholderHeight, true, drawBranchesTimeline, false);
    }

    doc.setFontSize(10);
    doc.setFont(FONTS[500]);
    doc.setTextColor(COLORS.BORDER_DARK_GREY);
    await doc.text(label, PADDING_X + 36 + leftMargin, currentPos + 10, { lineHeightFactor: 16 / 10 });
    currentPos += splitLabel.length * 10 + 8;
    const lineIndicatorPosition = currentPos - 8 - ((splitLabel.length - 1) * 10) - 5 - 4;

    doc.setFont(FONTS[400]);
    doc.setTextColor(COLORS.TEXT_LIGHT_GREY);
    await doc.text(message, PADDING_X + 36 + leftMargin, currentPos + 10, { lineHeightFactor: 16 / 10 });
    currentPos += splitMessage.length * 10 + 12;

    // timeline and indicator
    doc.setLineWidth(1);
    doc.setDrawColor(COLORS.FLOW_TIMELINE);
    doc.setLineDash([7, 7]);
    await doc.line(PADDING_X + 18 + leftMargin, lineStartPos + 8, PADDING_X + 18 + leftMargin, currentPos);
    const blockHeight = 12;
    doc.setFillColor(COLORS.WHITE);
    await doc.rect(PADDING_X + 12 + leftMargin, lineIndicatorPosition, 12, blockHeight, 'F');
    await doc.addImage(
      FlowArrow,
      'PNG',
      PADDING_X + CARD_PADDING + leftMargin,
      lineIndicatorPosition,
      12,
      12,
    );
    doc.setLineDash([]);
  };

  const drawHistoryParallelAssigneesStep = async (doc, parallelAssigneesStep, isFirstStep = false, isLastStep = false, isFirstOnPage = false, addTimeline = true, leftMargin = 0, rightMargin = 0) => {
    const lineStartPos = isFirstOnPage ? currentPos : currentPos + 6;
    const stepData = parallelAssigneesStep?.assigneeSteps[0]?.data as StepType;
    const stepName = t(`constructor-${bpmTask.processSysName}.actions.${stepData.actionSysName}.name`, { defaultValue: stepData.taskName });

    doc.setPage(currentPage);

    // step name
    const maxTextSize = PAPER_WIDTH - (PADDING_X + 36) * 2 - leftMargin - rightMargin;
    const splitStepName = doc.splitTextToSize(stepName, maxTextSize);
    doc.setFontSize(10);
    doc.setFont(FONTS[500]);
    doc.setTextColor(COLORS.BORDER_DARK_GREY);
    await doc.text(splitStepName, PADDING_X + 36 + leftMargin, currentPos + 10, { lineHeightFactor: 16 / 10 });
    currentPos += splitStepName.length * 10 + 8;
    const lineIndicatorPosition = currentPos - 8 - ((splitStepName.length - 1) * 10) - 5 - 4;

    // assignee
    doc.setFont(FONTS[400]);
    // eslint-disable-next-line no-template-curly-in-string
    doc.setFontSize(10);
    doc.setFont(FONTS[400]);
    doc.setTextColor(COLORS.TEXT_LIGHT_GREY);
    await doc.text(`${t('task_history.parallelAssigneesLabel')} (${parallelAssigneesStep.assigneeSteps.length})`, PADDING_X + 36 + leftMargin, currentPos + 7, { lineHeightFactor: 16 / 10 });
    currentPos += 10 + 8;

    for (const assigneeStep of parallelAssigneesStep?.assigneeSteps) {
      // assignee
      await drawUserData(doc, assigneeStep?.data?.assignee, PADDING_X + 36 + leftMargin, currentPos);
      currentPos += 16 + 4;

      // date and approve status
      if (assigneeStep.data.isCurrentStep) {
        doc.setTextColor(COLORS.TEXT_LIGHT_GREY);
        await doc.text(t('task_history.waitingApproval'), PADDING_X + 36 + leftMargin, currentPos + 10, { lineHeightFactor: 16 / 10 });
        currentPos += 10 + 12;
      } else {
        doc.setTextColor(COLORS.TEXT_LIGHT_GREY);
        await doc.text(`${assigneeStep?.data?.displayApproveStage} ${assigneeStep?.data?.completionDateString}`, PADDING_X + 36 + leftMargin, currentPos + 10, { lineHeightFactor: 16 / 10 });
        currentPos += 10 + 12;
      }
    }

    // timeline and indicator
    if (addTimeline) {
      if (!isLastStep) {
        doc.setLineWidth(1);
        doc.setDrawColor(COLORS.FLOW_TIMELINE);
        await doc.line(PADDING_X + 18 + leftMargin, lineStartPos, PADDING_X + 18 + leftMargin, currentPos);
      }
      const blockHeight = 12;
      doc.setFillColor(COLORS.WHITE);
      await doc.rect(PADDING_X + 12 + leftMargin, lineIndicatorPosition, 12, blockHeight, 'F');
      await doc.addImage(
        (isFirstStep || isLastStep) ? FlowCircle : FlowArrow,
        'PNG',
        PADDING_X + CARD_PADDING + leftMargin,
        lineIndicatorPosition,
        12,
        12,
      );
    }
  };

  const drawHistoryCondition = async (doc, condition, processSteps, isFirstOnPage = false) => {
    const lineStartPos = isFirstOnPage ? currentPos : currentPos + 6;
    const conditionName = t(`constructor-${bpmTask.processSysName}.scripts.${condition.stepperOrder}.name`, {
      defaultValue:
        t(`constructor-${bpmTask.processSysName}.scripts.${condition.stepperOrder + 1}.name`, { defaultValue: condition?.scriptName }),
    });
    doc.setPage(currentPage);
    const conditionTitle = `${t('task_data_view.history_tab.conditionNamePrefix')}: ${conditionName}`;

    let cardHeight = HISTORY_CONDITION_CARD_PADDING * 2;
    const conditionCardWidth = CARD_WIDTH - 18 - 9 - 12;

    // script name
    const splitConditionTitle = doc.splitTextToSize(conditionTitle, conditionCardWidth);
    doc.setFontSize(10);
    doc.setFont(FONTS[500]);
    doc.setTextColor(COLORS.BORDER_DARK_GREY);
    await doc.text(splitConditionTitle, PADDING_X + 36, currentPos + 10 + 5, { lineHeightFactor: 16 / 10 });
    currentPos += splitConditionTitle.length * 10 + 8;
    const lineIndicatorPosition = currentPos - 8 - ((splitConditionTitle.length - 1) * 10) - 5 - 4;
    cardHeight += splitConditionTitle.length * 10;

    // script condition and state
    const conditionTemplateKey = CONDITION_TYPES[condition.fieldType]?.find(item => item.key === condition?.condition)?.templatePdf || '';
    const allParameters = bpmTask.states.reduce((acc, v) => [...acc, ...(v.bsnParameters || [])], []);
    const fieldAttribute = bpmTask?.attributes?.find(attribute => attribute?.name === condition?.field);
    const stepAttribute = allParameters?.find(attr => attr?.attributeId === fieldAttribute?.typeAttributeId);
    const attributeHint = stepAttribute?.hint || fieldAttribute?.hint;
    let conditionFieldName = 'FIELD';
    if (fieldAttribute) {
      const hintTranslationKey = `constructor-${bpmTask.processSysName}.states.${condition.actionSysName || bpmTask.currentAction.sysName}.attributes.${fieldAttribute.sysName.replaceAll('::', '-')}.hint`;
      const hintTranslationNewFormatKey = `constructor-${bpmTask.processSysName}.attributes.${condition.actionSysName || fieldAttribute.sysName.replaceAll('::', '-')}.hint`;
      conditionFieldName = t(hintTranslationKey, { defaultValue: t(hintTranslationNewFormatKey, { defaultValue: attributeHint }) });
    }

    let conditionValueFormatted = condition.value;
    try {
      const allParameters = bpmTask.states.reduce((acc, v) => [...acc, ...(v.bsnParameters || [])], []);
      const fieldAttribute = bpmTask?.attributes?.find(attribute => attribute?.name === condition?.field);
      const stepAttribute = allParameters?.find(attr => attr?.attributeId === fieldAttribute?.typeAttributeId);

      conditionValueFormatted = await formatConditionValue({
        fieldType: condition.fieldType,
        value: condition.value,
        fieldParams: JSON.parse(stepAttribute?.componentParams || '{}') || {}
      });
    } catch (error) {
      //
    }

    const conditionDescription = t(conditionTemplateKey, { field: conditionFieldName, value: conditionValueFormatted });
    const conditionDescriptionWithArrow = `${conditionDescription} ->`;
    const conditionResultText = condition?.actualResult?.[0] === condition?.positiveStep?.[0]
                                ? t('task_data_view.history_tab.conditionToggle.yes')
                                : t('task_data_view.history_tab.conditionToggle.no');
    const conditionResultTextWidth = doc.getTextWidth(conditionResultText);
    const splitConditionDescriptionWithArrow = doc.splitTextToSize(conditionDescriptionWithArrow, conditionCardWidth - conditionResultTextWidth - 4);
    const arrowLineWidth = doc.getTextWidth(splitConditionDescriptionWithArrow[splitConditionDescriptionWithArrow.length - 1]);
    doc.setFontSize(10);
    doc.setFont(FONTS[400]);
    doc.setTextColor(COLORS.BORDER_DARK_GREY);
    await doc.text(splitConditionDescriptionWithArrow, PADDING_X + 36, currentPos + splitConditionDescriptionWithArrow.length * 10, { lineHeightFactor: 16 / 10 });
    currentPos += splitConditionDescriptionWithArrow.length * 10 + 8;
    doc.setFont(FONTS[500]);
    await doc.text(conditionResultText, PADDING_X + 36 + arrowLineWidth, currentPos - 8, { lineHeightFactor: 16 / 10 });
    cardHeight += splitConditionDescriptionWithArrow.length * 10;

    // condition card border
    doc.setDrawColor(COLORS.BORDER_LIGHT_GREY);
    doc.setLineWidth(1);
    doc.roundedRect(PADDING_X + 18 + 9, lineIndicatorPosition, conditionCardWidth, cardHeight, CARD_BORDER_RADIUS, CARD_BORDER_RADIUS, 'S');

    currentPos += HISTORY_CONDITION_CARD_PADDING * 1.5;

    // timeline and indicator
    doc.setLineWidth(1);
    doc.setDrawColor(COLORS.FLOW_TIMELINE);
    await doc.line(PADDING_X + 18, lineStartPos, PADDING_X + 18, currentPos);
    const blockHeight = 12;
    doc.setFillColor(COLORS.WHITE);
    await doc.rect(PADDING_X + 12, lineIndicatorPosition, 12, blockHeight, 'F');
    await doc.addImage(
      FlowConditionIcon,
      'PNG',
      PADDING_X + CARD_PADDING,
      lineIndicatorPosition,
      12,
      12,
    );
  };

  const drawParallelBranchesBackground = async (doc, startPos, height, hasBranchCard, hasBranchesTimeline = true, hasBranchInnerTimeline = false) => {
    const parallelStepsCardWidth = CARD_WIDTH - PARALLEL_BRANCHES_CARD_MARGIN_LEFT - PARALLEL_BRANCHES_CARD_MARGIN_RIGHT + PARALLEL_BRANCHES_CARD_PADDING - 4;
    const outerCardLeftPos = PADDING_X + PARALLEL_BRANCHES_CARD_MARGIN_LEFT - PARALLEL_BRANCHES_CARD_PADDING + 4 - 1;
    doc.setDrawColor(COLORS.BORDER_LIGHT_GREY);
    doc.setFillColor(COLORS.PARALLEL_BRANCHES_BACKGROUND);
    doc.setLineWidth(1);
    await doc.rect(outerCardLeftPos, startPos, parallelStepsCardWidth + 2, height, 'FD');
    await doc.rect(outerCardLeftPos + 0.5, startPos - 1, parallelStepsCardWidth + 1, height + 2, 'F');

    doc.setLineWidth(1);
    doc.setDrawColor(COLORS.FLOW_TIMELINE);
    await doc.line(PADDING_X + 18, startPos - 1, PADDING_X + 18, startPos + height + 1);

    if (hasBranchCard) {
      const branchCardLeftPos = outerCardLeftPos + PARALLEL_BRANCHES_CARD_MARGIN_LEFT;
      const branchCardWidth = parallelStepsCardWidth - PARALLEL_BRANCHES_CARD_MARGIN_LEFT - PARALLEL_BRANCHES_CARD_PADDING;
      doc.setDrawColor(COLORS.BORDER_LIGHT_GREY);
      doc.setFillColor(COLORS.WHITE);
      doc.setLineWidth(1);
      await doc.rect(branchCardLeftPos, startPos - 1, branchCardWidth, height + 2, 'FD');
      await doc.rect(branchCardLeftPos + 0.5, startPos - 2, branchCardWidth - 1, height + 4, 'F');
    }

    if (hasBranchesTimeline) {
      doc.setLineWidth(1);
      doc.setDrawColor(COLORS.FLOW_TIMELINE);
      await doc.line(outerCardLeftPos + PARALLEL_BRANCHES_CARD_PADDING, startPos - 1.5, outerCardLeftPos + PARALLEL_BRANCHES_CARD_PADDING, startPos + height + 1);
    }

    if (hasBranchInnerTimeline) {
      doc.setLineWidth(1);
      doc.setDrawColor(COLORS.FLOW_TIMELINE);
      const innerTimelineLeftPos = outerCardLeftPos + PARALLEL_BRANCHES_CARD_MARGIN_LEFT + PARALLEL_BRANCHES_CARD_PADDING * 2 - 5;
      await doc.line(innerTimelineLeftPos, startPos - 10, innerTimelineLeftPos, startPos + height + 1);
    }
  };

  const drawParallelBranchesCardStart = async (doc, startPos, height, hasBranchesTimeline = true) => {
    const parallelStepsCardWidth = CARD_WIDTH - PARALLEL_BRANCHES_CARD_MARGIN_LEFT - PARALLEL_BRANCHES_CARD_MARGIN_RIGHT + PARALLEL_BRANCHES_CARD_PADDING - 4;
    const outerCardLeftPos = PADDING_X + PARALLEL_BRANCHES_CARD_MARGIN_LEFT - PARALLEL_BRANCHES_CARD_PADDING + 4 - 1;
    doc.setDrawColor(COLORS.BORDER_LIGHT_GREY);
    doc.setFillColor(COLORS.PARALLEL_BRANCHES_BACKGROUND);
    doc.setLineWidth(1);
    await doc.rect(outerCardLeftPos, startPos, parallelStepsCardWidth + 2, height, 'FD');
    await doc.rect(outerCardLeftPos + 0.5, startPos - 1, parallelStepsCardWidth + 1, height + 2, 'F');

    const branchCardLeftPos = outerCardLeftPos + PARALLEL_BRANCHES_CARD_MARGIN_LEFT;
    const branchCardWidth = parallelStepsCardWidth - PARALLEL_BRANCHES_CARD_MARGIN_LEFT - PARALLEL_BRANCHES_CARD_PADDING;

    const delta = 0.55 * CARD_BORDER_RADIUS; // delta for cubic bezier curves control points calculations
    const x1 = branchCardLeftPos;
    const y1 = currentPos + height;
    // coordinates below are relative to corresponding previous points (2 -> 1, 3 -> 2, 4 -> 4)
    const x2 = CARD_BORDER_RADIUS;
    const y2 = -CARD_BORDER_RADIUS;
    const x3 = branchCardWidth - CARD_BORDER_RADIUS * 2;
    const y3 = 0;
    const x4 = CARD_BORDER_RADIUS;
    const y4 = CARD_BORDER_RADIUS;
    doc.setDrawColor(COLORS.BORDER_LIGHT_GREY);
    doc.setFillColor(COLORS.WHITE);
    // rounded part - background
    doc.lines([
      [0, -4],
      [0, -delta, delta, -CARD_BORDER_RADIUS, x2, y2],
      [x3, y3],
      [delta, 0, x4, (CARD_BORDER_RADIUS - delta), x4, y4],
      [0, 4],
    ], x1, y1, [1, 1], 'F', true);
    // rounded part border
    doc.lines([
      [0, -4],
      [0, -delta, delta, -CARD_BORDER_RADIUS, x2, y2],
      [x3, y3],
      [delta, 0, x4, (CARD_BORDER_RADIUS - delta), x4, y4],
      [0, 4],
    ], x1, y1, [1, 1], 'S', false);

    // main timeline
    doc.setLineWidth(1);
    doc.setDrawColor(COLORS.FLOW_TIMELINE);
    await doc.line(PADDING_X + 18, startPos - 1, PADDING_X + 18, startPos + height + 1);

    // branches cards timeline
    if (hasBranchesTimeline) {
      doc.setLineWidth(1);
      doc.setDrawColor(COLORS.FLOW_TIMELINE);
      await doc.line(outerCardLeftPos + PARALLEL_BRANCHES_CARD_PADDING, startPos - 3, outerCardLeftPos + PARALLEL_BRANCHES_CARD_PADDING, startPos + height + 6);
    }
  };

  const drawParallelBranchesCardEnd = async (doc, startPos, height, hasBranchesTimeline = true) => {
    const parallelStepsCardWidth = CARD_WIDTH - PARALLEL_BRANCHES_CARD_MARGIN_LEFT - PARALLEL_BRANCHES_CARD_MARGIN_RIGHT + PARALLEL_BRANCHES_CARD_PADDING - 4;
    const outerCardLeftPos = PADDING_X + PARALLEL_BRANCHES_CARD_MARGIN_LEFT - PARALLEL_BRANCHES_CARD_PADDING + 4 - 1;
    doc.setDrawColor(COLORS.BORDER_LIGHT_GREY);
    doc.setFillColor(COLORS.PARALLEL_BRANCHES_BACKGROUND);
    doc.setLineWidth(1);
    await doc.rect(outerCardLeftPos, startPos, parallelStepsCardWidth + 2, height, 'FD');
    await doc.rect(outerCardLeftPos + 0.5, startPos - 1, parallelStepsCardWidth + 1, height + 2, 'F');

    const branchCardLeftPos = outerCardLeftPos + PARALLEL_BRANCHES_CARD_MARGIN_LEFT;
    const branchCardWidth = parallelStepsCardWidth - PARALLEL_BRANCHES_CARD_MARGIN_LEFT - PARALLEL_BRANCHES_CARD_PADDING;

    const delta = 0.55 * CARD_BORDER_RADIUS; // delta for cubic bezier curves control points calculations
    const x1 = branchCardLeftPos;
    const y1 = currentPos - 2;
    // coordinates below are relative to corresponding previous points (2 -> 1, 3 -> 2, 4 -> 4)
    const x2 = CARD_BORDER_RADIUS;
    const y2 = CARD_BORDER_RADIUS;
    const x3 = branchCardWidth - CARD_BORDER_RADIUS * 2;
    const y3 = 0;
    const x4 = CARD_BORDER_RADIUS;
    const y4 = -CARD_BORDER_RADIUS;
    doc.setDrawColor(COLORS.BORDER_LIGHT_GREY);
    doc.setFillColor(COLORS.WHITE);
    // rounded part - background
    doc.lines([
      [0, 4],
      [0, delta, delta, CARD_BORDER_RADIUS, x2, y2],
      [x3, y3],
      [delta, 0, x4, -(CARD_BORDER_RADIUS - delta), x4, y4],
      [0, -4],
    ], x1, y1, [1, 1], 'F', true);
    // rounded part border
    doc.lines([
      [0, 4],
      [0, delta, delta, CARD_BORDER_RADIUS, x2, y2],
      [x3, y3],
      [delta, 0, x4, -(CARD_BORDER_RADIUS - delta), x4, y4],
      [0, -4],
    ], x1, y1, [1, 1], 'S', false);

    // main timeline
    doc.setLineWidth(1);
    doc.setDrawColor(COLORS.FLOW_TIMELINE);
    await doc.line(PADDING_X + 18, startPos - 1, PADDING_X + 18, startPos + height + 1);

    // branches cards timeline
    if (hasBranchesTimeline) {
      doc.setLineWidth(1);
      doc.setDrawColor(COLORS.FLOW_TIMELINE);
      await doc.line(outerCardLeftPos + PARALLEL_BRANCHES_CARD_PADDING, startPos - 3, outerCardLeftPos + PARALLEL_BRANCHES_CARD_PADDING, startPos + height + 6);
    }
  };

  const drawParallelBranchesOuterCardEnd = async (doc, startPos, height, hasBranchesTimeline = true) => {
    const parallelStepsCardWidth = CARD_WIDTH - PARALLEL_BRANCHES_CARD_MARGIN_LEFT - PARALLEL_BRANCHES_CARD_MARGIN_RIGHT + PARALLEL_BRANCHES_CARD_PADDING;
    const outerCardLeftPos = PADDING_X + PARALLEL_BRANCHES_CARD_MARGIN_LEFT - PARALLEL_BRANCHES_CARD_PADDING + 4 - 1;

    const delta = 0.55 * CARD_BORDER_RADIUS; // delta for cubic bezier curves control points calculations
    const x1 = outerCardLeftPos;
    const y1 = currentPos - 2;
    // coordinates below are relative to corresponding previous points (2 -> 1, 3 -> 2, 4 -> 4)
    const x2 = CARD_BORDER_RADIUS;
    const y2 = CARD_BORDER_RADIUS;
    const x3 = parallelStepsCardWidth - CARD_BORDER_RADIUS * 2 - 2;
    const y3 = 0;
    const x4 = CARD_BORDER_RADIUS;
    const y4 = -CARD_BORDER_RADIUS;
    doc.setDrawColor(COLORS.BORDER_LIGHT_GREY);
    doc.setFillColor(COLORS.PARALLEL_BRANCHES_BACKGROUND);
    // rounded part - background
    doc.lines([
      [0, 1],
      [0, delta, delta, CARD_BORDER_RADIUS, x2, y2],
      [x3, y3],
      [delta, 0, x4, -(CARD_BORDER_RADIUS - delta), x4, y4],
      [0, -1],
    ], x1, y1, [1, 1], 'F', true);
    // rounded part border
    doc.lines([
      [0, 1],
      [0, delta, delta, CARD_BORDER_RADIUS, x2, y2],
      [x3, y3],
      [delta, 0, x4, -(CARD_BORDER_RADIUS - delta), x4, y4],
      [0, -1],
    ], x1, y1, [1, 1], 'S', false);

    // main timeline
    doc.setLineWidth(1);
    doc.setDrawColor(COLORS.FLOW_TIMELINE);
    await doc.line(PADDING_X + 18, startPos - 1, PADDING_X + 18, startPos + height + 1);
  };

  const drawHistoryParallelBranches = async (doc, isCompletedProcess, parallelBranches, processSteps, startPage, isFirstOnPage = false, isLastInFlow = false) => {
    const lineStartPos = currentPos;
    const lineIndicatorPosition = lineStartPos + 6;
    const stepsGroupTitle = t(`constructor-${bpmTask.processSysName}.parallelBranches.${parallelBranches?.stepperOrder}.name`, { defaultValue: t(`constructor-${bpmTask.processSysName}.parallelBranches.${parallelBranches.stepperOrder + 1}.name`, { defaultValue: parallelBranches?.parallelBranchName }) });
    doc.setPage(currentPage);

    const conditionCardWidth = CARD_WIDTH - 18 - 9 - 12 - 8;

    await drawParallelBranchesBackground(doc, currentPos + 20, 40, false, false);

    // main timeline and indicator
    if (!isLastInFlow) {
      doc.setLineWidth(1);
      doc.setDrawColor(COLORS.FLOW_TIMELINE);
      await doc.line(PADDING_X + 18, lineStartPos, PADDING_X + 18, lineStartPos + 20);
    }
    const blockHeight = 12;
    doc.setFillColor(COLORS.WHITE);
    await doc.rect(PADDING_X + 12, lineIndicatorPosition, 12, blockHeight, 'F');
    await doc.addImage(
      (isLastInFlow && isCompletedProcess) ? FlowCircle : FlowArrow,
      'PNG',
      PADDING_X + CARD_PADDING,
      lineIndicatorPosition,
      12,
      12,
    );

    // steps group name and branches count
    const splitStepsGroupTitle = doc.splitTextToSize(stepsGroupTitle, conditionCardWidth);
    const branchesCountText = `${t('customProcesses.creationPage.parallelStepsGroupForm.branches', { defaultValue: 'Branches' })}: ${parallelBranches.branches.length}`;

    const parallelStepsCardWidth = CARD_WIDTH - PARALLEL_BRANCHES_CARD_MARGIN_LEFT - PARALLEL_BRANCHES_CARD_MARGIN_RIGHT + PARALLEL_BRANCHES_CARD_PADDING - 2;
    const parallelStepsCardStartPos = lineStartPos;
    const parallelStepsCardHeaderHeight = splitStepsGroupTitle.length * 10 + 10 + 4 + 8 + 4;

    doc.setDrawColor(COLORS.BORDER_LIGHT_GREY);
    doc.setFillColor(COLORS.PARALLEL_BRANCHES_BACKGROUND);
    doc.setLineWidth(1);
    await doc.roundedRect(PADDING_X + PARALLEL_BRANCHES_CARD_MARGIN_LEFT - PARALLEL_BRANCHES_CARD_PADDING + 3, parallelStepsCardStartPos, parallelStepsCardWidth, parallelStepsCardHeaderHeight, CARD_BORDER_RADIUS, CARD_BORDER_RADIUS, 'FD');
    await doc.rect(PADDING_X + PARALLEL_BRANCHES_CARD_MARGIN_LEFT - PARALLEL_BRANCHES_CARD_PADDING + 4 - 1, parallelStepsCardStartPos + CARD_BORDER_RADIUS, parallelStepsCardWidth, parallelStepsCardHeaderHeight - CARD_BORDER_RADIUS, 'FD');
    await doc.roundedRect(PADDING_X + PARALLEL_BRANCHES_CARD_MARGIN_LEFT - PARALLEL_BRANCHES_CARD_PADDING + 3.5, parallelStepsCardStartPos + 0.5, parallelStepsCardWidth - 1, parallelStepsCardHeaderHeight - 10, CARD_BORDER_RADIUS, CARD_BORDER_RADIUS, 'F');

    doc.setFontSize(10);
    doc.setFont(FONTS[500]);
    doc.setTextColor(COLORS.BORDER_DARK_GREY);
    await doc.text(splitStepsGroupTitle, PADDING_X + PARALLEL_BRANCHES_CARD_MARGIN_LEFT + 4, currentPos + 10 + 5, { lineHeightFactor: 16 / 10 });
    currentPos += splitStepsGroupTitle.length * 10 + 4;

    doc.setFontSize(10);
    doc.setFont(FONTS[400]);
    doc.setTextColor(COLORS.BORDER_DARK_GREY);
    await doc.text(branchesCountText, PADDING_X + PARALLEL_BRANCHES_CARD_MARGIN_LEFT + 4, currentPos + 10 + 5, { lineHeightFactor: 16 / 10 });
    currentPos += 10 + 8 + 10;

    await drawParallelBranchesCardStart(doc, currentPos, 20, false);
    currentPos += 18;
    await drawParallelBranchesBackground(doc, currentPos, 4, true, false);
    currentPos += 2;

    let branchesStepsInDisplayOrder = [];
    const branchesWithFutureSteps = [];
    for (let i = 0; i < parallelBranches.branches.length; i++) {
      const branch = parallelBranches.branches[i];
      const filteredBranchSteps = branch
        .filter(step => !step?.data?.isFutureStep)
        .map((step, stepIndex, branchSteps) => ({
          ...step,
          branchIndex: i,
          isFirstBranch: i === 0,
          isFirstStep: stepIndex === branchSteps.length - 1,
          isLastPassedStep: stepIndex === 0,
          isBranchWithFutureSteps: branch.some(step => step?.data?.isFutureStep) && !branch.some(branchStep => branchStep.data.approveStage === '[Canceled]' || branchStep.data.approveStage === '[Cancel]' ),
        }));
      if (branch.length > filteredBranchSteps.length && !branch.some(branchStep => branchStep.data.approveStage === '[Canceled]' || branchStep.data.approveStage === '[Cancel]' )) {
        branchesWithFutureSteps.push(i);
      }
      branchesStepsInDisplayOrder = [...branchesStepsInDisplayOrder, ...filteredBranchSteps];
    }

    for (let i = 0; i < branchesStepsInDisplayOrder.length; i++) {
      const branchStep = branchesStepsInDisplayOrder[i];
      if (i > 0) {
        const previousBranchStep = branchesStepsInDisplayOrder[i - 1];
        const hasBranchChanged = previousBranchStep.branchIndex !== branchStep.branchIndex;
        const processStep = branchStep?.data as StructuredHistoryStep;
        const stepName = t(`constructor-${bpmTask.processSysName}.actions.${processStep.actionSysName}.name`, { defaultValue: processStep.taskName });
        const splitStepName = doc.splitTextToSize(stepName, PAPER_WIDTH - (PADDING_X + 36) * 2 - CARD_PADDING + PARALLEL_BRANCHES_CARD_PADDING * 3 + PARALLEL_BRANCHES_CARD_MARGIN_LEFT);
        const historyItemValue = splitStepName?.length * 10 + 8 + processStep?.assigneeList?.length * 20 + 10;
        const maxCurrentPosOnPage = PAPER_HEIGHT - FOOTER_HEIGHT - FOOTER_TOP_MARGIN - CARD_PADDING;
        if (hasBranchChanged) {
          await drawParallelBranchesBackground(doc, currentPos, 40, false);
          await drawParallelBranchesCardEnd(doc, currentPos, 14);
          currentPos += 12 + 8;
        }
        const currentStepNeededSpace = currentPos + historyItemValue + (branchStep.isFirstStep ? 40 : 10);
        if (currentStepNeededSpace > maxCurrentPosOnPage) {
          const isBranchContinued = !hasBranchChanged;
          await drawParallelBranchesBackground(doc, currentPos, maxCurrentPosOnPage - currentPos + 11, isBranchContinued, branchStep.branchIndex > 0, isBranchContinued);
          await addNewPage(doc, true, startPage === currentPage, t('taskPdf.flow'), TaskFlowIcon);
          await drawParallelBranchesBackground(doc, PADDING_Y + 1, 20, isBranchContinued, branchStep.branchIndex > 0);
        }
        if (hasBranchChanged) {
          await drawParallelBranchesCardStart(doc, currentPos, 20);
          currentPos += 18 + 4;
        }
        const shouldAddFutureStepsPlaceholder = branchStep.isLastPassedStep && branchStep.isBranchWithFutureSteps;
        if (shouldAddFutureStepsPlaceholder) {
          await drawHistoryFutureStepsPlaceholder(doc, false, CARD_PADDING + PARALLEL_BRANCHES_CARD_PADDING + PARALLEL_BRANCHES_CARD_MARGIN_LEFT, true, !branchStep.isFirstBranch);
          const currentStepNeededSpaceAfterPlaceholder = currentPos + historyItemValue + (branchStep.isFirstStep
                                                                                          ? 40
                                                                                          : 10);
          if (currentStepNeededSpaceAfterPlaceholder > maxCurrentPosOnPage) {
            const isBranchContinued = !hasBranchChanged;
            await drawParallelBranchesBackground(doc, currentPos, maxCurrentPosOnPage - currentPos + 11, isBranchContinued, branchStep.branchIndex > 0, isBranchContinued);
            await addNewPage(doc, true, startPage === currentPage, t('taskPdf.flow'), TaskFlowIcon);
            await drawParallelBranchesBackground(doc, PADDING_Y + 1, 20, isBranchContinued, branchStep.branchIndex > 0);
          }
        }
      } else {
        const shouldAddFutureStepsPlaceholder = branchStep.isLastPassedStep && branchStep.isBranchWithFutureSteps;

        const processStep = branchStep?.data as StructuredHistoryStep;
        const stepName = t(`constructor-${bpmTask.processSysName}.actions.${processStep.actionSysName}.name`, { defaultValue: processStep.taskName });
        const splitStepName = doc.splitTextToSize(stepName, PAPER_WIDTH - (PADDING_X + 36) * 2 - CARD_PADDING + PARALLEL_BRANCHES_CARD_PADDING * 3 + PARALLEL_BRANCHES_CARD_MARGIN_LEFT);
        const historyItemValue = splitStepName?.length * 10 + 8 + processStep?.assigneeList?.length * 20 + 10;
        const maxCurrentPosOnPage = PAPER_HEIGHT - FOOTER_HEIGHT - FOOTER_TOP_MARGIN - CARD_PADDING;

        if (shouldAddFutureStepsPlaceholder) {
          await drawHistoryFutureStepsPlaceholder(doc, false, CARD_PADDING + PARALLEL_BRANCHES_CARD_PADDING + PARALLEL_BRANCHES_CARD_MARGIN_LEFT, true, !branchStep.isFirstBranch);
          const currentStepNeededSpaceAfterPlaceholder = currentPos + historyItemValue + (branchStep.isFirstStep
                                                                                          ? 40
                                                                                          : 10);
          if (currentStepNeededSpaceAfterPlaceholder > maxCurrentPosOnPage) {
            await drawParallelBranchesBackground(doc, currentPos, maxCurrentPosOnPage - currentPos + 11, true, branchStep.branchIndex > 0, true);
            await addNewPage(doc, true, startPage === currentPage, t('taskPdf.flow'), TaskFlowIcon);
            await drawParallelBranchesBackground(doc, PADDING_Y + 1, 20, true, branchStep.branchIndex > 0);
          }
        }
      }
      await drawHistoryStep(doc, isCompletedProcess, branchStep.data, false, false, false, true, CARD_PADDING + PARALLEL_BRANCHES_CARD_PADDING + PARALLEL_BRANCHES_CARD_MARGIN_LEFT, 0, true, !(branchStep.isFirstBranch && !branchStep.isFirstStep), branchStep.isFirstStep, branchStep.isFirstBranch);
    }

    await drawParallelBranchesCardEnd(doc, currentPos, 12, true);
    currentPos += 14;
    await drawParallelBranchesOuterCardEnd(doc, currentPos, 10, false);
    currentPos += 18;

    doc.setLineWidth(1);
    doc.setDrawColor(COLORS.FLOW_TIMELINE);
    await doc.line(PADDING_X + 18, currentPos - 12, PADDING_X + 18, currentPos + 4);
  };

  const addTaskHistory = async (doc, taskHistory, isCompletedProcess = false) => {
    cardStartPos = currentPos;
    currentPos += CARD_HEADER_HEIGHT + CARD_PADDING;
    const startPage = currentPage;

    const displayedNodes = taskHistory.filter(node => !node.data.isFutureStep && !node.data.isFutureNode);

    let haveJustAddedFutureStepsPlaceholder = false;
    const haveFutureNodes = taskHistory.some(node => node.data.isFutureStep && node.data.isFutureNode);
    if (!isCompletedProcess && displayedNodes.length < taskHistory.length) {
      await drawHistoryFutureStepsPlaceholder(doc, false);
      haveJustAddedFutureStepsPlaceholder = true;
    }

    let index = 0;
    let newPageAdded = false;
    for (const historyNode of displayedNodes) {
      newPageAdded = false;
      if (historyNode.type === 'step') {
        const processStep = historyNode.data as StructuredHistoryStep;
        const stepName = t(`constructor-${bpmTask.processSysName}.actions.${processStep.actionSysName}.name`, { defaultValue: processStep.taskName });
        const splitStepName = doc.splitTextToSize(stepName, PAPER_WIDTH - (PADDING_X + 36) * 2);
        const historyItemValue = splitStepName?.length * 10 + 8 + processStep?.assigneeList?.length * 20 + processStep?.candidateUsers?.length * 20 + 10;

        const maxCurrentPosOnPage = PAPER_HEIGHT - FOOTER_HEIGHT - FOOTER_TOP_MARGIN - CARD_PADDING;
        if (currentPos + historyItemValue > maxCurrentPosOnPage) {
          doc.setLineWidth(1);
          doc.setDrawColor(COLORS.FLOW_TIMELINE);
          doc.setLineDash(haveJustAddedFutureStepsPlaceholder ? [7, 7] : []);
          const lineTopMargin = haveJustAddedFutureStepsPlaceholder ? 2 : 0;
          await doc.line(PADDING_X + 18, currentPos + lineTopMargin, PADDING_X + 18, PAPER_HEIGHT - FOOTER_HEIGHT - FOOTER_TOP_MARGIN - CARD_PADDING);
          doc.setLineDash([]);
          await addNewPage(doc, true, startPage === currentPage, t('taskPdf.flow'), TaskFlowIcon);
          newPageAdded = true;
        }

        await drawHistoryStep(doc, isCompletedProcess, processStep, index === 0, index === displayedNodes.length - 1, newPageAdded);

        index += 1;
      }

      if (historyNode.type === 'condition') {
        const conditionBranch = historyNode?.data.actualResult?.[0] === historyNode?.data?.positiveStep?.[0]
                                ? historyNode?.data.positiveBranch
                                : historyNode?.data.negativeBranch;
        const displayedConditionBranchNodes = conditionBranch.filter(node => !node.data.isFutureStep && !node.data.isFutureNode);
        const isConditionBranchWithFutureNodes = conditionBranch.some(node => node.data.isFutureStep || node.data.isFutureNode);
        if (!haveFutureNodes && isConditionBranchWithFutureNodes) {
          await drawHistoryFutureStepsPlaceholder(doc, false);
        }
        for (const conditionBranchStep of displayedConditionBranchNodes) {
          const processStep = conditionBranchStep?.data as StructuredHistoryStep;
          const stepName = t(`constructor-${bpmTask.processSysName}.actions.${processStep.actionSysName}.name`, { defaultValue: processStep.taskName });
          const splitStepName = doc.splitTextToSize(stepName, PAPER_WIDTH - (PADDING_X + 36) * 2);
          const historyItemValue = splitStepName?.length * 10 + 8 + processStep?.assigneeList?.length * 20 + 10;
          const maxCurrentPosOnPage = PAPER_HEIGHT - FOOTER_HEIGHT - FOOTER_TOP_MARGIN - CARD_PADDING;
          if (currentPos + historyItemValue > maxCurrentPosOnPage) {
            doc.setLineWidth(1);
            doc.setDrawColor(COLORS.FLOW_TIMELINE);
            doc.setLineDash(haveJustAddedFutureStepsPlaceholder ? [7, 7] : []);
            const lineTopMargin = haveJustAddedFutureStepsPlaceholder ? 2 : 0;
            await doc.line(PADDING_X + 18, currentPos + lineTopMargin, PADDING_X + 18, PAPER_HEIGHT - FOOTER_HEIGHT - FOOTER_TOP_MARGIN - CARD_PADDING);
            doc.setLineDash([]);
            await addNewPage(doc, true, startPage === currentPage, t('taskPdf.flow'), TaskFlowIcon);
            newPageAdded = true;
          }
          await drawHistoryStep(doc, isCompletedProcess, processStep, index === 0, index === taskHistory.length - 1, newPageAdded);
        }

        if (currentPos + 80 > PAPER_HEIGHT - FOOTER_HEIGHT - FOOTER_TOP_MARGIN - CARD_PADDING) {
          doc.setLineWidth(1);
          doc.setDrawColor(COLORS.FLOW_TIMELINE);
          doc.setLineDash(haveJustAddedFutureStepsPlaceholder ? [7, 7] : []);
          const lineTopMargin = haveJustAddedFutureStepsPlaceholder ? 2 : 0;
          await doc.line(PADDING_X + 18, currentPos + lineTopMargin, PADDING_X + 18, PAPER_HEIGHT - FOOTER_HEIGHT - FOOTER_TOP_MARGIN - CARD_PADDING);
          doc.setLineDash([]);
          await addNewPage(doc, true, startPage === currentPage, t('taskPdf.flow'), TaskFlowIcon);
          newPageAdded = true;
        }

        await drawHistoryCondition(doc, historyNode.data, taskHistory, newPageAdded);

        index += 1;
      }

      if (historyNode.type === 'parallelBranches') {
        if (currentPos + 80 > PAPER_HEIGHT - FOOTER_HEIGHT - FOOTER_TOP_MARGIN - CARD_PADDING) {
          doc.setLineWidth(1);
          doc.setDrawColor(COLORS.FLOW_TIMELINE);
          doc.setLineDash(haveJustAddedFutureStepsPlaceholder ? [7, 7] : []);
          const lineTopMargin = haveJustAddedFutureStepsPlaceholder ? 2 : 0;
          await doc.line(PADDING_X + 18, currentPos + lineTopMargin, PADDING_X + 18, PAPER_HEIGHT - FOOTER_HEIGHT - FOOTER_TOP_MARGIN - CARD_PADDING);
          doc.setLineDash([]);
          await addNewPage(doc, true, startPage === currentPage, t('taskPdf.flow'), TaskFlowIcon);
          newPageAdded = true;
        }

        await drawHistoryParallelBranches(doc, isCompletedProcess, historyNode.data, taskHistory, startPage, newPageAdded, index === 0);

        index += 1;
      }

      if (historyNode.type === 'parallelAssigneesStep') {
        const processStep = historyNode.data as StructuredHistoryParallelAssigneesStep;
        const firstParallelStep = processStep?.assigneeSteps[0];
        const stepName = t(`constructor-${bpmTask.processSysName}.actions.${(firstParallelStep?.data as StructuredHistoryStep)?.actionSysName}.name`, { defaultValue: (firstParallelStep?.data as StructuredHistoryStep)?.taskName });
        const splitStepName = doc.splitTextToSize(stepName, PAPER_WIDTH - (PADDING_X + 36) * 2);
        const historyItemValue = splitStepName?.length * 10 + 8 + processStep?.assigneeSteps?.length * (20 + 10);

        if (currentPos + historyItemValue > PAPER_HEIGHT - FOOTER_HEIGHT - FOOTER_TOP_MARGIN - CARD_PADDING) {
          doc.setLineWidth(1);
          doc.setDrawColor(COLORS.FLOW_TIMELINE);
          await doc.line(PADDING_X + 18, currentPos, PADDING_X + 18, PAPER_HEIGHT - FOOTER_HEIGHT - FOOTER_TOP_MARGIN - CARD_PADDING);
          await addNewPage(doc, true, startPage === currentPage, t('taskPdf.flow'), TaskFlowIcon);
          newPageAdded = true;
        }

        await drawHistoryParallelAssigneesStep(doc, processStep, index === 0, index === taskHistory.length - 1, newPageAdded);

        index += 1;
      }
      haveJustAddedFutureStepsPlaceholder = false;
    }

    await drawCard({
      doc,
      startY: cardStartPos,
      height: currentPos + CARD_PADDING - cardStartPos,
      hasHeader: currentPage === startPage,
      headerIcon: TaskFlowIcon,
      headerText: t('taskPdf.flow'),
    });
  };

  const addFooterToAllPages = async (doc) => {
    for (let page = 1; page <= currentPage; page++) {
      doc.setPage(page);

      doc.setFillColor(COLORS.WHITE);
      await doc.rect(0, PAPER_HEIGHT - FOOTER_HEIGHT - FOOTER_TOP_MARGIN + 0.5, PAPER_WIDTH, FOOTER_HEIGHT + FOOTER_TOP_MARGIN, 'F');

      doc.setDrawColor(COLORS.BORDER_LIGHT_GREY);
      await doc.line(0, PAPER_HEIGHT - FOOTER_HEIGHT, PAPER_WIDTH, PAPER_HEIGHT - FOOTER_HEIGHT, 'S');

      const isUSRegion = environment.region === 'US';
      const exportDateFormat = isUSRegion ? 'MMMM D, YYYY' : 'D MMMM YYYY';
      const exportText = t('taskPdf.footer.export', { date: moment().format(exportDateFormat) });
      doc.setFontSize(10);
      doc.setFont(FONTS[400]);
      doc.setTextColor(COLORS.FLOW_TIMELINE);
      await doc.text(exportText, PADDING_X, PAPER_HEIGHT - FOOTER_HEIGHT + 10, { lineHeightFactor: 16 / 10 });

      const poweredByPrefix = t('taskPdf.footer.poweredByPrefix');
      const poweredBySuffix = t('taskPdf.footer.poweredBySuffix');
      const platformName = 'darlean';
      const prefixWidth = doc.getTextWidth(poweredByPrefix);
      const platformNameWidth = doc.getTextWidth(platformName);
      const suffixWidth = doc.getTextWidth(poweredBySuffix);

      let xPos = PAPER_WIDTH - PADDING_X;
      doc.setFontSize(10);
      doc.setFont(FONTS[400]);
      if (poweredBySuffix) {
        doc.setTextColor(COLORS.FLOW_TIMELINE);
        xPos -= suffixWidth;
        await doc.text(poweredBySuffix, xPos, PAPER_HEIGHT - FOOTER_HEIGHT + 10, { lineHeightFactor: 16 / 10 });
        xPos -= 4;
      }

      doc.setTextColor(COLORS.TEXT_LINK);
      xPos -= platformNameWidth;
      doc.textWithLink(platformName, xPos, PAPER_HEIGHT - FOOTER_HEIGHT + 10, {
        lineHeightFactor: 16 / 10,
        url: environment.landingUrl,
      });
      xPos -= 4;

      if (poweredByPrefix) {
        doc.setTextColor(COLORS.FLOW_TIMELINE);
        xPos -= prefixWidth;
        await doc.text(poweredByPrefix, xPos, PAPER_HEIGHT - FOOTER_HEIGHT + 10, { lineHeightFactor: 16 / 10 });
      }
    }
  };

  // Requires existence of TaskPdfGenerator component
  const generatePdf = async () => {
    try {
      const isProcessCompleted = bpmTask.isCompleted || bpmTask.businessTask?.taskStatus === 'Completed';
      const historyNodes = await getTaskHistoryNodes();
      const doc = new jsPDF('p', 'px', [PAPER_WIDTH, PAPER_HEIGHT], true);
      await addHeader(doc, isProcessCompleted);
      await addTaskDetails(doc);
      currentPos += CARD_PADDING * 2;

      let processDocuments = [];
      if (environment.isDocumentAdditionInTemplateProcessesAvailable) {
        processDocuments = await getProcessDocuments(bpmTask?.processInstanceId);

        const docflowField = bpmTask?.attributes?.find(attr =>  attr.name.includes('documentFromDocflow-'));
        if (docflowField) {
          const promises = [];
          bpmTask?.entity?.taskInstance?.docFlowDocuments?.forEach((doc) => {
            promises.push(getDocument(doc.id));
          });
          const result = await Promise.all(promises);
          const documentsFromDocflow = result.map(res => ({
            name: res?.title,
            docflowDocumentId: res?.id,
            isSigned: false,
            isAttachedDocument: true,
            signings: res?.signatories?.map(signatory => ({
              status: signatory.isSigned,
              userId: signatory.userId,
              signDate: signatory.signingDate,
              stepOrder: 0,
            }))
          }));
          processDocuments = documentsFromDocflow.length ? [...processDocuments, ...documentsFromDocflow] : processDocuments;
        }
      }
      const visibleDocuments = processDocuments
        .filter(document =>
          (
            document.firstAppearanceStep <= bpmTask.currentAction.stepperOrder &&
            !document.documentHiddenSteps.includes(bpmTask.currentAction.stepperOrder)
          ) || document?.isAttachedDocument
        );
      if (visibleDocuments.length > 0) {
        await addTaskDocuments(doc, visibleDocuments);
        currentPos += CARD_PADDING * 2;
      }

      const minimumHistoryCard = 60 + CARD_PADDING * 2 + CARD_HEADER_HEIGHT;
      if (currentPos + minimumHistoryCard > PAPER_HEIGHT - FOOTER_HEIGHT - FOOTER_TOP_MARGIN - CARD_PADDING) {
        doc.addPage();
        currentPage += 1;
        currentPos = PADDING_Y;
        doc.setPage(currentPage);
      }

      await addTaskHistory(doc, historyNodes, isProcessCompleted);
      await addFooterToAllPages(doc);
      doc.save(`${t(`constructor-${bpmTask.processSysName}.name`, { defaultValue: bpmTask.processName })}.pdf`);
    } catch (error) {
      console.log(error);
      NotificationManager.error(t('errors.somethingIsWrong'), `${t('customProcesses.notifications.error')}!`);
    }
  };

  return {
    generatePdf,
    processLink,
  };
};
