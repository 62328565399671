import React from 'react';
import cn from 'classnames';

import useStyles from './useStyles';
import useSuppliersVotingSwitch from './useSuppliersVotingSwitch';

import blueCheckIcon from 'assets/images/icons/blue-check.svg';

type Props = {
  instance?: any;
  instancesList?: any;
  setInstancesList?: (value: any[]) => void;
  votingStatusAttribute?: any;
  setMainFormValue?: (name: string, value: any) => void;
};

export const SuppliersVotingSwitch = ({
  instance,
  instancesList,
  setInstancesList,
  votingStatusAttribute,
  setMainFormValue,
}: Props) => {
  const classes = useStyles();

  const { value, handleChange } = useSuppliersVotingSwitch({
    instance,
    instancesList,
    setInstancesList,
    votingStatusAttribute,
    setMainFormValue,
  });

  return (
    <div className={classes.root} onClick={() => handleChange(!value)}>
      <div className={classes.switchRoot}>
        <span className={classes.yesLabel}>Yes</span>
      </div>
      <div
        className={cn(classes.switchRootOverlay, {
          [classes.switchRootOverlayYes]: value,
        })}
      >
        <span className={classes.noLabel}>No</span>
      </div>
      <div
        className={cn(classes.coverBlock, {
          [classes.coverBlockYes]: value,
        })}
      >
        <img
          className={cn(classes.checkIcon, {
            [classes.checkIconShown]: value,
          })}
          src={blueCheckIcon}
        />
      </div>
    </div>
  );
};

export default SuppliersVotingSwitch;
