import { CancelToken } from "axios";

import { baseV1ApiClient } from '../clients';
import { commonExceptionHandler } from '../handlers';
import { ProcessGroup } from '../../pages/Settings/GroupsSettingsPage/GroupsSettingsPage.types';

export const getEntity = async id => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: `/camunda/constructor-config/businesstypes?id=${id}`,
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getBusinessEntity = async ({ params, cancelToken}: {
  params: {
    sysName: string;
  },
  cancelToken?: CancelToken
}) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/camunda/constructor-config/businesstypes/bySysNameAndActiveLastVersion',
      params,
      cancelToken
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getProcessesGroups = async () => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/camunda/constructor-config/groups'
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getProcessesGroupsByCompany = async () => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/camunda/constructor-config/groups/byCompany'
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const createProcessGroup = async (data) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'POST',
      url: '/camunda/constructor-config/groups',
      data
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const updateProcessGroups = async (groups) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'POST',
      url: '/camunda/constructor-config/groups/saveAll',
      data: groups,
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const checkProcessGroupForProcesses = async (groupSysName: string) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/camunda/constructor-config/groups/groupCheck',
      params: {
        groupSysName
      },
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const transferProcessesBetweenGroups = async (oldGroupSysName: string, newGroupSysName: string) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/camunda/constructor-config/groups/groupTransfer',
      params: {
        oldGroupSysName,
        newGroupSysName
      },
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};


export const deleteProcessGroup = async (sysName: string) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'DELETE',
      url: '/camunda/constructor-config/groups',
      params: {
        sysName,
      },
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};
