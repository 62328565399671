import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useStyles from '../useStyles';

import { URGENT_TEXTAREA_NAME } from '../constants';

type Props = {
  urgentReason: string;
};

const BpmUrgentRequestReadOnly: React.FunctionComponent<Props> = ({
  urgentReason,
}) => {
  const { t } = useTranslation();
  const { register } = useFormContext();
  const classes = useStyles();

  return (
    <Box py={3} display="flex" width="100%">
      <Typography variant="h5" className={classes.formGroupTitle}>
        {t('form_components.urgent.title')}
      </Typography>
      <Box>
        <Box mb={1}>
          <Typography>{t('form_components.urgent.reasonOnTitle')}</Typography>
        </Box>
        <Typography color="secondary">{urgentReason}</Typography>
        <input
          type="hidden"
          {...register(URGENT_TEXTAREA_NAME)}
          value={urgentReason}
        />
      </Box>
    </Box>
  );
};

export default BpmUrgentRequestReadOnly;
