import { createAction } from 'redux-actions';

import {
  getCompanySettingsRoles,
  getCompanySettingsUsers,
  getAllUserPermissions as getAllUserPermissionRequest,
  getAllCompanyProcesses as getAllCompanyProcessesRequest,
  getAllCompanyTemplateProcesses as getAllCompanyProcessesTemplateRequest,
  getDefaultCompanyProcesses as getDefaultCompanyProcessesRequest,
  checkAccessToAdminSettings as checkAccessToAdminSettingsRequest,
  getChildrenCompanies as getChildrenCompaniesRequest,
  getUserACLSettingsRequest, checkUserPermission,
} from 'api/requests';
import { TEMPLATE_PROCESSES_PERMISSIONS } from '../../hooks';

export const SETTINGS_ACTIONS = {
  CHECK_USER_ACCESS_TO_ADMIN_SETTINGS: 'CHECK_USER_ACCESS_TO_ADMIN_SETTINGS',
  GET_ACL_CURRENT_USER_ACCESSES: 'GET_ACL_CURRENT_USER_ACCESSES',
  GET_COMPANY_ROLES: 'GET_COMPANY_ROLES',
  GET_COMPANY_USERS: 'GET_COMPANY_USERS',
  GET_ALL_USERS_PERMISSION: 'GET_ALL_USERS_PERMISSION',
  GET_ALL_COMPANY_PROCESSES: 'GET_ALL_COMPANY_PROCESSES',
  GET_ALL_COMPANY_TEMPLATE_PROCESSES: 'GET_ALL_COMPANY_TEMPLATE_PROCESSES',
  GET_DEFAULT_COMPANY_PROCESSES: 'GET_DEFAULT_COMPANY_PROCESSES',
  GET_CHILD_COMPANIES: 'GET_CHILD_COMPANIES',
  CHECK_TEMPLATE_PROCESSES_ACCESSES: 'CHECK_TEMPLATE_PROCESSES_ACCESSES'
};

export const getChildrenCompanies = createAction(
  SETTINGS_ACTIONS.GET_CHILD_COMPANIES,
  () => getChildrenCompaniesRequest()
)

export const checkAccessToAdminSettings = createAction(
  SETTINGS_ACTIONS.CHECK_USER_ACCESS_TO_ADMIN_SETTINGS,
  () => checkAccessToAdminSettingsRequest()
)

export const checkTemplateProcessesAccesses = createAction(
  SETTINGS_ACTIONS.CHECK_TEMPLATE_PROCESSES_ACCESSES,
  async () => {
    const checkPermission = async (permission: string) => {
      return checkUserPermission(permission).catch(() => {
        return new Promise((resolve) => resolve(false));
      });
    };

    const requests = [];
    for (const permission of TEMPLATE_PROCESSES_PERMISSIONS) {
      requests.push(checkPermission(permission));
    }
    const accesses = await Promise.all(requests);

    return TEMPLATE_PROCESSES_PERMISSIONS.reduce((acc, permission, index) =>
      ({...acc, [permission]: accesses[index]}),
      {})
  });


export const getAclUserAccesses = createAction(
  SETTINGS_ACTIONS.GET_ACL_CURRENT_USER_ACCESSES,
  () => getUserACLSettingsRequest()
)

export const getCompanyRoles = createAction(
  SETTINGS_ACTIONS.GET_COMPANY_ROLES,
  () => getCompanySettingsRoles()
)

export const getCompanyUsers = createAction(
  SETTINGS_ACTIONS.GET_COMPANY_USERS,
  () => getCompanySettingsUsers()
)

export const getAllUsersPermission = createAction(
  SETTINGS_ACTIONS.GET_ALL_USERS_PERMISSION,
  () => getAllUserPermissionRequest()
)

export const getAllCompanyProcesses = createAction(
  SETTINGS_ACTIONS.GET_ALL_COMPANY_PROCESSES,
  () => getAllCompanyProcessesRequest()
)

export const getAllCompanyTemplateProcesses = createAction(
  SETTINGS_ACTIONS.GET_ALL_COMPANY_TEMPLATE_PROCESSES,
  () => getAllCompanyProcessesTemplateRequest()
)

export const getDefaultCompanyProcesses = createAction(
  SETTINGS_ACTIONS.GET_DEFAULT_COMPANY_PROCESSES,
  () => getDefaultCompanyProcessesRequest()
)
