import React, { useMemo } from 'react';

import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useStyles from './useStyles';
import RenderFields from './RenderFields';
import { FormGroupTemplateProps } from './types';

const FormGroupTemplate = (props: FormGroupTemplateProps) => {
  const { formGroup, processSysName, stepSysName, setButtonDisabled, setAlwaysActiveButtons, handleAction } = props;
  const sortedFields = formGroup.fields.sort((a, b) => a.state_order - b.state_order);
  const { t } = useTranslation();
  const classes = useStyles();
  const groupNameOldTranslationKeyPrefix = useMemo(() => `constructor-${processSysName}.states.${stepSysName}.fieldGroups.`, [
    processSysName,
    stepSysName,
  ]);
  const groupNameTranslationKeyPrefix = useMemo(() => `constructor-${processSysName}.fieldGroups.`, [processSysName]);
  const transformOldGroupName = groupNameOldTranslationKeyPrefix + formGroup.name.toLowerCase().replaceAll(' ', '-');
  const transformGroupName = groupNameTranslationKeyPrefix + formGroup.name.toLowerCase().replaceAll(' ', '-');

  return (
    <Box className={classes.formGroup}>
      <Typography variant="h5" className={classes.formGroupTitle}>
        {t(transformOldGroupName, {
          defaultValue: t(transformGroupName, { defaultValue: formGroup.name }),
        })}
      </Typography>
      <Box flex="1" pt={1} maxWidth={400}>
        <RenderFields
          setButtonDisabled={setButtonDisabled}
          setAlwaysActiveButtons={setAlwaysActiveButtons}
          handleAction={handleAction}
          sortedFields={sortedFields}
        />
      </Box>
    </Box>
  );
};

export default FormGroupTemplate;
