import React, { memo } from 'react';
import cn from 'classnames';
import { FormControlLabel, Switch as MuiSwitch } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';

import useBpmSwitchTimeOff from './hooks/useBpmSwitchTimeOff';
import useBpmSwitchOnBoarding from './hooks/useBpmSwitchOnboarding';

import useStyles from './useStyles';
import blueCheckIcon from 'assets/images/icons/blue-check.svg';

type Props = {
  name?: string;
  hint?: string;
  variantWithLabels?: boolean;
};

export const Switch = memo(({ hint, name, variantWithLabels = false }: Props) => {
  const classes = useStyles();
  const { control } = useFormContext();

  const { isAdaptationSalaryEquals } = useBpmSwitchOnBoarding();
  const { isTimeOffNotHRUser } = useBpmSwitchTimeOff({ name });

  if (isAdaptationSalaryEquals || isTimeOffNotHRUser) return null;

  return (
        <Controller
          control={control}
          name={name}
          data-selenium={name}
          render={({ field: { onChange, value, onBlur, ref }}) =>
            variantWithLabels ? (
              <>
                <div className={classes.root} onClick={() => onChange(!value)}>
                  <div
                    className={classes.switchRoot}
                  >
                    <span className={classes.yesLabel}>Yes</span>
                  </div>
                  <div
                    className={cn(classes.switchRootOverlay, {
                      [classes.switchRootOverlayYes]: value,
                    })}
                  >
                    <span className={classes.noLabel}>No</span>
                  </div>
                  <div
                    className={cn(classes.coverBlock, {
                      [classes.coverBlockYes]: value,
                    })}
                  >
                    <img className={cn(classes.checkIcon, {[classes.checkIconShown]: value})} src={blueCheckIcon}/>
                  </div>
                </div>
              </>
            ) : (
              <FormControlLabel
                label={hint || null}
                style={{position: 'relative'}}
                control={
                  <MuiSwitch
                    onBlur={onBlur}
                    checked={value}
                    inputRef={ref}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                }
              />
            )
          }
        />
  );
});
