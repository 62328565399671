import React, { ReactElement, useState } from 'react';
import { Box, FormControlLabel, Switch } from '@mui/material';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { DocumentTemplate, ProcessStep } from '../../TemplateProcesses.types';

import useParallelAssigneeToggleStyles from './ParallelAssigneeToggle.useStyles';
import { ParallelAssigneeActivationConfirmationModal } from '../ParallelAssigneeActivationConfirmationModal';
import { DOCUMENT_DOCFLOW_FIELD_KEY } from "../ProcessSetupSidePanel/ProcessSetupSidePanel.constants";

interface PerformerSelectCheckboxProps {
  currentStep: ProcessStep;
  handleChange: (value: boolean) => void;
  disabled: boolean;
  documents: DocumentTemplate[];
}

export const ParallelAssigneeToggle = ({
  currentStep,
  handleChange,
  disabled,
  documents
}: PerformerSelectCheckboxProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useParallelAssigneeToggleStyles();

  const [isConfirmationModalOpen, setConfirmationModalClose] = useState(false);

  const handleToggleValueChange = (newValue) => {
    if (!newValue) {
      handleChange(false);
    } else {
      const hasSignatureStep = documents?.some(doc => doc.signatureSteps.includes(currentStep?.stepOrder));
      const isDocFlowRequired = currentStep.fields.some(step => step.component === DOCUMENT_DOCFLOW_FIELD_KEY && step.params.isRequired);

      if (hasSignatureStep || isDocFlowRequired) {
        setConfirmationModalClose(true);
      } else {
        handleChange(true);
      }
    }
  }

  return (
    <Box className={cn(classes.ParallelAssigneeToggle)}>
      <FormControlLabel
        disabled={disabled}
        control={<Switch
          checked={currentStep?.parallel}
          classes={{
            track: currentStep?.parallel ? classes.trackChecked : classes.track,
            switchBase: classes.switchBase
          }}
          onChange={e => handleToggleValueChange(e?.target?.checked)}
        />}
        label={t('customProcesses.creationPage.processForm.fields.parallelAssigneeToggle')}
      />

      <ParallelAssigneeActivationConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={() => setConfirmationModalClose(false)}
        onConfirm={() => handleChange(true)}
      />
    </Box>
  );
};
