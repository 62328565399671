import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ spacing }) => ({
  dialog: {
    width: 800,
    borderRadius: 12,
    border: '1px solid rgba(38, 40, 66, 0.08)',
  },
  dialogHeader: {
    display: 'flex',
    paddingLeft: 16,
    paddingRight: 20,
    height: 48,
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid rgba(38, 40, 66, 0.08)',
  },
  dialogTitle: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.3,
    color: '#262842',
  },
  dialogSubtitle: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.3,
    color: '#8B8C9E',
    margin: spacing(2, 0, 15),
  },
  dialogIcon: {
    '& > path': {
      fill: '#6D7C8B',
    },
  },
  dialogContent: {
    padding: 0,
  },
  dialogActions: {
    height: 64,
    backgroundColor: '#F7F8FC',
  },
  conditionFieldsWrapper: {
    height: 64,
    margin: 16,
    marginBottom: 72,
    border: '1px solid rgba(38, 40, 66, 0.12)',
    borderRadius: 12,
  },
  conditionField: {
    width: 240,
    height: 40,
    marginLeft: 12,
    marginTop: 12,
    border: '1px solid rgba(38, 40, 66, 0.12)',
    borderRadius: 10,
  },
  conditionFieldText: {
    '& div': {
      width: 240,
      height: 40,
      marginLeft: 12,
      marginTop: 12,
      border: '1px solid rgba(38, 40, 66, 0.12)',
      borderRadius: 10,
    },
  },
  emptyFieldsListMessageWrapper: {
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 8,
    paddingBottom: 8,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#8B8C9E',
    '& p': {
      margin: 0,
      padding: 0,
      paddingBottom: 2,
    },
    '& ul': {
      paddingLeft: 18,
      listStyle: 'disc',
    },
  },
  fieldsMenuItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    maxWidth: '100%',
    overflow: 'hidden',
  },
  fieldsMenuItemFieldName: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 14,
    fontWeight: 400,
    color: '#262842',
  },
  fieldsMenuItemStepInfo: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 12,
    fontWeight: 400,
    color: '#8B8C9E',
  },

  fieldGroup: {
    overflow: 'hidden',
    borderRadius: 12,
    border: '1px solid rgba(38, 40, 66, 0.08)',
  },
  fieldGroupHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '12px 16px',
    borderBottom: '1px solid rgba(38, 40, 66, 0.08)',
    background: '#F7F8FC',
  },
  processStepFormFieldsGroupIcon: {
    width: 20,
    height: 20,
    marginRight: 12,
  },
  processStepFormFieldsGroupTitle: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    color: '#262842',
  },
  fieldsListWrapper: {
    minHeight: 8,
    padding: '12px 4px',
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    backgroundColor: 'transparent',
    transitionProperty: 'background',
    transitionDuration: '200ms',
  },
  addDocumentContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  addDocumentContentInfoMessage: {
    color: '#8B8C9E',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
  },
  documentCard: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    borderRadius: 8,
    paddingTop: 8,
    paddingBottom: 8,
    position: 'relative',
    '&:hover': {
      background: '#F7F8FC',
      '& $cardActions': {
        display: 'block',
        position: 'absolute',
        top: '50%',
        right: 12,
        marginTop: -20
      },
    },
  },
  documentCardActive: {
    border: '1px solid #497CF6',
    background: '#EBF5FC',
  },
  documentCardError: {
    border: '1px solid #D6331F',
  },
  dragAndDropIndicator: {},
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  documentName: {
    color: '#262842',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    marginBottom: 2,
    display: 'flex',
  },
  documentStatus: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  documentStatusIcon: {
    height: 16,
    width: 16,
    marginRight: 4,
  },
  documentStatusText: {
    color: '#8B8C9E',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 400,
    paddingTop: 2,
  },
  cardActions: {
    display: 'none',
  },

  cardTopRowWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    gap: 4,
    marginBottom: 4,
  },
  signatureChip: {
    padding: '4px 6px',
    borderRadius: 6,
    background: '#F0F0F7',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
  },
  documentsListDraggableItem: {
    width: '100%',
  },
  documentsListDragZoneVisible: {
    backgroundColor: '#DEF7FC',
  },
  documentActionsButton: {
    width: 40,
    height: 40,
    backgroundColor: '#FFFFFF !important',
    // borderColor: '#FFFFFF !important',
    border: '1px solid #2628421F !important',
    borderRadius: '0 !important',
    '&:first-child': {
      borderTopLeftRadius: '10px !important',
      borderBottomLeftRadius: '10px !important',
    },
    '&:not(:first-child)': {
      borderLeftWidth: '0px !important',
    },
    '&:last-child': {
      borderTopRightRadius: '10px !important',
      borderBottomRightRadius: '10px !important',
    },
  },
  infoIcon: {
    width: 16,
    height: 16,
    marginLeft: 4,
    verticalAlign: 'sub',
  },
  goToStepButton: {
    padding: 0,
    color: '#7CCCDE',
  },
  tooltip: {
    background: '#262842',
  },
  tooltipArrow: {
    color: '#262842',
  },
  fieldTooltip: {
    borderRadius: 8,
  },
  infoCard: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alightItems: 'flex-start',
    gap: 8,
    marginBottom: 12,
    background: '#EBF5FC',
    border: '1px solid #2628421F',
    borderRadius: 12,
    padding: 12,
  },
  infoCardLeftPart: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alightItems: 'flex-start',
  },
  infoCardIcon: {
    width: 24,
    height: 24,
    '& path, & svg path': {
      fill: '#497CF6 !important',
    },
  },
  infoCardRightPart: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alightItems: 'flex-start',
  },
  infoCardTitle: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '18px',
    color: '#262842',
    marginBottom: 6,
  },
  infoCardMessage: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '18px',
    color: '#6D6E85',
  },
  noSignaturesError: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alightItems: 'center',
    gap: 4,
    marginTop: 4,
    '& img': {
      width: 18,
      height: 18
    },
    '& span': {
      fontSize: 14,
      fontWeight: 400,
      color: '#D6331F'
    }
  }
}));
