import React, { useState, useCallback, useEffect, memo, useMemo } from 'react';
import {Typography, FormControl, Theme, FormHelperText, Select, MenuItem, CircularProgress, Box} from '@mui/material';
import NumberFormat from 'react-number-format';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getCurrencies } from 'api/requests';
import { useTaskState } from 'store/requests';
import { removeSpacesFromNumber } from 'utils/general';
import { DictionaryItem } from 'types';

import useStyles from './useStyles';
import { AppTextField } from '../../../../../../components';
import CheckIcon from "@mui/icons-material/Check";
import classnames from "classnames";

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const MAX_VALUE_LIMIT = 1000000000000;

type Props = {
  name?: string;
  hint?: string;
  isTaskDetailsVariant?: boolean;
  disabled?: boolean;
  allowZeroAmount?: boolean;
  placeholder?: string;
};

export const Sum = memo(
  ({ name, hint, disabled = false, allowZeroAmount, placeholder }: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
      formState: { errors },
      control,
      watch,
      setValue,
    } = useFormContext();
    const [currencies, setCurrencies] = useState<DictionaryItem[]>([]);
    const [loadingCurrencies, setLoadingCurrencies] = useState(false);
    const { data: bpmTask, createdFromTemplate } = useTaskState();

    const defaultSumCurrencyValue = useMemo(() => {
      return bpmTask?.entity?.taskInstance?.stringAttributes?.find((attr) => attr.name === 'sumCurrency')?.value;
    }, [bpmTask]);

    useEffect(() => {
      const isValueUndefined = !watch(name);
      const isTaskTemplate = bpmTask?.isFirstStep && createdFromTemplate;

      if (isValueUndefined && isTaskTemplate) {
        setValue(name, null, { shouldValidate: true });
      }
    }, [name, setValue, watch, bpmTask, createdFromTemplate]);

    useEffect(() => {
      setLoadingCurrencies(true);
      getCurrencies().then((res) => {
        setCurrencies(res);
      }).finally(() => {
        setLoadingCurrencies(false);
      });
    }, []);

    useEffect(() => {
      if (!loadingCurrencies && currencies.length > 0) {
        const defaultCurrency = currencies.find(currency => currency.value === 'KZT');
        if (defaultCurrency) {
          setValue('sumCurrency', defaultCurrency.value, { shouldValidate: true });
        }
      }
    }, [loadingCurrencies, currencies, setValue]);


    const validateAmount = (value) => {
      if (!allowZeroAmount && removeSpacesFromNumber(value) === 0) {
        return t('form_components.amountAndCurrency.zeroIsNotAllowed');
      }

      if (removeSpacesFromNumber(value) > MAX_VALUE_LIMIT) {
        return t('form_components.validation_errors.numberMax') as string;
      }

      return true;
    };

    return (
      <>
        <Typography className={classnames(classes.label, {
          [classes.labelError]: !!errors[name] || !!errors['sumCurrency']
        })}>
          {hint}
          <span style={{ color: '#D6331F' }}> *</span>
        </Typography>

        <FormControl fullWidth className={classes.Wrapper} error={!!errors[name] || !!errors['sumCurrency']}>
          <div className={classnames(classes.InnerWrapper, {
            [classes.InnerErrorWrapper]: !!errors[name] || !!errors['sumCurrency']
          })}>
            <Controller
              name={name}
              control={control}
              rules={{
                required: t('form_components.required_errors.default') as string,
                validate: validateAmount,
              }}
              render={({ field: { value, onChange } }) => (
                <NumberFormat
                  value={value === 0 ? '' : value}
                  placeholder={placeholder}
                  disabled={disabled}
                  decimalScale={2}
                  thousandSeparator=" "
                  decimalSeparator="."
                  allowNegative={false}
                  allowLeadingZeros={false}
                  customInput={AppTextField}
                  className={classes.amountInput}
                  onValueChange={({ floatValue }) => onChange(floatValue)}
                />
              )}
            />
            <Controller
              name="sumCurrency"
              control={control}
              defaultValue={defaultSumCurrencyValue}
              rules={{
                required: t('form_components.required_errors.default') as string,
                // validate: validateCurrency,
              }}
              render={({ field: { value, onChange } }) => (
                <Select
                  className={classes.currencyAutocomplete}
                  classes={{
                    root: classes.currencyInputRoot,
                  }}
                  value={value}
                  onChange={(event) => onChange(event.target.value)}
                  disabled={disabled}
                  error={!!errors['sumCurrency']}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 170,
                        border: '1px solid rgba(38, 40, 66, 0.12)',
                        borderRadius: '10px',
                        boxShadow: '0px 8px 16px 0px rgba(38, 40, 66, 0.1)',
                      },
                    },
                  }}
                  renderValue={(selected) => selected}
                >
                  {loadingCurrencies ? (
                    <Box display="flex" alignItems="center" justifyContent="center" mr={2}>
                      <CircularProgress size={16}/>
                    </Box>
                  ) : (
                    currencies.map((currency) => (
                      <MenuItem
                        key={currency.value}
                        value={currency.value}
                        className={classnames({
                        [classes.currencyItemSelected]: value === currency.value
                        })}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          width="100%"
                          height="100%"
                        >
                          {currency.value}
                          {value === currency.value && (
                            <CheckIcon />
                          )}
                        </Box>
                      </MenuItem>
                    ))
                  )}
                </Select>
              )}
            />
          </div>
        </FormControl>

        {!!errors[name] && (
          <FormHelperText error={!!errors[name]} className={classes.errorMessage}>
            {errors[name]?.message}
          </FormHelperText>
        )}
        {!!errors['sumCurrency'] && (
          <FormHelperText error={!!errors['sumCurrency']} className={classes.errorMessage}>
            {errors['sumCurrency']?.message || t('form_components.required_errors.default')}
          </FormHelperText>
        )}
      </>
    );
  },
  (prevProps, nextProps) =>
    prevProps.name === nextProps.name &&
    prevProps.hint === nextProps.hint &&
    // prevProps.currencies === nextProps.currencies &&
    prevProps.disabled === nextProps.disabled &&
    prevProps.isTaskDetailsVariant === nextProps.isTaskDetailsVariant
);
