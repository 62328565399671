import { useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';

import { useRequestParams } from 'hooks';
import {
  getMyRequestsAction,
  useRequestListState,
  useSelectedRequest,
  setRequestListPageAction as setCurrentPage,
  setRequestsCompletedAction as setCompleted,
  setSelectedRequest,
  setRequestListPageSizeAction as setPageSize,
  setCompletedRequestsCountAction,
  setActiveRequestsCountAction,
} from 'store/requests';
import { setFilters, useFiltersState } from 'store/search';

const useRequestsList = (completed: boolean) => {
  const dispatch = useDispatch();

  const {
    data,
    loading,
    error,
    currentPage,
    pageSize,
  } = useRequestListState();
  const selectedRequest = useSelectedRequest();
  const filters = useFiltersState();

  const {
    urlParams,
  } = useRequestParams({
    completed,
    currentPage,
    pageSize: pageSize,
  });

  const prevCompleted = useRef(completed);
  const prevUrlParams = useRef(urlParams);

  const handleTaskSelection = (taskId) => {
    dispatch(setSelectedRequest(taskId));
  };

  const handlePageSizeChange = (event) => {
    dispatch(setPageSize(event.target.value));
  };

  useEffect(() => {
    dispatch(setCompleted(completed));
  }, [dispatch, completed]);

  useEffect(() => {
    const myRequestsParams = {
      ...filters,
      pagination: {
        size: pageSize,
        page: currentPage - 1,
        sort: 'taskStartDate,DESC'
      },
      completed,
      taskCompleted: completed,
      pending: !completed,
      archive: false,
    };

    dispatch(getMyRequestsAction(myRequestsParams));
    dispatch(setFilters(myRequestsParams));
  }, [pageSize, currentPage, completed]);


  useEffect(() => {
    if (!data) {
      return;
    }

    if (completed) {
      dispatch(setCompletedRequestsCountAction(data.totalElements));
    } else {
      dispatch(setActiveRequestsCountAction(data.totalElements));
    }
  }, [data]);

  useEffect(() => {
    if (completed !== prevCompleted.current) {
      prevCompleted.current = completed;
    }
  }, [completed]);

  useEffect(() => {
    if (!isEqual(prevUrlParams.current, urlParams)) {
      prevUrlParams.current = urlParams;
    }
  }, [urlParams]);

  const handlePageChange = useCallback(
    (_, newPage) => {
      dispatch(setCurrentPage({ page: newPage }));
    },
    [dispatch],
  );

  return {
    myRequests: data?.content,
    loading,
    error,
    currentPage,
    selectedRequest,
    totalPages: data?.totalPages,
    handlePageChange,
    handleTaskSelection,
    pageSize,
    handlePageSizeChange,
    totalElements: data?.totalElements
  };
};

export default useRequestsList;
