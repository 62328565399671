import { useCallback, useEffect, useMemo, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useTranslation } from 'react-i18next';

import {
  getDocumentTemplatesList, getGlossaryAsTree,
} from 'api/requests';

import { DocumentTemplate } from '../../../../TemplateProcesses.types';
import { initializeSelectedStates } from '../../../../../Task/TaskForm/FormFields/Fields/Glossary/utils';
import debounce from 'lodash/debounce';

type DepartmentDocuments = {
  departmentName: string;
  departmentId: string;
  types: {
    typeName: string;
    typeId: string;
    documents: DocumentTemplate[];
  }[]
}

type Props = {
  focusedDocumentId: string;
  processDocuments: any[];
}

export const useDocumentsTab = ({ focusedDocumentId, processDocuments }: Props) => {
  const { t, i18n } = useTranslation();

  const [documentsList, setDocumentsList] = useState<DocumentTemplate[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedDocument, setSelectedDocument] = useState<any>(null);
  const [directories, setDirectories] = useState([]);
  const [allDirectories, setAllDirectories] = useState([]);

  const [searchValue, setSearchValue] = useState('');
  const [departmentValue, setDepartmentValue] = useState('');
  const [typeValue, setTypeValue] = useState('');

  // TODO - debounce values changes before requesting requests list

  const debouncedHandleSearchChange = useCallback(
    debounce(value => handleSearchChange(value), 300),
    []);

  const handleSearchChange = (newValue: string) => {
    setSearchValue(() => newValue);
  };

  const handleTypeChange = (newValue: string) => {
    setTypeValue(() => newValue);
  };

  const handleDepartmentChange = (newValue: string) => {
    setDepartmentValue(() => newValue);
    if (!newValue) {
      setTypeValue(null);
    }
  };

  const loadDocumentsList = async () => {
    try {
      setLoading(true);
      const docs = await getDocumentTemplatesList(searchValue, departmentValue, typeValue);
      setDocumentsList(docs);
      setLoading(false);
    } catch (error) {
      console.log('error loading documents');
    }
  };

  useEffect(() => {
    loadDocumentsList();
  }, [searchValue, departmentValue, typeValue]);

  useEffect(() => {
    getGlossaryAsTree(null, true).then(directories => {
      setDirectories(directories);
    }).catch((error) => {
      console.log('error loading glossaries as tree structure');
      NotificationManager.error(t('errors.somethingIsWrong'), `${t('customProcesses.notifications.error')}!`);
    });
  }, []);

  useEffect(() => {
    getGlossaryAsTree(null, true, false).then(directories => {
      setAllDirectories(directories);
    }).catch((error) => {
      console.log('error loading glossaries as tree structure', error);
      NotificationManager.error(t('errors.somethingIsWrong'), `${t('customProcesses.notifications.error')}!`);
    });
  }, []);

  const groupedDocumentsList = useMemo((): DepartmentDocuments[] => {
    const directoriesNodes = allDirectories ? initializeSelectedStates(allDirectories, [], false) : {};
    const departmentsWithDocs = [];
    for (let doc of documentsList) {
      if (!departmentsWithDocs.some(v => v.departmentId === doc.department)) {
        const newDepartment = {
          departmentName: directoriesNodes[doc.department]
                          ? directoriesNodes[doc.department]?.data?.localization?.[i18n.language] || directoriesNodes[doc.department]?.data?.value
                          : doc.department,
          departmentId: doc.department,
          types: [],
        };
        departmentsWithDocs.push(newDepartment);
      }
      const departmentIndex = departmentsWithDocs.findIndex(v => v.departmentId === doc.department);
      if (!departmentsWithDocs[departmentIndex].types.some(v => v.typeId === doc.type)) {
        departmentsWithDocs[departmentIndex].types.push({
          typeName: directoriesNodes[doc.type]
                    ? directoriesNodes[doc.type]?.data?.localization?.[i18n.language] || directoriesNodes[doc.department]?.data?.value
                    : doc.type,
          typeId: doc.type,
          documents: [],
        });
      }
      const typeIndex = departmentsWithDocs[departmentIndex].types.findIndex(v => v.typeId === doc.type);
      departmentsWithDocs[departmentIndex].types[typeIndex].documents.push(doc);
    }

    return departmentsWithDocs;
  }, [documentsList, allDirectories]);

  useEffect(() =>
      setSelectedDocument(processDocuments.find(doc => doc.uniqueId === focusedDocumentId))
    , [focusedDocumentId, processDocuments]);

  return {
    documentsList,
    groupedDocumentsList,
    directories,
    allDirectories,
    loading,
    selectedDocument,
    searchValue,
    handleSearchChange: debouncedHandleSearchChange,
    departmentValue,
    handleDepartmentChange,
    typeValue,
    handleTypeChange,
  };
};
