import React, { memo, ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';
import { Box, ButtonBase, TextField, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { useDispatch } from 'react-redux';

import { ContentFilter } from 'components';

import { useContentActions } from "./useContentActions";
import { setRequestsCompletedAction, useRequestListState, useTaskState } from "store/requests";
import { setFilters } from 'store/search/search.actions';
import { useVisiblePanel } from "store/approvals";

import { ContentActionsProps } from "./ContentActions.types";

import useStyles from './ContentActions.useStyles';
import FilterIcon from 'assets/images/icons/new-filter-icon.svg';
import Search from '@mui/icons-material/Search';
import MassApproveIcon from 'assets/images/icons/mass-approve.svg';

import { SearchValueContext } from 'pages';
import { useUsersRole } from '../../../hooks';

export const ContentActions = memo(({
  menuList = [],
  basePageUrl = '',
  type,
  isMassSelectAvailable = false,
  isFiltersAvailable = false
}: ContentActionsProps): ReactElement => {
  const classes = useStyles();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const { hasAccessToMassActions } = useUsersRole();

  const visiblePanel = useVisiblePanel();
  const { data: bpmTask } = useTaskState();
  const { completed, currentPage } = useRequestListState();
  const [badgeCountDelta, setBadgeCountDelta] = useState<number>(0);

  const [searchFieldValue, setSearchFieldValue] = useState("");
  const {setValue: setSearchValue} = useContext(SearchValueContext);

  const debouncedSetSearchValue = useCallback(
    debounce((value: string) => setSearchValue(value), 400),
    [setSearchValue]);

  const handleSearchTextChange = (value) => {
    setSearchFieldValue(value);
    debouncedSetSearchValue(value);
  }

  const {
    selectedSubpage,
    isFiltersOpen,
    contentActionBadgeContent,
    handleTogglePanel,
    handleFiltersClick,
    handleFiltersClose,
    handleSubpageSelect
  } = useContentActions({
    type,
    badgeCountDelta,
    basePageUrl
  });

  useEffect(() => {
    dispatch(setFilters({}));
    dispatch(setRequestsCompletedAction(false));

    return () => {
      dispatch(setFilters({}));
      dispatch(setRequestsCompletedAction(false));
    }
  }, [dispatch])

  useEffect(() => {
    // clear searchText
    setSearchFieldValue("")
    setSearchValue("")

    if (visiblePanel){
      handleTogglePanel()
    }
  }, [selectedSubpage])

  const menuButtonClassNames = useCallback((path: string) => classNames(
    classes.tab,
    {
      [classes.activeTab]: path === selectedSubpage
    }
  ), [selectedSubpage]);

  const massSelectActiveButtonClassNames = useMemo(() =>
    classNames(classes.baseButton, classes.massSelectButton, {[classes.massSelectActiveButton]: visiblePanel}), [visiblePanel]);

  const filterButtonClassNames = useMemo(() =>
    classNames(classes.filterButton, { [classes.filterButtonActive]: isFiltersOpen || contentActionBadgeContent > 0}), [contentActionBadgeContent]);

  const subpageSelectClassNames = useMemo(() => classNames(classes.subpageSelect, classes.subpageSelectWithBackground), []);

  return (
    <Box className={classes.root}>
      <Box className={classes.topPanel}>
        {(isMassSelectAvailable && hasAccessToMassActions) && (
          <ButtonBase
            className={massSelectActiveButtonClassNames}
            onClick={() => {
              handleTogglePanel();
            }}
          >
            <img src={MassApproveIcon} className={classes.baseButtonIcon} alt="Approve panel" />
            <span>{t('massActions.selectButton')}</span>
          </ButtonBase>
        )}

        <Box className={classes.filtersBox}>
          <TextField
            size='medium'
            className={classes.searchInput}
            value={searchFieldValue}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start' className={classes.searchIconWrapper}>
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={event => handleSearchTextChange(event.target.value)}
          />

          {isFiltersAvailable && (
            <Box position="relative" zIndex={110}>
              <ButtonBase className={filterButtonClassNames} onClick={handleFiltersClick}>
                <img src={FilterIcon} className={classes.baseButtonIcon} alt="Filter"/>
                {t('filters.title')}{contentActionBadgeContent > 0 ? ` (${contentActionBadgeContent})` : ''}
              </ButtonBase>
            </Box>
          )}
        </Box>
      </Box>

      {menuList.length > 0 &&
        <Box className={subpageSelectClassNames}>
          {menuList.map(({path, name}) =>
              <ButtonBase onClick={() => handleSubpageSelect(path)} className={menuButtonClassNames(path)}>{name}</ButtonBase>
          )}
        </Box>
      }

      {isFiltersAvailable && (
        <Box className={classNames(classes.popup, {[classes.popupRight]: !bpmTask, [classes.popupOpen]: isFiltersOpen, [classes.popupHigh]: isMassSelectAvailable })}>
            <ContentFilter
              type={type}
              completed={completed}
              currentPage={currentPage}
              setBadgeCountDelta={setBadgeCountDelta}
              closeFilter={handleFiltersClose}
              selectedSubpage={selectedSubpage}
            />
        </Box>
      )}
    </Box>
  );
});
