import { commonExceptionHandler } from '../handlers';
import { baseV1ApiClient } from '../clients';

export const getDocumentTemplateByID = async (data): Promise<BlobPart> => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'POST',
      url: 'document-report/main/get/document',
      data,
      responseType: 'blob'
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
}
