import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Spinner } from 'components';

import { routesList } from 'router';
import { RestrictedRoute } from './RestrictedRoute';
import { useUsersRole } from '../hooks';
const RolesAndUsersSettingsPage = lazy(() => import('pages/Settings/RolesAndUsersSettingsPage/RolesAndUsersSettingsPage'));
const GroupsSettingsPage = lazy(() => import('pages/Settings/GroupsSettingsPage/GroupsSettingsPage'));

export const SettingsRouter = () => {
  const { t } = useTranslation();
  const {
    settings: { submenu },
  } = routesList(t);

  const { isGroupSettingPageAvailable, isACLUserHasAccessToSettings } = useUsersRole();

  const { path } = useRouteMatch();

  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route path={path} exact>
          <Redirect to={`${path + submenu[0].path}`} />
        </Route>

        <RestrictedRoute
          path={path + '/roles'}
          hasAccess={isACLUserHasAccessToSettings}
          component={RolesAndUsersSettingsPage}
          redirectPath="/overview"
          isExternalRedirect
        />

        <RestrictedRoute
          path={path + '/groups'}
          hasAccess={isGroupSettingPageAvailable}
          component={GroupsSettingsPage}
          redirectPath="/overview"
          isExternalRedirect
        />

        <Route path={`${path}(.*)`}>
          <Redirect to={routesList(t).requests.path} />
        </Route>
      </Switch>
    </Suspense>
  );
};
