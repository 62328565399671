import React, { memo, ReactElement } from 'react';
import { Box, Button, ButtonBase, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ProcessType } from 'types';

import useStyles from './useStyles';
import FlowIcon from 'assets/images/icons/organization_regular.svg';

export const FLOW_BTN_CLASSNAME = 'FLOW_BTN_CLASSNAME';

type ProcessItemProps = {
  process: ProcessType;
  handleStartProcess: (e: any, process: ProcessType) => void;
  handleShowFlowPreview: (process: ProcessType) => void;
  loadingSysName: string;
}

export const ProcessItem = memo(({
  process,
  handleStartProcess,
  handleShowFlowPreview,
  loadingSysName,
}: ProcessItemProps): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.root}>
      <ButtonBase
        onClick={(e) => handleStartProcess(e, process)}
        className={classes.content}
      >
        <Box display="flex" overflow="hidden">
          <Box className={classes.img}>
            <img src={process.processIconPath} alt=""/>
          </Box>

          <Box display="flex" flexDirection="column" alignItems="flex-start" textAlign="left">
            <Typography
              variant="h4">{t(`constructor-${process.processSysName}.name`, { defaultValue: process.name })}</Typography>

            <Box mt={1}>
              <Typography
                variant="body1">{t(`constructor-${process.processSysName}.description`, { defaultValue: process.processDescription })}</Typography>
            </Box>
          </Box>
        </Box>

        {process.isTemplateProcess &&
          <Button
            className={classNames(FLOW_BTN_CLASSNAME, classes.flowBtn)}
            color="secondary"
            onClick={(event) => {
              event.stopPropagation();
              handleShowFlowPreview(process);
            }}
          >
            <img src={FlowIcon} alt=""/>
            {t('detail.flow')}
          </Button>
        }

      </ButtonBase>

      {loadingSysName === process.processSysName && (
        <Box className={classes.disabled}>
          <CircularProgress size={20}/>
        </Box>
      )}
    </Box>
  );
});
