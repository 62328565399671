import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ palette, spacing }) => ({
  customSnackbar: {
    backgroundColor: '#101F2B',
    boxShadow: `0 ${spacing(1)} ${spacing(4)} rgba(0, 0, 0, 0.16)`,
    borderRadius: spacing(1),

    minWidth: spacing(24),
    padding: `${spacing(1.5)} ${spacing(4)}`
  },
  customSnackbarTitle: {
    fontSize: 12,
    fontWeight: 400,
    color: palette.common.white
  }
}));
