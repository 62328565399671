import moment from 'moment';

export const DATE_VALIDATE = moment()
  .endOf('day')
  .toDate();

export const CURRENT_PAGE_DEFAULT = 1;
export const PROCESS_DEFAULT = '';
export const INITIATOR_DEFAULT = '';
export const SEARCH_QUERY_DEFAULT = '';
export const LEGAL_ENTITY_DEFAULT = '';

export const ERROR_MESSAGES = {
  DATE_TO_ERROR: 'Error! Date to should not be later than current date',
  DATE_FROM_ERROR: 'Error! Date from should not be later than current date',
  DATE_VALIDATION_ERROR: 'Error! Date from should not be later than date to'
};

export const MASS_ACTIONS_SUCCESS_MESSAGES = {
  'approve': 'success_messages.mass_approve',
  'reject': 'success_messages.mass_reject'
}
