import { baseV1ApiClient } from '../clients';
import { commonExceptionHandler } from '../handlers';

import { Position } from 'types';

export const getPositionInfo = async (positionId: string): Promise<Position> => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: `/hcms/main-api/directory/items/${positionId}`,
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getGlossaries = async (glossaryId: string): Promise<any> => {
  try {
    const workspaceAuthorizationToken = localStorage.getItem('workspace_token') || '';
    const headers = {
      'Workspace-Authorization': workspaceAuthorizationToken,
      'Content-Type': 'application/json',
    };
    const response = await baseV1ApiClient.request({
      method: 'GET',
      headers,
      url: `/hcms/main-api/glossaries/by-ids?glossaryIds=${glossaryId}`,
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};
